import { calendarApi } from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { useSettingValue } from 'common/hooks/useSettingValue';
import config from 'config';
import _, { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUninstallableApplicationsAction } from 'redux/slices/applicationSlice';
import { getUser, testFeatureEnabled } from 'redux/slices/authSlice';
import { editOrganizationAction, getOrganization, getOrganizationError } from 'redux/slices/organizationSlice';
import { setSettingsDirty } from 'redux/slices/profileSlice';
import { createOrUpdateSetting, getSettings, getSettingsError } from 'redux/slices/settingSlice';
import CalendarSettings from './CalendarSettings/CalendarSettings';
import CartSettings from './CartSettings/CartSettings';
import ChatSettings from './ChatSettings/ChatSettings';
import EmailSettings from './EmailsSettings/EmailSettings';
import EventSettings from './EventSettings/EventSettings';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import HooksSettings from './HooksSettings/HooksSettings';
import ICalendarSettings from './ICalendar/ICalendarSettings';
import IChatSettings from './IChatSettings/IChatSettings';
import ImmersiveEnvironments from './ImmersiveEnvironments/ImmersiveEnvironments';
import Leads from './Leads/Leads';
import LeadsManagement from './LeadsManagement/LeadsManagement';
import NewFeaturesSettings from './NewFeaturesSettings/NewFeaturesSettings';
import OneToOneSettings from './OneToOneSettings/OneToOneSettings';
import QueueSettings from './QueueSettings/QueueSettings';
import Security from './Security/Security';
import SeoSettings from './SeoSettings/SeoSettings';
import SettingsError from './SettingsError';
import ViewerExtraField from './ViewerExtraField/ViewerExtraFields';
import WidgetOneToMany from './WidgetOneToManySettings/WidgetOneToMany';
import WidgetOneToOne from './WidgetOneToOneSettings/WidgetOneToOne';
import useStylesSettings from './styles/useStylesSettings.styles';
import { useSettingsInitialValue } from './utils/useSettingsInitialValue';

const Settings = () => {
    const isAuthorize = useAuthLicenseAddOns();
    const { classes, cx } = useStylesSettings();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const settings = useSelector(getSettings);
    const organization = useSelector(getOrganization);
    const settingError = useSelector(getSettingsError);
    const organizationError = useSelector(getOrganizationError);

    const showTestFeature = useSelector(testFeatureEnabled);

    const iCall = isAuthorize(['oneToOne.iCall']);
    const iLive = isAuthorize(['oneToMany.iLive']);
    const iCalendar = isAuthorize(['oneToOne.iCalendar']);
    const iChat = isAuthorize(['oneToOne.iChat']);
    const channel = isAuthorize(['oneToMany.channel']);
    const leads = isAuthorize(['leads']);
    const enableImmersive = isAuthorize(['immersive']);
    const user = useSelector(getUser);

    const { enqueueSnackbar } = useSnackbar();

    const { initialValue, initialValueHooks } = useSettingsInitialValue();

    const initialValueCalendar = useMemo(
        () => ({
            google: !organization.syncCalendly,
            calendly: organization.syncCalendly,
        }),
        [organization],
    );

    const [settingsState, setSettingsState] = useState(initialValue);

    const [hooksSettingsState, setHooksSettingsState] = useState(initialValueHooks);

    const [calendar, setCalendar] = useState(initialValueCalendar);

    const [saving, setSaving] = useState(false);

    const settingsToOmit = useMemo(
        () => [
            SettingsKey.ORGANIZATION_EMAIL_IDENTITIES,
            ...(settingsState[SettingsKey.ENABLE_FORM_MANAGE]?.settingValue ? [SettingsKey.VIEWER_EXTRA_FIELDS] : []),
        ],
        [settingsState],
    );

    useEffect(() => {
        if (
            !isEqual(_.omit(initialValue, settingsToOmit), _.omit(settingsState, settingsToOmit)) ||
            !isEqual(initialValueCalendar, calendar) ||
            !isEqual(initialValueHooks, hooksSettingsState)
        ) {
            dispatch(setSettingsDirty(true));
        } else {
            dispatch(setSettingsDirty(false));
        }
    }, [
        settingsState,
        calendar,
        hooksSettingsState,
        dispatch,
        initialValue,
        initialValueCalendar,
        initialValueHooks,
        settingsToOmit,
    ]);

    useEffect(() => {
        dispatch(fetchUninstallableApplicationsAction());
    }, [dispatch]);

    const handleSubmit = (event) => {
        setSaving(true);
        event.preventDefault();
        if (validateSettings()) {
            dispatch(
                createOrUpdateSetting(
                    {
                        ..._.omit(settingsState, settingsToOmit),
                        ...hooksSettingsState,
                    },
                    () => {
                        dispatch(
                            editOrganizationAction(
                                { calendarIntegration: calendar.google ? 'google' : 'calendly' },
                                () => {
                                    setSaving(false);
                                    setSettingsDirty(false);
                                    enqueueSnackbar(t('profile.settings.settings.saveSuccess'), { variant: 'success' });
                                },
                            ),
                        );
                    },
                ),
            );
            if (!initialValue.new_calendar?.settingValue && settingsState.new_calendar?.settingValue) {
                calendarApi({
                    method: 'get',
                    url: `${config.calendarApi.baseURL}/api/v1/organization/import-events/${organization?.id}`,
                });
            }
        } else {
            setSaving(false);
        }
    };

    const toggleChange = (settingKey, settingValue) => {
        const newSettingsState = {
            ...settingsState,
            [settingKey]: {
                settingKey,
                settingValue,
            },
        };
        setSettingsState(newSettingsState);
    };

    const toggleMultipleSettingsChange = (settingsChange) => {
        const newSettingsState = settingsChange.reduce(
            (prevValue, { settingKey, settingValue }) => ({
                ...prevValue,
                [settingKey]: {
                    settingKey,
                    settingValue,
                },
            }),
            settingsState,
        );
        setSettingsState(newSettingsState);
    };

    const changeAllOptions = () => {
        const newSettingsState = {
            ...settingsState,
            enable_shopping: {
                settingKey: SettingsKey.ENABLE_SHOPPING,
                settingValue: false,
            },
            enable_event_cart: {
                settingKey: SettingsKey.ENABLE_EVENT_CART,
                settingValue: false,
            },
            enable_shop_in_store: {
                settingKey: SettingsKey.ENABLE_SHOP_IN_STORE,
                settingValue: false,
            },
            cart_empty_after_checkout: {
                settingKey: SettingsKey.CART_EMPTY_AFTER_CHECKOUT,
                settingValue: false,
            },
        };
        setSettingsState(newSettingsState);
    };

    const changeExclusionaryOption = (settingKey) => {
        const eventCartValue = settingKey === SettingsKey.ENABLE_EVENT_CART;
        const newSettingsState = {
            ...settingsState,
            enable_event_cart: {
                settingKey: SettingsKey.ENABLE_EVENT_CART,
                settingValue: eventCartValue,
            },
            enable_shop_in_store: {
                settingKey: SettingsKey.ENABLE_SHOP_IN_STORE,
                settingValue: !eventCartValue,
            },
            ...((eventCartValue && {
                cart_empty_after_checkout: {
                    settingKey: SettingsKey.CART_EMPTY_AFTER_CHECKOUT,
                    settingValue: false,
                },
            }) ||
                {}),
        };
        setSettingsState(newSettingsState);
    };

    const validateColorSettings = useCallback(
        (color, flag) => {
            if (flag) {
                if (/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color)) {
                    return true;
                } else {
                    enqueueSnackbar(t('profile.settings.settings.invalidColor'), { variant: 'error' });
                    return false;
                }
            } else {
                return true;
            }
        },
        [enqueueSnackbar, t],
    );

    const hasChangesInKeysOfSettings = (keys) => {
        const hasChange = keys.reduce((previousValue, currentValue) => {
            return previousValue || !isEqual(initialValue[currentValue], settingsState[currentValue]);
        }, false);
        return hasChange;
    };

    const validateSettings = useCallback(() => {
        const widgetColor = settingsState[SettingsKey.WIDGET_COLOR]?.settingValue;
        const widgetSetting = settingsState[SettingsKey.ENABLE_CHAT_WIDGET]?.settingValue;
        return validateColorSettings(widgetColor, widgetSetting);
    }, [settingsState, validateColorSettings]);

    const rulesLanding = useSettingValue(SettingsKey.RULES_LANDING_AGENT);
    const landingAgent = useSettingValue(SettingsKey.LANDING_AGENT_PATH);

    useEffect(() => {
        if (settingsState && settings && !rulesLanding && landingAgent) {
            if (validateSettings()) {
                dispatch(
                    createOrUpdateSetting(
                        {
                            ...settingsState,
                            ...hooksSettingsState,
                        },
                        () => {},
                    ),
                );
            }
        }
    }, [dispatch, settings, settingsState, hooksSettingsState, validateSettings, landingAgent, rulesLanding]);

    const handleChangeNotification = (type, notificationName, settingName, value) => {
        const newSettingsState = {
            ...settingsState,
            [type]: {
                settingKey: type,
                settingValue: {
                    ...settingsState[type]?.settingValue,
                    [notificationName]: {
                        ...settingsState[type]?.settingValue[notificationName],
                        [settingName]: value,
                    },
                },
            },
        };
        setSettingsState(newSettingsState);
    };

    if ((settings.length === 0 && settingError) || organizationError) {
        return <SettingsError />;
    }

    return (
        <div className={classes.root}>
            <div className={cx(classes.container, classes.scroll)}>
                <div className={cx(classes.body, classes.mW80)}>
                    <GeneralSettings
                        langSettingValue={settingsState[SettingsKey.DEFAULT_LANGUAGE]?.settingValue}
                        timezoneSettingValue={settingsState['timezone']?.settingValue}
                        twelveHourFormatSettingValue={settingsState[SettingsKey.TWELVE_HOUR_FORMAT]?.settingValue}
                        hasChanges={hasChangesInKeysOfSettings([
                            SettingsKey.DEFAULT_LANGUAGE,
                            'timezone',
                            SettingsKey.TWELVE_HOUR_FORMAT,
                        ])}
                        toggleChange={toggleChange}
                        handleSubmit={handleSubmit}
                    />
                    {iCall || iLive ? (
                        <EventSettings
                            eventFinalizedTime={settingsState[SettingsKey.EVENT_FINALIZED_TIME]?.settingValue}
                            enableDiscount={settingsState[SettingsKey.ENABLE_DISCOUNT_CODES]?.settingValue}
                            enableGift={settingsState[SettingsKey.ENABLE_SHOPIFY_GIFT_PRODUCTS]?.settingValue}
                            remarketingEmail={settingsState[SettingsKey.ENABLE_REMARKETING_EMAIL]?.settingValue}
                            notifyMeValue={settingsState[SettingsKey.ENABLE_NOTIFY_ME]?.settingValue}
                            enableRegister={settingsState[SettingsKey.ENABLE_REGISTER]?.settingValue}
                            enableRegisterInsideTheShow={
                                settingsState[SettingsKey.ENABLE_REGISTER_INSIDE_THE_SHOW]?.settingValue
                            }
                            enableShareLink={settingsState[SettingsKey.ENABLE_EVENT_SHARE_LINK]?.settingValue}
                            textStartPageEvent={settingsState[SettingsKey.TEXT_START_PAGE_EVENT]?.settingValue}
                            textEndPageEvent={settingsState[SettingsKey.TEXT_END_PAGE_EVENT]?.settingValue}
                            enableShowNumberOfParticipants={
                                settingsState[SettingsKey.ENABLE_SHOW_NUMBER_OF_PARTICIPANTS]?.settingValue
                            }
                            enableEnterEventAsGuest={
                                settingsState[SettingsKey.ENABLE_ENTER_EVENT_AS_GUEST]?.settingValue
                            }
                            calendar={calendar}
                            setCalendar={(e) => {
                                setCalendar(e);
                            }}
                            toggleChange={toggleChange}
                            toggleMultipleSettingsChange={toggleMultipleSettingsChange}
                            hasChanges={
                                hasChangesInKeysOfSettings([
                                    SettingsKey.ENABLE_REMARKETING_EMAIL,
                                    SettingsKey.ENABLE_NOTIFY_ME,
                                    SettingsKey.EVENT_FINALIZED_TIME,
                                    SettingsKey.ENABLE_REGISTER,
                                    SettingsKey.ENABLE_REGISTER_INSIDE_THE_SHOW,
                                    SettingsKey.ENABLE_DISCOUNT_CODES,
                                    SettingsKey.ENABLE_SHOPIFY_GIFT_PRODUCTS,
                                    SettingsKey.ENABLE_EVENT_SHARE_LINK,
                                    SettingsKey.TEXT_START_PAGE_EVENT,
                                    SettingsKey.TEXT_END_PAGE_EVENT,
                                    SettingsKey.ENABLE_SHOW_NUMBER_OF_PARTICIPANTS,
                                    SettingsKey.ENABLE_ENTER_EVENT_AS_GUEST,
                                ]) || !isEqual(initialValueCalendar, calendar)
                            }
                            handleSubmit={handleSubmit}
                        />
                    ) : null}

                    {iCall ? (
                        <OneToOneSettings
                            enableHostIcallEmailReminder={
                                settingsState[SettingsKey.ENABLE_HOST_ICALL_EMAIL_REMINDER]?.settingValue
                            }
                            registerNotification={
                                settingsState[SettingsKey.ENABLE_EMAIL_POPUP_NOTIFICATION]?.settingValue
                            }
                            timeLeftShopping={settingsState[SettingsKey.SHOW_VIEW_TIME_LEFT_SHOPPING]?.settingValue}
                            meetRecordingEnabled={settingsState[SettingsKey.MEET_RECORDING_ENABLED]?.settingValue}
                            meetRecordingLayout={settingsState[SettingsKey.MEET_RECORDING_LAYOUT]?.settingValue}
                            qualityControlEnabled={
                                settingsState[SettingsKey.MEET_QUALITY_CONTROL_ENABLED]?.settingValue
                            }
                            recordingRandomProbability={
                                settingsState[SettingsKey.RECORDING_RANDOM_PROBABILITY]?.settingValue
                            }
                            meetQualityControlRecordingLayout={
                                settingsState[SettingsKey.MEET_QUALITY_CONTROL_RECORDING_LAYOUT]?.settingValue
                            }
                            showICallCategory={settingsState[SettingsKey.SHOW_ICALL_CATEGORY_IN_TABLE]?.settingValue}
                            showICallResult={settingsState[SettingsKey.SHOW_ICALL_RESULT_IN_TABLE]?.settingValue}
                            // enableIcall={settingsState[SettingsKey.ENABLE_ICALL]?.settingValue}
                            enablePool={settingsState[SettingsKey.ENABLE_POOL]?.settingValue}
                            iCallVersion={settingsState[SettingsKey.ICALL_VERSION]?.settingValue}
                            icallOptions={settingsState[SettingsKey.ICALL_OPTIONS]?.settingValue}
                            categories={settingsState[SettingsKey.ICALL_CATEGORIES]?.settingValue}
                            results={settingsState[SettingsKey.ICALL_RESULTS]?.settingValue}
                            enablePresets={settingsState[SettingsKey.ENABLE_PRESETS]?.settingValue}
                            widgetInternalBrowserClient={
                                settingsState[SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_CLIENT]?.settingValue
                            }
                            enableCamOnStartIcall={settingsState[SettingsKey.ENABLE_CAM_ON_START_ICALL]?.settingValue}
                            widgetBaseUrl={settingsState[SettingsKey.CALL_DOMAIN_BASE_URL]?.settingValue}
                            hideInputName={settingsState[SettingsKey.HIDE_INPUT_NAME]?.settingValue}
                            icallNotifications={settingsState[SettingsKey.ICALL_NOTIFICATIONS]?.settingValue}
                            communicationOutcome={settingsState[SettingsKey.COMMUNICATION_OUTCOME]?.settingValue}
                            communicationLogsEnabled={
                                settingsState[SettingsKey.COMMUNICATION_LOGS_ENABLED]?.settingValue
                            }
                            icallTranslations={settingsState[SettingsKey.ICALL_TRANSLATIONS]?.settingValue}
                            icallEmailReminders={settingsState[SettingsKey.ICALL_EMAIL_REMINDERS]?.settingValue}
                            paramsCharacter={settingsState[SettingsKey.PARAMS_CHARACTER]?.settingValue}
                            icallSchema={settingsState[SettingsKey.ICALL_SCHEMA]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.ENABLE_HOST_ICALL_EMAIL_REMINDER,
                                SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_CLIENT,
                                SettingsKey.ENABLE_EMAIL_POPUP_NOTIFICATION,
                                SettingsKey.SHOW_VIEW_TIME_LEFT_SHOPPING,
                                SettingsKey.MEET_RECORDING_ENABLED,
                                SettingsKey.MEET_RECORDING_LAYOUT,
                                SettingsKey.MEET_QUALITY_CONTROL_ENABLED,
                                SettingsKey.RECORDING_RANDOM_PROBABILITY,
                                SettingsKey.MEET_QUALITY_CONTROL_RECORDING_LAYOUT,
                                // SettingsKey.ENABLE_ICALL,
                                SettingsKey.ENABLE_PRESETS,
                                SettingsKey.SHOW_ICALL_CATEGORY_IN_TABLE,
                                SettingsKey.SHOW_ICALL_RESULT_IN_TABLE,
                                SettingsKey.ICALL_CATEGORIES,
                                SettingsKey.ICALL_RESULTS,
                                SettingsKey.ICALL_VERSION,
                                SettingsKey.ICALL_OPTIONS,
                                SettingsKey.CALL_DOMAIN_BASE_URL,
                                SettingsKey.ENABLE_POOL,
                                SettingsKey.HIDE_INPUT_NAME,
                                SettingsKey.ICALL_NOTIFICATIONS,
                                SettingsKey.COMMUNICATION_OUTCOME,
                                SettingsKey.COMMUNICATION_LOGS_ENABLED,
                                SettingsKey.ICALL_TRANSLATIONS,
                                SettingsKey.ENABLE_CAM_ON_START_ICALL,
                                SettingsKey.ICALL_EMAIL_REMINDERS,
                                SettingsKey.PARAMS_CHARACTER,
                                SettingsKey.ICALL_SCHEMA,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                            handleChangeNotification={(...props) =>
                                handleChangeNotification(SettingsKey.ICALL_NOTIFICATIONS, ...props)
                            }
                        />
                    ) : null}

                    {iChat && (
                        <IChatSettings
                            // enableIChat={settingsState[SettingsKey.ENABLE_ICHAT]?.settingValue}
                            ichatNotifications={settingsState[SettingsKey.ICHAT_NOTIFICATIONS]?.settingValue}
                            ichatTranslations={settingsState[SettingsKey.ICHAT_TRANSLATIONS]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                // SettingsKey.ENABLE_ICHAT,
                                SettingsKey.ICHAT_VERSION,
                                SettingsKey.ICHAT_NOTIFICATIONS,
                                SettingsKey.ICHAT_TRANSLATIONS,
                            ])}
                            handleChangeNotification={(...props) =>
                                handleChangeNotification(SettingsKey.ICHAT_NOTIFICATIONS, ...props)
                            }
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                            iChatVersion={settingsState[SettingsKey.ICHAT_VERSION]?.settingValue}
                        />
                    )}

                    {iCalendar && (
                        <ICalendarSettings
                            sendBookingEmailToAgent={
                                settingsState[SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT]?.settingValue
                            }
                            enableAgentEditAvailability={
                                settingsState[SettingsKey.ENABLE_AGENT_EDIT_AVAILABILITY]?.settingValue
                            }
                            enableAgentEditGroupAppointment={
                                settingsState[SettingsKey.ENABLE_AGENT_EDIT_GROUP_APPOINTMENT]?.settingValue
                            }
                            serviceAppointmentCreateTriageId={
                                settingsState[SettingsKey.SERVICE_APPOINTMENT_CREATE_TRIAGE_ID]?.settingValue
                            }
                            appointmentRatingInstallation={
                                settingsState[SettingsKey.APPOINTMENT_RATING_INSTALLATION]?.settingValue
                            }
                            appointmentStatus={settingsState[SettingsKey.APPOINTMENT_STATUS]?.settingValue}
                            appointmentOrigin={settingsState[SettingsKey.APPOINTMENT_ORIGIN]?.settingValue}
                            icalendarEmailPreviousReminders={
                                settingsState[SettingsKey.ICALL_EMAIL_REMINDERS]?.settingValue
                            }
                            icalendarEmailPastReminders={
                                settingsState[SettingsKey.ICALENDAR_EMAIL_PAST_REMINDERS]?.settingValue
                            }
                            hasChanges={hasChangesInKeysOfSettings([
                                // SettingsKey.ENABLE_ICALENDAR,
                                SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT,
                                SettingsKey.SERVICE_APPOINTMENT_CREATE_TRIAGE_ID,
                                SettingsKey.APPOINTMENT_STATUS,
                                SettingsKey.APPOINTMENT_ORIGIN,
                                SettingsKey.APPOINTMENT_RATING_INSTALLATION,
                                SettingsKey.ENABLE_AGENT_EDIT_AVAILABILITY,
                                SettingsKey.ENABLE_AGENT_EDIT_GROUP_APPOINTMENT,
                                SettingsKey.ICALL_EMAIL_REMINDERS,
                                SettingsKey.ICALENDAR_EMAIL_PAST_REMINDERS,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {iCall || iLive ? (
                        <ChatSettings
                            settingValue={settingsState[SettingsKey.ENABLE_EVENT_CHAT]?.settingValue}
                            useWordBag={settingsState[SettingsKey.ENABLE_WORD_BAG]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.ENABLE_EVENT_CHAT,
                                SettingsKey.ENABLE_WORD_BAG,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    ) : null}

                    {iCall ? (
                        <WidgetOneToOne
                            widgetSetting={settingsState[SettingsKey.ENABLE_CHAT_WIDGET]?.settingValue}
                            fullLookAndFeelAddOn={isAuthorize(['full_look_and_feel'])}
                            widgetColorSetting={settingsState[SettingsKey.WIDGET_COLOR]?.settingValue}
                            widgetVersionSetting={settingsState[SettingsKey.WIDGET_VERSION]?.settingValue}
                            widgetPositionSetting={settingsState[SettingsKey.WIDGET_ORIENTATION]?.settingValue}
                            widgetActionsSetting={settingsState[SettingsKey.WIDGET_ACTIONS]?.settingValue}
                            usePreselectedFlowSetting={
                                settingsState[SettingsKey.WIDGET_USE_PRESELECTED_FLOW]?.settingValue
                            }
                            widgetBtnOpenAtStart={settingsState[SettingsKey.WIDGET_BTN_OPEN_AT_START]?.settingValue}
                            widgetBtnDistribution={settingsState[SettingsKey.WIDGET_BTN_DISTRIBUTION]?.settingValue}
                            widgetHideVideoCall={settingsState[SettingsKey.WIDGET_HIDE_VIDEO_CALL]?.settingValue}
                            widgetVersion2Design={settingsState[SettingsKey.WIDGET_DESIGN]?.settingValue}
                            rulesWidget={settingsState[SettingsKey.RULES_WIDGET]?.settingValue}
                            customStylesWidget={settingsState[SettingsKey.CUSTOM_STYLES_WIDGET]?.settingValue}
                            optionsWidget={settingsState[SettingsKey.OPTIONS_WIDGET]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.ENABLE_CHAT_WIDGET,
                                'full_look_and_feel',
                                SettingsKey.WIDGET_COLOR,
                                SettingsKey.WIDGET_VERSION,
                                SettingsKey.WIDGET_ORIENTATION,
                                SettingsKey.WIDGET_ACTIONS,
                                SettingsKey.WIDGET_USE_PRESELECTED_FLOW,
                                SettingsKey.WIDGET_BTN_OPEN_AT_START,
                                SettingsKey.WIDGET_BTN_DISTRIBUTION,
                                SettingsKey.WIDGET_HIDE_VIDEO_CALL,
                                SettingsKey.WIDGET_DESIGN,
                                SettingsKey.RULES_WIDGET,
                                SettingsKey.CUSTOM_STYLES_WIDGET,
                                SettingsKey.OPTIONS_WIDGET,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    ) : null}
                    {/* <OnliveMeetSettings
                        meetRecordingEnabled={settingsState[SettingsKey.MEET_RECORDING_ENABLED]?.settingValue}
                        hasChanges={hasChangesInKeysOfSettings([SettingsKey.MEET_RECORDING_ENABLED])}
                        toggleChange={toggleChange}
                        handleSubmit={handleSubmit}
                    /> */}

                    {/* <LandingAgents
                        landingAgentEnabled={settingsState[SettingsKey.LANDING_AGENT_ENABLED]?.settingValue}
                        rulesLandingAgent={settingsState[SettingsKey.RULES_LANDING_AGENT]?.settingValue}
                        optionsLandingAgent={settingsState[SettingsKey.OPTIONS_LANDING_AGENT]?.settingValue}
                        customStylesLandingAgent={settingsState[SettingsKey.CUSTOM_STYLES_LANDING_AGENT]?.settingValue}
                        hasChanges={hasChangesInKeysOfSettings([
                            SettingsKey.LANDING_AGENT_ENABLED,
                            SettingsKey.RULES_LANDING_AGENT,
                            SettingsKey.OPTIONS_LANDING_AGENT,
                            SettingsKey.CUSTOM_STYLES_LANDING_AGENT,
                        ])}
                        toggleChange={toggleChange}
                        handleSubmit={handleSubmit}
                    /> */}

                    {channel ? (
                        <WidgetOneToMany
                            customStylesTVWidget={settingsState[SettingsKey.CUSTOM_STYLES_TV_WIDGET]?.settingValue}
                            channelSubscriptionEnable={
                                settingsState[SettingsKey.ENABLE_WIDGET_CHANNEL_SUBSCRIPTION]?.settingValue
                            }
                            widgetState={settingsState[SettingsKey.ENABLE_TV_WIDGET]?.settingValue}
                            widgetIternalBrowserTv={
                                settingsState[SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_TV_WIDGET]?.settingValue
                            }
                            clientBaseUrl={settingsState[SettingsKey.SEO_CLIENT_BASE_URL]?.settingValue}
                            rulesTVWidget={settingsState[SettingsKey.RULES_TV_WIDGET]?.settingValue}
                            optionsTVWidget={settingsState[SettingsKey.OPTIONS_TV_WIDGET]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.ENABLE_WIDGET_CHANNEL_SUBSCRIPTION,
                                SettingsKey.ENABLE_TV_WIDGET,
                                SettingsKey.TV_WIDGET_VERSION,
                                SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_TV_WIDGET,
                                SettingsKey.SEO_CLIENT_BASE_URL,
                                SettingsKey.CUSTOM_STYLES_TV_WIDGET,
                                SettingsKey.RULES_TV_WIDGET,
                                SettingsKey.OPTIONS_TV_WIDGET,
                            ])}
                            widgetLandingVersion={settingsState[SettingsKey.TV_WIDGET_VERSION]?.settingValue}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    ) : null}

                    {iCall ? (
                        <QueueSettings
                            enableOrganizationQueue={settingsState[SettingsKey.ENABLE_ORGANIZATION_QUEUE]?.settingValue}
                            enablePersonalQueue={settingsState[SettingsKey.ENABLE_PERSONAL_QUEUE]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.ENABLE_ORGANIZATION_QUEUE,
                                SettingsKey.ENABLE_PERSONAL_QUEUE,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    ) : null}

                    {iCall || iLive ? (
                        <CartSettings
                            settingValue={settingsState[SettingsKey.ENABLE_EVENT_CART]?.settingValue}
                            enableShopping={settingsState[SettingsKey.ENABLE_SHOPPING]?.settingValue}
                            shopInStoreValue={settingsState[SettingsKey.ENABLE_SHOP_IN_STORE]?.settingValue}
                            showPriceValue={settingsState[SettingsKey.SHOW_PRICE]?.settingValue}
                            cartEmptyAfterCheckout={settingsState[SettingsKey.CART_EMPTY_AFTER_CHECKOUT]?.settingValue}
                            storeUpdateCheckoutBehavior={
                                settingsState[SettingsKey.STORE_UPDATE_CHECKOUT_BEHAVIOR]?.settingValue
                            }
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.ENABLE_EVENT_CART,
                                SettingsKey.ENABLE_SHOPPING,
                                SettingsKey.ENABLE_SHOP_IN_STORE,
                                SettingsKey.SHOW_PRICE,
                                SettingsKey.CART_EMPTY_AFTER_CHECKOUT,
                                SettingsKey.STORE_UPDATE_CHECKOUT_BEHAVIOR,
                            ])}
                            toggleChange={toggleChange}
                            changeAllOptions={changeAllOptions}
                            changeExclusionaryOption={changeExclusionaryOption}
                            handleSubmit={handleSubmit}
                            toggleMultipleSettingsChange={toggleMultipleSettingsChange}
                        />
                    ) : null}

                    <EmailSettings
                        langSettingValue={settingsState[SettingsKey.DEFAULT_LANGUAGE]?.settingValue || 'es'}
                        senderNameValue={settingsState[SettingsKey.EMAIL_SENDER_NAME]?.settingValue}
                        formatDateEmail={settingsState[SettingsKey.FORMAT_DATE_EMAIL]?.settingValue}
                        formatTimeEmail={settingsState[SettingsKey.FORMAT_TIME_EMAIL]?.settingValue}
                        verifiedIdentities={settingsState[SettingsKey.ORGANIZATION_EMAIL_IDENTITIES]?.settingValue}
                        shareEmailIdentities={settingsState[SettingsKey.SHARE_EMAIL_IDENTITIES]?.settingValue}
                        hasChanges={hasChangesInKeysOfSettings([
                            'default_emails_language',
                            SettingsKey.ORGANIZATION_EMAILS_TEMPLATES,
                            'email_timezone',
                            'email_twelve_hour_format',
                            SettingsKey.EMAIL_SENDER_NAME,
                            SettingsKey.FORMAT_DATE_EMAIL,
                            SettingsKey.FORMAT_TIME_EMAIL,
                            SettingsKey.SHARE_EMAIL_IDENTITIES,
                        ])}
                        toggleChange={toggleChange}
                        handleSubmit={handleSubmit}
                        iCall={iCall}
                        iLive={iLive}
                        iCalendar={iCalendar}
                        isAuthorize={isAuthorize}
                    />

                    {showTestFeature && (
                        <SeoSettings
                            clientWebUrl={settingsState[SettingsKey.SEO_CLIENT_WEB_URL]?.settingValue}
                            clientWebUrlSource={settingsState[SettingsKey.SEO_CLIENT_WEB_URL_SOURCE]?.settingValue}
                            clientAutoIndex={settingsState[SettingsKey.SEO_CLIENT_AUTO_INDEX]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.SEO_CLIENT_WEB_URL,
                                SettingsKey.SEO_CLIENT_WEB_URL_SOURCE,
                                SettingsKey.SEO_CLIENT_AUTO_INDEX,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                            saving={saving}
                        />
                    )}

                    {!settingsState[SettingsKey.ENABLE_FORM_MANAGE]?.settingValue && (
                        <ViewerExtraField
                            viewerExtraField={settingsState[SettingsKey.VIEWER_EXTRA_FIELDS]?.settingValue}
                            textActionSubmitJoinForm={
                                settingsState[SettingsKey.TEXT_ACTION_SUBMIT_JOIN_FORM]?.settingValue
                            }
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.VIEWER_EXTRA_FIELDS,
                                SettingsKey.TEXT_ACTION_SUBMIT_JOIN_FORM,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    <HooksSettings
                        enableNotifyMe={settingsState[SettingsKey.ENABLE_NOTIFY_ME]?.settingValue}
                        settingsState={hooksSettingsState}
                        setSettingsState={setHooksSettingsState}
                        hasChanges={!isEqual(initialValueHooks, hooksSettingsState)}
                        handleSubmit={handleSubmit}
                    />

                    {showTestFeature && (
                        <NewFeaturesSettings
                            enableGroups={settingsState[SettingsKey.ENABLE_USER_GROUPS]?.settingValue}
                            enableProductUnit={settingsState[SettingsKey.ENABLE_PRODUCT_UNIT]?.settingValue}
                            instantSearch={settingsState[SettingsKey.INSTANT_SEARCH]?.settingValue}
                            enableLabels={settingsState[SettingsKey.ENABLE_LABELS]?.settingValue}
                            widgetInternalBrowser={
                                settingsState[SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER]?.settingValue
                            }
                            enableChime={settingsState[SettingsKey.ENABLE_CHIME]?.settingValue}
                            newCalendar={settingsState[SettingsKey.NEW_CALENDAR]?.settingValue}
                            supportWidgetScript={settingsState[SettingsKey.SUPPORT_WIDGET_SCRIPT]?.settingValue}
                            newInstallations={settingsState[SettingsKey.NEW_INSTALLATIONS]?.settingValue}
                            installationsMenu={settingsState[SettingsKey.ENABLE_INSTALLATIONS_MENU]?.settingValue}
                            shareFiles={settingsState[SettingsKey.SHARE_FILES]?.settingValue}
                            enableAircalls={settingsState[SettingsKey.ENABLE_AIRCALLS]?.settingValue}
                            dashboardSummary={settingsState[SettingsKey.ENABLE_DASHBOARD_SUMMARY_EMAILS]?.settingValue}
                            enableProductDiscount={settingsState[SettingsKey.ENABLE_PRODUCT_DISCOUNT]?.settingValue}
                            enableTvChannelManager={settingsState[SettingsKey.ENABLE_TV_CHANNEL_MANAGER]?.settingValue}
                            immersiveAppSizeLimit={settingsState[SettingsKey.IMMERSIVE_APP_SIZE_LIMIT]?.settingValue}
                            enableFormManage={settingsState[SettingsKey.ENABLE_FORM_MANAGE]?.settingValue}
                            enableGenAI={settingsState[SettingsKey.ENABLE_GENAI]?.settingValue}
                            enableSocialVideos={settingsState[SettingsKey.ENABLE_SOCIAL_VIDEOS]?.settingValue}
                            productCheck={settingsState[SettingsKey.PRODUCTS_CHECK_ENABLED]?.settingValue}
                            // showSectionLeads={settingsState[SettingsKey.SHOW_SECTION_LEADS]?.settingValue}
                            // enableImmersive={settingsState[SettingsKey.ENABLE_IMMERSIVE]?.settingValue}
                            // hideSectionOneToOne={settingsState[SettingsKey.HIDE_SECTION_ONE_TO_ONE]?.settingValue}
                            // hideSectionOneToMany={settingsState[SettingsKey.HIDE_SECTION_ONE_TO_MANY]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.INSTANT_SEARCH,
                                SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER,
                                SettingsKey.ENABLE_CHIME,
                                SettingsKey.ENABLE_LABELS,
                                SettingsKey.NEW_CALENDAR,
                                SettingsKey.SUPPORT_WIDGET_SCRIPT,
                                SettingsKey.NEW_INSTALLATIONS,
                                SettingsKey.ENABLE_INSTALLATIONS_MENU,
                                SettingsKey.SHARE_FILES,
                                SettingsKey.ENABLE_AIRCALLS,
                                SettingsKey.ENABLE_DASHBOARD_SUMMARY_EMAILS,
                                SettingsKey.ENABLE_PRODUCT_DISCOUNT,
                                SettingsKey.MILICAST_CLUSTER_REGION,
                                SettingsKey.ENABLE_TV_CHANNEL_MANAGER,
                                SettingsKey.IMMERSIVE_APP_SIZE_LIMIT,
                                SettingsKey.ENABLE_FORM_MANAGE,
                                SettingsKey.ENABLE_USER_GROUPS,
                                SettingsKey.ENABLE_PRODUCT_UNIT,
                                SettingsKey.ENABLE_GENAI,
                                SettingsKey.ENABLE_SOCIAL_VIDEOS,
                                SettingsKey.PRODUCTS_CHECK_ENABLED,
                                // SettingsKey.SHOW_SECTION_LEADS,
                                // SettingsKey.ENABLE_IMMERSIVE,
                                // SettingsKey.HIDE_SECTION_ONE_TO_ONE,
                                // SettingsKey.HIDE_SECTION_ONE_TO_MANY,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    <LeadsManagement
                        leadsEndPoint={settingsState[SettingsKey.LEADS_MANAGEMENT_END_POINT]?.settingValue}
                        leadsClientId={settingsState[SettingsKey.LEADS_MANAGEMENT_CLIENT_ID]?.settingValue}
                        leadsClientSecret={settingsState[SettingsKey.LEADS_MANAGEMENT_CLIENT_SECRET]?.settingValue}
                        hasChanges={hasChangesInKeysOfSettings([
                            SettingsKey.LEADS_MANAGEMENT_END_POINT,
                            SettingsKey.LEADS_MANAGEMENT_CLIENT_ID,
                            SettingsKey.LEADS_MANAGEMENT_CLIENT_SECRET,
                        ])}
                        toggleChange={toggleChange}
                        handleSubmit={handleSubmit}
                    />

                    {iCalendar ? null : (
                        <CalendarSettings
                            sendBookingEmailToAgent={
                                settingsState[SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT]?.settingValue
                            }
                            availabilityTypes={settingsState[SettingsKey.AVAILABILITY_TYPES]?.settingValue}
                            bookingShareUrl={settingsState[SettingsKey.BOOKING_SHARE_URL]?.settingValue}
                            bookingTitle={settingsState[SettingsKey.BOOKING_TITLE]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.AVAILABILITY_TYPES,
                                SettingsKey.BOOKING_SHARE_URL,
                                SettingsKey.BOOKING_TITLE,
                                SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {leads && user.role === 'root' && (
                        <Leads
                            enableAddLeadManual={settingsState[SettingsKey.ENABLE_ADD_LEAD_MANUAL]?.settingValue}
                            enableSendLeadRemarketingEmail={
                                settingsState[SettingsKey.ENABLE_SEND_LEAD_REMARKETING_EMAIL]?.settingValue
                            }
                            leadStatus={settingsState[SettingsKey.LEADS_STATUS]?.settingValue}
                            hasChanges={hasChangesInKeysOfSettings([
                                SettingsKey.LEADS_STATUS,
                                SettingsKey.ENABLE_ADD_LEAD_MANUAL,
                                SettingsKey.ENABLE_SEND_LEAD_REMARKETING_EMAIL,
                            ])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {enableImmersive && <ImmersiveEnvironments />}

                    {(user.role === 'root' || user.role === 'admin') && (
                        <Security
                            requireTwoFactorAuthentication={
                                settingsState[SettingsKey.REQUIRE_TWO_FACTOR_AUTHENTICATION]?.settingValue
                            }
                            hasChanges={hasChangesInKeysOfSettings([SettingsKey.REQUIRE_TWO_FACTOR_AUTHENTICATION])}
                            toggleChange={toggleChange}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Settings;
