import { FormLabel, Stack } from '@mui/material';
import CustomMenuItem from 'common/components/CustomMenuItem';
import CustomSelectValidator from 'common/components/CustomSelectValidators';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getForms } from 'redux/slices/formSlice';

const FormFieldSelectForm = ({ id, fields, handleFieldChange }) => {
    const { t } = useTranslation();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: onlive, cx } = useOnliveStyles();
    const forms = useSelector(getForms);

    return (
        <Stack
            direction="column"
            className={cx(flexBox.padding1, onlive.borderMediumGray, flexBox.borderRadius1, flexBox.marginBottom2)}
            spacing={2}
        >
            <FormLabel>{t('form.fields.selectForm').toUpperCase()}</FormLabel>

            <CustomSelectValidator
                name="formId"
                select
                value={fields.formId || ''}
                onChange={handleFieldChange}
                validators={['required']}
                errorMessages={[t('profile.settings.viewerExtraField.fieldForm.typeRequired')]}
                variant="outlined"
                size="small"
                fullWidth={true}
            >
                {forms
                    .filter((f) => f.id !== id)
                    .map((form) => {
                        return (
                            <CustomMenuItem key={form.id} value={form.id} noBorder={true}>
                                {form.name}
                            </CustomMenuItem>
                        );
                    })}
            </CustomSelectValidator>
        </Stack>
    );
};

export default FormFieldSelectForm;
