import '@onlive.site/ui/dist/themes/light.css';
import 'firebase/auth';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/ro';
import 'moment/locale/uk';
import './App.css';

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import { Button } from '@mui/material';
import { NotificationService } from '@onlive.site/front-core';
import { setBasePath } from '@onlive.site/ui/dist/utilities/base-path.js';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';
import { SnackbarProvider } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getRealtimeLoaded, initRealTimes } from 'redux/slices/realtimeSlice';

import StatusList from 'app/profile/StatusList';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import api from './api';
import LoadingSetting from './app/profile/LoadingSetting';
import Profile from './app/profile/Profile';
import SettingsKey from './app/profile/settings/utils/settingsTypes';
import SocialOAuthCallback from './app/socialMedia/SocialOAuthCallback';
import withClearCache from './ClearCache';
import ProtectedRoute from './common/components/ProtectedRoute';
import { useContextLicenseAddOns } from './common/features/licenseFeatures/useContextLicenseAddOns';
import RedirectServiceLogin from './common/features/o-auth/RedirectServiceLogin';
import { useExternalScript } from './common/hooks/useExternalScript';
import { useSettingValue } from './common/hooks/useSettingValue';
import { isValidHttpUrl } from './common/utils/utils';
import { useMenuEntries } from './components/menu/MenuEntries';
import config from './config';
import CustomThemeProvider from './libs/themeLib';
import { getUser, userHasAuthenticated } from './redux/slices/authSlice';
import { getInitialized, setInitialized } from './redux/slices/calendarNotificationSlice';
import { getContents } from './redux/slices/contentSlice';
import { fetchOneLicenseAction, getLicense } from './redux/slices/licenseSlice';
import { getOrganization, getOrganizationError, setMicroFrontsConfig } from './redux/slices/organizationSlice';
import {
    getLanguage,
    getLoadedSettings,
    getSettings,
    getSettingsError,
    setLanguage,
} from './redux/slices/settingSlice';
import CalendarNotificationService from './services/CalendarNotificationService';
import contentInstanceSocket from './sockets/content-instance-socket';
import iCallSocket from './sockets/icall-socket';
import Authorize from './views/Authorize';
import CloudStoreAuthorize from './views/CloudStoreAuthorize';
import Main from './views/Main';
import OAuthInvitation from './views/OAuthInvitation';
import TermsConditions from './views/TermsConditions';
import MicroFrontContext from './components/micro-front/MicroFrontContext';

if (!firebase.apps.length) {
    firebase.initializeApp(config.firebase);
}

const ClearCacheComponent = withClearCache(MainApp);
if (typeof Buffer === 'undefined') global.Buffer = require('buffer').Buffer;

const App = () => {
    const { i18n } = useTranslation();
    const language = useSelector(getLanguage);
    const loadedSettings = useSelector(getLoadedSettings);
    const settings = useSelector(getSettings);
    const dispatch = useDispatch();

    const defaultLanguage = useSettingValue(SettingsKey.DEFAULT_LANGUAGE) || 'es';
    moment.locale(language || defaultLanguage);

    setBasePath('https://cdn.onlive.site/@onlive.site/ui@1.2.9/cdn');

    useEffect(() => {
        if (!loadedSettings) {
            return;
        }
        const newLanguage = language || defaultLanguage;
        if (i18n?.language !== newLanguage || language !== newLanguage) {
            dispatch(setLanguage(newLanguage));
            i18n.changeLanguage(newLanguage);
            moment.locale(newLanguage);
        }
    }, [loadedSettings, language, settings, defaultLanguage, i18n, dispatch]);

    return <ClearCacheComponent />;
};

const cubejsApi = () => {
    return cubejs(
        () => {
            return api.get(`${process.env.REACT_APP_API_URL}/auth/cube-token`).then((data) => data.data.token);
        },
        { apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1` },
    );
};

function MainApp() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const contents = useSelector(getContents);
    const organization = useSelector(getOrganization);
    const license = useSelector(getLicense);
    const organizationId = user?.organizationId;
    const calendarNotificationInitialized = useSelector(getInitialized);
    const isAuthenticated = useSelector(userHasAuthenticated);
    const settings = useSelector(getSettings);
    const [supportWidgetScript, setSupportWidgetScript] = useState(null);
    const settingError = useSelector(getSettingsError);
    const widgetScript = useSettingValue(SettingsKey.SUPPORT_WIDGET_SCRIPT);
    const [scriptStatus, setScript] = useExternalScript(widgetScript);
    const [showError, setShowError] = useState(true);
    const organizationError = useSelector(getOrganizationError);
    useEffect(() => {
        setScript(supportWidgetScript);
        return () => {
            window.HubSpotConversations?.widget?.remove();
        };
    }, [supportWidgetScript, setScript]);

    useEffect(() => {
        console.debug('Support widget script status', scriptStatus);
        window.HubSpotConversations?.widget?.load();
    }, [scriptStatus]);

    useEffect(() => {
        setSupportWidgetScript(widgetScript);
    }, [settings, widgetScript]);

    useEffect(() => {
        if (((settingError && settings.length === 0) || organizationError) && showError) {
            setShowError(false);
            providerRef.current.enqueueSnackbar(
                t('app.common.settingsErrorText') + ' ' + t('app.common.settingsErrorMessage'),
                {
                    variant: 'error',
                    persist: true,
                },
            );
        }
    }, [organizationError, settingError, settings, showError, t]);

    const menuEntries = useMenuEntries();
    const providerRef = React.useRef();
    const icallNotifications = useSettingValue(SettingsKey.ICALL_NOTIFICATIONS);
    const ichatNotifications = useSettingValue(SettingsKey.ICHAT_NOTIFICATIONS);
    const realtimeLoaded = useSelector(getRealtimeLoaded);
    const chatWidget = license?.services?.oneToOne?.iCall;

    const newCalendar = useSettingValue(SettingsKey.NEW_CALENDAR) ?? false;
    const enableBrowserSoundNotification = useMemo(
        () => icallNotifications?.[SettingsKey.CONTENT_CREATION]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION],
        [icallNotifications],
    );
    const enableSystemTrayNotification = useMemo(
        () => icallNotifications?.[SettingsKey.CONTENT_CREATION]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION],
        [icallNotifications],
    );
    const enableBrowserPushNotification = useMemo(
        () => icallNotifications?.[SettingsKey.CONTENT_CREATION]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION],
        [icallNotifications],
    );
    const ringAudio = React.useRef(null);
    const chatAudio = React.useRef(null);
    const enableBrowserChatNotification = useMemo(
        () => ichatNotifications?.[SettingsKey.INCOMING_CHAT_REQUEST]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION],
        [ichatNotifications],
    );
    const enableSystemTrayChatNotification = useMemo(
        () => ichatNotifications?.[SettingsKey.INCOMING_CHAT_REQUEST]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION],
        [ichatNotifications],
    );
    const enableBrowserPushChatNotification = useMemo(
        () => ichatNotifications?.[SettingsKey.INCOMING_CHAT_REQUEST]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION],
        [ichatNotifications],
    );

    const enableBrowserSoundLostNotification = useMemo(
        () =>
            icallNotifications?.[SettingsKey.LOST_OPPORTUNITY_CREATION]?.[
            SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION
            ],
        [icallNotifications],
    );
    const enableSystemTrayLostNotification = useMemo(
        () =>
            icallNotifications?.[SettingsKey.LOST_OPPORTUNITY_CREATION]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION],
        [icallNotifications],
    );
    const enableBrowserPushLostNotification = useMemo(
        () =>
            icallNotifications?.[SettingsKey.LOST_OPPORTUNITY_CREATION]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION],
        [icallNotifications],
    );
    const ringLostAudio = React.useRef(null);

    const dataSoundIcall = useCallback(() => {
        const url =
            icallNotifications?.[SettingsKey.DIRECT_INCOMING_CALL]?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableBrowserSoundNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_ASSETS_SOUNDS_URL}/notification.mp3`;
    }, [icallNotifications, enableBrowserSoundNotification]);

    const dataSoundIchat = useCallback(() => {
        const url = ichatNotifications?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableBrowserChatNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_ASSETS_SOUNDS_URL}/notification.mp3`;
    }, [enableBrowserChatNotification, ichatNotifications]);

    useEffect(() => {
        ringAudio.current = NotificationService.notify(
            'sound_notification',
            dataSoundIcall(SettingsKey.CONTENT_CREATION),
        );
        ringLostAudio.current = NotificationService.notify(
            'sound_notification',
            dataSoundIcall(SettingsKey.LOST_OPPORTUNITY_CREATION),
        );
        chatAudio.current = NotificationService.notify('sound_notification', dataSoundIchat());
    }, [dataSoundIcall, dataSoundIchat]);

    const notify = useCallback(
        (
            title,
            body,
            redirectURL,
            enableBrowserSoundNotification,
            enableSystemTrayNotification,
            enableBrowserPushNotification,
            audioRef,
        ) => {
            const icon = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/icon-128.png`;

            if (enableBrowserSoundNotification) {
                audioRef.play();
            }

            if (enableSystemTrayNotification) {
                const action = (key) => (
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => providerRef.current.closeSnackbar(key)}
                        style={{ minWidth: '32px' }}
                    >
                        <SpeakerNotesOffIcon />
                    </Button>
                );
                NotificationService.notify('vms_notification', { title, action }, (data) => {
                    providerRef.current.enqueueSnackbar(data.title, {
                        variant: 'info',
                        persist: true,
                        action: data.action,
                    });
                });
            }

            if (enableBrowserPushNotification) {
                if (!('Notification' in window)) {
                    console.log('This browser does not support desktop notification');
                } else {
                    if (Notification.permission === 'granted') {
                        const notification = NotificationService.notify('push_notification', { title, body, icon });
                        notification.onclick = () => {
                            window.location.replace(redirectURL).focus();
                        };
                    } else {
                        Notification.requestPermission().then((permission) => {
                            if (permission === 'granted') {
                                const notification = NotificationService.notify('push_notification', {
                                    title,
                                    body,
                                    icon,
                                });
                                notification.onclick = () => {
                                    window.location.replace(redirectURL).focus();
                                };
                            }
                        });
                    }
                }
            }
        },
        [],
    );

    const lostNotify = useCallback(
        (data) => {
            const callOrigin = data?.callOrigin || 'service';
            notify(
                `${t('icalls.notifications.incomingLostOportunityInstanceNotification', { callOrigin })}`,
                `${data?.extraFields?.length ? data?.extraFields[0].value : ''}`,
                `${window.location.origin.toString()}/one-to-one?tab=3`,
                enableBrowserSoundLostNotification,
                enableSystemTrayLostNotification,
                enableBrowserPushLostNotification,
                ringLostAudio.current,
            );
        },
        [
            enableBrowserPushLostNotification,
            enableBrowserSoundLostNotification,
            enableSystemTrayLostNotification,
            t,
            notify,
        ],
    );

    const ichatNotify = useCallback(
        (data) => {
            notify(
                `${t('ichat.notifications.new')}`,
                '',
                `${window.location.origin.toString()}/ichat`,
                enableBrowserChatNotification,
                enableSystemTrayChatNotification,
                enableBrowserPushChatNotification,
                chatAudio.current,
            );
        },
        [enableBrowserChatNotification, enableSystemTrayChatNotification, enableBrowserPushChatNotification, t, notify],
    );

    const iCallNotify = useCallback(
        (data) => {
            const callOrigin = data?.callOrigin === 'GoogleCalendarAddon' ? 'Google Calendar' : 'service';
            notify(
                `${t('icalls.notifications.new', { callOrigin })}`,
                `${data?.guestName} - ${data?.guestEmail}`,
                `${window.location.origin.toString()}/one-to-one?tab=4`,
                enableBrowserSoundLostNotification,
                enableSystemTrayLostNotification,
                enableBrowserPushLostNotification,
                ringLostAudio.current,
            );
        },
        [
            enableBrowserPushLostNotification,
            enableBrowserSoundLostNotification,
            enableSystemTrayLostNotification,
            t,
            notify,
        ],
    );

    useContextLicenseAddOns();

    const removeDisabledMenu = (items, namespace) => {
        const result = [];
        for (const item of items) {
            if (
                !(item.path === '/billing' && license?.price === 0) &&
                !(typeof item.hide === 'function' ? item.hide(user?.role) : item.hide)
            ) {
                result.push(item);
            }
        }
        return result;
    };

    useEffect(() => {
        if (organizationId && chatWidget && !realtimeLoaded) {
            dispatch(initRealTimes({ organizationId: organizationId, firebase: config.firebase }));
        }
    }, [chatWidget, organizationId, realtimeLoaded, dispatch]);

    useEffect(() => {
        if (organization.licenseId) {
            dispatch(
                fetchOneLicenseAction({
                    id: organization.licenseId,
                }),
            );
        }
    }, [dispatch, organization.licenseId]);

    useEffect(() => {
        if (organizationId) {
            contentInstanceSocket.on(`content-instance:created:${organizationId}`, (data) => {
                const content = contents.find((c) => c.id === data.contentId);
                const contentName = content?.name || 'Content';

                notify(
                    `${t('icalls.contents.incomingContentInstanceNotification', { contentName })}`,
                    '',
                    `${window.location.origin.toString()}/one-to-one`,
                    enableBrowserSoundNotification,
                    enableSystemTrayNotification,
                    enableBrowserPushNotification,
                    ringAudio.current,
                );

                let incomingNewContentArr = localStorage?.getItem('incomingNewContent')
                    ? JSON.parse(localStorage?.getItem('incomingNewContent'))
                    : [];

                incomingNewContentArr =
                    incomingNewContentArr?.length && !incomingNewContentArr?.some((item) => item === data.contentId)
                        ? [...incomingNewContentArr, data.contentId]
                        : [data.contentId];
                localStorage.setItem('incomingNewContent', JSON.stringify(incomingNewContentArr));
            });
        }
        return () => {
            contentInstanceSocket.off(`content-instance:created:${organizationId}`);
        };
    }, [
        contents,
        enableBrowserPushNotification,
        enableBrowserSoundNotification,
        enableSystemTrayNotification,
        t,
        organizationId,
        notify,
    ]);

    useEffect(() => {
        async function initializeNotification() {
            if (isAuthenticated && user?.userId && settings?.length > 0 && !calendarNotificationInitialized) {
                if (newCalendar) {
                    CalendarNotificationService.initialize(
                        user.userId,
                        settings,
                        providerRef.current.enqueueSnackbar,
                        providerRef.current.closeSnackbar,
                    )
                        .then(() => {
                            CalendarNotificationService.proccessEvents();
                            dispatch(setInitialized(true));
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }
        }
        initializeNotification();
    }, [calendarNotificationInitialized, isAuthenticated, user?.userId, settings, newCalendar, dispatch]);

    useEffect(() => {
        if (isAuthenticated && organizationId) {
            const subscription = from(api.get(`organizations/${organizationId}/micro-fronts`))
                .pipe(
                    map(({ data }) => data),
                    catchError((error) => {
                        console.warn(
                            `Error fetching organization ${organizationId} micro-fronts configuration: ${error.message}`,
                        );
                        return of({});
                    }),
                )
                .subscribe((microFronts) => dispatch(setMicroFrontsConfig(microFronts)));

            return () => subscription.unsubscribe();
        }
    }, [dispatch, isAuthenticated, organizationId]);

    useEffect(() => {
        if (user) {
            iCallSocket.on(`lost-oportunity:created:${organizationId}`, (data) => {
                lostNotify(data);
            });
            iCallSocket.on(`icall:created:${user.userId}`, (data) => {
                lostNotify(data);
            });
            iCallSocket.on(`icall:newAppointment:${user.userId}`, (data) => {
                iCallNotify(data);
            });
            window.addEventListener('onlive-ichat-notification', ichatNotify);
        }
        return () => {
            window.removeEventListener('onlive-ichat-notification', ichatNotify);
            iCallSocket.off(`lost-oportunity:created:${organizationId}`);
            iCallSocket.off(`icall:created:${user?.userId}`);
            iCallSocket.off(`icall:newAppointment:${user?.userId}`);
        };
    }, [t, user, organizationId, iCallNotify, lostNotify, ichatNotify]);

    const cubeApi = useMemo(() => cubejsApi(), []);

    return (
        <CustomThemeProvider>
            <CubeProvider cubejsApi={cubeApi}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    autoHideDuration={3000}
                    maxSnack={3}
                    ref={providerRef}
                >
                    <MicroFrontContext>
                        <BrowserRouter>
                        <Routes>
                            <Route exact path="/login" element={<RedirectServiceLogin />} />
                            <Route exact path="/terms-conditions" element={<TermsConditions />} />
                            <Route exact path="/authorize/callback" element={<Authorize />} />
                            <Route exact path="/invitation/:token" element={<OAuthInvitation type={'invitation'} />} />
                            <Route exact path="/sign_up/:token" element={<OAuthInvitation type={'sign_up'} />} />
                            <Route exact path="/cloudstore/authorize" element={<CloudStoreAuthorize />} />
                            <Route exact path="/cloudstore/authorize/callback" element={<CloudStoreAuthorize />} />
                            <Route exact path="/oauth/callback" element={<SocialOAuthCallback />} />
                            <Route exact path="/loading" element={<LoadingSetting />} />
                            <Route path={''} element={<Main />}>
                                {menuEntries.map(({ namespace, items, roles }) => {
                                    const generalRoles = roles || [];
                                    return removeDisabledMenu(items, namespace).map(
                                        ({
                                            path,
                                            component,
                                            permissions,
                                            mainPermission,
                                            hiddenIfProfile,
                                            roles,
                                            childrenRoutes = null,
                                        }) => {
                                            const itemRoles = roles || [];
                                            return (
                                                <Route
                                                    key={path}
                                                    path={path}
                                                    element={
                                                        <ProtectedRoute
                                                            permissions={permissions}
                                                            hiddenIfProfile={hiddenIfProfile}
                                                            main={mainPermission}
                                                            roles={[...generalRoles, ...itemRoles]}
                                                        />
                                                    }
                                                >
                                                    <Route path={path} element={component}>
                                                        {childrenRoutes}
                                                    </Route>
                                                </Route>
                                            );
                                        },
                                    );
                                })}
                                <Route path="/status-list" element={<StatusList />} />
                                <Route path="/my-profile" element={<Profile />} />
                                <Route path="/my-profile/:integration_tab" element={<Profile />} />
                                <Route path="*" element={<Navigate to="/loading" />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                    </MicroFrontContext>
                </SnackbarProvider>
            </CubeProvider>
        </CustomThemeProvider>
    );
}

export default App;
