import { FormGroup, FormLabel } from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import CustomMenuItem from 'common/components/CustomMenuItem';
import CustomSelectValidator from 'common/components/CustomSelectValidators';
import CustomTextValidator from 'common/components/CustomTextValidators';
import PhoneInput from 'common/components/PhoneInput';
import RenderFormFields from 'common/features/renderForms/RenderFormFields';
import { getDynamicFields, getNestedData } from 'common/features/renderForms/utils';
import useFlexBoxStyles from 'common/flexBox';
import { useSettingValue } from 'common/hooks/useSettingValue';
import LayoutForm from 'common/layouts/LayoutForm';
import { useFormFields } from 'libs/hooksLib';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getContactsSavePending } from 'redux/slices/contactSlice';
import { getFormConfig } from 'redux/slices/formSlice';
import {
    cancelCloseNestedDialogAction,
    closeNestedDialogAction,
    getIsNestedDialogOnClose,
} from 'redux/slices/nestedDialogSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getLanguage } from 'redux/slices/settingSlice';

import { findCountryCodeByCountryName } from '../../../common/utils/countryList';

const ContactForm = ({ action, contact, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const isDrawerOnClose = useSelector(getIsNestedDialogOnClose);
    const savePending = useSelector(getContactsSavePending);
    const cancelConfirmTitle = t('user.userInviteForm.cancelConfirm');
    const cancelConfirmMessage = t('user.userInviteForm.cancelMessage');
    const formConfig = useSelector(getFormConfig);
    const organization = useSelector(getOrganization);
    const statusConfig = useSettingValue(SettingsKey.LEADS_STATUS) || null;
    const lang = useSelector(getLanguage);

    const initialForm = useMemo(() => {
        let status = contact?.status?.split('/')?.[0] || '';
        if (!statusConfig?.[status]) {
            status = '';
        }
        const subStatus = contact?.status?.split('/')?.[1] || '';
        const initial = {
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            status,
            subStatus,
        };
        const dynamic = getDynamicFields(formConfig, contact?.extra);
        return { ...initial, ...dynamic };
    }, [contact, formConfig, statusConfig]);
    const [fields, handleFieldChange, resetForm] = useFormFields(initialForm);

    const [subStatusConfig, setSubStatusConfig] = useState(statusConfig?.[fields.status]?.children || {});

    useEffect(() => {
        setSubStatusConfig(statusConfig?.[fields.status]?.children || {});
    }, [fields.status, statusConfig]);

    const userDidChange = useCallback(() => {
        return fields.name || fields.email || fields.phone;
    }, [fields]);

    const restart = useCallback(() => {
        resetForm({
            email: '',
        });
    }, [resetForm]);

    const finalCancel = useCallback(() => {
        restart();
        dispatch(closeNestedDialogAction());
        if (onCancel) {
            try {
                onCancel(null);
            } catch (e) {
                console.error(`Error executing cancel contact edition callback: ${e.message}`);
            }
        }
    }, [dispatch, restart, onCancel]);

    const manageCancel = useCallback(() => {
        if (!userDidChange()) {
            finalCancel();
        }
        setOpenCancelDlg(true);
    }, [userDidChange, finalCancel]);

    useEffect(() => {
        if (isDrawerOnClose) {
            manageCancel();
        }
    }, [isDrawerOnClose, manageCancel]);

    const [openCancelDlg, setOpenCancelDlg] = useState(false);

    const cancelDlg = () => {
        setOpenCancelDlg(false);
        dispatch(cancelCloseNestedDialogAction());
    };

    const cancel = (event) => {
        event.preventDefault();
        manageCancel();
    };

    function handleSubmit(event) {
        event.preventDefault();
        onSubmit({
            contactDto: {
                name: fields.name.trim(),
                email: fields.email.trim(),
                status: `${fields.status}${fields.subStatus ? `/${fields.subStatus}` : ''}`,
                phone: `${fields.phone}`,
                extra: getNestedData(formConfig, fields),
            },
            onSuccess: () => {
                enqueueSnackbar(contact.id ? t('contact.form.successUpdate') : t('contact.form.successCreate'), {
                    variant: 'success',
                });
                finalCancel();
            },
            onFailure: () => {
                enqueueSnackbar(t('app.common.error'), {
                    variant: 'error',
                });
            },
        });
    }

    const handleError = () => {
        enqueueSnackbar(t('user.userInviteForm.formError'), {
            variant: 'error',
        });
    };

    const defaultFields = useMemo(() => {
        const handleStatusChange = (event) => {
            const newStatus = event.target.value;
            const currentStatus = fields.status;
            handleFieldChange(event);

            if (currentStatus !== newStatus) {
                handleFieldChange({
                    target: {
                        name: 'subStatus',
                        value: '',
                    },
                });
            }
        };

        return [
            {
                name: 'name',
                component: (
                    <FormGroup key="name">
                        <FormLabel>{t('contact.name')}</FormLabel>
                        <CustomTextValidator
                            name="name"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.name}
                            onChange={handleFieldChange}
                            validators={['required']}
                            errorMessages={[t('user.userInviteForm.emailRequired')]}
                        />
                    </FormGroup>
                ),
            },
            {
                name: 'email',
                component: (
                    <FormGroup key="email">
                        <FormLabel>{t('contact.email')}</FormLabel>
                        <CustomTextValidator
                            name="email"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.email}
                            onChange={handleFieldChange}
                            validators={['required', 'isEmail']}
                            errorMessages={[
                                t('user.userInviteForm.emailRequired'),
                                t('user.userInviteForm.emailInvalid'),
                            ]}
                        />
                    </FormGroup>
                ),
            },
            {
                name: 'phone',
                component: (
                    <PhoneInput
                        key="phone"
                        value={fields.phone}
                        name="phone"
                        label={t('contact.phone')}
                        onChange={handleFieldChange}
                        defaultCountryCode={findCountryCodeByCountryName(organization?.country)}
                        hideCountryCode={false}
                        validators={['required', `matchRegexp:\\d*`]}
                        errorMessages={[t('app.common.required'), t('app.common.invalidInput.error')]}
                    />
                ),
            },
            {
                name: 'status',
                component: statusConfig && (
                    <FormGroup key="status">
                        <FormLabel>{t('iCalendar.appointmentForm.selectStatus')}</FormLabel>

                        <CustomSelectValidator
                            name="status"
                            select
                            size="small"
                            fullWidth={true}
                            value={fields.status || ' '}
                            onChange={handleStatusChange}
                            validators={['required']}
                            errorMessages={[t('app.common.required')]}
                            variant="outlined"
                        >
                            <CustomMenuItem value=" " disabled>
                                <em>{t('status.select.statusDefault')}</em>
                            </CustomMenuItem>
                            {Object.keys(statusConfig)?.map((key) => (
                                <CustomMenuItem key={key} value={key} noBorder={true}>
                                    {statusConfig[key].label?.[lang] ||
                                        statusConfig[key].label?.['en'] ||
                                        key.toUpperCase()}
                                </CustomMenuItem>
                            ))}
                        </CustomSelectValidator>
                    </FormGroup>
                ),
            },
            {
                name: 'subStatus',
                component: subStatusConfig && (
                    <FormGroup key="subStatus">
                        <FormLabel>{t('iCalendar.appointmentForm.selectSubStatus')}</FormLabel>
                        <CustomSelectValidator
                            name="subStatus"
                            select
                            size="small"
                            fullWidth={true}
                            value={fields.subStatus || ' '}
                            onChange={handleFieldChange}
                            disabled={!fields?.status}
                            validators={['required']}
                            errorMessages={[t('app.common.required')]}
                            variant="outlined"
                        >
                            <CustomMenuItem value=" " disabled>
                                <em>{t('status.select.subStatusDefault')}</em>
                            </CustomMenuItem>
                            {Object.keys(subStatusConfig).map((key) => (
                                <CustomMenuItem key={key} value={key} noBorder={true}>
                                    {subStatusConfig[key]?.label?.[lang] ||
                                        subStatusConfig[key].label?.['en'] ||
                                        key.toUpperCase()}
                                </CustomMenuItem>
                            ))}
                        </CustomSelectValidator>
                    </FormGroup>
                ),
            },
        ];
    }, [
        t,
        fields.name,
        fields.email,
        fields.phone,
        fields.status,
        fields.subStatus,
        handleFieldChange,
        organization?.country,
        statusConfig,
        subStatusConfig,
        lang,
    ]);

    return (
        <LayoutForm
            title={action}
            confirmDlg={{
                open: openCancelDlg,
                accept: finalCancel,
                cancel: cancelDlg,
                titleDlg: cancelConfirmTitle,
                messageDlg: cancelConfirmMessage,
            }}
        >
            <ValidatorForm
                onSubmit={handleSubmit}
                onError={handleError}
                className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden)}
            >
                <div
                    className={cx(
                        flexBox.flex,
                        flexBox.gap2,
                        flexBox.column,
                        flexBox.overflowXHidden,
                        flexBox.scrollbarNone,
                    )}
                >
                    <RenderFormFields
                        defaultFields={defaultFields}
                        formConfig={formConfig}
                        fields={fields}
                        handleFieldChange={handleFieldChange}
                    />
                </div>
                <div className={cx(flexBox.flex, flexBox.gap1, flexBox.justifyContentEnd, flexBox.paddingTop2)}>
                    <ActionButton disabled={savePending} onClick={cancel} color="default" fitWidth>
                        {t('multimedia.multimediaForm.cancel')}
                    </ActionButton>

                    <ActionButton
                        disabled={savePending}
                        color="primary"
                        type="submit"
                        autoFocus
                        fitWidth
                        spinner={savePending}
                    >
                        {t('game.gameActions.save')}
                    </ActionButton>
                </div>
            </ValidatorForm>
        </LayoutForm>
    );
};

export default ContactForm;
