import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormGroup, FormHelperText, Stack } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import { useFormFields } from 'libs/hooksLib';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldLabelI18n from './FormFieldLabelI18n';

const FormOptionValue = ({ option, index, onChange, error, onDelete }) => {
    const { t } = useTranslation();
    const { classes: flexBox } = useFlexBoxStyles();

    const initValue = useMemo(
        () => ({
            value: option.value,
            label: option.label || [{ languageCode: 'en', value: '' }],
        }),
        [option],
    );

    const [fields, handleFieldChange, resetForm] = useFormFields(initValue);

    useEffect(() => {
        resetForm(initValue);
    }, [initValue, resetForm]);

    const handleChange = (e) => {
        handleFieldChange(e);
        onChange?.({ value: e.target.value, label: fields.label });
    };

    const handleLabelChange = (e) => {
        const idx = fields.label.findIndex((l) => l.languageCode === 'en');
        const updatedLabels = [
            ...fields.label.slice(0, idx),
            { languageCode: 'en', value: e.target.value },
            ...fields.label.slice(idx + 1),
        ];
        handleFieldChange({
            target: {
                name: 'label',
                value: updatedLabels,
            },
        });
        onChange?.({ value: fields.value, label: updatedLabels });
    };

    const handleI18nLabelsSubmit = ({ name, value }, onSuccess) => {
        const updatedLabels = value.filter((item) => item.value !== '');
        handleFieldChange({
            target: {
                name,
                value: updatedLabels,
            },
        });
        onChange?.({ value: fields.value, label: updatedLabels });
        onSuccess();
    };

    return (
        <Stack direction="row" alignItems="flex-start" spacing={2}>
            <FormGroup className={flexBox.w40}>
                <CustomTextValidator
                    name="value"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-link"
                    value={fields.value}
                    disabled={error !== -1 && error !== index}
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={[t('profile.settings.viewerExtraField.fieldForm.nameRequired')]}
                />
                {error === index ? (
                    <FormHelperText error className={flexBox.paddingLeft2}>
                        {t('form.formField.optionValueUnic')}
                    </FormHelperText>
                ) : null}
            </FormGroup>

            <FormFieldLabelI18n
                className={flexBox.w40}
                value={fields.label.find((l) => l.languageCode === 'en')?.value || ''}
                handleLabelChange={handleLabelChange}
                handleI18nLabelsSubmit={handleI18nLabelsSubmit}
                labels={fields.label}
            />

            <div className={flexBox.grow1}></div>

            <CustomTooltip arrow title={t('contact.remove')} placement={'bottom'}>
                <CustomIconButton aria-label="delete" onClick={onDelete} size="small" className={flexBox.marginTop05}>
                    <DeleteOutlineOutlinedIcon />
                </CustomIconButton>
            </CustomTooltip>
        </Stack>
    );
};

export default FormOptionValue;
