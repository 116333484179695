import { makeStyles } from 'tss-react/mui';

const useStylesForm = makeStyles()((theme) => {
    return {
        root: {
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: theme.spacing(70),
            padding: theme.spacing(6, 9, 5),
            width: '40vw',
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(7.5, 11, 6),
                maxHeight: '1300px',
                maxWidth: theme.spacing(100),
                width: '35vw',
            },
            [theme.breakpoints.down('mg')]: {
                padding: theme.spacing(6),
                width: '60vw',
            },
            [theme.breakpoints.down('ms')]: {
                padding: theme.spacing(4),
                width: '80vw',
            },
        },
        notFixWidth: {
            width: 'auto',
            [theme.breakpoints.up('xl')]: {
                width: '35vw',
            },
        },
        formContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },

        spinner: {
            zIndex: 10,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            position: 'absolute',
        },
        formArea: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflowX: 'hidden',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },

        headerContainer: {},

        header: {
            fontSize: theme.typography.fontSize * 1.3,
            fontWeight: theme.typography.fontWeightMedium,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        headerTop: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            paddingBottom: theme.spacing(4),
            '& > svg': {
                cursor: 'pointer',
                fontSize: theme.spacing(1.75),
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('xl')]: {
                    fontSize: theme.spacing(2.5),
                },
            },
            '& > .MuiLink-root': {
                marginRight: 0,
                marginLeft: 'auto',
            },
            [theme.breakpoints.up('xl')]: {
                paddingBottom: theme.spacing(5),
            },
        },
        assetTitle: {
            fontSize: theme.spacing(1.5),
            textTransform: 'capitalize',
            fontWeight: 500,
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(2),
            },
        },

        title: {
            fontSize: theme.spacing(3),
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(3.75),
            },
        },
        subTitle: {
            fontSize: theme.spacing(2),
            color: '#9D9D9D',
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(2.25),
            },
        },
        divider: {
            height: theme.spacing(4.5),
            margin: theme.spacing(0, 3.5),
        },
        headerNavigation: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
            marginBottom: theme.spacing(3),
            [theme.breakpoints.up('xl')]: {
                marginBottom: theme.spacing(4),
            },
        },
        navLine: {
            position: 'absolute',
            width: '100%',
            background: theme.palette.secondary.medium,
            top: '13px',
            zIndex: 1,
            [theme.breakpoints.up('xl')]: {
                top: '16px',
            },
        },

        navButton: {
            background: theme.palette.background.paper,
            zIndex: 2,
            borderRadius: theme.spacing(3),
            textTransform: 'uppercase',
            border: `1px solid ${theme.palette.secondary.medium}`,
            color: '#6E6E6E',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 400,
            fontSize: theme.spacing(2),
            width: 'fit-content',
            padding: theme.spacing(0, 1.5),
            cursor: 'pointer',
            height: theme.spacing(3),
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(0, 2),
                height: theme.spacing(4),
                fontSize: theme.spacing(2),
            },
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(0, 1.5),
                height: theme.spacing(3),
                fontSize: theme.spacing(1.75),
            },
            [theme.breakpoints.down('ms')]: {
                padding: theme.spacing(0, 1.25),
                height: theme.spacing(2.75),
                fontSize: theme.spacing(1.75),
            },
        },

        navActive: {
            borderWidth: '2px',
            color: '#000000',
            fontWeight: 'bold',
            borderColor: '#000',
        },

        textArea: {
            '& .MuiInputBase-root': {
                borderRadius: theme.spacing(2),
            },
        },

        textField: {
            '& .MuiInputBase-root': {
                background: '#FFFF',
                borderRadius: theme.spacing(2),
                [theme.breakpoints.up('xl')]: {
                    height: 'auto',
                    borderRadius: theme.spacing(2.5),
                },

                '& > fieldset': {
                    borderColor: '#E2E2E2',
                },

                '& > input': {
                    padding: theme.spacing(0, 1),
                    height: '100%',
                    fontSize: '12px',
                    fontWeight: '500',
                    position: 'relative',
                    '&:focus': {
                        borderRadius: 5,
                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: '14px',
                    },
                },
            },
        },

        inputColor: {
            '& input': {
                fontFamily: theme.typography.fontFamily,
                height: theme.spacing(4.5),
                width: 'calc(100% - 18px)',
                borderRadius: theme.spacing(4.5),
                padding: theme.spacing(0, 1),
                fontSize: '12px',
                border: '1px solid #E2E2E2',
                fontWeight: '500',
                position: 'relative',
                background: '#FFFF',
                '&:focus-visible ': {
                    borderColor: '#FF0000 !important',
                },
                [theme.breakpoints.up('xl')]: {
                    width: 'calc(100% - 20px)',
                },
            },
            '&:focus-visible ': {
                borderColor: '#FF0000 !important',
            },
        },

        content: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            flexGrow: 1,
        },
        contentSeparator: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            height: '100%',
        },
        formControl: {
            width: '100%',
            paddingTop: theme.spacing(1),
        },
        formGroup: {
            marginTop: theme.spacing(2.5),
            '& >label': {
                textTransform: 'uppercase',
                color: '#000',
                fontWeight: 500,
                fontSize: theme.spacing(1.5),
                marginBottom: theme.spacing(0.5),
                [theme.breakpoints.up('xl')]: {
                    fontSize: theme.spacing(2),
                    marginBottom: theme.spacing(0.75),
                },
            },
        },

        checkBoxLabel: {
            textTransform: 'uppercase',
            color: '#000',
            fontWeight: 400,
            fontSize: theme.spacing(1.5),
        },

        formButton: {
            textTransform: 'none',
            width: '100%',
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.fontSize,
        },

        formButtons: {
            paddingBottom: theme.spacing(0.25),
            width: '100%',
            background: theme.palette.background.paper,
        },

        bottomContent: {
            paddingTop: theme.spacing(2),
        },

        actions: {
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        cancelButton: {
            textTransform: 'uppercase',
            border: '1px solid #000',
            borderRadius: theme.spacing(4),
            fontSize: theme.spacing(1.5),
            height: theme.spacing(4),
            fontWeight: 'bold',
            background: theme.palette.background.paper,
            color: '#000',
            padding: 0,
            '&:hover': {
                backgroundColor: '#DEDEDE',
            },
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(5),
                borderRadius: theme.spacing(5),
                fontSize: '14px',
            },
            '& span': {
                margin: theme.spacing(0, 4),
                [theme.breakpoints.up('xl')]: {
                    margin: theme.spacing(0, 6),
                },
            },
        },

        proceedButton: {
            borderRadius: theme.spacing(4),
            fontSize: theme.spacing(1.5),
            height: theme.spacing(4),
            textTransform: 'uppercase',
            fontWeight: 'bold',
            background: theme.palette.background.red,
            color: theme.palette.background.paper,
            position: 'relative',
            padding: 0,
            '&:hover': {
                color: '#FFFF',
                backgroundColor: '#000',
            },
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(5),
                borderRadius: theme.spacing(5),
                fontSize: '14px',
            },
            '& span': {
                margin: theme.spacing(0, 4),
                [theme.breakpoints.up('xl')]: {
                    margin: theme.spacing(0, 6),
                },
            },
        },

        topActions: {
            display: 'flex',
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.up('xl')]: {
                paddingBottom: theme.spacing(5),
            },
        },

        uploadImageButton: {
            padding: theme.spacing(0, 3),
            textTransform: 'uppercase',
            border: `1px solid ${theme.palette.secondary.dark}`,
            backgroundColor: theme.palette.secondary.light,
            display: 'flex',
            alignItems: 'center',
            height: theme.spacing(4.5),
            borderRadius: theme.spacing(4.5),
            fontSize: '12px',
            '& > .MuiButton-label': {
                display: 'flex',
                flexDirection: 'column',
            },
            '& > *': {
                color: theme.palette.secondary.medium,
            },
            '& svg': {
                fontSize: theme.spacing(1.5) + '!important',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                '& > *': {
                    color: theme.palette.background.paper,
                },
            },
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(0, 4),
                '& svg': {
                    fontSize: theme.spacing(2) + '!important',
                },
            },
        },

        customUpload: {
            backgroundColor: theme.palette.background.paper,
            fontWeight: 500,
            '& > *': {
                color: '#000',
            },
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                border: `1px solid #6E6E6E`,
                '& > *': {
                    color: '#000',
                },
            },
        },

        addAssetsButton: {
            textTransform: 'uppercase',
            border: `1px solid ${theme.palette.secondary.dark}`,
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            height: theme.spacing(3.75),
            borderRadius: theme.spacing(4.5),
            fontSize: theme.spacing(1.25),
            padding: theme.spacing(0, 2.5),
            marginLeft: theme.spacing(3.5),
            '& > .MuiButton-label': {
                display: 'flex',
                flexDirection: 'column',
            },
            '& > *': {
                color: theme.palette.background.paper,
            },
            '& svg': {
                fontSize: theme.spacing(2.25),
            },
            '&:hover': {
                backgroundColor: '#000',
                '& > *': {
                    color: theme.palette.background.paper,
                },
            },
            '&:disabled': {
                backgroundColor: '#E2E2E2',
            },
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(0, 3),
                marginLeft: theme.spacing(4.25),
                fontSize: theme.spacing(1.75),
                height: theme.spacing(4.25),
                '& svg': {
                    fontSize: theme.spacing(3),
                },
            },
        },

        imagesContainer: {
            paddingTop: theme.spacing(1),
            minHeight: theme.spacing(17),
            '& img': {
                height: '100%',
                width: 'auto',
            },
        },
        imagesComplement: {
            border: `1px solid ${theme.palette.secondary.dark}`,
            backgroundColor: theme.palette.secondary.light,
            opacity: 0.5,
            borderRadius: '10px',
            height: '100%',
        },
        imageContainer: {
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: '10px',
            overflow: 'hidden',
            height: '90px',
        },

        switchMargin: {
            margin: theme.spacing(0.5, 1.25),
            padding: 0,
        },

        imageContainerBig: {
            height: '120px',
        },

        imagesRecommendation: {
            opacity: 0.5,
            fontSize: theme.typography.fontSize * 0.9,
            marginTop: theme.spacing(1),
        },
        checkbox: {
            height: '25px',
        },
        resetPassword: {
            minWidth: '280px',
        },

        formIntegration: {
            width: '100%',
            // paddingTop: '20px',
        },

        mr10px: {
            marginRight: '10px',
        },

        mb10px: {
            marginBottom: '10px',
        },
        mt10px: {
            marginTop: '10px',
        },

        dNone: {
            display: 'none',
        },

        mb1: {
            marginBottom: theme.spacing(1),
        },

        ml1: {
            marginLeft: theme.spacing(1),
        },
        ml2: {
            marginLeft: theme.spacing(2),
        },
        mr1: {
            marginRight: theme.spacing(1),
        },
        mr2: {
            marginRight: theme.spacing(2),
        },
        mt1: {
            marginTop: theme.spacing(1),
        },
        mt3: {
            marginTop: theme.spacing(3),
        },
        w100pct: {
            width: '100%',
        },
        h100vh: {
            height: '100vh',
        },
        p1: {
            padding: theme.spacing(1) + 'px !important',
        },
        p2: {
            padding: theme.spacing(2),
        },
        p0: {
            padding: 0,
        },
        noWrap: {
            flexWrap: 'nowrap',
        },
        fWrap: {
            flexWrap: 'wrap',
        },
        jcSb: {
            justifyContent: 'space-between',
        },
        pb3: {
            paddingBottom: theme.spacing(3),
        },
        mt2: {
            marginTop: theme.spacing(2),
        },
        mt5: {
            marginTop: theme.spacing(5),
        },
        minWidth50pct: {
            minWidth: '50%',
        },
        mt8: {
            marginTop: theme.spacing(8),
        },
        fg1: {
            flexGrow: 1,
        },
        dFlex: {
            display: 'flex',
        },
        alignCent: {
            alignItems: 'center',
        },
        justContCent: {
            justifyContent: 'center',
        },
        mW80: {
            maxWidth: theme.spacing(80),
        },
        w32: {
            width: theme.spacing(32),
            [theme.breakpoints.up('xl')]: {
                width: theme.spacing(40),
            },
        },
        w6: {
            width: theme.spacing(6),
            [theme.breakpoints.up('xl')]: {
                width: theme.spacing(8),
            },
        },
        scroll: {
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        scrollForm: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },

        adjustButtons: {
            marginLeft: '-8px',
            marginRight: '24px',
        },
        assetBack: {
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.up('xl')]: {
                paddingBottom: theme.spacing(2.5),
            },
        },
        accordion: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '& .MuiAccordionSummary-root': {
                // flexDirection: 'row-reverse',
                padding: 0,
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: '30px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0px',
            },
            '&::before': {
                // height: 0,
                borderBottom: '1px solid #E9E9E9',
            },
        },

        menuAccordion: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderBottom: '2px solid #E9E9E9',
            '& .MuiAccordionSummary-root': {
                padding: theme.spacing(0, 1),
                minHeight: theme.spacing(5),
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: theme.spacing(5),
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: theme.spacing(1, 0),
            },
            '& .MuiAccordionSummary-content ': {
                margin: theme.spacing(1, 0),
            },
            '& .MuiAccordionDetails-root': {
                padding: 0,
            },
            '&::before': {
                height: 0,
            },
        },

        customSummary: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            '& >label': {
                textTransform: 'uppercase',
                color: '#000',
                fontWeight: 500,
                fontSize: theme.spacing(1.5),
                marginBottom: theme.spacing(0.5),
                [theme.breakpoints.up('xl')]: {
                    fontSize: theme.spacing(2),
                    marginBottom: theme.spacing(0.75),
                },
            },
        },

        accordionIconButton: {
            minWidth: theme.spacing(3),
            borderRadius: '50%',
            border: '1px solid #DEDEDE',
            height: theme.spacing(3),
            width: theme.spacing(3),
            color: '#000',
            marginRight: theme.spacing(1),
            '& svg': {
                fontSize: theme.spacing(2),
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: theme.spacing(3.5),
                height: theme.spacing(3.5),
                width: theme.spacing(3.5),
                marginRight: theme.spacing(1.2),
                '& svg': {
                    fontSize: theme.spacing(2.5),
                },
            },
        },

        accordionIcon: {
            fontSize: theme.spacing(3),
            color: 'black',
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(3.75),
            },
        },

        flexRow: {
            flexDirection: 'row !important',
        },

        heading: {
            color: 'black',
            fontWeight: '500',
            fontSize: theme.spacing(1.5),
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(2),
            },
        },
        textFieldSelectVideo: {
            '& .MuiInputBase-root': {
                '&:before': {
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#E2E2E2',
                },
            },
        },
        textNoWrap: {
            whiteSpace: 'nowrap',
        },
        mt0: {
            marginTop: 0,
        },
    };
});

export default useStylesForm;
