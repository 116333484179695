import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from '@mui/material';
import useColorsStyles from 'common/colors';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { cleanContent } from 'common/utils/cleanContent';
import { useTranslation } from 'react-i18next';
import { renderFields } from './ExtraFieldsRender';

const ExtraFieldsCard = (props) => {
    const { fields, onEdit, onDelete, disabled, showEdit = true } = props;
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();
    const { classes: colors } = useColorsStyles();

    if (!fields) return null;

    return (
        <Stack
            direction="column"
            className={cx(
                flexBox.relative,
                flexBox.padding21,
                onlive.borderSecondaryDark,
                flexBox.borderRadius1,
                flexBox.relative,
                disabled && colors.bgLightGray,
            )}
        >
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className={cx(flexBox.absolute, flexBox.right1, flexBox.top1)}
                spacing={1}
            >
                {onEdit && showEdit ? (
                    <CustomTooltip arrow title={t('actions.edit')} placement="bottom">
                        <CustomIconButton id="onlive-button-edit" size="small" onClick={onEdit} disabled={disabled}>
                            <EditOutlinedIcon />
                        </CustomIconButton>
                    </CustomTooltip>
                ) : null}
                {onDelete ? (
                    <CustomTooltip arrow title={t('contact.remove')} placement={'bottom'}>
                        <CustomIconButton aria-label="delete" onClick={onDelete} size="small" disabled={disabled}>
                            <DeleteOutlineOutlinedIcon />
                        </CustomIconButton>
                    </CustomTooltip>
                ) : null}
            </Stack>

            <Stack direction="column" spacing={1}>
                <div dangerouslySetInnerHTML={{ __html: cleanContent(renderFields(fields)) }}></div>
            </Stack>
        </Stack>
    );
};

export default ExtraFieldsCard;
