// const EVENT_TOTAL_ELAPSED_TIME = "Event.totalElapsedTime";
const EVENT_ID = "Event.eventid";
const EVENT_TOTAL_TIME_SPENT = "Event.totalTimeSpent";
const EVENT_TOTAL_DISPLAY_TIME = "Event.totalDisplayTime";
const EVENT_AVG_TIME_PER_USER = "Event.averageTimePerUser";
const EVENT_ITEMS_ON_CART = "Event.itemsOnCart";
const EVENT_TOTAL_LIVE_TIME = "Event.totalLiveTime";

const EventFields = {
    // EVENT_TOTAL_ELAPSED_TIME,
    EVENT_ID,
    EVENT_TOTAL_TIME_SPENT,
    EVENT_TOTAL_DISPLAY_TIME,
    EVENT_AVG_TIME_PER_USER,
    EVENT_ITEMS_ON_CART,
    EVENT_TOTAL_LIVE_TIME,
}

export default EventFields;