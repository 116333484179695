import { FormControl, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import DateInput from './DateInput';

const BasicDateRangePicker = (props) => {
    const { onValue, dateRange, type, startDate, endDate, maxDaysAllowed, maxDate = new Date() } = props;

    const { t } = useTranslation();

    const [dateRangeValue, setDateRangeValue] = React.useState({
        startDate: startDate ? new Date(startDate) : moment().startOf('week').toDate(),
        endDate: endDate ? new Date(endDate) : moment().endOf('day').toDate(),
    });

    const onChange = (pos, value) => {
        setDateRangeValue((range) => {
            const newRange = { ...range, ...(pos === 'start' ? { startDate: value } : { endDate: value }) };

            if (maxDaysAllowed) {
                // Ensure endDate respects the maxDaysAllowed range
                if (pos === 'start' && value) {
                    const maxEndDate = moment(value).add(maxDaysAllowed, 'days').toDate();
                    if (newRange.endDate > maxEndDate) {
                        newRange.endDate = maxEndDate; // Reset endDate if it exceeds the range
                    }
                }

                // Ensure startDate respects the maxDaysAllowed range
                if (pos === 'end' && value) {
                    const minStartDate = moment(value).subtract(maxDaysAllowed, 'days').toDate();
                    if (newRange.startDate < minStartDate) {
                        newRange.startDate = minStartDate; // Reset startDate if it exceeds the range
                    }
                }
            }

            if (onValue) {
                onValue([newRange.startDate, newRange.endDate]);
            }
            return newRange;
        });
    };

    useEffect(() => {
        if (Array.isArray(dateRange)) {
            setDateRangeValue({
                startDate: dateRange[0],
                endDate: dateRange[1],
            });
        }
    }, [dateRange]);

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <FormControl size="small" variant="outlined" style={{ width: type === 'date-time' ? '190px' : '140px' }}>
                <DateInput
                    id="basic-date-range-start-date"
                    type={type}
                    value={dateRangeValue.startDate}
                    onChange={(newValue) => {
                        onChange('start', newValue);
                    }}
                    minDate={startDate ? new Date(startDate) : ''}
                    maxDate={dateRangeValue.endDate ? moment(dateRangeValue.endDate).toDate() : maxDate} // Ensure startDate <= endDate
                    minT
                />
            </FormControl>

            <Typography fontWeight={500}> {t('services.form.dateTo')} </Typography>

            <FormControl size="small" variant="outlined" style={{ width: type === 'date-time' ? '190px' : '140px' }}>
                <DateInput
                    id="basic-date-range-end-date"
                    type={type}
                    value={dateRangeValue.endDate}
                    onChange={(newValue) => {
                        onChange('end', newValue);
                    }}
                    minDate={dateRangeValue.startDate ? moment(dateRangeValue.startDate).toDate() : undefined} // Ensure endDate >= startDate
                    maxDate={
                        dateRangeValue.startDate && maxDaysAllowed
                            ? moment(dateRangeValue.startDate).add(maxDaysAllowed, 'days').toDate()
                            : undefined // Limit endDate to maxDaysAllowed from startDate
                    }
                />
            </FormControl>
        </Stack>
    );
};

export default BasicDateRangePicker;
