import CallCenter from 'app/widget';
import QueueManager from 'app/widget/queueManager';

import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import IChatRequests from 'app/widget/IChatRequests';
import { loadMeetManager } from 'app/widget/MeetManager';
import PoolManager from 'app/widget/poolManager';
import CustomDialog from 'common/components/PopoversAndDialogs/CustomDialog';
import NestedDialog from 'common/components/nested-dialog/NestedDialog';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { useSettingValue } from 'common/hooks/useSettingValue';
import TopBar from 'components/header/TopBar';
import Sidebar from 'components/menu/Sidebar';
import { useEffect } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUninstallableApplicationsAction, getUninstallableApplications } from 'redux/slices/applicationSlice';
import { getUser, userHasAuthenticated } from 'redux/slices/authSlice';
import {
    getRealtimeAgents,
    getRealtimeLoaded,
    getRealtimePools,
    getRealtimeQueues,
    loadIchat,
    setMeetLoaded,
} from 'redux/slices/realtimeSlice';
import { getLoadedSettings, getSettings } from 'redux/slices/settingSlice';
import { getContentDialogMode } from '../redux/slices/appSlice';
import Router from './Router';
import useStylesLayout from './styles/useStylesLayout.styles';

const Layout = () => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const settings = useSelector(getSettings);
    const { classes, cx } = useStylesLayout();
    const realtimeQueues = useSelector(getRealtimeQueues);
    const realtimeAgents = useSelector(getRealtimeAgents);
    const realtimePools = useSelector(getRealtimePools);
    const realtimeLoaded = useSelector(getRealtimeLoaded);
    const loadedSettings = useSelector(getLoadedSettings);
    const isAuthenticated = useSelector(userHasAuthenticated);
    const uninstallableApplications = useSelector(getUninstallableApplications);
    const iCallApp = uninstallableApplications.find((d) => d.name === 'iCall');
    const ichatApp = uninstallableApplications.find((d) => d.name === 'ichat');
    const iCallVersion = useSettingValue(SettingsKey.ICALL_VERSION);
    const ichatVersion = useSettingValue(SettingsKey.ICHAT_VERSION);
    const isAuthorize = useAuthLicenseAddOns();
    const iChat = isAuthorize(['oneToOne.iChat']);
    const statusSetting = useSettingValue(SettingsKey.LEADS_STATUS);
    const contentDialogMode = useSelector(getContentDialogMode);

    const icallVersionActive =
        (['stable', ''].includes(iCallVersion?.toLowerCase())
            ? iCallApp?.stableVersion
            : iCallVersion?.toLowerCase() === 'testing'
            ? iCallApp?.testingVersion
            : iCallVersion) || iCallApp?.stableVersion;

    const ichatVersionActive =
        (['stable', ''].includes(ichatVersion?.toLowerCase())
            ? ichatApp?.stableVersion
            : ichatVersion?.toLowerCase() === 'testing'
            ? ichatApp?.testingVersion
            : ichatVersion) || ichatApp?.stableVersion;

    useEffect(() => {
        dispatch(fetchUninstallableApplicationsAction());
    }, [dispatch]);

    useEffect(() => {
        if (iChat && user && ichatApp)
            dispatch(
                loadIchat(user, ichatVersionActive, {
                    leadsStatus: statusSetting,
                }),
            );
    }, [dispatch, iChat, user, ichatApp, ichatVersionActive, statusSetting]);

    useEffect(() => {
        if (isAuthenticated && user && window.top === window.self && loadedSettings && iCallApp) {
            loadMeetManager(user.organizationId, () => dispatch(setMeetLoaded(true)), icallVersionActive);
        }
    }, [
        isAuthenticated,
        user,
        settings,
        uninstallableApplications,
        dispatch,
        iCallApp,
        icallVersionActive,
        loadedSettings,
    ]);

    const realtimeItems = () => {
        if (isAuthenticated && realtimeLoaded && window.self === window.top) {
            return (
                <>
                    <QueueManager realtimeQueues={realtimeQueues} />
                    <PoolManager realtimePools={realtimePools} />
                    <CallCenter realtimeAgents={realtimeAgents} />
                </>
            );
        }
        return null;
    };

    return (
        <ScreenClassRender
            render={(screenClass) => {
                return (
                    <div className={classes.root}>
                        {realtimeItems()}
                        <IChatRequests />
                        <TopBar className={contentDialogMode ? 'contentDialogMode' : undefined} />
                        <div className={cx(classes.row, contentDialogMode && 'contentDialogMode')}>
                            <Sidebar className={contentDialogMode ? 'contentDialogMode' : undefined} />
                            <div className={cx(classes.content, contentDialogMode && 'contentDialogMode')}>
                                <Router />
                                <CustomDialog />
                                <NestedDialog />
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default Layout;
