import { createSlice } from '@reduxjs/toolkit';
import api, { communicationApi } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'installations';

export const installationSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        installations: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        pendingEnabledSave: false,
        pendingDownload: false,
        total: 0,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        addInstallation: (state, action) => {
            state.savePending = false;
            state.installations = [action.payload, ...state.installations];
        },
        editInstallation: (state, action) => {
            state.savePending = false;
            const index = state.installations.findIndex((installation) => installation.id === action.payload.id);
            if (index !== -1) {
                state.installations = [
                    ...state.installations.slice(0, index),
                    action.payload,
                    ...state.installations.slice(index + 1),
                ];
            }
        },
        editEnabled: (state, action) => {
            state.pendingEnabledSave = false;
            const index = state.installations.findIndex((installation) => installation.id === action.payload.id);
            if (index !== -1) {
                state.installations = [
                    ...state.installations.slice(0, index),
                    {
                        ...state.installations[index],
                        enabled: action.payload.enabled,
                    },
                    ...state.installations.slice(index + 1),
                ];
            }
        },
        editDependencyVersion: (state, action) => {
            state.savePending = false;
            const index = state.installations.findIndex((installation) => installation.id === action.payload.id);
            if (index !== -1) {
                state.installations = [
                    ...state.installations.slice(0, index),
                    {
                        ...state.installations[index],
                        dependencyVersions: action.payload.dependencyVersions,
                    },
                    ...state.installations.slice(index + 1),
                ];
            }
        },
        deleteInstallation: (state, action) => {
            state.deletePending = false;
            const index = state.installations.findIndex((installation) => installation.id === action.payload.id);
            if (index !== -1) {
                state.installations = [...state.installations.slice(0, index), ...state.installations.slice(index + 1)];
            }
        },
        setInstallations: (state, action) => {
            state.pending = false;
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.installations = action.payload.items || action.payload;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        startEnabledSave: (state, action) => {
            state.pendingEnabledSave = true;
        },
        stopEnabledSave: (state, action) => {
            state.pendingEnabledSave = false;
        },
        startDownload: (state, action) => {
            state.pendingDownload = true;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
        },
    },
});

export const {
    start,
    stop,
    setInstallations,
    setError,
    addInstallation,
    editInstallation,
    editEnabled,
    editDependencyVersion,
    deleteInstallation,
    startDelete,
    startSave,
    setSaveError,
    stopSave,
    startEnabledSave,
    stopEnabledSave,
    startDownload,
    stopDownload,
} = installationSlice.actions;

export const getInstallations = (state) => state.installations.installations;
export const getInstallationsTotal = (state) => state.installations.total;
export const getInstallationsPending = (state) => state.installations.pending;
export const getDeletePending = (state) => state.installations.deletePending;
export const getDeleteId = (state) => state.installations.deleteId;
export const getInstallationsError = (state) => state.installations.error;
export const getSavePending = (state) => state.installations.savePending;
export const getInstallationById = (id) => (state) => state.installations.installations.find((item) => item.id === id);
export const getPendingEnabledSave = (state) => state.installations.pendingEnabledSave;
export const getPendingDownload = (state) => state.installations.pendingDownload;

export const fetchInstallationsAction =
    ({ organizationId, filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get(`${resource}/management/${organizationId}` + query)
                .then((response) => {
                    dispatch(setInstallations(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addInstallationAction =
    ({ dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(resource, dataDto)
                .then((response) => {
                    dispatch(addInstallation(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editInstallationAction =
    ({ id, dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put(`${resource}/${id}`, dataDto)
                .then((response) => {
                    dispatch(editInstallation(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const deleteInstallationAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete(resource + '/' + id)
                .then((response) => {
                    dispatch(deleteInstallation(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export const editInstallationEnabledAction = (id, enabled, success, failure) => async (dispatch) => {
    try {
        dispatch(startEnabledSave());
        api.patch(`${resource}/enabled/` + id, enabled)
            .then((response) => {
                dispatch(editEnabled(response.data));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                dispatch(stopEnabledSave());
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopEnabledSave());
        return console.error(e.message);
    }
};

export const fetch12MChannelSubscribersCsvReportAction =
    ({ param, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            const { channelId } = param;
            const response = await communicationApi.get(
                `subscriptions/report/one-to-many-channel-subscribers/${channelId}`,
                { params: queryParams },
            );

            if (onSuccess) {
                const headerKey = 'Content-Disposition';
                let fileName = (response.headers[headerKey] || response.headers[headerKey.toLowerCase()]).split(
                    'filename=',
                )[1];
                onSuccess(response.data, fileName);
            }

            dispatch(stopDownload());
            return response;
        } catch (e) {
            dispatch(stopDownload());
            if (onFailure) {
                onFailure(e);
            }
            return console.error(e.message);
        }
    };

export default installationSlice.reducer;
