import { FormControl, FormHelperText, MenuItem, Stack } from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import { useDateRangeType } from 'common/constants/dateRangeType';
import DateRange from 'common/enums/dateRange';
import BasicDateRangePicker from 'common/features/filters/components/BasicDateRangePicker';
import useFlexBoxStyles from 'common/flexBox';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FiltersContext } from '../FiltersContext';

const CustomDateRange = (props) => {
    const { maxDate, size, maxDaysAllowed } = props;

    const { t } = useTranslation();

    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { fullWidthFilter } = useContext(FiltersContext);
    const rangeValues = useDateRangeType({ maxDaysAllowed });
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const date = [startDate, endDate];
    const range = searchParams.get('range');

    // Get default range based on maxDaysAllowed
    const getDefaultRange = () => {
        if (!maxDaysAllowed) return DateRange.ALL;
        if (range) return range;
        // Get first available range from filtered options
        return rangeValues[0]?.id || DateRange.CUSTOM;
    };

    const [selected, setSelected] = useState(getDefaultRange());
    const [dateRange, setDateRange] = useState(date);

    useEffect(() => {
        if (range && rangeValues.some((r) => r.id === range)) {
            setSelected(range);
        }
    }, [range, rangeValues]);

    useEffect(() => {
        if (startDate) {
            const date = [new Date(startDate), new Date(endDate)];
            setDateRange(date);
        }
    }, [startDate, endDate]);

    const onValueRange = (value) => {
        onSubmit({ startDate: value[0], endDate: value[1] }, DateRange.CUSTOM);
    };

    const handleTypeChange = (e) => {
        let start = '';
        let end = '';
        switch (e.target.value) {
            case DateRange.ALL:
                start = '';
                end = '';
                break;
            case DateRange.TODAY:
                start = moment().startOf('day').toDate();
                end = moment().endOf('day').toDate();
                break;
            case DateRange.LAST_7_DAYS:
                start = moment().subtract(6, 'day').startOf('day').toDate();
                end = moment().endOf('day').toDate();
                break;
            case DateRange.LAST_30_DAYS:
                start = moment().subtract(29, 'day').startOf('day').toDate();
                end = moment().endOf('day').toDate();
                break;
            case DateRange.LAST_90_DAYS:
                start = moment().subtract(89, 'day').startOf('day').toDate();
                end = moment().endOf('day').toDate();
                break;
            case DateRange.CUSTOM:
                start = moment().startOf('week').toDate();
                end = moment().endOf('day').toDate();
                break;
            default:
                break;
        }

        if (e.target.value === DateRange.CUSTOM) {
            setDateRange([start, end]);
        }

        onSubmit({ startDate: start, endDate: end }, e.target.value);
        setSelected(e.target.value);
    };

    const onSubmit = (value, type) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        if (value?.startDate) {
            updatedSearchParams.set('range', type);
            updatedSearchParams.set('startDate', value.startDate.toISOString());
            updatedSearchParams.set('endDate', value.endDate ? value.endDate.toISOString() : '');
            setSearchParams(updatedSearchParams.toString());
        } else {
            updatedSearchParams.delete('range');
            updatedSearchParams.delete('startDate');
            updatedSearchParams.delete('endDate');
            setSearchParams(updatedSearchParams.toString());
        }
    };

    return (
        <Stack
            justifyContent="center"
            alignItems="flex-start"
            direction={fullWidthFilter ? 'column' : 'row'}
            className={cx(fullWidthFilter ? flexBox.w100 : '')}
            spacing={1}
        >
            <FormControl variant="outlined" className={cx(fullWidthFilter ? flexBox.w100 : '')}>
                <RoundedSelect value={selected} onChange={handleTypeChange} fullWidth={fullWidthFilter} size={size}>
                    {rangeValues.map((value) => {
                        return (
                            <MenuItem key={value.id} value={value.id}>
                                {value.name}
                            </MenuItem>
                        );
                    })}
                </RoundedSelect>
            </FormControl>
            {selected === DateRange.CUSTOM && (
                <FormControl variant="outlined">
                    <BasicDateRangePicker
                        dateRange={dateRange}
                        onValue={onValueRange}
                        type={'date'}
                        maxDate={maxDate}
                        maxDaysAllowed={maxDaysAllowed}
                    />
                    {maxDaysAllowed && (
                        <FormHelperText sx={{ fontSize: 10 }} id="component-helper-text">
                            {t('common.features.filters.maxDaysAllowed', { days: maxDaysAllowed })}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        </Stack>
    );
};

export default CustomDateRange;
