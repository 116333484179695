import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';

const QueueSettings = (props) => {
    const { toggleChange, handleSubmit, hasChanges, enableOrganizationQueue, enablePersonalQueue } = props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.queueSettings.eventqueue')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <div style={{ paddingBottom: '20px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('Save changes')}
                            </Button>
                        </Box>
                    </Box>
                </div>
                <ItemSettings
                    label={t('profile.settings.queueSettings.queueOrganization')}
                    onChange={handleCheckChange}
                    name={SettingsKey.ENABLE_ORGANIZATION_QUEUE}
                    keySettings={SettingsKey.ENABLE_ORGANIZATION_QUEUE}
                    value={enableOrganizationQueue}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.settings.queueSettings.queueAgent')}
                    onChange={handleCheckChange}
                    name={SettingsKey.ENABLE_PERSONAL_QUEUE}
                    keySettings={SettingsKey.ENABLE_PERSONAL_QUEUE}
                    value={enablePersonalQueue}
                    paddingBottom
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default QueueSettings;
