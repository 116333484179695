import { makeStyles } from 'tss-react/mui';

const useStylesDashboard = makeStyles()((theme) => ({
    root: {
        minHeight: '200px',
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: '#FAFAFA'
    },
    text: {
        fontSize: '14px',
        fontWeight: 'regular'
    },
    icon: {
        width: '59px'
    }
}));

export default useStylesDashboard;