import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const addOnsPaidSlice = createSlice({
    name: 'addOnsPaid',
    initialState: {
        pending: false,
        addOns: [],
        mainAddOns: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setAddOnsPaid: (state, action) => {
            state.pending = false;
            state.addOns = action.payload;
        },
        setMainAddOnsPaid: (state, action) => {
            state.pending = false;
            state.mainAddOns = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
    },
});

export const { start, stop, setAddOnsPaid, setMainAddOnsPaid, setError } = addOnsPaidSlice.actions;

export const getAddOnsPaid = (state) => state.addOnsPaid.addOns;
export const getMainAddOnsPaid = (state) => state.addOnsPaid.addOns;
export const getAddOnsPaidPending = (state) => state.addOnsPaid.pending;
export const getAddOnsPaidError = (state) => state.addOnsPaid.error;

export const fetchAddOnsPaidAction =
    ({ organizationId } = {}) =>
    async (dispatch) => {
        try {
            const organizationIdToSend =
                organizationId || localStorage?.getItem('user')
                    ? JSON.parse(localStorage?.getItem('user'))?.organizationId
                    : null;
            dispatch(start());
            api.get(`paid-add-ons/${organizationIdToSend}`)
                .then((response) => {
                    dispatch(setAddOnsPaid(response.data));
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export default addOnsPaidSlice.reducer;
