import { makeStyles } from 'tss-react/mui';

const useStylesFrontImage = makeStyles()((theme) => {
    return {
        control: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        containerFrontPage: {
            height: '180px',
            width: '200px',
            marginBottom: theme.spacing(1),
            overflow: "hidden",
        },
        frontPage: {
            marginTop: theme.spacing(3),
            width: "100%",
            height: "auto",
        },
        button: {
            width: '200px'
        },
        backgroundWhite: {
            background: '#FFFF !important'
        }
    };
});

export default useStylesFrontImage;
