import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    closePreviewEmailTemplateAction,
    getPreviewEmailTemplateDialog,
    previewEmailTemplateAction,
} from 'redux/slices/emailSlice';

import useStylesSettings from '../styles/useStylesSettings.styles';

const PreviewEmailTemplateDialog = () => {
    const { t } = useTranslation();
    const { classes } = useStylesSettings();
    const dispatch = useDispatch();
    const template = useSelector(getPreviewEmailTemplateDialog);
    const [fullScreen, setFullScreen] = useState(false);
    const [preview, setPreview] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (template) {
            dispatch(
                previewEmailTemplateAction(template.template, template.body, template.part, template.lang, (data) => {
                    setLoading(false);
                    setPreview(data.preview);
                }),
            );
        }
    }, [template, dispatch]);

    const handleClose = () => {
        dispatch(closePreviewEmailTemplateAction());
        setPreview('');
    };

    useEffect(() => {
        if (!preview) {
            setFullScreen(false);
            setLoading(true);
        }
    }, [preview]);

    return (
        <Dialog
            maxWidth="lg"
            fullScreen={fullScreen}
            open={template ? true : false}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.emailsSettings.previewEmailTemplateDialog.preview')}
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    className={classes.h100pct}
                    style={{ minWidth: '800px', minHeight: '400px' }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {loading && <CircularProgress />}
                    {preview && (
                        <iframe
                            title="email"
                            frameBorder="0"
                            allowTransparency="true"
                            style={{ background: '#FFFFFF', width: '100%', minHeight: '45vh', height: '100%' }}
                            sandbox="allow-same-origin"
                            srcDoc={preview}
                        />
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setFullScreen(!fullScreen)} color="primary" variant="contained" autoFocus>
                    {fullScreen
                        ? t('profile.settings.emailsSettings.previewEmailTemplateDialog.maximize')
                        : t('profile.settings.emailsSettings.previewEmailTemplateDialog.minimize')}
                </Button>
                <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
                    {t('profile.settings.emailsSettings.previewEmailTemplateDialog.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewEmailTemplateDialog;
