import { createSlice } from '@reduxjs/toolkit';
import api, { calendarApi } from 'api';
import moment from 'moment';
import CalendarNotificationService from 'services/CalendarNotificationService';
import { UUID } from 'uuidjs';

export const CREATE_TEMPLATE_MODE = 'create';
export const EDIT_TEMPLATE_MODE = 'edit';
export const DELETE_TEMPLATE_MODE = 'delete';

export const ocalendarSlice = createSlice({
    name: 'ocalendar',
    initialState: {
        pending: false,
        savePending: false,
        error: null,
        syncCalendly: localStorage?.getItem('organization')
            ? JSON.parse(localStorage?.getItem('organization'))?.syncCalendly
            : false,
        calendly: localStorage?.getItem('user') ? JSON.parse(localStorage?.getItem('user'))?.calendly : null,
        calendarEventDialog: null,
        calendarEventEditDialog: null,
        currentDate: new Date(),
        view: 'month',
        calendarAppointments: [],
        appointments: [],
        filterId: '',
        needsToUpdate: false,
        profile: null,
        templates: [],
        templateView: null,
        templateViewEnable: false,
        pendingTemplate: false,
        templateAction: 'create' | 'edit' | 'delete',
        pendingTemplateAction: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setCalendly: (state, action) => {
            state.calendly = action.payload.calendly || '';
            state.syncCalendly = action.payload.syncCalendly;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        openCalendarEventDialog: (state, action) => {
            state.calendarEventDialog = action.payload;
        },
        openCalendarEventEditDialog: (state, action) => {
            state.calendarEventEditDialog = action.payload;
        },
        setDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setCalendarAppointments: (state, action) => {
            state.calendarAppointments = action.payload;
        },
        setAppointments: (state, action) => {
            state.appointments = action.payload;
        },
        setFilterId: (state, action) => {
            state.filterId = action.payload;
        },
        setNeedsToUpdate: (state, action) => {
            state.needsToUpdate = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        addTemplate: (state, action) => {
            state.templates = [...state.templates, action.payload];
            state.templateView = action.payload;
        },
        editTemplate: (state, action) => {
            state.pendingTemplate = false;
            const index = state.templates.findIndex((template) => template.id === action.payload.id);
            if (index !== -1) {
                state.templates = [
                    ...state.templates.slice(0, index),
                    action.payload,
                    ...state.templates.slice(index + 1),
                ];
            }
            state.templateView = action.payload;
        },
        setTemplates: (state, action) => {
            state.templates = action.payload;
        },
        setTemplateView: (state, action) => {
            state.templateView = action.payload;
        },
        setTemplateViewEnable: (state, action) => {
            state.templateViewEnable = action.payload;
        },
        setPendingTemplate: (state, action) => {
            state.pendingTemplate = action.payload;
        },
        deleteTemplate: (state, action) => {
            state.pendingTemplate = false;
            if (state.templateAction === CREATE_TEMPLATE_MODE || state.templateAction === DELETE_TEMPLATE_MODE) {
                const index = state.templates.findIndex((template) => template.id === action.payload);
                if (index !== -1) {
                    state.templates = [...state.templates.slice(0, index), ...state.templates.slice(index + 1)];
                }
                state.templateView = null;
                state.templateViewEnable = false;
            } else {
                state.templateView = null;
                state.templateViewEnable = false;
            }
        },
        setTemplateAction: (state, action) => {
            state.templateAction = action.payload;
        },
        startCreateTemplateMode: (state, action) => {
            state.templateView = null;
            state.templateViewEnable = true;
            state.templateAction = CREATE_TEMPLATE_MODE;
        },
        startEditTemplateMode: (state, action) => {
            state.templateView = action.payload;
            state.templateViewEnable = true;
            state.templateAction = EDIT_TEMPLATE_MODE;
        },
        setPendingTemplateAction: (state, action) => {
            state.pendingTemplateAction = action.payload;
        },
        deleteWorkingHour: (state, action) => {
            const {
                id: hourId,
                template: { id: templateId },
            } = action.payload;
            state.deletePending = false;

            const templateIndex = state.templates.findIndex((template) => template.id === templateId);

            if (templateIndex !== -1) {
                let workingHours = [...state.templates[templateIndex].workingHours];

                for (let i = 0; i < workingHours.length; i++) {
                    const wH = workingHours[i];
                    const hourIndex = wH.hours.findIndex((h) => h.id === hourId);

                    if (hourIndex !== -1) {
                        workingHours[i].hours = [
                            ...workingHours[i].hours.slice(0, hourIndex),
                            ...workingHours[i].hours.slice(hourIndex + 1),
                        ];
                    }
                    if (!workingHours[i].hours.length) {
                        workingHours[i].enable = false;
                    }
                }

                state.templates[templateIndex].workingHours = workingHours;
                state.templateView = state.templates[templateIndex];
            }
        },
    },
});

export const {
    start,
    stop,
    startSave,
    stopSave,
    setCalendly,
    setError,
    openCalendarEventDialog,
    openCalendarEventEditDialog,
    setDate,
    setView,
    setCalendarAppointments,
    setAppointments,
    setFilterId,
    setNeedsToUpdate,
    setProfile,
    addTemplate,
    editTemplate,
    setTemplates,
    setTemplateView,
    setTemplateViewEnable,
    setPendingTemplate,
    deleteTemplate,
    setTemplateAction,
    startCreateTemplateMode,
    startEditTemplateMode,
    setPendingTemplateAction,
    deleteWorkingHour,
} = ocalendarSlice.actions;

export const getCalendarAppointments = (state) => state.ocalendar.calendarAppointments;
export const getAppointments = (state) => state.ocalendar.appointments;
export const isPending = (state) => state.ocalendar.pending;
export const getSavePending = (state) => state.ocalendar.savePending;
export const isSyncCalendlyEnabled = (state) => state.ocalendar.syncCalendly;
export const getCalendarError = (state) => state.ocalendar.error;
export const getOpenedCalendarEvent = (state) => state.ocalendar.calendarEventDialog;
export const getOpenedCalendarEventEdit = (state) => state.ocalendar.calendarEventEditDialog;
export const getCurrentDate = (state) => state.ocalendar.currentDate;
export const getView = (state) => state.ocalendar.view;
export const getCalendly = (state) => state.ocalendar.calendly;
export const getFilterId = (state) => state.ocalendar.filterId;
export const getNeedsToUpdate = (state) => state.ocalendar.needsToUpdate;
export const getProfile = (state) => state.ocalendar.profile;
export const getTemplates = (state) => state.ocalendar.templates;
export const getTemplateView = (state) => state.ocalendar.templateView;
export const getTemplateViewEnable = (state) => state.ocalendar.templateViewEnable;
export const getPendingTemplate = (state) => state.ocalendar.pendingTemplate;
export const getTemplateAction = (state) => state.ocalendar.templateAction;
export const getPendingTemplateAction = (state) => state.ocalendar.pendingTemplateAction;

export const closeEventAction = () => async (dispatch) => {
    dispatch(openCalendarEventDialog(null));
};

export const closeEventEditAction = () => async (dispatch) => {
    dispatch(openCalendarEventEditDialog(null));
};

// APPOINTMENTS
export const fetchAppointmentsByOrganizationAction = (date, onFailure) => async (dispatch, getState) => {
    try {
        const state = getState();
        const start = moment.parseZone(date).startOf('month').utc().format();
        const end = moment.parseZone(date).endOf('month').utc().format();
        calendarApi
            .get(
                `/api/v2/appointment/organization/${state.organization.organization.id}?startDate=${start}&endDate=${end}`,
            )
            .then((response) => {
                dispatch(setAppointments(response.data));
                dispatch(setNeedsToUpdate(false));
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchAppointmentsByUserAction = (userId, date, onFailure) => async (dispatch) => {
    try {
        const start = moment.parseZone(date).startOf('month').utc().format();
        const end = moment.parseZone(date).endOf('month').utc().format();
        calendarApi
            .get(`/api/v2/appointment/user/${userId}?startDate=${start}&endDate=${end}`)
            .then((response) => {
                dispatch(setAppointments(response.data));
                dispatch(setNeedsToUpdate(false));
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const deleteAppointmentAction = (eventId, userId, callback, onFailure) => async (dispatch) => {
    try {
        dispatch(start());
        calendarApi
            .delete(`/api/v2/appointment/${eventId}?userId=${userId}&type=delete`)
            .then((response) => {
                if (response.error) {
                    return console.error('error', `Error: ${response.error}`);
                } else {
                    CalendarNotificationService.deleteEvent(eventId);
                    if (callback) {
                        callback();
                    }
                }
                dispatch(stop());
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const createAppointmentAction = (appointment, callback, onFailure) => async (dispatch) => {
    try {
        dispatch(startSave());
        calendarApi
            .post(`/api/v2/appointment?type=create`, appointment)
            .then((response) => {
                dispatch(stopSave());
                CalendarNotificationService.addNewEvent({
                    eventId: response.data.eventId,
                    releaseDate: response.data.appointmentDate,
                    name: response.data.name,
                    publicUrl: response.data.iCallUrl,
                    description: response.data.description,
                    id: response.data.id,
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
                dispatch(stopSave());
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const updateAppointmentAction = (appointmentId, appointment, callback, onFailure) => async (dispatch) => {
    try {
        dispatch(startSave());
        calendarApi
            .put(`/api/v1/appointment/${appointmentId}`, appointment)
            .then(async (response) => {
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    if (appointment.eventId && appointment.sessionUserId) {
                        try {
                            await api.patch('events/' + appointment.eventId, {
                                adminUserId: appointment.sessionUserId,
                            });
                        } catch (e) {
                            console.error('error', e);
                        }
                    }
                    CalendarNotificationService.updateEvent({
                        eventId: response.data.eventId,
                        releaseDate: response.data.appointmentDate,
                        name: response.data.name,
                        publicUrl: response.data.iCallUrl,
                        description: response.data.description,
                        id: response.data.id,
                    });
                    if (callback) {
                        callback(response.data);
                    }
                }
                dispatch(closeEventEditAction());
                dispatch(stopSave());
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
                dispatch(stopSave());
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

// PROFILE
export const fetchProfileAction = (agentId, callback, onFailure) => async (dispatch) => {
    try {
        calendarApi
            .get(`/api/v1/profiles/agent/${agentId}`)
            .then((response) => {
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    let val = null;
                    if (response.data) {
                        val = {
                            id: response.data.id,
                            agentId: response.data.agentId,
                            timeZone: response.data.timeZone,
                        };
                    }
                    dispatch(setProfile(val));
                    if (callback) {
                        callback(val);
                    }
                }
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const postProfileAction = (profile, callback, onFailure) => async (dispatch) => {
    try {
        calendarApi
            .post(`/api/v1/profiles`, profile)
            .then((response) => {
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    const val = {
                        id: response.data.id,
                        agentId: response.data.agentId,
                        timeZone: response.data.timeZone,
                    };
                    dispatch(setProfile(val));
                    if (callback) {
                        callback(val);
                    }
                }
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const updateProfileAction = (profileId, profile, callback, onFailure) => async (dispatch) => {
    try {
        calendarApi
            .put(`/api/v1/profiles/${profileId}`, profile)
            .then((response) => {
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    const val = {
                        id: response.data.id,
                        agentId: response.data.agentId,
                        timeZone: response.data.timeZone,
                    };
                    dispatch(setProfile(val));
                    if (callback) {
                        callback(val);
                    }
                }
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

// TEMPLATE
export const fetchTemplatesAction = (userId, callback, onFailure) => async (dispatch) => {
    try {
        dispatch(setPendingTemplate(true));
        calendarApi
            .get(`/api/v1/templates/user/${userId}`)
            .then((response) => {
                dispatch(setPendingTemplate(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    dispatch(setTemplates(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplate(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const postTemplateAction = (template, userId, callback, onFailure) => async (dispatch, getState) => {
    try {
        const state = getState();
        dispatch(setPendingTemplate(true));
        calendarApi
            .post(`/api/v1/templates`, {
                id: template.id,
                title: template.title,
                duration: template.duration,
                timeBetweenEvents: template.timeBetweenEvents,
                minimumTimeToSchedule: template.minimumTimeToSchedule,
                maximumTimeToSchedule: template.maximumTimeToSchedule,
                userId: userId,
                timeIncrementation: template.timeIncrementation,
                profileId: template.profileId,
                availabilityType: template.availabilityType,
            })
            .then((response) => {
                dispatch(setPendingTemplate(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    const val = {
                        id: response.data.id,
                        title: response.data.title,
                        duration: response.data.duration,
                        minimumTimeToSchedule: response.data.minimumTimeToSchedule,
                        maximumTimeToSchedule: response.data.maximumTimeToSchedule,
                        timeBetweenEvents: response.data.timeBetweenEvents,
                        timeIncrementation: response.data.timeIncrementation,
                        profileId: response.data.profileId,
                        workingHours: template.workingHours,
                        dateOverrides: template.dateOverrides,
                        outsOfTheOffice: template.outsOfTheOffice,
                        availabilityType: response.data.availabilityType,
                    };
                    const index = state.ocalendar.templates.findIndex((t) => t.id === template.id);
                    if (index !== -1) {
                        dispatch(editTemplate(val));
                    } else {
                        dispatch(addTemplate(val));
                    }
                    if (callback) {
                        callback(val);
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplate(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplate(false));
        return console.error('error', e);
    }
};

export const updateTemplateAvailabilityTypeAction =
    (templateId, availabilityType, success, failure) => async (dispatch, getState) => {
        try {
            const state = getState();
            dispatch(setPendingTemplate(true));
            calendarApi
                .patch('/api/v1/templates/availability-type/' + templateId, availabilityType)
                .then((response) => {
                    const template = state.ocalendar.templates.find((t) => t.id === response.data.id);
                    const result = {
                        ...response.data,
                        workingHours: template.workingHours,
                        dateOverrides: template.dateOverrides,
                        outsOfTheOffice: template.outsOfTheOffice,
                    };
                    dispatch(editTemplate(result));
                    if (success) {
                        success(result);
                    }
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setPendingTemplate(false));
                    if (failure) {
                        failure(error);
                    }
                });
        } catch (e) {
            return console.error('error', e);
        }
    };

export const deleteTemplateAction = (templateId, userId, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(setPendingTemplate(true));
        dispatch(setTemplateAction(DELETE_TEMPLATE_MODE));
        calendarApi
            .delete(`/api/v1/templates/${templateId}/${userId}`)
            .then((response) => {
                dispatch(deleteTemplate(templateId));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setPendingTemplate(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplate(false));
        return console.error('error', e);
    }
};

// AVAILABILITY
export const postOutOfTheOfficeAction = (outOfTheOffice, callback, onFailure) => async (dispatch, getState) => {
    try {
        dispatch(setPendingTemplateAction(true));
        const state = getState();
        calendarApi
            .post(`/api/v1/out-of-office`, outOfTheOffice)
            .then((response) => {
                dispatch(setPendingTemplateAction(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    const tempTemplate = {
                        ...state.ocalendar.templateView,
                        outsOfTheOffice: [
                            ...state.ocalendar.templateView.outsOfTheOffice,
                            {
                                id: response.data.id,
                                beginDate: response.data.beginDate,
                                finalDate: response.data.finalDate,
                            },
                        ],
                    };
                    dispatch(editTemplate(tempTemplate));
                    if (callback) {
                        callback({
                            id: response.data.id,
                            beginDate: response.data.beginDate,
                            finalDate: response.data.finalDate,
                        });
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplateAction(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplateAction(false));
        return console.error('error', e);
    }
};

export const deleteOutOfTheOfficeAction = (outOfTheOfficeId, callback, onFailure) => async (dispatch, getState) => {
    try {
        dispatch(setPendingTemplateAction(true));
        const state = getState();
        calendarApi
            .delete(`/api/v1/out-of-office/delete/${outOfTheOfficeId}`)
            .then((response) => {
                dispatch(setPendingTemplateAction(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    let tempTemplate = { ...state.ocalendar.templateView };
                    const position = tempTemplate?.outsOfTheOffice.findIndex((_do) => _do.id === outOfTheOfficeId);
                    tempTemplate = {
                        ...state.ocalendar.templateView,
                        outsOfTheOffice: [
                            ...tempTemplate.outsOfTheOffice.slice(0, position),
                            ...tempTemplate.outsOfTheOffice.slice(position + 1),
                        ],
                    };
                    dispatch(editTemplate(tempTemplate));
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplateAction(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplateAction(false));
        return console.error('error', e);
    }
};

export const batchDeleteDaysOverrideAction =
    (deletedIds, userId, position, callback, onFailure) => async (dispatch, getState) => {
        try {
            dispatch(setPendingTemplateAction(true));
            const state = getState();
            calendarApi
                .post(`/api/v1/day-override/batch-delete`, {
                    daysOverrides: deletedIds,
                    userId: userId,
                })
                .then((response) => {
                    dispatch(setPendingTemplateAction(false));
                    if (response.error) {
                        console.error(`Error: ${response.error}`);
                    } else {
                        let tempTemplate = { ...state.ocalendar.templateView };
                        tempTemplate = {
                            ...tempTemplate,
                            dateOverrides: [
                                ...tempTemplate.dateOverrides.slice(0, position),
                                ...tempTemplate.dateOverrides.slice(position + 1),
                            ],
                        };
                        dispatch(editTemplate(tempTemplate));
                        if (callback) {
                            callback();
                        }
                    }
                })
                .catch((error) => {
                    dispatch(setPendingTemplateAction(false));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(setPendingTemplateAction(false));
            return console.error('error', e);
        }
    };

export const postDaysOverrideAction = (daysOverrides, callback, onFailure) => async (dispatch, getState) => {
    try {
        dispatch(setPendingTemplateAction(true));
        const state = getState();
        calendarApi
            .post(`/api/v1/day-override`, { daysOverrides })
            .then((response) => {
                dispatch(setPendingTemplateAction(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    let resolvedResponse = [];
                    let responseDaysOverrides = response.data;

                    while (responseDaysOverrides.length) {
                        // Extract the first day object from the array
                        const firstDay = responseDaysOverrides[0];

                        // Filter the array to get all objects with the same date
                        const tempDays = responseDaysOverrides.filter((d) => d.date === firstDay.date);

                        const { id, date } = tempDays[0];

                        // Map tempDays array to create an array of hours
                        const hours = tempDays.map((day) => ({
                            id: day.id,
                            startTime: day.startHour,
                            endTime: day.endHour,
                        }));

                        // Create a new dayOverride object
                        const dayOverride = { id, date, hours };

                        // Update responseDaysOverrides by removing processed items
                        responseDaysOverrides = responseDaysOverrides.slice(tempDays.length);

                        // Add the dayOverride object to resolvedResponse array
                        resolvedResponse.push(dayOverride);
                    }

                    let tempTemplate = {
                        ...state.ocalendar.templateView,
                        dateOverrides: [...state.ocalendar.templateView.dateOverrides, ...resolvedResponse],
                    };

                    dispatch(editTemplate(tempTemplate));
                    if (callback) {
                        callback(resolvedResponse);
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplateAction(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplateAction(false));
        return console.error('error', e);
    }
};

export const batchDeleteSchedulesAction = (deletedIds, userId, callback, onFailure) => async (dispatch) => {
    try {
        dispatch(setPendingTemplateAction(true));
        calendarApi
            .post(`/api/v1/schedule/batch-delete`, {
                schedules: deletedIds,
                userId: userId,
            })
            .then((response) => {
                dispatch(setPendingTemplateAction(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    if (response.data?.length) {
                        response.data.forEach((workingHour) => {
                            dispatch(deleteWorkingHour(workingHour));
                        });
                    }
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplateAction(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplateAction(false));
        return console.error('error', e);
    }
};

export const postSchedulesAction = (schedules, moment, tempDays, callback, onFailure) => async (dispatch, getState) => {
    try {
        dispatch(setPendingTemplateAction(true));
        const state = getState();
        calendarApi
            .post(`/api/v1/schedule`, { schedules })
            .then((response) => {
                dispatch(setPendingTemplateAction(false));
                if (response.error) {
                    console.error(`Error: ${response.error}`);
                } else {
                    const resolvedResponse = Array.from({ length: 7 }, (_, i) => {
                        const tempHours = response.data.filter((s) => s.dayIndex === i);
                        return tempHours.length > 0
                            ? {
                                id: UUID.generate(),
                                enable: true,
                                index: i,
                                hours: tempHours.map((h) => ({
                                    id: h.id,
                                    startTime: h.startHour,
                                    endTime: h.endHour,
                                })),
                            }
                            : null;
                    }).filter(Boolean); // Remove null entries (where tempHours length is 0)

                    const existingIndices = new Set(resolvedResponse.map((entry) => entry.index));
                    const newEntries = tempDays.filter((entry) => !existingIndices.has(entry.index));

                    const wH = resolvedResponse.concat(newEntries).sort((a, b) => a.index - b.index);

                    let tempTemplate = {
                        ...state.ocalendar.templateView,
                        workingHours: [...wH],
                    };
                    dispatch(editTemplate(tempTemplate));
                    if (callback) {
                        callback(resolvedResponse);
                    }
                }
            })
            .catch((error) => {
                dispatch(setPendingTemplateAction(false));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(setPendingTemplateAction(false));
        return console.error('error', e);
    }
};

export default ocalendarSlice.reducer;
