import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const lostOpportunitySlice = createSlice({
    name: 'lostOpportunity',
    initialState: {
        lostOpportunities: [],
        error: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 1,
        pending: false,
        savePending: false,
        deletePending: false,
        hasMore: true,
        allLostOpportunities: [],
        pendingAllLostOpportunities: [],
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startAll: (state, action) => {
            state.pendingAllContactRequests = true;
        },
        stopAll: (state, action) => {
            state.pendingAllContactRequests = false;
        },
        setLostOpportunities: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.lostOpportunities = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setAllLostOpportunities: (state, action) => {
            state.pendingAllLostOpportunities = false;
            state.allLostOpportunities = [...state.allLostOpportunities.slice(0, action.payload.offset), ...action.payload.items];
            if (action.payload.items.length < 20) {
                state.hasMore = false;
            } else {
                state.hasMore = true;
            }
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
        },
        deleteLostOpportunity: (state, action) => {
            state.pending = false;
            const index = state.lostOpportunities.findIndex((lostOpportunity) => lostOpportunity.id === action.payload.id);
            if (index !== -1) {
                state.lostOpportunities = [...state.lostOpportunities.slice(0, index), ...state.lostOpportunities.slice(index + 1)];
            }
        },
    },
});

export const {
    start,
    stop,
    setLostOpportunities,
    setError,
    setAllLostOpportunities,
    startAll,
    stopAll,
    startSave,
    stopSave,
    startDelete,
    deleteLostOpportunity,
} = lostOpportunitySlice.actions;

export const getLostOpportunities = (state) => state.lostOpportunity.lostOpportunities;
export const getAllLostOpportunities = (state) => state.lostOpportunity.allLostOpportunities;
export const getPendingAllLostOpportunities = (state) => state.lostOpportunity.pendingAllLostOpportunities;

export const getHasMore = (state) => state.lostOpportunity.hasMore;
export const getLostOpportunitiesPending = (state) => state.lostOpportunity.pending;
export const getLostOpportunitiesError = (state) => state.lostOpportunity.error;
export const getLostOpportunitiesTotal = (state) => state.lostOpportunity.total;
export const getLostOpportunitiesCurrentPage = (state) => state.lostOpportunity.currentPage;
export const getLostOpportunitiesTotalPages = (state) => state.lostOpportunity.totalPages;

export const fetchLostOpportunitiesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setLostOpportunities({
                            total: 0,
                            items: [],
                            count: 0,
                            currentPage: 1,
                            totalPages: 1,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('lost-opportunities' + query)
                .then((response) => {
                    dispatch(
                        setLostOpportunities(response.data),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchAllLostOpportunitiesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setAllLostOpportunities({
                            items: [],
                            offset: 0,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(startAll());
            api.get('lost-opportunities' + query)
                .then((response) => {
                    dispatch(
                        setAllLostOpportunities({
                            ...response.data,
                            offset: queryParams.page ? (queryParams.page - 1) * 20 : 0,
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopAll());
            return console.error('error', e);
        }
    };

export const addLostOpportunityAction =
    ({ lostOpportunityDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('lost-opportunities', lostOpportunityDto)
                .then((response) => {
                    dispatch(
                        fetchLostOpportunitiesAction({
                            queryParams: {
                                page: 1,
                            },
                        }),
                    );
                    if (onSuccess) {
                        onSuccess();
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const updateLostOpportunityAction =
    ({ lostOpportunityId, lostOpportunityDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.put('lost-opportunities/' + lostOpportunityId, lostOpportunityDto)
                .then((response) => {
                    dispatch(
                        fetchLostOpportunitiesAction({
                            queryParams: {
                                page: 1,
                            },
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const deleteLostOpportunityAction = (lostOpportunityId) => async (dispatch) => {
    try {
        api.delete('lost-opportunities/' + lostOpportunityId)
            .then((response) => {
                dispatch(deleteLostOpportunity(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export default lostOpportunitySlice.reducer;
