import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { editServiceAction } from 'redux/slices/serviceSlice';
import ServiceForm from './ServiceForm';

const ServiceEdit = (props) => {
    const { service } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSubmit = (organizationId, payload, onSuccess, onFailure) => {
        dispatch(
            editServiceAction({
                id: service.id,
                serviceDto: payload,
                onSuccess: () => onSuccess(),
                onFailure: () => onFailure(),
            }),
        );
    };

    return <ServiceForm service={service} onSubmit={handleSubmit} action={t('services.form.editTitle')} />;
};

export default ServiceEdit;
