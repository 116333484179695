import { createSlice } from '@reduxjs/toolkit';


export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        myAccountSettingsDirty: false,
        integrationSettingsDirty: false,
        settingsDirty: false,
        organizationSettingsDirty: false,
        plansSettingsDirty: false,
    },
    reducers: {
        setMyAccountSettingsDirty: (state, action) => {
            state.myAccountSettingsDirty = action.payload;
        },
        setIntegrationSettingsDirty: (state, action) => {
            state.integrationSettingsDirty = action.payload;
        },
        setSettingsDirty: (state, action) => {
            state.settingsDirty = action.payload;
        },
        setOrganizationSettingsDirty: (state, action) => {
            state.organizationSettingsDirty = action.payload;
        },
        setPlansSettingsDirty: (state, action) => {
            state.plansSettingsDirty = action.payload;
        },
    },
});

export const {
    setMyAccountSettingsDirty,
    setIntegrationSettingsDirty,
    setSettingsDirty,
    setOrganizationSettingsDirty,
    setPlansSettingsDirty,
} = profileSlice.actions;

export const getMyAccountSettingsDirty = (state) => state.profile.myAccountSettingsDirty;
export const getIntegrationSettingsDirty = (state) => state.profile.integrationSettingsDirty;
export const getSettingsDirty = (state) => state.profile.settingsDirty;
export const getOrganizationSettingsDirty = (state) => state.profile.organizationSettingsDirty;
export const getPlansSettingsDirty = (state) => state.profile.plansSettingsDirty;

export default profileSlice.reducer;
