import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ServicesList from 'app/services/ServicesList';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import YesNoDialog from 'common/components/YesNoDialog';
import { useSettingValue } from 'common/hooks/useSettingValue';
import * as moment from 'moment';
import { useSnackbar } from 'notistack';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { openCustomDialog } from 'redux/slices/customDialogSlice';
import { deleteAvailabilityAction } from 'redux/slices/myAvailabilitySlice';
import { getServices } from 'redux/slices/serviceSlice';
import 'suneditor/dist/css/suneditor.min.css';
import MyAvailabilityTable from './MyAvailabilityTable';
import MyAvailabilityEdit from './myAvailabilityForm/MyAvailabilityEdit';

const MyAvailabilityItem = ({ availability, userData, groups }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const allServices = useSelector(getServices);
    const dateOverrides = availability.configuration?.dateOverrides;
    const outsOfTheOffice = availability.configuration.outsOfTheOffice;
    const user = useSelector(getUser);
    const enableAgentEditAvailability = useSettingValue(SettingsKey.ENABLE_AGENT_EDIT_AVAILABILITY) ?? true;
    const disableAgentEditAvailability = user.role === 'creator' && !enableAgentEditAvailability;
    const [openConfirmDeleteDlg, setOpenConfirmDeleteDlg] = useState(false);

    const titleDlg = t('myAvailability.myAvailabilityItem.deleteConfirm');
    const messageDlg = t('myAvailability.myAvailabilityItem.deleteMessage');

    const handleDelete = (e) => {
        e.stopPropagation();
        setOpenConfirmDeleteDlg(true);
    };

    const confirmDelete = () => {
        dispatch(
            deleteAvailabilityAction(availability.id, () => {
                enqueueSnackbar(t('myAvailability.myAvailabilityItem.deleteDone'), { variant: 'success' });
            }),
        );
        setOpenConfirmDeleteDlg(false);
    };

    const cancelDlg = () => {
        setOpenConfirmDeleteDlg(false);
    };

    const templates = useMemo(
        () =>
            availability?.templatesWeeklySchedule.map((temp) => {
                const newService = allServices?.find((service) => service.id === temp?.template?.id);
                return {
                    ...temp.template,
                    ...newService,
                };
            }),
        [allServices, availability?.templatesWeeklySchedule],
    );

    const handleEdit = () => {
        dispatch(openCustomDialog(<MyAvailabilityEdit availability={availability} userData={userData} />));
    };

    return (
        <Fragment>
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <Typography variant="button" display="block" gutterBottom>
                    {availability.name}
                </Typography>

                {!disableAgentEditAvailability && (
                    <CustomTooltip arrow placement="bottom" title={t('actions.edit')} aria-label="edit availability">
                        <CustomIconButton id="onlive-button-edit" size="small" onClick={handleEdit}>
                            <EditOutlinedIcon component="svg" />
                        </CustomIconButton>
                    </CustomTooltip>
                )}

                {!disableAgentEditAvailability && (
                    <CustomTooltip
                        arrow
                        placement="bottom"
                        title={t('actions.delete')}
                        aria-label="delete availability"
                    >
                        <CustomIconButton id="onlive-button-edit" size="small" onClick={handleDelete}>
                            <DeleteOutlineOutlinedIcon component="svg" />
                        </CustomIconButton>
                    </CustomTooltip>
                )}
            </Stack>

            <Grid container spacing={10}>
                <Grid item md={9} sm={12}>
                    <Stack direction="column" spacing={2}>
                        <MyAvailabilityTable availability={availability} />
                        <ServicesList templates={templates} isService={false} groups={groups} />
                    </Stack>
                </Grid>

                <Grid item md={3} sm={12}>
                    <Stack direction="column" spacing={6}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('calendar.setOutOfTheOffice')}
                            </Typography>
                            <Stack direction="column" spacing={1}>
                                {outsOfTheOffice?.map((item, idx) => (
                                    <Stack key={idx} direction="row" spacing={2}>
                                        <Typography fontWeight={400} variant="subtitle2">
                                            {moment(item[0]).format('DD / MM / YYYY')}
                                        </Typography>
                                        <Typography fontWeight={400} variant="subtitle2">
                                            {t('services.form.dateTo')}
                                        </Typography>
                                        <Typography fontWeight={400} variant="subtitle2">
                                            {moment(item[1]).format('DD / MM / YYYY')}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="subtitle1" fontWeight={500}>
                                <Box component="span" fontWeight="fontWeightMedium">
                                    {t('calendar.dateOverride')}
                                </Box>
                            </Typography>
                            <Stack direction="column" spacing={2}>
                                {Object.keys(dateOverrides)?.map((key) => (
                                    <Stack direction="column" key={key} spacing={1}>
                                        <Typography variant="subtitle2" fontWeight={400}>
                                            {moment(key).format('DD / MM / YYYY')}
                                        </Typography>
                                        {dateOverrides[key]?.map((hour, idx) => (
                                            <Stack key={idx} direction="row" spacing={2}>
                                                <Typography fontWeight={400} variant="subtitle2">
                                                    {hour[0]}h
                                                </Typography>
                                                <Typography fontWeight={400} variant="subtitle2">
                                                    -
                                                </Typography>
                                                <Typography fontWeight={400} variant="subtitle2">
                                                    {hour[1]}h
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            {openConfirmDeleteDlg && (
                <YesNoDialog
                    openDialog={true}
                    title={titleDlg}
                    messageToConfirm={messageDlg}
                    yesButtonCallback={() => {
                        confirmDelete();
                    }}
                    noButtonCallback={() => {
                        cancelDlg();
                    }}
                />
            )}
        </Fragment>
    );
};

export default MyAvailabilityItem;
