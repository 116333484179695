import DateRange from 'common/enums/dateRange';
import * as moment from 'moment';
import TimeFormats from '../../../common/enums/timeFormats';

const createLabelMapper = (map) => {
    return (labelKey, fallbackValue) => {
        if (map && map[labelKey]) {
            return map[labelKey];
        }
        return fallbackValue || labelKey.replaceAll('.', ' ');
    };
};

export const secondsToMinutes = (seconds, precision = 2) => {
    return parseFloat((seconds / 60).toFixed(precision));
};

export const roundTo = (value, precision = 0) => {
    return parseFloat((value || 0).toFixed(precision));
};

export const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
};

export const formatMinutes = (minutes) => {
    var hh = Math.floor(Math.abs(minutes) / 60);
    var mm = Math.floor(Math.abs(minutes) - (hh * 3600) / 60);
    var ss = Math.floor(Math.abs(minutes) * 60 - hh * 3600 - mm * 60);

    // Appends 0 when unit is less than 10
    if (hh < 10) {
        hh = '0' + hh;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    if (ss < 10) {
        ss = '0' + ss;
    }

    const formatedData = hh + ':' + mm + ':' + ss;

    return minutes >= 0 ? formatedData : `-${formatedData}`;
    // return moment(minutes, 'mm').format('HH:mm:ss');
};

export const formatMilliseconds = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = totalSeconds % 60;

    return [String(h).padStart(2, '0'), String(m).padStart(2, '0'), String(s).padStart(2, '0')].join(':');
};

export const adaptXDateScale = (query, xScale, accumulative = false) => {
    if (query.timeDimensions[0]?.dateRange?.length) {
        const startDate = moment.utc(query.timeDimensions[0].dateRange[0]);
        const endDate = moment.utc(query.timeDimensions[0].dateRange[1]);
        const duration = moment.duration(endDate.diff(startDate));

        const timeFormat = duration.asHours() < 24 ? TimeFormats.HOUR_MINUTE : TimeFormats.YEAR_DAY;

        xScale.min = startDate.local().format(timeFormat);
        xScale.max = endDate.local().format(timeFormat);
        xScale.time.fillGapsWithZero = { inputFormat: TimeFormats.CUBE_UTC_FULL_DATETIME, accumulative };

        xScale.time.unit = query.timeDimensions[0].granularity ?? 'day';

        xScale.time.parser = (date) => moment(date);
    }
};

export const getTitle = (granularity, t) => {
    switch (granularity) {
        case 'hour':
            return t('common.hour');
        case 'day':
            return t('common.day');
        case 'week':
            return t('common.week');
        case 'month':
            return t('common.month');
        default:
            return t('common.all');
    }
};

export const getDateRangeByRangeType = (value) => {
    let start;
    let end;
    switch (value) {
        // case DateRange.ALL:
        //     start = undefined;
        //     end = undefined;
        //     break;
        case DateRange.TODAY:
            start = moment.utc().startOf('day').toDate();
            end = moment.utc().endOf('day').toDate();
            break;
        case DateRange.LAST_7_DAYS:
            start = moment.utc().subtract(6, 'days').startOf('day').toDate();
            end = moment.utc().endOf('day').toDate();
            break;
        case DateRange.LAST_30_DAYS:
            start = moment.utc().subtract(29, 'days').startOf('day').toDate();
            end = moment.utc().endOf('day').toDate();
            break;
        case DateRange.LAST_90_DAYS:
            start = moment.utc().subtract(89, 'days').startOf('day').toDate();
            end = moment.utc().endOf('day').toDate();
            break;
        case DateRange.CUSTOM:
            start = moment.utc().startOf('week').toDate();
            end = moment.utc().endOf('day').toDate();
            break;
        default:
            break;
    }
    return { start, end };
};

export const transformChartTooltipTitle = (xDatapoint, granularity) => {
    return granularity === 'hour' ? xDatapoint[0]?.label : moment(xDatapoint[0]?.parsed?.x).format('MMM DD, YYYY');
};

export { createLabelMapper };
