import { Badge, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import useFlexBoxStyles from 'common/flexBox';
import { useRef } from 'react';
import CustomTooltip from './CustomTooltip';

export function TabPanel(props) {
    const { children, value, index, p, classes, ...rest } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={value === index ? classes : ''}
            {...rest}
        >
            {value === index && children}
        </div>
    );
}

export const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

const checkForIncomingContent = (contentId) => {
    const incomingNewContentArr = JSON.parse(localStorage?.getItem('incomingNewContent'));
    return incomingNewContentArr?.some((item) => item === contentId);
};

export const CustomTab = styled((props) => {
    const { notifications, label, contentId, tooltipMessage, disabled, ...rest } = props;
    const ref = useRef(null);
    const { classes: flexBox, cx } = useFlexBoxStyles();

    const getNotification = () => {
        let content = '';
        if (notifications) {
            content = notifications?.filter((notification) => notification.unread === true).length;
        } else if (checkForIncomingContent(contentId)) {
            content = 'New';
        } else {
            return null;
        }
        return (
            <Badge
                className={cx(flexBox.absolute, flexBox.right2, flexBox.top2)}
                badgeContent={content}
                color="primary"
            />
        );
    };

    return (
        <CustomTooltip
            arrow
            disableHoverListener={!disabled || !tooltipMessage}
            title={tooltipMessage}
            key={`profile-tooltip-${label}`}
            placement={'bottom'}
        >
            <Tab
                {...rest}
                style={{ padding: '12px 24px', whiteSpace: 'nowrap' }}
                ref={ref}
                disabled={disabled}
                disableRipple={true}
                classes={{ root: cx(flexBox.overflowVisible, flexBox.relative) }}
                label={
                    <>
                        {label}
                        {getNotification()}
                    </>
                }
            />
        </CustomTooltip>
    );
})(({ theme }) => {
    return {
        textTransform: 'uppercase',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        [theme.breakpoints.up('xl')]: {
            fontSize: theme.spacing(2.25),
        },
        '&.Mui-selected': {
            color: '#000',
            fontWeight: 'bold',
        },
    };
});
