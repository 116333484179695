import { makeStyles } from 'tss-react/mui';

const useStylesLoadingButton = makeStyles()(() => {
    return {
        wrapper: {
            position: 'relative',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    };
});

export default useStylesLoadingButton;
