import { makeStyles } from 'tss-react/mui';

const useStylesTopBar = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: theme.spacing(10),
            position: 'absolute',
            zIndex: 999,
            '&.contentDialogMode': {
                display: 'none',
            }
        },
        divLine: {
            borderLeft: '2px solid #00000021',
            opacity: '0.5',
            height: theme.spacing(6),
        },
        grow1: {
            flexGrow: 1,
        },
        rightSpace: {
            width: theme.spacing(6),
            [theme.breakpoints.up('xl')]: {
                width: theme.spacing(7),
            },
        },
        leftSpace: {
            width: theme.spacing(4),
            [theme.breakpoints.up('xl')]: {
                width: theme.spacing(4.5),
            },
        },
        ml2: {
            marginLeft: theme.spacing(2),
        },
        absolute: {
            position: 'absolute',
        },
        currentEventDetails: {
            display: 'flex',
            gap: theme.spacing(2),
            justifyContent: 'end',
        },
        currentEvent: {
            display: 'flex',
            flexDirection: 'column',
        },
        currentEventHead: {
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
        },
        currentEventText: {
            fontSize: '10px',
            fontStyle: 'italic',
        },
        pickUpButton: {
            backgroundColor: '#f00',
            color: '#fff',
            borderRadius: '16px',
            height: '32px',
            textWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            justifyContent: 'start',
        },
    };
});

export default useStylesTopBar;
