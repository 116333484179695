import { Divider, Stack } from '@mui/material';
import EmptyView from 'common/features/noData/EmptyView';
import useFlexBoxStyles from 'common/flexBox';
import LayoutSectionHeader from 'common/layouts/LayoutSectionHeader';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupsByIdsAction } from 'redux/slices/groupSlice';
import { getAvailabilities, getAvailabilitiesPending } from 'redux/slices/myAvailabilitySlice';
import 'suneditor/dist/css/suneditor.min.css';
import MyAvailabilityCreateAction from './MyAvailabilityCreateAction';
import MyAvailabilityItem from './MyAvailabilityItem';

function extractGroupIds(data) {
    const uniqueGroupIds = new Set();
    data.forEach((item) => {
        if (item.templatesWeeklySchedule) {
            item.templatesWeeklySchedule.forEach((schedule) => {
                const userGroupId = schedule.template ? schedule.template.userGroupId : '';
                if (userGroupId) {
                    uniqueGroupIds.add(userGroupId);
                }
            });
        }
    });

    return Array.from(uniqueGroupIds);
}

const MyAvailabilitySection = ({ userData }) => {
    const { userView } = userData;
    const dispatch = useDispatch();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { t } = useTranslation();
    const availabilities = useSelector(getAvailabilities);
    const pending = useSelector(getAvailabilitiesPending);
    const [groups, setGroups] = useState([]);

    const getGroups = useCallback(() => {
        if (availabilities?.length) {
            const ids = extractGroupIds(availabilities);
            if (ids.length) {
                dispatch(
                    fetchGroupsByIdsAction({
                        queryParams: { ids },
                        onSuccess: (data) => setGroups(data),
                    }),
                );
            }
        }
    }, [dispatch, availabilities]);

    useEffect(() => {
        getGroups();
    }, [getGroups]);

    return (
        <>
            <LayoutSectionHeader total={userView ? false : availabilities?.length}>
                <Stack
                    direction="column"
                    className={cx(flexBox.w100, flexBox.h100, flexBox.overflowXHidden, flexBox.scrollbarNone)}
                    spacing={3}
                >
                    {availabilities?.map((availability, idx) => (
                        <Stack key={idx}>
                            <Stack>
                                <MyAvailabilityItem availability={availability} userData={userData} groups={groups} />
                            </Stack>
                            {idx !== availabilities.length - 1 ? <Divider sx={{ mt: 5 }} /> : null}
                        </Stack>
                    ))}
                </Stack>
            </LayoutSectionHeader>

            {!availabilities?.length && !pending && (
                <EmptyView
                    type={''}
                    description={t('availability.empty.description')}
                    action={userView ? null : <MyAvailabilityCreateAction userData={userData} />}
                />
            )}
        </>
    );
};

export default MyAvailabilitySection;
