import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const contentInstanceSlice = createSlice({
    name: 'contentInstance',
    initialState: {
        pending: false,
        savePending: false,
        contentInstances: [],
        error: null,
        currentPage: 0,
        totalPages: 0,
        count: 0,
        total: 0,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setContentInstances: (state, action) => {
            state.pending = false;
            state.contentInstances = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.count = action.payload.count;
            state.total = action.payload.total;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addContentInstance: (state, action) => {
            state.savePending = false;
            state.contentInstances = [...state.contentInstances, action.payload];
        },
        editContentInstance: (state, action) => {
            state.savePending = false;
            const index = state.contentInstances.findIndex(
                (contentInstance) => contentInstance.id === action.payload.id,
            );
            if (index !== -1) {
                state.contentInstances = [
                    ...state.contentInstances.slice(0, index),
                    {
                        ...state.contentInstances[index],
                        ...action.payload,
                    },
                    ...state.contentInstances.slice(index + 1),
                ];
            }
        },
        deleteContentInstance: (state, action) => {
            state.pending = false;
            const index = state.contentInstances.findIndex(
                (contentInstance) => contentInstance.id === action.payload.id,
            );
            if (index !== -1) {
                state.contentInstances = [
                    ...state.contentInstances.slice(0, index),
                    ...state.contentInstances.slice(index + 1),
                ];
            }
        },
        resetContentsInstance: (state, action) => {
            state.contentInstances = [];
            state.currentPage = 0;
            state.totalPages = 0;
            state.count = 0;
            state.total = 0;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    addContentInstance,
    stop,
    setContentInstances,
    setError,
    editContentInstance,
    deleteContentInstance,
    resetContentsInstance,
    startSave,
    stopSave,
    setSaveError,
} = contentInstanceSlice.actions;

export const getContentInstances = (state) => state.contentInstance.contentInstances;
export const getContentInstancesCount = (state) => state.contentInstance.count;
export const getContentInstancesTotal = (state) => state.contentInstance.total;
export const getContentInstancesPending = (state) => state.contentInstance.pending;
export const getContentInstancesSavePending = (state) => state.contentInstance.savePending;
export const getContentInstancesError = (state) => state.contentInstance.error;
export const getContentInstanceTotalPages = (state) => state.contentInstance.totalPages;
export const getContentInstanceCurrentPage = (state) => state.contentInstance.currentPage;

export const fetchContentInstancesAction =
    ({ contentId, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(resetContentsInstance());
            dispatch(start());
            let query = transformFiltersToQuery(queryParams);
            api.get(`content-instances/${contentId}` + query)
                .then(async (response) => {
                    dispatch(setContentInstances(response.data));
                    const ids = response.data.items.map((item) => item.id);
                    try {
                        const { data } = await api.post(`communication-logs/content-instance/ids`, {
                            ids,
                        });
                        if (data) {
                            Object.keys(data).forEach((key) => {
                                dispatch(editContentInstance({ id: key, communicationLogsCount: data[key] }));
                            });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addContentInstanceAction = (contentInstanceDto, contentId, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('content-instances/' + contentId, contentInstanceDto)
            .then((response) => {
                dispatch(addContentInstance(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const editContentInstanceAction =
    (contentInstanceId, contentId, contentInstanceDto, success, failure) => async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('content-instances/' + contentId + '/' + contentInstanceId, contentInstanceDto)
                .then((response) => {
                    dispatch(editContentInstance(response.data));
                    if (success) {
                        success();
                    }
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setSaveError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteContentInstanceAction = (contentInstanceId, contentId, success) => async (dispatch) => {
    try {
        dispatch(start());
        api.delete('content-instances/' + contentId + '/' + contentInstanceId)
            .then((response) => {
                // dispatch(deleteContentInstance(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const applyTriageContentInstanceAction =
    (contentInstanceId, contentId, applyTriageDto, success, failure) => async (dispatch) => {
        try {
            dispatch(start());
            api.post('content-instances/' + contentId + '/apply-triage/' + contentInstanceId, applyTriageDto)
                .then((response) => {
                    if (success) {
                        success();
                    }
                    dispatch(stop());
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchContentInstancesCsvAction =
    ({ param, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get(`content-instances/export-to-csv/${param.contentId}${query}`)
                .then((response) => {
                    if (onSuccess) {
                        const fileName = response.headers['content-disposition'].split('filename=')[1];
                        onSuccess(response.data, fileName);
                    }
                    dispatch(stop());
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export default contentInstanceSlice.reducer;
