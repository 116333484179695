import { ArrowForwardIos } from '@mui/icons-material';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useFlexBoxStyles from 'common/flexBox';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minHeight: theme.spacing(3),
        },

        selected: {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },

        arrowIcon: {
            opacity: '0.5',
            fontSize: '11px',
        },

        border: {
            borderBottom: `1px solid ${theme.palette.common.lightGray}`,
        },
    };
});

const SettingsMenuItem = (props) => {
    const { onClick, children, size, noBorder, submenu, ...rest } = props;

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    return (
        <MenuItem
            disableGutters={true}
            className={cx(
                classes.root,
                flexBox.flex,
                flexBox.justifySpaceBetween,
                flexBox.padding1,
                !noBorder && classes.border,
            )}
            onClick={onClick}
            {...rest}
            listitemclasses={{
                root: cx(
                    classes.root,
                    flexBox.flex,
                    flexBox.justifySpaceBetween,
                    flexBox.padding1,
                    !noBorder && classes.border,
                ),
                selected: classes.selected,
            }}
        >
            <Typography fontWeight={500}>{children}</Typography>

            {Boolean(submenu) && <ArrowForwardIos className={classes.arrowIcon} />}
        </MenuItem>
    );
};

SettingsMenuItem.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    submenu: PropTypes.bool,
    size: PropTypes.oneOf(['big']),
    ...MenuItem.propTypes,
};

export default SettingsMenuItem;
