import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Accordion,
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Switch,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import LoadingActionButton from 'common/components/LoadingActionButton';
import RoundedSelect from 'common/components/RoundedSelect';
import WrapWithTooltip from 'common/components/WrapWithTooltip';
import { messageNotAuthorize } from 'common/utils/CONST';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { openCustomDialog } from 'redux/slices/customDialogSlice';
import {
    getDefaultEmailTemplates,
    getSavePending,
    loadDefaultEmailTemplateAction,
    openPreviewEmailTemplateDialog,
    sendTestEmailAction,
} from 'redux/slices/emailSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import {
    fetchEmailTemplateSettingsAction,
    getEmailTemplatesDirty,
    getSettingsEmailPending,
    setEmailTemplatesDirty,
} from 'redux/slices/settingSlice';
import { UUID } from 'uuidjs';

import CustomTextField from 'common/components/CustomTextField';
import { getUser } from 'redux/slices/authSlice';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import EditEmailTemplate from './EditEmailTemplate';
import EmailIdentity from './EmailIdentity';
import PreviewEmailTemplateDialog from './PreviewEmailTemplateDialog';

const EmailSettings = (props) => {
    const {
        langSettingValue,
        toggleChange,
        handleSubmit,
        hasChanges,
        senderNameValue,
        verifiedIdentities,
        shareEmailIdentities,
        iCall,
        iLive,
        iCalendar,
        isAuthorize,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const savePending = useSelector(getSavePending);
    const emailTemplatesDirty = useSelector(getEmailTemplatesDirty);
    const settingsPending = useSelector(getSettingsEmailPending);
    const settingsDirty = useSelector(getSettingsDirty);
    const defaultEmailTemplates = useSelector(getDefaultEmailTemplates);
    const user = useSelector(getUser);

    const EMAIL_TEMPLATES = useMemo(
        () => [
            ...(iLive
                ? [
                      {
                          name: 'subscription-event-date-updated',
                          label: t('profile.settings.emailsSettings.templates.subscriptionEventDateUpdated'),
                      },
                      {
                          name: 'subscription-event-cancelled',
                          label: t('profile.settings.emailsSettings.templates.subscriptionEventCancelled'),
                      },
                      {
                          name: 'channel-subscription',
                          label: t('profile.settings.emailsSettings.templates.channelSubscription'),
                      },
                      {
                          name: 'channel-subscription-new-event',
                          label: t('profile.settings.emailsSettings.templates.channelSubscriptionNewEvent'),
                      },
                      {
                          name: 'channel-subscription-new-shoppable',
                          label: t('profile.settings.emailsSettings.templates.channelSubscriptionNewShoppable'),
                      },
                  ]
                : []),

            ...(iLive || iCall
                ? [
                      {
                          name: 'event-invitation',
                          label: t('profile.settings.emailsSettings.templates.eventInvitation'),
                      },
                      {
                          name: 'event-subscription',
                          label: t('profile.settings.emailsSettings.templates.eventSubscription'),
                      },
                      {
                          name: 'event-subscription-reminder',
                          label: t('profile.settings.emailsSettings.templates.eventSubscriptionReminder'),
                      },
                      {
                          name: 'event-cancellation',
                          label: t('profile.settings.emailsSettings.templates.eventCancellation'),
                      },
                      {
                          name: 'event-finalized',
                          label: t('profile.settings.emailsSettings.templates.eventFinalized'),
                      },
                      {
                          name: 'game-winners',
                          label: t('profile.settings.emailsSettings.templates.eventGameWinners'),
                      },
                  ]
                : []),
            ...(iCall
                ? [
                      {
                          name: 'session-request-received',
                          label: t('profile.settings.emailsSettings.templates.sessionRequestReceived'),
                      },
                  ]
                : []),

            ...(iCalendar
                ? [
                      {
                          name: 'appointment-invitation',
                          label: t('profile.settings.emailsSettings.templates.appointmentInvitation'),
                      },
                      {
                          name: 'appointment-reminder',
                          label: t('profile.settings.emailsSettings.templates.appointmentReminder'),
                      },
                      {
                          name: 'appointment-past-pending',
                          label: t('profile.settings.emailsSettings.templates.appointmentPastPending'),
                      },
                      {
                          name: 'appointment-past-pending-agent',
                          label: t('profile.settings.emailsSettings.templates.appointmentPastPendingAgent'),
                      },
                      {
                          name: 'appointment-cancellation',
                          label: t('profile.settings.emailsSettings.templates.appointmentCancellation'),
                      },
                      {
                          name: 'satisfaction-survey',
                          label: t('profile.settings.emailsSettings.templates.satisfactionSurvey'),
                      },
                      {
                          name: 'access-code',
                          label: t('profile.settings.emailsSettings.templates.accessCode'),
                      },
                  ]
                : []),
            {
                name: 'password-reset',
                label: t('profile.settings.emailsSettings.templates.passwordReset'),
            },
            {
                name: 'invitation-confirm',
                label: t('profile.settings.emailsSettings.templates.invitationConfirm'),
            },
            {
                name: 'report-completed',
                label: t('profile.settings.emailsSettings.templates.reportCompleted'),
            },
            {
                name: 'report-request-error',
                label: t('profile.settings.emailsSettings.templates.reportRequestError'),
            },
        ],
        [iLive, iCall, iCalendar, t],
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const [templatesSetting, setTemplatesSetting] = useState({});
    const [senderNameSetting, setSenderNameSetting] = useState(senderNameValue);
    const [verifiedIdentitiesSetting, setVerifiedIdentitiesSetting] = useState(verifiedIdentities);
    const [shareEmailIdentitiesSetting, setShareEmailIdentitiesSetting] = useState(shareEmailIdentities);
    const [emailTemplate, setEmailTemplate] = useState(EMAIL_TEMPLATES.length > 0 ? EMAIL_TEMPLATES[0].name : null);
    const [editingPart, setEditingPart] = useState('');
    const defaultEmailTemplatesRef = useRef(defaultEmailTemplates);
    const templatesSettingRef = useRef(templatesSetting);

    useEffect(() => {
        dispatch(
            fetchEmailTemplateSettingsAction((data) => {
                setTemplatesSetting(data);
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (!savePending) {
            dispatch(
                fetchEmailTemplateSettingsAction((data) => {
                    setTemplatesSetting(data);
                }),
            );
        }
    }, [savePending, dispatch]);

    useEffect(() => {
        setEmailTemplate(EMAIL_TEMPLATES.length > 0 ? EMAIL_TEMPLATES[0].name : null);
    }, [EMAIL_TEMPLATES, setEmailTemplate]);

    useEffect(() => {
        defaultEmailTemplatesRef.current = defaultEmailTemplates;
    }, [defaultEmailTemplates]);

    useEffect(() => {
        templatesSettingRef.current = templatesSetting;
    }, [templatesSetting]);

    const getEmailTemplate = function (emailTemplateName, language, part) {
        let template = '';
        if (templatesSettingRef?.current[language] && templatesSettingRef?.current[language][emailTemplateName]) {
            template = templatesSettingRef.current[language][emailTemplateName][part];
        }

        if (
            !template &&
            defaultEmailTemplates &&
            defaultEmailTemplates[language] &&
            defaultEmailTemplates[language][emailTemplateName]
        ) {
            template = defaultEmailTemplates[language][emailTemplateName][part];
        }

        return template;
    };

    const getDefaultEmailTemplate = (template, lang, part, callback) => {
        let content = '';
        if (defaultEmailTemplatesRef?.current[lang] && defaultEmailTemplatesRef.current[lang][template]) {
            content = defaultEmailTemplatesRef.current[lang][template][part];
        }
        if (content) {
            callback(content);
        } else {
            dispatch(
                loadDefaultEmailTemplateAction(template, part, lang, (data) => {
                    callback(data.template);
                }),
            );
        }
    };

    const handleEmailTemplateChange = (e) => {
        setEmailTemplate(e.target.value);
    };

    const handleSenderNameChange = (e) => {
        setSenderNameSetting(e.target.value);
        handleChange(e, SettingsKey.EMAIL_SENDER_NAME);
    };

    const handleShareEmailIdentitiesChange = (e) => {
        setShareEmailIdentitiesSetting(e.target.checked);
        toggleChange(SettingsKey.SHARE_EMAIL_IDENTITIES, e.target.checked);
    };

    const handleEmailIdentitiesUpdated = useCallback(
        (identity, action = 'added' | 'deleted' | 'markAsDefault' | 'statusChecked') => {
            setVerifiedIdentitiesSetting((prevEntities) => {
                let newIdentities;
                if (action === 'added') {
                    newIdentities = [...prevEntities, identity];
                } else if (action === 'deleted') {
                    newIdentities = prevEntities.filter((i) => i.name !== identity.name);
                } else if (action === 'statusChecked') {
                    newIdentities = prevEntities.map((i, index) => (i.name === identity.name ? identity : { ...i }));
                } else {
                    newIdentities = prevEntities.map((i) =>
                        i.name === identity.name ? identity : { ...i, default: false },
                    );
                }
                return newIdentities;
            });
        },
        [setVerifiedIdentitiesSetting],
    );

    useEffect(() => {
        if (!_.isEqual(verifiedIdentitiesSetting, verifiedIdentities)) {
            toggleChange(SettingsKey.ORGANIZATION_EMAIL_IDENTITIES, verifiedIdentitiesSetting);
        }
    }, [verifiedIdentitiesSetting, toggleChange, verifiedIdentities]);

    const handleEmailTemplatePartChange = function (template, lang, part, value) {
        let newValue = _.cloneDeep(templatesSettingRef.current);
        if (!newValue[lang]) {
            newValue[lang] = {};
        }
        if (!newValue[lang][template]) {
            newValue[lang][template] = {
                name: template,
                lang: lang,
                text: '',
                html: '',
                subject: '',
            };
        }

        newValue[lang][template][part] = value;
        dispatch(setEmailTemplatesDirty(true));
        setTemplatesSetting(newValue);
        toggleChange(SettingsKey.ORGANIZATION_EMAILS_TEMPLATES, newValue);
    };

    const handlePreview = function (part) {
        const body = getEmailTemplate(emailTemplate, langSettingValue, part);
        dispatch(openPreviewEmailTemplateDialog({ template: emailTemplate, body, part, lang: langSettingValue }));
    };

    const handleSendTestEmail = function () {
        dispatch(sendTestEmailAction(emailTemplate, (msg) => enqueueSnackbar(msg, { variant: 'success' })));
    };

    const handleEdit = function (part, title) {
        setEditingPart(part);
        const body = getEmailTemplate(emailTemplate, langSettingValue, part);
        if (body) {
            openEditCustomDialog(title, body, part);
        } else {
            dispatch(
                loadDefaultEmailTemplateAction(emailTemplate, part, langSettingValue, (data) => {
                    openEditCustomDialog(title, data.template, part);
                }),
            );
        }
    };

    const openEditCustomDialog = (title, content, part) => {
        dispatch(
            openCustomDialog(
                <EditEmailTemplate
                    title={title}
                    templateContent={content}
                    lang={langSettingValue}
                    emailTemplate={emailTemplate}
                    part={part}
                    handlePreview={handlePreview}
                    handleEmailTemplatePartChange={handleEmailTemplatePartChange}
                    getDefaultEmailTemplate={getDefaultEmailTemplate}
                />,
            ),
        );
    };

    const templateSettingRow = (leftComponent, rightComponent) => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: '20px 0 0 0',
            }}
        >
            <div id="leftSide" style={{ width: '45%' }}>
                {leftComponent}
            </div>

            <div id="rigthSide" style={{ width: '50%', alignSelf: 'end' }}>
                <div>{rightComponent}</div>
            </div>
        </div>
    );

    const perButtonLayout = (button1, button2) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>{button1}</div>
            <div>{button2}</div>
        </div>
    );

    const templateActionButtonStyle = {
        width: '100%',
        height: '35px',
        borderRadius: '20px',
        textTransform: 'uppercase',
        padding: '0 30px',
        fontSize: '11px',
    };

    const emailTestButtonStyle = {
        width: '100%',
        height: '35px',
        borderRadius: '20px',
        textTransform: 'uppercase',
        fontSize: '11px',
    };

    const templateSelectorStyle = {
        width: '100%',
        height: '35px',
        fontSize: '11px',
    };

    const senderInputStyle = {
        width: '100%',
        fontSize: '11px',
    };

    const renderEmailSettings = ({ disabled, disabledEditEmail, isMultiProfile, disabledEditEmailTemplate }) => (
        <React.Fragment>
            <Accordion disabled={disabled} className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('profile.settings.emailsSettings.emailSettings.emails')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.eventSection}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <Typography className={classes.emailsTemplatesText}>
                            {t('profile.settings.emailsSettings.emailSettings.setPreferences')}
                        </Typography>
                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('profile.settings.emailsSettings.emailSettings.save')}
                            </Button>
                        </Box>
                    </Box>
                    <ValidatorForm onSubmit={() => {}} onError={() => {}}>
                        <Box sx={{ display: 'block' }}>
                            <FormControl style={{ width: '100%', margin: '5px 0 0 0', display: 'block' }}>
                                <WrapWithTooltip
                                    style={{ width: '100%' }}
                                    title={messageNotAuthorize}
                                    wrap={disabledEditEmail}
                                    placement={'left'}
                                >
                                    <CustomTextValidator
                                        name="firstName"
                                        value={senderNameSetting}
                                        variant="outlined"
                                        onChange={(e) => handleSenderNameChange(e)}
                                        fullWidth={true}
                                        style={senderInputStyle}
                                        placeholder={t('profile.settings.emailsSettings.emailSettings.senderName')}
                                        disabled={savePending || disabledEditEmail || settingsPending}
                                    />
                                </WrapWithTooltip>
                            </FormControl>
                            <Typography style={{ width: '100%', margin: '8px 0 0 0', display: 'block' }}>
                                {t('profile.settings.emailsSettings.emailSettings.emailIdentities')}
                            </Typography>
                            {verifiedIdentities?.length > 0 && isMultiProfile && (
                                <FormControl style={{ width: '100%', margin: '5px 0 0 0', display: 'block' }}>
                                    <WrapWithTooltip
                                        style={{ width: '100%' }}
                                        title={messageNotAuthorize}
                                        wrap={disabledEditEmail}
                                        placement={'left'}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={shareEmailIdentitiesSetting}
                                                    onChange={handleShareEmailIdentitiesChange}
                                                    name="active"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <FormLabel>
                                                    {t(
                                                        'profile.settings.emailsSettings.emailSettings.shareEmailIdentities',
                                                    )}
                                                </FormLabel>
                                            }
                                        />
                                    </WrapWithTooltip>
                                </FormControl>
                            )}
                            <FormControl style={{ width: '100%', margin: '5px 0 0 0', display: 'block' }}>
                                <WrapWithTooltip
                                    style={{ width: '100%' }}
                                    title={messageNotAuthorize}
                                    wrap={disabledEditEmail}
                                    placement={'left'}
                                >
                                    {verifiedIdentitiesSetting &&
                                        [
                                            ...verifiedIdentitiesSetting,
                                            { name: '', default: false, status: 'Pending' },
                                        ].map((identity, index) => {
                                            return (
                                                <EmailIdentity
                                                    key={UUID.generate()}
                                                    identity={identity}
                                                    onAdded={(i) => handleEmailIdentitiesUpdated(i, 'added')}
                                                    onDeleted={(i) => handleEmailIdentitiesUpdated(i, 'deleted')}
                                                    onMarkAsDefault={(i) =>
                                                        handleEmailIdentitiesUpdated(i, 'markAsDefault')
                                                    }
                                                    onStatusCheck={(i) =>
                                                        handleEmailIdentitiesUpdated(i, 'statusChecked')
                                                    }
                                                />
                                            );
                                        })}
                                </WrapWithTooltip>
                            </FormControl>
                        </Box>
                    </ValidatorForm>

                    {templatesSetting && (
                        <div>
                            {templateSettingRow(
                                <div>
                                    <Typography className={classes.emailsTemplatesText}>
                                        {t('profile.settings.emailsSettings.emailSettings.template')}
                                    </Typography>
                                    <RoundedSelect
                                        value={emailTemplate}
                                        style={templateSelectorStyle}
                                        fullWidth
                                        onChange={(e) => handleEmailTemplateChange(e)}
                                        iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                                    >
                                        {EMAIL_TEMPLATES.map((template, idx) => {
                                            return (
                                                <MenuItem
                                                    key={`${template.name}-${idx}`}
                                                    value={template.name}
                                                    name={template.label}
                                                >
                                                    {template.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </RoundedSelect>
                                </div>,
                                <div>
                                    <ActionButton
                                        variant="contained"
                                        color="primary"
                                        disabled={savePending || emailTemplatesDirty || settingsPending}
                                        onClick={() => handleSendTestEmail()}
                                        style={emailTestButtonStyle}
                                    >
                                        {t('profile.settings.emailsSettings.emailSettings.sendTest')}
                                    </ActionButton>
                                </div>,
                            )}

                            {templateSettingRow(
                                <div>
                                    <Typography className={classes.emailsTemplatesText}>
                                        {t('profile.settings.emailsSettings.emailSettings.emailHtmlBodyTitle')}
                                    </Typography>
                                </div>,
                                perButtonLayout(
                                    !disabledEditEmailTemplate ? (
                                        <WrapWithTooltip
                                            title={messageNotAuthorize}
                                            wrap={disabledEditEmail}
                                            placement={'left'}
                                        >
                                            <LoadingActionButton
                                                loaderColor="primary"
                                                variant="contained"
                                                color="secondary"
                                                loading={settingsPending && editingPart === 'html'}
                                                disabled={savePending || disabledEditEmail || settingsPending}
                                                handleButtonClick={() =>
                                                    handleEdit(
                                                        'html',
                                                        t(
                                                            'profile.settings.emailsSettings.emailSettings.emailHtmlBody',
                                                        ),
                                                    )
                                                }
                                                label={t(
                                                    'profile.settings.emailsSettings.emailSettings.editEmailHtmlBody',
                                                )}
                                                style={templateActionButtonStyle}
                                            />
                                        </WrapWithTooltip>
                                    ) : null,
                                    <ActionButton
                                        disabled={savePending || settingsPending}
                                        onClick={() => handlePreview('html')}
                                        variant="contained"
                                        color="secondary"
                                        style={templateActionButtonStyle}
                                    >
                                        {t('profile.settings.emailsSettings.emailSettings.previewEmailHtmlBody')}
                                    </ActionButton>,
                                ),
                            )}

                            {templateSettingRow(
                                <Typography className={classes.emailsTemplatesText}>
                                    {t('profile.settings.emailsSettings.emailSettings.emailTextBodyTitle')}
                                </Typography>,
                                perButtonLayout(
                                    !disabledEditEmailTemplate ? (
                                        <WrapWithTooltip
                                            title={messageNotAuthorize}
                                            wrap={disabledEditEmail}
                                            placement={'left'}
                                        >
                                            <LoadingActionButton
                                                loaderColor="primary"
                                                variant="contained"
                                                color="secondary"
                                                loading={settingsPending && editingPart === 'text'}
                                                disabled={savePending || disabledEditEmail || settingsPending}
                                                handleButtonClick={() =>
                                                    handleEdit(
                                                        'text',
                                                        t(
                                                            'profile.settings.emailsSettings.emailSettings.emailTextBody',
                                                        ),
                                                    )
                                                }
                                                label={t(
                                                    'profile.settings.emailsSettings.emailSettings.editEmailTextBody',
                                                )}
                                                style={templateActionButtonStyle}
                                            />
                                        </WrapWithTooltip>
                                    ) : null,
                                    <ActionButton
                                        disabled={savePending || settingsPending}
                                        onClick={() => handlePreview('text')}
                                        variant="contained"
                                        color="secondary"
                                        style={templateActionButtonStyle}
                                    >
                                        {t('profile.settings.emailsSettings.emailSettings.previewEmailTextBody')}
                                    </ActionButton>,
                                ),
                            )}

                            {templateSettingRow(
                                <Typography className={classes.emailsTemplatesText}>
                                    {t('profile.settings.emailsSettings.emailSettings.emailSubjectBodyTitle')}
                                </Typography>,
                                perButtonLayout(
                                    !disabledEditEmailTemplate ? (
                                        <WrapWithTooltip
                                            title={messageNotAuthorize}
                                            wrap={disabledEditEmail}
                                            placement={'left'}
                                        >
                                            <LoadingActionButton
                                                loaderColor="primary"
                                                variant="contained"
                                                color="secondary"
                                                loading={settingsPending && editingPart === 'subject'}
                                                disabled={savePending || disabledEditEmail || settingsPending}
                                                handleButtonClick={() =>
                                                    handleEdit(
                                                        'subject',
                                                        t(
                                                            'profile.settings.emailsSettings.emailSettings.emailSubjectBody',
                                                        ),
                                                    )
                                                }
                                                label={t(
                                                    'profile.settings.emailsSettings.emailSettings.editEmailSubjectBody',
                                                )}
                                                style={templateActionButtonStyle}
                                            />
                                        </WrapWithTooltip>
                                    ) : null,
                                    <ActionButton
                                        disabled={savePending || settingsPending}
                                        onClick={() => handlePreview('subject')}
                                        variant="contained"
                                        color="secondary"
                                        style={templateActionButtonStyle}
                                    >
                                        {t('profile.settings.emailsSettings.emailSettings.previewEmailSubjectBody')}
                                    </ActionButton>,
                                ),
                            )}

                            <div style={{ paddingBottom: '20px', marginTop: '20px' }}>
                                <Typography className={classes.text}>
                                    {t('settings.events.formatDateEmailTitle')}
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: '60%',
                                        // marginTop: '10px'
                                    }}
                                >
                                    <Box style={{ width: '100%' }}>
                                        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                            <FormControl>
                                                <CustomTextField
                                                    value={props.formatDateEmail}
                                                    onChange={(e) =>
                                                        toggleChange(SettingsKey.FORMAT_DATE_EMAIL, e.target.value)
                                                    }
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    variant="outlined"
                                                    size="small"
                                                    disabled={savePending || settingsPending}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>

                            <div style={{ marginTop: '5px' }}>
                                <Typography className={classes.text}>
                                    {t('settings.events.formatTimeEmailTitle')}
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: '60%',
                                        // marginTop: '10px'
                                    }}
                                >
                                    <Box style={{ width: '100%' }}>
                                        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                            <FormControl>
                                                <CustomTextField
                                                    value={props.formatTimeEmail}
                                                    onChange={(e) =>
                                                        toggleChange(SettingsKey.FORMAT_TIME_EMAIL, e.target.value)
                                                    }
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    variant="outlined"
                                                    size="small"
                                                    disabled={savePending || settingsPending}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>

                            <Box sx={{ display: emailTemplatesDirty ? 'block' : 'none', p: 2 }}>
                                <Alert variant="outlined" severity="warning">
                                    {t('profile.settings.emailsSettings.emailSettings.saveMessage')}
                                </Alert>
                            </Box>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
            <PreviewEmailTemplateDialog />
        </React.Fragment>
    );

    return (
        <>
            {renderEmailSettings({
                disabled: false,
                disabledEditEmail: !isAuthorize(['full_look_and_feel']),
                isMultiProfile: isAuthorize(['multiProfile'], true),
                disabledEditEmailTemplate: user.role === 'creator' || user.userGroupId,
            })}
        </>
    );
};

export default EmailSettings;
