import { FormGroup, FormLabel, Grid } from '@mui/material';
import CustomTextValidator from 'common/components/CustomTextValidators';
import { useFormFields } from 'libs/hooksLib';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTemplateView } from 'redux/slices/ocalendarSlice';

const OTemplateSchedule = (props) => {
    const { classes, onChange, isReadOnly } = props;

    const { t } = useTranslation();
    const template = useSelector(getTemplateView);

    const [fields, handleFieldChange] = useFormFields({
        id: template.id,
        title: template.title,
        duration: template.duration,
        minimumTimeToSchedule: template.minimumTimeToSchedule,
        maximumTimeToSchedule: template.maximumTimeToSchedule,
        timeBetweenEvents: template.timeBetweenEvents,
        timeIncrementation: template.timeIncrementation,
        profileId: template.profileId,
    });

    const handleTemplateChange = () => {
        onChange(fields);
    };

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{t('common.term.Title')}</FormLabel>
                        <CustomTextValidator
                            name="title"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.title}
                            onChange={handleFieldChange}
                            onBlur={handleTemplateChange}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                            InputProps={{
                                readOnly: isReadOnly,
                            }}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{`${t('common.term.Duration')} (${t('common.term.Minutes')})`}</FormLabel>
                        <CustomTextValidator
                            name="duration"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.duration}
                            onChange={handleFieldChange}
                            onBlur={handleTemplateChange}
                            type="number"
                            InputProps={{
                                inputProps: { min: 0, max: 60, step: 5 },
                                readOnly: isReadOnly,
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Grid>
                <Grid item md={6}>
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{`${t('calendar.timeBetweenEvents')} (${t('common.term.Minutes')})`}</FormLabel>
                        <CustomTextValidator
                            name="timeBetweenEvents"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.timeBetweenEvents}
                            onChange={handleFieldChange}
                            onBlur={handleTemplateChange}
                            type="number"
                            InputProps={{
                                inputProps: { min: 0, max: 60, step: 5 },
                                readOnly: isReadOnly,
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{`${t('calendar.minimumTimeToSchedule')} (${t('common.term.Days')})`}</FormLabel>
                        <CustomTextValidator
                            name="minimumTimeToSchedule"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.minimumTimeToSchedule}
                            onChange={handleFieldChange}
                            onBlur={handleTemplateChange}
                            type="number"
                            InputProps={{
                                inputProps: { min: 0, max: 30, step: 1 },
                                readOnly: isReadOnly,
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Grid>
                <Grid item md={6}>
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{`${t('calendar.maximumTimeToSchedule')} (${t('common.term.Days')})`}</FormLabel>
                        <CustomTextValidator
                            name="maximumTimeToSchedule"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.maximumTimeToSchedule}
                            onChange={handleFieldChange}
                            onBlur={handleTemplateChange}
                            type="number"
                            InputProps={{
                                inputProps: { min: 1, step: 1 },
                                readOnly: isReadOnly,
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{`${t('calendar.timeIncrementation')} (${t('common.term.Minutes')})`}</FormLabel>
                        <CustomTextValidator
                            name="timeIncrementation"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.timeIncrementation}
                            onChange={handleFieldChange}
                            onBlur={handleTemplateChange}
                            type="number"
                            InputProps={{
                                inputProps: { min: 0, max: 120, step: 5 },
                                readOnly: isReadOnly,
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default OTemplateSchedule;
