import { Divider, Skeleton, Stack, TableCell, TableRow } from '@mui/material';
import useColorsStyles from 'common/colors';
import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minWidth: theme.spacing(33),
            maxWidth: theme.spacing(45),
            flexBasis: theme.spacing(30),
        },
    };
});

const SkeletonEventItem = (props) => {
    const { tableRow, cellCount = 4 } = props;
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: colors } = useColorsStyles();

    return (
        <>
            {!tableRow ? (
                <Stack className={cx(flexBox.grow1, flexBox.flex1, flexBox.padding3, flexBox.borderBox, classes.root)}>
                    <Stack aria-hidden={true} className={cx(flexBox.relative, flexBox.paddingBottom1)}>
                        <Stack direction="row" justifyContent="center" className={colors.bgPaper}>
                            <Skeleton
                                component="div"
                                variant="rectangular"
                                sx={{ height: '160px', width: '100%' }}
                            ></Skeleton>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={0.5}
                            className={cx(flexBox.marginTop1, flexBox.overflowHidden)}
                        >
                            <Skeleton variant="text" sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
                                <span>event</span>
                            </Skeleton>

                            <Stack direction="column">
                                <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                                <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                            </Stack>
                        </Stack>
                    </Stack>

                    <Divider />

                    <Stack direction="column" className={flexBox.margin050}>
                        <Skeleton variant="text" sx={{ fontSize: '0.8rem' }}>
                            <span>0 Users</span>
                        </Skeleton>
                    </Stack>

                    <Divider />
                </Stack>
            ) : (
                <TableRow>
                    <TableCell>
                        <Skeleton variant="text"></Skeleton>
                    </TableCell>
                    <TableCell>
                        <Skeleton
                            component="div"
                            variant="rectangular"
                            sx={{ height: '4rem', width: '100%' }}
                        ></Skeleton>
                    </TableCell>
                    {Array.from({ length: cellCount }, (c) => (
                        <TableCell key={c}>
                            <Skeleton variant="text"></Skeleton>
                        </TableCell>
                    ))}
                </TableRow>
            )}
        </>
    );
};

export default SkeletonEventItem;
