import React from "react";
import BaseCodeInputMonacoEditor from "./BaseCodeInputMonacoEditor";

/**
 * This a CodeMonaco based component.
 * 
 * onChange: (value, errors) => {}
 *  value: String.
 *  errors: String array. Each item is an error messages with the line and column.
 * 
 * value: String
 * 
 * height
 * Just some CSS valid height property.
 */
const CssCodeInputMonacoEditor = (props) => {
    const {
        value,
        onChange,
        height
    } = props;
    
    return (
        <div style={{ margin: '0 0 10px 0' }}>
            <div>
                <BaseCodeInputMonacoEditor
                    height={height || "200px"}
                    defaultLanguage="css"
                    defaultValue={value}
                    onChange={(content, errors) => onChange(content, errors) }
                />
            </div>
        </div>
    );
}

export default CssCodeInputMonacoEditor;