import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTextFieldDescription = styled((props) => <TextField {...props} className={props.className} />)(
    ({ theme }) => {
        return {
            '& .MuiInputBase-root': {
                background: '#FFFF',
                borderRadius: theme.spacing(2),
                [theme.breakpoints.up('xl')]: {
                    height: 'auto',
                    borderRadius: theme.spacing(2.5),
                },

                '& > fieldset': {
                    borderColor: '#E2E2E2',
                },

                '& > input': {
                    padding: theme.spacing(0, 1),
                    height: '100%',
                    fontSize: '12px',
                    fontWeight: '500',
                    position: 'relative',
                    '&:focus': {
                        borderRadius: 5,
                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: '14px',
                    },
                },
            },
        };
    },
);

export default CustomTextFieldDescription;
