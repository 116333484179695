import LayoutView from 'common/layouts/LayoutView';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { fetchAvailabilitiesByCalendarAction, getAvailabilitiesPending } from 'redux/slices/myAvailabilitySlice';
import { fetchServicesAction } from 'redux/slices/serviceSlice';
import 'suneditor/dist/css/suneditor.min.css';
import MyAvailabilitySection from './MyAvailabilitySection';

const MyAvailability = ({ userData, showTotal }) => {
    const { userId, userView } = userData;
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const pending = useSelector(getAvailabilitiesPending);

    const getServices = useCallback(
        (data) => {
            if (data?.length) {
                const uniqueIds = new Set();

                data.forEach((item) => {
                    if (item.templatesWeeklySchedule) {
                        item.templatesWeeklySchedule.forEach((schedule) => {
                            uniqueIds.add(schedule.templateId);
                        });
                    }
                });

                const ids = Array.from(uniqueIds);

                if (ids?.length) {
                    dispatch(
                        fetchServicesAction({
                            queryParams: { expand: ['relatedCalendars', 'dateRanges'], ids },
                        }),
                    );
                }
            }
        },
        [dispatch],
    );

    const loadData = useCallback(() => {
        dispatch(
            fetchAvailabilitiesByCalendarAction(userId || user.userId, { onSuccess: (data) => getServices(data) }),
        );
    }, [dispatch, getServices, user.userId, userId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const section = (
        <MyAvailabilitySection
            userData={{ ...userData, userId: userData.userId || user.userId }}
            showTotal={showTotal}
        />
    );

    return <>{userView ? section : <LayoutView pending={pending}>{section}</LayoutView>}</>;
};

export default MyAvailability;
