import { CircularProgress } from '@mui/material';
import axios from 'axios';
import config from 'config';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrganization } from 'redux/slices/organizationSlice';
import { from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import styles from 'views/styles/Login.module.css';

function CloudStoreAuthorize() {
    const { t } = useTranslation();

    const organization = useSelector(getOrganization);
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const query = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location?.search]);

    const navigate = useNavigate();

    const handleError = useCallback(
        (error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                enqueueSnackbar(t('views.cloudStoreAuthorize.wrongPass'), {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
            }
            navigate(`/cloudstore/authorize/callback?state=${query.get('state')}`);
        },
        [enqueueSnackbar, navigate, query, t],
    );

    useEffect(() => {
        if (location.pathname === '/cloudstore/authorize/callback') {
            const state = query.get('state');
            const code = query.get('code');
            if (state && code) {
                const subscription = from(
                    axios.post(config.oauth.cloudStoreCodeExchangeURL, {
                        code,
                        state,
                    }),
                )
                    .pipe(
                        catchError((err) => {
                            throw new Error(err.message);
                        }),
                    )
                    .subscribe(({ data: { jwtToken: accessToken } }) => {
                        const api = axios.create({
                            baseURL: config.api.baseURL,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                        api.post('auth/check', {})
                            .then(({ data }) => {
                                enqueueSnackbar(t('views.cloudStoreAuthorize.loginSuccess'), {
                                    variant: 'success',
                                });
                                const user = data.user;
                                return { user, accessToken };
                            })
                            .then(({ user, accessToken }) => {
                                return api.put(
                                    'cloudstore/users/' + user.id,
                                    {
                                        organizationId: user.organizationId,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        accessToken,
                                    }
                                );
                            })
                            .then(() => {
                                navigate('/');
                                return true;
                            })
                            .catch((error) => {
                                let errorMessage = error.message;
                                enqueueSnackbar(errorMessage, {
                                    variant: 'error',
                                });
                                navigate('/');
                            })
                    }, handleError);

                return () => subscription.unsubscribe();
            } else {
                navigate('/authorize');
            }
        }
    }, [enqueueSnackbar, handleError, location.pathname, navigate, query, t]);

    return (
        <div className={styles.main}>
            <div className={styles.imageContainer}>
                <img alt="" src={organization.mainImage} style={{ width: '100%' }} />
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.loadingContainer}>
                    <CircularProgress />
                </div>
            </div>
        </div>
    );
}

export default CloudStoreAuthorize;
