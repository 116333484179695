export const MIN_DURATION = 10;
export const MAX_DURATION = 300;

export const MODE_AUTOMATIC = "automatic";
export const MODE_MANUAL = "manual";

export const QUIZ = "quiz";
export const SURVEY = "survey";
export const AUCTION = "auction";
export const RAFFLE = "raffle";

export const TYPE_PRIZE_PROMOTIONAL_CODE = 'promotional_code';
export const TYPE_PRIZE_PRODUCT = 'product';
export const TYPE_PRIZE_CUSTOM = 'custom';

export const WINNER_SELECTION_BY_TIME = 'time';
export const WINNER_SELECTION_BY_RAFFLE = 'raffle';

export const GAME_SETUP_STEP_SETTINGS = 1;
export const GAME_SETUP_STEP_QUESTIONS = 2;
export const GAME_SETUP_STEP_PRIZE = 3;