import { Stack, Typography } from '@mui/material';
import ReloadButton from 'common/components/ReloadButton';
import ERROR_IMAGE_URL from 'common/constants/errorImageUrl';
import useFlexBoxStyles from 'common/flexBox';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()((theme) => {
    return {
        errorImage: {
            height: '25vh',
        },
    };
});

const SettingsError = () => {
    const { t } = useTranslation();
    const { classes, cx } = useStyle();
    const { classes: flexBox } = useFlexBoxStyles();

    const onReload = () => {
        window.location.reload();
    };

    return (
        <Stack direction="column" justifyContent={'center'} alignItems={'center'} className={flexBox.h60} spacing={2}>
            <Stack direction="column">
                <img alt="error-page" className={cx(classes.errorImage)} src={ERROR_IMAGE_URL} />
            </Stack>
            <Typography variant="onliveH4" fontWeight={500}>
                {t('app.common.settingsErrorText')}
            </Typography>
            <Typography variant="onliveH5" fontWeight={500}>
                {t('app.common.settingsErrorMessage')}
            </Typography>
            <ReloadButton onReload={onReload} />
        </Stack>
    );
};

export default SettingsError;
