import { CircularProgress } from '@mui/material';
import { BffApi } from 'api';
import useFullLoadingStyles from 'components/styles/useFullLoadingStyles';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { from } from 'rxjs';
import { catchError } from 'rxjs/operators';

function OAuthInvitation(props) {
    const { type } = props;
    const { classes } = useFullLoadingStyles();
    const { token } = useParams();
    const navigate = useNavigate();

    const handleError = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        const subscription = from(
            BffApi.post('oauth/auth-url', {
                redirectUri: `${window.location.origin.toString()}/authorize/callback`,
                ...(type === 'sign_up' ? { signUpToken: token } : { invitationToken: token }), // otherwise type === 'invitation'
            })
        )
            .pipe(
                catchError(() => {
                    navigate('/login');
                }),
            )
            .subscribe(({ data: { authorizationUrl } }) => {
                window.location.href = authorizationUrl;
            }, handleError);

        return () => subscription.unsubscribe();
    }, [handleError, navigate, token, type]);

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
}

export default OAuthInvitation;
