import LanguageIcon from '@mui/icons-material/Language';
import { FormGroup, IconButton } from '@mui/material';
import FormI18nLabel from 'app/form/form/FormI18nLabel';
import CustomTextValidator from 'common/components/CustomTextValidators';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openNestedDialog } from 'redux/slices/nestedDialogSlice';

const FormFieldLabelI18n = ({ value, handleLabelChange, handleI18nLabelsSubmit, type, labels }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <FormGroup>
            <CustomTextValidator
                name="textLabelEn"
                fullWidth={true}
                variant="outlined"
                size="small"
                autoComplete="new-label"
                value={value}
                onChange={handleLabelChange}
                validators={['required']}
                errorMessages={[t('app.common.required')]}
                helperText={t('profile.settings.viewerExtraField.fieldForm.enDefaultLabelHelper')}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            onClick={() => {
                                dispatch(
                                    openNestedDialog(
                                        <FormI18nLabel
                                            fieldLabels={labels}
                                            onSubmit={handleI18nLabelsSubmit}
                                            type={type}
                                        />,
                                    ),
                                );
                            }}
                        >
                            <LanguageIcon />
                        </IconButton>
                    ),
                }}
            />
        </FormGroup>
    );
};

export default FormFieldLabelI18n;
