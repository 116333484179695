import { makeStyles } from 'tss-react/mui';

const useStylesChatRequests = makeStyles()(() => {
    return {
        requests: {
            padding: '0 20px',
            zIndex: '1000000',
            backgroundColor: '#fff',
            borderRadius: '0 0 10px 10px',
            position: 'fixed',
            left: '60%',
            transform: 'translateX(-50%)',
            top: '-94px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
            width: '320px',
            maxHeight: '93px',
            overflow: 'hidden',
            transition: 'all ease-in-out 0.3s',
        },
        hideBtn: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0',
            right: '0',
            color: '#000',

            '& button': {
                color: '#000',

                '&:hover': {
                    color: '#000',
                    backgroundColor: 'transparent',
                },
            },
        },
        requestsMenu: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: '92px',
            borderBottom: '1px solid #EFEFEF',
        },
        requests_open: {
            top: '0',
        },
        collapseContent: {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            left: '0',
            color: '#000',

            '&:hover': {
                backgroundColor: '#F8F8F8',
            },
            '& svg': {
                transform: 'scale(1.2)',
            },
        },

        requests_expand: {
            maxHeight: '400px',
        },
        right: {
            left: 'calc(60% + 200px)',
        },
        request_icon: {
            width: '46px',
            height: '46px',
            borderRadius: '50%',
            backgroundColor: '#01E851',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '& svg': {
                fontSize: '24px',
                color: '#fff',
            },
        },
        new_request: { flex: '1', fontSize: '12px', marginLeft: '10px' },
        pick_up: {
            backgroundColor: '#fff',
            width: '96px',
            height: '32px',
            borderRadius: '16px',
            border: '1px solid #707070',
            fontWeight: 'bold',
            cursor: 'pointer',

            '&:hover': {
                backgroundColor: '#EFEFEF',
                color: '#fff',
            },
        },
        requests_list: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '32px',
        },
        request: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
            //no last-child
            '&:not(:last-child)': {
                borderBottom: '1px solid #EFEFEF',
            },
            '&:hover': {
                backgroundColor: '#F8F8F8',
                //third child
            },
            cursor: 'pointer',
        },
        request_icon_user: {
            width: '32px',
            height: '32px',
            minWidth: '32px',
            minHeight: '32px',
            borderRadius: '50%',
            backgroundColor: '#01E851',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
        },

        request_name: {
            fontSize: '14px',
            margin: '0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };
});

export default useStylesChatRequests;
