import { FormGroup, FormLabel, Grid, Stack } from '@mui/material';
import useStylesFieldForm from 'app/profile/settings/styles/useStylesFieldForm.styles';
import useStylesViewerExtraFields from 'app/profile/settings/styles/useStylesViewerExtraFields.styles';
import ActionButton from 'common/components/ActionButton';
import CustomTextFieldDescription from 'common/components/CustomTextFieldDescription';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useSupportedLangs } from 'common/constants/langs';
import useFlexBoxStyles from 'common/flexBox';
import LayoutForm from 'common/layouts/LayoutForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { closeNestedDialogAction } from 'redux/slices/nestedDialogSlice';

const languageCodes = ['en', 'es', 'fr', 'it', 'pt', 'pt-br', 'de', 'pl', 'uk', 'ro', 'nl-be', 'sr', 'nl', 'sv'];

const FormI18nLabel = (props) => {
    const { onSubmit, fieldLabels, type } = props;

    const { classes: formClasses } = useStylesForm();
    const { classes: viewerExtraFieldsClasses, cx } = useStylesViewerExtraFields();
    const { classes: FieldFormClasses } = useStylesFieldForm();
    const { classes: flexBox } = useFlexBoxStyles();
    const classes = { ...formClasses, ...viewerExtraFieldsClasses, ...FieldFormClasses, ...flexBox };

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const langs = useSupportedLangs().filter((lang) => lang.languageCode !== 'en');

    const initialValue =
        fieldLabels && fieldLabels.length > 0
            ? fieldLabels
            : languageCodes.map((languageCode) => ({
                  languageCode,
                  value: '',
              }));

    const [value, setValue] = useState(initialValue);

    const handleLangFieldChange = (e, languageCode) => {
        let newLabels = value.filter((label) => label.languageCode !== languageCode);
        newLabels.push({ languageCode: languageCode, value: e.target.value });
        setValue(newLabels);
    };

    const onClose = () => {
        dispatch(closeNestedDialogAction());
    };

    const handleSubmit = () => {
        onSubmit({ name: 'label', value }, () => onClose());
    };

    const handleError = (e) => {
        console.debug('🚀 ~ file: IntlLabel.js ~ handleError', e);
    };

    const handleCancel = () => {
        setValue(initialValue);
        dispatch(closeNestedDialogAction());
    };

    return (
        <LayoutForm title={t('profile.settings.viewerExtraField.fieldForm.label')}>
            <ValidatorForm
                onSubmit={() => {}}
                onError={handleError}
                className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden, flexBox.h100)}
            >
                <Stack direction="column" className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone, flexBox.h100)}>
                    <Grid container spacing={1}>
                        {langs.map((lang) =>
                            type === 'checkbox' || type === 'privacy-policy' ? (
                                <Grid key={lang.languageCode} item md={12}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel>{lang.language}</FormLabel>
                                        <CustomTextFieldDescription
                                            name="description"
                                            value={
                                                value.find(
                                                    (l) =>
                                                        l.languageCode.toLowerCase() ===
                                                        lang.languageCode.toLowerCase(),
                                                )?.value || ''
                                            }
                                            onChange={(e) => handleLangFieldChange(e, lang.languageCode)}
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            rows={3}
                                        />
                                    </FormGroup>
                                </Grid>
                            ) : (
                                <Grid key={lang.languageCode} item md={6}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel>{lang.language}</FormLabel>
                                        <CustomTextValidator
                                            className={classes.formControl}
                                            name={lang.languageCode}
                                            fullWidth={true}
                                            size="small"
                                            autoComplete="new-link"
                                            value={
                                                value.find(
                                                    (l) =>
                                                        l.languageCode.toLowerCase() ===
                                                        lang.languageCode.toLowerCase(),
                                                )?.value || ''
                                            }
                                            onChange={(e) => handleLangFieldChange(e, lang.languageCode)}
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={[
                                                t('profile.settings.viewerExtraField.fieldForm.labelRequired'),
                                            ]}
                                        />
                                    </FormGroup>
                                </Grid>
                            ),
                        )}
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    className={flexBox.paddingTop2}
                >
                    <ActionButton onClick={handleCancel} color="default" fitWidth>
                        {t('multimedia.multimediaForm.cancel')}
                    </ActionButton>

                    <ActionButton color="primary" autoFocus fitWidth onClick={handleSubmit}>
                        {t('app.common.save')}
                    </ActionButton>
                </Stack>
            </ValidatorForm>
        </LayoutForm>
    );
};

export default FormI18nLabel;
