import { createSlice } from "@reduxjs/toolkit";

export const socialSlice = createSlice({
  name: "social",
  initialState: { url: null },
  reducers: {
    resetSocial: (state) => {
      state.url = null;
    },
    setSocial: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const {
  resetSocial,
  setSocial,
} = socialSlice.actions;

export const getSocial = state => state.social.url;

export default socialSlice.reducer;
