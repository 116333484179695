import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import Layout from 'components/Layout';
import useFullLoadingStyles from 'components/styles/useFullLoadingStyles';
import config from 'config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    getSessionChecked,
    getTokenCsrf,
    loginCheck,
    logoutAction,
    userHasAuthenticated,
    userIsAuthenticating,
} from 'redux/slices/authSlice';
import { AirCallPanel } from './aircallpanel';

const Main = () => {
    const dispatch = useDispatch();
    const [stripe] = useState(() => loadStripe(config.stripe.publicKey));

    const enableAircalls = useSettingValue(SettingsKey.ENABLE_AIRCALLS) ?? false;
    const sessionChecked = useSelector(getSessionChecked);
    const isAuthenticated = useSelector(userHasAuthenticated);
    const isAuthenticating = useSelector(userIsAuthenticating);
    const { classes } = useFullLoadingStyles();
    const tokenCsrf = useSelector(getTokenCsrf);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(loginCheck(window.location.href));
    }, [dispatch]);

    useEffect(() => {
        if (sessionChecked && !isAuthenticating && !isAuthenticated) {
            dispatch(
                logoutAction({
                    success: () => {
                        navigate('/login');
                    },
                    tokenCsrf,
                }),
            );
        }
    }, [sessionChecked, isAuthenticating, isAuthenticated, dispatch, navigate, tokenCsrf]);

    useEffect(() => {
        if (enableAircalls) window['airCallPanel'] = new AirCallPanel();
    }, [enableAircalls]);

    return sessionChecked && isAuthenticated ? (
        <Elements stripe={stripe}>
            {Boolean(enableAircalls) && (
                <div id="aircall-container">
                    <div id="close-phone">
                        <IconButton onClick={() => window.airCallPanel.close()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div id="aircall"></div>
                </div>
            )}
            <Layout />
        </Elements>
    ) : (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
};

export default Main;
