import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'call-to-actions';

export const callToActionSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        callToActions: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        addCallToAction: (state, action) => {
            state.savePending = false;
            state.callToActions = [action.payload, ...state.callToActions];
        },
        editCallToAction: (state, action) => {
            state.savePending = false;
            const index = state.callToActions.findIndex((callToAction) => callToAction.id === action.payload.id);
            if (index !== -1) {
                state.callToActions = [
                    ...state.callToActions.slice(0, index),
                    action.payload,
                    ...state.callToActions.slice(index + 1),
                ];
            }
        },
        deleteCallToAction: (state, action) => {
            state.deletePending = false;
            const index = state.callToActions.findIndex((callToAction) => callToAction.id === action.payload.id);
            if (index !== -1) {
                state.callToActions = [...state.callToActions.slice(0, index), ...state.callToActions.slice(index + 1)];
            }
        },
        setCallToActions: (state, action) => {
            state.pending = false;
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.callToActions = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
    },
});

export const {
    start,
    stop,
    setCallToActions,
    setError,
    addCallToAction,
    editCallToAction,
    deleteCallToAction,
    startDelete,
    startSave,
    setSaveError,
    stopSave,
} = callToActionSlice.actions;

export const getCallToActions = (state) => state.callToActions.callToActions;
export const getCallToActionsTotal = (state) => state.callToActions.total;
export const getCallToActionsPending = (state) => state.callToActions.pending;
export const getDeletePending = (state) => state.callToActions.deletePending;
export const getDeleteId = (state) => state.callToActions.deleteId;
export const getCallToActionsError = (state) => state.callToActions.error;
export const getSavePending = (state) => state.callToActions.savePending;

export const fetchCallToActionsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get(resource + query)
                .then((response) => {
                    dispatch(setCallToActions(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchCallToActionAndStickersAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get('assets' + query)
                .then((response) => {
                    dispatch(setCallToActions(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addCallToActionAction =
    ({ dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(resource, dataDto)
                .then((response) => {
                    dispatch(addCallToAction(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editCallToActionAction =
    ({ id, dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put(`${resource}/${id}`, dataDto)
                .then((response) => {
                    dispatch(editCallToAction(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const deleteCallToActionsAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete(resource + '/' + id)
                .then((response) => {
                    dispatch(deleteCallToAction(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export default callToActionSlice.reducer;
