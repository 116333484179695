import { createSlice } from '@reduxjs/toolkit';

export const eventStickersSlice = createSlice({
    name: 'eventSticker',
    initialState: {
        pending: false,
        stickers: [],
        selectedStickers: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        resetEventStickers: (state, action) => {
            state.stickers = [];
        },
        setEventStickers: (state, action) => {
            state.pending = false;
            state.stickers = action.payload;
        },
        setSelectedEventStickers: (state, action) => {
            state.selectedStickers = action.payload;
        },
        addEventSticker: (state, action) => {
            state.stickers = [...state.stickers, action.payload];
        },
        deleteEventSticker: (state, action) => {
            const index = action.payload;
            state.stickers = [
                ...state.stickers.slice(0, index),
                ...state.stickers.slice(index + 1, state.stickers.length),
            ];
        },
        deleteSelectedEventSticker: (state, action) => {
            const index = action.payload;
            state.selectedStickers = [
                ...state.selectedStickers.slice(0, index),
                ...state.selectedStickers.slice(index + 1, state.stickers.length),
            ];
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        deleteEventStickerItem: (state, action) => {
            const sticker = action.payload;
            const index = state.stickers.findIndex((item) => item.id === sticker?.id);
            if (index !== -1) {
                state.stickers = [
                    ...state.stickers.slice(0, index),
                    ...state.stickers.slice(index + 1, state.stickers.length),
                ];
            }
        },
    },
});

export const {
    start,
    resetEventStickers,
    setEventStickers,
    addEventSticker,
    deleteEventSticker,
    deleteSelectedEventSticker,
    setError,
    setSelectedEventStickers,
    deleteEventStickerItem,
} = eventStickersSlice.actions;

export const getEventStickers = (state) => state.eventSticker.stickers;
export const getEventStickersPending = (state) => state.eventSticker.pending;
export const getEventStickersError = (state) => state.eventSticker.error;

export default eventStickersSlice.reducer;
