import api from 'api';

export function updateAgent(organizationId, agentId, data) {
    return api.put(`users/realtime/${organizationId}`, { ...data, id: agentId });
}

export function setAgentOnline(agent) {
    return api.put(`users/realtime/online/${agent.organizationId}`, agent);
}

export function setAgentOffline(organizationId, agentId, forcedOffline = false) {
    return api.put(`users/realtime/offline/${organizationId}`, { agentId, forcedOffline });
}

export function setAgentOfflineMobile(organizationId, agentId, forcedOffline = false) {
    return api.put(`users/realtime/offline-mobile/${organizationId}`, { agentId, forcedOffline });
}
