import { Search } from '@mui/icons-material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { CircularProgress, InputAdornment, ListSubheader, MenuItem, Select, Stack } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import CustomIconButton from './CustomIconButton';
import CustomTextField from './CustomTextField';
const useStyles = makeStyles()((theme) => ({
    select: {
        color: 'black',
        fontSize: '12px',
        backgroundColor: 'rgb(255,255,255)',
        borderRadius: theme.spacing(2),
        fontWeight: 400,
        height: theme.spacing(3.5),
        minWidth: theme.spacing(25),
        '& .MuiListItem-root': {
            borderRadius: theme.spacing(2),
        },
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
        },
        '& .MuiCheckbox-root': {
            color: 'green',
        },
        '& .MuiCheckbox-colorSecondary': {
            '&.Mui-checked': {
                color: 'orange',
            },
        },
        '& .MuiSelect-icon': {
            color: 'black',
            fontSize: '20px',
        },
        '& > fieldset': {
            borderColor: '#E2E2E2',
        },
        [theme.breakpoints.up('xl')]: {
            minWidth: theme.spacing(30),
            height: theme.spacing(4),
            fontSize: theme.spacing(1.75),
        },
        '&.smallWidth': {
            minWidth: theme.spacing(12),
            [theme.breakpoints.up('xl')]: {
                minWidth: theme.spacing(14),
            },
        },
        '&.small': {
            height: theme.spacing(3),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(3.5),
            },
        },
        '&.medium': {
            height: theme.spacing(4),
        },
        '&.big': {
            height: theme.spacing(4.5),
            borderRadius: theme.spacing(3),
        },
    },
    mW25: {
        maxWidth: theme.spacing(25),
    },
    searchTextField: {
        marginTop: theme.spacing(1.5),
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(3),
            paddingRight: theme.spacing(0.25),
            height: theme.spacing(4),
        },
    },

    paper: {
        borderRadius: theme.spacing(2),
        marginTop: theme.spacing(0.5),
        '& .MuiMenuItem-root': {
            fontSize: theme.spacing(1.25),
            fontWeight: 500,
            borderRadius: 0,
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(1.75),
            },
        },
    },
    fw400: {
        fontWeight: 500,
    },
    listHeight: {
        maxHeight: theme.spacing(31),
    },
    spinner: {
        minWidth: theme.spacing(28.25),
    },
}));

const RoundedSelect = ({
    id,
    children,
    size,
    bold,
    small,
    iconStyle,
    fullWidth,
    searchPlaceholder,
    onSearchFilter,
    customMenuProps,
    setHasMore,
    pending,
    className,
    search = false,
    maxWidth = true,
    ...props
}) => {
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const [value, setValue] = useState();
    const [firstSearch, setFirstSearch] = useState(false);

    const handleMenuScroll = (event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setHasMore?.(true);
        }
    };

    const MenuProps = {
        PaperProps: {
            className: cx(classes.paper),
        },
        MenuListProps: {
            onScroll: handleMenuScroll,
            className: cx(
                classes.listHeight,
                flexBox.padding0,
                flexBox.overflowXHidden,
                flexBox.scrollbarNone,
                search && flexBox.marginBottom1,
            ),
            style: {
                padding: 0,
            },
        },
        autoFocus: !search,
        ...customMenuProps,
    };

    useEffect(() => {
        if (firstSearch) {
            const delayDebounceFn = setTimeout(() => {
                onSearchFilter(value);
                setFirstSearch(false);
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [firstSearch, onSearchFilter, value]);

    const onInput = (e) => {
        setValue(e.target.value);
        setFirstSearch(true);
    };

    return (
        <Select
            id={id || 'custom_select'}
            className={cx(className, classes.select, size, classes.fw400, fullWidth ? flexBox.w100 : classes.mW25)}
            MenuProps={MenuProps}
            IconComponent={
                iconStyle ? () => <ExpandMoreOutlinedIcon style={{ ...iconStyle }} /> : ExpandMoreOutlinedIcon
            }
            onScroll={handleMenuScroll}
            {...props}
        >
            {search ? (
                <ListSubheader>
                    <CustomTextField
                        placeholder={searchPlaceholder}
                        className={cx(flexBox.w100, classes.searchTextField)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomIconButton
                                        aria-label="search"
                                        size="small"
                                        hideBorder={true}
                                        disabled
                                        classes={{ disabled: classes.disabledIconButton }}
                                    >
                                        <Search />
                                    </CustomIconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        size="small"
                        autoFocus
                        fullWidth
                        onInput={onInput}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                                e.stopPropagation();
                            }
                        }}
                    />
                </ListSubheader>
            ) : null}
            {children}
            {pending ? (
                <MenuItem disabled>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={cx(classes.spinner, flexBox.padding10)}
                    >
                        <CircularProgress size={24} />
                    </Stack>
                </MenuItem>
            ) : null}
        </Select>
    );
};

export default RoundedSelect;
