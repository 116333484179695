export const customMenuPropsRight = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right', // Aligns the menu list with the right border of the select input
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right', // Aligns the menu list with the right border of the select input
    },
};
