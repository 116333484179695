import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddOnsActive } from 'redux/slices/addOnsActiveSlice';
import { fetchAddOnsPaidAction, getAddOnsPaid } from 'redux/slices/addOnsPaidSlice';
import {
    getAuthLicense,
    getUser,
    setLicenseWithAddOns,
    setMainLicenseWithAddOns,
    userHasAuthenticated,
} from 'redux/slices/authSlice';
import { fetchOneLicenseAction } from 'redux/slices/licenseSlice';
import { fetchMainOrganizationAction, getMainOrganization, getOrganization } from 'redux/slices/organizationSlice';

export function useContextLicenseAddOns() {
    const user = useSelector(getUser);
    const organization = useSelector(getOrganization);
    const mainOrganization = useSelector(getMainOrganization);
    const isAuthenticated = useSelector(userHasAuthenticated);

    const license = useSelector(getAuthLicense);
    const oauthAddOnsPaid = useSelector(getAddOnsPaid);
    const addOnsActive = useSelector(getAddOnsActive);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated && organization?.licenseId) {
            dispatch(
                fetchOneLicenseAction({
                    id: organization.licenseId,
                    onSuccess: (data) => {
                        dispatch(setLicenseWithAddOns(data));
                    },
                    onFailure: (error) => {
                        console.error(error);
                    },
                }),
            );
        }
    }, [organization?.licenseId, isAuthenticated, dispatch]);

    useEffect(() => {
        if (organization.id && isAuthenticated) {
            dispatch(
                fetchAddOnsPaidAction({
                    organizationId: organization.id,
                }),
            );
        }
    }, [organization.id, isAuthenticated, addOnsActive, dispatch]);

    useEffect(() => {
        if (user && user.organizationId !== user.mainOrganizationId) {
            dispatch(fetchMainOrganizationAction());
        }
    }, [user?.organizationId, user?.mainOrganizationId, dispatch, user]);

    useEffect(() => {
        if (
            isAuthenticated &&
            mainOrganization &&
            organization &&
            organization.id !== mainOrganization.id &&
            'licenseId' in mainOrganization &&
            mainOrganization.licenseId
        ) {
            dispatch(
                fetchOneLicenseAction({
                    id: mainOrganization.licenseId,
                    onSuccess: (data) => {
                        dispatch(setMainLicenseWithAddOns(data));
                    },
                    onFailure: (error) => {
                        console.error(error);
                    },
                }),
            );
        }
    }, [mainOrganization?.licenseId, organization?.id, isAuthenticated, dispatch, organization, mainOrganization]);

    useEffect(() => {
        if (isAuthenticated && mainOrganization?.id && organization?.id && organization.id !== mainOrganization.id) {
            dispatch(
                fetchAddOnsPaidAction({
                    organizationId: mainOrganization.id,
                }),
            );
        }
    }, [mainOrganization?.id, organization?.id, isAuthenticated, addOnsActive, dispatch]);

    return [license, oauthAddOnsPaid];
}
