import GATEWAY_API from '../gateway';

const BASE_PATH_TRACKING = 'tracking';

/**
 * Create tracking
 * @param eventId
 * @param data
 * @returns {Promise<*>}
 */
export const createTracking = (eventId, data) => {
    if (process.env.NODE_ENV === 'development') {
        console.debug('skipping track in development', data);
        return Promise.resolve();
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    // Cleaning channelURL query params
    if (data.data.channelURL) {
        data.data.channelURL = data.data.channelURL.split('?')[0];
    }
    // console.debug("🚀 ~ file: tracking.js ~ line 13 ~ createTracking ~ data", data)
    return GATEWAY_API.post(`${BASE_PATH_TRACKING}/${eventId}`, data, config).then((trackingResponse) => {
    });
};
