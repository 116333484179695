import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton } from '@mui/material';

import CustomTooltip from 'common/components/CustomTooltip';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStylesViewerExtraFields from '../styles/useStylesViewerExtraFields.styles';
import FieldForm from './FieldForm';

const FieldsRow = (props) => {
    const { field, fields, setFieldsEdited, setFields, deleteField, openForm, index, editingFieldIndex, id } = props;
    const { t } = useTranslation();
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2, cx } = useStylesViewerExtraFields();
    const classes = { ...classes1, ...classes2 };

    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (index !== editingFieldIndex) {
            setShowForm(false);
        } else if (editingFieldIndex === index || editingFieldIndex === -1) {
            setShowForm(true);
        }
    }, [editingFieldIndex, index]);

    const upDownField = (e) => {
        let newArr = [...fields];
        newArr.splice(index, 1);
        newArr.splice(index + e, 0, field);
        setFields(newArr);
    };

    const setFieldActive = (e) => {
        let newArr = [...fields];
        let idx = newArr.findIndex((f) => f.name === field.name);
        newArr[idx] = {
            ...field,
            active: e.target.checked,
            readOnly: field.readOnly !== undefined ? field.readOnly : false,
        };
        setFields(newArr);
    };

    return (
        <div key={index} className={classes.fieldContent}>
            <div key={field.name} className={showForm ? classes.editfield : ''}>
                <div className={classes.p10px}>
                    <Grid container spacing={1} className={classes.optionsRow}>
                        <Grid item md={1} className={cx(classes.fieldOption)}>
                            <CustomTooltip
                                arrow
                                placement="bottom"
                                title={t('profile.settings.viewerExtraField.fieldsRow.showHide')}
                            >
                                <FormGroup
                                    className={cx(
                                        classes.formGroup,
                                        classes.check,
                                        field.disabledCheck && classes.checkDisable,
                                    )}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="active"
                                                checked={field.active !== undefined ? field.active : true}
                                                onChange={(e) => {
                                                    setFieldActive(e);
                                                }}
                                                color="primary"
                                                disabled={field.disabledCheck}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </CustomTooltip>
                        </Grid>
                        <Grid item md={3} className={cx(classes.fieldOption)}>
                            <span className={cx(classes.fieldRowSpan, classes.varOptText)}>{field.name}</span>
                        </Grid>
                        <Grid item md={3}>
                            <span className={classes.fieldRowSpan}>
                                {field.useDefault ? 'default' : (field.name === 'onlivePolicy' ? 'onlive-policy-terms' : field.type)}
                            </span>
                        </Grid>
                        <Grid item md={3}>
                            <span className={classes.fieldRowSpan}>
                                {field.required
                                    ? t('profile.settings.viewerExtraField.fieldsRow.true')
                                    : t('profile.settings.viewerExtraField.fieldsRow.false')}
                            </span>
                        </Grid>
                        <Grid item md={2} className={cx(classes.optionsRow, classes.positionEnd, classes.actionsIcons)}>
                            {index !== 0 && editingFieldIndex === -1 && (
                                <CustomTooltip
                                    arrow
                                    placement="bottom"
                                    title={t('profile.settings.viewerExtraField.fieldsRow.up')}
                                >
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        aria-label="up"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            upDownField(-1);
                                        }}
                                    >
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                </CustomTooltip>
                            )}
                            {index !== fields.length - 1 && editingFieldIndex === -1 && (
                                <CustomTooltip
                                    arrow
                                    placement="bottom"
                                    title={t('profile.settings.viewerExtraField.fieldsRow.down')}
                                >
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        aria-label="down"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            upDownField(1);
                                        }}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </CustomTooltip>
                            )}

                            {!field.readOnly && (
                                <Divider
                                    className={classes.divider + ' ' + (showForm ? classes.dividerColor : '')}
                                    orientation="vertical"
                                />
                            )}

                            {!showForm && (
                                <CustomTooltip
                                    arrow
                                    placement="bottom"
                                    title={t('profile.settings.viewerExtraField.fieldsRow.edit')}
                                >
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        aria-label="up"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openForm(index);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </CustomTooltip>
                            )}

                            {!field.readOnly && (
                                <CustomTooltip
                                    arrow
                                    placement="bottom"
                                    title={t('profile.settings.viewerExtraField.fieldsRow.delete')}
                                >
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        aria-label="up"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowForm(false);
                                            deleteField();
                                            openForm(-1);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </CustomTooltip>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
            {showForm && (
                <div className={classes.p10px}>
                    <FieldForm
                        id={id}
                        field={field}
                        fieldsExtra={fields}
                        index={index}
                        setFields={setFieldsEdited}
                        close={() => openForm(-1)}
                    />
                </div>
            )}
        </div>
    );
};

export default FieldsRow;
