import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const contentSlice = createSlice({
    name: 'content',
    initialState: {
        pending: false,
        contents: [],
        error: null,
        currentPage: 0,
        totalPages: 0,
        count: 0,
        total: 0,
        savePending: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        setContents: (state, action) => {
            state.pending = false;
            state.contents = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.count = action.payload.count;
            state.total = action.payload.total;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addContent: (state, action) => {
            state.savePending = false;
            state.contents = [...state.contents, action.payload];
        },
        editContent: (state, action) => {
            state.savePending = false;
            const index = state.contents.findIndex((content) => content.id === action.payload.id);
            if (index !== -1) {
                state.contents = [
                    ...state.contents.slice(0, index),
                    action.payload,
                    ...state.contents.slice(index + 1),
                ];
            }
        },
        deleteContent: (state, action) => {
            state.pending = false;
            const index = state.contents.findIndex((content) => content.id === action.payload.id);
            if (index !== -1) {
                state.contents = [...state.contents.slice(0, index), ...state.contents.slice(index + 1)];
            }
        },
        resetContents: (state, action) => {
            state.contents = [];
            state.currentPage = 0;
            state.totalPages = 0;
            state.count = 0;
        },
    },
});

export const {
    start,
    addContent,
    stop,
    setContents,
    setError,
    editContent,
    deleteContent,
    resetContents,
    startSave,
    stopSave,
    setSaveError,
} = contentSlice.actions;

export const getContents = (state) => state.content.contents;
export const getContentsTotal = (state) => state.content.total;
export const getContentsPending = (state) => state.content.pending;
export const getContentsError = (state) => state.content.error;
export const getSavePending = (state) => state.content.savePending;

export const fetchContentsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            let query = transformFiltersToQuery(queryParams);
            api.get('contents' + query)
                .then((response) => {
                    dispatch(setContents(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addContentAction = (contentDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post(`contents`, contentDto)
            .then((response) => {
                dispatch(addContent(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const editContentAction = (contentId, contentDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('contents/' + contentId, contentDto)
            .then((response) => {
                dispatch(editContent(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteContentAction = (contentId, success, failure) => async (dispatch) => {
    try {
        dispatch(start());
        api.delete('contents/' + contentId)
            .then((response) => {
                dispatch(deleteContent(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default contentSlice.reducer;
