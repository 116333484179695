import { Button, FormGroup, FormLabel, Grid, MenuItem, Slide, Typography } from '@mui/material';
import { calendarApi } from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import RoundedSelect from 'common/components/RoundedSelect';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import UserRole from 'common/enums/roles';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { checkBool, isValidHttpUrl } from 'common/utils/utils';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { getUser } from 'redux/slices/authSlice';
import {
    fetchProfileAction,
    getProfile,
    getTemplateViewEnable,
    postProfileAction,
    updateProfileAction,
} from 'redux/slices/ocalendarSlice';
import {
    editOrganizationCalendarProviderAction,
    fetchOrganizationAction,
    getOrganization,
} from 'redux/slices/organizationSlice';
import OConnectMenu from './OConnectMenu';
import Availability from './availability/Availability';
import { AvailabilityProvider } from './availability/AvailabilityContext';
import AvailabilityForm from './availability/AvailabilityForm';
import useStylesOCalendar from './styles/useStylesOCalendar.styles';
import { CalendarProviderType } from './types';

const OCalendar = (props) => {
    const { userId, isReadOnly, scrollable } = props;
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesOCalendar();
    const classes = { ...classes1, ...classes2 };

    const dispatch = useDispatch();
    const organization = useSelector(getOrganization);
    const user = useSelector(getUser);

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isConnected, setIsConnected] = useState(false);
    const profile = useSelector(getProfile);
    const templateViewEnable = useSelector(getTemplateViewEnable);

    const orgTimeZone = useSettingValue(SettingsKey.TIMEZONE);
    const requestUser = useMemo(() => userId || user?.userId, [userId, user]);
    const timeZone = useMemo(() => orgTimeZone ?? 'Europe/Madrid', [orgTimeZone]);
    const availabilitiesRef = useRef(null);
    const formRef = useRef(null);

    moment.locale(i18n.language || 'en');

    // CALLBACKS
    const handleCreateProfile = useCallback(() => {
        dispatch(
            postProfileAction({
                agentId: requestUser,
                timeZone: timeZone,
            }),
        );
    }, [requestUser, timeZone, dispatch]);

    const handleInitialFetchProfile = useCallback(() => {
        if (!profile) {
            dispatch(
                fetchProfileAction(requestUser, (result) => {
                    if (!result) {
                        handleCreateProfile();
                    }
                }),
            );
        }
    }, [requestUser, profile, handleCreateProfile, dispatch]);

    // EFFECTS
    useEffect(() => {
        setIsConnected(
            organization?.isAuthorizedByTheProvider &&
                organization?.calendarProvider !== CalendarProviderType.Onlive.description,
        );
    }, [organization]);

    useEffect(() => {
        handleInitialFetchProfile();
    }, [handleInitialFetchProfile]);

    const getAuthUrl = async (basePath, userId, organizationId) => {
        const queryParams = {
            userId,
            organizationId,
        };
        const endpoint = `${basePath}?${new URLSearchParams(queryParams)}`;

        try {
            const response = await calendarApi.get(endpoint);
            return response.data;
        } catch (err) {
            enqueueSnackbar(t('app.common.error'), {
                variant: 'error',
            });
        }
    };

    const handleConnectionBtn = async (type) => {
        const providerType = typeof type === 'string' ? type.trim().toLowerCase() : organization.calendarProvider;

        if (!isConnected) {
            let redirect;
            switch (providerType) {
                case CalendarProviderType.Microsoft.description:
                    redirect = await getAuthUrl(
                        '/api/v1/microsoft-auth/authorization-url',
                        user.userId,
                        organization.id,
                    );
                    break;

                case CalendarProviderType.Google.description:
                    redirect = await getAuthUrl('/api/v1/google-auth/authorization-url', user.userId, organization.id);
                    break;

                default:
                    break;
            }
            if (redirect && isValidHttpUrl(redirect)) {
                window.location.href = redirect;
            }
        } else {
            switch (providerType) {
                case CalendarProviderType.Microsoft.description:
                    dispatch(
                        editOrganizationCalendarProviderAction(
                            {
                                calendarProvider: 'onlive',
                                isAuthorizedByTheProvider: false,
                            },
                            () => {
                                enqueueSnackbar(t('profile.settings.settings.saveSuccess'), { variant: 'success' });
                            },
                        ),
                    );
                    break;

                case CalendarProviderType.Google.description:
                    try {
                        await calendarApi.put(`/api/v1/provider/disconnect/${organization.id}/${user.userId}`, {
                            type: organization.calendarProvider,
                        });
                    } catch (err) {
                        enqueueSnackbar(err.description || t('app.common.error'), {
                            variant: 'error',
                        });
                    }
                    dispatch(fetchOrganizationAction());
                    break;

                default:
                    break;
            }
            setIsConnected(false);
        }
    };

    const handleTimezoneChange = (e) => {
        if (profile) {
            dispatch(
                updateProfileAction(
                    profile.id,
                    {
                        agentId: profile.agentId,
                        timeZone: e.target.value,
                    },
                    () => {
                        enqueueSnackbar(t('common.term.saveSuccess'), {
                            variant: 'success',
                        });
                    },
                    () => {
                        enqueueSnackbar(t('app.common.error'), {
                            variant: 'error',
                        });
                    },
                ),
            );
        }
    };

    const templateSelectorStyle = {
        width: '50%',
        height: '35px',
        fontSize: '11px',
    };

    return (
        <>
            {organization?.id && (
                <Fragment>
                    {user?.role === 'root' && (
                        <Typography>
                            {`${t('calendar.provider').toUpperCase()}: ${organization?.calendarProvider.toUpperCase()}`}
                        </Typography>
                    )}

                    {!isReadOnly && (user?.role === UserRole.ADMIN || user?.role === UserRole.EDITOR) && (
                        <div style={{ marginBottom: '20px' }}>
                            {isConnected ? (
                                <Button variant={'contained'} color="primary" onClick={handleConnectionBtn}>
                                    {t('calendar.DisconnectFromExternalProvider')}
                                </Button>
                            ) : (
                                <OConnectMenu onClick={handleConnectionBtn} />
                            )}
                        </div>
                    )}

                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('profile.settings.generalSettings.timezone')}</FormLabel>
                                <RoundedSelect
                                    value={profile?.timeZone || timeZone}
                                    style={templateSelectorStyle}
                                    fullWidth
                                    iconStyle={{
                                        fontSize: '13px',
                                        padding: '0 12px',
                                        color: 'darkslategrey',
                                    }}
                                    inputProps={{ readOnly: isReadOnly }}
                                    onChange={(e) => handleTimezoneChange(e)}
                                >
                                    {moment.tz.names().map((tz) => {
                                        return (
                                            <MenuItem value={tz} name={tz} key={tz}>
                                                {tz}
                                            </MenuItem>
                                        );
                                    })}
                                </RoundedSelect>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <AvailabilityProvider>
                        {checkBool(templateViewEnable) && (
                            <Transition
                                nodeRef={availabilitiesRef}
                                in={!templateViewEnable}
                                timeout={{
                                    enter: 0,
                                    exit: 500,
                                }}
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => <Availability requestUser={requestUser} scrollable={scrollable} />}
                            </Transition>
                        )}

                        {checkBool(templateViewEnable) && (
                            <Transition
                                nodeRef={formRef}
                                in={templateViewEnable}
                                timeout={{
                                    enter: 500,
                                    exit: 300,
                                }}
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <Slide direction="up" in={state} mountOnEnter unmountOnExit>
                                        <div>
                                            <AvailabilityForm
                                                isReadOnly={isReadOnly}
                                                requestUser={requestUser}
                                                profile={profile}
                                            />
                                        </div>
                                    </Slide>
                                )}
                            </Transition>
                        )}
                    </AvailabilityProvider>
                </Fragment>
            )}
        </>
    );
};

export default OCalendar;
