import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CardMedia, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import noImage from 'common/assets/noImage.png';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomSwitch from 'common/components/CustomSwitch';
import CustomTooltip from 'common/components/CustomTooltip';
import DateRange from 'common/components/DateRange';
import useFlexBoxStyles from 'common/flexBox';
import { useIncludeDateValidation } from 'common/hooks/useIncludeDateValidation';
import useOnliveStyles from 'common/onliveStyles';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const transformDates = (dates) => {
    return dates.map((dateRange) => ({
        startDate: dateRange[0],
        endDate: dateRange[1],
    }));
};

const ServiceCardDateRange = ({ unit, onDeleteUnit, onEditUnit, disabled, timeZone }) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();
    const { isValidDateRange, error, validationType, setError } = useIncludeDateValidation();
    const isActive = unit?.active || unit?.available;
    const [showAddForm, setShowAddForm] = useState(false);
    const dates = unit?.restrictionDates.exclude || [];

    const handleEditDate = (newDate, idx) => {
        const newRange = [
            ...dates.slice(0, idx),
            [moment.tz(newDate.start, timeZone), moment.tz(newDate.end, timeZone)],
            ...dates.slice(idx + 1),
        ];
        onEditUnit({ ...unit, restrictionDates: { ...unit?.restrictionDates, exclude: [...newRange] } });
    };

    const handleAddDate = (newDate) => {
        const newRange = [...dates, [moment.tz(newDate.start, timeZone), moment.tz(newDate.end, timeZone)]];
        onEditUnit({ ...unit, restrictionDates: { ...unit?.restrictionDates, exclude: [...newRange] } });
    };

    const handleDeleteDate = (idx) => {
        const newRange = [...dates.slice(0, idx), ...dates.slice(idx + 1)];
        onEditUnit({ ...unit, restrictionDates: { ...unit?.restrictionDates, exclude: [...newRange] } });
        if (error?.idx === idx) {
            setError(null);
        }
    };

    const handleAvailable = () => {
        onEditUnit({ ...unit, active: !isActive });
    };

    const imageSrc = unit?.images?.length ? unit.images[0] : noImage;

    const handleChange = (date, idx, callback) => {
        const ranges = transformDates(dates);
        const isValid = isValidDateRange({ ranges, date, idx, type: validationType.UNSELECTED });
        if (isValid) {
            if (idx === -1) {
                handleAddDate(date);
                setShowAddForm(false);
                callback?.();
            } else {
                handleEditDate(date, idx);
            }
        }
    };

    return (
        <Stack
            direction="column"
            className={cx(flexBox.relative, flexBox.padding1, onlive.borderSecondaryDark, flexBox.borderRadius1)}
        >
            {disabled ? null : (
                <Stack className={cx(flexBox.absolute, flexBox.right1, flexBox.top1)}>
                    <CustomTooltip arrow title={t('contact.remove')} placement={'bottom'}>
                        <CustomIconButton aria-label="delete" onClick={onDeleteUnit} size="small">
                            <DeleteOutlineOutlinedIcon />
                        </CustomIconButton>
                    </CustomTooltip>
                </Stack>
            )}

            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} useFlexGap>
                <CardMedia component="img" sx={{ width: 50 }} image={imageSrc} />

                <Stack direction="column">
                    <Typography variant="onliveH5" fontWeight={400}>
                        {unit?.productName || unit?.name}
                    </Typography>
                    <Typography variant="body2" fontWeight={400}>
                        ID: {unit.externalId || unit.id}
                    </Typography>
                </Stack>

                <FormGroup sx={{ ml: 4 }}>
                    <FormControlLabel
                        control={<CustomSwitch checked={isActive} onChange={handleAvailable} name="available" />}
                        label={
                            <Typography textTransform="uppercase">
                                {isActive ? t('services.form.card.available') : t('common.term.unavailable')}
                            </Typography>
                        }
                    />
                </FormGroup>
            </Stack>

            <Stack direction="column" spacing={1} className={flexBox.paddingTop2}>
                <Typography>{t('services.form.card.excludeRangeDate').toUpperCase()}</Typography>
                <Grid container>
                    <Grid item sm={1.5}>
                        <Typography variant="subtitle2" fontWeight={400} className={flexBox.paddingTop1}>
                            {t('services.form.dateFrom').toUpperCase()}
                        </Typography>
                    </Grid>

                    <Grid item sm={10.5}>
                        <Stack direction="column" spacing={1}>
                            {unit?.restrictionDates?.exclude?.map((date, idx) => (
                                <DateRange
                                    key={idx}
                                    type="datetime-local"
                                    date={{
                                        start: moment.tz(date[0], timeZone).format('YYYY-MM-DDTHH:mm'),
                                        end: moment.tz(date[1], timeZone).format('YYYY-MM-DDTHH:mm'),
                                    }}
                                    onChange={(date) => handleChange(date, idx)}
                                    onDelete={() => handleDeleteDate(idx)}
                                    error={error?.idx === idx ? error?.msg : null}
                                    disabled={error ? error?.idx !== idx : false}
                                />
                            ))}

                            <DateRange
                                type="datetime-local"
                                isDates={unit?.restrictionDates?.exclude?.length}
                                showAddForm={showAddForm}
                                setShowAddForm={setShowAddForm}
                                onChange={(date, callback) => handleChange(date, -1, callback)}
                                onDelete={() => setError(null)}
                                disabled={error ? error?.idx !== -1 : false}
                                error={error?.idx === -1 ? error?.msg : null}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    );
};

export default ServiceCardDateRange;
