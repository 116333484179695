import { Box, FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { isValidHttpUrl } from 'common/validators/validators';
import { useTranslation } from 'react-i18next';
import ItemSettings from '../components/ItemSettings';
import useStylesSettings from '../styles/useStylesSettings.styles';

const ICallNotificationsSettings = (props) => {
    const { notifications, onChange } = props;
    const { t } = useTranslation();
    const { classes } = useStylesSettings();

    const validBaseUrl = (baseUrl) => {
        return isValidHttpUrl(baseUrl) || baseUrl === '' || !baseUrl;
    };

    const handleCheckChange = (event, notificationName, settingName) => {
        onChange(notificationName, settingName, event.target.checked);
    };

    const handleValueChange = (event, notificationName, settingName) => {
        onChange(notificationName, settingName, event.target.value);
    };

    function renderNotificationSettings() {
        return Object.keys(notifications).map((notification) => {
            const notificationName = notification.replace(/_/g, '');
            return (
                <div key={notification} style={{ marginTop: '10px' }}>
                    <Typography>{t(`profile.settings.oneToOneSettings.${notificationName}`)}</Typography>
                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.enableSystemTrayNotification')}
                        onChange={(e, settingName) => handleCheckChange(e, notification, settingName)}
                        name={SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION}
                        keySettings={SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION}
                        value={notifications[notification]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]}
                        paddingBottom
                    />

                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.enableBrowserPushNotification')}
                        onChange={(e, settingName) => handleCheckChange(e, notification, settingName)}
                        name={SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION}
                        keySettings={SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION}
                        value={notifications[notification]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]}
                        paddingBottom
                    />

                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.enableBrowserSoundNotification')}
                        onChange={(e, settingName) => handleCheckChange(e, notification, settingName)}
                        name={SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION}
                        keySettings={SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION}
                        value={notifications[notification]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]}
                        paddingBottom
                    />

                    {notifications[notification]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION] && (
                        <div style={{ paddingBottom: '20px', marginTop: '20px' }}>
                            <Typography className={classes.text}>
                                {t('profile.settings.oneToOneSettings.urlBrowserSoundNotification')}
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ width: '60%', marginTop: '10px' }}
                            >
                                <Box style={{ width: '100%' }}>
                                    <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                        <FormControl>
                                            <TextField
                                                style={{ width: '100%' }}
                                                color={
                                                    validBaseUrl(
                                                        notifications[notification]?.[
                                                            SettingsKey.URL_BROWSER_SOUND_NOTIFICATION
                                                        ],
                                                    )
                                                        ? 'primary'
                                                        : 'error'
                                                }
                                                size="small"
                                                label={t('profile.settings.seoSettings.baseUrl')}
                                                variant="outlined"
                                                value={
                                                    notifications[notification]?.[
                                                        SettingsKey.URL_BROWSER_SOUND_NOTIFICATION
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    handleValueChange(
                                                        e,
                                                        notification,
                                                        SettingsKey.URL_BROWSER_SOUND_NOTIFICATION,
                                                    )
                                                }
                                            />
                                            <FormHelperText
                                                error={
                                                    !validBaseUrl(
                                                        notifications[notification]?.[
                                                            SettingsKey.URL_BROWSER_SOUND_NOTIFICATION
                                                        ],
                                                    )
                                                }
                                            >
                                                {!validBaseUrl(
                                                    notifications[notification]?.[
                                                        SettingsKey.URL_BROWSER_SOUND_NOTIFICATION
                                                    ],
                                                ) && t('profile.settings.urlValidationError')}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    )}
                </div>
            );
        });
    }

    return renderNotificationSettings();
};

export default ICallNotificationsSettings;
