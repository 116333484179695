import Item from './Item';
import useStylesTerms from './styles/useStylesTerms.style';

const TermsESv3 = () => {
    const { classes, cx } = useStylesTerms();
    return (
        <div className={cx(classes.root)}>
            <h1 className={cx(classes.title)}>CONDICIONES DEL SERVICIO PLATAFORMA ONLIVE</h1>

            <ol>
                <Item title={'Información previa'}>
                    <p>
                        El presente acuerdo de servicio (en adelante, el <b>"Contrato"</b> o{' '}
                        <b>"ACUERDO DEL SERVICIO"</b>) regula el acceso y uso de los servicios ofrecidos por Onlive
                        Site, S.L. (en adelante, <b>"ONLIVE"</b>) y establece los Términos y Condiciones Legales que
                        serán de aplicación al uso de los servicios y de los sitios (según se definen a continuación).
                    </p>
                </Item>

                <Item title={'Definitions'}>
                    <p>
                        <b>“CLIENTE”</b> significa el cliente.
                    </p>
                    <p>
                        <b>“Datos del CLIENTE”</b> significa toda información, datos u otros contenidos proporcionados
                        por el CLIENTE en relación con la utilización del Servicio, incluyendo, sin limitación alguna,
                        cualquier dato e información transmitida al Servicio a través de las Librerías del CLIENTE.
                    </p>
                    <p>
                        <b>“Librerías del CLIENTE”</b> significa las librerías del CLIENTE proporcionadas por ONLIVE e
                        instaladas en las aplicaciones del CLIENTE a los efectos de poder recabar los Datos del CLIENTE
                        y enviar dichos datos a la plataforma ONLIVE.
                    </p>
                    <p>
                        <b>Documentación</b> significa los manuales o guías de implementación; información de ayuda y
                        otra documentación del Usuario relativa al Servicio que sea proporcionada por ONLIVE al CLIENTE
                        en formato electrónico o cualquier otro formato.
                    </p>
                    <p>
                        <b>“Información Confidencial”</b> significa toda la información técnica, jurídica, económica,
                        comercial, organizativa o de cualquier otro tipo correspondiente a cualquiera de las Partes que
                        por su naturaleza pudiera ser considerada confidencial, con independencia del soporte en que
                        constara o de los medios empleados para su revelación, así como cualquier análisis,
                        recopilación, estudio, resumen o extracto que cualquiera de las Partes o ambas, conjuntamente,
                        hubieran elaborado a partir de la información/documentación inicialmente divulgada por
                        cualquiera de ellas.
                    </p>
                    <p>
                        <b>“Plataforma ONLIVE”</b> significa la plataforma titularidad de ONLIVE que ayuda a las marcas
                        a potenciar sus ventas online y la fidelidad de sus clientes mediante la creación de shows de
                        compra en tiempo real interactivos y divertidos. Con ONLIVE cualquier comercio puede crear
                        fácilmente su propio canal audiovisual para vender, lanzar y demostrar sus productos en tiempo
                        real. Las marcas pueden poner cara y voz a sus productos mientras interactúan con sus clientes,
                        y la experiencia de compra y el servicio al cliente se vuelven más realistas, interesantes e
                        innovadores.
                    </p>
                    <p>
                        <b>“Propiedad de ONLIVE”</b> significa todas las ideas, conceptos, invenciones, sistemas,
                        plataformas, programas informáticos, interfaces, herramientas, utilidades, plantillas, formas,
                        técnicas, métodos, procesos, algoritmos, conocimientos técnicos, secretos comerciales y otras
                        tecnologías e informaciones adquiridas, creadas, desarrolladas o autorizadas por ONLIVE, antes o
                        fuera del ámbito del presente Contrato y cualquier mejora, modificación, ampliación u otros
                        trabajos derivados de los mismos y todos los derechos de propiedad intelectual de los mismos,
                        incluyendo sin limitación el Servicio, las Librerías del CLIENTE, Documentación, Plantillas de
                        Informes y Datos Agregados. La propiedad del ONLIVE excluye los Datos y Resultados del CLIENTE.
                    </p>
                    <p>
                        <b>“Usuarios Mensuales Activos”</b> significa cada una de las instalaciones individuales de la
                        aplicación del CLIENTE que puede transmitir Datos del CLIENTE durante el período de
                        contratación.
                    </p>
                    <p>
                        <b>“Registro”</b> significa un registro en referencia a este Contrato y a los servicios
                        concretos que hayan sido acordados y firmados mutuamente entre las Partes.
                    </p>
                    <p>
                        <b>“Resultados”</b> significa el trabajo resultante de los Servicios prestados por ONLIVE al
                        CLIENTE a través del Servicio, incluyendo cualquier show grabado, basados en lo menester en los
                        Datos del CLIENTE. Los Resultados excluyen expresamente cualquier Propiedad de ONLIVE.
                    </p>
                    <p>
                        <b>“Servicio”</b> significa el servicio de ONLIVE prestado al CLIENTE según lo identificado en
                        el Registro.
                    </p>
                    <p>
                        <b>“Sitios”</b> significa websites u otros sitios gestionados por ONLIVE que son accesibles por
                        parte del CLIENTE bajo lo previsto en el presente Contrato.
                    </p>
                    <p>
                        <b>“Usuario”</b> significa una persona física autorizada por el CLIENTE a utilizar el Servicio,
                        a favor de la cual el CLIENTE ha adquirido una suscripción (o en el caso de Servicios prestados
                        por ONLIVE sin cargo, a favor de la cual se presta el Servicio), y en relación con la cual el
                        CLIENTE (o, en su caso, ONLIVE a instancias del CLIENTE) ha proporcionado un usuario y
                        contraseña (en relación con Servicios que requieren autenticación). A título de ejemplo, los
                        Usuarios pueden comprender empleados, consultores, proveedores o agentes del CLIENTE, así como
                        terceras partes con las que el CLIENTE mantiene relaciones comerciales.
                    </p>
                </Item>

                <Item title={'Aceptación del Contrato'}>
                    <p>
                        Mediante la aceptación del CONTRATO, el cliente (en adelante, el <b>“CLIENTE”</b>) se compromete
                        a actuar en todo momento de conformidad con las disposiciones establecidas en el clausulado del
                        presente texto legal, los cuales definen los derechos y obligaciones de ONLIVE y del CLIENTE a
                        propósito de la relación contractual que se deriva de la utilización de los servicios accesibles
                        a través del sitio web <a href="https://onlive.site/">www.onlive.site</a> (en adelante,{' '}
                        <b>“SITIO WEB”</b> o <b>“Plataforma ONLIVE”</b>).
                    </p>
                    <p>
                        Queda prohibido el acceso, registro, navegación, utilización, alojamiento y/o descarga de
                        contenidos y/o uso de los servicios del SITIO WEB por parte de menores de edad (menores de 18
                        años). Mediante la aceptación del presente Contrato, el CLIENTE declara ser mayor de 18 años y/o
                        tener capacidad legal para contratar y utilizar los servicios presentes en el SITIO WEB.
                    </p>
                </Item>

                <Item title={'Condiciones del Servicio'}>
                    <ol>
                        <Item title={'Licencia'}>
                            <p>
                                ONLIVE otorga al CLIENTE, una licencia limitada, no exclusiva, no transferible (sin
                                derecho a sublicenciar), durante el plazo de Servicio especificado en el Registro para
                                (a) el acceso y utilización remotos de la Plataforma ONLIVE según lo descrito en el
                                Registro, (b) la descarga, instalación y uso de las librerías del CLIENTE en relación
                                con el uso del Servicio por parte del CLIENTE.
                            </p>
                            <p>
                                El Servicio será puesto a disposición del CLIENTE únicamente como alojado por o en
                                representación de ONLIVE, y nada de los previsto en el Contrato podrá ser entendido como
                                la concesión al CLIENTE de ningún derecho a recibir copia alguna del Servicio o de
                                software (distinto de las Librerías del Cliente).
                            </p>
                            <p>
                                El acceso y uso del Servicio por parte del CLIENTE quedará sujeto al cumplimiento de las
                                condiciones establecidas en el Registro y Documentación (como, por ejemplo, cualquier
                                requerimiento relativo al formato de los datos, número de Usuarios o usos prohibidos)
                            </p>
                        </Item>
                        <Item title={'Acceso al Servicio y disponibilidad'}>
                            <ol>
                                <Item title={'Sistemas del CLIENTE'}>
                                    <p>
                                        El CLIENTE será responsable de (i) los equipos, suscripciones y credenciales
                                        necesarias para que ONLIVE pueda recibir los Datos del CLIENTE y (ii) los
                                        servidores, dispositivos, almacenamiento, programas (diversos de las Librerías
                                        del CLIENTE), bases de datos, redes y equipos de comunicaciones y servicios
                                        auxiliares necesarios para establecer la conexión, acceder o de cualquier forma
                                        utilizar los Servicios en sus instalaciones (colectivamente,{' '}
                                        <b>"Sistemas del CLIENTE"</b>). El CLIENTE garantiza que todos los Sistemas del
                                        CLIENTE sean compatibles con el Servicio y que cumplen con todas las
                                        configuraciones y especificaciones descritas en la Documentación.
                                    </p>
                                </Item>
                                <Item title={'Acceso al Servicio'}>
                                    <p>
                                        Como parte del proceso de implementación, el CLIENTE creará un usuario principal
                                        y una contraseña que serán utilizados para configurar la cuenta del CLIENTE. El
                                        CLIENTE podrá utilizar el usuario principal y la contraseña para crear
                                        subcuentas para sus Usuarios (cada uno de los cuales con su correspondiente
                                        usuario y contraseña).
                                    </p>
                                    <p>
                                        El CLIENTE será responsable de cualesquiera actos u omisiones de cualquier
                                        persona que acceda al Servicio utilizando las contraseñas creadas por el
                                        CLIENTE. ONLIVE se reserva el derecho de rechazar el registro, o de cancelar,
                                        las cuentas que infrinjan los términos y condiciones establecidos en este
                                        Contrato. En el momento en que tenga conocimiento de ello, el CLIENTE notificará
                                        inmediatamente a ONLIVE cualquier uso no autorizado de la cuenta del CLIENTE o
                                        cualquier otro fallo o problema de seguridad.
                                    </p>
                                    <p>
                                        Periódicamente, el personal de ONLIVE podrá acceder al Servicio a la cuenta del
                                        CLIENTE a efectos de mantener y mejorar el Servicio, incluyendo la asistencia
                                        técnica al CLIENTE en relación con aspectos técnicos o de facturación. El
                                        CLIENTE manifiesta su conocimiento y, en lo menester, su consentimiento a tal
                                        acceso. Dicho acceso será llevado a cabo utilizando una cuenta de usuario de
                                        ONLIVE y en ningún caso accediendo a las cuentas individuales de los usuarios o
                                        bajo la identidad de los Usuarios del CLIENTE.
                                    </p>
                                </Item>
                            </ol>
                        </Item>
                        <Item title={'Disponibilidad del Servicio'}>
                            <p>
                                ONLIVE hará esfuerzos comerciales que sean razonables para mantener la disponibilidad
                                del Servicio para enviar y recibir datos, sujeto a los cortes derivados de
                                mantenimiento, reparaciones y actualizaciones. En la medida de lo posible, ONLIVE
                                intentará notificar al CLIENTE, por vía electrónica a través del Servicio, de
                                cualesquiera suspensiones del Servicio programadas.
                            </p>
                            <p>
                                No obstante lo anterior, ONLIVE no será responsable por cualesquiera fallos en el
                                Servicio o cualesquiera otros problemas que tengan relación con (a) los datos del
                                CLIENTE o los Sistemas del CLIENTE, o (b) cortes en las redes de telecomunicaciones o
                                proveedores de acceso a Internet, redes o servidores, u otros equipos o servicios fuera
                                de las instalaciones de ONLIVE o de su control.
                            </p>
                        </Item>
                        <Item title={'Soporte del Servicio'}>
                            <p>
                                ONLIVE prestará al CLIENTE soporte por correo electrónico para la utilización del
                                Servicio durante el horario laboral de ONLIVE. El CLIENTE acepta que ONLIVE no será
                                responsable de proporcionar soporte en relación con problemas, errores o consultas
                                relacionadas con los Sistemas del CLIENTE.
                            </p>
                        </Item>
                        <Item title={'Servicios profesionales'}>
                            <p>
                                Periódicamente el CLIENTE puede requerir y ONLIVE acceder a prestar servicios de
                                customización, consultoría, formación u otros servicios profesionales que sean acordados
                                mutuamente entre las Partes (<b>"Servicios Profesionales"</b>).
                            </p>
                            <p>
                                Los términos y condiciones de cualesquiera de dichos acuerdos para la prestación de
                                Servicios Profesionales se establecerán en un documento de encargo específico que
                                deberán acordar las Partes con referencia específica a este Contrato ("Registro"), cada
                                uno de ellos sometido a las previsiones de este Contrato. Salvo que se acuerde lo
                                contrario entre las Partes y se establezca en el correspondiente Registro, toda la
                                propiedad intelectual, y derechos que se deriven, que sean creados por ONLIVE durante la
                                prestación de los Servicios Profesionales, corresponderán de forma exclusiva a ONLIVE
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Restricciones y obligaciones'}>
                    <ol>
                        <Item title={'Restricciones de la licencia'}>
                            <p>
                                El CLIENTE no podrá ni directa ni directamente: (i) utilizar el Servicio o cualesquiera
                                de las Propiedades o Informaciones Confidenciales para crear un servicio, software o
                                documentación que realice sustancialmente las mismas funciones que el Servicio; (ii)
                                desmontar, decompilar, aplicar ingeniería inversa sobre el software o ni intentar por
                                otros medios descubrir el código fuente, algoritmos o secretos comerciales en relación
                                con los Servicios, salvo en la medida permitida por la ley aplicable; (iii) gravar,
                                sublicenciar, transferir, distribuir, alquilar, arrendar, compartir o utilizar las
                                Propiedades de ONLIVE en relación con otros contratos de servicios o en beneficio de
                                cualquier tercero; (iv) copiar, reproducir, traducir, adaptar, combinar, crear trabajos
                                derivados o de cualquier otra forma modificar cualquier Propiedad de ONLIVE; o (v) usar
                                o permitir la utilización de los Servicios para almacenar o transferir cualesquiera
                                Datos del CLIENTE que estuvieran controlados a efectos de exportación, de acuerdo con
                                las Leyes de Control de la Exportación o cualquier otra jurisdicción relevante a estos
                                efectos.
                            </p>
                        </Item>
                        <Item title={'Usos no autorizados del Servicio'}>
                            <p>
                                El CLIENTE no podrá directa o indirectamente: (i) exceder el número activo de Usuarios
                                Mensuales Activos; (ii) utilizar valores únicos o un conjunto continuo de valores como
                                inputs de atributos de eventos, (iii) interferir o intentar interferir con el adecuado
                                funcionamiento del Servicio o de cualquier actividad llevada a cabo en el Servicio; (iv)
                                eludir las opciones de privacidad o medidas que ONLIVE pueda utilizar para prevenir o
                                restringir el acceso al Servicio (u otras cuentas, sistemas informáticos o redes
                                conectadas al Servicio); (v) utilizar listas de email, cualquier forma de
                                autor-respuesta o "spam" en el Servicio, o (vi) utilizar software manual o automatizado,
                                dispositivos u otros procesos para rastrear cualquier página de los Sitios.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Registro, precio y pago'}>
                    <ol>
                        <Item title={'Registro'}>
                            <p>
                                Las partes podrán acordar uno o más Registros en virtud del presente Contrato. Cada
                                Registro deberá detallar el Servicio a prestar, precio del Servicio, el plazo durante el
                                cual el Servicio se va a prestar, las condiciones de facturación, y cualesquiera otros
                                términos acordados entre las partes. ONLIVE únicamente será responsable de prestar el
                                Servicio identificado en un Registro durante el plazo especificado en dicho Registro.
                            </p>
                            <p>
                                El Registro se acordará en el momento del alta del usuario, ya que éste tendrá la
                                capacidad de elegir qué “Addons” desea suscribir y por cuánto tiempo desea hacerlo,
                                teniendo la opción de elegir la licencia de renovación mensual, trimestral o anual.
                                Asimismo, en limitadas ocasiones, cuando el usuario solicite la firma de manera física,
                                se le facilitará una Orden de Pedido en la que quedarán recogidas las características
                                del servicio, la forma de la prestación del servicio, la duración y demás estipulaciones
                                en relación con el Contrato.
                            </p>
                        </Item>
                        <Item title={'Precio del Servicio'}>
                            <p>
                                El CLIENTE deberá satisfacer el precio correspondiente a la utilización del Servicio (
                                <b>"Precio del Servicio"</b>) en la cantidad y de acuerdo con el calendario de
                                facturación establecido en el Registro. Salvo que se disponga otra cosa en el Registro,
                                ONLIVE facturará el Precio del Servicio de forma anual.
                            </p>
                        </Item>
                        <Item title={'Precio de los Servicios Profesionales'}>
                            <p>
                                El CLIENTE deberá satisfacer el precio de los Servicios Profesionales conforme las
                                tarifas y calendario de facturación establecido en el Registro.
                            </p>
                        </Item>
                        <Item title={'Condiciones de pago'}>
                            <p>
                                Los pagos se realizan en euros a la cuenta de ONLIVE, en su totalidad, sin compensación,
                                reconvención o deducción en los términos y condiciones establecidos en el Registro. En
                                caso de impago, ONLIVE podrá exigir un interés de demora a razón de 1,5% por mes o la
                                cantidad máxima permitida por la ley, lo que sea menor.
                            </p>
                            <p>
                                El CLIENTE, por su parte, se obliga a reembolsar a ONLIVE todos los gastos (incluyendo
                                honorarios de abogados) incurridos por ONLIVE en el cobro de los pagos atrasados. ONLIVE
                                podrá, asimismo, a su plena discreción, suspender el acceso del CLIENTE al Servicio o
                                terminar este Contrato en el caso que el CLIENTE no esté al corriente de pago de las
                                cantidades debidas a ONLIVE.
                            </p>
                        </Item>
                        <Item title={'Impuestos'}>
                            <p>
                                Será responsabilidad del CLIENTE abonar los Impuestos aplicables y pagará a ONLIVE los
                                Servicios prestados sin ningún tipo de reducción en los importes correspondientes. En
                                caso de que de conformidad con la normativa aplicable ONLIVE se vea en la obligación de
                                recaudar o de pagar Impuestos, estos serán facturados al CLIENTE, excepto que este
                                proporcione a ONLIVE un certificado de exención tributaria válido emitido por la
                                autoridad fiscal pertinente.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'CONFIDENCIALIDAD'}>
                    <ol>
                        <Item title={'Alcance'}>
                            <p>
                                Tendrán la consideración de Información Confidencial del CLIENTE, en particular, (a) el
                                Servicio, las Propiedades de <b>ONLIVE</b> y la información sobre los precios tendrán la
                                consideración de Información Confidencial de <b>ONLIVE</b>, y (b) los Datos del CLIENTE.
                            </p>
                            <p>
                                La Información Confidencial excluye expresamente cualquier información en la medida en
                                que el receptor pueda demostrar que dicha información (a) ya era conocida por él antes
                                de recibirla de la parte reveladora sin restricciones, (b) le fue proporcionada
                                legítimamente y sin restricciones por un tercero que no haya incumplido ninguna
                                obligación con la parte reveladora, (c) está a disposición del público en general sin
                                incumplir este Contrato o (d) ha sido desarrollada de forma independiente por el
                                receptor sin hacer referencia a la Información Confidencial de la parte reveladora ni
                                utilizarla.
                            </p>
                        </Item>
                        <Item title={'Confidencialidad'}>
                            <p>
                                Ambas Partes se comprometen respecto a la Información Confidencial a (i) mantenerla
                                secreta, incluyendo este deber, cualquier divulgación de la información, no sólo a
                                terceros sino también a cualquier persona no autorizada en virtud del presente Contrato;
                                (ii) adoptar todas las medidas necesarias tendentes a evitar que dicha información pueda
                                ser conocida por terceros no autorizados por el titular de la misma.
                            </p>
                            <p>
                                En este sentido, cada una de las Partes protegerá la Información Confidencial de la otra
                                parte utilizando los mismos medios de protección que utiliza para proteger su propia
                                Información Confidencial y nunca medios interiores a los razonablemente exigibles en
                                atención a la naturaleza de la información y al estado de la técnica; (iii) no utilizar
                                la Información Confidencial, directa o indirectamente, para ningún fin ajeno a la
                                prestación de los Servicios; (iv) Restringir el acceso a la Información Confidencial
                                sólo a aquellos empleados, propios o subcontratados, que deban conocerla con motivo de
                                la ejecución del Contrato; (v) No reproducir o copiar la Información Confidencial a
                                menos que sea imprescindible para dar cumplimiento a lo establecido en el presente
                                Contrato o se obtenga el consentimiento previo y escrito de la parte a la que
                                pertenezca.
                            </p>
                            <p>
                                Nada de lo dispuesto en el presente Contrato impedirá a una de las Partes{' '}
                                <b>divulgar</b> el presente Contrato o cualquier Información Confidencial de la otra
                                Parte según sea necesario en virtud de una orden judicial o de cualquier{' '}
                                <b>requerimiento</b> legal, reglamentario o similar; siempre y cuando, antes de dicha
                                divulgación, la parte receptora realice esfuerzos razonables para (a) notificar con
                                prontitud a la parte
                                <b>divulgadora</b> por escrito dicho requisito de divulgación y (b) cooperar con la
                                parte
                                <b>divulgadora</b> para proteger o minimizar dicha <b>divulgación</b> u obtener una
                                orden de protección.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Propiedad Intelectual'}>
                    <ol>
                        <Item title={'Resultados y Datos del CLIENTE'}>
                            <p>
                                El CLIENTE será propietario de todos los derechos, títulos, intereses (incluyendo toda
                                la propiedad intelectual y otros derechos de propiedad) relacionados con los Resultados
                                (incluido cualquier show de cliente grabado a través de la Plataforma ONLIVE), los Datos
                                del CLIENTE y Sistemas del CLIENTE.
                            </p>
                            <p>
                                El CLIENTE otorga a ONLIVE un derecho no exclusivo, gratuito y con licencia para
                                acceder, usar, copiar, procesar y almacenar los Datos del CLIENTE con el único fin de
                                prestar el Servicio. El CLIENTE es consciente y acepta que el Servicio depende de la
                                disponibilidad de los Datos del CLIENTE. El CLIENTE será el único responsable de los
                                Datos del CLIENTE recogidos de los Usuarios durante el uso de los Servicios por parte
                                del CLIENTE, incluyendo la exactitud e integridad de dicha información. Salvo que se
                                establezca lo contrario en el Registro, ONLIVE no tendrá obligación alguna de almacenar
                                los Datos del CLIENTE o Resultados. Excepto los derechos y licencias limitadas aquí
                                concedidos, no se conceden otras licencias, ningún otro uso está permitido y el Cliente
                                conservará todos los derechos, títulos e intereses (incluyendo toda la propiedad
                                intelectual y los derechos de propiedad sobre la misma) sobre los Resultados, los Datos
                                del CLIENTE y Sistemas del CLIENTE.
                            </p>
                            <p>
                                Asimismo, el CLIENTE concede a ONLIVE un derecho y una licencia no exclusivos y libres
                                de derechos para acceder, utilizar, copiar, procesar y almacenar los Resultados,
                                incluidos los shows del CLIENTE grabados a través de la Plataforma ONLIVE, que pueden
                                ser almacenados, editados y distribuidos a través de la Plataforma ONLIVE y las redes
                                sociales de ONLIVE, a menos que se establezca lo contrario en el Registro.
                            </p>
                        </Item>
                        <Item title={'Feedback del Servicio'}>
                            <p>
                                El CLIENTE podrá, a su sola discreción, proponer cambios, modificaciones o mejoras sobre
                                el Servicio a través de <a href="mailto:hello@onlive.site">hello@onlive.site</a> (
                                <b>“Feedback”</b>). Cualquier Feedback generado será propiedad exclusiva de ONLIVE
                                (incluyendo cualesquiera derechos de propiedad intelectual que puedan derivarse) y serán
                                considerados como Información Confidencial de ONLIVE. El CLIENTE se obligue a realizar
                                cuantas actuaciones sean necesarias para formalizar dicha propiedad.
                            </p>
                        </Item>
                        <Item title={'Propiedad de ONLIVE'}>
                            <p>
                                El CLIENTE declara conocer y acepta que la Plataforma ONLIVE, incluyendo el Software
                                ONLIVE y cualesquiera otro software, hardware y documentación asociada ha sido creada
                                por ONLIVE, quien conservará todos los derechos de propiedad intelectual, industrial o
                                cualesquiera otros sobre la misma, que no podrá ser objeto de ulterior modificación,
                                copia, alteración, reproducción, adaptación o traducción por parte del CLIENTE.
                            </p>
                            <p>
                                La estructura, características, códigos, métodos de trabajo, sistemas de información,
                                herramientas de desarrollo, know-how, metodologías, procesos, tecnologías o algoritmos
                                de la Plataforma ONLIVE son propiedad de ONLIVE, o de sus proveedores, habiendo sido, en
                                este último caso, objeto de licencia o cesión por parte de los mismos, y están
                                protegidos por las normas españolas o internacionales de propiedad intelectual e
                                industrial, y no pueden ser objeto de ulterior modificación, copia, alteración,
                                reproducción, adaptación o traducción por parte del CLIENTE.
                            </p>
                            <p>
                                El acceso por parte del CLIENTE a la Plataforma ONLIVE no implica, en ningún caso, la
                                cesión de su titularidad ni la concesión de un derecho de uso a favor del CLIENTE
                                distinto al establecido en el presente Contrato o en los Registros.
                            </p>
                            <p>
                                En consecuencia, queda terminantemente prohibido cualquier uso por el CLIENTE de la
                                Plataforma ONLIVE o de los Materiales Asociados que se realice sin la autorización de
                                ONLIVE, incluida su explotación, reproducción, difusión, transformación, distribución,
                                transmisión por cualquier medio, posterior publicación, exhibición, comunicación pública
                                o representación total o parcial, las cuales, de producirse, constituirán infracciones
                                de los derechos de propiedad intelectual o industrial de ONLIVE, sancionadas por la
                                legislación vigente.
                            </p>
                        </Item>
                        <Item title={'Datos agregados'}>
                            <p>
                                El CLIENTE acepta que ONLIVE tiene plena libertad para (i) recabar, utilizar y crear
                                trabajos derivativos de los datos relacionados con la utilización del Servicio y
                                rendimiento derivado de los Resultados; (ii) agregar dichos datos con otros datos para
                                crear compilaciones y análisis de dichos datos (<b>“Datos Agregados”</b>); y (iii)
                                utilizar, copiar, modificar, crear trabajos derivativos, publicar y compartir dichos
                                Datos Agregados de forma tal que no se pueda identificar ni directa ni indirectamente a
                                los CLIENTES o a cualquier persona individual. ONLIVE será titular de cualesquiera
                                derechos, títulos e intereses relacionados con los Datos Agregados y cualesquiera
                                trabajos derivativos. En adición, ONLIVE gozará de plena libertad para reutilizar todo
                                el conocimiento general, experiencia, know-how, trabajos y tecnologías (incluyendo
                                ideas, conceptos, procesos y técnicas) relacionadas con los Resultados o adquiridas
                                durante la provisión del Servicio (incluyendo sin limitación alguna, aquellos que
                                pudiera haber adquirido prestando los mismos o similares servicios para otro cliente).
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Responsabilidad'}>
                    <ol>
                        <Item title={'Indemnización de ONLIVE por incumplimiento'}>
                            <p>
                                Salvo por lo dispuesto a continuación, ONLIVE se compromete a (a) defender al CLIENTE
                                contra cualquier demanda, reclamación, acción, procedimiento o pleito (cada uno de
                                ellos, una "Reclamación") por parte de un tercero de que el uso autorizado del Servicio
                                por parte del CLIENTE infringe cualquier patente o derecho de autor o se apropia
                                indebidamente de cualquier secreto comercial de dicho tercero y (b) indemnizar al
                                CLIENTE por los importes de liquidación o daños y perjuicios, responsabilidades, costes
                                y gastos (incluidos honorarios razonables de abogados, "Pérdida(s)") otorgados a dicho
                                tercero por un tribunal de jurisdicción competente o acordados como parte de un acuerdo
                                monetario derivado de dicha Reclamación; siempre y cuando (i) el CLIENTE notifique a
                                ONLIVE por escrito y le proporcione la cooperación, información y asistencia razonables
                                en relación con la misma, y (ii) ONLIVE tenga el control y la autoridad exclusivos para
                                defender, resolver o comprometer dicha Reclamación.
                            </p>
                            <p>
                                Si el Servicio se convierte o, en opinión de ONLIVE, es probable que se convierta en
                                objeto de cualquier requerimiento judicial que impida su uso tal y como se contempla en
                                el presente documento, ONLIVE podrá, a su elección, (1) obtener para el CLIENTE el
                                derecho a seguir utilizando el Servicio o (2) sustituir o modificar el Servicio para que
                                se convierta en no infractor sin comprometer sustancialmente sus funciones principales.
                            </p>
                            <p>
                                Si (1) y (2) no están razonablemente al alcance de ONLIVE, esta podrá resolver este
                                Contrato mediante notificación por escrito al CLIENTE y reembolsar al CLIENTE cualquier
                                tarifa de servicio prepagada no utilizada, prorrateada por el resto del período
                                prepagado. ONLIVE no tendrá ninguna responsabilidad ni obligación ante el CLIENTE en
                                virtud del presente Contrato con respecto a cualquier Reclamación o Pérdida en la medida
                                en que se base en (a) cualquier uso no autorizado del Servicio, (b) cualquier
                                modificación o combinación del Servicio con datos, software, hardware o sistemas no
                                proporcionados por ONLIVE, (c) cualquier parte del Servicio que implemente los
                                requisitos específicos del CLIENTE, (d) la continuación de la actividad supuestamente
                                infractora por parte del CLIENTE después de que se le haya notificado que cese su uso
                                según lo previsto en el presente documento o (e) el uso continuado por parte del CLIENTE
                                de cualquier versión de las Propiedades de ONLIVE después de que se le hayan
                                proporcionado modificaciones que habrían evitado la supuesta infracción. Lo anterior
                                establece la única y exclusiva responsabilidad de ONLIVE, y el único y exclusivo recurso
                                del CLIENTE, con respecto a cualquier violación real o supuesta de los derechos de
                                propiedad intelectual por parte del Servicio o cualquier parte del mismo o por su uso o
                                funcionamiento.
                            </p>
                        </Item>
                        <Item title={'Indemnidad por parte del CLIENTE'}>
                            <p>
                                El CLIENTE acepta (i) defender a ONLIVE ante cualesquiera demanda o reclamación de
                                terceros que traigan causa o sean consecuencia de (a) el incumplimiento del CLIENTE de
                                sus obligaciones derivadas del Contrato, (b) el incumplimiento por parte del CLIENTE de
                                las declaraciones y garantías establecidas en el presente Contrato, o (c) cualquier
                                violación sobre derechos de terceros (incluyendo cualquier usuario del CLIENTE); y (ii)
                                indemnizar a ONLIVE en relación con cualesquiera pérdidas declaradas por un tribunal o
                                juez competente o convenidas en un acuerdo extrajudicial en relación con dicha demanda o
                                reclamación; en la medida en que (i) ONLIVE notifique inmediatamente por escrito al
                                CLIENTE y proporcione cooperación, información y asistencia razonable en relación con la
                                misma, y (ii) el CLIENTE tenga control directo para defenderse y alcanzar cuantos
                                acuerdos en relación con dicha demanda o reclamación.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Limitación de responsabilidad'}>
                    <p>
                        Las partes no serán responsables, en ningún caso, de daños indirectos, imprevistos, especiales o
                        accidentales, inclusive pérdida de beneficios, ingresos, datos o uso sufridos por cualquiera de
                        las partes o por un tercero, incluso si la otra parte o cualquier otra persona ha sido advertida
                        de la posibilidad de tales daños. En consecuencia, será obligación del cliente y no de ONLIVE,
                        la realización de copias de respaldo con la periodicidad suficiente, así como la instalación de
                        medidas de seguridad y la obtención de licencias e implantación de programas de antivirus,
                        firewall, o cualquier otro que pudiera ser necesario para mantener la integridad,
                        confidencialidad y seguridad de los datos y sistemas del cliente.
                    </p>
                    <p>
                        ONLIVE responderá de los daños causados por culpa o negligencia derivados de su actuación, no
                        excediendo, en ningún caso, la indemnización que corresponda del importe correspondiente al
                        precio de la licencia efectivamente abonada por el cliente a ONLIVE en el momento en que se
                        produjo la causa que motivó la indemnización. ONLIVE no será responsable frente al cliente,
                        entre otros supuestos, de los siguientes (i) en caso de que el software no se utilice de acuerdo
                        a la documentación proporcionada al cliente por ONLIVE; (ii) en caso de que el fallo sea
                        provocado por manipulaciones del software no autorizadas previamente por ONLIVE o por software
                        de terceros.
                    </p>
                </Item>

                <Item title={'Duración y resolución del Contrato'}>
                    <ol>
                        <Item title={'Duración del CONTRATO'}>
                            <p>
                                El Contrato comenzará en la Fecha Efectiva y continuará en vigor hasta que (i) una de
                                las Partes notifique por escrito a la otra Parte, con treinta (30) días de antelación,
                                la terminación del Contrato, siempre que no exista ningún Registro en vigor, o (ii) en
                                caso de existencia de uno o más Registros en vigor, al expirar el último punto de
                                expirar de tales formularios de pedido existentes; lo que suceda más tarde.
                            </p>
                        </Item>
                        <Item title={'Plazo del Registro'}>
                            <p>
                                Tendrá la consideración de plazo inicial el fijado en el Registro (
                                <b>“Plazo Inicial”</b>). Los Registros serán renovados automáticamente por períodos
                                adicionales de un (1) año adicional (<b>“Plazo de Renovación”</b>) al finalizar el Plazo
                                Inicial y cada uno de los Plazos de Renovación (el Plazo Inicial y el Plazo de
                                Renovación considerados colectivamente como <b>“Plazo”</b>), salvo que alguna de las
                                partes comunique por escrito a la otra la no-renovación, con un plazo mínimo de treinta
                                (30) días de antelación a la finalización del Plazo. Los Registros no estarán sujetos a
                                terminación salvo por lo establecido en el apartado 12.3.
                            </p>
                        </Item>
                        <Item title={'Resolución del Registro'}>
                            <p>
                                Los Registros podrán ser resueltos por cualquiera de las Partes si la otra Parte
                                incumple sustancialmente el Contrato o el Registro en cuestión y no se procede a la
                                subsanación de dicho incumplimiento en un plazo de treinta (30) días desde la
                                notificación por escrito del mismo por la otra Parte.
                            </p>
                        </Item>
                        <Item title={'Efectos de la resolución'}>
                            <p>
                                En caso de resolución o terminación del presente Contrato (incluyendo los Registros):
                                (i) cesarán inmediatamente cualesquiera derechos otorgados por cualquiera de las partes
                                a la otra; (ii) ONLIVE permitirá al CLIENTE acceder y exportar los Datos del CLIENTE,
                                siendo de aplicación las tarifas de ONLIVE vigentes en ese momento respecto de los
                                Servicios aplicables en cuestión; (iii) transcurrido un período de noventa (90) días
                                ONLIVE borrará los Datos del CLIENTE eliminando los indicadores a los Datos del CLIENTE
                                de los servidores activos y replicados de ONLIVE; y (iv) cada Parte, a solicitud de la
                                otra, realizará los esfuerzos que fuesen razonables a fin de devolver o destruir
                                inmediatamente cualquier Información Confidencial de esta última que se encontrase en su
                                poder.
                            </p>
                        </Item>
                        <Item title={'Protección de datos personales'}>
                            <p>
                                El presente Contrato pretende garantizar la seguridad y confidencialidad de los datos de
                                carácter personal que los CLIENTES facilitan cumpliendo con lo dispuesto en el
                                Reglamento 2016/679 de 27 de abril de 2016 relativo a la protección de las personas
                                físicas en lo que respecta al tratamiento de datos personales y la libre circulación de
                                estos datos (en adelante, <b>“RGPD”</b>) y la Ley Orgánica 3/2018, de 5 de diciembre, de
                                Protección de Datos de Carácter Personal y garantía de derechos digitales (en adelante,{' '}
                                <b>“LOPDGDD”</b>). En virtud del presente contrato, las Partes se comprometen a firmar
                                un contrato de encargo del tratamiento de datos que se incluye como Anexo al mismo.
                            </p>
                            <p>
                                En este sentido, se informa al CLIENTE que, en materia de privacidad y protección de sus
                                datos personales, pueden ejercitar los siguientes derechos:
                            </p>
                            <ol type="i">
                                <li>acceder a sus datos personales; </li>
                                <li>solicitar la rectificación de cualquier dato inexacto; </li>
                                <li>solicitar la supresión de sus datos; </li>
                                <li>solicitar que se limite el tratamiento de sus datos; </li>
                                <li>oponerse al tratamiento de sus datos;</li>
                                <li>
                                    solicitar la portabilidad de sus datos. Asimismo, el CLIENTE tendrá derecho a no ser
                                    objeto de decisiones basadas únicamente en el tratamiento automatizado de datos.
                                </li>
                            </ol>
                            <p>
                                El CLIENTE puede ejercer todos estos derechos en la dirección de correo electrónico [
                                <a href="mailto:protecciondedatos@onlive.site">protecciondedatos@onlive.site</a>]
                                indicando el motivo de su petición o dirigir un escrito ejerciendo sus derechos a la
                                siguiente dirección postal: Avenida Diagonal, 383, 3º, 2ª – 08008 Barcelona. Igualmente,
                                el CLIENTE podrá dirigir sus reclamaciones a la Agencia Española de Protección de Datos
                                (https://www.aepd.es/es) si entiende que sus derechos no han sido atendidos
                                correctamente.
                            </p>
                            <p>
                                Asimismo, el CLIENTE garantiza que no pedirá a los Usuarios que utilicen la Plataforma
                                ONLIVE en los términos expuestos en el presente Contrato, ningún dato considerado
                                “categorías especiales de datos personales”, en virtud del artículo 9 RGPD. En este
                                sentido, el CLIENTE no podrá solicitar a los Usuarios que hagan uso la Plataforma ONLIVE
                                que revelen su origen étnico o racial, opiniones políticas, convicciones religiosas o
                                filosóficas la afiliación sindical, ni datos relativos a la salud o datos relativos a su
                                vida u orientación sexual. Ello debido a que, las categorías especiales de datos
                                personales que merecen mayor protección únicamente deben tratarse con fines relacionados
                                con la salud cuando sea necesario para lograr dichos fines en beneficio de las personas
                                físicas y de la sociedad en su conjunto, y se entiende que no es necesario en los
                                términos establecidos en el presente Contrato.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Ley aplicable y jurisdicción'}>
                    <p>
                        Cualquier controversia surgida de la interpretación o ejecución de las presentes Contrato o de
                        cualquiera de sus eventuales modificaciones, así como cualquier incumplimiento de las mismas, se
                        interpretará de conformidad con la legislación española.
                    </p>
                    <p>
                        Para solucionar cualquier controversia relacionada con lo dispuesto en el presente Contrato o en
                        ejecución del mismo, ONLIVE y el CLIENTE se someten expresamente a los Juzgados y Tribunales de
                        Barcelona, con renuncia a cualquier otro fuero que pudiere corresponderles. El idioma a utilizar
                        será el español.
                    </p>
                    <p>
                        Cada una de las Partes deberá someterse a cualesquiera leyes y regulaciones que sean aplicables
                        en relación con el cumplimiento de sus obligaciones y el ejercicio de sus derechos según lo
                        previsto en este Contrato.
                    </p>
                </Item>

                <Item title={'Fuerza mayor'}>
                    <p>
                        Las Partes no serán responsable del incumplimiento de las obligaciones establecidas en el
                        presente Contrato en la medida en que tal incumplimiento sea debido a causas razonablemente
                        fuera de control de la Parte incumplidora, tales como, sin carácter limitativo, incendios,
                        inundaciones, huelgas, conflictos laborales u otros desórdenes sociales, escasez o
                        indisponibilidad de combustible o energía eléctrica, indisponibilidad o funcionamiento anómalo
                        de las redes de comunicaciones, accidentes, guerras (declaradas o no declaradas), embargos
                        comerciales, bloqueos, disturbios o insurrecciones.
                    </p>
                </Item>

                <Item title={'Publicidad'}>
                    <p>
                        El CLIENTE acepta que ONLIVE pueda incluir el nombre o marca del CLIENTE en una lista de
                        clientes de ONLIVE, online o en materiales de promoción. Además, el CLIENTE también acepta que
                        ONLIVE puede hacer referencia verbal al CLIENTE como un CLIENTE de productos o servicios de
                        ONLIVE, conforme a lo estipulado en este CONTRATO.
                    </p>
                </Item>

                <Item title={'Notificaciones'}>
                    <p>
                        Toda notificación o comunicación de resolución o incumplimiento del presente Contrato deberá
                        efectuarse mediante escrito dirigido a la otra Parte a la dirección especificada en el Registro.
                        Las notificaciones se entenderán entregadas en el momento de su recepción conforme resulte del
                        correspondiente acuse de recibo físico o electrónico (según corresponda en cada caso).
                    </p>
                </Item>

                <Item title={'Cesión'}>
                    <p>
                        Ninguna de las partes podrá ceder sus derechos u obligaciones derivados del presente Contrato
                        sin el previo consentimiento por escrito de la otra parte, salvo en caso de cesión a favor de
                        cualquier Compañía de su Grupo, siempre que: (i) el cesionario se comprometa por escrito a
                        quedar obligado por los términos del presente Contrato; (ii) el cedente notifique dicha cesión a
                        la otra parte; y (iii) en aquellos casos en que el cedente fuera el CLIENTE, el cesionario pase
                        satisfactoriamente todos los controles de crédito exigidos por ONLIVE.
                    </p>
                    <p>
                        ONLIVE podrá subcontratar total o parcialmente el cumplimiento de sus obligaciones derivadas del
                        presente Contrato sin necesidad de obtener el previo consentimiento por escrito del CLIENTE.
                        ONLIVE continuará no obstante siendo plenamente responsable del cumplimiento de las mismas, en
                        particular por cualesquiera acciones y/u omisiones de sus subcontratistas al respecto, tal y
                        como si se tratara de sus propias acciones y/u omisiones.
                    </p>
                </Item>

                <Item title={'Modificaciones'}>
                    <p>
                        La información que aparece en la Plataforma ONLIVE es la vigente en la fecha de su última
                        actualización. ONLIVE se reserva el derecho a actualizar, modificar o eliminar la información de
                        este, así como el presente Contrato, la Política de Privacidad o cualquier otra información.
                    </p>
                    <p>
                        La entrada en vigor de las modificaciones del Contrato se producirá desde el momento de su
                        publicación en la Plataforma ONLIVE.
                    </p>
                </Item>

                <Item title={'Cláusula de salvaguarda'}>
                    <p>
                        Todas las cláusulas o extremos de este Contrato deben ser interpretadas de forma independiente y
                        autónoma, no viéndose afectadas el resto de las estipulaciones en caso de que una de ellas haya
                        sido declarada nula por sentencia judicial o resolución arbitral firme. Se sustituirá la
                        cláusula o cláusulas afectadas por otra u otras que preserven los efectos perseguidos por el
                        Contrato.
                    </p>
                </Item>
            </ol>

            <h2>CONTRATO DE ENCARGO DE TRATAMIENTO</h2>
            <p>
                Las Partes acuerdan suscribir el presente documento de encargo del tratamiento para regular los datos
                personales que ONLIVE, en calidad de Encargado del tratamiento (en adelante, <b>el ENCARGADO</b>),
                tratará por cuenta del CLIENTE, en calidad de Responsable del tratamiento (en adelante,{' '}
                <b>el RESPONSABLE</b>).
            </p>

            <h3>PRIMERA. – DEFINICIONES</h3>
            <ol type="a">
                <Item>Objeto del Contrato: Tiene el significado otorgado en la Estipulación 2 de este Contrato.</Item>
                <Item>Fecha de Inicio: Significa la fecha de firma de este Contrato.</Item>
                <Item>
                    Responsable de tratamiento: Significa la persona física o jurídica, autoridad pública, servicio u
                    otro organismo que, solo o junto con otros, determine los fines y medios del tratamiento; si el
                    Derecho de la Unión o de los Estados miembros determina los fines y medios del tratamiento, el
                    responsable del tratamiento o los criterios específicos para su nombramiento podrá́ establecerlos el
                    Derecho de la Unión o de los Estados miembros.
                </Item>
                <Item>
                    Encargado de tratamiento: Significa la persona física o jurídica, autoridad pública, servicio u otro
                    organismo que tiene acceso de forma puntual a los datos de los que el Responsable del tratamiento es
                    responsable, y que no decide sobre la finalidad de los mismos.
                </Item>
                <Item>
                    Autoridad de Protección de Datos: Significa la correspondiente Autoridad de Protección de Datos en
                    los territorios donde se encuentran ubicadas las Partes de este Contrato.
                </Item>
                <Item>
                    Legislación sobre Protección de Datos: significa el Reglamento 2016/679, del Parlamento Europeo y
                    del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas y en lo que
                    respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante,
                    “RGPD”) y cualquier legislación y/o norma que implemente o se haya promulgado conforme al mismo, o
                    que lo modifique o sustituya, junto con las guías y los códigos de práctica emitidos por la Agencia
                    Española de Protección de Datos (AEPD) o por cualquier otra autoridad supervisora, conforme sea
                    aplicable a las correspondientes actividades de tratamiento de datos personales de las Partes o de
                    otra forma a los Datos Personales Compartidos.
                </Item>
                <Item>Partes: Las Partes firmantes del Contrato.</Item>
                <Item>
                    Fallo de seguridad: Significa cualquier pérdida efectiva, destrucción, modificación, o revelación no
                    autorizada o ilegal de o a los Datos Personales, y/o cualquier otra irregularidad en el tratamiento
                    de Datos Personales.
                </Item>
                <Item>
                    Derechos de los usuarios o interesados: Tendrá el significado otorgado en la Legislación sobre
                    Protección de Datos.
                </Item>
                <Item>
                    Elaboración de perfiles: Significa toda forma de tratamiento automatizado de datos personales
                    consistente en utilizar datos personales para evaluar determinados aspectos personales de una
                    persona física, en particular para analizar o predecir aspectos relativos al rendimiento
                    profesional, situación económica, salud, preferencias personales, intereses, fiabilidad,
                    comportamiento, ubicación o movimientos de dicha persona física.
                </Item>
                <Item>Interesado: Significa personas afectadas por el tratamiento de sus datos.</Item>
                <Item>
                    Tratamiento de Datos: Significa cualquier operación o conjunto de operaciones realizadas sobre datos
                    personales o conjuntos de datos personales, ya sea por procedimientos automatizados o no, como la
                    recogida, registro, organización, estructuración, conservación, adaptación o modificación,
                    extracción, consulta, utilización, comunicación por transmisión, difusión o cualquier otra forma de
                    habilitación de acceso, cotejo o interconexión, limitación, supresión o destrucción.
                </Item>
                <Item>
                    Consentimiento del interesado: Significa toda manifestación de voluntad libre, específica, informada
                    e inequívoca por la que el interesado acepta, ya sea mediante una declaración o una clara acción
                    afirmativa, el tratamiento de datos personales que le conciernen.
                </Item>
            </ol>

            <h3>SEGUNDA. - OBJETO DEL CONTRATO</h3>
            <ol>
                <Item>
                    El objeto del presente Contrato es regular el tratamiento por parte del ENCARGADO de los datos
                    personales titularidad del RESPONSABLE, con la finalidad de prestar los servicios de definidos en el
                    contrato principal (en adelante, “los Servicios”) encomendadas por el RESPONSABLE, consistentes en
                    la organización de espacios virtuales para la venta de productos del RESPONSABLE a usuarios del
                    RESPONSABLE.
                </Item>
                <Item>
                    El presente Contrato da lugar a un acceso a los datos por cuenta de terceros y no a una cesión o
                    comunicación de datos de carácter personal, de modo que el RESPONSABLE sigue teniendo bajo su
                    control los usos y los fines del tratamiento de los datos personales sobre los cuales ostenta la
                    condición de RESPONSABLE.
                </Item>
            </ol>

            <h3>TERCERA. - DURACIÓN</h3>
            <ol>
                <Item>
                    El tratamiento de los datos descritos en la presente estipulación tendrá la misma duración que el
                    resto de las obligaciones derivadas del presente contrato, así como la duración prevista n el
                    contrato principal.
                </Item>
                <Item>
                    Una vez terminadas las obligaciones del presente contrato y del contrato principal, el ENCARGADO
                    devolverá o destruirá los datos y de cualquier copia existente a elección del RESPONSABLE.
                </Item>
            </ol>

            <h3>CUARTA. - TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL</h3>
            <ol>
                <Item>
                    Mediante las presentes estipulaciones se habilita al ENCARGADO para tratar por cuenta del
                    RESPONSABLE, los datos de carácter personal necesarios para prestar los Servicios.
                </Item>
                <Item>
                    El ENCARGADO ha sido seleccionado comprobando que ofrece garantías suficientes para aplicar medidas
                    técnicas y organizativas apropiadas, de manera que el tratamiento sea conforme con los requisitos
                    legales garantizando la protección de los derechos de los usuarios.
                </Item>
                <Item title={'Tipos de datos personales tratados'}>
                    <p>El ENCARGADO podrá tratar las siguientes categorías de datos personales:</p>
                    <ul>
                        <Item>Datos identificativos y de contacto.</Item>
                    </ul>
                </Item>
                <Item title={'El ENCARGADO va a poder realizar las siguientes operaciones de tratamiento'}>
                    <ul>
                        <Item>Recogida</Item>
                        <Item>Registro</Item>
                        <Item>Almacenamiento</Item>
                        <Item>Utilización</Item>
                        <Item>Comunicación</Item>
                        <Item>Restricción</Item>
                        <Item>Supresión</Item>
                        <Item>Destrucción</Item>
                    </ul>
                </Item>
            </ol>

            <h3>QUINTA. - OBLIGACIONES DEL ENCARGADO</h3>
            <p>El ENCARGADO y todo su personal se comprometen a:</p>
            <ol>
                <Item>
                    Utilizar los datos personales objeto del tratamiento, o los que recoja o trate con motivo de la
                    prestación de los Servicios descritos en el presente Contrato únicamente con la finalidad de prestar
                    los Servicios al RESPONSABLE. En ningún caso podrá utilizar los datos para finalidades distintas a
                    las previstas en este Contrato.
                </Item>
                <Item>
                    Tratar los datos de acuerdo con las instrucciones del RESPONSABLE. Si el ENCARGADO considera que
                    alguna de las instrucciones infringe la normativa de Protección de Datos informará inmediatamente al
                    RESPONSABLE.
                </Item>
                <Item>
                    Garantizar que las personas autorizadas para tratar datos personales se han comprometido de forma
                    expresa y por escrito a respetar la confidencialidad y a cumplir las medidas de seguridad
                    correspondientes.
                </Item>
                <Item>
                    Tomar medidas para garantizar que cualquier persona que actúe bajo su autoridad y tenga acceso a
                    datos personales solo pueda tratarlos siguiendo las instrucciones del RESPONSABLE.
                </Item>
                <Item>
                    No comunicar los datos a terceros, salvo que cuente con la autorización expresa del RESPONSABLE.
                </Item>
                <Item>
                    Comunicar datos a otros encargados del tratamiento, de acuerdo con las instrucciones del
                    RESPONSABLE. En este caso, el RESPONSABLE identificará, de forma previa y por escrito, la entidad a
                    la que se deben comunicar los datos, los datos a comunicar y las medidas de seguridad a aplicar para
                    proceder a la comunicación.
                </Item>
                <Item>
                    Dar apoyo al RESPONSABLE en la realización de consultas previas a la autoridad de control, cuando
                    proceda.
                </Item>
                <Item>
                    Implantar las medidas de seguridad apropiadas para garantizar la seguridad de la información tratada
                    y los derechos de las personas afectadas del nivel de seguridad que corresponda.
                </Item>
                <Item>
                    Poner a disposición del RESPONSABLE toda la información necesaria para demostrar el cumplimiento de
                    las obligaciones establecidas en el presente Contrato, así como para permitir y contribuir a la
                    realización de auditorías, incluidas inspecciones, por parte del RESPONSABLE o de otro auditor
                    autorizado por el RESPONSABLE.
                </Item>
                <Item>
                    Cuando se vayan a llevar a cabo transferencias internacionales de datos, adoptar las medidas
                    adecuadas para garantizar que el tratamiento y la transferencia internacional de datos se lleva a
                    cabo en un entorno seguro, basándose, según corresponda, en las Decisiones de Adecuación que la
                    Comisión Europea tenga suscritas con cada territorio u organización internacional, o suscribiendo
                    las Cláusulas Contractuales Tipo de la Comisión Europea que en cada caso corresponda.
                </Item>
                <Item
                    title={
                        'Realizar una descripción general de las medidas técnicas y organizativas de seguridad relativas a:'
                    }
                >
                    <ol type="a">
                        <Item>La seudoanonimización y el cifrado de datos personales.</Item>
                        <Item>
                            La capacidad de garantizar la confidencialidad, integridad, disponibilidad y resiliencia
                            permanentes de los sistemas y servicios de tratamiento.
                        </Item>
                        <Item>
                            La capacidad de restaurar la disponibilidad y el acceso a los datos personales de forma
                            rápida, en caso de incidente físico o técnico.
                        </Item>
                        <Item>
                            El proceso de verificación, evaluación y valoración regulares de la eficacia de las medidas
                            técnicas y organizativas para garantizar la seguridad del tratamiento.
                        </Item>
                    </ol>
                </Item>
                <Item title={'Notificar al RESPONSABLE que va a subcontratar sus servicios, siempre que:'}>
                    <ol type="a">
                        <Item>
                            Exista una autorización por escrito del RESPONSABLE (autorización que no deberá ser
                            denegada, condicionada o demorada injustificadamente); o
                        </Item>
                        <Item>
                            Que exista un contrato que obligue al subencargado del tratamiento a condiciones
                            sustancialmente similares a las del tratamiento de los datos personales del RESPONSABLE por
                            parte del ENCARGADO de acuerdo con lo previsto en este Contrato;
                        </Item>
                    </ol>
                </Item>
                <p>
                    El subencargado del tratamiento está obligado igualmente a cumplir las obligaciones establecidas en
                    este documento para el ENCARGADO y las instrucciones que dicte el RESPONSABLE.
                </p>
                <Item
                    title={
                        'El ENCARGADO se compromete a asistir al RESPONSABLE y cooperar con él, cuando proceda, en los siguientes ámbitos:'
                    }
                >
                    <ol type="a">
                        <Item>
                            Para que el RESPONSABLE pueda cumplir con su obligación de dar respuesta a las solicitudes
                            que tengan por objeto el ejercicio de derechos de los interesados (acceso, rectificación,
                            supresión y oposición; limitación del tratamiento; portabilidad de datos; y no ser objeto de
                            decisiones individualizadas automatizadas, incluida la elaboración de perfiles) y, en su
                            caso, olvido.
                        </Item>
                        <Item>
                            En este sentido, cuando las personas afectadas ejerzan los referidos derechos ante el
                            ENCARGADO, éste debe comunicarlo por correo electrónico al RESPONSABLE, de forma inmediata y
                            en ningún caso más allá del día laborable siguiente al de la recepción de la solicitud. A la
                            comunicación se acompañará el resto de información que pueda ser relevante para resolver la
                            solicitud.
                        </Item>
                        <Item>
                            Para que el RESPONSABLE pueda cumplir los deberes de aplicar las medidas de seguridad
                            pertinentes en el tratamiento.
                        </Item>
                        <Item>
                            En la realización de las evaluaciones de impacto relativas a la protección de datos, cuando
                            procedan.
                        </Item>
                    </ol>
                </Item>
            </ol>

            <h3>SEXTA. - OBLIGACIONES DEL RESPONSABLE</h3>
            <p>Corresponde al RESPONSABLE:</p>
            <ol>
                <Item>
                    Velar por el cumplimiento de todas las medidas técnicas y organizativas necesarias con la finalidad
                    de garantizar la seguridad de los datos de carácter personal, evitando su alteración, pérdida,
                    tratamiento o acceso no autorizado.
                </Item>
                <Item>
                    Comunicar al ENCARGADO cualquier variación que se produzca en los datos de carácter personal
                    facilitados, para poder actualizarlos.
                </Item>
                <Item>
                    Recabar los datos personales objeto de tratamiento cumpliendo con la legislación aplicable en
                    materia de Protección de Datos.
                </Item>
                <Item title={'El RESPONSABLE garantiza:'}>
                    <ol type="a">
                        <Item>
                            Que los datos de carácter personal que se contienen en sus bases de datos han sido obtenidos
                            directamente de los interesados, y se les ha informado de forma previa a recabar del dato de
                            modo expreso, preciso e inequívoco, de:
                            <ul>
                                <li>La existencia de una base de datos de carácter personal.</li>
                                <li>La finalidad de la recogida de los datos.</li>
                                <li>De los destinatarios de la información.</li>
                                <li>
                                    De las consecuencias de la obtención de los datos o de la negativa a suministrarlos.
                                </li>
                                <li>
                                    De la posibilidad de ejercitar los derechos de acceso, rectificación, supresión,
                                    oposición, limitación, portabilidad y olvido.
                                </li>
                                <li>De la identidad y dirección del Responsable del tratamiento.</li>
                            </ul>
                        </Item>
                        <Item>
                            Que ha informado al interesado de las finalidades explícitas y legítimas para las que va a
                            utilizar sus datos.
                        </Item>
                        <Item>
                            Que ofrece a los interesados o destinatarios la posibilidad de oponerse al tratamiento de
                            sus datos mediante un procedimiento sencillo y gratuito.
                        </Item>
                        <Item>
                            Que ha implantado las medidas de seguridad necesarias para garantizar la salvaguarda de los
                            datos personales.
                        </Item>
                        <Item>
                            Que los datos personales son tratados únicamente por aquellos empleados cuya intervención
                            sea precisa para la finalidad contractual y les ha advertido y formado en sus obligaciones
                            con respecto a la protección de datos de carácter personal.
                        </Item>
                        <Item>Que el tratamiento de datos que realiza es legítimo.</Item>
                        <Item>
                            Que, en caso de ser preceptivo para su organización, ha nombrado un Delegado de Protección
                            de Datos.
                        </Item>
                        <Item>Que cuenta con un Registro de Actividades.</Item>
                    </ol>
                </Item>
            </ol>

            <h3>SÉPTIMA. - NOTIFICACIÓN DE VIOLACIONES DE SEGURIDAD Y EVALUACIONES DE IMPACTO</h3>
            <ol>
                <Item title={'Notificación de violaciones de seguridad de los datos'}>
                    <p>
                        El ENCARGADO notificará al RESPONSABLE por correo electrónico, sin dilación indebida y, de ser
                        posible, en el plazo máximo de 48 horas desde que existan, las violaciones de la seguridad de
                        los datos personales a su cargo de las que tenga conocimiento. A la comunicación se acompañará
                        toda la información relevante para la documentación y comunicación de la incidencia.
                    </p>
                    <p>
                        No será necesaria la notificación cuando sea improbable que dicha violación de la seguridad
                        constituya un riesgo para los derechos y las libertades de las personas físicas.
                    </p>
                    <p>Si se dispone de ella se facilitará, como mínimo, la información siguiente:</p>
                    <ol type="a">
                        <Item>
                            Descripción de la naturaleza de la violación de la seguridad de los datos personales,
                            inclusive, cuando sea posible, las categorías y el número aproximado de interesados
                            afectados, y las categorías y el número aproximado de registros de datos personales
                            afectados.
                        </Item>
                        <Item>
                            El nombre y los datos de contacto del delegado de protección de datos o de otro punto de
                            contacto en el que pueda obtenerse más información.
                        </Item>
                        <Item>
                            Descripción de las posibles consecuencias de la violación de la seguridad de los datos
                            personales.
                        </Item>
                        <Item>
                            Descripción de las medidas adoptadas o propuestas para poner remedio a la violación de la
                            seguridad de los datos personales, incluyendo, si procede, las medidas adoptadas para
                            mitigar los posibles efectos negativos.
                        </Item>
                    </ol>
                    <p>
                        Si no es posible facilitar toda la información simultáneamente, y en la medida en que no lo sea,
                        la información se facilitará de manera gradual sin dilación indebida.
                    </p>
                </Item>
                <Item title={'Evaluaciones de impacto'}>
                    <p>
                        El ENCARGADO se compromete a dar apoyo al RESPONSABLE en la realización de las evaluaciones de
                        impacto relativas a la protección de datos, cuando proceda.
                    </p>
                </Item>
            </ol>

            <h3>OCTAVA. – DESTINO DE LOS DATOS UNA VEZ FINALIZADA LA RELACIÓN</h3>
            <ol>
                <Item>
                    <p>
                        Finalizada la relación contractual, el ENCARGADO solicitará por escrito al RESPONSABLE
                        instrucciones sobre el destino que debe dar a los datos personales tratados. El RESPONSABLE
                        comunicará al ENCARGADO qué acción, de entre las siguientes, debe llevar a cabo con los datos:
                    </p>
                    <ol type="a">
                        <Item>
                            Devolver al RESPONSABLE los datos y, si procede, los soportes donde consten, una vez
                            cumplida la prestación. La devolución debe comportar el borrado total de los datos
                            existentes en los equipos informáticos utilizados por el ENCARGADO.
                        </Item>
                        <Item>
                            Entregar los datos al otro encargado que designe por escrito el RESPONSABLE y, si procede,
                            los soportes donde consten. La devolución debe comportar el borrado total de los datos
                            existentes en los equipos informáticos utilizados por el ENCARGADO.
                        </Item>
                        <Item>
                            Destruir los datos, una vez cumplida la prestación. El ENCARGADO debe certificar la
                            destrucción por escrito y entregar el certificado al RESPONSABLE.
                        </Item>
                    </ol>
                </Item>
                <p>
                    En todo caso, el ENCARGADO podrá conservar una copia de los datos, debidamente bloqueados, en tanto
                    pudieran derivarse responsabilidades de la ejecución de la prestación de servicios.
                </p>
            </ol>

            <h3>NOVENA. - RESPONSABILIDADES EN CASO DE RECLAMACIÓN</h3>
            <ol>
                <Item>
                    Si el ENCARGADO se viese implicado en cualquier investigación o procedimiento administrativo incoado
                    por una autoridad de control, o bien en una reclamación de un tercero, lo comunicará inmediatamente
                    al RESPONSABLE, describiendo los hechos que se le imputan y las actuaciones realizadas. Una vez
                    concluido el procedimiento, deberá entregarle copia de la Resolución que se dicte.
                </Item>
                <Item>
                    En caso de que una autoridad de control sancionase al RESPONSABLE o a cualquier tercero como
                    consecuencia, directa o indirecta, de que el ENCARGADO no haya cumplido las estipulaciones de este
                    contrato, el ENCARGADO indemnizará al RESPONSABLE o, en su caso, al tercero, con un importe igual a
                    la sanción, más los intereses legales, más los gastos de defensa y procesales que la misma origine,
                    más la cuantificación de cualesquiera otros daños y perjuicios que pudieran ocasionarse.
                </Item>
                <Item>
                    Sin perjuicio de lo anterior, ambas partes, de mutuo acuerdo, se comprometen a responder de la
                    totalidad de los daños y perjuicios que se irroguen a la otra en todos los supuestos de conducta
                    negligente o culposa en el cumplimiento de las obligaciones contractuales y normativas que le
                    incumben a tenor de lo pactado en el presente Contrato.
                </Item>
            </ol>

            <h3>DÉCIMA. - CONTROL DE CUMPLIMIENTO DEL PRESENTE CONTRATO</h3>
            <ol>
                <Item>
                    EL ENCARGADO pondrá a disposición del RESPONSABLE toda la información necesaria para demostrar el
                    cumplimiento de las obligaciones establecidas en este contrato y para permitir y contribuir a la
                    realización de auditorías, incluidas las inspecciones, por parte del RESPONSABLE o un tercero
                    autorizado por él. Dichos controles se deberán hacer con el correspondiente preaviso y causando al
                    ENCARGADO los menores inconvenientes posibles.
                </Item>
                <Item>
                    La falta de acreditación de que el ENCARGADO esté cumpliendo correctamente las obligaciones asumidas
                    en este contrato, será causa de resolución de este.
                </Item>
            </ol>

            <h3>UNDÉCIMA. - CONFIDENCIALIDAD</h3>
            <ol>
                <Item>
                    El ENCARGADO y el RESPONSABLE desean evitar que determinada Información Confidencial que se
                    intercambiarán en el transcurso de la relación mercantil que les une o vaya a unir (en adelante, el
                    <b>“Propósito”</b>) pueda llegar a terceras partes y por tanto ambas partes están de acuerdo en
                    regular el régimen de dicha Información Confidencial de conformidad con lo dispuesto en los
                    siguientes párrafos:
                </Item>
                <Item title={'DEFINICIONES'}>
                    <p>
                        <b>“Información Confidencial”</b> significa toda la información confidencial que las Partes se
                        podrán intercambiar como información tangible, intangible, oral, visual, electrónica, presente,
                        o futura cualquiera que sea el modo y el momento en que se adquiera, incluyendo sin limitación:
                    </p>
                    <ol type="a">
                        <Item>
                            secretos comerciales (estén o no sujetos a o protegidos por derechos de autor, patente, o
                            marca [registrada o sin registrar]);
                        </Item>
                        <Item>
                            información financiera incluyendo, pero sin limitarse, proyecciones económicas o planes
                            financieros a futuro.
                        </Item>
                        <Item>
                            información sensible incluyendo, pero sin limitarse a operaciones, planificaciones, acciones
                            de marketing y productos, proyectos tecnológicos o de cualquier clase, desarrollos, etc.
                        </Item>
                        <Item>
                            Los términos y condiciones de cualquier acuerdo suscrito entre las Partes y las discusiones,
                            negociaciones y propuestas relacionadas con cualquier de dichos contratos.
                        </Item>
                        <Item>
                            Información sobre los contratos firmados con los clientes, mensajes de correo electrónico y
                            cualquiera otra comunicación.
                        </Item>
                        <ul>
                            <Item>
                                <b>“Revelador”</b> significa la Parte que revele o divulgue Información Confidencial.
                            </Item>
                            <Item>
                                <b>“Receptor”</b> significa la Parte que recibe la Información Confidencial.
                            </Item>
                            <Item>
                                <b>“Personal”</b> significa empleados, directivos, asesores profesionales, agentes o
                                terceros contratistas de cualquiera de ambas Partes.
                            </Item>
                            <Item>
                                <b>“Requerimiento”</b> significa por aplicación de la ley o requerimiento de un juzgado,
                                tribunal, u otros organismos administrativos.
                            </Item>
                        </ul>
                    </ol>
                </Item>
                <Item title={'USO DE LA INFORMACIÓN'}>
                    <p>El Receptor utilizará la Información Confidencial en las siguientes circunstancias:</p>
                    <ol type="a">
                        <Item>Sólo para el Propósito del presente Contrato.</Item>
                        <Item>
                            De modo que se evite cualquier uso de Información Confidencial que infrinja lo dispuesto en
                            la presente estipulación y/o la comunicación de Información Confidencial a cualquier tercera
                            parte no autorizada.
                        </Item>
                        <Item>
                            La Información Confidencial solo puede facilitarse o mostrarse al Personal con base en la
                            estricta necesidad de conocer y únicamente para o en relación con el Propósito. El Receptor
                            acepta en este momento que su Personal quede obligado por pactos de confidencialidad
                            adecuados y suficientes para proteger la confidencialidad de la Información Confidencial.
                        </Item>
                    </ol>
                </Item>
                <Item title={'RESPONSABILIDAD DEL RECEPTOR DE LA INFORMACIÓN'}>
                    <p>
                        El Receptor será responsable por cualquier incumplimiento de este Acuerdo y responderá por los
                        daños y perjuicios causados por una revelación de Información Confidencial sin autorización de
                        la otra Parte.
                    </p>
                    <p>
                        Este Acuerdo no impone ninguna obligación al Receptor en relación con la Información
                        Confidencial que:
                    </p>
                    <ol type="a">
                        <Item>Se encontraba en poder de las Partes con anterioridad a la fecha de su revelación.</Item>
                        <Item>
                            Pase a ser de dominio público sin mediar falta, actuación, omisión o intervención del
                            Receptor.
                        </Item>
                        <Item>
                            Sea recibida por el Receptor por parte de un tercero que no tenga un deber de
                            confidencialidad frente al Revelador.
                        </Item>
                        <Item>
                            Sea obligatorio proporcionar como consecuencia de un requerimiento judicial o por imperativo
                            legal.
                        </Item>
                        <Item>
                            Salvo que las Partes acuerden algo distinto por escrito, la obligación de estas de proteger
                            la Información Confidencial será indefinida.
                        </Item>
                        <Item>
                            El Receptor realizará únicamente las copias de cualquier documento u otro material (por
                            cualquier medio) incorporando cualquier Información Confidencial en tanto sea razonablemente
                            necesario para el Propósito. Inmediatamente a la recepción de la solicitud por escrito del
                            Revelador, el Receptor devolverá todos los documentos u otro material original que contenga
                            o incorpore Información Confidencial al Revelador, junto con todas las copias, o certificará
                            por escrito que toda la Información Confidencial y sus copias han sido destruidas.
                        </Item>
                    </ol>
                </Item>
                <Item title={'CESIÓN DEL ACUERDO'}>
                    <p>
                        Este Acuerdo no podrá ser cedido o transmitido por ninguna de las Partes sin el previo
                        consentimiento por escrito de la otra Parte.
                    </p>
                </Item>
                <Item title={'CUANTIFICACIÓN ECONÓMICA DEL ACUERDO DE CONFIDENCIALIDAD'}>
                    <p>
                        Las partes manifiestan y aceptan que la Información Confidencial es única y cuantificable
                        económicamente por lo que cualquier revelación no consentida supondrá un incumplimiento de lo
                        dispuesto en la presente estipulación, y el mismo será generador de daños y perjuicios,
                        reservándose la parte perjudicada las acciones judiciales que procedan en reclamación de los
                        daños y perjuicios efectivamente ocasionados.
                    </p>
                    <p>
                        El ENCARGADO aplicará todas las medidas técnicas y organizativas necesarias para mantener el
                        deber de confidencialidad de la información, en concreto aquellas referentes al tratamiento de
                        datos de carácter personal pertenecientes al RESPONSABLE según establece la normativa de
                        protección de datos.
                    </p>
                    <p>
                        Esta limitación se aplicará indefinidamente, manteniéndose en vigor con posterioridad a la
                        finalización, por cualquier causa, del presente Contrato desde la comunicación de la
                        información, independientemente de la duración del presente Contrato. Ambas partes acuerdan que
                        cualquier información confidencial recibida de la otra parte debe ser utilizada a los solos
                        efectos de prestar o recibir servicios al amparo del presente Contrato.
                    </p>
                    <p>Las anteriores restricciones no se aplicarán a cualquier información:</p>
                    <ol type="a">
                        <Item>
                            Que sea o devenga accesible con carácter general para el público, por un motivo diferente al
                            incumplimiento de una obligación derivada de la presente estipulación; o bien
                        </Item>
                        <Item>
                            Que se obtenga de un tercero que no esté obligado a guardar confidencialidad en relación con
                            la información; o bien
                        </Item>
                        <Item>Que fuese por él conocida con anterioridad; o bien</Item>
                        <Item>Cuya revelación por parte del receptor venga impuesta por la ley.</Item>
                    </ol>
                </Item>
            </ol>

            <h3>DUODÉCIMA. - INCUMPLIMIENTO Y RESOLUCIÓN DEL CONTRATO</h3>
            <ol>
                <Item>
                    En el supuesto de incumplimiento de alguna de las obligaciones por parte del RESPONSABLE
                    establecidas en las estipulaciones anteriores, podrá el ENCARGADO proceder a la resolución del
                    presente Contrato sin más trámite que el comunicar al RESPONSABLE su decisión. En caso de
                    incumplimiento de alguna de las obligaciones por parte del ENCARGADO, el RESPONSABLE podrá asimismo
                    resolver el Contrato, comunicando su decisión al ENCARGADO.
                </Item>
            </ol>

            <h3>DÉCIMOTERCERA. – FUERO Y LEGISLACIÓN APLICABLE</h3>
            <ol>
                <Item>
                    Las partes, renuncian a su fuero propio sometiéndose expresamente a la jurisdicción de los Juzgados
                    y Tribunales de Madrid cualquier tipo de controversia judicial que pudiera suscitar el
                    incumplimiento del presente Contrato, y acuerdan que la legislación aplicable será la española.
                </Item>
                <Item>
                    Si alguna de las estipulaciones de este Contrato fuera declarada nula, ilegal o inexigible, la
                    validez, legalidad y exigibilidad del resto de estipulaciones no se verá afectada o perjudicada por
                    aquella. Las estipulaciones de este Contrato serán interpretadas conforme a la buena fe, al sentido
                    recto, propio y usual de las palabras con las que fueron dichas.
                </Item>
            </ol>
        </div>
    );
};

export default TermsESv3;
