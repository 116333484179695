import React from "react";

export function useAbortController() {
    const abortControllerRef = React.useRef();

    React.useEffect(() => {
        if (!abortControllerRef.current) {
            abortControllerRef.current = new AbortController()
        }
        return () => {
            abortControllerRef.current?.abort();
        }
    }, [])

    const getSignal = React.useCallback(() => {
        if (!abortControllerRef.current) {
            abortControllerRef.current = new AbortController()
        }
        return abortControllerRef.current.signal;
    }, [])

    return getSignal
}