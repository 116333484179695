const SettingsKey = {
    //---General settings-------------------------------------
    ENABLE_EVENT_CHAT: 'enable_event_chat',
    ICHAT_VERSION: 'ichat_version',
    CALL_DOMAIN_BASE_URL: 'call_domain_base_url',
    ENABLE_CAM_ON_START_ICALL: 'enable_cam_on_start_icall',
    ENABLE_WIDGET_INTERNAL_BROWSER: 'enable_widget_internal_browser',
    ENABLE_WIDGET_INTERNAL_BROWSER_TV_WIDGET: 'enable_widget_internal_browser_tv_widget',
    ENABLE_WIDGET_INTERNAL_BROWSER_CLIENT: 'enable_widget_internal_browser_client',
    ENABLE_CHAT_WIDGET: 'enable_chat_widget',
    ENABLE_TV_WIDGET: 'enable_tv_widget',
    CUSTOM_STYLES_TV_WIDGET: 'custom_styles_tv_widget',
    TV_WIDGET_VERSION: 'tv_widget_version',
    LANDING_AGENT_PATH: 'landing_agent_path',
    RULES_LANDING_AGENT: 'rules_landing_agent',
    OPTIONS_LANDING_AGENT: 'options_landing_agent',
    CUSTOM_STYLES_LANDING_AGENT: 'custom_styles_landing_agent',
    ENABLE_EVENT_CART: 'enable_event_cart',
    DEFAULT_LANGUAGE: 'default_language',
    VIEWER_EXTRA_FIELDS: 'viewer_extra_fields',
    ENABLE_NOTIFY_ME: 'enable_notify_me',
    ENABLE_SHOP_IN_STORE: 'enable_shop_in_store',
    ORGANIZATION_EMAILS_TEMPLATES: 'organization_emails_templates',
    WIDGET_COLOR: 'widget_color',
    WIDGET_VERSION: 'widget_version',
    ICALL_VERSION: 'icall_version',
    ICALL_OPTIONS: 'icall_options',
    CUSTOM_STYLES_WIDGET: 'custom_styles_widget',
    OPTIONS_WIDGET: 'options_widget',
    WIDGET_DESIGN: 'widget_design',
    RULES_WIDGET: 'rules_widget',
    WIDGET_ORIENTATION: 'widget_orientation',
    WIDGET_ACTIONS: 'widget_actions',
    ENABLE_REMARKETING_EMAIL: 'enable_remarketing_email',
    ENABLE_EMAIL_POPUP_NOTIFICATION: 'enable_email_popup_notification',
    SHOW_VIEW_TIME_LEFT_SHOPPING: 'show_view_time_left_shopping',
    MEET_RECORDING_ENABLED: 'meet_recording_enabled',
    MEET_RECORDING_LAYOUT: 'meet_recording_layout',
    MEET_QUALITY_CONTROL_ENABLED: 'meet_quality_control_enabled',
    RECORDING_RANDOM_PROBABILITY: 'recording_random_probability',
    MEET_QUALITY_CONTROL_RECORDING_LAYOUT: 'meet_quality_control_recording_layout',
    RULES_TV_WIDGET: 'rules_tv_widget',
    OPTIONS_TV_WIDGET: 'options_tv_widget',
    ENABLE_WIDGET_CHANNEL_SUBSCRIPTION: 'enable_widget_channel_subscription',
    WIDGET_USE_PRESELECTED_FLOW: 'widget_use_preselected_flow',
    INSTANT_SEARCH: 'instant_search',
    TIMEZONE: 'timezone',
    TWELVE_HOUR_FORMAT: 'twelve_hour_format',
    ENABLE_ORGANIZATION_QUEUE: 'enable_organization_queue',
    ENABLE_PERSONAL_QUEUE: 'enable_personal_queue',
    ENABLE_SHOPPING: 'enable_shopping',
    WIDGET_BTN_OPEN_AT_START: 'widget_btn_open_at_start',
    WIDGET_BTN_DISTRIBUTION: 'widget_btn_distribution',
    WIDGET_HIDE_VIDEO_CALL: 'widget_hide_video_call',
    ENABLE_REGISTER: 'enable_register',
    ENABLE_REGISTER_INSIDE_THE_SHOW: 'enable_register_inside_the_show',
    ENABLE_CHIME: 'enable_chime',
    SEO_CLIENT_BASE_URL: 'seo_client_base_url',
    SEO_CLIENT_WEB_URL: 'seo_client_web_url',
    SEO_CLIENT_WEB_URL_SOURCE: 'seo_client_web_url_source',
    SEO_CLIENT_AUTO_INDEX: 'seo_client_auto_index',
    EMAIL_SENDER_NAME: 'email_sender_name',
    TEXT_ACTION_SUBMIT_JOIN_FORM: 'text_action_submit_join_form',
    NEW_CALENDAR: 'new_calendar',
    SHOW_PRICE: 'show_price',
    ENABLE_DISCOUNT_CODES: 'enable_discount_codes',
    ENABLE_SHOPIFY_GIFT_PRODUCTS: 'enable_shopify_gift_products',
    ENABLE_EVENT_SHARE_LINK: 'enable_event_share_link',
    TEXT_START_PAGE_EVENT: 'text_start_page_event',
    TEXT_END_PAGE_EVENT: 'text_end_page_event',
    ENABLE_ENTER_EVENT_AS_GUEST: 'enable_enter_event_as_guest',
    ENABLE_SHOW_NUMBER_OF_PARTICIPANTS: 'enable_show_number_of_participants',
    SUPPORT_WIDGET_SCRIPT: 'support_widget_script',
    NEW_INSTALLATIONS: 'new_installations',
    ENABLE_INSTALLATIONS_MENU: 'enable_installations_menu',
    ENABLE_LABELS: 'enable_labels',
    ENABLE_PRESETS: 'enable_presets',
    SHOW_ICALL_CATEGORY_IN_TABLE: 'show_icall_category_in_table',
    SHOW_ICALL_RESULT_IN_TABLE: 'show_icall_result_in_table',
    ENABLE_POOL: 'enable_pool',
    SHARE_FILES: 'share_files',
    ICALL_CATEGORIES: 'icall_categories',
    ICALL_RESULTS: 'icall_results',
    ENABLE_AIRCALLS: 'enable_aircalls',
    CART_EMPTY_AFTER_CHECKOUT: 'cart_empty_after_checkout',
    EVENT_FINALIZED_TIME: 'event_finalized_time',
    STORE_UPDATE_CHECKOUT_BEHAVIOR: 'store_update_checkout_behavior',
    LEADS_MANAGEMENT_END_POINT: 'leads_management_end_point',
    LEADS_MANAGEMENT_CLIENT_ID: 'leads_management_client_id',
    LEADS_MANAGEMENT_CLIENT_SECRET: 'leads_management_client_secret',
    LEADS_STATUS: 'leads_status',
    FORMAT_DATE_EMAIL: 'format_date_email',
    FORMAT_TIME_EMAIL: 'format_time_email',
    ENABLE_DASHBOARD_SUMMARY_EMAILS: 'enable_dashboard_summary_emails',
    ORGANIZATION_EMAIL_IDENTITIES: 'organization_email_identities',
    SHARE_EMAIL_IDENTITIES: 'share_email_identities',
    HIDE_INPUT_NAME: 'hide_input_name',
    ICALL_NOTIFICATIONS: 'icall_notifications',
    ICHAT_NOTIFICATIONS: 'ichat_notifications',
    COMMUNICATION_OUTCOME: 'communication_outcome',
    COMMUNICATION_LOGS_ENABLED: 'communication_logs_enabled',
    ICALL_TRANSLATIONS: 'icall_translations',
    ICHAT_TRANSLATIONS: 'ichat_translations',
    ICALL_EMAIL_REMINDERS: 'icall_email_reminders',
    ICALENDAR_EMAIL_PAST_REMINDERS: 'icalendar_email_past_reminders',
    PARAMS_CHARACTER: 'params_character',
    ENABLE_PRODUCT_DISCOUNT: 'enable_product_discount',
    MILICAST_CLUSTER_REGION: 'milicast_cluster_region',
    ENABLE_WORD_BAG: 'enable_word_bag',
    INCOMING_CHAT_REQUEST: 'incoming_chat_request',
    ENABLE_TV_CHANNEL_MANAGER: 'enable_tv_channel_manager',
    ENABLE_FORM_MANAGE: 'enable_form_manage',
    IMMERSIVE_APP_SIZE_LIMIT: 'immersive_app_size_limit',
    ENABLE_USER_GROUPS: 'enable_user_groups',
    ENABLE_PRODUCT_UNIT: 'enable_product_unit',
    ENABLE_GENAI: 'enable_genai',
    ENABLE_SOCIAL_VIDEOS: 'enable_social_videos',
    ICALL_SCHEMA: 'icall_schema',
    PRODUCTS_CHECK_ENABLED: 'products_check_enabled',
    ENABLE_HOST_ICALL_EMAIL_REMINDER: 'enable_host_icall_email_reminder',
    REQUIRE_TWO_FACTOR_AUTHENTICATION: 'require_two_factor_authentication',

    //---Hooks settings-------------------------------------
    ENABLE_EVENT_WEBHOOKS: 'enable_event_webhooks',
    OVERWRITE_DEFAULT_WEBHOOKS_ACTIONS: 'overwrite_default_webhooks_actions',
    EVENT_SUBSCRIPTION_WEBHOOK: 'event_subscription_webhook',
    CREATE_VIEWER_WEBHOOK: 'create_viewer_webhook',
    GAME_WINNER_WEBHOOK: 'game_winner_webhook',
    WEBHOOK_TOKEN: 'webhook_token',

    //---Organization settings-------------------------------
    WALLPAPER_IMAGE: 'wallpaper_image',
    AVAILABILITY: 'availability',

    //---Notifications settings-------------------------------
    DIRECT_INCOMING_CALL: 'direct_incoming_call',
    ORGANIZATION_INCOMING_CALL: 'organization_incoming_call',
    CONTENT_CREATION: 'content_creation',
    LOST_OPPORTUNITY_CREATION: 'lost_opportunity_creation',
    CALENDAR_REMINDER: 'calendar_reminder',
    ICALL_QUEUE_INCOMING: 'icall_queue_incoming',
    ICALL_QUEUE_OUTGOING: 'icall_queue_outgoing',

    ENABLE_SYSTEM_TRAY_NOTIFICATION: 'enable_system_tray_notification',
    ENABLE_BROWSER_PUSH_NOTIFICATION: 'enable_browser_push_notification',
    ENABLE_BROWSER_SOUND_NOTIFICATION: 'enable_browser_sound_notification',
    URL_BROWSER_SOUND_NOTIFICATION: 'url_browser_sound_notification',

    //---Calendar settings-------------------------------
    AVAILABILITY_TYPES: 'availability_types',
    BOOKING_SHARE_URL: 'booking_share_url',
    BOOKING_TITLE: 'booking_title',
    SEND_BOOKING_EMAIL_TO_AGENT: 'send_booking_email_to_agent',
    SERVICE_APPOINTMENT_CREATE_TRIAGE_ID: 'service_appointment_create_triage_id',
    APPOINTMENT_STATUS: 'appointment_status',
    APPOINTMENT_ORIGIN: 'appointment_origin',
    APPOINTMENT_RATING_INSTALLATION: 'appointment_rating_installation',
    ENABLE_AGENT_EDIT_AVAILABILITY: 'enable_agent_edit_availability',
    ENABLE_AGENT_EDIT_GROUP_APPOINTMENT: 'enable_agent_edit_group_appointment',

    //----Leads--------------------------------------------
    ENABLE_ADD_LEAD_MANUAL: 'enable_add_lead_manual',
    ENABLE_SEND_LEAD_REMARKETING_EMAIL: 'enable_send_lead_remarketing_email',

    //----GenAI--------------------------------------------
    GENAI_AUTOMATIC_LANGUAGE_IDENTIFICATION: 'genai_automatic_language_identification',
    GENAI_VOCABULARIES_LANGUAGE_IDENTIFICATION: 'genai_vocabularies_language_identification',

    //---Deprecated----------------------------------------
    // ENABLE_IMMERSIVE: 'enable_immersive',
    // HIDE_SECTION_ONE_TO_ONE: 'hide_section_one_to_one',
    // HIDE_SECTION_ONE_TO_MANY: 'hide_section_one_to_many',
    // ENABLE_ICHAT: 'enable_ichat',
    // LANDING_AGENT_ENABLED: 'landing_agent_enabled',
    // ENABLE_ICALL: 'enable_icall',
    // SHOW_SECTION_LEADS: 'show_section_leads',
    // ENABLE_ICALENDAR: 'enable_icalendar',
};

export default SettingsKey;
