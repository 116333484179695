import { withIcon } from './whitIcon';

const IVideoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22.289 26.137">
        <path
            id="theaters_FILL0_wght300_GRAD0_opsz20"
            d="M4.208,29.345V3.208H6.294V6.094H9.98V3.208H20.724V6.094h3.687V3.208H26.5V29.345H24.411V26.459H20.724v2.886H9.98V26.459H6.294v2.886Zm2.086-4.972H9.98V20.365H6.294Zm0-6.092H9.98v-4.01H6.294Zm0-6.094H9.98V8.18H6.294ZM20.724,24.373h3.687V20.365H20.724Zm0-6.092h3.687v-4.01H20.724Zm0-6.094h3.687V8.18H20.724ZM12.066,27.259h6.573V5.294H12.066Zm0-21.965h0Z"
            transform="translate(-4.208 -3.208)"
        />
    </svg>
);

export default withIcon(IVideoIcon);
