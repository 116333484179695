import { makeStyles } from 'tss-react/mui';

const useStylesTerms = makeStyles()((theme) => {
    return {
        root: {
            lineHeight: '1.7em',
            fontSize: '1rem',
            textAlign: 'justify',
        },
        title: {
            margin: 'auto',
            maxWidth: '600px',
            textAlign: 'center',
            fontSize: '2rem',
            lineHeight: 1,
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: '3rem',
        },
    };
});

export default useStylesTerms;
