import CustomButton from 'common/components/CustomButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openCustomDialog } from 'redux/slices/customDialogSlice';
import MyAvailabilityCreate from './myAvailabilityForm/MyAvailabilityCreate';

const MyAvailabilityCreateAction = ({ userData }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleCreateClick = () => {
        dispatch(openCustomDialog(<MyAvailabilityCreate userData={userData} />));
    };

    return (
        <CustomButton onClick={handleCreateClick}>
            <span>{t('availability.createAction.add')}</span>
        </CustomButton>
    );
};

export default MyAvailabilityCreateAction;
