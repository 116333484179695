import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const immersiveSlice = createSlice({
    name: 'immersive',
    initialState: {
        pending: false,
        savePending: false,
        environmentVariables: [],
        error: null,
        usersEnvVariables: {},
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setImmersiveEnvironmentVariables: (state, action) => {
            state.pending = false;
            state.savePending = false;
            state.environmentVariables =
                Object.keys(action.payload).length === 0
                    ? [{ key: '', value: '' }]
                    : Object.keys(action.payload).map((key) => ({ key: key, value: action.payload[key] }));
        },
        setUsersEnvVariables: (state, action) => {
            const { payload } = action;
            state.pending = false;
            state.savePending = false;
            state.usersEnvVariables = {
                ...state.usersEnvVariables,
                [payload.actorId]: payload?.environment,
            };
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        deleteImmersiveEnvironment: (state, action) => {
            state.pending = false;
            state.environmentVariables = [];
        },
        deleteUserImmersiveEnvironment: (state, action) => {
            const { payload } = action;
            state.pending = false;
            state.usersEnvVariables[payload.userId] = {};
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setImmersiveEnvironmentVariables,
    setError,
    deleteImmersiveEnvironment,
    startSave,
    stopSave,
    setSaveError,
    setUsersEnvVariables,
    deleteUserImmersiveEnvironment,
} = immersiveSlice.actions;

export const getImmersiveEnvironmentVariables = (state) => state.immersive.environmentVariables;
export const getUsersImmersiveEnvironmentVariables = (state) => state.immersive.usersEnvVariables;
export const getImmersivePending = (state) => state.immersive.pending;
export const getImmersiveError = (state) => state.immersive.error;
export const getImmersiveSavePending = (state) => state.immersive.savePending;

export const fetchEnvironmentVariablesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery({
                ...(queryParams?.userId ? { userId: queryParams.userId } : {}),
            });
            dispatch(start());
            api.get('immersive-environment' + query)
                .then((response) => {
                    const { environment } = response?.data;
                    dispatch(setImmersiveEnvironmentVariables(environment));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchUserEnvironmentVariablesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch, getState) => {
        if (!queryParams?.userId) {
            onFailure('User Id is required');
            return;
        }

        const state = getState();
        const { usersEnvVariables } = state.immersive;

        if (usersEnvVariables[queryParams.userId]) {
            if (onSuccess) {
                onSuccess(usersEnvVariables[queryParams.userId]);
            }
            return usersEnvVariables[queryParams.userId];
        }

        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('immersive-environment' + query)
                .then((response) => {
                    dispatch(setUsersEnvVariables(response?.data));
                    if (onSuccess) {
                        onSuccess(response?.data?.environment);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            if (onFailure) {
                onFailure(e);
            }
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addEnvironmentVariablesAction =
    ({ environmentDto, success, failure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(`immersive-environment`, environmentDto)
                .then((response) => {
                    const { environment } = response?.data;

                    if (environmentDto.userId) {
                        dispatch(setUsersEnvVariables(response?.data));
                    } else {
                        dispatch(setImmersiveEnvironmentVariables(environment));
                    }

                    if (success) {
                        success(response?.data);
                    }
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setSaveError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteEnvironmentVariablesAction =
    ({ queryParams, success, failure }) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery({
                ...(queryParams?.userId ? { userId: queryParams.userId } : {}),
            });
            dispatch(start());
            api.delete('immersive-environment' + query)
                .then((response) => {
                    if (queryParams?.userId) {
                        dispatch(deleteUserImmersiveEnvironment({ userId: queryParams.userId }));
                    } else {
                        dispatch(dispatch(deleteImmersiveEnvironment()));
                    }

                    if (success) {
                        success();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export default immersiveSlice.reducer;
