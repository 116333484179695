import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled((props) => <Button {...props} className={props.className} />)(({ active, theme }) => {
    return {
        color: '#FFFFFF',
        background: theme.palette.background.red,
        borderRadius: theme.spacing(3),
        height: theme.spacing(4.25),
        fontSize: theme.spacing(1.5),
        width: 'max-content',
        padding: 0,
        '& svg': {
            fontSize: theme.spacing(3),
        },
        '&.selected, &:hover': {
            color: '#FFFFFF',
            background: '#000',
        },
        '&:disabled': {
            color: '#FFFFFF',
            backgroundColor: '#e0e0e0 !important',
        },
        '& span': {
            fontWeight: 'bold',
            margin: theme.spacing(0, 6),
            fontSize: theme.spacing(1.5),
        },
    };
});

export default CustomButton;
