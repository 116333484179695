import { Box, Button, Grid, Typography } from '@mui/material/';
import LoadingButton from 'common/components/LoadingButton';
import HandlebarsCodeInputMonacoEditor from 'common/components/code-editor/HandlebarsCodeInputMonacoEditor';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeCustomDialogAction, getIsDialogOnClose } from 'redux/slices/customDialogSlice';
import { getSettingsEmailPending } from 'redux/slices/settingSlice';
import useStylesSettings from '../styles/useStylesSettings.styles';

const EditEmailTemplate = (props) => {
    const {
        title,
        templateContent,
        emailTemplate,
        lang,
        part,
        handlePreview,
        handleEmailTemplatePartChange,
        getDefaultEmailTemplate,
    } = props;

    const { t } = useTranslation();

    const { classes } = useStylesSettings();
    const dispatch = useDispatch();
    const isDrawerOnClose = useSelector(getIsDialogOnClose);
    const settingsPending = useSelector(getSettingsEmailPending);
    const [content, setContent] = useState(templateContent);

    const handleReset = () => {
        getDefaultEmailTemplate(emailTemplate, lang, part, (data) => {
            handleEmailTemplatePartChange(emailTemplate, lang, part, '');
            setContent(data);
        });
    };

    useEffect(() => {
        if (isDrawerOnClose) {
            dispatch(closeCustomDialogAction());
        }
    }, [isDrawerOnClose, dispatch]);

    // TODO: fix Codemirror, original deps uninstalled
    return (
        <Grid>
            <Box p={1}>
                <Typography variant="subtitle1" className={classes.emailsTemplatesText}>
                    {title}
                </Typography>

                <HandlebarsCodeInputMonacoEditor
                    value={content}
                    height="75vh"
                    width="90vw"
                    onChange={(value, errors) => {
                        setContent(value);
                        handleEmailTemplatePartChange(emailTemplate, lang, part, value);
                    }}
                />
            </Box>
            <Grid container direction="row" p={2} justifyContent="flex-end" alignItems="center">
                <LoadingButton
                    className={classes.mr1}
                    variant="contained"
                    color="primary"
                    loading={settingsPending}
                    disabled={settingsPending}
                    handleButtonClick={handleReset}
                    label={t('profile.settings.emailsSettings.editEmailTemplate.reset')}
                />
                <Button
                    className={classes.mr1}
                    onClick={() => handlePreview(part)}
                    variant="contained"
                    component="span"
                    color="primary"
                    disabled={settingsPending}
                >
                    {t('profile.settings.emailsSettings.editEmailTemplate.preview')}
                </Button>
                <Button
                    className={classes.mr1}
                    onClick={() => dispatch(closeCustomDialogAction())}
                    variant="contained"
                    component="span"
                    color="primary"
                    disabled={settingsPending}
                >
                    {t('profile.settings.emailsSettings.editEmailTemplate.close')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default EditEmailTemplate;
