import { TextField } from '@mui/material';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            height: theme.spacing(3.5),
            background: '#FFFF',
            borderRadius: theme.spacing(4.5),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(4),
            },
            '& > fieldset': {
                borderColor: '#E2E2E2',
            },
            '& > input': {
                padding: theme.spacing(0, 1),
                height: '100%',
                fontSize: theme.spacing(1.5),
                fontWeight: '500',
                position: 'relative',
                '&:focus': {
                    borderRadius: theme.spacing(4.5),
                },
            },
            '& .MuiSvgIcon-root': {
                fontSize: theme.spacing(2),
                [theme.breakpoints.up('xl')]: {
                    height: theme.spacing(2.25),
                },
            },
        },

        '& .MuiFormHelperText-root': {
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(1.75),
            },
        },
    },
}));

const TextFieldForPicker = forwardRef((props, ref) => {
    const { size, ...rest } = props;
    const { classes, cx } = useStyles();

    return <TextField {...rest} ref={ref} className={cx(classes.root, size)} />;
});

export default TextFieldForPicker;
