import { Add, Delete } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import api from 'api';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';

import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import CircularProgressWithLabel from 'common/components/CircularProgressWithLabel';
import CustomTextValidator from 'common/components/CustomTextValidators';
import ImageFileInput from 'common/components/ImageFileInput';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import useStylesLogo from 'common/components/styles/useStylesLogo.styles';
import { useAbortController } from 'common/hooks';
import { handleUploadFileSignedUrl } from 'common/utils/file';
import { useFormFields } from 'libs/hooksLib';
import { getUser, testFeatureEnabled } from 'redux/slices/authSlice';
import { editOrganizationAction, fetchOrganizationAction, getOrganization } from 'redux/slices/organizationSlice';
import { getOrganizationSettingsDirty, setOrganizationSettingsDirty } from 'redux/slices/profileSlice';
import { createOrUpdateSetting, getSettings } from 'redux/slices/settingSlice';
import OrganizationManager from './OrganizationManager';
import OrganizationAvailability from './availability/OrganizationAvailability';
import useStylesProfileOrganization from './styles/useStylesProfileOrganization.styles';

const Organization = () => {
    const dispatch = useDispatch();
    const organization = useSelector(getOrganization);
    const user = useSelector(getUser);
    const organizationSettingsDirty = useSelector(getOrganizationSettingsDirty);

    const { t } = useTranslation();
    const classesOrganization = useStylesLogo();
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2, cx } = useStylesProfileOrganization();
    const classes = { ...classes1, ...classes2 };

    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [uploadLogoProgress, setUploadLogoProgress] = useState(0);
    const [uploadingMainImage, setUploadingMainImage] = useState(false);
    const [uploadMainImageProgress, setUploadMainImageProgress] = useState(0);
    const [uploadingBackground, setUploadingBackground] = useState(false);
    const [uploadBackgroundProgress, setUploadBackgroundProgress] = useState(0);
    const [backgrounds, setBackgrounds] = useState([]);
    const [initForm, setInitForm] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const isTestFeatureEnabled = useSelector(testFeatureEnabled);
    const settings = useSelector(getSettings);
    const getSignal = useAbortController();

    const initialForm = useMemo(() => {
        const form = {
            name: organization?.name ? organization.name : '',
            logo: organization?.logo ? organization.logo : '',
            mainImage: organization?.mainImage ? organization.mainImage : '',
            primaryColor: organization?.primaryColor ? organization.primaryColor : '',
            secondaryColor: organization?.secondaryColor ? organization.secondaryColor : '',
        };
        setInitForm(form);
        return form;
    }, [organization]);

    const [fields, handleFieldChange] = useFormFields(initialForm);

    const organizationFieldChange = (event) => {
        handleFieldChange(event);
    };

    const handleCheckboxChange = (event, idx) => {
        const v = event.target.checked ? idx : '-1';
        toggleChange(SettingsKey.WALLPAPER_IMAGE, v.toString());
    };

    const getSettingValue = useCallback(
        (key) => {
            return settings?.find((s) => s.settingKey === key)?.settingValue;
        },
        [settings],
    );

    const initSettings = useMemo(
        () => ({
            [SettingsKey.WALLPAPER_IMAGE]: {
                settingKey: SettingsKey.WALLPAPER_IMAGE,
                settingValue: getSettingValue(SettingsKey.WALLPAPER_IMAGE) || '-1',
            },
        }),
        [getSettingValue],
    );

    const [settingsState, setSettingsState] = useState(initSettings);

    const toggleChange = (settingKey, settingValue) => {
        setSettingsState({ ...settingsState, [settingKey]: { ...settingsState[settingKey], settingValue } });
    };

    useEffect(() => {
        if (user?.organizationId) {
            dispatch(fetchOrganizationAction());
            fetchBackgroundAssets(user?.organizationId);
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (!isEqual(initForm, fields)) {
            dispatch(setOrganizationSettingsDirty(true));
        } else {
            dispatch(setOrganizationSettingsDirty(false));
        }
    }, [fields, dispatch, initForm]);

    useEffect(() => {
        if (!isEqual(initSettings, settingsState)) {
            dispatch(setOrganizationSettingsDirty(true));
        } else {
            dispatch(setOrganizationSettingsDirty(false));
        }
    }, [settingsState, dispatch, initSettings]);

    function validateForm() {
        return fields.name && fields.logo && fields.mainImage && fields.primaryColor && fields.secondaryColor;
    }

    function handleSubmit(event) {
        event.preventDefault();
        dispatch(editOrganizationAction(fields));
        dispatch(createOrUpdateSetting(settingsState));
        dispatch(setOrganizationSettingsDirty(false));
        enqueueSnackbar(t('profile.organization.saveSuccess'), {
            variant: 'success',
        });
    }

    function handleError() {
        enqueueSnackbar(t('profile.organization.formError'), {
            variant: 'error',
        });
    }

    const uploadImageFile = (file, setProgress, setUploading, callback) => {
        handleUploadFileSignedUrl({
            file,
            size: 1024 * 1024 * 100, // 100MB
            onInit: (size) => {
                setProgress(0);
                setUploading(true);
            },
            onProgress: (loaded, total) => {
                setProgress(Math.min(100, Math.round((loaded / total) * 100)));
            },
            onComplete: (url) => {
                setUploading(false);
                callback(url);
            },
            onError: (error) => {
                setUploading(false);
                if (error.code !== 'ERR_CANCELED') {
                    enqueueSnackbar(t('app.common.uploadError'), {
                        variant: 'error',
                    });
                }
            },
            bucketType: 'resources',
            signal: getSignal(),
        });
    };

    function handleLogoUploadFile(logoFile) {
        if (logoFile.size / 1000 > 500) {
            enqueueSnackbar(t('upload.imageSizeError', { size: '500kb' }), {
                variant: 'error',
            });
        } else {
            uploadImageFile(logoFile, setUploadLogoProgress, setUploadingLogo, (imageUrl) => {
                handleFieldChange({
                    target: {
                        name: 'logo',
                        value: imageUrl,
                    },
                });
            });
        }
    }

    function handleMainImageUploadFile(mainImageFile) {
        if (mainImageFile.size / 1000 > 500) {
            enqueueSnackbar(t('upload.imageSizeError', { size: '500kb' }), {
                variant: 'error',
            });
        } else {
            uploadImageFile(mainImageFile, setUploadMainImageProgress, setUploadingMainImage, (imageUrl) => {
                handleFieldChange({
                    target: {
                        name: 'mainImage',
                        value: imageUrl,
                    },
                });
            });
        }
    }

    const renderButtonAddLogo = ({ disabled }) => {
        return (
            <Button
                component="span"
                className={cx(classes.uploadImageButton, classes.customUpload)}
                disabled={disabled}
            >
                {uploadingLogo && <CircularProgressWithLabel value={uploadLogoProgress} />}
                {!uploadingLogo && (
                    <>
                        <Add />
                        <span>{t('profile.organization.upload')}</span>
                    </>
                )}
            </Button>
        );
    };

    const renderInputColor = ({ disabled, name, label, placeholder }) => (
        <FormGroup className={cx(classes.formGroup, classes.w22)}>
            <FormLabel>{label}</FormLabel>
            <CustomTextValidator
                variant="outlined"
                size="small"
                name={name}
                value={fields[name]}
                onChange={organizationFieldChange}
                placeholder={placeholder}
                fullWidth={true}
                validators={['required', 'matchRegexp:^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$']}
                errorMessages={[t('profile.organization.colorRequired'), t('profile.organization.colorInvalid')]}
                disabled={disabled}
            />
        </FormGroup>
    );

    const renderButtonMainImage = ({ disabled }) => (
        <Button component="span" className={cx(classes.uploadImageButton, classes.customUpload)} disabled={disabled}>
            {uploadingMainImage && <CircularProgressWithLabel value={uploadMainImageProgress} />}
            {!uploadingMainImage && (
                <>
                    <Add />
                    <span>{t('profile.organization.upload')}</span>
                </>
            )}
        </Button>
    );

    const renderButtonBackgroundImage = () => (
        <Button component="span" className={cx(classes.uploadImageButton, classes.customUpload)}>
            {uploadingBackground && <CircularProgressWithLabel value={uploadBackgroundProgress} />}
            {!uploadingBackground && (
                <>
                    <Add />
                    <span>{t('profile.organization.upload')}</span>
                </>
            )}
        </Button>
    );

    function fetchBackgroundAssets(organizationId) {
        api.get('effects/background/organization/' + organizationId, {})
            .then((response) => {
                if (response.error) {
                    throw response.error;
                } else {
                    setBackgrounds(response.data);
                }
            })
            .catch((error) => {
                setUploadingBackground(false);
                console.debug(error);
            });
    }

    function handleUploadEffect(file) {
        handleUploadFileSignedUrl({
            file,
            size: 1024 * 1024 * 100, // 100MB
            onInit: (size) => {
                setUploadingBackground(true);
                setUploadBackgroundProgress(0);
            },
            onProgress: (loaded, total) => {
                setUploadBackgroundProgress(Math.min(100, Math.round((loaded / total) * 100)));
            },
            onComplete: (url) => {
                const data = {
                    name: file.name,
                    format: file.type,
                    effectType: 'background',
                    source: url,
                };
                api.post('effects/create', data)
                    .then((response) => {
                        setUploadingBackground(false);
                        if (response.error) {
                            throw response.error;
                        } else {
                            let newBackgrounds = [...backgrounds, response.data];
                            setBackgrounds(newBackgrounds);
                        }
                    })
                    .catch((error) => {
                        setUploadingBackground(false);
                        console.debug(error);
                    });
            },
            onError: (error) => {
                setUploadingBackground(false);
                if (error.code !== 'ERR_CANCELED') {
                    enqueueSnackbar(t('app.common.uploadError'), {
                        variant: 'error',
                    });
                }
            },
            bucketType: 'resources',
            signal: getSignal(),
        });
    }

    const onDeleteBackground = (e, id, idx) => {
        e.preventDefault();
        api.delete(`effects/${id}`, {})
            .then((response) => {
                if (response.error) {
                    throw response.error;
                } else {
                    let newBackgrounds = [...backgrounds];
                    if (newBackgrounds.length > 0) {
                        newBackgrounds.splice(idx, 1);
                    }

                    if (idx.toString() <= settingsState[SettingsKey.WALLPAPER_IMAGE]?.settingValue) {
                        let value = -1;
                        if (idx.toString() < settingsState[SettingsKey.WALLPAPER_IMAGE]?.settingValue) {
                            value = parseInt(settingsState[SettingsKey.WALLPAPER_IMAGE]?.settingValue) - 1;
                        }
                        toggleChange(SettingsKey.WALLPAPER_IMAGE, value.toString());
                    }
                    setBackgrounds(newBackgrounds);
                }
            })
            .catch((error) => {
                console.debug(error);
            });
    };

    const renderButtonSave = ({ disabled, ...rest }) => (
        <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            type="submit"
            className={cx(classes.formButton, classes.buttonSave)}
        >
            {t('common.term.saveChanges')}
        </Button>
    );

    return (
        <div className={classes.root}>
            <div className={cx(classes.container, classes.scroll)}>
                <div className={cx(classes.body, classes.mW80)}>
                    <Accordion disableGutters className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('common.term.generalSettings')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.eventSection}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ width: '100%' }}
                                className={classes.boxGeneralSettings}
                            >
                                <div className={classes.containerForm}>
                                    {organization?.id && (
                                        <ValidatorForm
                                            onSubmit={handleSubmit}
                                            className={classes.form}
                                            onError={handleError}
                                        >
                                            <FormGroup className={classes.formGroup}>
                                                <FormLabel>{t('profile.organization.organizationName')}</FormLabel>
                                                <CustomTextValidator
                                                    name="name"
                                                    value={fields.name}
                                                    onChange={organizationFieldChange}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth={true}
                                                    validators={['required']}
                                                    errorMessages={[t('profile.organization.colorRequired')]}
                                                />
                                            </FormGroup>
                                            {isTestFeatureEnabled && (
                                                <FormGroup className={cx(classes.formGroup, classes.rowCenter)}>
                                                    <FormLabel>Organization Id</FormLabel>
                                                    <CopyToClipboard text={organization.id}>
                                                        <IconButton className={classes.iconCopyClipboard}>
                                                            <ContentCopyIcon fontSize={'small'} />
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                </FormGroup>
                                            )}

                                            <FormGroup className={classes.formGroup}>
                                                <div className={classes.uploadContent}>
                                                    <FormLabel>{t('profile.organization.logo')}</FormLabel>

                                                    <label htmlFor="raised-logo-button-file">
                                                        {renderButtonAddLogo({ disabled: false })}
                                                    </label>

                                                    <ImageFileInput
                                                        inputId={'raised-logo-button-file'}
                                                        uploadAssetFn={handleLogoUploadFile}
                                                        disabled={uploadingLogo}
                                                    />
                                                </div>
                                                <div
                                                    className={cx(
                                                        classes.logoContainer,
                                                        classesOrganization.logoBackground,
                                                    )}
                                                >
                                                    {fields.logo && (
                                                        <img className={classes.logo} src={fields.logo} alt="logo" />
                                                    )}
                                                </div>
                                                <span className={classes.imagesRecommendation}>
                                                    {t('profile.organization.imageRecommended')}
                                                </span>
                                            </FormGroup>
                                            <FormGroup className={classes.formGroup}>
                                                <div className={classes.uploadContent}>
                                                    <FormLabel>{t('profile.organization.coverImage')}</FormLabel>

                                                    <label htmlFor="raised-button-main-image-file">
                                                        {renderButtonMainImage({ disabled: false })}
                                                    </label>
                                                    <ImageFileInput
                                                        inputId={'raised-button-main-image-file'}
                                                        uploadAssetFn={handleMainImageUploadFile}
                                                        disabled={uploadingMainImage}
                                                    />
                                                </div>
                                                <span className={classes.imagesRecommendation}>
                                                    {t('profile.organization.coverImageRecommended')}
                                                </span>
                                            </FormGroup>

                                            <div className={cx(classes.dFlex, classes.mb1)}>
                                                {renderInputColor({
                                                    disabled: false,
                                                    name: 'primaryColor',
                                                    label: t('profile.organization.color'),
                                                    placeholder: `${t('profile.organization.color')} ...`,
                                                })}

                                                <div className={cx(classes.w6)}></div>

                                                {renderInputColor({
                                                    disabled: false,
                                                    name: 'secondaryColor',
                                                    label: t('profile.organization.secondaryColor'),
                                                    placeholder: `${t('profile.organization.secondaryColor')} ...`,
                                                })}
                                            </div>

                                            {organizationSettingsDirty &&
                                                renderButtonSave({ disabled: !validateForm() })}
                                        </ValidatorForm>
                                    )}

                                    {organization && fields.mainImage && (
                                        <img
                                            src={fields.mainImage}
                                            className={classes.mainImage}
                                            alt="main_organization"
                                        />
                                    )}
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('profile.organization.tabs.effects')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.eventSection}>
                            <Box style={{ width: '100%' }}>
                                <div className={classes.effectHeader}>
                                    <span>{t('profile.organization.backgroundImages')}</span>
                                    {backgrounds.length < 4 && (
                                        <div className={classes.ml1}>
                                            <ImageFileInput
                                                inputId={'raised-button-backgroud-file'}
                                                uploadAssetFn={handleUploadEffect}
                                                disabled={uploadingBackground}
                                            />
                                            <label htmlFor="raised-button-backgroud-file">
                                                {renderButtonBackgroundImage()}
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <Grid container spacing={1}>
                                    {backgrounds.map((asset, idx) => (
                                        <Grid key={asset.id} item lg={4} sm={6}>
                                            <div className={classes.selectAssetItem}>
                                                <div className={classes.actionAsset}>
                                                    <IconButton onClick={(e) => onDeleteBackground(e, asset.id, idx)}>
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                                <div className={classes.selectAssetItemImage}>
                                                    <img src={asset.source} alt={asset.name}></img>
                                                </div>
                                                <div className={classes.selectAssetItemName}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    settingsState[SettingsKey.WALLPAPER_IMAGE]
                                                                        ?.settingValue === idx.toString()
                                                                }
                                                                onChange={(e) => handleCheckboxChange(e, idx)}
                                                                size="small"
                                                                name={`checkbox-${idx}`}
                                                            />
                                                        }
                                                        label={t('common.term.setDefault')}
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>

                                {organizationSettingsDirty && (
                                    <ValidatorForm
                                        onSubmit={handleSubmit}
                                        className={classes.form}
                                        onError={handleError}
                                    >
                                        {renderButtonSave({ disabled: false })}
                                    </ValidatorForm>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('profile.organization.tabs.availability')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.eventSection}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ width: '100%' }}
                            >
                                <OrganizationAvailability />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('profile.organization.tabs.manager')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.eventSection}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ width: '100%' }}
                            >
                                <OrganizationManager />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default Organization;
