import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import api, { BffApi } from 'api';

import UserRole from '../../common/enums/roles';
import { IS_DEVELOP } from '../../common/utils/utils';
import { setOrganization } from './organizationSlice';

const setSentryUser = (user) => {
    if (!IS_DEVELOP) {
        const scope = Sentry.getCurrentScope();
        // scope.setExtra('battery', 0.7);
        // scope.setTag('user_mode', 'admin');
        scope.setUser({
            id: user.id || user.userId,
            organizationId: user.organizationId,
            mainOrganizationId: user.mainOrganizationId,
            role: user.role,
            email: user.email,
        });
        // scope.clear();
    }
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: localStorage?.getItem('user') ? JSON.parse(localStorage?.getItem('user')) : null,
        isAuthenticated: false,
        tokenCsrf: '',
        isAuthenticating: false,
        error: null,
        googleLogin: localStorage?.getItem('googleLoading')
            ? JSON.parse(localStorage?.getItem('googleLoading'))
            : false,
        permissions: [],
        mainLicense: null,
        license: null,
        mainAddOns: [],
        addOns: [],
        sessionChecked: false,
    },
    reducers: {
        start: (state, action) => {
            state.isAuthenticating = true;
        },
        stop: (state, action) => {
            state.isAuthenticating = false;
        },
        setGoogleLogin: (state, action) => {
            localStorage.setItem('googleLoading', JSON.stringify(action.payload));
            state.googleLogin = action.payload;
        },
        setUser: (state, action) => {
            const { id, userId, ...userDto } = action.payload;
            const newUser = {
                ...userDto,
                userId: userId || id,
                name: `${userDto.firstName} ${userDto.lastName}`,
                frontPage: userDto?.account?.frontPage,
            };
            setSentryUser(newUser);
            localStorage.setItem('user', JSON.stringify(newUser));
            state.user = newUser;
        },
        login: (state, action) => {
            const user = action.payload.user;
            const newUser = {
                userId: user.id,
                email: user.email,
                avatar: user.avatar,
                role: user.role,
                name: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                lastName: user.lastName,
                mainOrganizationId: user.mainOrganizationId,
                organizationId: user.organizationId,
                calendly: user.account?.calendlySchedulingUrl,
                username: user.account?.username,
                about: user.account?.about,
                headline: user.account?.headline,
                frontPage: user.account?.frontPage,
                userGroupId: user.userGroupId,
                isOnliveUser: user.email?.endsWith('@onlive.site') && user?.email !== 'test@onlive.site',
            };
            state.user = newUser;
            setSentryUser(newUser);
            if (action.payload.tokenCsrf) {
                state.tokenCsrf = action.payload.tokenCsrf;
            }
            const { email, username, ...rest } = newUser;
            localStorage.setItem('user', JSON.stringify(rest));
            state.isAuthenticated = true;
            state.isAuthenticating = false;
        },
        logout: (state, action) => {
            localStorage.removeItem('user');
            state.user = null;
            state.tokenCsrf = '';
            state.isAuthenticated = false;
            state.isAuthenticating = false;
        },
        setError: (state, action) => {
            state.isAuthenticating = false;
            state.error = action.payload;
        },
        setAuthenticationType: (state, action) => {
            state.user = { ...state.user, authenticationType: action.payload };
        },
        setLicenseWithAddOns: (state, action) => {
            state.license = action.payload;
        },
        setMainLicenseWithAddOns: (state, action) => {
            state.mainLicense = action.payload;
        },
        setActiveAddOns: (state, action) => {
            state.addOns = action.payload;
        },
        setMainActiveAddOns: (state, action) => {
            state.mainAddOns = action.payload;
        },
        setTokenCsrf: (state, action) => {
            state.tokenCsrf = action.payload;
        },
        setSessionChecked: (state, action) => {
            state.sessionChecked = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setGoogleLogin,
    setUser,
    login,
    logout,
    setError,
    setAuthenticationType,
    setLicenseWithAddOns,
    setMainLicenseWithAddOns,
    setActiveAddOns,
    setMainActiveAddOns,
    setTokenCsrf,
    setSessionChecked,
} = authSlice.actions;

export const getUser = (state) => state.auth.user;
export const userHasAuthenticated = (state) => state.auth.isAuthenticated;
export const userIsAuthenticating = (state) => state.auth.isAuthenticating;
export const isGoogleLogin = (state) => state.auth.googleLogin;
export const getPermissions = (state) => state.auth.permissions;
export const getAuthLicense = (state) => state.auth.license;
export const getAuthAddOns = (state) => state.auth.addOns;
export const getAuthMainLicense = (state) => state.auth.mainLicense;
export const getAuthMainAddOns = (state) => state.auth.mainAddOns;
export const testFeatureEnabled = (state) =>
    state.auth.user?.email?.endsWith('@onlive.site') && state.auth.user?.email !== 'test@onlive.site';
export const getUserCanEdit = (state) =>
    state?.auth?.user?.role === UserRole.ADMIN ||
    state?.auth?.user?.role === UserRole.EDITOR ||
    state?.auth?.user?.role === UserRole.ROOT;
export const getSessionChecked = (state) => state.auth.sessionChecked;
export const getTokenCsrf = (state) => state.auth.tokenCsrf;

export const refreshToken = (tokenCsrf, dispatch) => {
    return BffApi.post(
        '/oauth/refresh-token',
        {},
        {
            headers: {
                'x-onlive-sessions-csrf': tokenCsrf,
            },
        },
    ).then(() => userInfo(dispatch));
};

const userInfo = (dispatch) => {
    return api.get('auth/user-info').then(({ data: userData }) => {
        dispatch(login({ user: userData.user }));
        dispatch(setOrganization(userData.organization));
        return userData;
    });
};

export const impersonateAction = (data, callback) => async (dispatch) => {
    try {
        dispatch(start());
        api.put('auth/impersonate', data)
            .then((response) => refreshToken(data.tokenCsrf, dispatch))
            .then((data) => {
                dispatch(stop());
                if (callback) {
                    callback(null, data);
                }
                return data;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (callback) {
                    callback(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const logoutAction =
    ({ success, fail, tokenCsrf } = {}) =>
    async (dispatch) => {
        try {
            (tokenCsrf
                ? BffApi.post(
                      'oauth/logout',
                      {},
                      {
                          headers: {
                              'x-onlive-sessions-csrf': tokenCsrf,
                          },
                      },
                  )
                : Promise.resolve({ data: null })
            )
                .then(({ data }) => {
                    dispatch(logout());
                    if (success) {
                        success();
                    }
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (fail) {
                        fail(error);
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export const editUserAction =
    ({ user } = {}) =>
    async (dispatch) => {
        try {
            dispatch(setUser(user));
            api.put('users/' + user.userId, user)
                .then((response) => {
                    if (response.error) {
                        dispatch(setError(response.error));
                    } else {
                        dispatch(setUser(user));
                    }
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export const updateCloudStoreUserAction = (userId, data, success) => async (dispatch) => {
    try {
        dispatch(start());
        api.put('cloudstore/users/' + userId, data)
            .then((response) => {
                dispatch(stop());
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const refreshUserAction =
    ({ id } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get('users/' + id)
                .then((response) => {
                    dispatch(setUser(response?.data));
                    dispatch(stop());
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const setUserAction =
    ({ user, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.put('users/' + user.userId, user)
                .then((response) => {
                    if (response.error) {
                        dispatch(setError(response.error));
                        onFailure?.(response.error);
                    } else {
                        dispatch(setUser(response?.data));
                        onSuccess?.(response?.data);
                    }
                })
                .catch((error) => {
                    dispatch(setError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const updateUserAction = (userId, data, success) => async (dispatch) => {
    try {
        dispatch(start());
        api.put('users/' + userId, data)
            .then((response) => {
                dispatch(stop());
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const loginCheck = (url, callback) => async (dispatch) => {
    try {
        dispatch(start());
        const roomId = window.location.hash.split('/')[2];
        roomId && localStorage.setItem('onlive-roomId-loaded', roomId);
        BffApi.post('oauth/login', { url })
            .then(({ data }) => {
                dispatch(setSessionChecked(true));
                if (data.isLoggedIn) {
                    dispatch(setTokenCsrf(data.tokenCsrf));
                    return userInfo(dispatch).then((userData) => {
                        if (callback) {
                            callback(null, userData);
                        }
                        return true;
                    });
                } else {
                    dispatch(logout());
                    if (callback) {
                        callback(null, null);
                    }
                    return false;
                }
            })
            .catch((error) => {
                dispatch(setError(error));
                if (callback) {
                    callback(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export default authSlice.reducer;
