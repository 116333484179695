import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputAdornment } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTextField from 'common/components/CustomTextField';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    searchTextFullWidth: {
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(3),
            paddingRight: theme.spacing(0.25),
        },
        '&.small .MuiOutlinedInput-root': {
            height: theme.spacing(3.5),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(4),
            },
        },
    },

    searchTextField: {
        '& input': {
            padding: '0 !important',
        },
        '& .MuiInputAdornment-root': {
            margin: 0,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(3),
            paddingRight: 0,
            paddingLeft: 0,
        },
    },

    searchBox: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        cursor: 'pointer',
        transition: 'all 0.5s',
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        '&:hover': {
            width: theme.spacing(25),
            [theme.breakpoints.up('xl')]: {
                width: theme.spacing(30),
            },
            '& .MuiOutlinedInput-root': {
                transition: 'all 0.5s',
                paddingLeft: `${theme.spacing(1)} !important`,
            },
        },
    },

    searchBoxWithValue: {
        width: theme.spacing(25),
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(30),
        },
        '& .MuiOutlinedInput-root': {
            paddingLeft: theme.spacing(1),
        },
    },
}));

const SearchInput = (props) => {
    const {
        onClick,
        instantFirstSearch,
        onAddNew,
        fullWidth,
        onChange,
        placeholder,
        size = 'medium',
        searchMinLength = 0,
        searchIcon = true,
        ...rest
    } = props;

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const { t } = useTranslation();
    const [firstSearch, setFirstSearch] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';
    const [value, setValue] = useState(search);

    const onSubmit = useCallback(
        (newValue) => {
            if (onChange) {
                onChange(newValue);
            } else {
                let updatedSearchParams = new URLSearchParams(searchParams.toString());
                updatedSearchParams.delete('page');
                if (newValue) {
                    updatedSearchParams.set('search', newValue);
                    setSearchParams(updatedSearchParams.toString());
                } else {
                    updatedSearchParams.delete('search');
                }
                setSearchParams(updatedSearchParams.toString());
            }
        },
        [searchParams, setSearchParams, onChange],
    );

    useEffect(() => {
        if ((!value || value.length >= searchMinLength) && firstSearch) {
            if (instantFirstSearch) {
                onSubmit(value);
                setFirstSearch(false);
                return;
            }
            const delayDebounceFn = setTimeout(() => {
                onSubmit(value);
                setFirstSearch(false);
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [value, firstSearch, searchMinLength, instantFirstSearch, onSubmit, anchorEl]);

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSubmit(value, e.currentTarget);
    };

    const onInput = (e) => {
        setValue(e.target.value);
        setFirstSearch(true);
        setAnchorEl(e.currentTarget);
    };

    return (
        <form
            aria-hidden={true}
            className={cx(
                fullWidth ? flexBox.w100 : cx(classes.searchBoxWithValue, flexBox.flex, flexBox.justifyContentEnd),
            )}
            onClick={onClick}
            onSubmit={onFormSubmit}
        >
            <CustomTextField
                placeholder={placeholder || t('event.event.search')}
                className={cx(
                    fullWidth
                        ? cx(flexBox.w100, classes.searchTextFullWidth)
                        : cx(classes.searchBox, classes.searchTextField, value ? classes.searchBoxWithValue : ''),
                    size,
                )}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchIcon && (
                                <CustomIconButton
                                    aria-label="search"
                                    size="small"
                                    hideBorder={true}
                                    classes={{ disabled: classes.disabledIconButton }}
                                >
                                    <Search />
                                </CustomIconButton>
                            )}
                            {onAddNew ? (
                                <CustomTooltip
                                    arrow
                                    title={t('web.waiting.assets.stickerEditor.create')}
                                    placement={'bottom'}
                                >
                                    <IconButton type={'submit'} size="large" onClick={onAddNew}>
                                        <AddIcon />
                                    </IconButton>
                                </CustomTooltip>
                            ) : undefined}
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
                size={size}
                value={value}
                onInput={onInput}
                {...rest}
            />
        </form>
    );
};

export default SearchInput;
