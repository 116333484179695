import { makeStyles } from 'tss-react/mui';

const useStylesItems = makeStyles()((theme) => {
    return {
        root: {
            '&::marker': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
});

export default useStylesItems;
