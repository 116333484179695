import { CircularProgress } from '@mui/material';
import styles from 'views/styles/Login.module.css';
import useStyles from '../styles/useStylesChartLoading.styles';

const ChartLoading = ({ className }) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.root, className)}>
            <div className={styles.loadingContainer}>
                <CircularProgress />
            </div>
        </div>
    );
};

export default ChartLoading;
