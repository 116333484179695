import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button, Grid } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import YesNoDialog from 'common/components/YesNoDialog';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import TextActionJoinForm from '../TextActionJoinForm/TextActionJoinForm';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import useStylesViewerExtraFields from '../styles/useStylesViewerExtraFields.styles';
import FieldForm from './FieldForm';
import FieldsRow from './FieldsRow';

const onliveFields = [
    {
        type: 'input',
        name: 'name',
        label: [
            {
                value: 'Name',
                languageCode: 'en',
            },
            {
                value: 'Nombre',
                languageCode: 'es',
            },
            {
                value: 'Nom',
                languageCode: 'fr',
            },
            {
                value: 'Nome',
                languageCode: 'it',
            },
            {
                value: 'Nazwa',
                languageCode: 'pl',
            },
            {
                value: 'Nome',
                languageCode: 'pt',
            },
            {
                value: 'Nome',
                languageCode: 'pt-br',
            },
            {
                value: 'Name',
                languageCode: 'de',
            },
            {
                value: `Ім'я`,
                languageCode: 'uk',
            },
            {
                value: 'Nume',
                languageCode: 'ro',
            },
            {
                value: 'Naam',
                languageCode: 'nl-be',
            },
            {
                value: 'Naziv',
                languageCode: 'sr',
            },
            {
                value: 'Naam',
                languageCode: 'nl',
            },
            {
                value: 'Namn',
                languageCode: 'sv',
            },
        ],
        readOnly: true,
        required: true,
        active: true,
    },
    {
        type: 'email',
        name: 'email',
        label: [
            {
                value: 'E-mail',
                languageCode: 'en',
            },
            {
                value: 'Correo',
                languageCode: 'es',
            },
            {
                value: 'E-mail',
                languageCode: 'fr',
            },
            {
                value: 'E-mail',
                languageCode: 'it',
            },
            {
                value: 'E-mail',
                languageCode: 'pl',
            },
            {
                value: 'O email',
                languageCode: 'pt',
            },
            {
                value: 'O email',
                languageCode: 'pt-br',
            },
            {
                value: 'Email',
                languageCode: 'de',
            },
            {
                value: 'Електронна пошта',
                languageCode: 'uk',
            },
            {
                value: 'E-mail',
                languageCode: 'ro',
            },
            {
                value: 'E-mailen',
                languageCode: 'nl-be',
            },
            {
                value: 'Adresa e-pošte',
                languageCode: 'sr',
            },
            {
                value: 'E-mailen',
                languageCode: 'nl',
            },
            {
                value: 'E-post',
                languageCode: 'sv',
            },
        ],
        readOnly: true,
        required: true,
        active: true,
    },
    {
        type: 'checkbox',
        name: 'onlivePolicy',
        label: [
            {
                value: 'Onlive Policy',
                languageCode: 'en',
            },
            {
                value: 'Políticas de Onlive',
                languageCode: 'es',
            },
            {
                value: 'Politiques de Onlive',
                languageCode: 'fr',
            },
            {
                value: 'Politiche di Onlive',
                languageCode: 'it',
            },
            {
                value: 'Zasady Onlive',
                languageCode: 'pl',
            },
            {
                value: 'Políticas de Onlive',
                languageCode: 'pt',
            },
            {
                value: 'Políticas de Onlive',
                languageCode: 'pt-br',
            },
            {
                value: 'Richtlinien von Onlive',
                languageCode: 'de',
            },
            {
                value: 'Політика onlive',
                languageCode: 'uk',
            },
            {
                value: 'Politica onlive',
                languageCode: 'ro',
            },
            {
                value: 'Onlive politiek',
                languageCode: 'nl-be',
            },
            {
                value: 'Onlive politike',
                languageCode: 'sr',
            },
            {
                value: 'Onlive politiek',
                languageCode: 'nl',
            },
            {
                value: 'Onlive policyer',
                languageCode: 'sv',
            },
        ],
        readOnly: true,
        required: true,
        active: true,
        checked: false,
    },
];

const ViewerExtraField = (props) => {
    const { viewerExtraField, toggleChange, handleSubmit, hasChanges, textActionSubmitJoinForm } = props;
    const { t } = useTranslation();
    const { classes: classes1 } = useStylesSettings();
    const { classes: classes2, cx } = useStylesViewerExtraFields();
    const { classes: classes3 } = useStylesFieldForm();
    const { enqueueSnackbar } = useSnackbar();
    const classes = { ...classes1, ...classes2, ...classes3 };

    const settingsDirty = useSelector(getSettingsDirty);

    const [fields, setFields] = useState([]);

    const [showForm, setShowForm] = useState(false);

    const [editingFieldIndex, setEditingFieldIndex] = useState(-1);

    const [openConfirmDeleteDlg, setOpenConfirmDeleteDlg] = useState(false);

    const [deleteIdx, setDeleteIdx] = useState(-1);

    const deleteConfirmTitle = t('profile.settings.viewerExtraField.viewerExtraFields.deleteConfirm');
    const deleteConfirmMessage = t('profile.settings.viewerExtraField.viewerExtraFields.deleteMessage');

    const fieldCreate = {
        type: '',
        name: '',
        label: [],
        url: '',
        required: false,
        active: true,
        readOnly: false,
        disabled: false,
        checked: false,
    };

    useEffect(() => {
        if (fields.length > 0) {
            if (!_.isEqual(fields, viewerExtraField)) {
                toggleChange(SettingsKey.VIEWER_EXTRA_FIELDS, fields);
            }
        }
    }, [fields, toggleChange, viewerExtraField]);

    const handleSetField = useCallback((arr) => {
        setFields((fields) => {
            const idxDataCapture = fields.findIndex(
                (f) => (f.type === 'email' || f.type === 'phone' || f.name === 'email') && f.active,
            );
            const onlivePolicyIdx = arr.findIndex((f) => f.name === 'onlivePolicy');
            const otherPolicyIdx = arr.findIndex((f) => f.type === 'privacy-policy' && f.active);
            if (idxDataCapture !== -1) {
                if (otherPolicyIdx === -1) {
                    arr[onlivePolicyIdx] = {
                        ...arr[onlivePolicyIdx],
                        active: true,
                        readOnly: true,
                        required: true,
                        disabledCheck: true,
                    };
                } else {
                    arr[onlivePolicyIdx] = {
                        ...arr[onlivePolicyIdx],
                        disabledCheck: false,
                    };
                }
            } else {
                arr[onlivePolicyIdx] = {
                    ...arr[onlivePolicyIdx],
                    disabledCheck: false,
                };
            }
            return arr;
        });
    }, []);

    const getExtraFields = useCallback(() => {
        let arr = [];
        let index = viewerExtraField.findIndex((f) => f.name === 'name');
        if (index !== -1) {
            arr = [...viewerExtraField];
        } else {
            arr = [...onliveFields, ...viewerExtraField];
        }
        handleSetField(arr);
    }, [handleSetField, viewerExtraField]);

    useEffect(() => {
        getExtraFields();
    }, [getExtraFields]);

    const confirmDelete = () => {
        deleteField(deleteIdx);
        setOpenConfirmDeleteDlg(false);
    };

    const deleteField = (idx) => {
        let newArr = [...fields];
        newArr.splice(idx, 1);
        handleSetField(newArr);
    };

    const cancelDlg = () => {
        setOpenConfirmDeleteDlg(false);
    };

    const setEditedField = (field, idx) => {
        let newArr = [...fields];
        newArr[idx] = field;
        handleSetField(newArr);
    };

    const handleDelete = (idx) => {
        setDeleteIdx(idx);
        setOpenConfirmDeleteDlg(true);
    };

    const setNewField = (field) => {
        let newArr = [...fields];
        handleSetField([...newArr, field]);
    };

    const addField = (e) => {
        e.preventDefault();
        setShowForm(true);
    };

    const handleSubmitForm = (e) => {
        const idxDataCapture = fields.findIndex(
            (f) => (f.type === 'email' || f.type === 'phone' || f.name === 'email') && f.active,
        );
        if (idxDataCapture !== -1) {
            const idx = fields.findIndex(
                (f) => (f.type === 'privacy-policy' || f.name === 'onlivePolicy') && f.active && f.required,
            );
            if (idx !== -1) {
                handleSubmit(e);
            } else {
                enqueueSnackbar(t('settings.viewerExtraField.policyError'), {
                    variant: 'error',
                });
            }
        } else {
            handleSubmit(e);
        }
    };

    return (
        <React.Fragment>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('profile.settings.viewerExtraField.viewerExtraFields.joinForm')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.eventSection}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <Typography className={classes.text}>
                            {t('profile.settings.viewerExtraField.viewerExtraFields.configureJoinForm')}
                        </Typography>
                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmitForm}
                            >
                                {t('profile.settings.viewerExtraField.viewerExtraFields.save')}
                            </Button>
                        </Box>
                    </Box>
                    <div>
                        <div>
                            <Button
                                className={cx(classes.buttonStyle, classes.addOption, classes.mr0px)}
                                variant="contained"
                                size="small"
                                disableElevation={true}
                                onClick={addField}
                            >
                                {t('profile.settings.viewerExtraField.viewerExtraFields.addField')}
                            </Button>
                        </div>

                        {showForm && (
                            <div className={cx(classes.variantContent, classes.mb20px)}>
                                <div className={classes.editVariant}>
                                    <div className={classes.p10px}>
                                        <Grid container spacing={1} className={classes.optionsRow}>
                                            <Grid item md={6} className={cx(classes.variantOption)}>
                                                {t('profile.settings.viewerExtraField.viewerExtraFields.newField')}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className={classes.p10px}>
                                    <FieldForm
                                        field={fieldCreate}
                                        fieldsExtra={fields}
                                        setFields={(e) => {
                                            setNewField(e);
                                            setShowForm(false);
                                        }}
                                        close={() => {
                                            setEditingFieldIndex(-1);
                                            setShowForm(false);
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {fields.length > 0 && (
                            <Fragment>
                                <div className={cx(classes.actorItem, classes.mlr10px)}>
                                    <Grid container spacing={1} className={classes.optionsRow}>
                                        <Grid item md={1}></Grid>
                                        <Grid item md={3}>
                                            <Typography>
                                                {t('profile.settings.viewerExtraField.viewerExtraFields.name')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography>
                                                {t('profile.settings.viewerExtraField.viewerExtraFields.type')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography>
                                                {t('profile.settings.viewerExtraField.viewerExtraFields.required')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>

                                {fields.map((field, idx) => (
                                    <div key={idx} className={classes.pb10px}>
                                        <FieldsRow
                                            index={idx}
                                            editingFieldIndex={editingFieldIndex}
                                            field={field}
                                            fields={fields}
                                            setFieldsEdited={(e) => setEditedField(e, idx)}
                                            setFields={(e) => handleSetField(e)}
                                            deleteField={() => handleDelete(idx)}
                                            openForm={(i) => {
                                                setEditingFieldIndex(i);
                                                setShowForm(false);
                                            }}
                                        />
                                    </div>
                                ))}
                            </Fragment>
                        )}
                    </div>

                    <div className={classes.pb10px}>
                        <TextActionJoinForm
                            textActionSubmitJoinForm={textActionSubmitJoinForm}
                            toggleChange={toggleChange}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>

            <YesNoDialog
                openDialog={openConfirmDeleteDlg}
                title={deleteConfirmTitle}
                messageToConfirm={deleteConfirmMessage}
                yesButtonCallback={() => {
                    confirmDelete();
                }}
                noButtonCallback={() => {
                    cancelDlg();
                }}
            />
        </React.Fragment>
    );
};

export default ViewerExtraField;
