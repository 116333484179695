import { makeStyles } from 'tss-react/mui';

const useStylesItemSettings = makeStyles()((theme) => {
    return {
        paddingBottom20: {
            paddingBottom: 20,
        },
        text: {
            fontSize: '12px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
            },
        },
    };
});

export default useStylesItemSettings;
