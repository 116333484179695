import { isObject } from "lodash";

export const transformFiltersToQuery = (filters, preffix = '') => {
    let query = '';
    if (filters) {
        query = Object.keys(filters)
            .filter((key) => filters[key] || filters[key] === 0)
            .map((key) => {
                if (isObject(filters[key])) {
                    return transformFiltersToQuery(filters[key], preffix ? `${preffix}[${key}]` : key);
                }
                return `${encodeURIComponent(preffix ? `${preffix}[${key}]` : key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');
        if (query && !preffix) {
            query = `?${query}`;
        }
    }
    return query;
};
