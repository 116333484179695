import { FormHelperText, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SunEditor from 'suneditor-react';

const containsScriptsOrIframes = (content) =>
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi.test(content) || // Detect <script>
    /<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi.test(content) || // Detect <iframe>
    /\bon\w+=["'].*?["']/gi.test(content);

const CustomSunEditor = (props) => {
    const { onChange, sunError, ...rest } = props;
    const { t } = useTranslation();

    const [error, setError] = useState(false);

    const cleanValue = (value) => {
        if (containsScriptsOrIframes(value)) {
            setError(true);
            sunError(true);
            return;
        }
        setError(false);
        sunError(false);
        onChange(value);
    };

    return (
        <Stack>
            <SunEditor {...rest} onChange={cleanValue} />
            {error ? <FormHelperText error>{t('app.common.sunEditorError')}</FormHelperText> : null}
        </Stack>
    );
};

export default CustomSunEditor;
