import { Agents, Pools, Queues } from '@onlive.site/front-core';
import { createSlice } from '@reduxjs/toolkit';

export const realtimeSlice = createSlice({
    name: 'realtime',
    initialState: {
        queues: null,
        agents: null,
        pools: null,
        meetLoaded: false,
        realtimeLoaded: false,
        sessionRequest: null,
        available: false,
        iChat: null,
        iChatRequests: [],
        pool: [],
        queue: [],
    },
    reducers: {
        initRealTimes: (state, action) => {
            state.realtimeLoaded = true;
            let realtimeAgents = Agents.getInstance(action.payload.organizationId, 'firebase', action.payload.firebase);
            let realtimeQueues = Queues.getInstance(action.payload.organizationId, 'firebase', action.payload.firebase);
            let realtimePools = Pools.getInstance(action.payload.organizationId, 'firebase', action.payload.firebase);
            state.queues = realtimeQueues;
            state.agents = realtimeAgents;
            state.pools = realtimePools;
        },
        setAgentAvailable: (state, action) => {
            state.available = action.payload;
        },
        setMeetLoaded: (state, action) => {
            state.meetLoaded = action.payload;
        },
        setPool: (state, action) => {
            state.pool = action.payload;
        },
        setQueue: (state, action) => {
            state.queue = action.payload;
        },
        setIChat: (state, action) => {
            state.iChat = action.payload;
        },
        setSessionRequest: (state, action) => {
            state.request = action.payload;
        },
        setIchatRequests: (state, action) => {
            if (state.iChatRequests.length < action.payload.length)
                window.dispatchEvent(new CustomEvent('onlive-ichat-notification'));
            state.iChatRequests = action.payload;
        },
    },
});

export const {
    initRealTimes,
    setMeetLoaded,
    setSessionRequest,
    setAgentAvailable,
    setIChat,
    setIchatRequests,
    setPool,
    setQueue,
} = realtimeSlice.actions;

export const getRealtimeAgents = (state) => state.realtime.agents;
export const getRealtimeQueues = (state) => state.realtime.queues;
export const getRealtimePools = (state) => state.realtime.pools;
export const getMeetLoaded = (state) => state.realtime.meetLoaded;
export const getRealtimeLoaded = (state) => state.realtime.realtimeLoaded && state.realtime.meetLoaded;
export const getSessionRequest = (state) => state.realtime.request;
export const getAgentAvailable = (state) => state.realtime.available;
export const getIChat = (state) => state.realtime.iChat;
export const getIchatRequests = (state) => state.realtime.iChatRequests;
export const getPool = (state) => state.realtime.pool;
export const getQueue = (state) => state.realtime.queue;

export default realtimeSlice.reducer;

export const loadIchat = (user, ichatVersion, context) => (dispatch) => {
    if (document.body.querySelector('#onlive-ichat')) return;
    const script = document.createElement('script');
    script.id = 'onlive-ichat';
    let basePath = process.env.REACT_APP_ICHAT_URL;
    let bundleName = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'admin.min.js' : 'admin.js';

    script.src =
        process.env.REACT_APP_ENVIRONMENT === 'production'
            ? `${basePath}/${ichatVersion}/${bundleName}`
            : `${basePath}`;

    script.async = true;
    script.onload = () => {
        const agentIchatEl = document.createElement('agent-ichat');
        agentIchatEl.organizationId = user.organizationId;
        agentIchatEl.nickname = user.firstName;
        agentIchatEl.avatar = user.avatar;
        agentIchatEl.showBubble = false;
        agentIchatEl.context = context;
        dispatch(setIChat(agentIchatEl));
    };
    window.addEventListener('onlive-ichat-request', (e) => {
        dispatch(setIchatRequests(e.detail || []));
    });
    document.body.appendChild(script);
};
