import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const indexerAccountSlice = createSlice({
    name: 'indexerAccount',
    initialState: {
        pending: false,
        indexerAccounts: [],
        error: null,
    },
    reducers: {
        resetIndexerAccounts: (state, action) => {
            state.indexerAccounts = [];
        },
        addIndexerAccount: (state, action) => {
            state.pending = false;
            state.indexerAccounts = [...state.indexerAccounts, action.payload];
        },
        deleteIndexerAccount: (state, action) => {
            state.pending = false;
            const index = state.indexerAccounts.findIndex((indexerAccount) => indexerAccount.id === action.payload.id);
            if (index !== -1) {
                state.indexerAccounts = [
                    ...state.indexerAccounts.slice(0, index),
                    ...state.indexerAccounts.slice(index + 1),
                ];
            }
        },
        updateIndexerAccount: (state, action) => {
            state.pending = false;
            const index = state.indexerAccounts.findIndex((indexerAccount) => indexerAccount.id === action.payload.id);
            if (index !== -1) {
                state.indexerAccounts = [
                    ...state.indexerAccounts.slice(0, index),
                    action.payload,
                    ...state.indexerAccounts.slice(index + 1),
                ];
            }
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setIndexerAccounts: (state, action) => {
            state.pending = false;
            state.indexerAccounts = action.payload;
        },
    },
});

export const {
    resetIndexerAccounts,
    addIndexerAccount,
    deleteIndexerAccount,
    updateIndexerAccount,
    setError,
    start,
    stop,
    setIndexerAccounts,
} = indexerAccountSlice.actions;

export const getIndexerAccounts = (state) => state.indexerAccessToken.indexerAccessTokens;
export const getIndexerAccountSavePending = (state) => state.indexerAccessToken.savePending;
export const getIndexerAccountPending = (state) => state.indexerAccessToken.pending;
export const getIndexerAccountError = (state) => state.indexerAccessToken.error;


export const addIndexerAccountAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.post('indexer-account', data)
                .then((response) => {
                    dispatch(addIndexerAccount(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };


export default indexerAccountSlice.reducer;
