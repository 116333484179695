import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const searchEngineSlice = createSlice({
    name: 'searchEngine',
    initialState: {
        pending: false,
        savePending: false,
        searchEngines: [],
        error: null,
    },
    reducers: {
        resetSearchEngines: (state, action) => {
            state.searchEngines = [];
        },
        addSearchEngine: (state, action) => {
            state.savePending = false;
            state.searchEngines = [...state.searchEngines, action.payload];
        },
        deleteSearchEngine: (state, action) => {
            state.pending = false;
            const index = state.searchEngines.findIndex((searchEngine) => searchEngine.id === action.payload.id);
            if (index !== -1) {
                state.searchEngines = [...state.searchEngines.slice(0, index), ...state.searchEngines.slice(index + 1)];
            }
        },
        updateSearchEngine: (state, action) => {
            state.savePending = false;
            const index = state.searchEngines.findIndex((searchEngine) => searchEngine.id === action.payload.id);
            if (index !== -1) {
                state.searchEngines = [
                    ...state.searchEngines.slice(0, index),
                    action.payload,
                    ...state.searchEngines.slice(index + 1),
                ];
            }
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setSearchEngines: (state, action) => {
            state.pending = false;
            state.searchEngines = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    resetSearchEngines,
    addSearchEngine,
    deleteSearchEngine,
    updateSearchEngine,
    setError,
    start,
    stop,
    setSearchEngines,
    startSave,
    stopSave,
    setSaveError,
} = searchEngineSlice.actions;

export const getSearchEngines = (state) => state.searchEngine.searchEngines;
export const getSearchEnginePending = (state) => state.searchEngine.pending;
export const getSearchEngineSavePending = (state) => state.searchEngine.savePending;
export const getSearchEngineError = (state) => state.searchEngine.error;

export const fetchSearchEnginesAction = () => async (dispatch) => {
    try {
        dispatch(start());
        api.get('search-engine')
            .then((response) => {
                dispatch(setSearchEngines(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const addSearchEngineAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('search-engine', data)
                .then((response) => {
                    dispatch(addSearchEngine(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const updateSearchEngineAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('search-engine/' + id, data)
                .then((response) => {
                    dispatch(updateSearchEngine(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteSearchEngineAction =
    ({ id, onSuccess, onFailure } = {} ) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.delete('search-engine/' + id)
                .then((response) => {
                    dispatch(deleteSearchEngine(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };


export default searchEngineSlice.reducer;
