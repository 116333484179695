import { connect, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { useCallback } from 'react';
import { getUser, userHasAuthenticated } from 'redux/slices/authSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { useAuthLicenseAddOns } from '../features/licenseFeatures/useAuthLicenseAddOns';
import { useDefaultServiceRoute } from '../hooks/useDefaultServiceRoute';

const ProtectedRoute = ({
    roles,
    user,
    isAuthenticated,
    hiddenIfProfile,
    permissions,
    main,
}) => {
    const isAuthorize = useAuthLicenseAddOns();
    const organization = useSelector(getOrganization);
    const defaultRedirect = useDefaultServiceRoute();

    const redirectPath = useCallback(() => {
        return isAuthenticated ? defaultRedirect : '/login';
    }, [defaultRedirect, isAuthenticated]);

    return isAuthenticated &&
        (!roles || user?.role === 'root' || roles.findIndex((role) => role === user?.role) !== -1) &&
        (!hiddenIfProfile || !organization?.isProfile) &&
        (!permissions || isAuthorize(permissions, user?.role === 'root' ? false : main, true)) ? (
        <Outlet />
    ) : (
        <Navigate replace={true} to={redirectPath()} />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: userHasAuthenticated(state),
    user: getUser(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
