import { CircularProgress } from '@mui/material';
import useFullLoadingStyles from 'components/styles/useFullLoadingStyles';

const backgroundImage = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/auth_background.webp`;
const logoImage = `${process.env.REACT_APP_ASSETS_URL}/ONLIVE.SITE_LOGOTYPE_COLOR_150x30.webp`;

const OAuthTransition = ({ message }) => {
    const { classes } = useFullLoadingStyles();

    return (
        <div className={classes.root}>
            <div className={classes.text}>{message}</div>
            <CircularProgress classes={{ root: classes.spinner }} size={'4vh'} />
            <div className={classes.logo}>
                <img src={logoImage} alt="Logo" />
            </div>
            <div className={classes.backgroundImage}>
                <img src={backgroundImage} alt="Background" />
            </div>
        </div>
    );
};

export default OAuthTransition;
