import { Add } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import OHoursRange from './OHoursRange';
import useStylesOCalendar from './styles/useStylesOCalendar.styles';

const OWeeklyHours = (props) => {
    const { days, onChange, onHourRangeChange, onAddHourRange, onDeleteHourRange, timeZone, isReadOnly } = props;

    const { classes } = useStylesOCalendar();
    const { t, i18n } = useTranslation();

    moment.locale(i18n.language);

    return (
        <FormGroup className={classes.reactionRow}>
            {days.map((d) => {
                return (
                    <div className={classes.hoursPicker} key={d.id}>
                        <Grid container spacing={1}>
                            <Grid item md={3}>
                                <FormControlLabel
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={d.enable}
                                            onChange={(e) => onChange(d.index)}
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            disabled={isReadOnly}
                                        />
                                    }
                                    label={
                                        <div className={classes.text}>
                                            {moment().day(d.index).format('dddd').toUpperCase()}
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item md={8}>
                                {d.enable && d.hours.length ? (
                                    d.hours.map((h) => {
                                        return (
                                            <OHoursRange
                                                id={h.id}
                                                key={h.id}
                                                initialHourBegin={h.startTime}
                                                initialHourEnd={h.endTime}
                                                onDelete={() => onDeleteHourRange(d.index, h.id)}
                                                onChange={(hoursRange) => onHourRangeChange(d.index, hoursRange)}
                                                timeZone={timeZone}
                                                isReadOnly={isReadOnly}
                                            />
                                        );
                                    })
                                ) : (
                                    <Typography className={classes.textUndefined}>
                                        {t('common.term.unavailable')}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item md={1}>
                                <CustomIconButton
                                    size="small"
                                    id="onlive-button-delete"
                                    onClick={() => onAddHourRange(d.index)}
                                    disabled={!d.enable || isReadOnly}
                                    hideBorder={true}
                                >
                                    <Add component="svg" />
                                </CustomIconButton>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
        </FormGroup>
    );
};

export default OWeeklyHours;
