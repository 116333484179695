import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button, MenuItem } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import RoundedSelect from 'common/components/RoundedSelect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';

const CartSettings = (props) => {
    const {
        settingValue,
        toggleChange,
        changeAllOptions,
        changeExclusionaryOption,
        handleSubmit,
        hasChanges,
        shopInStoreValue,
        showPriceValue,
        enableShopping,
        cartEmptyAfterCheckout,
        storeUpdateCheckoutBehavior,
        toggleMultipleSettingsChange,
    } = props;

    const { t } = useTranslation();

    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);

    //const [langSetting, setLangSetting] = useState(langSettingValue);

    const handleSelectChange = (e) => {
        // setLangSetting(e.target.value);
        toggleChange(SettingsKey.STORE_UPDATE_CHECKOUT_BEHAVIOR, e.target.value);
    };

    const handleCheckChange = (event, settingName) => {
        if (settingName === SettingsKey.ENABLE_SHOPPING && !event.target.checked) changeAllOptions();
        else if (
            (settingName === SettingsKey.ENABLE_EVENT_CART || settingName === SettingsKey.ENABLE_SHOP_IN_STORE) &&
            event.target.checked &&
            enableShopping
        )
            changeExclusionaryOption(settingName);
        else if (settingName === SettingsKey.ENABLE_EVENT_CART && !event.target.checked && enableShopping)
            toggleMultipleSettingsChange([
                { settingKey: SettingsKey.ENABLE_EVENT_CART, settingValue: false },
                { settingKey: SettingsKey.CART_EMPTY_AFTER_CHECKOUT, settingValue: false },
            ]);
        else if (
            (event.target.checked && (enableShopping || settingName === SettingsKey.ENABLE_SHOPPING)) ||
            !event.target.checked
        )
            toggleChange(settingName, event.target.checked);
    };

    const templateSelectorStyle = {
        width: '50%',
        height: '35px',
        fontSize: '11px',
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.cartSettings.cart')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <div style={{ paddingBottom: '20px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <ItemSettings
                            label={t('profile.settings.eventSettings.enableShopping')}
                            onChange={handleCheckChange}
                            name={'enableShopping'}
                            keySettings={SettingsKey.ENABLE_SHOPPING}
                            value={enableShopping}
                        />

                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('profile.settings.cartSettings.save')}
                            </Button>
                        </Box>
                    </Box>
                </div>
                <ItemSettings
                    label={t('profile.settings.cartSettings.setAccess')}
                    onChange={handleCheckChange}
                    name={'eventCart'}
                    keySettings={SettingsKey.ENABLE_EVENT_CART}
                    value={settingValue}
                    paddingBottom
                />
                <ItemSettings
                    label={t('profile.settings.eventSettings.shopInStore')}
                    onChange={handleCheckChange}
                    name={'shopInStore'}
                    keySettings={SettingsKey.ENABLE_SHOP_IN_STORE}
                    value={shopInStoreValue}
                    paddingBottom
                />
                {settingValue && (
                    <ItemSettings
                        label={t('profile.settings.eventSettings.cartEmptyAfterCheckout')}
                        onChange={handleCheckChange}
                        name={'cartEmptyAfterCheckout'}
                        keySettings={SettingsKey.CART_EMPTY_AFTER_CHECKOUT}
                        value={cartEmptyAfterCheckout}
                        paddingBottom
                    />
                )}
                {shopInStoreValue && (
                    <ItemSettings
                        label={t('profile.settings.eventSettings.showPrice')}
                        onChange={handleCheckChange}
                        name={'showPrice'}
                        keySettings={SettingsKey.SHOW_PRICE}
                        value={showPriceValue}
                    />
                )}

                <div style={{ paddingTop: '12px' }}>
                    <Typography className={classes.emailsTemplatesText}>{t('cartSettings.storeUpdate')}</Typography>
                    <RoundedSelect
                        value={storeUpdateCheckoutBehavior}
                        style={templateSelectorStyle}
                        fullWidth
                        onChange={(e) => handleSelectChange(e)}
                        iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                    >
                        <MenuItem value="new" key="new">
                            {t('cartSetting.storeUpdate.new')}
                        </MenuItem>
                        <MenuItem value="update" key="update">
                            {t('cartSetting.storeUpdate.update')}
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default CartSettings;
