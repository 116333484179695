import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const addOnsActiveSlice = createSlice({
    name: 'addOnsActive',
    initialState: {
        pending: false,
        addOns: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setAddOns: (state, action) => {
            state.pending = false;
            state.addOns = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        deleteAddOns: (state, action) => {
            state.pending = false;
            const index = state.addOns.findIndex((addOn) => addOn.id === action.payload.id);
            if (index !== -1) {
                state.customers = [...state.addOns.slice(0, index), ...state.addOns.slice(index + 1)];
            }
        },
    },
});

export const { start, stop, setAddOns, setError, deleteAddOns } = addOnsActiveSlice.actions;

export const getAddOnsActive = (state) => state.addOnsActive.addOns;
export const getAddOnsActivePending = (state) => state.addOnsActive.pending;
export const getAddOnsActiveError = (state) => state.addOnsActive.error;

export const fetchAddOnsActiveAction = () => async (dispatch) => {
    try {
        const organizationIdToSend = localStorage?.getItem('user')
            ? JSON.parse(localStorage?.getItem('user'))?.organizationId
            : null;
        dispatch(start());
        api.get(`add-ons/active/${organizationIdToSend}`)
            .then((response) => {
                dispatch(setAddOns(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const editAddOnsActiveAction =
    ({ addOns, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            const orgId = localStorage?.getItem('user')
                ? JSON.parse(localStorage?.getItem('user'))?.organizationId
                : null;
            api.put('add-ons/active/' + orgId, { addOns })
                .then((response) => {
                    dispatch(setAddOns(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export default addOnsActiveSlice.reducer;
