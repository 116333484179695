import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const taxonomySlice = createSlice({
    name: 'taxonomy',
    initialState: {
        pending: false,
        savePending: false,
        taxonomies: [],
        termTaxonomies: {},
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        //------------------------------------------------------------------------------------------------------
        setTaxonomies: (state, action) => {
            state.pending = false;
            state.taxonomies = action.payload;
        },
        addTaxonomy: (state, action) => {
            state.savePending = false;
            state.taxonomies = [action.payload, ...state.taxonomies];
        },
        editTaxonomy: (state, action) => {
            state.savePending = false;
            const index = state.taxonomies.findIndex((taxonomy) => taxonomy.id === action.payload.id);
            if (index !== -1) {
                state.taxonomies = [
                    ...state.taxonomies.slice(0, index),
                    action.payload,
                    ...state.taxonomies.slice(index + 1),
                ];
            }
        },
        deleteTaxonomy: (state, action) => {
            state.deletePending = false;
            const index = state.taxonomies.findIndex((taxonomy) => taxonomy.id === action.payload.id);
            if (index !== -1) {
                state.taxonomies = [...state.taxonomies.slice(0, index), ...state.taxonomies.slice(index + 1)];
                delete state.termTaxonomies?.[`${action.payload.id}`];
            }
        },

        //------------------------------------------------------------------------------------------------------
        concatTermsTaxonomies: (state, action) => {
            state.pending = false;
            state.termTaxonomies[`${action.payload.parentId}`] = action.payload.terms;
        },
        setTermTaxonomy: (state, action) => {
            state.termTaxonomies = action.payload;
        },
        addTermTaxonomy: (state, action) => {
            state.savePending = false;
            const terms = state.termTaxonomies[`${action.payload.parentId}`] || [];
            state.termTaxonomies[`${action.payload.parentId}`] = [action.payload.term, ...terms];
        },
        editTermTaxonomy: (state, action) => {
            state.savePending = false;
            const { parentId, taxonomySlug, id } = action.payload;
            let terms = [];
            let taxonomyId = '';

            if (parentId) {
                terms = state.termTaxonomies[parentId] || [];
            } else {
                const taxonomy = state.taxonomies.find((tax) => tax.slug === taxonomySlug);
                if (taxonomy) {
                    taxonomyId = taxonomy.id;
                    terms = state.termTaxonomies[taxonomyId] || [];
                }
            }

            const index = terms.findIndex((term) => term.id === id);

            if (index !== -1) {
                const updatedTerms = [...terms.slice(0, index), action.payload, ...terms.slice(index + 1)];
                if (parentId) {
                    state.termTaxonomies[parentId] = updatedTerms;
                } else {
                    state.termTaxonomies[taxonomyId] = updatedTerms;
                }
            }
        },

        deleteTermTaxonomy: (state, action) => {
            state.deletePending = false;
            const terms = state.termTaxonomies[`${action.payload.parentId}`] || [];
            const index = terms.findIndex((term) => term.id === action.payload.id);
            if (index !== -1) {
                const newTerms = [...terms.slice(0, index), ...terms.slice(index + 1)];
                if (newTerms.length) {
                    state.termTaxonomies[`${action.payload.parentId}`] = newTerms;
                } else {
                    delete state.termTaxonomies[`${action.payload.parentId}`];
                }
            }
        },
    },
});

export const {
    start,
    stop,
    //--------------------------------
    setTaxonomies,
    addTaxonomy,
    editTaxonomy,
    deleteTaxonomy,
    //--------------------------------
    concatTermsTaxonomies,
    addTermTaxonomy,
    editTermTaxonomy,
    setTermTaxonomy,
    deleteTermTaxonomy,
    //--------------------------------
    stopDelete,
    setError,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
} = taxonomySlice.actions;

export const getTaxonomies = (state) => state.taxonomy.taxonomies;
export const getTaxonomiesPending = (state) => state.taxonomy.pending;
export const getTaxonomiesSavePending = (state) => state.taxonomy.savePending;
export const getDeletePending = (state) => state.taxonomy.deletePending;
export const getDeleteId = (state) => state.taxonomy.deleteId;
export const getTaxonomiesError = (state) => state.taxonomy.error;
export const getTermsTaxonomy = (state) => state.taxonomy.termTaxonomies;

//----------TAXONOMIES-----------------------------------------------------------------

export const fetchTaxonomiesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('taxonomies' + query)
                .then((response) => {
                    dispatch(setTaxonomies(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const getTaxonomyAction =
    ({ taxonomySlug, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get('taxonomies' + taxonomySlug)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addTaxonomyAction =
    ({ taxonomyDto, callback } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('taxonomies', taxonomyDto)
                .then((response) => {
                    dispatch(addTaxonomy(response?.data));
                    if (callback) {
                        callback(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (callback) {
                        callback(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editTaxonomyAction =
    ({ taxonomySlug, taxonomyDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('taxonomies/' + taxonomySlug, taxonomyDto)
                .then((response) => {
                    dispatch(editTaxonomy(response.data));
                    if (onSuccess) {
                        onSuccess(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteTaxonomyAction =
    ({ taxonomySlug, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(taxonomySlug));
            api.delete('taxonomies/' + taxonomySlug)
                .then((response) => {
                    dispatch(deleteTaxonomy(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error('error', e);
        }
    };

//-----------TERM-TAXONOMY----------------------------------------------------------------------------------------------------

export const addTermTaxonomyBySlugAction =
    ({ taxonomySlug, parentId, taxonomyDto, callback } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(`taxonomies/${taxonomySlug}/term-taxonomy`, taxonomyDto)
                .then((response) => {
                    dispatch(addTermTaxonomy({ term: response?.data, parentId }));
                    if (callback) {
                        callback(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (callback) {
                        callback(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editTermTaxonomyBySlugAction =
    ({ taxonomySlug, termId, termDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put(`taxonomies/${taxonomySlug}/term-taxonomy/` + termId, termDto)
                .then((response) => {
                    dispatch(editTermTaxonomy(response.data));
                    if (onSuccess) {
                        onSuccess(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteTermTaxonomyAction =
    ({ taxonomySlug, termId, parentId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(taxonomySlug));
            api.delete(`taxonomies/${taxonomySlug}/term-taxonomy/` + termId)
                .then((response) => {
                    dispatch(deleteTermTaxonomy({ ...response.data, parentId }));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error('error', e);
        }
    };

export const fetchTermTaxonomyAction =
    ({ taxonomySlug, taxonomyId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get(`/taxonomies/${taxonomySlug}/term-taxonomy`)
                .then((response) => {
                    dispatch(concatTermsTaxonomies({ terms: response.data, parentId: taxonomyId }));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchTermTaxonomiesByParentIdAction =
    ({ taxonomySlug, parentId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get(`/taxonomies/${taxonomySlug}/term-taxonomy/${parentId}/children`)
                .then((response) => {
                    dispatch(concatTermsTaxonomies({ terms: response.data, parentId }));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchTermTaxonomyByFacetAction = (taxonomySlug, facet, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(start());
        api.get(`/taxonomies/${taxonomySlug}/${facet}`)
            .then((response) => {
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export default taxonomySlice.reducer;
