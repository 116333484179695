import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import api, { calendarApiV3 } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        pending: false,
        sessionRequests: [],
        error: null,
        syncCalendly: localStorage?.getItem('organization')
            ? JSON.parse(localStorage?.getItem('organization'))?.syncCalendly
            : false,
        calendly: localStorage?.getItem('user') ? JSON.parse(localStorage?.getItem('user'))?.calendly : null,
        calendarEventDialog: null,
        currentDate: new Date(),
        view: 'month',
        calendarEventList: [],
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setSessionRequests: (state, action) => {
            state.pending = false;
            state.sessionRequests = action.payload;
        },

        setCalendly: (state, action) => {
            state.calendly = action.payload.calendly || '';
            state.syncCalendly = action.payload.syncCalendly;
        },
        setError: (state, action) => {
            state.pending = false;
            state.sessionRequests = [];
            state.error = action.payload;
        },
        editSessionRequest: (state, action) => {
            const index = state.sessionRequests.findIndex((e) => e.eventUri === action.payload.eventUri);
            state.sessionRequests = [
                ...state.sessionRequests.slice(0, index),
                action.payload,
                ...state.sessionRequests.slice(index + 1, state.sessionRequests.length),
            ];
        },
        addSessionRequest: (state, action) => {
            state.sessionRequests = [...state.sessionRequests, action.payload];
        },
        deleteSessionRequest: (state, action) => {
            const index = state.sessionRequests.findIndex((e) => e.id === action.payload);
            state.sessionRequests = [
                ...state.sessionRequests.slice(0, index),
                ...state.sessionRequests.slice(index + 1, state.sessionRequests.length),
            ];
        },
        openCalendarEventDialog: (state, action) => {
            state.calendarEventDialog = action.payload;
        },
        setDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setCalendarEventList: (state, action) => {
            state.calendarEventList = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setSessionRequests,
    setCalendly,
    setError,
    addSessionRequest,
    editSessionRequest,
    deleteSessionRequest,
    openCalendarEventDialog,
    setDate,
    setView,
    setCalendarEventList,
} = calendarSlice.actions;

export const getSessionRequests = (state) => state.calendar.sessionRequests;
export const getCalendarEvents = (state) => state.calendar.events;
export const isPending = (state) => state.calendar.pending;
export const isSyncCalendlyEnabled = (state) => state.calendar.syncCalendly;
export const getCalendarError = (state) => state.calendar.error;
export const getOpenedCalendarEvent = (state) => state.calendar.calendarEventDialog;
export const getCurrentDate = (state) => state.calendar.currentDate;
export const getView = (state) => state.calendar.view;
export const getCalendarEventList = (state) => state.calendar.calendarEventList;
export const getCalendly = (state) => state.calendar.calendly;

export const getCalendarCapacityAction =
    ({ id, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            calendarApiV3
                .get(`templates/${id}/calendars-capacity` + query)
                .then((response) => {
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            return console.error('error', e);
        }
    };

export const fetchSessionRequestsAction = () => async (dispatch) => {
    try {
        dispatch(start());
        api.get('session-requests')
            .then((response) => {
                dispatch(
                    setSessionRequests(
                        response.data.map((sessionRequest) => {
                            return {
                                id: sessionRequest.id,
                                title: `${sessionRequest.duration}min with ${sessionRequest.inviteeName.split(' ')[0]}`,
                                start: new Date(sessionRequest.startDate),
                                end: moment(new Date(sessionRequest.startDate))
                                    .add(sessionRequest.duration, 'm')
                                    .toDate(),
                                duration: sessionRequest.duration,
                                scheduled: false,
                                info: sessionRequest.info ? sessionRequest.info : '',
                                inviteeEmail: sessionRequest.inviteeEmail,
                                inviteeName: sessionRequest.inviteeName,
                                eventUri: sessionRequest.calendlyEventUri,
                                eventTypeUri: sessionRequest.calendlyEventTypeUri,
                                cancelEventUri: sessionRequest.calendlyCancelEventUri,
                                guests: sessionRequest.guests,
                                streamConnectors: [],
                            };
                        }),
                    ),
                );
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const cancelSessionRequestAction = (sessionRequestId) => async (dispatch) => {
    try {
        dispatch(start());
        api.put('session-requests/cancel/' + sessionRequestId)
            .then((response) => {
                dispatch(deleteSessionRequest(sessionRequestId));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const closeEventAction = () => {
    return (dispatch) => {
        dispatch(openCalendarEventDialog(null));
    };
};

export const addSessionRequestAction = (sessionRequest) => async (dispatch) => {
    dispatch(
        addSessionRequest({
            id: sessionRequest.id,
            title: `${sessionRequest.duration}min with ${sessionRequest.inviteeName.split(' ')[0]}`,
            start: new Date(sessionRequest.startDate),
            end: moment(new Date(sessionRequest.startDate)).add(sessionRequest.duration, 'm').toDate(),
            duration: sessionRequest.duration,
            scheduled: false,
            inviteeEmail: sessionRequest.inviteeEmail,
            inviteeName: sessionRequest.inviteeName,
            eventUri: sessionRequest.calendlyEventUri,
            eventTypeUri: sessionRequest.calendlyEventTypeUri,
            cancelEventUri: sessionRequest.calendlyCancelEventUri,
            guests: sessionRequest.guests,
            streamConnectors: [],
        }),
    );
};

export const openCalendarEventDialogAction = (event) => {
    return (dispatch) => {
        dispatch(openCalendarEventDialog(event));
    };
};

export default calendarSlice.reducer;
