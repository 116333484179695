import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Stack, Typography } from '@mui/material';
import { calendarApiV3 as api } from 'api';
import TriageApp from 'app/triageApp/triageApp';
import CustomCard from 'common/components/CustomCard';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import YesNoDialog from 'common/components/YesNoDialog';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openCustomDialog } from 'redux/slices/customDialogSlice';
import { openNestedDialog } from 'redux/slices/nestedDialogSlice';
import { deleteServiceAction } from 'redux/slices/serviceSlice';
import ServiceShare from './ServiceShare';
import ServiceEdit from './serviceForm/ServiceEdit';

const ServiceItem = ({ template, type, isService, group, disabledDelete = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [openConfirmDeleteDlg, setOpenConfirmDeleteDlg] = useState(false);

    const titleDlg = t('service.serviceItem.deleteConfirm');
    const messageDlg = t('service.serviceItem.deleteMessage');

    const handleDelete = (e) => {
        e.stopPropagation();
        setOpenConfirmDeleteDlg(true);
    };

    const confirmDelete = () => {
        dispatch(
            deleteServiceAction(template.id, () => {
                enqueueSnackbar(t('videoHelp.videoHelpItem.wasDeleted'), { variant: 'success' });
            }),
        );
        setOpenConfirmDeleteDlg(false);
    };

    const cancelDlg = () => {
        setOpenConfirmDeleteDlg(false);
    };

    const handleEdit = () => {
        api.post(`templates/${template.id}/full-capacity`)
            .then((response) => {
                const { availableCapacities, busyCapacities, totalCapacities } = response.data;
                const fullService = { ...response.data.template, availableCapacities, busyCapacities, totalCapacities };
                dispatch(openCustomDialog(<ServiceEdit service={fullService} />));
            })
            .catch((error) => {
                console.log(error);
                dispatch(openCustomDialog(<ServiceEdit service={template} />));
            });
    };

    const getSubtitle = (template) => {
        const subtitles = [];

        if (template.start) {
            if (template.end) {
                subtitles.push(`${t('common.from')} ${template.start} ${t('common.to')} ${template.end}`);
            } else {
                subtitles.push(`${t('iCalendar.schedule.startAt')} ${template.start}`);
            }
        }

        if (group) {
            subtitles.push(`${t('services.form.group')}: ${group.name}`);
        }
        if (type) {
            subtitles.push(`${t('app.common.type')}: ${type.name}`);
        }
        return subtitles;
    };

    const BodyText = ({ text }) => {
        return (
            <Typography variant="body1" gutterBottom component="div">
                {text}
            </Typography>
        );
    };

    const cardBody = (template) => {
        return (
            <Stack direction="column" spacing={1}>
                <BodyText text={`${template.timeBetween} ${t('iCalendar.schedule.minutesBetweenEvents')}`} />
                <BodyText text={`${template.timeBetween} ${t('iCalendar.schedule.minutesBetweenEvents')}`} />
                <BodyText text={`${template.duration} ${t('iCalendar.schedule.minutesPerEvents')}`} />
                <BodyText text={`${template.minTimeToSchedule} ${t('iCalendar.schedule.minTimeToSchedule')}`} />
                <BodyText text={`${template.maxTimeToSchedule} ${t('iCalendar.schedule.maxTimeToSchedule')}`} />
            </Stack>
        );
    };

    const openShare = () => {
        dispatch(openCustomDialog(<ServiceShare template={template} group={group} />));
    };

    const actions = (template) => {
        return (
            <>
                <CustomTooltip
                    key={`action-edit-${template.id}`}
                    arrow
                    placement="bottom"
                    title={t('actions.edit')}
                    aria-label="edit service"
                >
                    <CustomIconButton id="onlive-button-edit" size="small" onClick={handleEdit}>
                        <EditOutlinedIcon component="svg" />
                    </CustomIconButton>
                </CustomTooltip>

                <CustomTooltip
                    key={`action-schedule-${template.id}`}
                    arrow
                    placement="bottom"
                    title={t('actions.schedule')}
                    aria-label="schedule"
                >
                    <CustomIconButton
                        id="onlive-button-schedule"
                        size="small"
                        onClick={() => {
                            dispatch(openNestedDialog(<TriageApp callback={() => {}} service={template} />));
                        }}
                    >
                        <DateRangeOutlinedIcon component="svg" />
                    </CustomIconButton>
                </CustomTooltip>

                <CustomTooltip
                    key={`action-share-${template.id}`}
                    arrow
                    placement="bottom"
                    title={t('actions.share')}
                    aria-label="share"
                >
                    <CustomIconButton aria-label="copy to share" size="small" onClick={openShare}>
                        <ShareOutlinedIcon />
                    </CustomIconButton>
                </CustomTooltip>

                {isService ? (
                    <CustomTooltip
                        key={`action-delete-${template.id}`}
                        arrow
                        placement={'bottom'}
                        title={t('app.common.delete')}
                        aria-label="delete"
                    >
                        <CustomIconButton
                            size="small"
                            id="onlive-button-delete"
                            onClick={handleDelete}
                            disabled={disabledDelete}
                        >
                            <DeleteOutlinedIcon component="svg" />
                        </CustomIconButton>
                    </CustomTooltip>
                ) : null}
            </>
        );
    };

    return (
        <>
            <CustomCard
                title={template.name}
                subtitles={getSubtitle(template)}
                image={template.image || '/broken-image.jpg'}
                titleDivider={true}
                actions={actions(template)}
                cardBody={cardBody(template)}
                actionsAlign="right"
                borderRadius={2}
            />
            {openConfirmDeleteDlg && (
                <YesNoDialog
                    openDialog={true}
                    title={titleDlg}
                    messageToConfirm={messageDlg}
                    yesButtonCallback={() => {
                        confirmDelete();
                    }}
                    noButtonCallback={() => {
                        cancelDlg();
                    }}
                />
            )}
        </>
    );
};

export default ServiceItem;
