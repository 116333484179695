import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const groupSlice = createSlice({
    name: 'group',
    initialState: {
        pending: false,
        savePending: false,
        pendingByIds: false,
        groups: [],
        groupsByIds: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 1,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addGroup: (state, action) => {
            state.savePending = false;
            state.groups = [action.payload, ...state.groups];
        },
        editGroup: (state, action) => {
            state.savePending = false;
            const index = state.groups.findIndex((group) => group.id === action.payload.id);
            if (index !== -1) {
                state.groups = [...state.groups.slice(0, index), action.payload, ...state.groups.slice(index + 1)];
            }
        },
        deleteGroup: (state, action) => {
            state.deletePending = false;
            const index = state.groups.findIndex((group) => group.id === action.payload.id);
            if (index !== -1) {
                state.groups = [...state.groups.slice(0, index), ...state.groups.slice(index + 1)];
            }
        },
        setGroups: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.groups = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setGroupsByIds: (state, action) => {
            state.pendingByIds = false;
            state.groupsByIds = action.payload.items;
        },
        setPendingByIds: (state, action) => {
            state.pendingByIds = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setGroups,
    stopDelete,
    setError,
    setGroupsByIds,
    setPendingByIds,
    addGroup,
    editGroup,
    deleteGroup,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
} = groupSlice.actions;

export const getGroups = (state) => state.group.groups;
export const getGroupsPending = (state) => state.group.pending;
export const getGroupsSavePending = (state) => state.group.savePending;
export const getDeletePending = (state) => state.group.deletePending;
export const getDeleteId = (state) => state.group.deleteId;
export const getGroupsError = (state) => state.group.error;
export const getGroupsTotal = (state) => state.group.total;
export const getGroupsCurrentPage = (state) => state.group.currentPage;
export const getGroupsTotalPages = (state) => state.group.totalPages;
export const getGroupsByIds = (state) => state.group.groupsByIds;
export const getGroupsPendingByIds = (state) => state.group.pendingByIds;

export const fetchGroupsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('user-groups' + query)
                .then((response) => {
                    dispatch(setGroups(response.data));
                    onSuccess?.(response.data.items);
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchGroupsByIdsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(setPendingByIds(true));
            api.get('user-groups' + query)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess?.(response.data.items);
                        dispatch(setPendingByIds(false));
                    } else {
                        dispatch(setGroupsByIds(response.data));
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    dispatch(setPendingByIds(false));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(setPendingByIds(false));
            return console.error(e.message);
        }
    };

export const getGroupAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(setPendingByIds(true));
            api.get('user-groups/' + id)
                .then((response) => {
                    dispatch(setPendingByIds(false));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setPendingByIds(false));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(setPendingByIds(false));
            onFailure?.();
            return console.error(e.message);
        }
    };

export const addGroupAction = (groupDto, callback) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('user-groups', groupDto)
            .then((response) => {
                dispatch(addGroup(response?.data));
                callback?.(null);
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                callback?.(error);
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error(e.message);
    }
};

export const editGroupAction = (id, groupDto, callback) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('user-groups/' + id, groupDto)
            .then((response) => {
                dispatch(editGroup(response.data));
                callback?.(null);
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                callback?.(error);
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteGroupAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete('user-groups/' + id)
                .then((response) => {
                    dispatch(deleteGroup(response.data));
                    onSuccess?.();
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error('error', e);
        }
    };

export default groupSlice.reducer;
