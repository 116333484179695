import useStylesOCalendar from 'app/ocalendar/styles/useStylesOCalendar.styles';
import ActionButton from 'common/components/ActionButton';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganization } from 'redux/slices/organizationSlice';
import { createOrUpdateSetting, getSettings } from 'redux/slices/settingSlice';

import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import Schedule from './Schedule';

function mockWorkingDays() {
    const days = [];
    for (let i = 0; i < 7; i++) {
        days.push({
            enable: false,
            index: i,
            hours: [],
        });
    }
    return days;
}

function generateTemplate() {
    return {
        workingHours: mockWorkingDays(),
        dateOverrides: [],
    };
}

const OrganizationAvailability = () => {
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesOCalendar();
    const classes = { ...classes1, ...classes2 };

    const dispatch = useDispatch();
    const organization = useSelector(getOrganization);
    const settings = useSelector(getSettings);

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [template, setTemplate] = useState(generateTemplate());
    const [timezone, setTimezone] = useState('Europe/Madrid');

    moment.locale(i18n.language || 'en');

    const _timezone = useSettingValue(SettingsKey.TIMEZONE) || 'Europe/Madrid';
    const availability = useSettingValue(SettingsKey.AVAILABILITY);

    useEffect(() => {
        setTimezone(_timezone);
        if (availability) {
            setTemplate({
                workingHours: availability.workingHours
                    .map((w) => {
                        return {
                            ...w,
                            hours: [...w.hours],
                        };
                    })
                    .sort((a, b) => a.index - b.index),
                dateOverrides: availability.dateOverrides.map((d) => {
                    return {
                        ...d,
                        hours: [...d.hours],
                    };
                }),
            });
        }
    }, [settings, _timezone, availability]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(
                createOrUpdateSetting({
                    availability: {
                        settingKey: SettingsKey.AVAILABILITY,
                        settingValue: {
                            ...template,
                            workingHours: template.workingHours.map((day) => ({
                                ...day,
                                ...(!day.enable && { hours: [] }),
                            })),
                        },
                    },
                }),
            );
            enqueueSnackbar(t('common.term.saveSuccess'), {
                variant: 'success',
            });
        } catch (err) {
            console.error('🚀 => handleSubmit => err', err);
            enqueueSnackbar(t('app.common.error'), {
                variant: 'error',
            });
        }
    };

    const handleTemplateChange = (template) => {
        setTemplate(template);
    };

    return (
        <>
            {organization?.id && (
                <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={() => {}}
                    className={classes.form}
                    style={{ width: '100%' }}
                >
                    <Schedule
                        classes={classes}
                        template={template}
                        onChange={handleTemplateChange}
                        timeZone={timezone}
                    />

                    <ActionButton
                        id="onlive-button-availability-save"
                        type="submit"
                        disableElevation={true}
                        className={classes.buttonSave}
                        disabled={false}
                    >
                        <span>{t('common.term.saveChanges')}</span>
                    </ActionButton>
                </ValidatorForm>
            )}
        </>
    );
};

export default OrganizationAvailability;
