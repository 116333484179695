import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material';
import useColorsStyles from 'common/colors';
import CustomTooltip from 'common/components/CustomTooltip';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import useFlexBoxStyles from 'common/flexBox';
import useFontStyles from 'common/fontStyles';
import useOnliveStyles from 'common/onliveStyles';
import { useMenuEntries } from 'components/menu/MenuEntries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUser } from 'redux/slices/authSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        menuItem: {
            height: theme.spacing(2.25),
            '& a, & a:hover': {
                color: theme.palette.secondary.contrastText,
                textDecoration: 'none',
            },
            [theme.breakpoints.down('xl')]: {
                height: theme.spacing(2),
                margin: 0,
            },
        },
        nameSpace: {
            [theme.breakpoints.down('xl')]: {
                paddingLeft: theme.spacing(1),
            },
        },
        menuItemIcon: {
            padding: theme.spacing(0.65),
            background: '#E2E2E2',
            borderRadius: '50%',
            '& svg': {
                fontSize: theme.spacing(2.5),
                color: '#000',
                stroke: '#E2E2E2',
            },
            '&.closedMenu': {
                padding: theme.spacing(0.75),
                '& svg': {
                    height: theme.spacing(2.5),
                    width: theme.spacing(2.5),
                },
            },
            [theme.breakpoints.down('xl')]: {
                padding: theme.spacing(0.6),
                '& svg': {
                    fontSize: theme.spacing(2),
                },
                '&.closedMenu': {
                    padding: theme.spacing(0.7),
                    '& svg': {
                        height: theme.spacing(2),
                        width: theme.spacing(2),
                    },
                },
            },
        },
        menuItemActive: {
            background: '#000',
            '& svg': {
                color: '#FFFFFF',
                stroke: '#000',
            },
        },

        menuItemEvent: {
            height: theme.spacing(5.5),
            '&:hover': {
                backgroundClip: 'content-box',
                background: 'rgba(0, 0, 0, 0.03)',
                cursor: 'pointer',
                '& svg': {
                    transform: 'scale(1.3)',
                },
            },
            [theme.breakpoints.up('xl')]: {
                '&:hover': {
                    '& svg': {
                        transform: 'scale(1.2)',
                    },
                },
            },
            '&.active': {
                '&:hover': {
                    cursor: 'default',
                    background: '#000',
                    color: '#FFFFFF',
                },
            },
            [theme.breakpoints.down('xl')]: {
                height: theme.spacing(4.5),
                margin: theme.spacing(0, 1, 1),
            },
        },
        nameSpaceEvent: {
            [theme.breakpoints.down('xl')]: {
                paddingLeft: theme.spacing(2),
            },
        },
        menuItemEventIcon: {
            '& svg': {
                fontSize: theme.spacing(3),
                padding: theme.spacing(0, 1),
                transition: 'transform .4s',
            },
            '&.active': {
                '& svg': {
                    color: '#FFFFFF',
                    textDecoration: 'none',
                    background: '#000',
                },
            },
            [theme.breakpoints.down('xl')]: {
                '& svg': {
                    fontSize: theme.spacing(2.5),
                    padding: theme.spacing(0, 0.75),
                },
            },
        },

        namespaceContainer: {
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.down('xl')]: {
                paddingBottom: theme.spacing(3),
            },
        },

        itemsContent: {
            margin: theme.spacing(0, 0, 8),
            [theme.breakpoints.down('xl')]: {
                margin: theme.spacing(0, 0, 5),
            },
        },
        borderMenuAccordion: {
            borderBottom: '1px solid #E9E9E9',
        },
        menuAccordion: {
            '& .MuiAccordionSummary-root': {
                padding: theme.spacing(0, 1),
                minHeight: theme.spacing(5),
                alignItems: 'flex-end',
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: theme.spacing(5),
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: theme.spacing(0.5, 0),
            },
            '& .MuiAccordionSummary-content ': {
                margin: theme.spacing(0.5, 0),
            },
            '& .MuiAccordionDetails-root': {
                padding: 0,
            },
            '&::before': {
                height: 0,
            },
        },
    };
});

const Menu = (props) => {
    const { openMenu } = props;
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: colors } = useColorsStyles();
    const { classes: font } = useFontStyles();
    const { classes: onlive } = useOnliveStyles();

    const isAuthorize = useAuthLicenseAddOns();
    const user = useSelector(getUser);
    const organization = useSelector(getOrganization);
    const { pathname } = useLocation();
    const menuEntries = useMenuEntries();
    const navigate = useNavigate();

    const removeDisabledMenu = (items) =>
        items.filter(
            (item) =>
                !(typeof item.hide === 'function' ? item.hide(user?.role) : item.hide) &&
                (!item.roles || user?.role === 'root' || item.roles.findIndex((role) => role === user?.role) !== -1) &&
                (!item.permissions ||
                    isAuthorize(item.permissions, user?.role === 'root' ? false : item.mainPermission, true)) &&
                (!item.hiddenIfProfile || !organization?.isProfile),
        );

    const menuItems = (items) =>
        removeDisabledMenu(items).map((item, idx, row) => {
            const showBottomBorder = row.length > idx + 1;
            const { icon } = item;
            return (
                <React.Fragment key={item.name}>
                    <li
                        className={cx(
                            flexBox.flex,
                            flexBox.alignItemsCenter,
                            openMenu ? cx(flexBox.padding2, flexBox.marginLeft1) : flexBox.padding20,
                            classes.menuItem,
                        )}
                    >
                        <CustomTooltip
                            arrow
                            disableHoverListener={openMenu}
                            title={t(t(item.name))}
                            placement={'right'}
                        >
                            <Link to={item.path} className={cx(flexBox.flex, flexBox.alignItemsCenter)}>
                                <Stack
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    className={cx(
                                        classes.menuItemIcon,
                                        pathname.startsWith(item.path) && classes.menuItemActive,
                                        !openMenu && 'closedMenu',
                                    )}
                                >
                                    {icon}
                                </Stack>
                                {openMenu && (
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={pathname.startsWith(item.path) ? 600 : 400}
                                        className={cx(flexBox.paddingLeft1, classes.subtitle1)}
                                    >
                                        {t(item.name)}
                                    </Typography>
                                )}
                            </Link>
                        </CustomTooltip>
                    </li>
                    {showBottomBorder && openMenu && <Divider key={item.name} />}
                </React.Fragment>
            );
        });

    const menuItemsEvents = (items) => {
        return removeDisabledMenu(items).map((item) => (
            <Stack
                direction="row"
                alignItems="center"
                aria-hidden={true}
                key={item.name}
                className={cx(
                    flexBox.borderRadius1,
                    flexBox.relative,
                    flexBox.margin02,
                    flexBox.marginBottom2,
                    flexBox.padding1,
                    onlive.boxShadowWeb,
                    classes.menuItemEvent,
                    pathname.startsWith(item.path) && cx(colors.textWhite, colors.bgBlack, 'active'),
                )}
                onClick={() => {
                    navigate(item.path);
                }}
            >
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className={cx(classes.menuItemEventIcon, pathname.startsWith(item.path) && 'active')}
                >
                    {item.icon}
                </Stack>
                <Stack direction="column" className={cx(flexBox.paddingLeft1, flexBox.overflowHidden)}>
                    <Typography variant="onliveH6" fontWeight={500} className={classes.onliveH6}>
                        {t(item.name)}
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight={400}
                        className={cx(font.wrap, !pathname.startsWith(item.path) && flexBox.opacity07, classes.body2)}
                    >
                        {t(item.subTitle)}
                    </Typography>
                </Stack>
            </Stack>
        ));
    };

    const menuEntriesToShow = menuEntries.filter(
        (namespace) =>
            !namespace?.hide &&
            ((user?.role === 'root' && user?.organizationId) ||
                (!namespace.roles && user?.role !== 'root') ||
                (namespace.roles && namespace.roles.findIndex((role) => role === user?.role) !== -1)),
    );

    return menuEntriesToShow.map((entry, index) => {
        return (
            <React.Fragment key={`menu-divider-${entry.namespace}`}>
                {openMenu && entry.collapsible ? (
                    <Accordion
                        disableGutters
                        className={cx(flexBox.boxShadowNone, colors.bgTransparent, classes.menuAccordion, {
                            [classes.borderMenuAccordion]: index + 1 !== menuEntriesToShow.length,
                        })}
                    >
                        <AccordionSummary
                            expandIcon={entry?.collapsible && <ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                        >
                            {openMenu && entry?.namespace && (
                                <Typography
                                    fontWeight="400"
                                    className={cx(
                                        flexBox.paddingLeft2,
                                        flexBox.opacity05,
                                        classes.nameSpace,
                                        classes.body1,
                                    )}
                                >
                                    {t(entry.namespace).toUpperCase()}
                                </Typography>
                            )}
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <ul className={cx(flexBox.padding0, classes.itemsContent)}>{menuItems(entry.items)}</ul>
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <div
                        className={cx(
                            openMenu && (entry.section === 'event' ? flexBox.padding2 : classes.namespaceContainer),
                        )}
                    >
                        {entry?.namespace && openMenu && (
                            <Typography
                                fontWeight="400"
                                className={cx(
                                    flexBox.paddingLeft3,
                                    flexBox.opacity05,
                                    classes.nameSpaceEvent,
                                    classes.body1,
                                )}
                            >
                                {t(entry.namespace).toUpperCase()}
                            </Typography>
                        )}
                        {entry.section === 'events' && openMenu ? (
                            <Stack direction="column" className={cx(flexBox.padding1)}>
                                {menuItemsEvents(entry.items)}
                            </Stack>
                        ) : (
                            <ul
                                className={cx(
                                    flexBox.padding0,
                                    classes.itemsContent,
                                    flexBox.flex,
                                    flexBox.column,
                                    flexBox.alignItemsCenter,
                                    flexBox.justifyContentCenter,
                                )}
                            >
                                {menuItems(entry.items)}
                            </ul>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    });
};

export default Menu;
