import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        pending: false,
        savePending: false,
        payments: [],
        default: null,
        totalPayments: 0,
        error: null,
        paymentDetailsDialog: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setPayments: (state, action) => {
            state.pending = false;
            state.payments = action.payload;
        },
        setDefaultPayment: (state, action) => {
            state.default = action.payload;
        },
        setTotalPayments: (state, action) => {
            state.totalPayments = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.payments = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addPayment: (state, action) => {
            state.savePending = false;
            const newPayments = [action.payload, ...state.payments];
            state.payments = newPayments;
        },
        editPayment: (state, action) => {
            state.savePending = false;
            const index = state.payments.findIndex((payment) => payment.id === action.payload.id);
            if (index !== -1) {
                state.payments = [
                    ...state.payments.slice(0, index),
                    action.payload,
                    ...state.payments.slice(index + 1),
                ];
            }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.paymentId = action.payload;
        },
        deletePayment: (state, action) => {
            state.deletePending = false;
            const index = state.payments.findIndex((payment) => payment.id === action.payload.id);
            if (index !== -1) {
                state.payments = [...state.payments.slice(0, index), ...state.payments.slice(index + 1)];
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        openPaymentDetailsDialog: (state, action) => {
            state.paymentDetailsDialog = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setPayments,
    setDefaultPayment,
    setTotalPayments,
    setError,
    startSave,
    addPayment,
    editPayment,
    setSaveError,
    startDelete,
    deletePayment,
    setDeleteError,
    openPaymentDetailsDialog,
    stopSave,
} = paymentSlice.actions;

export const getPayments = (state) => state.payment.payments;
export const getDefaultPayment = (state) => state.payment.default;
export const getTotalPayments = (state) => state.payment.totalPayments;
export const getPaymentsPending = (state) => state.payment.pending;
export const getPaymentsSavePending = (state) => state.payment.savePending;
export const getPaymentsDeletePending = (state) => state.payment.deletePending;
export const getPaymentsError = (state) => state.payment.error;
export const getPaymentId = (state) => state.payment.paymentId;
export const getOpenedPaymentDetails = (state) => state.payment.paymentDetailsDialog;

export const fetchPaymentsAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('payment-methods' + query)
            .then((response) => {
                dispatch(setPayments(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchDefaultPaymentAction = () => async (dispatch) => {
    try {
        api.get('payment-methods/default')
            .then((response) => {
                dispatch(setDefaultPayment(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const createPaymentMethodAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('payment-methods', data)
                .then((response) => {
                    dispatch(addPayment(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setSaveError(error));
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deletePaymentAction = (paymentId) => async (dispatch) => {
    try {
        dispatch(startDelete(paymentId));
        api.delete('payment-methods/' + paymentId)
            .then((response) => {
                dispatch(deletePayment(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};


export const selectDefaultPaymentAction = (paymentMethodId) => async (dispatch) => {
    try {
        api.put('payment-methods/default', { paymentMethodId })
            .then((response) => {
                dispatch(setDefaultPayment(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default paymentSlice.reducer;
