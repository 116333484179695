import { Add } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormHelperText, Stack, Typography } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import CustomTooltip from 'common/components/CustomTooltip';
import { useFormFields } from 'libs/hooksLib';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import ActionButton from './ActionButton';

const useStyles = makeStyles()((theme) => ({
    mW12: {
        minWidth: theme.spacing(14),
    },
}));

const DateRange = ({
    date,
    onDelete,
    addDate,
    onChange,
    disabled,
    error,
    isDates,
    showAddForm,
    setShowAddForm,
    type = 'date',
}) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const [fields, handleFieldChange, resetForm] = useFormFields({
        start: date?.start || '',
        end: date?.end || '',
    });

    const handleChange = (e) => {
        handleFieldChange(e);
        onChange?.(
            e.target.name === 'start'
                ? { start: e.target.value, end: fields.end }
                : { start: fields.start, end: e.target.value },
            () => {
                if (!addDate) {
                    resetForm({ start: '', end: '' });
                }
            },
        );
    };

    const handleAdd = () => {
        addDate?.(fields);
        resetForm({ start: '', end: '' });
    };

    const handleDelete = () => {
        onDelete?.();
        if (!date) {
            resetForm({ start: '', end: '' });
        }
    };

    return (
        <Stack direction="column" spacing={0.5}>
            {!isDates || showAddForm ? (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <CustomTextValidator
                        name="start"
                        className={classes.mW12}
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={fields.start}
                        onChange={handleChange}
                        disabled={disabled}
                        type={type}
                        InputProps={{
                            inputProps: {
                                shrink: true,
                            },
                        }}
                        validators={['required']}
                    />

                    <Typography variant="body1">{t('services.form.dateTo')}</Typography>

                    <CustomTextValidator
                        name="end"
                        className={classes.mW12}
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={fields.end}
                        onChange={handleChange}
                        disabled={disabled || !fields.start}
                        type={type}
                        InputProps={{
                            inputProps: {
                                shrink: true,
                                min: fields.start,
                            },
                        }}
                        validators={['required']}
                    />

                    {onDelete ? (
                        <CustomTooltip arrow title={t('contact.remove')} placement="bottom">
                            <CustomIconButton
                                aria-label="delete"
                                onClick={handleDelete}
                                size="small"
                                disabled={(!fields.start && !fields.end) || disabled}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </CustomIconButton>
                        </CustomTooltip>
                    ) : null}

                    {addDate ? (
                        <CustomTooltip arrow title={t('calendar.common.add')} placement="bottom">
                            <CustomIconButton
                                size="small"
                                onClick={handleAdd}
                                disabled={
                                    !fields.start ||
                                    !fields.end ||
                                    fields.start > fields.end ||
                                    disabled ||
                                    Boolean(error)
                                }
                            >
                                <Add />
                            </CustomIconButton>
                        </CustomTooltip>
                    ) : null}
                </Stack>
            ) : null}

            {isDates && !showAddForm ? (
                <ActionButton
                    fullWidth={true}
                    color="secondary"
                    disableElevation={true}
                    onClick={() => setShowAddForm(true)}
                >
                    <Add />
                    <span>{t('calendar.common.add')}</span>
                </ActionButton>
            ) : null}

            {error ? (
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <FormHelperText error>{error}</FormHelperText>
                </Stack>
            ) : null}
        </Stack>
    );
};

export default DateRange;
