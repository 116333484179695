import KeyOffOutlinedIcon from '@mui/icons-material/KeyOffOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import { Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import useFlexBoxStyles from 'common/flexBox';
import LayoutForm from 'common/layouts/LayoutForm';
import { QRCodeCanvas } from 'qrcode.react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cancelCloseCustomDialogAction, closeCustomDialogAction } from 'redux/slices/customDialogSlice';
import 'suneditor/dist/css/suneditor.min.css';

const AuthenticatorForm = ({ data, onConfirm }) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const [showKey, setShowKey] = useState(false);
    const dispatch = useDispatch();
    const cancelConfirmTitle = t('security.authenticator.closeConfirm');
    const cancelConfirmMessage = t('security.authenticator.closeMessage');
    const [openCancelDlg, setOpenCancelDlg] = useState(false);

    const finalCancel = () => {
        dispatch(closeCustomDialogAction());
    };

    const cancelDlg = () => {
        setOpenCancelDlg(false);
        dispatch(cancelCloseCustomDialogAction());
    };

    const close = (event) => {
        event.preventDefault();
        setOpenCancelDlg(true);
    };

    return (
        <LayoutForm
            title={t('security.authenticator.form.title')}
            confirmDlg={{
                open: openCancelDlg,
                accept: finalCancel,
                cancel: cancelDlg,
                titleDlg: cancelConfirmTitle,
                messageDlg: cancelConfirmMessage,
            }}
        >
            <Stack direction="column" spacing={2} className={flexBox.overflowHidden}>
                <Stack direction="column" spacing={2} className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone)}>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {t('security.authenticator.form.message')}
                    </Typography>
                    {/* <ul>
                        <li>
                            <Typography variant="subtitle1" fontWeight={400}>
                                {t('security.authenticator.form.step1')} <strong>+</strong>.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="subtitle1" fontWeight={400}>
                                {t('security.authenticator.form.step2')}{' '}
                                <strong>{t('security.authenticator.form.step2A')}</strong>.
                            </Typography>
                        </li>
                    </ul> */}

                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        className={flexBox.paddingTop4}
                    >
                        <QRCodeCanvas value={data?.totpKeyUri} />

                        {showKey ? (
                            <Typography variant="subtitle1" fontWeight={600}>
                                {data?.key}
                            </Typography>
                        ) : null}

                        <ActionButton disabled={false} onClick={() => setShowKey(!showKey)} color="primary" fitWidth>
                            {showKey ? <KeyOffOutlinedIcon /> : <KeyOutlinedIcon />}
                            {showKey
                                ? t('security.authenticator.form.hideKey')
                                : t('security.authenticator.form.showKey')}
                        </ActionButton>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} justifyContent="flex-end" className={flexBox.paddingTop4}>
                    <ActionButton disabled={false} onClick={close} color="default" fitWidth>
                        {t('common.customPopover.closeIcon')}
                    </ActionButton>
                    <ActionButton disabled={false} onClick={onConfirm} color="primary" fitWidth>
                        {t('profile.profile.closeConfirm')}
                    </ActionButton>
                </Stack>
            </Stack>
        </LayoutForm>
    );
};

export default AuthenticatorForm;
