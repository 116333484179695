import { createSlice } from '@reduxjs/toolkit';

export const discountSlice = createSlice({
    name: 'discount',
    initialState: {
        pending: false,
        discounts: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        resetDiscounts: (state, action) => {
            state.discounts = [];
        },
        setDiscounts: (state, action) => {
            state.pending = false;
            state.discounts = action.payload;
        },
        addDiscount: (state, action) => {
            state.discounts = [...state.discounts, action.payload];
        },
        deleteDiscount: (state, action) => {
            const index = action.payload;
            state.discounts = [
                ...state.discounts.slice(0, index),
                ...state.discounts.slice(index + 1, state.discounts.length),
            ];
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
    },
});

export const { start, resetDiscounts, setDiscounts, addDiscount, deleteDiscount, setError } = discountSlice.actions;

export const getDiscounts = (state) => state.discount.discounts;
export const getDiscountsPending = (state) => state.discount.pending;
export const getDiscountsError = (state) => state.discount.error;

export default discountSlice.reducer;
