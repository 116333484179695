import { Skeleton, TableCell, TableRow } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { range } from 'lodash';

const TableSkeleton = (props) => {
    const { columnNumb } = props;
    const { classes: flexBox } = useFlexBoxStyles();

    return (
        <TableRow>
            {range(1, columnNumb, 1).map((i) => (
                <TableCell key={i} classes={{ root: flexBox.padding2 }} style={{ border: 'none' }}>
                    <Skeleton variant="text"></Skeleton>
                </TableCell>
            ))}
        </TableRow>
    );
};

export default TableSkeleton;
