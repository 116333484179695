import { FormGroup, FormLabel, Grid, Stack } from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useFlexBoxStyles from 'common/flexBox';
import { useSettingValue } from 'common/hooks/useSettingValue';
import LayoutForm from 'common/layouts/LayoutForm';
import { useFormFields } from 'libs/hooksLib';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailabilities } from 'redux/slices/myAvailabilitySlice';
import { getNestedDialogStateData } from 'redux/slices/nestedDialogSlice';
import { fetchServicesAction, getServices, getServicesSavePending } from 'redux/slices/serviceSlice';
import DateOverride from '../components/DateOverride';
import OutOfTheOffice from '../components/OutOfTheOffice';
import WorkingHours from '../components/WorkingHours';
import MyAvailabilityFormTemplates from './MyAvailabilityFormTemplates';

const mockConfiguration = {
    workingHours: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
    dateOverrides: {},
    outsOfTheOffice: [],
};

function getUniqueTemplatesWeeklySchedule(objects, myAvId) {
    const allTemplates = objects.flatMap((obj) => (obj.id !== myAvId ? obj.templatesWeeklySchedule : []));

    const uniqueTemplates = [];
    const ids = new Set();

    allTemplates.forEach((temp) => {
        if (!ids.has(temp.template.id)) {
            ids.add(temp.template.id);
            uniqueTemplates.push(temp.template);
        }
    });

    return uniqueTemplates;
}

const MyAvailabilityForm = (props) => {
    const { title, availability, onSubmit, onClose, userGroupId } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const allServices = useSelector(getServices);
    const availabilities = useSelector(getAvailabilities);
    const savePending = useSelector(getServicesSavePending);
    const nestedDialogStateData = useSelector(getNestedDialogStateData);
    const timeZone = useSettingValue(SettingsKey.TIMEZONE) || 'Europe/Madrid';
    const [selectedServices, setSelectedServices] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        dispatch(
            fetchServicesAction({
                queryParams: { expand: ['relatedCalendars', 'dateRanges'] },
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (availabilities) {
            let newServices = [];
            const availabilitiesServices = getUniqueTemplatesWeeklySchedule(availabilities, availability?.id);
            newServices = allServices.filter(
                (service) =>
                    !availabilitiesServices.some((aService) => aService.id === service.id) &&
                    (userGroupId ? service.userGroupId === userGroupId : true),
            );

            setServices(newServices);
        }
    }, [allServices, availabilities, availability?.id, userGroupId]);

    useEffect(() => {
        if (availability?.templatesWeeklySchedule) {
            const templates = availability.templatesWeeklySchedule.map((temp) => {
                const newService = allServices?.find((service) => service.id === temp.template.id) || {};
                return {
                    ...temp.template,
                    ...newService,
                };
            });
            setSelectedServices(templates);
        }
    }, [allServices, availability?.templatesWeeklySchedule]);

    const initialForm = useMemo(() => {
        return {
            name: availability?.name || '',
            configuration: availability?.configuration || mockConfiguration,
        };
    }, [availability?.configuration, availability?.name]);

    const [fields, handleFieldChange, resetForm] = useFormFields(nestedDialogStateData || initialForm);

    function cancel(e) {
        e.preventDefault();
        resetForm(initialForm);
        onClose();
    }

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(
            {
                ...fields,
                timezone: timeZone,
                templateIds: selectedServices.map((s) => s.id),
            },
            () => {
                resetForm(initialForm);
            },
            (error) => {
                if (error?.response?.data?.description) {
                    enqueueSnackbar(error.response?.data?.description, {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(t('app.common.error'), {
                        variant: 'error',
                    });
                }
            },
        );
    }

    return (
        <LayoutForm
            size="big"
            title={title}
            customClasses={flexBox.vH70}
            // confirmDlg={{
            //     open: openCancelDlg,
            //     accept: finalCancel,
            //     cancel: cancelDlg,
            //     titleDlg: confirmTitle,
            //     messageDlg: confirmMessage,
            // }}
        >
            <Stack direction="column" spacing={2} className={cx(flexBox.overflowHidden, flexBox.h100)}>
                <Stack direction="column" spacing={3} className={cx(flexBox.overflowHidden, flexBox.h100)}>
                    <ValidatorForm
                        onSubmit={() => {}}
                        onError={(e) => {}}
                        className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden, flexBox.h100)}
                    >
                        <Stack
                            direction="column"
                            className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone, flexBox.h100)}
                        >
                            <Grid container spacing={10}>
                                <Grid item md={6} sm={12}>
                                    <Stack direction="column" spacing={3}>
                                        <FormGroup>
                                            <FormLabel>{t('profile.myAvailability.form.title')}</FormLabel>
                                            <CustomTextValidator
                                                name="name"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                autoComplete="new-name"
                                                value={fields.name}
                                                onChange={handleFieldChange}
                                                validators={['maxStringLength: 100']}
                                                errorMessages={[t('profile.myAvailability.form.titleMaxLength')]}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <WorkingHours fields={fields} handleFieldChange={handleFieldChange} />
                                        </FormGroup>
                                    </Stack>
                                </Grid>

                                <Grid item md={6} sm={12}>
                                    <Stack direction="column" spacing={3}>
                                        <MyAvailabilityFormTemplates
                                            services={services}
                                            selectedServices={selectedServices}
                                            setSelectedServices={setSelectedServices}
                                        />

                                        <FormGroup>
                                            <FormLabel>{t('calendar.setOutOfTheOffice')}</FormLabel>
                                            <OutOfTheOffice
                                                fields={fields}
                                                handleFieldChange={handleFieldChange}
                                                timeZone={timeZone}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <DateOverride fields={fields} handleFieldChange={handleFieldChange} />
                                        </FormGroup>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </ValidatorForm>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    className={flexBox.paddingTop2}
                >
                    <ActionButton onClick={cancel} color="default" fitWidth>
                        {t('app.common.cancel')}
                    </ActionButton>

                    <ActionButton color="primary" autoFocus fitWidth disabled={savePending} onClick={handleSubmit}>
                        {t('app.common.save')}
                    </ActionButton>
                </Stack>
            </Stack>
        </LayoutForm>
    );
};

export default MyAvailabilityForm;
