import CircularProgress from '@mui/material/CircularProgress';
import ActionButton from './ActionButton';
import useStylesLoadingButton from './styles/useStylesLoadingButton.styles';

const LoadingActionButton = (props) => {
    const { loading, disabled, handleButtonClick, color, variant, label, style, loaderColor } = props;

    const { classes } = useStylesLoadingButton();

    return (
        <div>
            <ActionButton
                variant={variant}
                color={color}
                disabled={disabled || loading}
                onClick={handleButtonClick}
                style={style || {}}
            >
                {label}
            </ActionButton>
            {loading && (
                <CircularProgress size={24} color={loaderColor || 'primary'} className={classes.buttonProgress} />
            )}
        </div>
    );
};

export default LoadingActionButton;
