import { useSelector } from 'react-redux';


import { getOrganization } from 'redux/slices/organizationSlice';
import useStylesLogo from './styles/useStylesLogo.styles';

const Logo = (props) => {
    const { onClick } = props;
    const organization = useSelector(getOrganization);
    const { classes, cx } = useStylesLogo();

    return (
        <div className={cx(classes.logo)}>
            <img src={organization.logo} alt="logo" onClick={onClick} style={onClick && { cursor: 'pointer' }} />
        </div>
    );
};

export default Logo;
