import { Help } from '@mui/icons-material';
import { MenuList } from '@mui/material';
import Paper from '@mui/material/Paper';
import useFlexBoxStyles from 'common/flexBox';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import config from 'config';
import CustomPopover from '../../components/PopoversAndDialogs/CustomPopover';
import SettingsLang from './components/SettingsLang';
import SettingsMenuItem from './components/SettingsMenuItem';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minWidth: theme.spacing(20),
        },
        icon: {
            height: theme.spacing(2),
            width: theme.spacing(2),
        },
    };
});

const SettingsMenu = (props) => {
    const { open, handleOnClose, anchorEl } = props;
    const { classes, cx } = useStyles();

    const { classes: flexBox } = useFlexBoxStyles();
    const { t } = useTranslation();
    const [anchorElLang, setAnchorElLang] = useState(null);
    const [settingsLangOpen, setSettingsLangOpen] = useState(false);

    const handleListKeyDown = useCallback(
        (event) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                handleOnClose();
            }
        },
        [handleOnClose],
    );

    const handleLangClicked = (event) => {
        setAnchorElLang(event.currentTarget);
        setSettingsLangOpen(true);
    };

    const handleSettingsLangOnClose = () => {
        setSettingsLangOpen(false);
        setAnchorElLang(null);
    };

    const openHelp = () => {
        handleOnClose();
        window.open(config.helpUrl.onlive, '_blank', 'noopener,noreferrer');
    };

    return (
        <React.Fragment>
            <CustomPopover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                hideCloseButton={true}
                anchorEl={anchorEl}
                open={open}
                handleOnClose={handleOnClose}
            >
                <Paper className={cx(flexBox.borderRadius2, flexBox.overflowHidden, classes.root)}>
                    <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        className={flexBox.padding0}
                    >
                        <SettingsMenuItem
                            id="onlive-menu-item-setting-language"
                            submenu={true}
                            onClick={handleLangClicked}
                            selected={settingsLangOpen}
                        >
                            {t('common.features.settingsMenu.settingsMenu.language')}
                        </SettingsMenuItem>
                        <SettingsMenuItem id="onlive-menu-item-setting-language" onClick={openHelp}>
                            <span className={cx(flexBox.flex, flexBox.alignItemsCenter)}>
                                <Help className={cx(flexBox.marginRight05, classes.icon)} />
                                {t('common.features.settingsMenu.settingsMenu.helpVideos')}
                            </span>
                        </SettingsMenuItem>
                    </MenuList>
                </Paper>
            </CustomPopover>

            <SettingsLang open={settingsLangOpen} handleOnClose={handleSettingsLangOnClose} anchorEl={anchorElLang} />
        </React.Fragment>
    );
};

export default SettingsMenu;
