import { makeStyles } from 'tss-react/mui';

const useStylesCustomAutocomplete = makeStyles()((theme) => {
    return {
        root: {
            '& input': {
                    paddingLeft: theme.spacing(1),
                    paddingTop: '0 !important',
                    fontSize: '0.75rem',
                    fontWeight: '500',
                    position: 'relative',
                    '&:focus': {
                        borderRadius: 5,
                    },
                    [theme.breakpoints.up('xl')]: {
                        fontSize: '14px',
                        height: '26px',
                    },
            }
        },
    };
});

export default useStylesCustomAutocomplete;
