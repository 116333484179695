import { Grid, Stack } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import DateRange from 'common/components/DateRange';
import useFlexBoxStyles from 'common/flexBox';
import { useIncludeDateValidation } from 'common/hooks/useIncludeDateValidation';
import LayoutForm from 'common/layouts/LayoutForm';
import { useFormFields } from 'libs/hooksLib';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { closeNestedDialogAction } from 'redux/slices/nestedDialogSlice';

const transformDates = (dates) => {
    return dates.map((dateRange) => ({
        startDate: dateRange[0],
        endDate: dateRange[1],
    }));
};

const DateOverrideItem = (props) => {
    const { onSubmit } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const [showAddForm, setShowAddForm] = useState(false);
    const { isValidHourRange, error, validationType, setError } = useIncludeDateValidation();

    const [fields, handleFieldChange] = useFormFields({
        date: '',
        overrides: [],
    });

    const handleEditDate = (newDate, idx) => {
        const newRange = [
            ...fields.overrides.slice(0, idx),
            [newDate.start, newDate.end],
            ...fields.overrides.slice(idx + 1),
        ];
        saveRange(newRange);
    };

    const handleAddDate = (newDate) => {
        const newRange = [...fields.overrides, [newDate.start, newDate.end]];
        saveRange(newRange);
    };

    const handleDeleteDate = (idx) => {
        deleteError(idx);
        const newRange = [...fields.overrides.slice(0, idx), ...fields.overrides.slice(idx + 1)];
        saveRange(newRange);
    };

    const deleteError = (idx) => {
        if (error?.idx === idx) {
            setError(null);
        }
    };

    const saveRange = (data) => {
        handleFieldChange({
            target: {
                name: 'overrides',
                value: data,
            },
        });
    };

    const onClose = () => {
        dispatch(closeNestedDialogAction());
    };

    const handleSubmit = () => {
        onSubmit({ ...fields }, () => onClose());
    };

    const handleChange = (date, idx, callback) => {
        const ranges = transformDates(fields.overrides);
        const isValid = isValidHourRange({ ranges, date, idx, type: validationType.UNSELECTED });
        if (isValid) {
            if (idx === -1) {
                handleAddDate(date);
                setShowAddForm(false);
                callback?.();
            } else {
                handleEditDate(date, idx);
            }
        }
    };

    return (
        <LayoutForm
            size="small"
            title={t('calendar.addADateOverride').toUpperCase()}
            // customClasses={flexBox.vH70}
            // confirmDlg={{
            //     open: openCancelDlg,
            //     accept: finalCancel,
            //     cancel: cancelDlg,
            //     titleDlg: confirmTitle,
            //     messageDlg: confirmMessage,
            // }}
        >
            <Stack direction="column" spacing={2} className={cx(flexBox.overflowHidden, flexBox.h100)}>
                <Stack direction="column" spacing={3} className={cx(flexBox.overflowHidden, flexBox.h100)}>
                    <ValidatorForm
                        onSubmit={() => {}}
                        onError={() => {}}
                        className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden, flexBox.h100)}
                    >
                        <Stack
                            direction="column"
                            className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone, flexBox.h100)}
                        >
                            <Grid container xs={12}>
                                <Grid item xs={3} alignItems="center" className={flexBox.paddingRight1}>
                                    <CustomTextValidator
                                        name="date"
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        value={fields.dates}
                                        onChange={handleFieldChange}
                                        type="date"
                                        InputProps={{
                                            inputProps: {
                                                shrink: true,
                                                min: moment().format('YYYY-MM-DD'),
                                            },
                                        }}
                                        validators={['required']}
                                        errorMessages={[t('license.licenseForm.emailRequired')]}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Stack direction="column" spacing={1}>
                                        {fields.overrides?.map((date, idx) => (
                                            <Stack direction="column" key={idx}>
                                                <DateRange
                                                    type="time"
                                                    date={{ start: date[0], end: date[1] }}
                                                    onChange={(date) => handleChange(date, idx)}
                                                    onDelete={() => handleDeleteDate(idx)}
                                                    error={error?.idx === idx ? error?.msg : null}
                                                    disabled={error ? error?.idx !== idx : false}
                                                />
                                            </Stack>
                                        ))}

                                        <DateRange
                                            type="time"
                                            isDates={fields.overrides?.length}
                                            showAddForm={showAddForm}
                                            setShowAddForm={setShowAddForm}
                                            onChange={(date, callback) => handleChange(date, -1, callback)}
                                            onDelete={() => deleteError(-1)}
                                            error={error?.idx === -1 ? error?.msg : null}
                                            disabled={error ? error?.idx !== -1 : !fields.date}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </ValidatorForm>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    className={flexBox.paddingTop2}
                >
                    <ActionButton onClick={onClose} color="default" fitWidth>
                        {t('app.common.cancel')}
                    </ActionButton>

                    <ActionButton
                        color="primary"
                        autoFocus
                        fitWidth
                        disabled={fields.overrides.length === 0 || error}
                        onClick={handleSubmit}
                    >
                        {t('app.common.save')}
                    </ActionButton>
                </Stack>
            </Stack>
        </LayoutForm>
    );
};

export default DateOverrideItem;
