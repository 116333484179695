import { FormControl, FormGroup, FormLabel, TextField } from '@mui/material';
import OCalendar from 'app/ocalendar/OCalendar';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import CustomSunEditor from 'common/components/CustomSunEditor';
import CustomTextValidator from 'common/components/CustomTextValidators';
import FrontImage from 'common/components/FrontImage';
import ProfileImage from 'common/components/ProfileImage';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { useAbortController } from 'common/hooks';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { handleUploadFileSignedUrl } from 'common/utils/file';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUserAction } from 'redux/slices/authSlice';
import { getMyAccountSettingsDirty, setMyAccountSettingsDirty } from 'redux/slices/profileSlice';
import 'suneditor/dist/css/suneditor.min.css';
import { useStylesMyAccount } from './styles/useStylesMyAccount';

const MyAccount = () => {
    const { t } = useTranslation();
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2, cx } = useStylesMyAccount();
    const classes = { ...classes1, ...classes2 };
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const isFormDirty = useSelector(getMyAccountSettingsDirty);
    const { enqueueSnackbar } = useSnackbar();
    const [uploadingProfileImage, setUploadingProfileImage] = useState(false);
    const [about, setAbout] = useState(user?.about);
    const newCalendar = useSettingValue(SettingsKey.NEW_CALENDAR) ?? false;
    const getSignal = useAbortController();
    const isAuthorize = useAuthLicenseAddOns();
    const [sunError, setSunError] = useState(false);

    const [values, setValues] = useState({
        avatar: user?.avatar,
        submited: false,
        isUpdating: false,
        firstName: user?.firstName,
        lastName: user?.lastName,
        username: user?.username,
        headline: user?.headline,
        frontPage: user?.frontPage,
    });

    const readOnly = false;

    const editorConfig = {
        buttonList: [
            readOnly
                ? ['fullScreen', 'codeView', 'preview']
                : [
                      'undo',
                      'redo',
                      'font',
                      'fontSize',
                      'formatBlock',
                      'paragraphStyle',
                      'blockquote',
                      'bold',
                      'underline',
                      'italic',
                      'strike',
                      'subscript',
                      'superscript',
                      'fontColor',
                      'hiliteColor',
                      'textStyle',
                      'removeFormat',
                      'outdent',
                      'indent',
                      'align',
                      'horizontalRule',
                      'list',
                      'lineHeight',
                      'link',
                      'fullScreen',
                      'showBlocks',
                      'codeView',
                      'preview',
                  ],
        ],
        height: 300,
    };

    const setAvatar = (imageValue) => {
        setValues({
            ...values,
            avatar: imageValue,
        });
        dispatch(setMyAccountSettingsDirty(true));
    };

    const setFrontPage = (imageValue) => {
        setValues({
            ...values,
            frontPage: imageValue,
        });
        dispatch(setMyAccountSettingsDirty(true));
    };

    useEffect(() => {
        setValues((currentValues) => ({
            ...currentValues,
            avatar: user?.avatar,
            firstName: user?.firstName,
            lastName: user?.lastName,
            username: user?.username,
            headline: user?.headline,
            frontPage: user?.frontPage,
        }));
        setAbout(user?.about);
    }, [user]);

    const getDto = () => {
        return {
            ...user,
            name: values.firstName + ' ' + values.lastName,
            firstName: values.firstName,
            lastName: values.lastName,
            username: values?.username,
            about: about,
            headline: values?.headline,
            avatar: values.avatar,
            frontPage: values.frontPage,
        };
    };

    const uploadImage = (file, setProgress, setUploading, callback) => {
        handleUploadFileSignedUrl({
            file,
            size: 1024 * 1024 * 100, // 100MB
            onInit: (size) => {
                setProgress(0);
                setUploading(true);
            },
            onProgress: (loaded, total) => {
                setProgress(Math.min(100, Math.round((loaded / total) * 100)));
            },
            onComplete: (url) => {
                setUploading(false);
                callback(url);
            },
            onError: (error) => {
                setUploading(false);
                if (error.code !== 'ERR_CANCELED') {
                    enqueueSnackbar(t('app.common.uploadError'), {
                        variant: 'error',
                    });
                }
            },
            bucketType: 'resources',
            signal: getSignal(),
        });
    };

    const updateAccount = async () => {
        setValues({
            ...values,
            isUpdating: true,
            submited: false,
        });

        const userDto = getDto();
        dispatch(
            setUserAction({
                user: userDto,
                onSuccess: () => {
                    enqueueSnackbar(t('profile.organization.saveSuccess'), { variant: 'success' });
                    setValues({
                        ...values,
                        isUpdating: false,
                        submited: true,
                    });

                    dispatch(setMyAccountSettingsDirty(false));
                },
                onFailure: () => {
                    enqueueSnackbar(t('app.common.error'), { variant: 'error' });
                    setValues({
                        ...values,
                        isUpdating: false,
                        submited: true,
                    });

                    dispatch(setMyAccountSettingsDirty(false));
                },
            }),
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateAccount();
    };

    const aboutChange = (value) => {
        dispatch(setMyAccountSettingsDirty(true));
        setAbout(value);
    };

    const descriptionError = false;

    const onUploading = (uploading) => {
        setUploadingProfileImage(uploading);
    };
    useEffect(() => {
        ValidatorForm.addValidationRule('whiteSpaces', (value) => {
            const regex = /^\s+$/g;
            return !value?.match(regex);
        });
        return () => {
            ValidatorForm.removeValidationRule('whiteSpaces');
        };
    }, []);

    useEffect(() => {
        ValidatorForm.addValidationRule('validateName', (value) => {
            const regExName = /^[a-zA-Z0-9ñáéíóú ]+$/;
            return Boolean(value.match(regExName));
        });
        return () => {
            ValidatorForm.removeValidationRule('validateName');
        };
    }, []);

    function handleError() {
        enqueueSnackbar(t('product.productFormVariants.formError'), {
            variant: 'error',
        });
    }

    return (
        <div className={classes.root}>
            <div className={cx(classes.container, classes.scroll)}>
                <div className={cx(classes.body, classes.mW80)}>
                    <ValidatorForm onSubmit={handleSubmit} onError={handleError}>
                        <ProfileImage
                            image={values.avatar}
                            setImage={setAvatar}
                            submitted={values.submitted}
                            uploadImage={uploadImage}
                            onUploading={onUploading}
                            required={false}
                        />

                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.myAccount.name')}</FormLabel>
                            <CustomTextValidator
                                name="firstName"
                                value={values.firstName}
                                variant="outlined"
                                onChange={(e) => {
                                    dispatch(setMyAccountSettingsDirty(true));
                                    setValues({ ...values, firstName: e.target.value });
                                }}
                                fullWidth={true}
                                size="small"
                                validators={['required', 'whiteSpaces', 'validateName']}
                                errorMessages={[
                                    t('license.licenseForm.emailRequired'),
                                    t('license.licenseForm.emailRequired'),
                                    t('myAccount.formValidator.textFormat'),
                                ]}
                            />
                        </FormGroup>

                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.myAccount.lastName')}</FormLabel>
                            <CustomTextValidator
                                name="lastName"
                                value={values.lastName}
                                onChange={(e) => {
                                    dispatch(setMyAccountSettingsDirty(true));
                                    setValues({ ...values, lastName: e.target.value });
                                }}
                                variant="outlined"
                                fullWidth={true}
                                size="small"
                                validators={['whiteSpaces', 'validateName']}
                                errorMessages={[
                                    t('license.licenseForm.emailRequired'),
                                    t('myAccount.formValidator.textFormat'),
                                ]}
                            />
                        </FormGroup>

                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.myAccount.username')}</FormLabel>
                            <CustomTextValidator
                                name="username"
                                value={values.username}
                                onChange={(e) => {
                                    dispatch(setMyAccountSettingsDirty(true));
                                    setValues({ ...values, username: e.target.value });
                                }}
                                variant="outlined"
                                fullWidth={true}
                                size="small"
                                validators={['required', 'whiteSpaces']}
                                errorMessages={[
                                    t('license.licenseForm.emailRequired'),
                                    t('license.licenseForm.emailRequired'),
                                ]}
                            />
                        </FormGroup>

                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.myAccount.about')}</FormLabel>
                            <FormControl
                                className={cx(classes.formControl, descriptionError && classes.descriptionError)}
                            >
                                <CustomSunEditor
                                    readOnly={readOnly}
                                    defaultValue={about}
                                    setOptions={editorConfig}
                                    sunError={setSunError}
                                    onChange={aboutChange}
                                />
                            </FormControl>
                        </FormGroup>

                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.myAccount.headline')}</FormLabel>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    name="headline"
                                    value={values.headline}
                                    className={classes.textField}
                                    onChange={(e) => {
                                        dispatch(setMyAccountSettingsDirty(true));
                                        setValues({ ...values, headline: e.target.value });
                                    }}
                                    variant="outlined"
                                    size="small"
                                    rows={3}
                                    multiline={true}
                                />
                            </FormControl>
                        </FormGroup>

                        <FrontImage
                            image={values.frontPage}
                            setImage={setFrontPage}
                            submitted={values.submitted}
                            uploadImage={uploadImage}
                            required={false}
                            label={t('profile.myAccount.frontPage')}
                        />

                        {newCalendar && isAuthorize(['oneToOne.iCall']) && !isAuthorize(['oneToOne.iCalendar']) && (
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('common.term.Calendar')}</FormLabel>
                                <OCalendar isReadOnly={false} />
                            </FormGroup>
                        )}

                        {isFormDirty && (
                            <ActionButton
                                type="submit"
                                disableElevation={true}
                                spinner={uploadingProfileImage}
                                disabled={uploadingProfileImage || sunError}
                                className={cx(classes.proceedButton, classes.buttonSave)}
                            >
                                <span>{t('profile.myAccount.save')}</span>
                            </ActionButton>
                        )}
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
