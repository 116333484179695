import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CustomPopover from 'common/components/PopoversAndDialogs/CustomPopover';
import useFlexBoxStyles from 'common/flexBox';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage, setLanguage } from 'redux/slices/settingSlice';

const langList = [
    {
        value: 'es',
        title: 'common.features.settingsMenu.settingsLang.es',
        subTitle: 'Español',
    },
    {
        value: 'en',
        title: 'common.features.settingsMenu.settingsLang.en',
        subTitle: 'English',
    },
    {
        value: 'fr',
        title: 'common.features.settingsMenu.settingsLang.fr',
        subTitle: 'Français',
    },
    {
        value: 'it',
        title: 'common.features.settingsMenu.settingsLang.it',
        subTitle: 'Italiano',
    },
    {
        value: 'pt',
        title: 'common.features.settingsMenu.settingsLang.pt',
        subTitle: 'Português',
    },
    {
        value: 'pt-br',
        title: 'common.features.settingsMenu.settingsLang.ptBr',
        subTitle: 'Português (Brasil)',
    },
    {
        value: 'de',
        title: 'common.features.settingsMenu.settingsLang.de',
        subTitle: 'Deutsch',
    },
    {
        value: 'pl',
        title: 'common.features.settingsMenu.settingsLang.pl',
        subTitle: 'Polskil',
    },
    {
        value: 'uk',
        title: 'common.features.settingsMenu.settingsLang.uk',
        subTitle: 'Yкраїнська',
    },
    {
        value: 'ro',
        title: 'common.features.settingsMenu.settingsLang.ro',
        subTitle: 'Română',
    },
    {
        value: 'sr',
        title: 'common.features.settingsMenu.settingsLang.sr',
        subTitle: 'Српски',
    },
    {
        value: 'nl-be',
        title: 'common.features.settingsMenu.settingsLang.nlBe',
        subTitle: 'Nederland - België',
    },
    {
        value: 'nl',
        title: 'common.features.settingsMenu.settingsLang.nl',
        subTitle: 'Nederland',
    },
    {
        value: 'sq',
        title: 'common.features.settingsMenu.settingsLang.sq',
        subTitle: 'Albanian',
    },
    {
        value: 'mk',
        title: 'common.features.settingsMenu.settingsLang.mk',
        subTitle: 'Macedonian',
    },
    {
        value: 'sv',
        title: 'common.features.settingsMenu.settingsLang.sv',
        subTitle: 'Svenska',
    },
];

const SettingsLang = (props) => {
    const { open, handleOnClose, anchorEl } = props;

    const { t, i18n } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const dispatch = useDispatch();

    const language = useSelector(getLanguage);

    const handleChange = (event) => {
        const newLanguage = event.target.value;
        dispatch(setLanguage(newLanguage));
        i18n.changeLanguage(newLanguage);
        moment.locale(newLanguage);
        handleOnClose();
    };

    return (
        <CustomPopover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            hideCloseButton={true}
            anchorEl={anchorEl}
            open={open}
            handleOnClose={handleOnClose}
        >
            <Paper className={cx(flexBox.borderRadius2, flexBox.overflowHidden)}>
                <FormControl component="fieldset" className={flexBox.padding051}>
                    <RadioGroup aria-label="gender" name="gender1" value={language} onChange={handleChange}>
                        {langList.map((lan, idx) => (
                            <FormControlLabel
                                key={idx}
                                id={idx}
                                value={lan.value}
                                control={<Radio color="primary" />}
                                label={
                                    <div className={cx(flexBox.flex, flexBox.column)}>
                                        <Typography fontWeight={500}>{t(lan.title)}</Typography>
                                        <Typography variant={'body2'} className={flexBox.opacity05}>
                                            {t(lan.subTitle)}
                                        </Typography>
                                    </div>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Paper>
        </CustomPopover>
    );
};

export default SettingsLang;
