import { Stack } from '@mui/material';
import EventGridComplete from 'common/components/EventGridComplete';
import useFlexBoxStyles from 'common/flexBox';
import LayoutSectionHeader from 'common/layouts/LayoutSectionHeader';
import SkeletonEventItem from 'common/layouts/skeleton/SkeletonEventItem';
import { range } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getServicesTotal } from 'redux/slices/serviceSlice';
import { getTemplateTypes } from 'redux/slices/templateTypeSlice';
import { makeStyles } from 'tss-react/mui';
import { getUser } from '../../redux/slices/authSlice';
import ServiceCard from './ServiceItem';
import ServicesFilter from './filters/ServicesFilter';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minWidth: theme.spacing(33),
            maxWidth: theme.spacing(45),
            flexBasis: theme.spacing(30),
        },
    };
});

const ServicesList = ({ templates, isService, groups, pending }) => {
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const user = useSelector(getUser);
    const templateTypes = useSelector(getTemplateTypes);
    const total = useSelector(getServicesTotal);

    return (
        <LayoutSectionHeader
            total={total}
            filters={{
                showSearch: true,
                customFilters: isService ? <ServicesFilter /> : null,
            }}
        >
            <Stack
                direction="row"
                className={cx(flexBox.wrap, flexBox.w100, flexBox.h100, flexBox.overflowXHidden, flexBox.scrollbarNone)}
            >
                {templates?.map((item, idx) => {
                    const type = templateTypes.find((t) => t.id === item.typeId);
                    const group = groups.find((g) => g.id === item.userGroupId);
                    const disabledDelete = user?.userGroupId !== null && item.taxonomy !== null;
                    return (
                        <React.Fragment key={idx}>
                            <Stack className={cx(flexBox.grow1, flexBox.padding3, flexBox.borderBox, classes.root)}>
                                <ServiceCard
                                    type={type}
                                    group={group}
                                    disabledDelete={disabledDelete}
                                    template={item}
                                    isService={isService}
                                />
                            </Stack>
                        </React.Fragment>
                    );
                })}
                {pending &&
                    range(1, 13, 1).map((i) => {
                        return (
                            <React.Fragment key={i}>
                                <SkeletonEventItem />
                            </React.Fragment>
                        );
                    })}
                <EventGridComplete />
            </Stack>
        </LayoutSectionHeader>
    );
};

export default ServicesList;
