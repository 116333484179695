import { makeStyles } from 'tss-react/mui';

const useOnliveStyles = makeStyles()((theme) => ({
    boxShadow: {
        boxShadow: '2px 6px 6px 0px rgba(0,0,0,0.45)',
    },
    borderMediumGray: {
        border: `1px solid  ${theme.palette.common.mediumGray}`,
    },
    borderSecondaryDark: {
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    borderIconButton: {
        border: `1px solid #E8E8E8`,
    },
    boxShadowWeb: {
        boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    borderSolid1pxCurrentColor: {
        border: '1px solid currentColor',
    },
    borderSolid1pxPrimaryLight: {
        border: `1px solid ${theme.palette.primary.light}`,
    },
    borderSolid1pxLightGray: {
        border: `1px solid ${theme.palette.common.lightGray}`,
    },
    borderSolid1pxGray: {
        border: `1px solid ${theme.palette.common.gray}`,
    },
    borderSolid1pxBlack: {
        border: `1px solid ${theme.palette.common.black}`,
    },
    borderSolid1pxGreen: {
        border: `1px solid ${theme.palette.common.green}`,
    },
    borderSolid1pxRed: {
        border: `1px solid ${theme.palette.common.red}`,
    },
    darkBorder: {
        border: `1px solid ${theme.palette.common.softBlack}`,
    },
    blackBorder: {
        border: `1px solid ${theme.palette.common.black}`,
    },
    whiteBorder: {
        border: `1px solid ${theme.palette.common.white}`,
    },
}));

export default useOnliveStyles;
