import { Grid, Stack } from '@mui/material';
import YesNoDialog from 'common/components/YesNoDialog';
import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            padding: theme.spacing(5, 7, 4),
            height: '100%',
            '&.fullscreen': {
                height: '100vh', // makes full-screen layout height consistent
                overflow: 'hidden',
            },
            '&:not(.fullscreen)': {
                width: '65vw',
                [theme.breakpoints.up('xl')]: {
                    padding: theme.spacing(6, 8, 5),
                    maxWidth: theme.spacing(200),
                },
                [theme.breakpoints.down('mg')]: {
                    padding: theme.spacing(4),
                    width: '80vw',
                },
                [theme.breakpoints.down('ms')]: {
                    padding: theme.spacing(2),
                },
                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(1),
                },
            },
        },

        scrollableContainer: {
            height: '100%',
            overflowY: 'auto', // Enable scroll within this wrapper
        },

        leftContent: {
            maxWidth: '30vw',
            textAlign: 'center',
            '& img': {
                objectFit: 'contain', // it is important that users upload images with 6/11 ratio
                maxWidth: theme.spacing(90),
                width: '100%',
                maxHeight: theme.spacing(50),
                [theme.breakpoints.up('xl')]: {
                    maxHeight: theme.spacing(60),
                },
                [theme.breakpoints.down('md')]: {
                    maxWidth: '100%',
                    maxHeight: theme.spacing(50),
                },
            },
            '& video': {
                height: '100%',
                width: '100%',
                objectFit: 'cover', // it is important that users upload images with 6/11 ratio
                maxWidth: theme.spacing(90),
                maxHeight: theme.spacing(50),
                [theme.breakpoints.up('xl')]: {
                    maxHeight: theme.spacing(60),
                },
                [theme.breakpoints.down('md')]: {
                    maxWidth: '100%',
                    maxHeight: theme.spacing(50),
                },
            },
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
                maxHeight: '100%',
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: theme.spacing(90),
            },
        },
        rightContent: {
            width: '100%',
            [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(2),
            },
        },
    };
});

const LayoutDetails = (props) => {
    const { children, className, fullScreen } = props;

    const { classes } = useStyles();
    const { classes: flexBox, cx } = useFlexBoxStyles();

    return (
        <Stack
            direction="column"
            className={cx(
                flexBox.h100,
                classes.root,
                fullScreen ? 'fullscreen' : '',
                flexBox.overflowHidden,
                className,
            )}
        >
            <Stack
                direction="column"
                spacing={5}
                className={cx(flexBox.h100, flexBox.w100, classes.scrollableContainer, flexBox.scrollbarNone)}
            >
                {children}
            </Stack>
        </Stack>
    );
};

const LayoutDetailsItem = ({ confirmDlg, leftContent, actions, footer, children, leftContentClassName }) => {
    const { accept, cancel, titleDlg, messageDlg, open } = confirmDlg;

    const { classes } = useStyles();
    const { classes: flexBox, cx } = useFlexBoxStyles();

    return (
        <>
            <Stack direction="column" alignItems="flex-start" className={cx(flexBox.relative, flexBox.hAuto)}>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                    <Grid item md={6} xs={12}>
                        <div className={cx(classes.leftContent, flexBox.overflowHidden, leftContentClassName)}>
                            {leftContent}
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            className={cx(classes.rightContent, flexBox.relative)}
                        >
                            {children}
                        </Stack>
                    </Grid>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        className={cx(flexBox.absolute, flexBox.right1, flexBox.top1)}
                    >
                        {actions}
                    </Stack>
                </Grid>
                <Stack direction="column" alignItems="flex-start" className={flexBox.w100}>
                    {footer}
                </Stack>
            </Stack>

            {open && (
                <YesNoDialog
                    openDialog={true}
                    title={titleDlg}
                    messageToConfirm={messageDlg}
                    yesButtonCallback={accept}
                    noButtonCallback={cancel}
                />
            )}
        </>
    );
};

export { LayoutDetails, LayoutDetailsItem };
