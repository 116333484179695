import { Dialog, DialogContent } from '@mui/material';
import { isSafari } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { closeNestedDialogAction, getIsNestedDialogOpen, getNestedDialogContent } from 'redux/slices/nestedDialogSlice';
import { makeStyles } from 'tss-react/mui';
import CloseIconButton from '../PopoversAndDialogs/CloseIconButton';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '& .MuiDialog-paperScrollPaper': {
                borderRadius: '20px',
                maxwidth: '80%',
            },

            '& .MuiDialogContent-root': {
                boxSizing: 'border-box',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
            },
        },

        drawerPaper: {
            position: 'initial !important',
        },
    };
});

const NestedDialog = ({ open, onClose, children }) => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const content = useSelector(getNestedDialogContent);

    const close = () => {
        dispatch(closeNestedDialogAction());
    };

    const isOpen = useSelector(getIsNestedDialogOpen);

    return (
        <Dialog
            maxWidth={false}
            className={classes.root}
            fullScreen={false}
            open={open || isOpen}
            onClose={() => {}}
            classes={{
                paper: isSafari ? classes.drawerPaper : '',
            }}
        >
            <DialogContent>
                <CloseIconButton onClick={() => (onClose ? onClose() : close())} inside={true} />

                {children || content}
            </DialogContent>
        </Dialog>
    );
};

export default NestedDialog;
