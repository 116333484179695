import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { QUIZ, SURVEY } from 'app/game/Const';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const gameSlice = createSlice({
    name: 'game',
    initialState: {
        pending: 0,
        savePending: false,
        deletePending: false,
        games: [],
        error: null,
        gameId: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = state.pending + 1;
        },
        stop: (state, action) => {
            state.pending = state.pending - 1;
        },
        setGames: (state, action) => {
            state.pending = state.pending - 1;
            state.games = action.payload;
        },
        setError: (state, action) => {
            state.pending = state.pending - 1;
            state.games = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        addGame: (state, action) => {
            state.savePending = false;
            state.games = [...state.games, action.payload];
        },
        editGame: (state, action) => {
            state.savePending = false;
            const index = state.games.findIndex((game) => game.id === action.payload.id);
            if (index !== -1) {
                state.games = [...state.games.slice(0, index), action.payload, ...state.games.slice(index + 1)];
            }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.gameId = action.payload;
        },
        deleteGame: (state, action) => {
            state.deletePending = false;
            const index = state.games.findIndex((game) => game.id === action.payload.id);
            if (index !== -1) {
                state.games = [...state.games.slice(0, index), ...state.games.slice(index + 1)];
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setGames,
    setError,
    startSave,
    addGame,
    editGame,
    setSaveError,
    startDelete,
    deleteGame,
    setDeleteError,
} = gameSlice.actions;

export const getGames = (state) => state.game.games;
export const getGamesSurvey = (state) => state.game.games.filter((t) => t.type === SURVEY);
export const getGamesQuiz = (state) => state.game.games.filter((t) => t.type === QUIZ);
export const getGamesPending = (state) => Boolean(state.game.pending);
export const getGamesSavePending = (state) => state.game.savePending;
export const getGamesDeletePending = (state) => state.game.deletePending;
export const getGamesError = (state) => state.game.error;
export const getGameId = (state) => state.game.gameId;

export const fetchGamesAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('games' + query)
            .then((response) => {
                dispatch(setGames(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const addSurveyAction = (surveyDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('games/survey', surveyDto)
            .then((response) => {
                dispatch(addGame(response.data));
                dispatch(fetchGamesAction());
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const addQuizAction = (quizDto, onSuccess) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('games/quiz', quizDto)
            .then((response) => {
                dispatch(addGame(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const addRaffleAction = (raffleDto, onSuccess) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('games/raffle', raffleDto)
            .then((response) => {
                dispatch(addGame(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const editSurveyAction = (eventId, eventDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('games/survey/' + eventId, eventDto)
            .then((response) => {
                dispatch(editGame(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const editQuizAction = (quizId, quizDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('games/quiz/' + quizId, quizDto)
            .then((response) => {
                dispatch(editGame(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const editRaffleAction = (raffleId, raffleDto, success) => async (dispatch) => {
    try {
        console.debug({ raffleId, raffleDto });

        dispatch(startSave());
        api.put('games/raffle/' + raffleId, raffleDto)
            .then((response) => {
                dispatch(editGame(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const deleteGameAction = (gameId) => async (dispatch) => {
    try {
        dispatch(startDelete(gameId));
        api.delete('games/' + gameId)
            .then((response) => {
                dispatch(deleteGame(response.data));
                dispatch(fetchGamesAction());
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export default gameSlice.reducer;
