import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import useColorsStyles from 'common/colors';
import CustomTooltip from 'common/components/CustomTooltip';
import 'suneditor/dist/css/suneditor.min.css';

const SecurityStatusItem = ({ factor, text }) => {
    const { classes: colors } = useColorsStyles();

    return (
        <>
            {factor?.configured ? (
                factor?.confirmed ? (
                    <CheckCircleOutlineOutlinedIcon className={colors.textSuccess} />
                ) : (
                    <CustomTooltip title={text}>
                        <WarningAmberOutlinedIcon className={colors.textWarning} />
                    </CustomTooltip>
                )
            ) : null}
        </>
    );
};

export default SecurityStatusItem;
