import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';
import EmptyTypes from 'common/enums/emptyTypes';
import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            top: '35%',
            left: 'calc(50% - 180px)',
            width: '360px',
            '&.small': {
                top: 'calc(50% - 70px)',
                [theme.breakpoints.up('xl')]: {
                    top: 'calc(50% - 100px)',
                },
            },
        },
        image: {
            width: theme.spacing(16),
            [theme.breakpoints.up('xl')]: {
                width: theme.spacing(18),
            },
            '&.small': {
                width: theme.spacing(10),
                [theme.breakpoints.up('xl')]: {
                    width: theme.spacing(14),
                },
            },
        },
    };
});

const EmptyView = (props) => {
    const { type, action, description, descriptionKey, size, noImage, className } = props;
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    const text = type?.name === EmptyTypes.EMPTY_SEARCH.name
        ? t('search.empty')
        : descriptionKey
            ? t(descriptionKey)
            : description;
    
    const src = type?.src || EmptyTypes.EMPTY_RESOURCE.src;

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={cx(flexBox.absolute, classes.root, size, className)}
        >
            {!noImage && (
                <div className={cx(classes.image, size)}>
                    <img className={cx(flexBox.w100, flexBox.hAuto)} style={type?.style} src={src} alt="Empty data" />
                </div>
            )}
            <Typography
                variant={size ? 'body1' : 'onliveH6'}
                fontWeight={500}
                textAlign="center"
                className={cx(classes.description)}
            >
                {text}
            </Typography>
            {type?.name !== EmptyTypes.EMPTY_SEARCH.name && action ? (
                <Stack direction="row" justifyContent="center">
                    {action}
                </Stack>
            ) : null}
        </Stack>
    );
};

export default EmptyView;
