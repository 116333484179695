import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from 'common/theme';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, userHasAuthenticated } from 'redux/slices/authSlice';
import { fetchOrganizationAction, setOrganization } from 'redux/slices/organizationSlice';
import socket from 'sockets/organization-socket';

const CustomThemeProvider = (props) => {
    const { children } = props;

    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const isAuthenticated = useSelector(userHasAuthenticated);

    const handleUpdateOrganization = useCallback(
        (org) => {
            dispatch(setOrganization(org));
        },
        [dispatch],
    );

    useEffect(() => {
        if (isAuthenticated && user?.organizationId) {
            dispatch(fetchOrganizationAction());
            socket.emit('organization', user.organizationId);
            socket.on(`organization:updated:${user.organizationId}`, handleUpdateOrganization);
            return () => {
                socket.off(`organization:updated:${user.organizationId}`, handleUpdateOrganization);
            };
        }
    }, [isAuthenticated, user?.organizationId, dispatch, handleUpdateOrganization]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};

export default CustomThemeProvider;
