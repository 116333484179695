import Typography from '@mui/material/Typography';
import { ReactComponent as NoDataIcon } from 'common/assets/dashboards/noData1.svg';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles/useStylesChartNoData.styles';

const ChartNoData = ({ className }) => {
    const { classes, cx } = useStyles();
    const { t } = useTranslation();

    return (
        <div className={cx(classes.root, className)}>
            <div className={classes.container}>
                <NoDataIcon className={classes.icon} />
                <Typography variant="subtitle2" className={classes.text}>
                    {t('analytics.common.noData')}
                </Typography>
            </div>
        </div>
    );
};

export default ChartNoData;
