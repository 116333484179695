import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Avatar, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { setAgentOffline, setAgentOnline } from 'app/widget/agentService';
import { groupSvg } from 'common/assets/svgs';
import CustomSwitch from 'common/components/CustomSwitch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'redux/slices/authSlice';
import { getAgentAvailable, getMeetLoaded, getRealtimeAgents, setAgentAvailable } from 'redux/slices/realtimeSlice';
import useStylesUserInfo from './styles/useStylesUserInfo.styles';

const UserInfo = (props) => {
    const { onNavigate } = props;

    const { t } = useTranslation();
    const { classes, cx } = useStylesUserInfo();
    const user = useSelector(getUser);
    const available = useSelector(getAgentAvailable);
    const realtimeAgents = useSelector(getRealtimeAgents);
    const [actionPopup, setActionPopup] = useState(false);
    const [agentWaitTimeout, setAgentWaitTimeout] = useState(null);
    const dispatch = useDispatch();
    const [scheduleHasAgentCurrentEvent, setScheduleHasAgentCurrentEvent] = useState(false);
    const [loading, setLoading] = useState(false);
    const meetLoaded = useSelector(getMeetLoaded);
    const navigate = useNavigate();
    const [hasInteraction, setHasInteraction] = useState(false);

    const agent = useMemo(() => {
        const { role, ...agent } = user;
        return {
            ...agent,
            id: user?.userId,
        };
    }, [user]);

    const role = useMemo(() => {
        return user?.role;
    }, [user?.role]);

    const goToProfile = () => {
        if (onNavigate) {
            onNavigate();
        }
        navigate('/my-profile');
    };

    const handleCheckChange = async () => {
        if (loading) return;
        setLoading(true);
        if (!available) {
            localStorage.setItem('online-agent-available', true);
            await setAgentOnline(agent);
            setLoading(false);
        } else {
            localStorage.setItem('online-agent-available', false);
            await setAgentOffline(agent.organizationId, agent.id);
            setLoading(false);
        }
    };

    const observeAgentCallback = useCallback(
        ({ detail }) => {
            if (detail) {
                dispatch(setAgentAvailable(detail.onlineWeb));
            }
        },
        [dispatch],
    );

    useEffect(() => {
        if (scheduleHasAgentCurrentEvent) {
            const hasAgentCurrentEventTimeout = setTimeout(() => {
                setScheduleHasAgentCurrentEvent(false);
            }, 2000);
            return () => clearTimeout(hasAgentCurrentEventTimeout);
        }
    }, [scheduleHasAgentCurrentEvent, realtimeAgents, agent?.id, agent?.organizationId]);

    const updateAgentsObservers = useCallback(() => {
        realtimeAgents?.stopObserverAgentWithCallback(agent.id, observeAgentCallback);
        realtimeAgents?.observeAgent(agent.id, observeAgentCallback);
        setScheduleHasAgentCurrentEvent(true);
    }, [agent.id, observeAgentCallback, realtimeAgents]);

    const clickAction = useCallback(() => {
        if (localStorage?.getItem('online-agent-available') === 'true' && role !== 'root') {
            setAgentOnline(agent);
        }
        updateAgentsObservers();
        if (agentWaitTimeout) {
            clearTimeout(agentWaitTimeout);
        }
        document.body.removeEventListener('click', clickAction);
        window['onlive_agent_click'] = true;
    }, [agent, role, updateAgentsObservers, agentWaitTimeout]);

    useEffect(() => {
        if (realtimeAgents) {
            if (!hasInteraction) {
                setHasInteraction(true);
                const agentActionTimeout = setTimeout(() => {
                    if (localStorage?.getItem('online-agent-available') === 'true' && role !== 'root') {
                        if (!realtimeAgents?.hasAgentCurrentEvent(agent?.id) && !available) {
                            setActionPopup(true);
                        } else {
                            setAgentOnline(agent);
                        }
                    }
                }, 1000);
                setAgentWaitTimeout(agentActionTimeout);
            } else if (!available && localStorage?.getItem('online-agent-available') === 'true') {
                setAgentOnline(agent);
            }
        }
    }, [realtimeAgents, role, available, agent, hasInteraction]);

    useEffect(() => {
        if (realtimeAgents) {
            document.body.addEventListener('click', clickAction);
        }
        return () => document.body.removeEventListener('click', clickAction);
    }, [realtimeAgents, clickAction]);

    return (
        <>
            <div className={classes.userProfile}>
                {role === 'root' ? (
                    <div className={classes.adminIcon}>
                        <SettingsIcon />
                    </div>
                ) : (
                    <Avatar alt={user?.name} src={user?.avatar} size="small" />
                )}
                <div aria-hidden={true} className={classes.userInfo} onClick={goToProfile}>
                    <span>{user?.name}</span>
                    <div className={classes.userRole}>
                        <VerifiedUserIcon />
                        <span>{t(`roles.role.${role}`)}</span>
                    </div>
                </div>

                {realtimeAgents && meetLoaded && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={available ?? false}
                                    onChange={handleCheckChange}
                                    name="available"
                                    color="primary"
                                />
                            }
                            label={
                                <FormLabel className={classes.availableControl}>
                                    {available
                                        ? t('components.userInfo.available')
                                        : t('components.userInfo.notAvailable')}
                                </FormLabel>
                            }
                        />
                    </FormGroup>
                )}
            </div>
            {window.self === window.top && (
                <div
                    aria-hidden={true}
                    className={cx(classes.notificationsPopupBackground, {
                        [classes.notificationsPopupHidden]: !actionPopup,
                    })}
                    onClick={() => {
                        clickAction();
                        window['onlive_agent_click'] = true;
                        setActionPopup(false);
                    }}
                >
                    <div className={classes.notificationContent}>
                        {groupSvg}
                        <h3>{t('components.userInfo.afk')}</h3>
                        <p>{t('components.userInfo.pleaseClick')}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserInfo;
