import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useStylesLoadingButton from './styles/useStylesLoadingButton.styles';

const LoadingButton = (props) => {
    const { className, startIcon, loading, disabled, handleButtonClick, color, variant, label } = props;

    const { classes } = useStylesLoadingButton();

    return (
        <div className={classes.wrapper + ' ' + className}>
            <Button
                startIcon={startIcon}
                variant={variant}
                color={color}
                disabled={disabled || loading}
                onClick={handleButtonClick}
            >
                {label}
            </Button>
            {loading && <CircularProgress size={24} color={color} className={classes.buttonProgress} />}
        </div>
    );
};

export default LoadingButton;
