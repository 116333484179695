import { Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { isEqual, set } from 'date-fns';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const OListDateOverride = (props) => {
    const { classes, datesOverride, onDeleteDate, simple, timeZone, isReadOnly } = props;

    const { i18n } = useTranslation();

    moment.locale(i18n.language);

    const contentDate = (d) => simple ? contentCompoundDate(d) : moment.tz(`${d.date} 00:00`, timeZone).format('LL')

    const contentCompoundDate = (d) => {
        return <>
                {moment.tz(`${d.beginDate} 00:00`, timeZone).format('LL')}
                {d.finalDate !== null && !isEqual(
                    set(new Date(d.beginDate), { hours: 0, minutes: 0, seconds: 0}),
                    set(new Date(d.finalDate), { hours: 0, minutes: 0, seconds: 0})
                ) &&
                <>
                    <span style={{margin: '0 5px'}}>-</span>
                    {moment.tz(`${d.finalDate} 00:00`, timeZone).format('LL')}
                </>
                }
            </> 
    }

    return (
        <div>
            {datesOverride.map((d) => {
                return (
                    <Grid container spacing={1} key={d.id} className={classes.listItemDateOverride}>
                        <Grid item md={simple ? 10 : 4} className={classes.listItemDateOverrideContent}>
                            {
                                contentDate(d)
                            }
                        </Grid>
                        {Boolean(!simple) && (
                            <Grid item md={6} className={classes.listItemDateOverrideContentHours}>
                                {d.hours.map((h) => {
                                    return (
                                        <div key={h.id}>
                                            {h.startTime !== null
                                                ? moment.tz(`${d.date} ${h.startTime}`, timeZone).format('LT')
                                                : ''}
                                            <span className={classes.dividerDateOverride}>-</span>
                                            {h.endTime !== null
                                                ? moment.tz(`${d.date} ${h.endTime}`, timeZone).format('LT')
                                                : ''}
                                        </div>
                                    );
                                })}
                            </Grid>
                        )}
                        {Boolean(!isReadOnly) && (
                            <Grid item md={2} className={classes.listItemDateOverrideContentBtn}>
                                <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                    onClick={() => onDeleteDate(d.id, simple)}
                                    size="large"
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </div>
    );
};

export default OListDateOverride;
