import {
    CommonStatus,
    ContactRequestStatus,
    EventStatus,
    ICallStatus,
    InvoiceStatus,
    MultimediaStatus,
    OthersStatus,
    ReportStatus,
    SessionRequestStatus,
    ShoppableStatus,
    UserInvitationStatus,
    WebHookStatus,
} from 'common/enums/statusEnum';
import DynamicStatusChip from 'common/features/status/DynamicStatusChip';
import CustomTable from 'common/features/tables/CustomTable';
import CustomTableRow from 'common/features/tables/CustomTableRow';
import LayoutSectionHeader from 'common/layouts/LayoutSectionHeader';
import LayoutView from 'common/layouts/LayoutView';

const StatusTypeTableRow = ({ chip, list }) => {
    const rowConfig = {
        columns: [
            {
                content: <DynamicStatusChip status={chip.name} />,
            },
            {
                content: chip.name,
            },
            {
                content: list,
            },
            {
                content: chip?.icon,
            },
            {
                content: chip?.iconName,
            },
            {
                content: chip.style.backgroundColor,
            },
            {
                content: chip.style.color,
            },
        ],
    };

    return <CustomTableRow rowConfig={rowConfig} />;
};

const StatusList = () => {
    const commonStatus = CommonStatus;
    const sessionRequestStatus = SessionRequestStatus;
    const eventStatus = EventStatus;
    const contactRequestStatus = ContactRequestStatus;
    const iCallStatus = ICallStatus;
    const multimediaStatus = MultimediaStatus;
    const reportStatus = ReportStatus;
    const shoppableStatus = ShoppableStatus;
    const userInvitationStatus = UserInvitationStatus;
    const invoiceStatus = InvoiceStatus;
    const webHookStatus = WebHookStatus;
    const othersStatus = OthersStatus;

    const tableConfig = {
        columns: [
            { id: 'chip', content: 'Chip', style: { width: '350px' } },
            { id: 'name', content: 'Name' },
            { id: 'type', content: 'Type' },
            { id: 'icon', content: 'Icon' },
            { id: 'iconName', content: 'Icon Name' },
            { id: 'background', content: 'background' },
            { id: 'color', content: 'color' },
        ],
    };

    return (
        <LayoutView>
            <LayoutSectionHeader>
                <CustomTable tableConfig={tableConfig}>
                    {Object.keys(commonStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={commonStatus[key]} list={'CommonStatus'} />
                    ))}
                    {Object.keys(sessionRequestStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={sessionRequestStatus[key]} list={'SessionRequestStatus'} />
                    ))}
                    {Object.keys(eventStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={eventStatus[key]} list={'EventStatus'} />
                    ))}
                    {Object.keys(contactRequestStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={contactRequestStatus[key]} list={'ContactRequestStatus'} />
                    ))}
                    {Object.keys(iCallStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={iCallStatus[key]} list={'ICallStatus'} />
                    ))}
                    {Object.keys(multimediaStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={multimediaStatus[key]} list={'MultimediaStatus'} />
                    ))}
                    {Object.keys(reportStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={reportStatus[key]} list={'ReportStatus'} />
                    ))}
                    {Object.keys(shoppableStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={shoppableStatus[key]} list={'ShoppableStatus'} />
                    ))}
                    {Object.keys(userInvitationStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={userInvitationStatus[key]} list={'UserInvitationStatus'} />
                    ))}
                    {Object.keys(invoiceStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={invoiceStatus[key]} list={'InvoiceStatus'} />
                    ))}
                    {Object.keys(webHookStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={webHookStatus[key]} list={'WebHookStatus'} />
                    ))}
                    {Object.keys(othersStatus).map((key) => (
                        <StatusTypeTableRow key={key} chip={othersStatus[key]} list={'OthersStatus'} />
                    ))}
                </CustomTable>
            </LayoutSectionHeader>
        </LayoutView>
    );
};

export default StatusList;
