import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Badge,
    Box,
    Button,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStylesIntegrationRow from './styles/useStylesIntegrationRow.styles';

const IntegrationRow = (props) => {
    const { row } = props;
    const { classes } = useStylesIntegrationRow();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const errorsDescriptions = useMemo(() => {
        return {
            MISSING_NAME: t('profile.ecommerceIntegration.errorDescription.missingName'),
            MISSING_CURRENCY: t('profile.ecommerceIntegration.errorDescription.missingCurrency'),
            MISSING_VARIANT_CURRENCY: t('profile.ecommerceIntegration.errorDescription.missingVariantCurrency'),
            UNKNOWN_CURRENCY: t('profile.ecommerceIntegration.errorDescription.unknownCurrency'),
            UNKNOWN_VARIANT_CURRENCY: t('profile.ecommerceIntegration.errorDescription.unknownVariantCurrency'),
            MISSING_PRICE: t('profile.ecommerceIntegration.errorDescription.missingPrice'),
            MISSING_VARIANT_PRICE: t('profile.ecommerceIntegration.errorDescription.missingVariantPrice'),
            MISSING_IMAGES: t('profile.ecommerceIntegration.errorDescription.missingImages'),
            MISSING_VARIANT_IMAGES: t('profile.ecommerceIntegration.errorDescription.missingVariantImages'),
            INVALID_VARIANT: t('profile.ecommerceIntegration.errorDescription.invalidVariant'),
            INVALID_VARIANT_SELECTED_OPTION: t(
                'profile.ecommerceIntegration.errorDescription.invalidVariantSelectedOption',
            ),
        };
    }, [t]);

    return (
        <React.Fragment>
            <TableRow onClick={() => setOpen(!open)}>
                <TableCell className={classes.p1}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.p1} scope="row">
                    {row.productName}
                </TableCell>
                <TableCell className={classes.p1} align="center">
                    {row.productId}
                </TableCell>
                <TableCell className={classes.p1} align="center">
                    <Badge badgeContent={row.errors.length} color="error" />
                </TableCell>
                <TableCell className={classes.p1} align="center">
                    <Button variant="outlined" color="primary" href={row.productUrl} target="_blank">
                        {t('profile.ecommerceIntegration.link')}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                {t('profile.ecommerceIntegration.tableHead.errors')}
                            </Typography>
                            <List>
                                {row.errors.map((error) => {
                                    let text = errorsDescriptions[error.code];
                                    if (error.hasOwnProperty('variantId')) {
                                        text += ` ${t('profile.ecommerceIntegration.errorDescription.variantId')} ${
                                            error.variantId
                                        }.`;
                                    }
                                    return (
                                        <ListItem key={error.code}>
                                            <ListItemIcon>
                                                <ErrorIcon color="error" />
                                            </ListItemIcon>
                                            <ListItemText primary={text} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default IntegrationRow;
