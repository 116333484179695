import { FormControl, MenuItem, Stack } from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import DateRangeEnum from 'common/enums/dateRange';
import BasicDateRangePicker from 'common/features/filters/components/BasicDateRangePicker';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDateRange, resetEventDashboard, setDateRange, setGranularity } from 'redux/slices/dashboardSlice';

import ActionButton from 'common/components/ActionButton';
import { useDateRangeType } from 'common/constants/dateRangeType';
import DateRange from 'common/enums/dateRange';
import useFlexBoxStyles from 'common/flexBox';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import Granularity from '../enums/granularity';
import { getDateRangeByRangeType } from './util';

const useStyles = makeStyles()((theme) => {
    return {
        sizeSmall: {
            minWidth: theme.spacing(40),
        },
    };
});

const AnalyticsDateRange = ({ onTypeChange }) => {
    const { t } = useTranslation();
    const dateRange = useSelector(getDateRange);
    const [dateRangeTemp, setDateRangeTemp] = useState();
    const rangeValues = useDateRangeType({ showAll: false });
    const { classes } = useStyles();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const [rangeType, setRangeType] = useState(DateRangeEnum.LAST_30_DAYS);
    const [canUpdate, setCanUpdate] = useState(false);
    const dispatch = useDispatch();

    const propagateChange = () => {
        onTypeChange?.();
        dispatch(setGranularity(onGranularityControl()));
        dispatch(setDateRange(dateRangeTemp));
        dispatch(resetEventDashboard());
        setCanUpdate(false);
    };

    const onGranularityControl = () => {
        switch (rangeType) {
            case DateRange.TODAY:
                return Granularity.HOUR;

            case DateRange.LAST_7_DAYS:
                return Granularity.diffInDays;

            case DateRange.LAST_30_DAYS:
                return Granularity.WEEK;

            case DateRange.LAST_90_DAYS:
                return Granularity.MONTH;

            case DateRange.CUSTOM:
                const [start, end] = dateRange;
                if (start && end) {
                    const diffInMilliseconds = end.getTime() - start.getTime();
                    const diffInDays = diffInMilliseconds / (1000 * 3600 * 24);

                    if (diffInDays <= 7) {
                        return Granularity.DAY;
                    } else if (diffInDays <= 30) {
                        return Granularity.WEEK;
                    } else {
                        return Granularity.MONTH;
                    }
                }
                return Granularity.DAY;
            default:
                return Granularity.DAY;
        }
    };

    const sameMomentDate = (momentDate1, momentDate2, unit) => {
        return momentDate1.isSame(momentDate2, unit);
    };

    const isSameDatePeriod = (start, end) => {
        const timeUnits = ['day', 'month', 'year', 'hour', 'minute', 'second'];

        return timeUnits.every(
            (unit) =>
                sameMomentDate(moment(start), moment(dateRange[0]), unit) &&
                sameMomentDate(moment(end), moment(dateRange[1]), unit),
        );
    };

    const isValidChange = (newRangeType, start, end) => {
        return newRangeType !== rangeType || !isSameDatePeriod(start, end);
    };

    const handleTypeChange = (value) => {
        const { start, end } = getDateRangeByRangeType(value);
        if (isValidChange(value, start, end)) {
            setDateRangeTemp([start, end]);
            setRangeType(value);
            setCanUpdate(true);
        } else {
            setCanUpdate(false);
        }
    };

    const onValueRange = (value) => {
        if (isValidChange(DateRange.CUSTOM, value[0], value[1])) {
            setDateRangeTemp(value);
            setRangeType(DateRange.CUSTOM);
            setCanUpdate(true);
        } else {
            setCanUpdate(false);
        }
    };

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            className={cx(flexBox.wrap, flexBox.hFitContent)}
        >
            <Stack direction="column" spacing={1}>
                <FormControl size="small" variant="outlined" className={classes.sizeSmall}>
                    <RoundedSelect
                        bold={true}
                        defaultValue={DateRangeEnum.LAST_30_DAYS}
                        value={rangeType}
                        onChange={(e) => handleTypeChange(e.target.value)}
                        size="big"
                        fullWidth={true}
                    >
                        {rangeValues.map((value) => {
                            return (
                                <MenuItem selected={rangeType === value.id} key={value.id} value={value.id}>
                                    {value.name}
                                </MenuItem>
                            );
                        })}
                    </RoundedSelect>
                </FormControl>

                {rangeType === DateRange.CUSTOM && (
                    <FormControl size="small" variant="outlined">
                        <BasicDateRangePicker
                            dateRange={dateRange}
                            onValue={onValueRange}
                            type={'date-time'}
                        ></BasicDateRangePicker>
                    </FormControl>
                )}
            </Stack>

            <div style={{ order: 2 }}>
                <ActionButton
                    color="default"
                    fitWidth
                    disabled={!canUpdate}
                    onClick={() => {
                        propagateChange();
                    }}
                >
                    {t('app.common.update')}
                </ActionButton>
            </div>
        </Stack>
    );
};

export default AnalyticsDateRange;
