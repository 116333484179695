import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * Use onButtonClick function to pass the state setter that set the openDialog value to false.
 */
const AlertDialog = ({ openDialog, title, messageToConfirm, dialogContent, onOkButtonClick }) => {

  const { t } = useTranslation();

  const [open, setOpen] = useState(openDialog);

  const handleAnswer = () => {
    onOkButtonClick();
    setOpen(false);
  }

  useEffect(() => {
    setOpen(openDialog);
  },
    [openDialog]);

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
          {dialogContent}
          <DialogContentText id="alert-dialog-description">
            {messageToConfirm}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleAnswer() }} color="primary">
            {t("calendar.calendarEventItem.accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;