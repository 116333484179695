import { CircularProgress, IconButton } from '@mui/material';
import useOnliveStyles from 'common/onliveStyles';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        '& svg': {
            fill: theme.palette.common.black,
        },
        '&.selected': {
            background: theme.palette.common.black,
            color: theme.palette.common.white,
            border: 'none',
            '& svg': {
                fill: theme.palette.common.white,
            },
        },
        '&:hover': {
            background: theme.palette.common.lightGray,
            color: theme.palette.getContrastText(theme.palette.common.lightGray),
            '& svg': {
                fill: theme.palette.getContrastText(theme.palette.common.lightGray),
            },
        },
        '&:disabled': {
            background: theme.palette.common.lightGray,
            color: theme.palette.common.disabled,
            '& svg': {
                fill: theme.palette.common.disabled,
            },
        },
    },
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            '& svg': {
                width: theme.spacing(2.25),
                height: theme.spacing(2.25),
            },
        },
    },
    medium: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        '& svg': {
            width: theme.spacing(2.25),
            height: theme.spacing(2.25),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            '& svg': {
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
        },
    },
    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        '& svg': {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(5.5),
            height: theme.spacing(5.5),
            '& svg': {
                width: theme.spacing(3.75),
                height: theme.spacing(3.75),
            },
        },
    },

    big: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        '& svg': {
            width: theme.spacing(4.75),
            height: theme.spacing(4.75),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(6.5),
            height: theme.spacing(6.5),
            '& svg': {
                width: theme.spacing(5),
                height: theme.spacing(5),
            },
        },
    },
}));

const CustomIconButton = React.forwardRef((props, ref) => {
    const { className, pending, mobile, children, size = 'inherit', hideBorder = false, ...rest } = props;
    const { classes, cx } = useStyles();
    const { classes: onlive } = useOnliveStyles();

    let sizeClass = '';

    if (size !== 'inherit') {
        sizeClass = classes[size];
    }

    const rootClass = !mobile ? cx(classes.root, !hideBorder && onlive.borderIconButton) : '';

    return (
        <IconButton {...rest} className={cx(rootClass, sizeClass, className)} ref={ref}>
            {pending ? <CircularProgress style={{ width: 'auto', height: 'auto' }} /> : children}
        </IconButton>
    );
});

export default CustomIconButton;
