import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Button } from '@mui/material';
import { NotificationService } from '@onlive.site/front-core';
import api from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import TrackingTypes from 'common/enums/trackingTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { useTrackingCallCenter } from 'common/tracking/useTrackingCallCenter';
import { isValidHttpUrl } from 'common/utils/utils';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getMeetLoaded, getSessionRequest, setSessionRequest } from 'redux/slices/realtimeSlice';
import { getCmsFingerPrint, setCurrentOne2OneEvent } from 'redux/slices/userSlice';
import socket from 'sockets/event-socket';
import useStylesCallCenter from './styles/callCenter.styles';

function CallCenter({ realtimeAgents }) {
    const [me, setMe] = useState(null);
    const [showCall, setShowCall] = useState(false);
    const user = useSelector(getUser);
    const cmsFingerPrintState = useSelector(getCmsFingerPrint);
    const { ...agent } = { ...user, id: user.userId, role: 'personal_shopper' };
    const [prevSession, setprevSession] = useState(null);
    const session = useSelector(getSessionRequest);
    const { track } = useTrackingCallCenter();
    const { classes, cx } = useStylesCallCenter();
    const dispatch = useDispatch();
    const organization = useSelector(getOrganization);
    const [, setOldRealtimeAgents] = useState(null);
    const meetLoaded = useSelector(getMeetLoaded);
    const icallNotifications = useSettingValue(SettingsKey.ICALL_NOTIFICATIONS);
    const enableBrowserSoundNotification =
        icallNotifications?.[SettingsKey.DIRECT_INCOMING_CALL]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION];
    const enableSystemTrayNotification =
        icallNotifications?.[SettingsKey.DIRECT_INCOMING_CALL]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION];
    const enableBrowserPushNotification =
        icallNotifications?.[SettingsKey.DIRECT_INCOMING_CALL]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION];
    const audioRef = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dataSound = useCallback(() => {
        const url =
            icallNotifications?.[SettingsKey.DIRECT_INCOMING_CALL]?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableBrowserSoundNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_WIDGET_ASSETS_URL}/call_phone_ring_tone.mp3`;
    }, [icallNotifications, enableBrowserSoundNotification]);

    useEffect(() => {
        audioRef.current = NotificationService.notify('sound_notification', dataSound());
    }, [dataSound]);

    const notify = useCallback(
        (sessionRequest) => {
            const title = `${t('incomingCall')} ${getOriginOfSession(sessionRequest)}`;
            const icon = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/icon-128.png`;

            if (enableBrowserSoundNotification) {
                audioRef?.current?.play();
            }

            if (enableSystemTrayNotification) {
                const action = (key) => (
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => closeSnackbar(key)}
                        style={{ minWidth: '32px' }}
                    >
                        <CloseIcon />
                    </Button>
                );
                NotificationService.notify('vms_notification', { title, action }, (data) => {
                    enqueueSnackbar(data.title, { variant: 'info', persist: true, action: data.action });
                });
            }

            if (enableBrowserPushNotification) {
                let body = '';
                if (sessionRequest?.fields?.length) {
                    body = sessionRequest.fields[0]?.value;
                } else if (sessionRequest.userInfo?.extraFields?.length) {
                    body = sessionRequest.userInfo.extraFields[0]?.value;
                }
                if (!('Notification' in window)) {
                    console.log('This browser does not support desktop notification');
                } else if (Notification.permission === 'granted') {
                    const notification = NotificationService.notify('push_notification', { title, body, icon });
                    notification.onclick = () => {
                        window.focus();
                    };
                } else {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            const notification = NotificationService.notify('push_notification', { title, body, icon });
                            notification.onclick = () => {
                                window.focus();
                            };
                        }
                    });
                }
            }
        },
        [
            closeSnackbar,
            enableBrowserPushNotification,
            enableBrowserSoundNotification,
            enableSystemTrayNotification,
            enqueueSnackbar,
        ],
    );

    const closeCall = useCallback(() => {
        setShowCall(false);
        if (enableBrowserSoundNotification) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [enableBrowserSoundNotification]);

    const handleNewInstantSessionRequest = useCallback(
        (sessionRequest) => {
            dispatch(setSessionRequest(sessionRequest));
            setShowCall(true);
        },
        [dispatch],
    );

    const handleCancelInstantSessionRequest = useCallback(() => {
        closeCall();
    }, [closeCall]);

    const openCallTab = useCallback(
        (url) => {
            closeCall();
            window.onliveManager.services.onliveMeet.joinToMeet(url);
        },
        [closeCall],
    );

    const handleAcceptedInstantSessionRequest = useCallback(
        (data) => {
            const { url, cmsFingerPrint } = data;
            if (cmsFingerPrintState === cmsFingerPrint) {
                openCallTab(url);
            }
        },
        [cmsFingerPrintState, openCallTab],
    );

    useEffect(() => {
        if (enableBrowserSoundNotification) {
            audioRef.current.addEventListener(
                'ended',
                function () {
                    this.currentTime = 0;
                    this.play();
                },
                false,
            );
        }
        if (me) {
            socket.emit('session-request', me.userId);
            socket.on(`session-request:created:${me.userId}:instant`, handleNewInstantSessionRequest);
            socket.on(`session-request:canceled:${me.userId}:instant`, handleCancelInstantSessionRequest);
            socket.on(`session-request:accepted:${me.userId}:instant`, handleAcceptedInstantSessionRequest);
            return () => {
                socket.off(`session-request:created:${me.userId}:instant`, handleNewInstantSessionRequest);
                socket.off(`session-request:canceled:${me.userId}:instant`, handleCancelInstantSessionRequest);
                socket.off(`session-request:accepted:${me.userId}:instant`, handleAcceptedInstantSessionRequest);
            };
        }
    }, [
        me,
        enableBrowserSoundNotification,
        handleAcceptedInstantSessionRequest,
        handleCancelInstantSessionRequest,
        handleNewInstantSessionRequest,
    ]);

    const onCancel = () => {
        closeCall();
        const payload = {
            inviteeUserId: session.inviteeUserId,
            userId: session.userId,
            organizationId: user.organizationId,
            canceledBy: 'agent',
            agentEmail: user?.email,
        };
        api.put(`${process.env.REACT_APP_API_URL}/session-requests/cancel-meeetrequest-to-agent`, payload)
            .then(() => {
                saveLostRequest(session);
                const phone = session?.fields?.find((field) => field.name === 'phone');
                const data = {
                    interactionId: session.onliveInteractionId,
                    actorId: me?.id,
                    userId: session?.inviteeUserId,
                    userName: session?.inviteeName,
                    userEmail: session?.inviteeEmail,
                    userPhone: phone?.value || '',
                    // ...extraFields,
                    callOrigin: session?.callOrigin,
                    channelURL: session?.channelURL,
                    fields: session?.fields,
                };
                track({ type: TrackingTypes.CANCEL_INCOMING_MEET, data });
            })
            .catch((error) => {
                closeCall();
            });
    };

    const onAccept = async () => {
        await realtimeAgents?.setRequestSessionAttended(agent.id);
        closeCall();
        const payload = {
            eventDto: {
                name: `${t('common.session.request.instant')} ${organization.name}`,
                description: '',
                releaseDate: new Date(),
                assets: [],
                adminUserId: session?.userId,
                organizationId: user.organizationId,
                type: 'one_to_one',
                instant: true,
                streamConnectors: [],
                interactionId: session?.onliveInteractionId,
                cmsFingerPrint: cmsFingerPrintState,
                inviteeName: session?.inviteeName,
                inviteeEmail: session?.inviteeEmail,
                actors: [
                    {
                        role: 'host',
                        isAdmin: true,
                        email: me?.email,
                        userId: session?.userId,
                    },
                    {
                        role: 'guest',
                        email: session?.userInfo?.email,
                        name: session?.userInfo?.name,
                        userId: session?.inviteeUserId,
                        extraFields: session?.userInfo?.extraFields,
                    },
                ],
                isTemplate: true,
                callOrigin: session?.callOrigin,
                channelURL: session?.channelURL,
                extraFields: session?.extraFields,
            },
            type: 'personal',
            requestSession: session,
        };

        api.post(`${process.env.REACT_APP_API_URL}/events/accept-call`, payload)
            .then((response) => {
                const event = response?.data;
                if (session.callOrigin === 'ichat') {
                    window.dispatchEvent('onlive-ichat-call-started', { detail: event });
                }
            })
            .catch((error) => {});
    };

    const observeAgentCallback = useCallback(
        ({ detail }) => {
            if (detail) {
                const { requestSession, currentEvent, onlineWeb } = detail;
                if (requestSession && onlineWeb) {
                    dispatch(setSessionRequest(requestSession));
                    if (!requestSession?.attended) setShowCall(true);
                } else {
                    closeCall();
                    dispatch(setSessionRequest(null));
                }
                dispatch(setCurrentOne2OneEvent(currentEvent));
            }
        },
        [closeCall, dispatch],
    );

    useEffect(() => {
        const roomIdLoaded = localStorage?.getItem('onlive-roomId-loaded');
        if (roomIdLoaded && meetLoaded) {
            localStorage.removeItem('onlive-roomId-loaded');
            window.onliveManager.services.onliveMeet.joinToMeet(roomIdLoaded);
        }
    }, [meetLoaded]);

    async function saveLostRequest(request) {
        const payload = {
            guestName: request.inviteeName,
            guestEmail: request.inviteeEmail,
            callDate: request.createdAt,
            extraFields: request.extraFields,
            status: 'agent_cancel',
            userId: request.userId,
            callOrigin: request.callOrigin,
        };

        return fetch(`${process.env.REACT_APP_API_URL}/icalls`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }).then((res) => res.json());
    }

    useEffect(() => {
        if (session && !session.attended) {
            if (prevSession) {
                if (session.id !== prevSession.id) {
                    notify(session);
                }
            } else {
                notify(session);
            }
        } else {
            if (enableBrowserSoundNotification) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        }
        setprevSession(session);
    }, [session, agent.id, agent.organizationId, enableBrowserSoundNotification, notify, prevSession]);

    useEffect(() => {
        if (!me) {
            setMe(agent);
        }
    }, [me, agent]);

    useEffect(() => {
        if (realtimeAgents && meetLoaded) {
            setOldRealtimeAgents((currentOldRealtimeAgents) => {
                if (currentOldRealtimeAgents) {
                    currentOldRealtimeAgents?.stopObserverAgentWithCallback(agent.id, observeAgentCallback);
                }
                return realtimeAgents;
            });
            realtimeAgents?.createSession(agent.id, cmsFingerPrintState);
            realtimeAgents?.observeAgent(agent.id, observeAgentCallback);
        }
    }, [realtimeAgents, meetLoaded, agent.id, cmsFingerPrintState, observeAgentCallback]);

    const getOriginOfSession = (session) => {
        return session?.callOrigin && !['widget', 'direct'].includes(session?.callOrigin)
            ? ` ${session?.callOrigin}`
            : '';
    };

    return (
        <div
            className={cx(classes.callCenter, {
                [classes.callCenterOpen]: showCall,
            })}
        >
            {showCall && (
                <>
                    <div className={classes.callCenterDetails}>
                        <p className={classes.callCenterDetailsIncoming}>
                            {` ${t('incomingCall')} ${getOriginOfSession(session)}`}
                        </p>
                        <p className={classes.callCenterDetailsName}>{session?.inviteeName || 'Unnamed User'}</p>
                        <p className={classes.callCenterDetailsEmail}>{session?.inviteeEmail}</p>
                    </div>
                    <div className={classes.callCenterButtons}>
                        <div className={classes.callCenterButtonDecline} onClick={() => onCancel()}>
                            <CallEndOutlinedIcon sx={{ color: 'white', fontSize: 24 }} />
                        </div>
                        <div className={classes.callCenterButtonAccept} onClick={() => onAccept()}>
                            <PhoneOutlinedIcon sx={{ color: 'black', fontSize: 24 }} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default CallCenter;
