import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const eventGlobalSlice = createSlice({
    name: 'eventGlobal',
    initialState: {
        pending: false,
        events: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        setEvents: (state, action) => {
            state.pending = false;
            state.events = action.payload.items;
        },
        setError: (state, action) => {
            state.pending = false;
            state.events = [];
            state.error = action.payload;
        },
    },
});

export const { start, setEvents, setError } = eventGlobalSlice.actions;

export const getEventsGlobal = (state) => state.eventGlobal.events;
export const getEventsGlobalPending = (state) => state.eventGlobal.pending;
export const getEventsGlobalError = (state) => state.eventGlobal.error;

export const fetchEventsActionGlobalAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('events/global' + query)
                .then((response) => {
                    dispatch(setEvents(response.data));
                    onSuccess && onSuccess(response.data);
                    return response;
                })
                .catch((error) => {
                    onFailure && onFailure(error);
                    dispatch(setError(error));
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export default eventGlobalSlice.reducer;
