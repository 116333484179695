import { createSlice } from '@reduxjs/toolkit';
import { leadApi } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        contacts: [],
        error: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 1,
        pending: false,
        savePending: false,
        deletePending: false,
        pendingDownload: false,
        contactHistory: [],
        hasMore: true,
        allContacts: [],
        contactOrigins: [],
        pendingAllContacts: [],
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startAll: (state, action) => {
            state.pendingAllContacts = true;
        },
        stopAll: (state, action) => {
            state.pendingAllContacts = false;
        },
        setContacts: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.contacts = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setAllContacts: (state, action) => {
            state.pendingAllContacts = false;
            state.allContacts = [...state.allContacts.slice(0, action.payload.offset), ...action.payload.items];
            if (action.payload.items.length < 20) {
                state.hasMore = false;
            } else {
                state.hasMore = true;
            }
        },
        setContactOrigins: (state, action) => {
            state.contactOrigins = action.payload;
        },
        setContactHistory: (state, action) => {
            state.pending = false;
            state.contactHistory = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        editContact: (state, action) => {
            state.pending = false;
            const index = state.contacts.findIndex((contact) => contact.id === action.payload.id);
            if (index !== -1) {
                state.contacts = [
                    ...state.contacts.slice(0, index),
                    action.payload,
                    ...state.contacts.slice(index + 1),
                ];
            }
        },
        deleteContact: (state, action) => {
            state.pending = false;
            const index = state.contacts.findIndex((contact) => contact.id === action.payload.id);
            if (index !== -1) {
                state.contacts = [...state.contacts.slice(0, index), ...state.contacts.slice(index + 1)];
            }
        },
        startDownload: (state, action) => {
            state.pendingDownload = true;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
        },
    },
});

export const {
    start,
    stop,
    setContacts,
    setError,
    deleteContact,
    startDownload,
    stopDownload,
    startSave,
    stopSave,
    startDelete,
    setContactHistory,
    setAllContacts,
    startAll,
    stopAll,
    setContactOrigins,
} = contactSlice.actions;

export const getContacts = (state) => state.contact.contacts;
export const getAllContacts = (state) => state.contact.allContacts;
export const getPendingAllContacts = (state) => state.contact.pendingAllContacts;
export const getContactOrigins = (state) => state.contact.contactOrigins;
export const getHasMore = (state) => state.contact.hasMore;
export const getContactHistory = (state) => state.contact.contactHistory;
export const getContactsPending = (state) => state.contact.pending;
export const getContactsSavePending = (state) => state.contact.savePending;
export const getContactsError = (state) => state.contact.error;
export const getContactsTotal = (state) => state.contact.total;
export const getContactsCurrentPage = (state) => state.contact.currentPage;
export const getContactsTotalPages = (state) => state.contact.totalPages;
export const getPendingDownload = (state) => state.event.pendingDownload;

export const hasMoreContacts = (state) => state.contact.hasMore;

export const fetchContactsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setContacts({
                            total: 0,
                            items: [],
                            count: 0,
                            currentPage: 1,
                            totalPages: 1,
                            offset: 0,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            leadApi
                .get('leads' + query)
                .then((response) => {
                    dispatch(
                        setContacts({ ...response.data, offset: queryParams.page ? (queryParams.page - 1) * 10 : 0 }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchAllContactsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setAllContacts({
                            items: [],
                            offset: 0,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(startAll());
            leadApi
                .get('leads' + query)
                .then((response) => {
                    dispatch(
                        setAllContacts({
                            ...response.data,
                            offset: queryParams.page ? (queryParams.page - 1) * 20 : 0,
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopAll());
            return console.error('error', e);
        }
    };

export const fetchOriginHistory =
    ({ contactId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            leadApi
                .get(`leads/${contactId}/history`)
                .then((response) => {
                    dispatch(setContactHistory(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addContactAction =
    ({ contactDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            leadApi
                .post('leads', contactDto)
                .then((response) => {
                    dispatch(
                        fetchContactsAction({
                            queryParams: {
                                page: 1,
                                expand: 'origins',
                            },
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const sendRemarketingEmailAction =
    ({ data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            leadApi
                .post('leads/remarketing-email', data)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess();
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const updateContactAction =
    ({ contactId, contactDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            leadApi
                .put('leads/' + contactId, contactDto)
                .then((response) => {
                    dispatch(
                        fetchContactsAction({
                            queryParams: {
                                page: 1,
                                expand: 'origins',
                            },
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const deleteContactAction = (contactId) => async (dispatch) => {
    try {
        leadApi
            .delete('leads/' + contactId)
            .then((response) => {
                dispatch(deleteContact(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const fetchContactsCsvAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(startDownload());
            leadApi
                .get(`leads/export-to-csv/${query}`)
                .then((response) => {
                    if (onSuccess) {
                        const fileName = response.headers['content-disposition'].split('filename=')[1];
                        onSuccess(response.data, fileName);
                    }
                    dispatch(stopDownload());
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopDownload());
            return console.error(e.message);
        }
    };

export const fetchContactOriginsAction =
    ({ onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            leadApi
                .get(`leads/origins`)
                .then((response) => {
                    dispatch(setContactOrigins(response.data));
                    onSuccess?.(response.data);
                    dispatch(stop());
                    return response;
                })
                .catch((error) => {
                    onFailure?.();
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export default contactSlice.reducer;
