import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateContactAction } from 'redux/slices/contactSlice';
import { closeNestedDialogAction } from 'redux/slices/nestedDialogSlice';
import ContactForm from './ContactForm';

const ContactEdit = ({ contact, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleSubmit = ({ contactDto, onSuccess, onFailure }) => {
        dispatch(
            updateContactAction({
                contactId: contact.id,
                contactDto,
                onSuccess: (d) => {
                    if (onSubmit) {
                        try {
                            onSubmit(d);
                        } catch (e) {
                            console.error(`Error executing edit contact callback: ${e.message}`);
                        }
                    }
                    onSuccess(d);
                    dispatch(closeNestedDialogAction());
                },
                onFailure: onFailure,
            }),
        );
    };

    return (
        <ContactForm
            action={t('contact.form.editContact')}
            contact={contact}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    );
};

export default ContactEdit;
