import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import invoiceSlice from 'redux/slices/invoiceSlice';

import actorReducer from 'redux/slices/actorSlice';
import addOnsActiveReducer from 'redux/slices/addOnsActiveSlice';
import addOnsPaidReducer from 'redux/slices/addOnsPaidSlice';
import addOnsReducer from 'redux/slices/addOnsSlice';
import appSlice from 'redux/slices/appSlice';
import assetReducer from 'redux/slices/assetSlice';
import authReducer from 'redux/slices/authSlice';
import calendarReducer from 'redux/slices/calendarSlice';
import callToActionReducer from 'redux/slices/callToActionSlice';
import contentInstanceSlice from 'redux/slices/contentInstanceSlice';
import contentSlice from 'redux/slices/contentSlice';
import couponsReducer from 'redux/slices/couponsSlice';
import customDialogReducer from 'redux/slices/customDialogSlice';
import customerReducer from 'redux/slices/customerSlice';
import dashboardReducer from 'redux/slices/dashboardSlice';
import discountReducer from 'redux/slices/discountSlice';
import eventGlobalReducer from 'redux/slices/eventGlobalSlice';
import eventReducer from 'redux/slices/eventSlice';
import eventStickerReducer from 'redux/slices/eventStickerSlice';
import gameReducer from 'redux/slices/gameSlice';
import videoHelpReducer from 'redux/slices/helpSlice';
import indexerAccountReducer from 'redux/slices/indexerAccountSlice';
import indexerApiCredentialReducer from 'redux/slices/indexerApiCredentialSlice';
import integrationErrorReducer from 'redux/slices/integrationErrorSlice';
import interactiveAppSlice from 'redux/slices/interactiveAppsSlice';
import invitationReducer from 'redux/slices/invitationSlice';
import launchReducer from 'redux/slices/launchSlice';
import licenseReducer from 'redux/slices/licenseSlice';
import multimediaReducer from 'redux/slices/multimediaSlice';
import ocalendarReducer from 'redux/slices/ocalendarSlice';
import organizationReducer from 'redux/slices/organizationSlice';
import paymentReducer from 'redux/slices/paymentSlice';
import productReducer from 'redux/slices/productSlice';
import profileReducer from 'redux/slices/profileSlice';
import reactionsReducer from 'redux/slices/reactionsSlice';
import requestReducer from 'redux/slices/requestSlice';
import searchEngineReducer from 'redux/slices/searchEngineSlice';
import settingReducer from 'redux/slices/settingSlice';
import shoppableReducer from 'redux/slices/shoppableSlice';
import socialReducer from 'redux/slices/socialSlice';
// import statisticsSlice from 'redux/slices/statisticsSlice';
import immersiveSlice from 'redux/slices/ImmersiveSlice';
import adminDashboardSlice from 'redux/slices/adminDashboardSlice';
import applicationReducer from 'redux/slices/applicationSlice';
import appointmentSlice from 'redux/slices/appointmentSlice';
import calendarNotificationSlice from 'redux/slices/calendarNotificationSlice';
import communicationLogReducer from 'redux/slices/communicationLogSlice';
import contactRequestSlice from 'redux/slices/contactRequestSlice';
import contactSlice from 'redux/slices/contactSlice';
import emailSlice from 'redux/slices/emailSlice';
import formReducer from 'redux/slices/formSlice';
import groupSlice from 'redux/slices/groupSlice';
import iCallReducer from 'redux/slices/iCallSlice';
import installationReducer from 'redux/slices/installationSlice';
import labelsReducer from 'redux/slices/labelsSlice';
import nestedDialogSlice from 'redux/slices/nestedDialogSlice';
import presetsReducer from 'redux/slices/presetSlice';
import pricingSlice from 'redux/slices/pricingSlice';
import productDiscountReducer from 'redux/slices/productDiscountSlice';
import realtimeSlice from 'redux/slices/realtimeSlice';
import serviceSlice from 'redux/slices/serviceSlice';
import shortVideoSlice from 'redux/slices/shortVideoSlice';
import stickerReducer from 'redux/slices/stickerSlice';
import streamConnectorReducer from 'redux/slices/streamConnectorsSlice';
import taxonomySlice from 'redux/slices/taxonomySlice';
import triageSlice from 'redux/slices/triageSlice';
import tvChannelSlice from 'redux/slices/tvChannelSlice';
import unitSlice from 'redux/slices/unitSlice';
import userReducer from 'redux/slices/userSlice';
import webhookReducer from 'redux/slices/webhookSlice';
import lostOpportunitySlice from './slices/lostOpportunitySlice';
import myAvailabilitySlice from './slices/myAvailabilitySlice';
import recordingSlice from './slices/recordingSlice';
import reportSlice from './slices/reportSlice';
import scriptSlice from './slices/scriptSlice';
import systemLogReducer from './slices/systemLogSlice';
import templateTypeSlice from './slices/templateTypeSlice';
import verticalManagementSlice from './slices/verticalManagementSlice';
import icalendarProviderSlice from './slices/icalendarProviderSlice';

export default configureStore({
    reducer: {
        product: productReducer,
        asset: assetReducer,
        event: eventReducer,
        game: gameReducer,
        customDialog: customDialogReducer,
        launch: launchReducer,
        actor: actorReducer,
        calendar: calendarReducer,
        multimedia: multimediaReducer,
        organization: organizationReducer,
        auth: authReducer,
        setting: settingReducer,
        user: userReducer,
        payment: paymentReducer,
        preset: presetsReducer,
        discount: discountReducer,
        // statistics: statisticsSlice,
        reactions: reactionsReducer,
        eventSticker: eventStickerReducer,
        licenses: licenseReducer,
        customers: customerReducer,
        addOns: addOnsReducer,
        requests: requestReducer,
        addOnsActive: addOnsActiveReducer,
        profile: profileReducer,
        shoppable: shoppableReducer,
        videoHelp: videoHelpReducer,
        coupons: couponsReducer,
        addOnsPaid: addOnsPaidReducer,
        eventGlobal: eventGlobalReducer,
        streamConnectors: streamConnectorReducer,
        social: socialReducer,
        invoices: invoiceSlice,
        searchEngine: searchEngineReducer,
        indexerApiCredential: indexerApiCredentialReducer,
        indexerAccount: indexerAccountReducer,
        invitations: invitationReducer,
        integrationError: integrationErrorReducer,
        callToActions: callToActionReducer,
        dashboard: dashboardReducer,
        ocalendar: ocalendarReducer,
        sticker: stickerReducer,
        content: contentSlice,
        interactiveApp: interactiveAppSlice,
        contentInstance: contentInstanceSlice,
        triage: triageSlice,
        app: appSlice,
        realtime: realtimeSlice,
        pricing: pricingSlice,
        applications: applicationReducer,
        installations: installationReducer,
        iCalls: iCallReducer,
        labels: labelsReducer,
        communicationLog: communicationLogReducer,
        calendarNotification: calendarNotificationSlice,
        tvChannel: tvChannelSlice,
        contact: contactSlice,
        adminDashboards: adminDashboardSlice,
        scripts: scriptSlice,
        shortVideo: shortVideoSlice,
        productDiscount: productDiscountReducer,
        nestedDialog: nestedDialogSlice,
        webhook: webhookReducer,
        form: formReducer,
        group: groupSlice,
        unit: unitSlice,
        taxonomy: taxonomySlice,
        service: serviceSlice,
        email: emailSlice,
        appointment: appointmentSlice,
        myAvailability: myAvailabilitySlice,
        systemLog: systemLogReducer,
        immersive: immersiveSlice,
        contactRequest: contactRequestSlice,
        lostOpportunity: lostOpportunitySlice,
        report: reportSlice,
        vertical: verticalManagementSlice,
        templateType: templateTypeSlice,
        recording: recordingSlice,
        icalendarProvider: icalendarProviderSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});
