import { Search, Sync } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import api from 'api';
import ActionButton from 'common/components/ActionButton';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomSelectValidator from 'common/components/CustomSelectValidators';
import OrganizationSyncProgress from 'common/components/OrganizationSyncProgress';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import {
    CLOUDSTORE,
    FILE_CSV,
    FILE_GOOGLE_FEED_ATOM,
    FILE_GOOGLE_FEED_RSS,
    INTERNAL_ONLIVE,
    isInternalIntegration,
    isInternalIntegrationFile,
    MODDO,
    PRESTASHOP,
    SHOPIFY_PRIVATE,
    WOOCOMMERCE,
} from 'common/constants/providerTypes';
import UserRole from 'common/enums/roles';
import { useAbortController } from 'common/hooks';
import { uploadFile } from 'common/utils/file';
import { useFormFields } from 'libs/hooksLib';
import * as moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import {
    fetchIntegrationErrorsListAction,
    getIntegrationErrors,
    getTotalIntegrationErrors,
} from 'redux/slices/integrationErrorSlice';
import {
    clearTestResultAction,
    editOrganizationProviderAction,
    fetchOrganizationProviderAction,
    getOrganization,
    getOrganizationProviderTestPending,
    getOrganizationProviderTestResult,
    getOrganizationSavePending,
    getProvider,
    testConnectionAction,
} from 'redux/slices/organizationSlice';
import {
    getProductsPending,
    syncCloudStoreProductsAction,
    syncWoocommerceProductsAction,
} from 'redux/slices/productSlice';
import { getIntegrationSettingsDirty, setIntegrationSettingsDirty } from 'redux/slices/profileSlice';
import { getLanguage } from 'redux/slices/settingSlice';

import useStylesSettings from '../settings/styles/useStylesSettings.styles';
import CloudstoreForm from './CloudstoreForm';
import FileIntegrationForm from './FileIntegrationForm';
import IntegrationRow from './IntegrationRow';
import PrestashopForm from './PrestashopForm';
import ShopifyPrivateForm from './ShopifyPrivateForm';
import useStylesIntegration from './styles/useStylesIntegrationRow.styles';
import WoocommerceForm from './WoocommerceForm';

const IntegrationV2 = () => {
    const integrationErrors = useSelector(getIntegrationErrors);
    const totalIntegrationErrors = useSelector(getTotalIntegrationErrors);
    const organization = useSelector(getOrganization);
    const provider = useSelector(getProvider);
    const testResult = useSelector(getOrganizationProviderTestResult);
    const language = useSelector(getLanguage);
    const productPending = useSelector(getProductsPending);
    const integrationSettingsDirty = useSelector(getIntegrationSettingsDirty);
    const savePending = useSelector(getOrganizationSavePending);
    const testPending = useSelector(getOrganizationProviderTestPending);
    const user = useSelector(getUser);
    const isAuthorized = user?.role === UserRole.ADMIN || user?.role === UserRole.ROOT;

    const [hasError, setHasError] = useState(false);

    const formRef = useRef(null);

    const [fields, handleFieldChange, resetForm] = useFormFields({
        type: 'onlive',
        accessToken: '',
        shopUri: '',
        username: '',
        password: '',
        idSite: '',
        apiKey: '',
        version: '',
        storeId: '',
        fileUrl: '',
        scheduleSync: false,
        updateFrequency: 12,
        fileFormat: null,
        externalSource: false,
        consumerKey: '',
        consumerSecret: '',
        auth: 'none',
    });

    const { classes: classes1 } = useStylesForm();
    const { classes: classes2, cx } = useStylesSettings();
    const { classes: classes3 } = useStylesIntegration();
    const classes = { ...classes1, ...classes2, ...classes3 };

    const dispatch = useDispatch();

    const limit = 4;
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [productsFile, setProductsFile] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const getSignal = useAbortController();
    const [disableSave, setDisableSave] = useState(true);

    const { t } = useTranslation();

    const integrationFieldChange = (event) => {
        dispatch(setIntegrationSettingsDirty(true));
        handleFieldChange(event);
        formRef.current.isFormValid().then((valid) => {
            setHasError(!valid);
        });
    };

    const handleChangeType = (event) => {
        dispatch(setIntegrationSettingsDirty(true));
        const initialProvider =
            (provider?.type === event.target.value && provider.type !== INTERNAL_ONLIVE) ||
            (provider?.externalSource === isInternalIntegrationFile(event.target.value) &&
                provider?.fileFormat === event.target.value);
        resetForm({
            type: event.target.value,
            fileFormat: (initialProvider && provider?.fileFormat) || null,
            fileUrl: (initialProvider && provider?.fileUrl) || '',
            accessToken: (initialProvider && provider?.accessToken) || '',
            shopUri: (initialProvider && provider?.shopUri) || '',
            username: (initialProvider && provider?.username) || '',
            password: (initialProvider && provider?.password) || '',
            idSite: (initialProvider && provider?.idSite) || '',
            apiKey: (initialProvider && provider?.apiKey) || '',
            version: (initialProvider && provider?.version) || '',
            storeId: (initialProvider && provider?.storeId) || '',
            scheduleSync: (initialProvider && provider?.scheduleSync) || false,
            updateFrequency: (initialProvider && provider?.updateFrequency) || 12,
            externalSource: (initialProvider && provider?.externalSource) || false,
            consumerKey: (initialProvider && provider?.consumerKey) || '',
            consumerSecret: (initialProvider && provider?.consumerSecret) || '',
            auth: Boolean((initialProvider && provider?.username) || (initialProvider && provider?.password))
                ? 'basic'
                : 'none',
        });
    };

    const { enqueueSnackbar } = useSnackbar();

    const loadData = useCallback(
        (loadPage = 1) => {
            const queryParams = {
                offset: (loadPage - 1) * limit,
                limit,
                ...(search && { title: search }),
            };
            dispatch(fetchIntegrationErrorsListAction(queryParams));
        },
        [dispatch, search],
    );

    useEffect(() => {
        dispatch(fetchOrganizationProviderAction());
    }, [dispatch]);

    useEffect(() => {
        setPage(1);
        loadData();
    }, [loadData, search]);

    useEffect(() => {
        resetForm({
            // type: provider?.type || 'onlive',
            type:
                (provider?.type === INTERNAL_ONLIVE ? provider.fileFormat || provider?.type : provider?.type) ||
                INTERNAL_ONLIVE,
            fileFormat: provider?.fileFormat || null,
            fileUrl: provider?.fileUrl || '',
            accessToken: provider?.accessToken || '',
            shopUri: provider?.shopUri || '',
            username: provider?.username || '',
            password: provider?.password || '',
            idSite: provider?.idSite || '',
            apiKey: provider?.apiKey || '',
            version: provider?.version || '',
            storeId: provider?.storeId || '',
            scheduleSync: provider?.scheduleSync || false,
            updateFrequency: provider?.updateFrequency || 12,
            externalSource: provider?.externalSource || false,
            consumerKey: provider?.consumerKey || '',
            consumerSecret: provider?.consumerSecret || '',
            auth: Boolean(provider?.username || provider?.password) ? 'basic' : 'none',
        });
    }, [provider, resetForm]);

    useEffect(() => {
        if (testResult) {
            enqueueSnackbar(`Connection ${testResult}`, {
                variant: testResult,
            });
            dispatch(clearTestResultAction());
        }
    }, [dispatch, enqueueSnackbar, testResult]);

    function getDto() {
        if (isInternalIntegration(fields.type)) {
            return {
                type: INTERNAL_ONLIVE,
                fileFormat: isInternalIntegrationFile(fields.type) ? fields.type : null,
                fileUrl: fields.fileUrl,
                scheduleSync: fields.fileUrl ? fields.scheduleSync : false,
                updateFrequency: fields.updateFrequency,
                externalSource: isInternalIntegrationFile(fields.type), // Some file format
                ...(fields.fileUrl &&
                    fields.auth === 'basic' && { username: fields.username, password: fields.password }),
            };
        }
        if (fields.type === SHOPIFY_PRIVATE) {
            return {
                type: SHOPIFY_PRIVATE,
                accessToken: fields.accessToken,
                shopUri: fields.shopUri,
            };
        }
        if (fields.type === MODDO) {
            return {
                type: MODDO,
                username: fields.username,
                password: fields.password,
                idSite: fields.idSite,
            };
        }
        if (fields.type === CLOUDSTORE) {
            return {
                type: CLOUDSTORE,
                storeId: fields.storeId,
                accessToken: fields.accessToken,
            };
        }
        if (fields.type === WOOCOMMERCE) {
            return {
                type: WOOCOMMERCE,
                shopUri: fields.shopUri,
                consumerKey: fields.consumerKey,
                consumerSecret: fields.consumerSecret,
            };
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const providerDto = getDto();

        await dispatch(editOrganizationProviderAction(providerDto, (e) => submitMessage(e)));

        if (isInternalIntegrationFile(fields.type)) {
            await requestSync();
        }

        setIntegrationSettingsDirty(false);
        if (productsFile && (!fields.fileFormat || fields.fileUrl)) {
            setProductsFile('');
        }
    };

    const submitMessage = (e) => {
        if (e === 'success') {
            dispatch(setIntegrationSettingsDirty(false));
            enqueueSnackbar(t('profile.ecommerceIntegration.integration.saveSuccess'), {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(t('profile.ecommerceIntegration.integration.integrationError'), {
                variant: 'error',
            });
        }
    };

    function handleError() {
        setHasError(true);
        enqueueSnackbar(t('profile.ecommerceIntegration.integration.formError'), {
            variant: 'error',
        });
    }

    const validForm = useCallback(() => {
        if (isInternalIntegration(fields.type)) {
            if (!fields.fileFormat) {
                return true;
            }
        } else if (fields.type === SHOPIFY_PRIVATE) {
            // TODO: implement
        }
        return !hasError;
    }, [fields.fileFormat, fields.type, hasError]);

    const handlePaginationChange = (e, value) => {
        setPage(value);
        const queryParams = {
            offset: (value - 1) * limit,
            limit,
            ...(search && { title: search }),
        };
        dispatch(fetchIntegrationErrorsListAction(queryParams));
    };

    const requestSyncMessage = (e) => {
        if (e === 'success') {
            enqueueSnackbar(t('profile.ecommerceIntegration.integration.syncRequestSuccess'), {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(t('profile.ecommerceIntegration.integration.syncRequestError'), {
                variant: 'error',
            });
        }
    };

    const syncRequest = () => {
        if (organization.provider === 'cloudstore' || organization.provider?.type === 'cloudstore') {
            dispatch(syncCloudStoreProductsAction(organization.id, (e) => requestSyncMessage(e)));
        } else if (organization.provider === 'woocommerce' || organization.provider?.type === 'woocommerce') {
            dispatch(syncWoocommerceProductsAction(organization.id, (e) => requestSyncMessage(e)));
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const getChipText = () => {
        return organization?.syncStartedAt
            ? moment(organization?.syncStartedAt).locale(language).tz('Europe/Madrid').format('ddd MMM D, YYYY h:mma Z')
            : t('profile.ecommerceIntegration.unsynchronized');
    };

    const requestSync = async () => {
        if (!fields.fileUrl && productsFile) {
            // setAllowSync(false);
            uploadFile(
                productsFile,
                {
                    size: 1024 * 1024 * 5, // Decreased to 5mb due to aws api gateway limitations
                    onInit: (size) => {
                        setUploading(true);
                    },
                    onProgress: (loaded, total) => {
                        setProgress(Math.min(100, Math.round((loaded / total) * 100)));
                    },
                    onComplete: (url) => {
                        api.post('onlive-integration/sync-uploaded-products-file', { url })
                            .then((response) => {
                                setUploading(false);
                                setProductsFile('');
                                // setOpenDialog(false);
                                setProgress(0);
                                if (response.error) {
                                    enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncFailed'), {
                                        variant: 'error',
                                    });
                                    return null;
                                } else {
                                    if (response.data) {
                                        enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncStarted'), {
                                            variant: 'success',
                                        });
                                    } else {
                                        enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncFailed'), {
                                            variant: 'error',
                                        });
                                    }
                                    return response.data;
                                }
                            })
                            .catch((error) => {
                                console.error('Error during sync request.');
                                console.error(error);
                                setUploading(false);
                                setProductsFile('');
                                // setOpenDialog(false);
                                setProgress(0);
                                enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncFailed'), {
                                    variant: 'error',
                                });
                            });
                    },
                },
                getSignal(),
            );
            return;
        } else if (fields.fileUrl && !integrationSettingsDirty) {
            // setAllowSync(false);
            return api
                .post('onlive-integration/sync-products-file', {})
                .then((response) => {
                    if (response.error) {
                        enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncFailed'), { variant: 'error' });
                        return null;
                    } else {
                        if (response.data) {
                            enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncStarted'), {
                                variant: 'success',
                            });
                        } else {
                            enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncFailed'), { variant: 'error' });
                        }
                        return response.data;
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t('profile.ecommerceIntegration.onlive.syncFailed'), { variant: 'error' });
                });
        }
    };

    useEffect(() => {
        setDisableSave((!integrationSettingsDirty && !productsFile) || !validForm());
    }, [fields, integrationSettingsDirty, productsFile, hasError, validForm]);

    return (
        <div className={classes.root}>
            <div className={cx(classes.container, classes.scroll)}>
                <div className={cx(classes.body, classes.mW80)}>
                    <OrganizationSyncProgress
                        clickHereAction={true}
                        clickHereFn={() => {}}
                        showClickHereMessage={isAuthorized}
                    />
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('profile.ecommerceIntegration.tabs.connection')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.eventSection}>
                            <Box style={{ width: '100%' }}>
                                {(!provider || savePending || testPending || uploading) && (
                                    <div className={classes.pending}>
                                        <CircularProgress color="primary" />
                                    </div>
                                )}

                                <ValidatorForm
                                    ref={formRef}
                                    onSubmit={handleSubmit}
                                    className={classes.formIntegration}
                                    onError={handleError}
                                >
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel>{t('profile.ecommerceIntegration.integration.provider')}</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            <CustomSelectValidator
                                                name="type"
                                                fullWidth
                                                // className={classes.selector}
                                                value={fields?.type}
                                                onChange={handleChangeType}
                                                // InputLabelProps={{ shrink: false }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                disabled={fields.type === CLOUDSTORE}
                                            >
                                                <MenuItem key={INTERNAL_ONLIVE} value={INTERNAL_ONLIVE}>
                                                    {t('profile.ecommerceIntegration.integration.none')}
                                                </MenuItem>
                                                <MenuItem key={FILE_CSV} value={FILE_CSV}>
                                                    CSV
                                                </MenuItem>
                                                <MenuItem key={FILE_GOOGLE_FEED_RSS} value={FILE_GOOGLE_FEED_RSS}>
                                                    {t('profile.ecommerceIntegration.onlive.fileFormat.googleFeedRss')}
                                                </MenuItem>
                                                <MenuItem key={FILE_GOOGLE_FEED_ATOM} value={FILE_GOOGLE_FEED_ATOM}>
                                                    {t('profile.ecommerceIntegration.onlive.fileFormat.googleFeedAtom')}
                                                </MenuItem>
                                                <MenuItem key={SHOPIFY_PRIVATE} value={SHOPIFY_PRIVATE}>
                                                    {t('profile.ecommerceIntegration.integration.shopify')}
                                                </MenuItem>
                                                {/* <MenuItem key="moddo" value="moddo">{t("profile.ecommerceIntegration.integration.moddo")}</MenuItem> */}
                                                {fields.type === PRESTASHOP && (
                                                    <MenuItem key={PRESTASHOP} value={PRESTASHOP}>
                                                        {t('profile.ecommerceIntegration.integration.prestashop')}
                                                    </MenuItem>
                                                )}
                                                {fields.type === CLOUDSTORE && (
                                                    <MenuItem key={CLOUDSTORE} value={CLOUDSTORE}>
                                                        {t('profile.ecommerceIntegration.integration.cloudstore')}
                                                    </MenuItem>
                                                )}
                                                {fields.type === WOOCOMMERCE && (
                                                    <MenuItem key={WOOCOMMERCE} value={WOOCOMMERCE}>
                                                        {t('profile.ecommerceIntegration.integration.woocommerce')}
                                                    </MenuItem>
                                                )}
                                            </CustomSelectValidator>
                                        </FormControl>
                                    </FormGroup>
                                    {fields.type === SHOPIFY_PRIVATE && (
                                        <ShopifyPrivateForm
                                            fields={fields}
                                            handleFieldChange={integrationFieldChange}
                                        />
                                    )}
                                    {fields.type === PRESTASHOP && (
                                        <PrestashopForm fields={fields} handleFieldChange={integrationFieldChange} />
                                    )}
                                    {fields.type === CLOUDSTORE && (
                                        <CloudstoreForm fields={fields} handleFieldChange={integrationFieldChange} />
                                    )}
                                    {fields.type === WOOCOMMERCE && (
                                        <WoocommerceForm fields={fields} handleFieldChange={integrationFieldChange} />
                                    )}

                                    {isInternalIntegrationFile(fields.type) && (
                                        <FileIntegrationForm
                                            fields={fields}
                                            handleFieldChange={integrationFieldChange}
                                            productsFile={productsFile}
                                            setProductsFile={setProductsFile}
                                            uploading={uploading}
                                            progress={progress}
                                        />
                                    )}
                                    <div className={cx(classes.actions, classes.jcSb)}>
                                        {!isInternalIntegration(fields?.type) && (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disabled={!validForm()}
                                                className={cx(classes.cancelButton, classes.mr10px)}
                                                onClick={() => dispatch(testConnectionAction(fields))}
                                            >
                                                <span>{t('profile.ecommerceIntegration.integration.test')}</span>
                                            </Button>
                                        )}
                                        {isInternalIntegrationFile(fields.type) &&
                                            fields?.fileUrl &&
                                            !integrationSettingsDirty && (
                                                <Button
                                                    className={cx(classes.cancelButton, classes.mr10px)}
                                                    disabled={!validForm() || organization?.syncing}
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<Sync />}
                                                    onClick={requestSync}
                                                    component="label"
                                                >
                                                    <span>{t('profile.ecommerceIntegration.onlive.synchronize')}</span>
                                                </Button>
                                            )}
                                        <ActionButton
                                            disabled={disableSave}
                                            type="submit"
                                            disableElevation={true}
                                            className={cx(classes.proceedButton, classes.buttonSave)}
                                        >
                                            <span>{t('profile.ecommerceIntegration.integration.save')}</span>
                                        </ActionButton>
                                    </div>
                                </ValidatorForm>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="subtitle1" fontWeight={500}>
                                {t('profile.ecommerceIntegration.tabs.syncStatus')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.eventSection}>
                            <Box style={{ width: '100%' }}>
                                <TextField
                                    placeholder={t('event.event.search')}
                                    className={classes.searchTextField}
                                    value={search}
                                    onChange={handleSearchChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CustomIconButton
                                                    aria-label="search"
                                                    size="small"
                                                    disabled
                                                    hideBorder={true}
                                                    classes={{ disabled: classes.disabledIconButton }}
                                                >
                                                    <Search />
                                                </CustomIconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    size="small"
                                />

                                <div className={classes.mb1}></div>

                                {productPending && (
                                    <Box className={classes.mb1}>
                                        <LinearProgress />
                                    </Box>
                                )}
                                <Grid container className={classes.root} spacing={1}>
                                    <Grid item xs={8}>
                                        {!organization?.syncing ? (
                                            <Chip
                                                icon={<DoneIcon />}
                                                label={
                                                    t('profile.ecommerceIntegration.lastSyncAt') + ' ' + getChipText()
                                                }
                                                variant="outlined"
                                                color="primary"
                                            />
                                        ) : (
                                            <Chip
                                                icon={<SyncIcon />}
                                                label={
                                                    t('profile.ecommerceIntegration.syncing') +
                                                    ' ' +
                                                    (organization?.synchronizedProducts + organization?.syncErrors) +
                                                    ' ' +
                                                    t('profile.ecommerceIntegration.products') +
                                                    ' ' +
                                                    t('profile.ecommerceIntegration.of') +
                                                    ' ' +
                                                    organization?.totalProductsToSync
                                                }
                                                variant="outlined"
                                                color="primary"
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {(organization.provider === 'cloudstore' ||
                                                organization.provider?.type === 'cloudstore' ||
                                                organization.provider === 'woocommerce' ||
                                                organization.provider?.type === 'woocommerce') && (
                                                <Button
                                                    className={classes.searchButton}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={syncRequest}
                                                >
                                                    {t('profile.ecommerceIntegration.resyncProduct')}
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>

                                {integrationErrors.length > 0 && (
                                    <div>
                                        <Box>
                                            <Typography className={classes.mb1} variant="span" component="span">
                                                {t('profile.ecommerceIntegration.titles.invalidProducts')}
                                            </Typography>

                                            <Paper>
                                                <Table
                                                    className={classes.table + ' ' + classes.mt1}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell className={classes.p1}>
                                                                {t('profile.ecommerceIntegration.tableHead.product')}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {t('profile.ecommerceIntegration.tableHead.productId')}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {t('profile.ecommerceIntegration.tableHead.errors')}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {t('profile.ecommerceIntegration.tableHead.storeUrl')}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {integrationErrors.map((row) => (
                                                            <IntegrationRow key={row.productName} row={row} />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Paper>
                                        </Box>
                                        <div className={classes.paginator}>
                                            <Pagination
                                                color="primary"
                                                count={Math.max(1, Math.ceil(totalIntegrationErrors / limit))}
                                                page={page}
                                                onChange={handlePaginationChange}
                                                className={classes.integrationErrorsPagination}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default IntegrationV2;
