import { AttachFile, CloudUpload } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
} from '@mui/material';
import CircularProgressWithLabel from 'common/components/CircularProgressWithLabel';
import FileInput from 'common/components/FileInput';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import config from 'config';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { getOrganization } from 'redux/slices/organizationSlice';
import validator from 'validator';
import CustomSelectValidator from '../../../common/components/CustomSelectValidators';
import useStylesEcommerceIntegrationOnliveForm from './styles/useStylesIntegrationOnliveForm.styles';

const FileIntegrationForm = (props) => {
    const { fields, handleFieldChange, productsFile, setProductsFile, uploading, progress } = props;
    const { t } = useTranslation();
    const { classes: classes1 } = useStylesEcommerceIntegrationOnliveForm();
    const { classes: classes2 } = useStylesForm();
    const classes = { ...classes1, ...classes2 };
    const URL_SOURCE = 'url-source';
    const UPLOADED_FILE_SOURCE = 'uploaded-file-source';

    const organization = useSelector(getOrganization);

    useEffect(() => {
        ValidatorForm.addValidationRule('isUrl', (value) => {
            return validator.isURL(value);
        });

        return () => {
            ValidatorForm.removeValidationRule('isUrl');
        };
    }, []);

    const [fileSourceType, setFileSourceType] = useState(fields?.fileUrl ? URL_SOURCE : UPLOADED_FILE_SOURCE);

    const { enqueueSnackbar } = useSnackbar();

    function handleUploadFile(file, products) {
        if (products && file) {
            if (file?.size > 1610612736) {
                enqueueSnackbar(t('profile.ecommerceIntegration.onlive.invalidFileSize'), { variant: 'error' });
                setProductsFile('');
            } else {
                setProductsFile(file);
                enqueueSnackbar(t('profile.ecommerceIntegration.onlive.productsFileSelected'), { variant: 'success' });
            }
        }
    }

    const onDataSourceChange = (event) => {
        setFileSourceType(event.target.value);
        event.target.value === UPLOADED_FILE_SOURCE &&
            handleFieldChange({
                target: {
                    name: 'fileUrl',
                    value: '',
                },
            });
    };

    return (
        <FormGroup className={classes.formGroup}>
            <FormGroup className={classes.formGroup}>
                <FormLabel id="demo-radio-buttons-group-label">{t('profile.fileIntegrationForm.FileOrigin')}</FormLabel>
                <RadioGroup
                    name="fileSource"
                    value={fileSourceType || UPLOADED_FILE_SOURCE}
                    defaultValue={fileSourceType}
                    onChange={onDataSourceChange}
                    style={{ display: 'flex', flexDirection: 'row' }}
                >
                    <FormControlLabel
                        value={URL_SOURCE}
                        control={<Radio />}
                        label={t('profile.fileIntegrationForm.FileOriginUrl')}
                    />
                    <FormControlLabel
                        value={UPLOADED_FILE_SOURCE}
                        control={<Radio />}
                        label={t('profile.fileIntegrationForm.FileOriginComputer')}
                    />
                </RadioGroup>
            </FormGroup>

            {fileSourceType === URL_SOURCE && (
                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.ecommerceIntegration.onlive.fileUrl')}</FormLabel>
                    <FormControl className={classes.formControl}>
                        <TextValidator
                            name="fileUrl"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.fileUrl}
                            onChange={handleFieldChange}
                            validators={['isUrl']}
                            errorMessages={[t('profile.ecommerceIntegration.fields.validationErrors.fieldUrl')]}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!validator.isURL(fields.fileUrl)}
                                    name="scheduleSync"
                                    checked={fields.scheduleSync}
                                    onChange={handleFieldChange}
                                    color="primary"
                                />
                            }
                            label={t('profile.ecommerceIntegration.onlive.scheduleSync')}
                        />
                    </FormControl>

                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{t('profile.ecommerceIntegration.onlive.auth')}</FormLabel>
                        <FormControl className={classes.formControl}>
                            <CustomSelectValidator
                                name="auth"
                                fullWidth
                                // className={classes.selector}
                                value={fields?.auth}
                                onChange={handleFieldChange}
                                // InputLabelProps={{ shrink: false }}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                variant="outlined"
                                size="small"
                                disabled={!validator.isURL(fields.fileUrl)}
                            >
                                <MenuItem key="none" value="none">
                                    {t('profile.ecommerceIntegration.onlive.noAuth')}
                                </MenuItem>
                                <MenuItem key="basic" value="basic">
                                    {t('profile.ecommerceIntegration.onlive.basicAuth')}
                                </MenuItem>

                            </CustomSelectValidator>
                        </FormControl>
                    </FormGroup>

                    {(fields?.auth === 'basic') && <>
                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.ecommerceIntegration.onlive.username')}</FormLabel>
                            <FormControl className={classes.formControl}>
                                <TextValidator
                                    name="username"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    value={fields.username}
                                    onChange={handleFieldChange}
                                    validators={['required']}
                                    errorMessages={[t('profile.ecommerceIntegration.fields.validationErrors.fieldPassword')]}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.ecommerceIntegration.onlive.password')}</FormLabel>
                            <FormControl className={classes.formControl}>
                                <TextValidator
                                    name="password"
                                    type="password"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    value={fields.password}
                                    onChange={handleFieldChange}
                                    validators={['required']}
                                    errorMessages={[t('profile.ecommerceIntegration.fields.validationErrors.fieldPassword')]}
                                />
                            </FormControl>
                        </FormGroup>
                    </>}
                    <FormGroup className={classes.formGroup}>
                        <FormLabel>{t('profile.ecommerceIntegration.onlive.updateFrequency')}</FormLabel>
                        <FormControl className={classes.formControl}>
                            <TextValidator
                                type="number"
                                disabled={!validator.isURL(fields.fileUrl) || !fields.scheduleSync}
                                inputProps={{ min: 6 }}
                                name="updateFrequency"
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                value={fields.updateFrequency}
                                onChange={handleFieldChange}
                                validators={['minNumber:6']}
                                errorMessages={[
                                    t('profile.ecommerceIntegration.fields.validationErrors.updateFrequencyMinValue'),
                                ]}
                            />
                        </FormControl>
                    </FormGroup>
                </FormGroup>
            )}

            {fileSourceType === UPLOADED_FILE_SOURCE && (
                <FormGroup className={classes.formGroup}>
                    <FormControl className={classes.formControl}>
                        <Grid container className={classes.mb1} direction="row">
                            {uploading && (
                                <Grid direction={'row'} container alignItems={'center'} spacing={3}>
                                    <Grid item>
                                        <span>{t('profile.fileIntegrationForm.UploadingFile')}</span>
                                    </Grid>
                                    <Grid item>
                                        <CircularProgressWithLabel value={progress} />
                                    </Grid>
                                </Grid>
                            )}
                            {!uploading && (
                                <Grid container className={classes.mb1} direction="row">
                                    <Grid item md={6}>
                                        <Button
                                            variant="outlined"
                                            className={classes.button}
                                            startIcon={<CloudUpload />}
                                            component="label"
                                        >
                                            <FileInput
                                                textFormats={config?.media?.dataFile?.allowedFormats || ['CSV']}
                                                imageFormats={[]}
                                                videoFormats={[]}
                                                customMimeTypes={['application/vnd.ms-excel']}
                                                inputId={'product-data-file-input'}
                                                uploadAssetFn={(file) => handleUploadFile(file, true)}
                                                disabled={organization?.syncing}
                                            />
                                            {t('profile.fileIntegrationForm.SelectFile')}
                                        </Button>
                                    </Grid>
                                    <Grid item md={6}>
                                        {productsFile && (
                                            <FormLabel className={classes.fileUploadedLabel}>
                                                <AttachFile />
                                                {productsFile.name}
                                            </FormLabel>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </FormControl>
                </FormGroup>
            )}
        </FormGroup>
    );
};

export default FileIntegrationForm;
