import { createSlice } from '@reduxjs/toolkit';

import { calendarApiV3 as api } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const serviceSlice = createSlice({
    name: 'service',
    initialState: {
        count: 0,
        total: 0,
        pending: false,
        savePending: false,
        services: [],
        currentPage: 1,
        totalPages: 1,
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        scheduledCalendarsIds: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addService: (state, action) => {
            state.savePending = false;
            state.services = [action.payload, ...state.services];
        },
        editService: (state, action) => {
            state.savePending = false;
            const index = state.services.findIndex((service) => service.id === action.payload.id);
            if (index !== -1) {
                state.services = [
                    ...state.services.slice(0, index),
                    { ...state.services[index], ...action.payload },
                    ...state.services.slice(index + 1),
                ];
            }
        },
        deleteService: (state, action) => {
            state.deletePending = false;
            const index = state.services.findIndex((service) => service.id === action.payload.id);
            if (index !== -1) {
                state.services = [...state.services.slice(0, index), ...state.services.slice(index + 1)];
            }
        },
        setServices: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.services = action.payload.items;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        setScheduledCalendarsIds: (state, action) => {
            state.pending = false;
            state.scheduledCalendarsIds = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setServices,
    stopDelete,
    setError,
    addService,
    editService,
    deleteService,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
    setScheduledCalendarsIds,
} = serviceSlice.actions;

export const getServices = (state) => state.service.services;
export const getServicesCount = (state) => state.service.count;
export const getServicesTotal = (state) => state.service.total;
export const getServicesCurrentPage = (state) => state.service.currentPage;
export const getServicesTotalPages = (state) => state.service.totalPages;
export const getServicesPending = (state) => state.service.pending;
export const getServicesSavePending = (state) => state.service.savePending;
export const getDeletePending = (state) => state.service.deletePending;
export const getDeleteId = (state) => state.service.deleteId;
export const getServicesError = (state) => state.service.error;
export const getScheduledCalendarsIds = (state) => state.service.scheduledCalendarsIds;

export const fetchServicesAction =
    ({ queryParams, onSuccess, onFailure, getResponse } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get(`templates` + query)
                .then((response) => {
                    if (getResponse) {
                        getResponse(response.data);
                    } else {
                        dispatch(setServices(response.data));
                    }
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchServiceScheduledCalendarIdsAction =
    ({ id, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get(`templates/${id}/calendar-ids` + query)
                .then((response) => {
                    dispatch(setScheduledCalendarsIds(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addServiceAction =
    ({ organizationId, serviceDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(`organizations/${organizationId}/templates`, serviceDto)
                .then((response) => {
                    dispatch(addService(response?.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editServiceAction =
    ({ id, serviceDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('templates/' + id, serviceDto)
                .then((response) => {
                    dispatch(editService(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteServiceAction = (id, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startDelete(id));
        api.delete('templates/' + id)
            .then((response) => {
                dispatch(deleteService({ id }));
                onSuccess?.();
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                onFailure?.();
            });
    } catch (e) {
        dispatch(stopDelete());
        return console.error('error', e);
    }
};

export default serviceSlice.reducer;
