import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        pending: false,
        invoices: [],
        totalAmountDue: 0,
        totalAmountPaid: 0,
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        addInvoice: (state, action) => {
            state.pending = false;
            state.invoices = [action.payload, ...state.invoices];
        },
        setInvoices: (state, action) => {
            state.pending = false;
            state.invoices = action.payload;
            state.totalAmountDue = action.payload.reduce((sum, invoice) => sum + invoice.amountDue, 0);
            state.totalAmountPaid = action.payload.reduce((sum, invoice) => sum + invoice.amountPaid, 0);
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        editInvoice: (state, action) => {
            state.pending = false;
            const index = state.invoices.findIndex((invoice) => invoice.id === action.payload.id);
            if (index !== -1) {
                state.invoices = [
                    ...state.invoices.slice(0, index),
                    action.payload,
                    ...state.invoices.slice(index + 1),
                ];
            }
        },
        deleteInvoice: (state, action) => {
            state.pending = false;
            const index = state.invoices.findIndex((invoice) => invoice.id === action.payload.id);
            if (index !== -1) {
                state.invoices = [...state.invoices.slice(0, index), ...state.invoices.slice(index + 1)];
            }
        },
    },
});

export const { start, stop, setInvoices, setError, editInvoice, deleteInvoice, addInvoice } = invoiceSlice.actions;

export const getInvoices = (state) => state.invoices.invoices;
export const getTotalAmountDue = (state) => state.invoices.totalAmountDue;
export const getTotalAmountPaid = (state) => state.invoices.totalAmountPaid;
export const getInvoicesPending = (state) => state.invoices.pending;
export const getInvoicesError = (state) => state.invoices.error;

export const fetchInvoicesAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('invoices' + query)
            .then((response) => {
                dispatch(setInvoices(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default invoiceSlice.reducer;
