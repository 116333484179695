import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useFlexBoxStyles from 'common/flexBox';
import { RenderDateInput } from 'common/utils/utils';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        '.MuiPickersCalendarHeader-label, .MuiPickersDay-root, .MuiDayPicker-weekDayLabel': {
            ...theme.typography.subtitle2,
        },
    },
}));

const DateInput = (props) => {
    const { id, value, onChange, minDate, maxDate, type, size = 'medium' } = props;
    const { classes } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const [date, setDate] = useState(value);
    const [finishedSelection, setFinishedSelection] = useState(false);
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (finishedSelection) {
            const delayDebounceFn1 = setTimeout(() => {
                setFinishedSelection(false);
                onChange(date);
            }, 100);
            return () => clearTimeout(delayDebounceFn1);
        }
    }, [finishedSelection, date, onChange]);

    useEffect(() => {
        if (closed) {
            const delayDebounceFn2 = setTimeout(() => {
                setClosed(false);
                setFinishedSelection(true);
            }, 100);
            return () => clearTimeout(delayDebounceFn2);
        }
    }, [closed]);

    const onDateChange = (newDate, context) => {
        setDate(newDate);
    };

    const onClose = () => {
        setClosed(true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {(!type || type === 'date') && (
                <DatePicker
                    classes={{ root: classes.root }}
                    PaperProps={{
                        classes: { root: flexBox.borderRadius2 },
                    }}
                    value={value}
                    onChange={onDateChange}
                    onClose={onClose}
                    variant="inline"
                    inputVariant="outlined"
                    minDate={minDate}
                    maxDate={maxDate}
                    format="dd/MM/yyyy"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <RenderDateInput id={id} {...params} size={size} bg="#FFFF" />}
                />
            )}
            {type === 'date-time' && (
                <DateTimePicker
                    classes={{ root: classes.root }}
                    value={value}
                    onChange={onDateChange}
                    onClose={onClose}
                    variant="inline"
                    inputVariant="outlined"
                    minDate={minDate}
                    maxDate={maxDate}
                    views={['day', 'hours', 'minutes']}
                    inputFormat="dd/MM/yyyy hh:mm a"
                    renderInput={(params) => (
                        <RenderDateInput
                            id={id}
                            {...params}
                            size={size}
                            bg="#FFFF"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    )}
                />
            )}
            {type === 'time' && (
                <TimePicker
                    value={value}
                    classes={{ root: classes.root }}
                    PaperProps={{
                        classes: { root: flexBox.borderRadius2 },
                    }}
                    onChange={onDateChange}
                    onClose={onClose}
                    variant="inline"
                    size="small"
                    inputVariant="outlined"
                    minDate={minDate}
                    maxDate={maxDate}
                    ampm={false}
                    views={['hours', 'minutes', 'seconds']}
                    format="HH:mm:ss"
                    inputFormat="HH:mm:ss"
                    renderInput={(params) => <RenderDateInput id={id} {...params} size={size} bg="#FFFF" />}
                />
            )}
        </LocalizationProvider>
    );
};

export default DateInput;
