import { Button, FormGroup, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editOrganizationVerticalAction, getOrganization } from 'redux/slices/organizationSlice';
import { fetchVerticalsAction, getVerticals } from 'redux/slices/verticalManagementSlice';
import useStylesSettings from '../styles/useStylesSettings.styles';

const OrganizationVerticalManagementSetting = () => {
    const { t } = useTranslation();
    const { classes } = useStylesSettings();
    const dispatch = useDispatch();
    const organization = useSelector(getOrganization);
    const verticals = useSelector(getVerticals);
    const [verticalSlug, setVerticalSlug] = useState(organization?.verticalSlug || ' ');

    useEffect(() => {
        dispatch(fetchVerticalsAction());
    }, [dispatch]);

    const canSave = () => {
        return (
            verticalSlug !== organization?.verticalSlug &&
            !(verticalSlug === ' ' && organization?.verticalSlug === null)
        );
    };

    const handleSave = async () => {
        try {
            dispatch(editOrganizationVerticalAction({ slug: verticalSlug === ' ' ? null : verticalSlug }));
        } catch (error) {
            console.error('Error saving vertical setting.');
            console.error(error);
        }
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mt={2}>
            <FormGroup>
                <InputLabel>{t('profile.settings.newFeaturesSettings.organizationVericalSlug')}</InputLabel>
                <Select
                    name={'verticalManagement'}
                    value={verticalSlug}
                    onChange={(event) => setVerticalSlug(event.target.value)}
                >
                    <MenuItem key="none" value=" ">
                        <b>{t('common.none')}</b>
                    </MenuItem>
                    {verticals.map(({ slug, id, name }) => (
                        <MenuItem value={slug} key={`settingList.${id}`}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormGroup>

            {canSave() && (
                <Button variant="contained" className={classes.redColor} size="small" onClick={handleSave}>
                    {t('Save changes')}
                </Button>
            )}
        </Stack>
    );
};

export default OrganizationVerticalManagementSetting;
