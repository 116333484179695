import { Stack, Tabs } from '@mui/material';
import { CustomTab, TabPanel, a11yProps } from 'common/components/TabPanel';
import YesNoDialog from 'common/components/YesNoDialog';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser, testFeatureEnabled } from 'redux/slices/authSlice';
import {
    getIntegrationSettingsDirty,
    getMyAccountSettingsDirty,
    getOrganizationSettingsDirty,
    getSettingsDirty,
    setIntegrationSettingsDirty,
    setMyAccountSettingsDirty,
    setOrganizationSettingsDirty,
    setSettingsDirty,
} from 'redux/slices/profileSlice';

import UserRole from 'common/enums/roles';
import { messageNotAuthorize } from 'common/utils/CONST';
import VodDeliveryDashboard from '../dashboard/components/VodDeliveryDashboard';
import MyAccount from './MyAccount';
import MyAvailability from './availability/MyAvailability';
import MyAvailabilityCreateAction from './availability/MyAvailabilityCreateAction';
import IntegrationV2 from './ecommerceIntegration/IntegrationV2';
import Organization from './organization/Organization';
import Security from './security/Security';
import Settings from './settings/Settings';
import useStylesProfile from './styles/useStylesProfile.styles';

const INTEGRATION_COMPONENT = 'INTEGRATION_COMPONENT';

const Profile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { classes } = useStylesProfile();

    const user = useSelector(getUser);
    const settingsDirty = useSelector(getSettingsDirty);
    const integrationSettingsDirty = useSelector(getIntegrationSettingsDirty);
    const myAccountSettingsDirty = useSelector(getMyAccountSettingsDirty);
    const organizationSettingsDirty = useSelector(getOrganizationSettingsDirty);
    const [selected, setSelected] = useState(0);
    const isAuthorize = useAuthLicenseAddOns();
    const [nextSelected, setNextSelected] = useState(null);
    const [openConfirmDeleteDlg, setOpenConfirmDeleteDlg] = useState(false);
    const { integration_tab } = useParams();
    const iCalendar = isAuthorize(['oneToOne.iCalendar']);

    const isAdmin = useCallback(() => {
        return (
            user?.role !== UserRole.CREATOR &&
            user?.role !== UserRole.EDITOR &&
            (user?.role !== UserRole.ROOT || user?.organizationId)
        );
    }, [user]);

    const isTestFeatureEnabled = useSelector(testFeatureEnabled);
    const isNotPersonalShopper = useCallback(() => {
        return user?.role !== UserRole.CREATOR && user?.role !== UserRole.EDITOR;
    }, [user]);

    const closeConfirmTitle = t('profile.profile.closeConfirm');
    const closeConfirmMessage = t('profile.profile.closeMessage');

    const items = useMemo(() => {
        return [
            {
                title: t('profile.profile.myAccount'),
                condition: null,
                component: <MyAccount />,
                dirty: myAccountSettingsDirty,
                setDirty: () => dispatch(setMyAccountSettingsDirty()),
            },

            {
                title: t('profile.settings.security'),
                isDisabled: false,
                component: <Security organizationId={user?.organizationId} />,
            },

            ...(iCalendar
                ? [
                      {
                          title: t('profile.profile.myAvailability'),
                          condition: null,
                          component: (
                              <MyAvailability
                                  userData={{ userView: false, userId: user.userId, userGroupId: user.userGroupId }}
                              />
                          ),
                          actions: [
                              <MyAvailabilityCreateAction
                                  key="action-availability"
                                  userData={{ userView: false, userId: user.userId, userGroupId: user.userGroupId }}
                              />,
                          ],
                      },
                  ]
                : []),

            {
                title: t('profile.profile.organization'),
                condition: isNotPersonalShopper,
                component: <Organization />,
                isDisabled: false,
                dirty: organizationSettingsDirty,
                setDirty: () => dispatch(setOrganizationSettingsDirty),
            },
            {
                title: t('profile.profile.settings'),
                condition: isAdmin,
                component: <Settings />,
                isDisabled: false,
                dirty: settingsDirty,
                setDirty: () => dispatch(setSettingsDirty()),
            },
            {
                title: t('profile.profile.integration'),
                condition: isAdmin,
                component: <IntegrationV2 />,
                isDisabled: false,
                dirty: integrationSettingsDirty,
                setDirty: () => dispatch(setIntegrationSettingsDirty()),
                name: INTEGRATION_COMPONENT,
            },
            {
                title: t('analytics.shoppable.tabs.delivery'),
                condition: () =>
                    isAdmin() &&
                    isTestFeatureEnabled &&
                    (isAuthorize(['oneToOne.iCall']) ||
                        isAuthorize(['oneToMany.iLive']) ||
                        isAuthorize(['oneToMany.shoppable'])),
                component: <VodDeliveryDashboard organizationId={user?.organizationId} className={classes.padding17} />,
            },
        ];
    }, [
        t,
        myAccountSettingsDirty,
        iCalendar,
        user.userId,
        user.userGroupId,
        user?.organizationId,
        isNotPersonalShopper,
        organizationSettingsDirty,
        isAdmin,
        settingsDirty,
        integrationSettingsDirty,
        classes.padding17,
        dispatch,
        isTestFeatureEnabled,
        isAuthorize,
    ]);

    const handleClick = (e, index) => {
        if (items[selected].dirty) {
            setNextSelected(index);
            setOpenConfirmDeleteDlg(true);
        } else {
            setSelected(index);
        }
    };

    const confirmChange = () => {
        items[selected].setDirty(false);
        setSelected(nextSelected);
        setNextSelected(null);
        setOpenConfirmDeleteDlg(false);
    };

    const cancelDlg = () => {
        setNextSelected(null);
        setOpenConfirmDeleteDlg(false);
    };

    useEffect(() => {
        if (integration_tab) {
            setSelected(items.findIndex((item) => item.name === INTEGRATION_COMPONENT));
        }
    }, [integration_tab, items]);

    const handleActions = items ? items[selected]?.actions : [];

    return (
        <>
            <div className={classes.root}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={items ? 'space-between' : 'flex-end'}
                    className={classes.tabsContainer}
                >
                    {items ? (
                        <Tabs
                            value={selected}
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={handleClick}
                            aria-label="tabs_navigate"
                            classes={{
                                indicator: classes.tabsIndicator,
                            }}
                        >
                            {items
                                .filter((item) => !item.condition || item.condition())
                                .map((item, idx) => (
                                    <CustomTab
                                        component="div"
                                        key={`profile-item-${item.title}`}
                                        label={item.title}
                                        disabled={item.isDisabled}
                                        tooltipMessage={t(messageNotAuthorize)}
                                        {...a11yProps(idx)}
                                    />
                                ))}
                        </Tabs>
                    ) : null}

                    <Stack direction="row" alignItems="center" spacing={2}>
                        {handleActions?.map((action) => action)}
                    </Stack>
                </Stack>

                <div className={classes.panelContainer}>
                    {items.map((item, idx) => (
                        <TabPanel key={item.title} value={selected} index={idx} classes={classes.panelContainer}>
                            {item.component}
                        </TabPanel>
                    ))}
                </div>
            </div>

            <YesNoDialog
                openDialog={openConfirmDeleteDlg}
                title={closeConfirmTitle}
                messageToConfirm={closeConfirmMessage}
                yesButtonCallback={() => {
                    confirmChange();
                }}
                noButtonCallback={() => {
                    cancelDlg();
                }}
            />
        </>
    );
};

export default Profile;
