import { makeStyles } from 'tss-react/mui';

export const useStylesMyAccount = makeStyles()((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        flexGrow: 1,
        overflow: 'hidden',
    },
    scroll: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        /* Hide scrollbar for IE, Edge and Firefox */
        scrollbarWidth: 'none',
        /* Hide scrollbar for Chrome, Safari and Opera */
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    body: {
        padding: theme.spacing(1, 7),
    },
    buttonSave: {
        margin: 0,
        top: 'auto',
        right: 120,
        bottom: 48,
        left: 'auto',
        position: 'fixed',
        [theme.breakpoints.down('md')]: {
            bottom: 16,
        }
    },
    pending: {
        width: '50px',
        margin: '20px auto',
    },
}));