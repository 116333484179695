import { makeStyles } from 'tss-react/mui';

const useStylesProfile = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: '#EFEFEF',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        tabsIndicator: {
            width: '100%',
            height: '4px',
            fontSize: '4px',
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.up('xl')]: {
                height: '5px',
                fontSize: '5px',
            },
        },
        tabsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(0, 6),
            height: theme.spacing(6),
            minHeight: theme.spacing(6),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(7.5),
                minHeight: theme.spacing(7.5),
            },

        },

        tabRoot: {
            fontSize: '17px',
        },

        actions: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        ml2: {
            marginLeft: theme.spacing(2),
        },
        panelContainer: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            overflow: 'hidden',
        },


        main: {
            height: '100%',
            [theme.breakpoints.down('lg')]: {
                height: 'auto',
            },
        },

        paper: {
            background: theme.palette.default.main,
            borderRadius: '10px',
            padding: theme.spacing(2),
            height: 'calc(100% - 30px)',
            boxShadow: 'none',
            [theme.breakpoints.down('lg')]: {
                height: 'auto',
            },
        },

        menu: {
            paddingInlineStart: 0,
            listStyle: 'none',
        },

        menuItem: {
            background: theme.palette.default.main,
            borderRadius: '0.5rem',
            fontSize: '0.7rem',
            padding: theme.spacing(1.5),
            cursor: 'pointer',
            marginBottom: theme.spacing(1),
            whiteSpace: 'no-wrap',
            [theme.breakpoints.down('lg')]: {
                display: 'inline-block',
                marginRight: theme.spacing(1),
            },
        },
        menuItemActive: {
            backgroundColor: theme.palette.primary.main,
            '& > span': {
                color: theme.palette.default.main,
            },
        },
        menuItemDisabled: {
            color: theme.palette.text.disabled,
        },
        disabledTab: {
            opacity: 0.38,
            textTransform: 'uppercase',
            fontSize: theme.spacing(2),
            fontWeight: 500,
            padding: theme.spacing(1.5, 2),
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(2.25),
            },
        },
        padding17: {
            padding: theme.spacing(1, 7),
        }
    };
});

export default useStylesProfile;
