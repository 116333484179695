import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const verticalManagementSlice = createSlice({
    name: 'vertical',
    initialState: {
        pending: false,
        verticals: [],
        error: null,
        deletePending: false,
        deleteError: null,
        total: 0,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        pendingDownload: false,
    },
    reducers: {
        startDownload: (state, action) => {
            state.pendingDownload = true;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
        },
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setVerticals: (state, action) => {
            state.pending = false;
            state.verticals = action.payload.items;
            state.total = action.payload.total;
            state.count = action.payload.count;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addVertical: (state, action) => {
            state.pending = false;
            state.verticals = [action.payload, ...state.verticals];
        },
        editVertical: (state, action) => {
            state.pending = false;
            const index = state.verticals.findIndex((vertical) => vertical.slug === action.payload.slug);
            if (index !== -1) {
                state.verticals = [
                    ...state.verticals.slice(0, index),
                    action.payload,
                    ...state.verticals.slice(index + 1),
                ];
            }
        },
        deleteVertical: (state, action) => {
            state.deletePending = false;
            const index = state.verticals.findIndex((vertical) => vertical.slug === action.payload.slug);
            if (index !== -1) {
                state.verticals = [...state.verticals.slice(0, index), ...state.verticals.slice(index + 1)];
            }
        },
        startDelete: (state, action) => {
            state.deletePending = true;
        },
        setErrorDelete: (state, action) => {
            state.deletePending = false;
            state.deleteError = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setVerticals,
    setError,
    addVertical,
    editVertical,
    deleteVertical,
    startDelete,
    setErrorDelete,
    startDownload,
    stopDownload,
} = verticalManagementSlice.actions;

export const getVerticals = (state) => state.vertical.verticals;
export const getVerticalsPending = (state) => state.vertical.pending;
export const getDeletePending = (state) => state.vertical.deletePending;
export const getVerticalsPagination = (state) => ({
    count: state.vertical.count,
    currentPage: state.vertical.currentPage,
    totalPages: state.vertical.totalPages,
    total: state.vertical.total,
});

export const fetchVerticalsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('verticals' + query)
                .then((response) => {
                    dispatch(setVerticals(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const updateVerticalAction =
    ({ verticalDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.put('verticals/' + verticalDto.slug, verticalDto)
                .then((response) => {
                    dispatch(editVertical(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(stop());
                    onFailure?.();
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addVerticalAction =
    ({ verticalDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.post('verticals', verticalDto)
                .then((response) => {
                    dispatch(addVertical(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(stop());
                    onFailure?.();
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const deleteVerticalAction =
    ({ slug, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(slug));
            api.delete('verticals/' + slug)
                .then((response) => {
                    dispatch(deleteVertical(response.data));
                    onSuccess?.();
                    return response;
                })
                .catch((error) => {
                    dispatch(setErrorDelete(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(setErrorDelete(e));
            return console.error(e.message);
        }
    };

export default verticalManagementSlice.reducer;
