import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useSettingValue } from 'common/hooks/useSettingValue';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    CREATE_TEMPLATE_MODE,
    EDIT_TEMPLATE_MODE,
    batchDeleteDaysOverrideAction,
    batchDeleteSchedulesAction,
    deleteOutOfTheOfficeAction,
    deleteTemplateAction,
    getPendingTemplate,
    getPendingTemplateAction,
    getTemplateAction,
    getTemplateView,
    getTemplates,
    postDaysOverrideAction,
    postOutOfTheOfficeAction,
    postSchedulesAction,
    postTemplateAction,
    setTemplateView,
    setTemplateViewEnable,
} from 'redux/slices/ocalendarSlice';
import { UUID } from 'uuidjs';
import ODateOverride from '../ODateOverride';
import OListDateOverride from '../OListDateOverride';
import OTemplateSchedule from '../OTemplateSchedule';
import OWeeklyHours from '../OWeeklyHours';
import useStylesOCalendar from '../styles/useStylesOCalendar.styles';
import { AvailabilityContext } from './AvailabilityContext';
import AvailabilityTypeSelect from './AvailabilityTypeSelect';

function mockWorkingDays() {
    const days = [];
    for (let i = 0; i < 7; i++) {
        days.push({
            id: UUID.generate(),
            enable: false,
            index: i,
            hours: [],
        });
    }
    return days;
}

function generateTemplate(title, availabilityType) {
    return {
        id: UUID.generate(),
        title: title,
        duration: 30,
        minimumTimeToSchedule: 0,
        maximumTimeToSchedule: 30,
        timeBetweenEvents: 30,
        timeIncrementation: 30,
        workingHours: mockWorkingDays(),
        dateOverrides: [],
        outsOfTheOffice: [],
        availabilityType,
    };
}

const AvailabilityForm = ({ isReadOnly, requestUser, profile }) => {
    const { classes: classes1, cx } = useStylesForm();
    const { classes: classes2 } = useStylesOCalendar();
    const classes = { ...classes1, ...classes2 };
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const location = useLocation();
    const { handleSubmitTemplate } = useContext(AvailabilityContext);
    const pendingTemplate = useSelector(getPendingTemplate);
    const templateView = useSelector(getTemplateView);
    const templateAction = useSelector(getTemplateAction);
    const pendingTemplateAction = useSelector(getPendingTemplateAction);
    const templates = useSelector(getTemplates);

    const defaultTitle = useMemo(() => t('calendar.availableHours'), [t]);
    const timerRef = useRef(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [simple, setSimple] = useState(false);

    const availabilityTypes = useSettingValue(SettingsKey.AVAILABILITY_TYPES);
    const [predefinedType, setPredefinedType] = useState(null);

    const findMissingTypes = useCallback(
        (existingTypes) => {
            let missingTypes = [];
            let allTypesTaken = true;

            if (availabilityTypes) {
                missingTypes = availabilityTypes.filter(
                    (value) => !existingTypes.includes(value?.trim().toLowerCase()),
                );
                allTypesTaken = missingTypes.length === 0;
            }

            return {
                missingTypes,
                allTypesTaken,
            };
        },
        [availabilityTypes],
    );

    const showErrorAndRefresh = useCallback(
        (error) => {
            enqueueSnackbar(error, {
                variant: 'error',
            });
            timerRef.current = setTimeout(() => {
                dispatch(setTemplateViewEnable(false));
            }, 500);
        },
        [dispatch, enqueueSnackbar],
    );

    const handleCreateTemplate = useCallback(() => {
        const existingTypes = templates.map((template) => template.availabilityType);
        const { missingTypes, allTypesTaken } = findMissingTypes(existingTypes);
        const hasDefault = existingTypes?.some((t) => t === 'default');

        if (allTypesTaken && hasDefault) {
            showErrorAndRefresh(t('profile.organization.tabs.availability.noMoreAvailabilitiesError'));
            return;
        }

        const type = !hasDefault ? 'default' : missingTypes[0];
        setPredefinedType(type);

        const defaultTemplate = generateTemplate(defaultTitle, type);
        const reqValue = Object.assign(defaultTemplate, {
            profileId: profile?.id,
        });
        dispatch(
            postTemplateAction(reqValue, requestUser, null, (error) => {
                showErrorAndRefresh(error.response?.data?.description);
            }),
        );
    }, [defaultTitle, requestUser, profile?.id, dispatch, findMissingTypes, showErrorAndRefresh, t, templates]);

    useLayoutEffect(() => {
        if (!templateView) {
            handleCreateTemplate();
        }
    }, [templateView, handleCreateTemplate]);

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
            dispatch(setTemplateViewEnable(false));
            dispatch(setTemplateView(null));
        };
    }, [dispatch, location.pathname]);

    const handleTemplateChange = (template) => {
        const data = Object.assign(JSON.parse(JSON.stringify(templateView)), template);
        dispatch(
            postTemplateAction(
                data,
                requestUser,
                () => {
                    enqueueSnackbar(t('common.term.saveSuccess'), {
                        variant: 'success',
                    });
                },
                () => {
                    enqueueSnackbar(t('app.common.error'), {
                        variant: 'error',
                    });
                },
            ),
        );
    };

    const formatTime = (hour, timeZone) => {
        return moment.tz({ hour }, timeZone).format('HH:mm');
    };

    const handleCheckDayChange = (_index) => {
        let tempDays = JSON.parse(JSON.stringify(templateView?.workingHours));
        const position = templateView?.workingHours.findIndex((d) => d.index === _index);

        if (tempDays[position].enable) {
            const deletedIds = [];
            tempDays[position].hours.forEach((h) => {
                deletedIds.push(h.id);
            });
            if (deletedIds.length) {
                dispatch(
                    batchDeleteSchedulesAction(
                        deletedIds,
                        requestUser,
                        () => {
                            enqueueSnackbar(t('common.term.saveSuccess'), {
                                variant: 'success',
                            });
                        },
                        () => {
                            enqueueSnackbar(t('app.common.error'), {
                                variant: 'error',
                            });
                        },
                    ),
                );
            }
        } else {
            tempDays[position] = {
                ...tempDays[position],
                enable: true,
                hours: [
                    {
                        id: UUID.generate(),
                        index: _index,
                        startTime: formatTime(9, profile?.timeZone),
                        endTime: formatTime(18, profile?.timeZone),
                    },
                ],
            };
            saveSchedules(tempDays);
        }
    };

    const saveSchedules = (tempDays) => {
        let schedules = [];
        tempDays.forEach((element) => {
            if (element.enable) {
                element.hours.forEach((h) => {
                    schedules.push({
                        id: h.id,
                        templateId: templateView?.id,
                        dayIndex: element.index,
                        startHour: h.startTime,
                        endHour: h.endTime,
                    });
                });
            }
        });
        if (schedules.length) {
            dispatch(
                postSchedulesAction(
                    schedules,
                    moment(),
                    tempDays,
                    () => {
                        enqueueSnackbar(t('common.term.saveSuccess'), {
                            variant: 'success',
                        });
                    },
                    () => {
                        enqueueSnackbar(t('app.common.error'), {
                            variant: 'error',
                        });
                    },
                ),
            );
        }
    };

    const handleHourRangeChange = (_index, hoursRange) => {
        let tempDays = JSON.parse(JSON.stringify(templateView?.workingHours));
        const index = templateView?.workingHours.findIndex((d) => d.index === _index);
        const indexHour = tempDays[index].hours.findIndex((h) => h.id === hoursRange.id);
        tempDays[index].hours.splice(indexHour, 1, hoursRange);
        saveSchedules(tempDays);
    };

    const handleAddHourRange = (_index) => {
        let tempDays = JSON.parse(JSON.stringify(templateView?.workingHours));
        const index = templateView?.workingHours.findIndex((d) => d.index === _index);
        tempDays[index].hours.push({
            id: UUID.generate(),
            index: _index,
            startTime: moment.tz({ hour: 9 }, profile?.timeZone).format('HH:mm'),
            endTime: moment.tz({ hour: 18 }, profile?.timeZone).format('HH:mm'),
        });
        saveSchedules(tempDays);
    };

    const handleDeleteHourRange = (_index, hourId) => {
        let tempDays = JSON.parse(JSON.stringify(templateView?.workingHours));
        const index = templateView?.workingHours.findIndex((d) => d.index === _index);
        const hourIndex = tempDays[index].hours.findIndex((h) => h.id === hourId);
        if (hourIndex >= 0) {
            dispatch(
                batchDeleteSchedulesAction(
                    [tempDays[index].hours[hourIndex].id],
                    requestUser,
                    () => {
                        enqueueSnackbar(t('common.term.saveSuccess'), {
                            variant: 'success',
                        });
                    },
                    () => {
                        enqueueSnackbar(t('app.common.error'), {
                            variant: 'error',
                        });
                    },
                ),
            );
        }
    };

    const handleOpenOutOfTheOffice = () => {
        setDialogTitle(t('calendar.setOutOfTheOffice'));
        setSimple(true);
        setOpenDialog(true);
    };

    const handleDeleteDateOverride = (id, simple) => {
        if (!simple) {
            const index = templateView?.dateOverrides.findIndex((_do) => _do.id === id);
            const deletedDay = templateView?.dateOverrides[index];
            const deletedIds = [];

            if (deletedDay) {
                deletedDay.hours.forEach((h) => {
                    deletedIds.push(h.id);
                });
            }

            if (deletedIds.length) {
                dispatch(
                    batchDeleteDaysOverrideAction(
                        deletedIds,
                        requestUser,
                        index,
                        () => {
                            enqueueSnackbar(t('common.term.saveSuccess'), {
                                variant: 'success',
                            });
                        },
                        () => {
                            enqueueSnackbar(t('app.common.error'), {
                                variant: 'error',
                            });
                        },
                    ),
                );
            }
        } else {
            dispatch(
                deleteOutOfTheOfficeAction(
                    id,
                    () => {
                        enqueueSnackbar(t('common.term.saveSuccess'), {
                            variant: 'success',
                        });
                    },
                    () => {
                        enqueueSnackbar(t('app.common.error'), {
                            variant: 'error',
                        });
                    },
                ),
            );
        }
    };

    const handleOpenAddADateOverride = () => {
        setDialogTitle(t('calendar.addADateOverride'));
        setSimple(false);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleAddDateOverride = (value, simple) => {
        setOpenDialog(false);
        if (!simple) {
            let daysOverrides = [];
            if (value.hours.length) {
                value.hours.forEach((h) => {
                    daysOverrides.push({
                        id: h.id,
                        date: value.date,
                        templateId: templateView?.id,
                        startHour: h.startTime,
                        endHour: h.endTime,
                    });
                });
            } else {
                daysOverrides.push({
                    id: value.id,
                    date: value.date,
                    templateId: templateView?.id,
                    startHour: null,
                    endHour: null,
                });
            }
            if (daysOverrides.length) {
                dispatch(
                    postDaysOverrideAction(
                        daysOverrides,
                        () => {
                            enqueueSnackbar(t('common.term.saveSuccess'), {
                                variant: 'success',
                            });
                        },
                        () => {
                            enqueueSnackbar(t('app.common.error'), {
                                variant: 'error',
                            });
                        },
                    ),
                );
            }
        } else {
            dispatch(
                postOutOfTheOfficeAction(
                    {
                        templateId: templateView?.id,
                        beginDate: value.startDate,
                        finalDate: value.endDate,
                    },
                    () => {
                        enqueueSnackbar(t('common.term.saveSuccess'), {
                            variant: 'success',
                        });
                    },
                    () => {
                        enqueueSnackbar(t('app.common.error'), {
                            variant: 'error',
                        });
                    },
                ),
            );
        }
    };

    function cancel(e) {
        e.preventDefault();
        switch (templateAction) {
            case CREATE_TEMPLATE_MODE:
                // If in create mode and templateView exists, delete the template
                if (templateView) {
                    dispatch(deleteTemplateAction(templateView.id, requestUser));
                }
                break;
            case EDIT_TEMPLATE_MODE:
                // If in edit mode, cancel the template edition
                dispatch(setTemplateViewEnable(false));
                break;
            default:
                break;
        }
    }

    return (
        <>
            {templateView && !pendingTemplateAction ? (
                <>
                    <AvailabilityTypeSelect
                        template={templateView}
                        availabilityTypes={availabilityTypes ?? []}
                        predefinedType={predefinedType}
                    />
                    <OTemplateSchedule classes={classes} onChange={handleTemplateChange} isReadOnly={isReadOnly} />
                    <Grid container spacing={1} className={classes.paddingY1}>
                        <Grid item md={12}>
                            <Typography>{t('calendar.setWeeklyHours')}</Typography>
                            <OWeeklyHours
                                classes={classes}
                                days={templateView?.workingHours}
                                onChange={handleCheckDayChange}
                                onHourRangeChange={handleHourRangeChange}
                                onAddHourRange={handleAddHourRange}
                                onDeleteHourRange={handleDeleteHourRange}
                                timeZone={profile?.timeZone}
                                isReadOnly={isReadOnly}
                            />
                        </Grid>
                        <Grid item md={12} className={classes.dateOverrideGridContainer}>
                            <div className={classes.titleAction}>
                                <Typography>{t('calendar.setOutOfTheOffice')}</Typography>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.dateOverridePlusBtn}
                                    onClick={handleOpenOutOfTheOffice}
                                    startIcon={<Add />}
                                    disabled={isReadOnly}
                                >
                                    {t('calendar.common.add')}
                                </Button>
                            </div>
                            <OListDateOverride
                                classes={classes}
                                datesOverride={templateView?.outsOfTheOffice}
                                onDeleteDate={handleDeleteDateOverride}
                                simple={true}
                                timeZone={profile?.timeZone}
                                isReadOnly={isReadOnly}
                            />
                        </Grid>
                        <Grid item md={12} className={classes.dateOverrideGridContainer}>
                            <div className={classes.titleAction}>
                                <Typography className={classes.dateOverrideTitle}>
                                    {t('calendar.addDateOverrides')}
                                </Typography>
                                <Button
                                    variant="text"
                                    color="primary"
                                    className={classes.dateOverridePlusBtn}
                                    onClick={handleOpenAddADateOverride}
                                    startIcon={<Add />}
                                    disabled={isReadOnly}
                                >
                                    {t('calendar.common.add')}
                                </Button>
                            </div>
                            <OListDateOverride
                                classes={classes}
                                datesOverride={templateView?.dateOverrides}
                                onDeleteDate={handleDeleteDateOverride}
                                simple={false}
                                timeZone={profile?.timeZone}
                                isReadOnly={isReadOnly}
                            />
                        </Grid>
                    </Grid>
                    <ODateOverride
                        classes={classes}
                        openDialog={openDialog}
                        onCloseDialog={handleCloseDialog}
                        onAddDateOverride={handleAddDateOverride}
                        title={dialogTitle}
                        simple={simple}
                        timeZone={profile?.timeZone}
                    />
                    <div className={cx(classes.formButtons, classes.formActions)}>
                        <div className={classes.bottomContent}>
                            <div className={classes.actions}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation={true}
                                    className={classes.cancelButton}
                                    onClick={cancel}
                                    disabled={pendingTemplate}
                                >
                                    <span>{t('app.common.cancel')}</span>
                                </Button>

                                <ActionButton
                                    id="onlive-button-availability-save"
                                    type="submit"
                                    disableElevation={true}
                                    className={cx(classes.proceedButton)}
                                    disabled={pendingTemplate}
                                    onClick={handleSubmitTemplate}
                                >
                                    {pendingTemplate && (
                                        <div className={classes.spinner}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                    <span>{t('app.common.save')}</span>
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        p: '2rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '60vw',
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
        </>
    );
};

export default AvailabilityForm;
