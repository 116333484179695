import { makeStyles } from 'tss-react/mui';

const useStylesQueueManager = makeStyles()(() => {
    return {
        notificationsPopupBackground: {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: '1000000',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        },
        notificationsPopupHidden: {
            display: 'none',
        },
        pickUpButton: {
            backgroundColor: 'red',
            color: 'white',

            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },

        notificationsPopup: {
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxShadow: '0 0 5px #ccc',
            zIndex: '1000001',
            padding: '30px',
        },
        notificationsPopupDescription: {
            padding: '10px',
            '& h3': {
                margin: '0',
                padding: '0',
                fontSize: '16px',
            },
        },
        notificationsPopupButtons: {
            padding: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            '& button': {
                marginLeft: '10px',
            },
        },
        callCenter: {
            zIndex: 100000001,
            position: 'fixed',
            top: '-200px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            maxWidth: '400px',
            height: '103px',
            borderRadius: '0 0 20px 20px',
            transition: 'top 0.5s',
        },
        pickUp: {
            zIndex: '1000001',
        },
        callCenterOpen: {
            top: '-1px',
        },
        callCenterDetails: {
            marginLeft: '20px',
            padding: '20px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        currentEventDetails: {
            padding: '0 20px',
            display: 'flex',
            gap: '3px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        callCenterDetailsIncoming: {
            fontSize: '12px',
            color: '#000',
            fontWeight: '300',
            margin: '0',
        },
        callCenterDetailsName: { margin: ' 0', fontSize: '18px', color: '#000', fontWeight: '600' },
        callCenterButtons: { display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '20px' },
        callCenterButtonDecline: {
            borderRadius: ' 50%',
            width: '46px',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
            cursor: 'pointer',
            backgroundColor: '#f44336',
        },
        callCenterDetailsEmail: {
            fontSize: '12px',
            color: '#000',
            fontWeight: '600',
            margin: '0',
            wordBreak: 'break-word'
        },
        callCenterButtonAccept: {
            borderRadius: ' 50%',
            width: '46px',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
            cursor: 'pointer',
            backgroundColor: '#00E851',
        },
    };
});

export default useStylesQueueManager;
