import { createContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setPendingTemplate, setTemplateViewEnable } from 'redux/slices/ocalendarSlice';

const AvailabilityContext = createContext();

const AvailabilityProvider = ({ children }) => {
    const timerRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    const handleSubmitTemplate = () => {
        dispatch(setPendingTemplate(true));

        timerRef.current = setTimeout(() => {
            dispatch(setTemplateViewEnable(false));
            dispatch(setPendingTemplate(false));
        }, 500);
    };

    return <AvailabilityContext.Provider value={{ handleSubmitTemplate }}>{children}</AvailabilityContext.Provider>;
};

export { AvailabilityContext, AvailabilityProvider };
