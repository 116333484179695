import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" {...props} />)(({ theme }) => {
    return {
        width: theme.spacing(4.5),
        height: theme.spacing(2.5),
        padding: 0,
        marginRight: theme.spacing(0.5),
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#42E57B',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        '& .MuiSwitch-track': {
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },

        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(5.5),
            height: theme.spacing(3.5),
            '& .MuiSwitch-thumb': {
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
            '& .MuiSwitch-track': {
                borderRadius: theme.spacing(2),
            },
        },
    };
});

export default CustomSwitch;
