import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useFlexBoxStyles from 'common/flexBox';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minHeight: theme.spacing(3),
        },

        border: {
            borderBottom: `1px solid ${theme.palette.common.lightGray}`,
        },
    };
});

const CustomMenuItem = (props) => {
    const { onClick, children, noBorder, submenu, ...rest } = props;

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    return (
        <MenuItem
            disableGutters={true}
            className={cx(
                classes.root,
                flexBox.flex,
                flexBox.justifySpaceBetween,
                flexBox.padding1,
                !noBorder && classes.border,
            )}
            onClick={onClick}
            {...rest}
        >
            <Typography fontWeight={500}>{children}</Typography>
        </MenuItem>
    );
};

CustomMenuItem.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    submenu: PropTypes.bool,
    size: PropTypes.oneOf(['big']),
    ...MenuItem.propTypes,
};

export default CustomMenuItem;
