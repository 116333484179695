import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTP from 'i18next-http-backend';

import translationDe from './locales/de.json';
import translationEn from './locales/en.json';
import translationEs from './locales/es.json';
import translationFr from './locales/fr.json';
import translationIt from './locales/it.json';
import translationNlBe from './locales/nl-be.json';
import translationNl from './locales/nl.json';
import translationPl from './locales/pl.json';
import translationPtBr from './locales/pt-br.json';
import translationPtPt from './locales/pt-pt.json';
import translationRO from './locales/ro.json';
import translationSr from './locales/sr.json';
import translationSv from './locales/sv.json';
import translationUk from './locales/uk.json';

i18n.use(HTTP)
    .use(LanguageDetector)
    .init({
        react: {
            useSuspense: false,
        },
        fallbackLng: 'en',
        debug: false,
        detection: {
            order: ['localStorage', 'htmlTag', 'navigator'],
        },

        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },

        resources: {
            en: {
                translations: translationEn,
            },
            es: {
                translations: translationEs,
            },
            fr: {
                translations: translationFr,
            },
            de: {
                translations: translationDe,
            },
            it: {
                translations: translationIt,
            },
            'pt-BR': {
                translations: translationPtBr,
            },
            pt: {
                translations: translationPtPt,
            },
            pl: {
                translations: translationPl,
            },
            uk: {
                translations: translationUk,
            },
            ro: {
                translations: translationRO,
            },
            'nl-BE': {
                translations: translationNlBe,
            },
            sr: {
                translations: translationSr,
            },
            nl: {
                translations: translationNl,
            },
            sv: {
                translations: translationSv,
            },
        },

        ns: ['translations'],
        defaultNS: 'translations',
    });

export default i18n;
