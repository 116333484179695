import { Box, FormGroup, FormLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomAutocompleteSearch from 'common/components/CustomAutocompleteSearch';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getUnits, getUnitsPending } from 'redux/slices/unitSlice';
import HorizontalServiceCardDateRange from '../components/ServiceCardDateRange';

const sortUnits = (array) => {
    return array.sort((a, b) => {
        const nameA = a.name || a.productName;
        const nameB = b.name || b.productName;
        const primaryComparison = nameA.localeCompare(nameB);
        if (primaryComparison === 0) {
            const externalIdA = a.externalId || '';
            const externalIdB = b.externalId || '';
            return externalIdA.localeCompare(externalIdB);
        }
        return primaryComparison;
    });
};

const ServiceFormUnits = (props) => {
    const { selectedUnits, setSelectedUnits, disabled, timeZone, groupId } = props;
    const allUnits = useSelector(getUnits);
    const pending = useSelector(getUnitsPending);
    const user = useSelector(getUser);

    const canManageUnit = useCallback(
        (unit) => {
            if (groupId && groupId !== 'all' && unit?.userGroupId && unit?.userGroupId !== groupId) return false;
            if (unit?.userGroupId === user?.userGroupId) return true;
            if (!user?.userGroupId) return true;
            return false;
        },
        [user?.userGroupId, groupId],
    );

    const units = useMemo(
        () =>
            allUnits
                ?.filter((unit) => canManageUnit(unit) || selectedUnits.some((selected) => selected.id === unit.id))
                .map((u) => ({
                    ...u,
                    calendarId: u.id,
                    name: u.name || u.productName,
                    restrictionDates: { include: [], exclude: [] },
                    active: true,
                })),
        [allUnits, selectedUnits, canManageUnit],
    );

    const { t } = useTranslation();

    const editUnit = (unit, idx) => {
        setSelectedUnits([...selectedUnits.slice(0, idx), unit, ...selectedUnits.slice(idx + 1)]);
    };

    const deleteUnit = (idx) => {
        setSelectedUnits([...selectedUnits.slice(0, idx), ...selectedUnits.slice(idx + 1)]);
    };

    const renderOption = (props, option) => {
        return (
            <li aria-hidden="true" {...props} key={option.id}>
                {option.productName}
                {option.externalId ? (
                    <Typography>&nbsp;&nbsp;(&nbsp;{option.externalId || ''}&nbsp;)</Typography>
                ) : null}
            </li>
        );
    };

    const getOptionLabel = (option) => {
        return option?.productName + (option?.externalId ? ` (${option?.externalId})` : '');
    };

    const getOptionDisabled = (option) => {
        return user?.userGroupId && option?.userGroupId !== user?.userGroupId;
    };

    return (
        <FormGroup>
            <FormLabel>{t('services.form.assets')}</FormLabel>

            <CustomAutocompleteSearch
                selectedValues={sortUnits(selectedUnits)}
                optionProp="calendarId"
                setSelectedValues={setSelectedUnits}
                options={sortUnits(units)}
                pending={pending}
                disabled={disabled}
                renderOption={renderOption}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
            />

            {sortUnits(selectedUnits)?.map((unit, idx) => (
                <Box sx={{ my: 2 }} key={unit.id}>
                    <HorizontalServiceCardDateRange
                        timeZone={timeZone}
                        unit={unit}
                        onDeleteUnit={() => deleteUnit(idx)}
                        onEditUnit={(unit) => editUnit(unit, idx)}
                        disabled={disabled || (user.userGroupId && unit.userGroupId !== user.userGroupId)}
                    />
                </Box>
            ))}
        </FormGroup>
    );
};

export default ServiceFormUnits;
