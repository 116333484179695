/*
statusSetting example
{
    "status": {
        "label": {
            "en": "Status",
            "es": "Estado",
        },
        "action": {
            "type": "sendEmail",
            "template": "template-name"
        },
        "children": {
            "subStatus1": {
                "label": {
                    "en": "Substate 1",
                    "es": "Subestado 1",
                },
                "action": {
                    "type": "sendEmail",
                    "template": "template-name"
                },
            },
        },
        icon: <Icon />,
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
            ...otherStyles
        },
    }
}
*/

import { StatusType } from 'common/enums/statusEnum';

export const getStatusActions = (status, statusSetting) => {
    return typeof status === 'string' && typeof statusSetting === 'object' ? statusSetting?.[status]?.actions : null;
};

export const getStatusSettingProp = (status, statusSetting, propKey) => {
    return statusSetting?.hasOwnProperty(status) && statusSetting[status].hasOwnProperty(propKey)
        ? statusSetting[status][propKey]
        : null;
};

export const getStatusSetting = (status, statusSetting) => {
    return statusSetting?.[status] || StatusType?.[status?.toUpperCase()] || null;
};

export const getStatusColor = (status, statusSetting) => {
    return (
        statusSetting?.[status]?.style?.backgroundColor ||
        StatusType?.[status?.toUpperCase()]?.style?.backgroundColor ||
        'transparent'
    );
};
