import { FormGroup, FormLabel } from '@mui/material';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useTranslation } from 'react-i18next';

const WoocommerceForm = (props) => {
    const { fields, handleFieldChange } = props;
    const { t } = useTranslation();
    const { classes } = useStylesForm();

    return (
        <>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.woocommerceForm.shopUri')}</FormLabel>
                <CustomTextValidator
                    name="shopUri"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-shopUri"
                    value={fields.shopUri}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('app.common.required')]}
                    disabled
                />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.woocommerceForm.consumerKey')}</FormLabel>
                <CustomTextValidator
                    name="consumerKey"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-consumerKey"
                    value={fields.consumerKey}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('app.common.required')]}
                    disabled={true}
                />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.woocommerceForm.consumerSecret')}</FormLabel>
                <CustomTextValidator
                    name="consumerSecret"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-consumerSecret"
                    value={fields.consumerSecret}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('app.common.required')]}
                    disabled={true}
                />
            </FormGroup>
        </>
    );
};

export default WoocommerceForm;
