import { Add } from '@mui/icons-material';
import { Avatar, Button, FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import ImageFileInput from './ImageFileInput';
import useStylesForm from './styles/useStylesForm.styles';
import useStylesProfileImage from './styles/useStylesProfileImage.styles';

const ProfileImage = (props) => {
    const { image, setImage, submitted, required, uploadImage, onUploading } = props;
    const { t } = useTranslation();
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { classes: classes1 } = useStylesProfileImage();
    const { classes: classes2, cx } = useStylesForm();
    const classes = { ...classes1, ...classes2 };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        onUploading(uploading);
    }, [uploading, onUploading]);

    const handleUploadFile = async (file) => {
        if (file.size / 1048576 > 2) {
            enqueueSnackbar(t('common.components.profileImage.imageSizeError'), {
                variant: 'error',
            });
        } else {
            uploadImage(file, setUploadProgress, setUploading, (imageUrl) => {
                imageUrl && setImage(imageUrl);
            });
        }
    };

    return (
        <FormGroup className={classes.formGroup}>
            <FormLabel>{t('common.components.profileImage.image')}</FormLabel>
            <FormControl className={classes.formControl}>
                <div className={classes.profileImage}>
                    <div className={classes.progressProfile}>
                        {!uploading && <Avatar className={classes.avatarProfile} src={image} />}
                        {uploading && <CircularProgressWithLabel value={uploadProgress} />}
                    </div>
                    <div className={classes.control}>
                        <div className={classes.dFlex}>
                            <ImageFileInput
                                inputId={'profile-img-input'}
                                uploadAssetFn={handleUploadFile}
                                disabled={false}
                            />
                            <label htmlFor="profile-img-input">
                                <Button
                                    component="span"
                                    className={cx(classes.uploadImageButton, classes.customUpload)}
                                >
                                    <Add />
                                    <span>{t('common.components.profileImage.upload')}</span>
                                </Button>
                            </label>
                        </div>
                        {submitted && !image && required && (
                            <FormHelperText error="true">
                                {t('common.components.profileImage.pickupImage')}
                            </FormHelperText>
                        )}
                        <span className={classes.imagesRecommendation}>
                            {t('common.components.profileImage.recommendedImage')}
                        </span>
                    </div>
                </div>
            </FormControl>
        </FormGroup>
    );
};

export default ProfileImage;
