import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const integrationErrorSlice = createSlice({
    name: 'integrationError',
    initialState: {
        pending: false,
        savePending: false,
        integrationErrors: [],
        totalIntegrationErrors: 0,
        error: null,
        hasMore: true,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setIntegrationErrors: (state, action) => {
            state.pending = false;
            state.integrationErrors = [
                ...state.integrationErrors.slice(0, action.payload.offset),
                ...action.payload.data.errors,
            ];
            state.hasMore = action.payload.data.errors.length < 6;
        },
        setIntegrationErrorsList: (state, action) => {
            state.pending = false;
            state.integrationErrors = action.payload.errors;
            state.totalIntegrationErrors = action.payload.total;
        },
        setError: (state, action) => {
            state.pending = false;
            state.integrationErrors = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
    },
});

export const { start, stop, setIntegrationErrors, setIntegrationErrorsList, setError, startSave } =
    integrationErrorSlice.actions;

export const getIntegrationErrors = (state) => state.integrationError.integrationErrors;
export const getIntegrationErrorPending = (state) => state.integrationError.pending;
export const getIntegrationErrorSavePending = (state) => state.integrationError.savePending;
export const getIntegrationErrorErr = (state) => state.integrationError.error;
export const hasMoreIntegrationErrors = (state) => state.integrationError.hasMore;
export const getTotalIntegrationErrors = (state) => state.integrationError.totalIntegrationErrors;

export const fetchIntegrationErrorsListAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('integration-errors' + query)
            .then((response) => {
                dispatch(setIntegrationErrorsList(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const countCurrentIntegrationErrorsAction = () => async (dispatch) => {
    try {
        dispatch(start());
        return api
            .get('integration-errors/count-current')
            .then((response) => {
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export default integrationErrorSlice.reducer;
