import { makeStyles } from 'tss-react/mui';

const useFontStyles = makeStyles()((theme) => ({
    upperCase: {
        textTransform: 'uppercase',
    },
    tlnum: {
        fontFeatureSettings: '"tnum" on,"lnum" on',
    },
    textOverflowEllipsis: {
        textOverflow: 'ellipsis',
    },
    overflowEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    textTransformNone: {
        textTransform: 'none',
    },
    textCapitalize: {
        textTransform: 'capitalize',
    },
    breakWord: {
        wordBreak: 'break-word',
    },
    textDecorationNone: {
        textDecoration: 'none',
    },
    fontStyleItalic: {
        fontStyle: 'italic',
    },
    whiteSpacePreWrap: {
        whiteSpace: 'pre-wrap',
    },
    wrap: {
        textWrap: 'wrap',
    },
    noWrap: {
        textWrap: 'nowrap',
    },
    overflowWrap: {
        overflowWrap: 'break-word',
    },
    clamp: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
    },
    clampLine2: {
        WebkitLineClamp: 2,
    },
    clampLine3: {
        WebkitLineClamp: 3,
    },
    clampLine4: {
        WebkitLineClamp: 4,
    },
    clampLine5: {
        WebkitLineClamp: 5,
    },
    lHInherit: {
        lineHeight: 'inherit',
    },
    lineHeight0: {
        lineHeight: 0,
    },
    lHNormal: {
        lineHeight: 'normal',
    },
    lineHeight1: {
        lineHeight: 1,
    },
    textThrough: {
        textDecoration: 'line-through',
    },
    size15: {
        fontSize: theme.spacing(1.5)
    },
}));

export default useFontStyles;
