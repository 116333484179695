import { SelectValidator } from 'react-material-ui-form-validator';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '& .MuiInputBase-root': {
                height: theme.spacing(4.5),
                borderRadius: theme.spacing(4.5),
                '& > div': {
                    paddingLeft: theme.spacing(1),
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: '500',
                    position: 'relative',
                    '&:focus': {
                        borderRadius: 5,
                    },
                },

                '& > fieldset': {
                    borderColor: '#E2E2E2',
                },

                '& > input': {
                    display: 'none',
                },
            },
        },
    };
});

const CustomSelectValidator = (props) => {
    const { className, ...rest } = props;

    const { classes, cx } = useStyles();

    return <SelectValidator className={cx(classes.root, className)} {...rest}></SelectValidator>;
};

export default CustomSelectValidator;
