import { MenuItem, Typography } from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupsAction, getGroupsCurrentPage, getGroupsTotal, getGroupsTotalPages } from 'redux/slices/groupSlice';

const SelectGroups = ({
    customMenuProps,
    groupId,
    onSubmit,
    selectedGroupById,
    selectAll,
    fullWidth,
    selectNone,
    size = 'small',
    updateGroups,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hasMore, setHasMore] = useState(false);
    const total = useSelector(getGroupsTotal);
    const currentPage = useSelector(getGroupsCurrentPage);
    const totalPage = useSelector(getGroupsTotalPages);
    const [groups, setGroups] = useState([]);
    const [displayValue, setDisplayValue] = useState('all');
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [pending, setPending] = useState(true);
    const [initialLoadDone, setInitialLoadDone] = useState(false);
    const [wasSearching, setWasSearching] = useState(false);

    const [currentSelectedGroup, setCurrentSelectedGroup] = useState(selectedGroupById);

    useEffect(() => {
        if (selectedGroupById) {
            setCurrentSelectedGroup(selectedGroupById);
        }
    }, [selectedGroupById]);

    useEffect(() => {
        setDisplayValue(groupId || 'all');
    }, [groupId]);

    useEffect(() => {
        if (currentSelectedGroup && groupId && groupId !== 'all') {
            setGroups((prevGroups) => {
                // Don't add duplicates
                if (!prevGroups.some((group) => group.id === currentSelectedGroup.id)) {
                    return [...prevGroups, currentSelectedGroup];
                }
                return prevGroups;
            });
        }
    }, [currentSelectedGroup, groupId]);

    const loadData = useCallback(
        (loadPage = 1, shouldReset = false) => {
            setPending(true);
            dispatch(
                fetchGroupsAction({
                    queryParams: {
                        page: loadPage,
                        limit: 50,
                        ...(search ? { search, name: search } : {}),
                    },
                    onSuccess: (data) => {
                        setGroups((prevGroups) => {
                            // If shouldReset is true, only keep the selected group (if any)
                            const baseGroups = shouldReset
                                ? currentSelectedGroup && groupId && groupId !== 'all'
                                    ? [currentSelectedGroup]
                                    : []
                                : prevGroups;

                            // Filter out duplicates
                            const newGroups = data.filter(
                                (group) => !baseGroups.some((prevGroup) => prevGroup.id === group.id),
                            );

                            // Create the updated list
                            let updatedGroups = [...baseGroups, ...newGroups];

                            // Make sure selected group is included if we have one
                            if (currentSelectedGroup && groupId && groupId !== 'all') {
                                if (!updatedGroups.some((group) => group.id === currentSelectedGroup.id)) {
                                    updatedGroups = [currentSelectedGroup, ...updatedGroups];
                                }
                            }

                            updateGroups?.(updatedGroups);
                            setInitialLoadDone(true);
                            setPending(false);
                            return updatedGroups;
                        });
                    },
                    onFailure: () => {
                        setPending(false);
                    },
                }),
            );
        },
        [dispatch, search, updateGroups, currentSelectedGroup, groupId],
    );

    useEffect(() => {
        if (hasMore && total > groups?.length && currentPage < totalPage) {
            loadData(currentPage + 1, false);
        }
    }, [currentPage, groups, groups?.length, hasMore, loadData, total, totalPage]);

    useEffect(() => {
        if (!initialLoadDone) {
            loadData(1, false);
        }
    }, [loadData, initialLoadDone]);

    useEffect(() => {
        if (open) {
            if (search) {
                setWasSearching(true);
                // Reset groups and load search results
                loadData(1, true);
            }
        } else {
            setSearch('');
        }
    }, [search, open, loadData]);

    useEffect(() => {
        if (open) {
            // If we were searching before and now opening without search,
            // we need to reload the full list
            if (wasSearching && !search) {
                setWasSearching(false);
                loadData(1, false);
            }
        }
    }, [open, wasSearching, search, loadData]);

    const handleChange = (e) => {
        const newValue = e.target.value;

        // Update the selected group object if selecting a specific group
        if (newValue !== 'all' && newValue !== null) {
            const selectedGroup = groups.find((group) => group.id === newValue);
            if (selectedGroup) {
                setCurrentSelectedGroup(selectedGroup);
            }
        } else {
            setCurrentSelectedGroup(null);
        }

        if (search) {
            setSearch('');
        }

        onSubmit(e, 'group');
    };

    return (
        <RoundedSelect
            bold={true}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={displayValue}
            onChange={handleChange}
            size={size}
            search={groups?.length > 10 || Boolean(search) || pending}
            searchPlaceholder={t('iCalendar.filter.searchGroups')}
            onSearchFilter={setSearch}
            customMenuProps={customMenuProps}
            setHasMore={setHasMore}
            pending={pending}
            fullWidth={fullWidth}
        >
            {selectAll ? (
                <MenuItem value="all">{t('iCalendar.filter.allGroups')}</MenuItem>
            ) : (
                <MenuItem disabled value="all">
                    <em>{t('user.table.selectGroup')}</em>
                </MenuItem>
            )}
            {selectNone ? (
                <MenuItem value={null}>
                    <b>{t('common.none')}</b>
                </MenuItem>
            ) : null}
            {groups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                    {group.name}
                    &nbsp;
                    {group?.externalId ? <Typography>( {group?.externalId} )</Typography> : null}
                </MenuItem>
            ))}
        </RoundedSelect>
    );
};

export default SelectGroups;
