const config = {
    cdn: {
        baseUrl: process.env.REACT_APP_CDN_BASE_URL,
    },
    api: {
        baseURL: process.env.REACT_APP_API_URL,
        socketBaseURL: process.env.REACT_APP_SOCKET_API_URL,
    },
    instantSearch: {
        baseURL: process.env.REACT_APP_INSTANT_SEARCH_API_URL,
    },
    widgets: {
        widgetOneToManyCDN: process.env.REACT_APP_WIDGET_ONE_TO_MANY_CDN,
        widgetOneToOneCDNV1: process.env.REACT_APP_WIDGET_ONE_TO_ONE_CDN_V1,
        widgetOneToOneCDNV2: process.env.REACT_APP_WIDGET_ONE_TO_ONE_CDN_V2,
        widgetOneToOneCDNV3: process.env.REACT_APP_WIDGET_ONE_TO_ONE_CDN_V3,
    },
    shoppable: {
        baseURL: process.env.REACT_APP_SHOPPABLE_BASE_URL,
        studioURL: process.env.REACT_APP_SHOPPABLE_STUDIO_URL,
    },
    vod: {
        baseUrl: process.env.REACT_APP_VOD_BASE_URL,
    },
    streaming: {
        baseURL: process.env.REACT_APP_STREAMING_BASE_URL,
        oauthPopupTimeout: 300,
        recording121BaseURL: process.env.REACT_APP_STREAMING_RECORDING_121_BASE_URL,
    },
    accounts: {
        baseURL: process.env.REACT_APP_ACCOUNTS_BASE_URL,
    },
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
    oauth: {
        onlive: {
            authURL: process.env.REACT_APP_OAUTH_API_URL,
            clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
        },
        cloudStoreCodeExchangeURL: process.env.REACT_APP_OAUTH_CLOUD_STORE_CODE_EXCHANGE_URL, // CloudStore API
        google: {
            authURL: 'https://accounts.google.com/o/oauth2/auth',
            client_id: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID,
            scope: 'openid email https://www.googleapis.com/auth/userinfo.profile',
            authParams: {
                prompt: 'select_account consent',
            },
        },
    },
    stripe: {
        publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },
    legal: {
        privacyPolicyURL: process.env.REACT_APP_PRIVACY_POLICY_URL,
        termsAndConditionsURL: process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
    },
    helpApi: {
        baseURL: process.env.REACT_APP_HELP_API_BASE_URL,
    },
    socialApi: {
        baseURL: process.env.REACT_APP_SOCIAL_API_BASE_URL,
    },
    calendarApi: {
        baseURL: process.env.REACT_APP_CALENDAR_API_URL,
    },
    genaiHub: {
        api: {
            baseURL: process.env.REACT_APP_GENAI_HUB_API_BASE_URL,
        },
        socket: {
            baseURL: process.env.REACT_APP_GENAI_HUB_SOCKET_BASE_URL,
            path: process.env.REACT_APP_GENAI_HUB_SOCKET_PATH,
        },
    },
    calendarApiV3: {
        baseURL: process.env.REACT_APP_CALENDAR_API_V3_URL,
    },
    leadApi: {
        baseURL: process.env.REACT_APP_LEAD_API_URL,
    },
    communicationApi: {
        baseURL: process.env.REACT_APP_COMMUNICATION_API_BASE_URL,
    },

    // This is for file upload.
    media: {
        image: {
            allowedFormats: ['GIF', 'JPEG', 'PNG', 'WebP', 'JPG'],
        },
        video: {
            allowedFormats: [
                '3GP',
                '3G2',
                'ASF',
                'WMV',
                'WMA',
                'AVI',
                'FLV',
                'F4V',
                'GIF',
                'HSL',
                'IMF',
                'MOV',
                'MP4',
                'MPEG-1',
                'MPEG-PS',
                'MPEG-TS',
                'MXF',
                'WebM',
                'DivX',
                'M2V',
                'M3U',
                'M3U8',
                'M4V',
                'MKV',
                'MPEG',
                'OGV',
                'QVT',
                'RAM',
                'RM',
                'VOB',
                'WebM',
                'XAP',
                'mkv',
            ],
            allowedMimeTypes: [
                'video/mp4',
                'video/mpeg',
                'video/mpeg2',
                'video/webm',
                'video/3gpp',
                'video/3gpp2',
                'video/x-matroska',
                'video/matroska',
                'video/quicktime',
                'video/x-f4v',
                'video/x-flv',
                'video/webm',
                'video/avi',
                'video/x-msvideo',
                'video/msvideo',
                'video/x-ms-wmv',
                'image/gif',
            ],
        },
        dataFile: {
            allowedFormats: ['CSV', 'XML'],
        },
        presentation: {
            allowedFormats: ['PPT', 'PPTX', 'PDF', 'ODP'],
            allowedMimeTypes: [
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/pdf',
                'application/vnd.oasis.opendocument.presentation',
            ],
            placeholder: 'https://cdn.onlive.site/assets/mvp/cover_hand_live_gray.jpg',
        },
        immersive: {
            allowedFormats: ['zip', '7zip', 'rar'],
            allowedMimeTypes: [
                'application/zip',
                'application/x-7z-compressed',
                'application/vnd.rar',
                'application/x-zip-compressed',
            ],
        },
    },
    gatewayApi: {
        gatewayUrl: process.env.REACT_APP_API_GATEWAY_URL,
    },
    msalConfig: {
        auth: {
            clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
            clientSecret: process.env.REACT_APP_MSAL_CLIENT_SECRET,
            tenantId: process.env.REACT_APP_MSAL_TENANT_ID,
            redirectUri: `${window.location.origin.toString()}/ocalendar`,
        },
    },
    msalLoginScopes: {
        scopes: ['https://graph.microsoft.com/.default'],
    },
    loader: {
        baseURL: process.env.REACT_APP_ONLIVE_LOADER_CDN,
    },
    immersive: {
        tourURL: process.env.REACT_APP_IMMERSIVE_TOUR_URL,
        script: {
            fileInput: {
                allowedFormats: ['py'],
                allowedMimeTypes: ['text/x-python'],
            },
        },
    },
    bff: {
        baseURL: process.env.REACT_APP_BFF_URL,
    },
    oEmbed: {
        baseURL: process.env.REACT_APP_OEMBED_URL,
    },
    appointment: {
        baseUrl: process.env.REACT_APP_APPOINTMENT_BASE_URL,
    },
    helpUrl: {
        onlive: 'https://8593667.hs-sites.com/knowledge-base-onlive-tool',
        cupra: 'https://client.onlive.site/cupra/manuals.html',
    },
    authenticatorApp: {
        playStore: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
        appStore: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    },
};

export default config;
