import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const recordingSlice = createSlice({
    name: 'recording',
    initialState: {
        pending: false,
        recordings: [],
        error: null,
        deletePending: false,
        deleteId: null,
        total: 0,
        count: 0,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setRecordings: (state, action) => {
            state.pending = false;
            state.recordings = action.payload.items;
            state.total = action.payload.total;
            state.count = action.payload.count;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.savePending = false;
            state.error = action.payload;
        },
        deleteRecording: (state, action) => {
            state.deletePending = false;
            state.deleteId = null;
            const index = state.recordings.findIndex((recording) => recording.id === action.payload.id);
            if (index !== -1) {
                state.recordings = [...state.recordings.slice(0, index), ...state.recordings.slice(index + 1)];
            }
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
            state.deleteId = null;
        },
    },
});

export const { start, stop, setRecordings, setError, deleteRecording, startDelete, stopDelete } =
    recordingSlice.actions;

export const getRecordings = (state) => state.recording.recordings;
export const getRecordingsPending = (state) => state.recording.pending;
export const getDeletePending = (state) => state.recording.deletePending;
export const getRecordingsTotal = (state) => state.recording.total;
export const getRecordingsTotalPages = (state) => state.recording.totalPages;
export const getRecordingsCurrentPage = (state) => state.recording.currentPage;

export const fetchRecordingsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get(`recordings` + query)
                .then((response) => {
                    dispatch(setRecordings(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const deleteRecordingAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete('recordings/' + id)
                .then((response) => {
                    dispatch(deleteRecording(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error(e.message);
        }
    };

export default recordingSlice.reducer;
