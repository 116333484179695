import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button, CircularProgress, MenuItem } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { calendarApiV3 as api } from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ReminderForm from 'common/components/ReminderForm';
import RoundedSelect from 'common/components/RoundedSelect';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import {
    disableCalendarIntegrationAction,
    fetchCalendarIntegrationAction,
    fetchHasCredentialAction,
    getCredentialLevel,
    getHasCredential,
    getIsConnected,
    getPendingHasCredential,
    getPendingIntegration,
    getProviderType
} from 'redux/slices/icalendarProviderSlice';
import { fetchInstallationsAction, getInstallations } from 'redux/slices/installationSlice';
import { checkImportStatus, getOrganization, setICalendarStoreKeyForImporting } from 'redux/slices/organizationSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import { fetchTriagesAction, getTriages } from 'redux/slices/triageSlice';
import JsonCodeInputMonacoEditor from '../../../../common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from '../../../../common/components/code-editor/SimpleCodeErrorView';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import ICalendarIntegrationDialog from './ICalendarIntegrationDialog';

const ICalendarSettings = (props) => {
    const {
        toggleChange,
        handleSubmit,
        hasChanges,
        serviceAppointmentCreateTriageId,
        appointmentRatingInstallation,
        sendBookingEmailToAgent,
        appointmentStatus,
        appointmentOrigin,
        enableAgentEditAvailability,
        icalendarEmailPreviousReminders,
        icalendarEmailPastReminders,
        enableAgentEditGroupAppointment,
    } = props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2, cx } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };
    const triages = useSelector(getTriages);
    const settingsDirty = useSelector(getSettingsDirty);
    const user = useSelector(getUser);
    const organization = useSelector(getOrganization);
    const { id: organizationId } = organization;
    const dispatch = useDispatch();
    const [inputError, setInputError] = useState({ appointmentStatus: null, appointmentOrigin: null });
    const installations = useSelector(getInstallations);
    const [pendingImport, setPendingImport] = useState(true);
    const isCalendarConnected = useSelector(getIsConnected);
    const integrationProviderType = useSelector(getProviderType);
    const integrationCredentialLevel = useSelector(getCredentialLevel);
    const pendingIntegration = useSelector(getPendingIntegration);
    const hasCredential = useSelector(getHasCredential);
    const pendingHasCredential = useSelector(getPendingHasCredential);
    const [openIntegrationDialog, setOpenIntegrationDialog] = useState(false);

    const loadData = useCallback(() => {
        dispatch(
            fetchTriagesAction({
                organizationId,
            }),
        );
        dispatch(
            fetchInstallationsAction({
                organizationId,
            }),
        );

        dispatch(fetchCalendarIntegrationAction(organizationId));
    }, [dispatch, organizationId]);

    const loadIntegrationData = useCallback(() => {
        if (isCalendarConnected && integrationCredentialLevel === 'Organization') {
            dispatch(fetchHasCredentialAction(organizationId));
        }
    }, [dispatch, organizationId, isCalendarConnected, integrationCredentialLevel]);

    const authCalendarIntegration = useCallback(() => {
        if (isCalendarConnected && integrationCredentialLevel === 'Organization' && !hasCredential && !pendingHasCredential) {
            api.get(`integrations/${integrationProviderType.toLowerCase()}/auth-url?organizationId=${organizationId}`)
                .then((response) => {
                    window.location.href = response.data.authUrl;
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [isCalendarConnected, organizationId, integrationProviderType, integrationCredentialLevel, hasCredential, pendingHasCredential]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        loadIntegrationData();
    }, [loadIntegrationData]);

    useEffect(() => {
        authCalendarIntegration();
    }, [authCalendarIntegration]);

    const importStatus = useCallback(() => {
        if (!pendingImport) return;
        dispatch(checkImportStatus(
            organizationId,
            () => setPendingImport(false),
            () => setPendingImport(false),
        ));
    }, [dispatch, organizationId, pendingImport]);

    useEffect(() => {
        importStatus();

        const intervalId = setInterval(importStatus, 60000);

        return () => clearInterval(intervalId);
    }, [importStatus]);

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const handleSelectChange = (e) => {
        // setLangSetting(e.target.value);
        toggleChange(e.target.name, e.target.value);
    };

    const handleImportData = () => {
        api.get(`importer/organizations/${organizationId}/import-data-from-v2`)
            .then((response) => {
                dispatch(setICalendarStoreKeyForImporting(response.data));
                setPendingImport(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCalendarIntegration = () => {
        if (isCalendarConnected) {
            dispatch(disableCalendarIntegrationAction(organizationId));
        } else {
            setOpenIntegrationDialog(true);
        }
    }

    const handleCloseIntegrationDialog = () => {
        setOpenIntegrationDialog(false);
    };

    if (user?.role !== 'root') return null;

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.iCalendar')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <div className={classes.pb20px}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('Save changes')}
                            </Button>
                        </Box>
                    </Box>
                </div>

                <div className={classes.pb10px}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: '100%' }}
                    >
                        <Box sx={{ display: 'inline-flex' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleCalendarIntegration}
                            >
                                {
                                    pendingIntegration ? (
                                        <CircularProgress style={{ width: '80%', height: 'auto' }} color="secondary" />
                                    ) : (
                                        isCalendarConnected
                                            ? t('calendar.DisconnectFromExternalProvider')
                                            : t('profile.settings.iCalendar.addCalendarIntegration')
                                    )
                                }
                            </Button>
                        </Box>
                    </Box>
                </div>

                <ICalendarIntegrationDialog
                    openDialog={openIntegrationDialog}
                    onCloseDialog={handleCloseIntegrationDialog}
                />

                {user?.role === 'root' && (
                    <div className={classes.pb10px}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: '100%' }}
                        >
                            <Box sx={{ display: 'inline-flex' }}>
                                <Button
                                    variant="contained"
                                    className={classes.redColor}
                                    size="small"
                                    onClick={handleImportData}
                                    disabled={pendingImport}
                                >
                                    {pendingImport ? (
                                        <CircularProgress style={{ width: '80%', height: 'auto' }} color="secondary" />
                                    ) : (
                                        t('Import data')
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}

                <ItemSettings
                    label={t('profile.settings.iCalendar.sendBookingEmailToAgent')}
                    onChange={handleCheckChange}
                    name={SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT}
                    keySettings={SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT}
                    value={sendBookingEmailToAgent}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.settings.iCalendar.enableAgentEditAvailability')}
                    onChange={handleCheckChange}
                    name={'enableAgentEditAvailability'}
                    keySettings={SettingsKey.ENABLE_AGENT_EDIT_AVAILABILITY}
                    value={enableAgentEditAvailability}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.settings.iCalendar.enableAgentEditGroupAppointment')}
                    onChange={handleCheckChange}
                    name={'enableAgentEditGroupAppointment'}
                    keySettings={SettingsKey.ENABLE_AGENT_EDIT_GROUP_APPOINTMENT}
                    value={enableAgentEditGroupAppointment}
                    paddingBottom
                />

                <div className={cx(classes.pt20px, classes.pb20px)}>
                    <Typography className={classes.emailsTemplatesText}>
                        {t('profile.settings.iCalendar.triageAppointmentCreate')}
                    </Typography>
                    <RoundedSelect
                        value={serviceAppointmentCreateTriageId}
                        fullWidth
                        name={SettingsKey.SERVICE_APPOINTMENT_CREATE_TRIAGE_ID}
                        onChange={(e) => handleSelectChange(e)}
                        iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                    >
                        {triages?.map((triage) => (
                            <MenuItem
                                value={triage._id}
                                key={triage._id}
                                selected={triage._id === serviceAppointmentCreateTriageId}
                            >
                                {triage.name}
                            </MenuItem>
                        ))}
                    </RoundedSelect>
                </div>

                <div className={cx(classes.pt20px, classes.pb20px)}>
                    <Typography className={classes.emailsTemplatesText}>
                        {t('profile.settings.iCalendar.triageAppointmentRating')}
                    </Typography>
                    <RoundedSelect
                        value={appointmentRatingInstallation}
                        fullWidth
                        name={SettingsKey.APPOINTMENT_RATING_INSTALLATION}
                        onChange={(e) => handleSelectChange(e)}
                        iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                    >
                        {installations?.map((i) => (
                            <MenuItem value={i.id} key={i.id} selected={i.id === appointmentRatingInstallation}>
                                {i.name}
                            </MenuItem>
                        ))}
                    </RoundedSelect>
                </div>

                <div className={classes.pb20px}>
                    <Typography className={classes.emailsTemplatesText}>{`${t(
                        'profile.settings.iCalendar.appointmentStatus',
                    )}`}</Typography>
                    <JsonCodeInputMonacoEditor
                        name="appointmentStatus"
                        value={appointmentStatus}
                        onChange={(value, errors) => {
                            const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
                            const newErrors = codeHaveErrors ? errors : null;
                            setInputError({ appointmentStatus: newErrors });
                            toggleChange(SettingsKey.APPOINTMENT_STATUS, value);
                        }}
                    />
                    <SimpleCodeErrorView
                        id={'error-context'}
                        mainErrorMessage={t('JsonError')}
                        errorList={inputError['appointmentStatus']}
                    />
                </div>

                <div className={classes.pb20px}>
                    <Typography className={classes.emailsTemplatesText}>{`${t(
                        'profile.settings.iCalendar.appointmentOrigin',
                    )}`}</Typography>
                    <JsonCodeInputMonacoEditor
                        name="appointmentOrigin"
                        value={appointmentOrigin}
                        onChange={(value, errors) => {
                            const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
                            const newErrors = codeHaveErrors ? errors : null;
                            setInputError({ appointmentOrigin: newErrors });
                            toggleChange(SettingsKey.APPOINTMENT_ORIGIN, value);
                        }}
                    />
                    <SimpleCodeErrorView
                        id={'error-context'}
                        mainErrorMessage={t('JsonError')}
                        errorList={inputError['appointmentOrigin']}
                    />
                </div>

                <ReminderForm
                    emailReminders={icalendarEmailPreviousReminders}
                    toggleChange={toggleChange}
                    settingKey={SettingsKey.ICALL_EMAIL_REMINDERS}
                    time="before"
                />

                <ReminderForm
                    emailReminders={icalendarEmailPastReminders}
                    toggleChange={toggleChange}
                    settingKey={SettingsKey.ICALENDAR_EMAIL_PAST_REMINDERS}
                    time="after"
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default ICalendarSettings;
