import { createSlice } from '@reduxjs/toolkit';

export const streamConnectorsSlice = createSlice({
    name: 'streamConnectors',
    initialState: {
        streamConnectors: [],
    },
    reducers: {
        resetStreamConnectors: (state) => {
            state.streamConnectors = [];
        },
        setStreamConnectors: (state, action) => {
            state.streamConnectors = action.payload;
        },
    },
});

export const { resetStreamConnectors, setStreamConnectors } = streamConnectorsSlice.actions;

export const getStreamConnectors = (state) => state.streamConnectors.streamConnectors;

export default streamConnectorsSlice.reducer;
