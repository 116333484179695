import {
    Accordion,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSupportedLangs } from 'common/constants/langs';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';

const GeneralSettings = (props) => {
    const {
        langSettingValue,
        toggleChange,
        handleSubmit,
        timezoneSettingValue,
        twelveHourFormatSettingValue,
        hasChanges,
    } = props;

    const { t } = useTranslation();
    const langs = useSupportedLangs();

    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);

    const [langSetting, setLangSetting] = useState(langSettingValue);
    const [timezoneSetting, setTimezoneSetting] = useState(timezoneSettingValue);
    const [twelveHourFormatSetting, setTwelveHourFormatSetting] = useState(twelveHourFormatSettingValue);

    const handleChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const handleTimezoneChange = (e) => {
        setTimezoneSetting(e.target.value);
        handleChange(e, 'timezone');
    };

    const handleTwelveHourFormatChange = (e) => {
        setTwelveHourFormatSetting(e.target.checked);
        toggleChange(SettingsKey.TWELVE_HOUR_FORMAT, e.target.checked);
    };

    const handleLanguageChange = (e) => {
        setLangSetting(e.target.value);
        handleChange(e, SettingsKey.DEFAULT_LANGUAGE);
    };

    const renderGeneralSettings = ({ disabled }) => (
        <Accordion disabled={disabled} className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.generalSettings')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="end" alignItems="center" style={{ width: '100%' }}>
                    <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('Save changes')}
                        </Button>
                    </Box>
                </Box>
                <FormGroup>
                    <Box display="flex" justifyContent="start" alignItems="center" style={{ width: '100%' }}>
                        <FormControl className={classes.languageSelect}>
                            <InputLabel>{t('profile.settings.generalSettings.defaultLanguage')}</InputLabel>
                            <Select
                                value={langSetting}
                                label="Default language"
                                onChange={(e) => handleLanguageChange(e)}
                            >
                                {langs.map((lang) => {
                                    const codes = lang.languageCode.split('-');
                                    return (
                                        <MenuItem value={lang.languageCode} key={lang.languageCode}>
                                            {t(
                                                `common.constants.lang.${
                                                    codes.length === 1
                                                        ? codes[0]
                                                        : codes[0] + codes[1][0].toUpperCase() + codes[1].slice(1)
                                                }`,
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.languageSelect} style={{ minWidth: 180 }}>
                            <InputLabel>{t('profile.settings.generalSettings.timezone')}</InputLabel>
                            <Select value={timezoneSetting} label="Timezone" onChange={(e) => handleTimezoneChange(e)}>
                                {moment.tz.names().map((tz) => {
                                    return (
                                        <MenuItem value={tz} name={tz} key={tz}>
                                            {tz}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={twelveHourFormatSetting}
                                onChange={(e) => handleTwelveHourFormatChange(e)}
                                name="twelveHourFormat"
                                color="primary"
                            />
                        }
                        label={<FormLabel>{t('profile.settings.generalSettings.twelveHourFormat')}</FormLabel>}
                    />
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );

    return (
        <>
            {renderGeneralSettings({
                disabled: false,
            })}
        </>
    );
};

export default GeneralSettings;
