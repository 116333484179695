import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Chip, CircularProgress, TextField } from '@mui/material';
import CustomAutocomplete from 'common/components/CustomAutocomplete';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        '& .MuiInputBase-root': {
            background: '#FFFF',
            borderRadius: theme.spacing(2),

            [theme.breakpoints.up('xl')]: {
                height: 'auto',
                borderRadius: theme.spacing(2.5),
            },

            '& > fieldset': {
                borderColor: '#E2E2E2',
                borderRadius: theme.spacing(3),
            },

            '& > input': {
                padding: theme.spacing(0, 1),

                fontSize: '12px',
                fontWeight: '500',
                position: 'relative',
                height: theme.spacing(3),
                '&:focus': {
                    borderRadius: 5,
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '14px',
                },
            },
        },
        '& .MuiInputBase-sizeSmall': {
            paddingTop: theme.spacing(0.5) + '!important',
            paddingBottom: theme.spacing(0.5) + '!important',
        },
    },
}));

export const CustomAutocompleteSearch = ({
    selectedValues,
    setSelectedValues,
    options,
    keyProp,
    renderOption,
    getOptionLabel,
    optionProp,
    pending,
    disabled,
    getOptionDisabled,
}) => {
    const { classes } = useStyles();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const handleTagChange = (values) => {
        setSelectedValues([...values]);
    };

    return (
        <CustomAutocomplete
            disabled={disabled}
            multiple
            value={selectedValues}
            onChange={(event, value) => {
                handleTagChange(value);
            }}
            popupIcon={<ExpandMoreOutlinedIcon />}
            options={options}
            disableCloseOnSelect
            size={'small'}
            getOptionLabel={(option) => {
                if (getOptionLabel) {
                    return getOptionLabel(option);
                } else {
                    return option[keyProp];
                }
            }}
            getOptionSelected={(option, value) =>
                optionProp ? option[optionProp] === value[optionProp] : option.id === value.id
            }
            isOptionEqualToValue={(option, value) =>
                optionProp ? option[optionProp] === value[optionProp] : option.id === value.id
            }
            getOptionDisabled={(option) => {
                if (getOptionDisabled) {
                    return getOptionDisabled(option);
                } else return false;
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            loading={pending}
            className={classes.root}
            loadingText={t('features.streaming.loading')}
            renderOption={(props, option) => {
                if (renderOption) {
                    return renderOption(props, option);
                } else {
                    return (
                        <li aria-hidden="true" {...props} key={option.id}>
                            {option[keyProp]}
                        </li>
                    );
                }
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            label={getOptionLabel ? getOptionLabel(option) : option[keyProp]}
                            {...tagProps}
                            disabled={getOptionDisabled ? getOptionDisabled(option) : disabled}
                        />
                    );
                })
            }
            disableClearable={true}
            renderInput={(params) => (
                <TextField
                    disabled
                    {...params}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {pending ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
            autoCorrect="false"
        />
    );
};

export default CustomAutocompleteSearch;
