import { Close } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button } from '@mui/material';
import { NotificationService } from '@onlive.site/front-core';
import api, { calendarApiForNotification } from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { isValidHttpUrl } from 'common/utils/utils';
import moment from 'moment';
import { Fragment } from 'react';
import config from '../config';

const isValidUUID = (uuid) => uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i);

let instance;
let globalState = {
    db: null,
    userId: null,
    organizationSettings: null,
    enqueueSnackbar: null,
    closeSnackbar: null,
};

class CalendarNotificationService {
    constructor() {
        if (!instance) {
            instance = this;
        }
    }

    initialize(userId, organizationSettings, enqueueSnackbar, closeSnackbar) {
        return new Promise((resolve, reject) => {
            globalState.userId = userId;
            globalState.organizationSettings = organizationSettings;
            globalState.enqueueSnackbar = enqueueSnackbar;
            globalState.closeSnackbar = closeSnackbar;

            // Verify if the browser support indexedDB
            if (!window.indexedDB) {
                console.log("Your browser doesn't support a stable version of IndexedDB.");
                return reject();
            }

            // Verify if has permission to send push notifications
            const icallNotifications = globalState.organizationSettings.find(
                (s) => s.settingKey === SettingsKey.ICALL_NOTIFICATIONS,
            )?.settingValue;
            if (icallNotifications?.[SettingsKey.CALENDAR_REMINDER]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]) {
                if (!('Notification' in window)) {
                    console.log('This browser does not support desktop notification');
                } else if (Notification.permission !== 'granted') {
                    Notification.requestPermission().then((_permission) => {});
                }
            }

            const DBDeleteRequest = indexedDB.deleteDatabase('CalendarNotification');

            DBDeleteRequest.onerror = (event) => {
                console.error('Error deleting database.');
            };

            DBDeleteRequest.onsuccess = (event) => {
                console.log('Database deleted successfully');
            };

            const request = indexedDB.open('CalendarNotification', 1);

            request.onerror = (event) => {
                console.log('Error loading database.');
                return reject();
            };

            request.onsuccess = (event) => {
                // Store the result of opening the database in the #db variable. This is used a lot below
                globalState.db = event.target.result;
                // Verifies if exist object store
                if (globalState.db.objectStoreNames.contains('CalendarNotification')) {
                    console.log('Database initialised');
                    return resolve();
                }
            };

            // This event handles the event whereby a new version of the database needs to be created
            // Either one has not been created before, or a new version number has been submitted via the
            // window.indexedDB.open line above
            //it is only implemented in recent browsers
            request.onupgradeneeded = (event) => {
                globalState.db = event.target.result;
                globalState.db.onerror = (event) => {
                    console.log('Error loading database.');
                };
                // Create an objectStore for this database
                const objectStore = globalState.db.createObjectStore('CalendarNotification', { keyPath: 'eventId' });
                // Define what data items the objectStore will contain
                objectStore.createIndex('hours', 'hours', { unique: false });
                objectStore.createIndex('minutes', 'minutes', { unique: false });
                objectStore.createIndex('day', 'day', { unique: false });
                objectStore.createIndex('month', 'month', { unique: false });
                objectStore.createIndex('year', 'year', { unique: false });

                objectStore.createIndex('title', 'title', { unique: false });
                objectStore.createIndex('location', 'location', { unique: false });
                objectStore.createIndex('publicId', 'publicId', { unique: false });
                objectStore.createIndex('description', 'description', { unique: false });

                console.log('Database setup complete');
            };
        });
    }

    async proccessEvents() {
        // Clear database
        this.#clearDataBase();

        // Fetch events from calendar
        await this.#fetchEvents();

        // Check new events every 5 minutes
        this.#setAsyncInterval(async () => await this.#fetchEvents(), 1000 * 60 * 5);

        // Check deadlines every minute
        setInterval(this.#checkDeadlines, 1000 * 60);
    }

    addNewEvent(e) {
        if (!e.instant) {
            const now = new Date();
            const temp = moment.parseZone(e.releaseDate);
            // check 1 minute before the event at less
            const diff = temp.toDate().getTime() - now.getTime();
            const diffMinutes = Math.round(diff / 60000);
            if (diffMinutes >= 1) {
                const event = {
                    hours: temp.toDate().getHours(),
                    minutes: temp.toDate().getMinutes(),
                    day: temp.toDate().getDate(),
                    month: temp.toDate().getMonth(),
                    year: temp.toDate().getFullYear(),
                    title: e.name ?? moment(e.releaseDate).format('LLL'),
                    location: e.publicUrl,
                    eventId: e.id,
                    publicId: e.publicId,
                    description: e.description,
                };
                this.#addEvent(event);
            }
        }
    }

    deleteEvent(eventId) {
        if (!globalState.db) {
            return;
        }
        const transaction = globalState.db.transaction(['CalendarNotification'], 'readwrite');
        const objectStore = transaction.objectStore('CalendarNotification');
        objectStore.delete(eventId);
    }

    updateEvent(event) {
        if (!globalState.db) {
            return;
        }
        const transaction = globalState.db.transaction(['CalendarNotification'], 'readwrite');
        const objectStore = transaction.objectStore('CalendarNotification');
        const eventId = event.eventId;
        const objectStoreRequest = objectStore.get(eventId);
        objectStoreRequest.onsuccess = (e) => {
            const myRecord = objectStoreRequest.result;
            if (myRecord) {
                const now = new Date();
                const temp = moment.parseZone(event.releaseDate);
                // check 1 minute before the event at lest
                const diff = temp.toDate().getTime() - now.getTime();
                const diffMinutes = Math.round(diff / 60000);
                if (
                    diffMinutes >= 1 &&
                    (myRecord.hours !== temp.toDate().getHours() ||
                        myRecord.minutes !== temp.toDate().getMinutes() ||
                        myRecord.day !== temp.toDate().getDate() ||
                        myRecord.month !== temp.toDate().getMonth() ||
                        myRecord.year !== temp.toDate().getFullYear() ||
                        myRecord.title !== event.name ||
                        myRecord.description !== event.description ||
                        myRecord.location !== event.publicUrl)
                ) {
                    myRecord.hours = temp.toDate().getHours();
                    myRecord.minutes = temp.toDate().getMinutes();
                    myRecord.day = temp.toDate().getDate();
                    myRecord.month = temp.toDate().getMonth();
                    myRecord.year = temp.toDate().getFullYear();
                    myRecord.title = event.name ?? moment(event.releaseDate).format('LLL');
                    myRecord.description = event.description;
                    myRecord.location = event.publicUrl;
                    objectStore.put(myRecord);
                } else if (diffMinutes < 1) {
                    objectStore.delete(eventId);
                }
            } else {
                this.addNewEvent({
                    instant: false,
                    releaseDate: event.releaseDate,
                    name: event.name,
                    publicUrl: event.publicUrl,
                    id: event.eventId,
                    publicId: event.publicId,
                    description: event.description,
                });
            }
        };
    }

    #setAsyncInterval = (fn, interval) => {
        const now = new Date();
        const delay = interval - (now % interval);
        const boundFn = fn.bind(null);
        setTimeout(async () => {
            await boundFn();
            setInterval(boundFn, interval);
        }, delay);
    };

    #clearDataBase() {
        const transaction = globalState.db.transaction(['CalendarNotification'], 'readwrite');
        const objectStore = transaction.objectStore('CalendarNotification');
        const objectStoreRequest = objectStore.clear();
        objectStoreRequest.onsuccess = (event) => {
            console.log('Database cleared');
        };
    }

    async #fetchEvents() {
        if (!globalState.db) {
            return;
        }
        calendarApiForNotification({
            method: 'get',
            url: `${config.calendarApi.baseURL}/api/v2/appointment/user/${globalState.userId}?startDate=${moment()
                .startOf('day')
                .toDate()
                .toISOString()}&endDate=${moment().endOf('day').toDate().toISOString()}`,
        })
            .then((response) => {
                if (response.data && response.data?.length > 0) {
                    response.data.forEach(async (appointment) => {
                        const temp = moment.parseZone(appointment.appointmentDate);
                        if (temp.isSameOrAfter(moment().startOf('day')) && temp.isSameOrBefore(moment().endOf('day'))) {
                            let location = null;
                            let publicId = null;
                            if (appointment.eventId) {
                                const onliveEvent = await this.#fetchEvent(appointment.eventId);
                                if (onliveEvent) {
                                    location = onliveEvent.publicUrl;
                                    publicId = onliveEvent.publicId;
                                }
                            }
                            const event = {
                                releaseDate: appointment.appointmentDate,
                                name: appointment.name,
                                eventId: appointment.eventId || appointment.id,
                                publicUrl: location || appointment.iCallUrl,
                                publicId: publicId,
                                description: appointment.description,
                            };
                            this.updateEvent(event);
                        }
                    });
                }
            })
            .catch((error) => {
                return Promise.resolve();
            });
    }

    async #fetchEvent(eventId) {
        try {
            // TODO: check when start to use external calendar providers
            if (!isValidUUID(eventId)) return null;

            const response = await api.get(`events/${eventId}`, {});
            return response.data;
        } catch (error) {
            return null;
        }
    }

    #addEvent(event) {
        if (!globalState.db) {
            return;
        }
        // Open a transaction to the database
        const transaction = globalState.db.transaction(['CalendarNotification'], 'readwrite');
        // Handler for any unexpected error
        transaction.onerror = () => {
            console.log(`Transaction not opened due to error: ${transaction.error}`);
        };

        // Ask for the objectStore
        const objectStore = transaction.objectStore('CalendarNotification');
        // Make a request to add our newItem object to the object store
        const objectStoreRequest = objectStore.add(event);
        objectStoreRequest.onsuccess = (event) => {
            console.log('Event added to the object store');
        };
    }

    #checkDeadlines() {
        // Open a new transaction
        const objectStore = globalState.db
            .transaction(['CalendarNotification'], 'readwrite')
            .objectStore('CalendarNotification');

        // Open a cursor to iterate through the items in the database
        objectStore.openCursor().onsuccess = (event) => {
            const now = new Date();
            now.setSeconds(0);
            now.setMilliseconds(0);

            const cursor = event.target.result;
            if (!cursor) return;

            const onliveEvent = cursor.value;

            const onliveDate = new Date(
                onliveEvent.year,
                onliveEvent.month,
                onliveEvent.day,
                onliveEvent.hours,
                onliveEvent.minutes,
            );
            const diff = onliveDate.getTime() - now.getTime();
            const diffMinutes = Math.trunc(diff / 60000);
            // check 10 or 1 minute before the event
            if (diffMinutes === 10 || diffMinutes === 1) {
                const icallNotifications = globalState.organizationSettings.find(
                    (s) => s.settingKey === SettingsKey.ICALL_NOTIFICATIONS,
                )?.settingValue;
                if (icallNotifications) {
                    const body = onliveEvent.location
                        ? onliveEvent.location
                        : onliveEvent.description
                        ? onliveEvent.description
                        : '';
                    const icon = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/icon-128.png`;
                    // Verify if has enable vms system notifications
                    if (
                        icallNotifications[SettingsKey.CALENDAR_REMINDER]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]
                    ) {
                        const action = (key) => (
                            <Fragment>
                                {(onliveEvent.publicId || onliveEvent.location) && (
                                    <Button
                                        variant="text"
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                            CalendarNotificationInstance.deleteEvent(onliveEvent.eventId);
                                            globalState.closeSnackbar(key);
                                            onliveEvent.publicId
                                                ? window.onliveManager.services.onliveMeet.joinToMeet([
                                                      onliveEvent.publicId,
                                                  ])
                                                : window.open(onliveEvent.location, '_blank').focus();
                                        }}
                                        style={{ minWidth: '32px' }}
                                    >
                                        <LaunchIcon />
                                    </Button>
                                )}
                                <Button
                                    variant="text"
                                    size="small"
                                    color="secondary"
                                    onClick={() => globalState.closeSnackbar(key)}
                                    style={{ minWidth: '32px' }}
                                >
                                    <Close />
                                </Button>
                            </Fragment>
                        );
                        NotificationService.notify(
                            'vms_notification',
                            {
                                time: moment()
                                    .set({
                                        hours: onliveEvent.hours,
                                        minutes: onliveEvent.minutes,
                                        seconds: 0,
                                        milliseconds: 0,
                                    })
                                    .format('HH:mm'),
                                title: onliveEvent.title,
                                action: action,
                            },
                            (data) => {
                                globalState.enqueueSnackbar(
                                    `${data.time} ${data.title ? data.title : 'Calendar event'}`,
                                    {
                                        variant: 'info',
                                        persist: true,
                                        action: data.action,
                                    },
                                );
                            },
                        );
                    }
                    // Verify if has enable browser sound notifications
                    if (
                        icallNotifications[SettingsKey.CALENDAR_REMINDER]?.[
                            SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION
                        ]
                    ) {
                        const sound = icallNotifications[SettingsKey.CALENDAR_REMINDER]?.[
                            SettingsKey.URL_BROWSER_SOUND_NOTIFICATION
                        ]
                            ? icallNotifications[SettingsKey.CALENDAR_REMINDER]?.[
                                  SettingsKey.URL_BROWSER_SOUND_NOTIFICATION
                              ]
                            : `${process.env.REACT_APP_ASSETS_SOUNDS_URL}/notification.mp3`;
                        const notification = NotificationService.notify('sound_notification', sound);
                        notification.play();
                    }
                    // Verify if has enable browser push notifications
                    if (
                        icallNotifications[SettingsKey.CALENDAR_REMINDER]?.[
                            SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION
                        ]
                    ) {
                        if (!('Notification' in window)) {
                            console.log('This browser does not support desktop notification');
                        } else {
                            if (Notification.permission !== 'granted') {
                                Notification.requestPermission().then((permission) => {
                                    if (permission === 'granted') {
                                        const notification = NotificationService.notify('push_notification', {
                                            title: `${moment()
                                                .set({
                                                    hours: onliveEvent.hours,
                                                    minutes: onliveEvent.minutes,
                                                    seconds: 0,
                                                    milliseconds: 0,
                                                })
                                                .format('HH:mm')} ${
                                                onliveEvent.title ? onliveEvent.title : 'Calendar event'
                                            }`,
                                            body: body,
                                            icon: icon,
                                        });
                                        notification.onclick = () => {
                                            if (isValidHttpUrl(body)) {
                                                window.open(body, '_blank').focus();
                                            }
                                        };
                                    }
                                });
                            } else {
                                const notification = NotificationService.notify('push_notification', {
                                    title: `${moment()
                                        .set({
                                            hours: onliveEvent.hours,
                                            minutes: onliveEvent.minutes,
                                            seconds: 0,
                                            milliseconds: 0,
                                        })
                                        .format('HH:mm')} ${onliveEvent.title ? onliveEvent.title : 'Calendar event'}`,
                                    body: body,
                                    icon: icon,
                                });
                                notification.onclick = () => {
                                    if (isValidHttpUrl(body)) {
                                        window.open(body, '_blank').focus();
                                    }
                                };
                            }
                        }
                    }
                }
            } else if (diffMinutes < 1) {
                cursor.delete();
            }
            cursor.continue();
        };
    }

    #parseOnliveURL(_url) {
        if (isValidHttpUrl(_url)) {
            const url = new URL(_url);
            return url.hostname.includes('onlive.site');
        }
        return false;
    }
}

const CalendarNotificationInstance = Object.freeze(new CalendarNotificationService());

export default CalendarNotificationInstance;
