import TermsENv3 from 'common/features/terms/TermsENv3';
import TermsESv3 from 'common/features/terms/TermsESv3';
import Footer from 'components/Footer';
import useStyles from './styles/useStylesTermsConditions.style';

const TermsConditions = () => {
    const linkUrl = new URL(window.location);
    const lang = linkUrl.searchParams.get('lang') || 'en';
    const origin = linkUrl.origin;
    const { classes } = useStyles();

    const esLink = `${origin}/terms-conditions?lang=es`;
    const enLink = `${origin}/terms-conditions?lang=en`;
    const esLinkName = lang === 'es' ? 'Versión en Español' : 'Spanish version';
    const enLinkName = lang === 'es' ? 'Versión en Inglés' : 'English version';

    return (
        <>
            <div className={classes.root}>
                <div className={classes.changeLang}>
                    {lang !== 'es' ? (
                        <a href={esLink}>{esLinkName}</a>
                    ) : (
                        <span className={classes.disabledText}>{esLinkName}</span>
                    )}
                    {lang !== 'en' ? (
                        <a href={enLink}>{enLinkName}</a>
                    ) : (
                        <span className={classes.disabledText}>{enLinkName}</span>
                    )}
                </div>
                <div>{lang === 'es' ? <TermsESv3 /> : <TermsENv3 />}</div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default TermsConditions;
