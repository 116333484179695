import { Add } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import moment from 'moment-timezone';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStylesOCalendar from '../../../ocalendar/styles/useStylesOCalendar.styles';
import HoursRange from './HoursRange';

const DateOverride = (props) => {
    const {
        openDialog,
        onCloseDialog,
        onAddDateOverride,
        timeZone
    } = props;

    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesOCalendar();
    const classes = { ...classes1, ...classes2 };

    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState();
    const [hours, setHours] = useState([]);

    const handleDeleteHourRange = (indexHour) => {
        setHours([...hours.slice(0, indexHour), ...hours.slice(indexHour + 1)]);
    };

    const handleAddHourRange = () => {
        setHours([
            ...hours,
            {
                startTime: moment.tz({ hour: 9 }, timeZone).format('HH:mm'),
                endTime: moment.tz({ hour: 18 }, timeZone).format('HH:mm'),
            },
        ]);
    };

    const handleHourRangeChange = (indexHour, hoursRange) => {
        setHours([...hours.slice(0, indexHour), hoursRange, ...hours.slice(indexHour + 1)]);
    };

    const handleOk = () => {
        onAddDateOverride({
            date: selectedDate,
            hours: [...hours],
        });
    };

    const onChangeDate = (e) => {
        setSelectedDate(e.target.value);
    };

    useEffect(() => {
        setSelectedDate(moment().format('YYYY-MM-DD'));
        setHours([
            {
                startTime: moment.tz({ hour: 9 }, timeZone).format('HH:mm'),
                endTime: moment.tz({ hour: 18 }, timeZone).format('HH:mm'),
            },
        ]);
    }, [openDialog, timeZone]);

    return (
        <Dialog
            open={openDialog}
            onClose={onCloseDialog}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="date-override-dialog"
            className={classes.customDialogWidth}
        >
            <DialogTitle id="date-override-dialog-title">{t('calendar.addADateOverride')}</DialogTitle>
            <DialogContent className={classes.dateOverrideDialogContent}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <CustomTextValidator
                            name="firstDate"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={selectedDate}
                            onChange={onChangeDate}
                            type="date"
                            InputProps={{
                                inputProps: {
                                    shrink: true,
                                    min: moment().format('YYYY-MM-DD'),
                                },
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                        />
                    </Grid>
                    <Fragment>
                        <Grid item md={8}>
                            <div className={classes.dateOverrideHourContainer}>
                                {hours.map((h, indexHour) => {
                                    return (
                                        <HoursRange
                                            key={indexHour}
                                            initialHourBegin={h.startTime}
                                            initialHourEnd={h.endTime}
                                            onDelete={() => handleDeleteHourRange(indexHour)}
                                            onChange={(hoursRange) => handleHourRangeChange(indexHour, hoursRange)}
                                            timeZone={timeZone}
                                        />
                                    );
                                })}
                            </div>
                        </Grid>
                        <Grid item md={1}>
                            <IconButton
                                aria-label="add"
                                className={classes.dateOverridePlusBtn}
                                onClick={handleAddHourRange}
                                size="large"
                            >
                                <Add fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Fragment>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCloseDialog} color="primary">
                    {t('common.term.Cancel')}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t('common.term.OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DateOverride;
