import { makeStyles } from 'tss-react/mui';

const useStylesUserInfo = makeStyles()((theme) => {
    return {
        main: {
            maxWidth: '100%',
            flex: '0 0 100%',
            display: 'flex',
            flexDirection: 'column',
        },
        notificationsPopupBackground: {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: '1000000',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        notificationContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                fontSize: '30px',
                color: '#fff',
            },
            '& h3': {
                color: '#fff',
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '20px',
                marginBottom: '0px',
            },
            '& p': {
                marginTop: '8px',
                color: '#fff',
                fontSize: '14px',
            },
        },
        notificationsPopupHidden: {
            display: 'none',
        },
        logo: {
            height: '65px',
            // backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
            '& img': {
                maxWidth: '80%',
                height: 'auto',
                maxHeight: '60px',
            },
        },
        menu: {
            padding: '15px 8px',
        },
        userProfile: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(3),
            '& svg': {
                color: '#000 !important',
            },
            '& svg:nth-of-type(3)': {
                marginRight: 0,
                marginLeft: 'auto',
                cursor: 'pointer',
                fontSize: '1.4rem',
            },
        },

        activeUserProfile: {
            backgroundColor: theme.palette.primary.main,
        },

        contrastBlack: {
            '& > a, & svg, & span': {
                color: '#000000 !important',
            },
        },

        contrastWhite: {
            '& > a, & svg, & span': {
                color: '#FFF !important',
            },
        },

        adminIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                padding: '10px',
                fontSize: '30px',
                borderRadius: '30px',
                backgroundColor: '#eeeff0',
            },
        },

        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            fontSize: theme.spacing(1.25),
            textTransform: 'uppercase',
            fontWeight: 500,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(6),
            cursor: 'pointer',
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(1.5),
                paddingRight: theme.spacing(8),
            },
        },
        userRole: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& svg': {
                fontSize: '11px',
            },
        },
        availableControl: {
            color: '#000',
            fontSize: theme.spacing(1.25),
            textTransform: 'uppercase',
            fontWeight: 500,
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(1.5),
            },
        },
    };
});

export default useStylesUserInfo;
