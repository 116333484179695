import { withIcon } from './whitIcon';

const ICallIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27.862 20.445">
        <path
            id="computer_FILL0_wght300_GRAD0_opsz20"
            d="M2.181,24.049a.868.868,0,1,1,0-1.737H28.305a.868.868,0,1,1,0,1.737Zm3.04-3.34a2.111,2.111,0,0,1-2.137-2.137V5.775A2.123,2.123,0,0,1,3.7,4.239,2.04,2.04,0,0,1,5.221,3.6H25.265a2.04,2.04,0,0,1,1.52.635A2.123,2.123,0,0,1,27.4,5.775v12.8a2.111,2.111,0,0,1-2.137,2.137Zm0-1.737H25.265a.411.411,0,0,0,.268-.117.365.365,0,0,0,.133-.284V5.775a.414.414,0,0,0-.133-.3.389.389,0,0,0-.268-.133H5.221a.389.389,0,0,0-.268.133.414.414,0,0,0-.133.3v12.8a.365.365,0,0,0,.133.284A.411.411,0,0,0,5.221,18.972Zm-.4,0v0Z"
            transform="translate(-1.312 -3.604)"
        />
    </svg>
);

export default withIcon(ICallIcon);
