import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DateValidationType = {
    INCLUDED: 'included',
    EXCLUDED: 'excluded',
    UNSELECTED: 'unselected',
};

export function useIncludeDateValidation() {
    const { t } = useTranslation();

    const [error, setError] = useState(null);

    const validationType = useMemo(
        () => ({
            INCLUDED: { name: DateValidationType.INCLUDED, msgError: t('dateValidation.message.includeError') },
            EXCLUDED: { name: DateValidationType.EXCLUDED, msgError: t('dateValidation.message.excludeError') },
            UNSELECTED: { name: DateValidationType.UNSELECTED, msgError: t('dateValidation.message.selectedError') },
        }),
        [t],
    );

    const isValidDateRange = ({ ranges, date, idx, type, customMsg } = {}) => {
        if (!date?.start && !date?.end) {
            setError(null);
            return false;
        }

        if (date?.start > date?.end) {
            setError({ msg: t('dateValidation.message.startEndError'), idx });
            return false;
        }

        const rangeTemp = idx === -1 ? ranges : [...ranges.slice(0, idx), ...ranges.slice(idx + 1)];
        const included = checkIncludeDateRange(rangeTemp, date);
        const msg = customMsg || type.msgError;

        if (included && (type.name === DateValidationType.EXCLUDED || type.name === DateValidationType.UNSELECTED)) {
            setError({ msg, idx });
            return false;
        }

        if (!included && type.name === DateValidationType.INCLUDED) {
            setError({ msg, idx });
            return false;
        }

        setError(null);
        return true;
    };

    const checkIncludeDateRange = (ranges, date) => {
        const start = new Date(date?.start);
        const end = new Date(date?.end);
        const included = ranges.some((range) => {
            const startDate = new Date(range.startDate);
            const endDate = new Date(range.endDate);
            return (
                (start >= startDate && start <= endDate) ||
                (end >= startDate && end <= endDate) ||
                (start <= startDate && end >= endDate)
            );
        });

        return included;
    };

    const parseTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const isValidHourRange = ({ ranges, date, idx, dayIndex, type, customMsg } = {}) => {
        const start = parseTime(date.start);
        const end = parseTime(date.end);

        if (!start && !end) {
            setError(null);
            return false;
        }

        if (start > end || !end) {
            setError({ msg: t('dateValidation.message.startEndError'), idx, dayIndex });
            return false;
        }

        const rangeTemp = idx === -1 ? ranges : [...ranges.slice(0, idx), ...ranges.slice(idx + 1)];
        const included = checkIncludeHourRange(rangeTemp, { start, end });
        const msg = customMsg || type.msgError;

        if (included && (type.name === DateValidationType.EXCLUDED || type.name === DateValidationType.UNSELECTED)) {
            setError({ msg, idx, dayIndex });
            return false;
        }

        if (!included && type.name === DateValidationType.INCLUDED) {
            setError({ msg, idx, dayIndex });
            return false;
        }

        setError(null);
        return true;
    };

    const checkIncludeHourRange = (ranges, date) => {
        const { start, end } = date;

        const included = ranges.some((range) => {
            const startDate = parseTime(range.startDate);
            const endDate = parseTime(range.endDate);
            return (
                (start >= startDate && start <= endDate) ||
                (end >= startDate && end <= endDate) ||
                (start <= startDate && end >= endDate)
            );
        });

        return included;
    };

    return { isValidDateRange, isValidHourRange, validationType, error, setError };
}
