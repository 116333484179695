import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeCustomDialogAction, openCustomDialog } from 'redux/slices/customDialogSlice';
import 'suneditor/dist/css/suneditor.min.css';
import useStylesSettings from '../../settings/styles/useStylesSettings.styles';
import SecurityCardItem from '../SecurityCardItem';
import SecurityStatusItem from '../SecurityStatusItem';
import VerificationCodesForm from './VerificationCodesForm';

const VerificationCodes = ({ postMethod, factor, onDelete, confirmMethod }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { classes } = useStylesSettings();

    const [pending, setPending] = useState(false);

    const onConfigure = () => {
        setPending(true);
        postMethod({
            method: 'alternative_codes',
            callback: (data) => {
                setPending(false);
                dispatch(
                    openCustomDialog(
                        <VerificationCodesForm data={data} onReload={onConfigure} confirmMethod={sendConfirmMethod} />,
                        {
                            showCloseIcon: false,
                        },
                    ),
                );
            },
        });
    };

    const sendConfirmMethod = () => {
        confirmMethod({
            method: 'alternative_codes',
            callback: () => {
                setPending(false);
                dispatch(closeCustomDialogAction());
            },
        });
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <PinOutlinedIcon />
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('profile.security.verificationCodes')}
                    </Typography>

                    <SecurityStatusItem factor={factor} text={t('profile.security.codesConfirmedWarning')} />
                </Stack>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {t('profile.security.codesMessage')}
                    </Typography>

                    {factor?.configured ? (
                        <SecurityCardItem
                            icon={<PinOutlinedIcon />}
                            title={t('profile.security.codesCardTitle')}
                            content={
                                <>
                                    {t('profile.security.codesRemaining')}&nbsp;
                                    <strong>{factor?.remainingCodes}</strong>
                                </>
                            }
                            confirmed={factor?.confirmed}
                            warningText={t('profile.security.codesConfirmedWarning')}
                            onConfigure={onConfigure}
                            onDelete={() => onDelete({ method: 'alternative_codes' })}
                        />
                    ) : (
                        <Stack direction="row" justifyContent="flex-start">
                            <ActionButton
                                disabled={pending}
                                color="primary"
                                type="submit"
                                autoFocus
                                fitWidth={true}
                                spinner={pending}
                                onClick={onConfigure}
                            >
                                {t('profile.security.configureCodes')}
                            </ActionButton>
                        </Stack>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default VerificationCodes;
