import { isArray } from 'lodash';

export const getDynamicFields = (formConfig, objectData) => {
    const dynamicFields = (isArray(formConfig?.fields) ? formConfig?.fields : [])?.reduce((obj, field) => {
        const value = objectData?.[field.name];

        if (field.type === 'form' && field.form) {
            const nestedFields = getDynamicFields(field.form, objectData?.[field.name] || {});
            return {
                ...obj,
                ...nestedFields,
            };
        } else if (value !== undefined) {
            return {
                ...obj,
                [field.name]: value,
            };
        }

        return obj;
    }, {});

    return dynamicFields;
};

export const getNestedData = (formConfig, objectData) => {
    const nestedData = (isArray(formConfig?.fields) ? formConfig?.fields : [])?.reduce((obj, field) => {
        if (field.type === 'form' && field.form) {
            const nestedFields = getNestedData(field.form, objectData);
            return {
                ...obj,
                [field.name]: nestedFields,
            };
        } else {
            const value = field.type === 'float' ? parseFloat(objectData[field.name]) : objectData[field.name];
            return {
                ...obj,
                [field.name]: value,
            };
        }
    }, {});
    return nestedData;
};
