import { FormControl, Stack } from '@mui/material';
import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import useFlexBoxStyles from 'common/flexBox';
import LayoutSubForm from 'common/layouts/LayoutSubForm';
import { useFormFields } from 'libs/hooksLib';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import RenderFormFields from '../renderForms/RenderFormFields';
import { getDynamicFields } from '../renderForms/utils';

const ExtraFieldsEditor = (props) => {
    const { close, onConfirm, sectionName, value, formConfig } = props;
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { t } = useTranslation();
    const [inputError, setInputError] = useState({ extraFields: null });
    const [extraFields, setExtraFields] = useState(value);

    const initialForm = useMemo(() => {
        const dynamic = formConfig ? getDynamicFields(formConfig, value) : null;
        return { ...dynamic };
    }, [value, formConfig]);

    const [fields, handleFieldChange] = useFormFields(initialForm);

    const done = (event) => {
        event.preventDefault();
        onConfirm(formConfig ? fields : extraFields);
        close();
    };

    const handleValueChangeCode = (value, name, errors) => {
        const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
        const newErrors = codeHaveErrors ? errors : null;
        setInputError({ ...inputError, [name]: newErrors });
        setExtraFields(value);
    };

    return (
        <LayoutSubForm sectionName={sectionName} close={close} done={done} showCloseButton={false}>
            <ValidatorForm onSubmit={() => {}} onError={() => {}} className={cx(flexBox.overflowXHidden, flexBox.h100)}>
                <Stack
                    direction="column"
                    className={cx(flexBox.overflowXHidden, flexBox.h100, flexBox.scrollbarCustom)}
                >
                    {formConfig ? (
                        <RenderFormFields
                            formConfig={formConfig}
                            fields={fields}
                            handleFieldChange={handleFieldChange}
                        />
                    ) : (
                        <FormControl style={{ minHeight: '5em' }}>
                            <JsonCodeInputMonacoEditor
                                height={'500px'}
                                width={'100%'}
                                name="extraFields"
                                value={extraFields}
                                onChange={(value, errors) => handleValueChangeCode(value, 'extraFields', errors)}
                            />
                            <SimpleCodeErrorView
                                id={'error-extraFields'}
                                mainErrorMessage={t('JsonError')}
                                errorList={inputError['extraFields']}
                            />
                        </FormControl>
                    )}
                </Stack>
            </ValidatorForm>
        </LayoutSubForm>
    );
};

export default ExtraFieldsEditor;
