import { FormGroup, FormLabel, Grid, MenuItem } from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateTemplateAvailabilityTypeAction } from 'redux/slices/ocalendarSlice';

const AvailabilityTypeSelect = ({ template, availabilityTypes, predefinedType }) => {
    const { classes } = useStylesForm();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [availabilityType, setAvailabilityType] = useState(predefinedType || '');

    const handleChange = (e) => {
        const { value } = e.target;
        setAvailabilityType(value);
        dispatch(
            updateTemplateAvailabilityTypeAction(
                template.id,
                { availabilityType: value },
                () => {
                    enqueueSnackbar(t('common.term.saveSuccess'), {
                        variant: 'success',
                    });
                },
                (error) => {
                    // TODO: improve error handling
                    if (
                        error?.response?.data?.description?.includes('duplicate key value violates unique constraint')
                    ) {
                        enqueueSnackbar('Sorry, there are another template with same availability type', {
                            variant: 'error',
                        });
                    } else {
                        enqueueSnackbar(t('app.common.error'), {
                            variant: 'error',
                        });
                    }
                },
            ),
        );
    };

    const menu = [
        <MenuItem key="default" value="default">
            {t('app.common.default')}
        </MenuItem>,
        ...availabilityTypes.map((type) => (
            <MenuItem key={type} value={type}>
                {t(type)}
            </MenuItem>
        )),
    ];

    return (
        <Grid container spacing={1}>
            <Grid item md={6}>
                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.organization.tabs.availability.availabilityTypes')}</FormLabel>
                    <RoundedSelect
                        id="template-availability-type"
                        value={availabilityType}
                        style={{ width: '50%', height: '35px', fontSize: '11px' }}
                        fullWidth
                        iconStyle={{
                            fontSize: '13px',
                            padding: '0 12px',
                            color: 'darkslategrey',
                            cursor: 'pointer',
                        }}
                        onChange={handleChange}
                    >
                        {menu}
                    </RoundedSelect>
                </FormGroup>
            </Grid>
        </Grid>
    );
};

export default AvailabilityTypeSelect;
