import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const unitSlice = createSlice({
    name: 'unit',
    initialState: {
        pending: false,
        savePending: false,
        units: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 1,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addUnit: (state, action) => {
            state.savePending = false;
            state.units = [action.payload, ...state.units];
        },
        editUnit: (state, action) => {
            state.savePending = false;
            const index = state.units.findIndex((unit) => unit.id === action.payload.id);
            if (index !== -1) {
                state.units = [...state.units.slice(0, index), action.payload, ...state.units.slice(index + 1)];
            }
        },
        deleteUnit: (state, action) => {
            state.deletePending = false;
            const index = state.units.findIndex((unit) => unit.id === action.payload.id);
            if (index !== -1) {
                state.units = [...state.units.slice(0, index), ...state.units.slice(index + 1)];
            }
        },
        setUnits: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.units = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setUnits,
    stopDelete,
    setError,
    addUnit,
    editUnit,
    deleteUnit,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
} = unitSlice.actions;

export const getUnits = (state) => state.unit.units;
export const getUnitsPending = (state) => state.unit.pending;
export const getUnitsSavePending = (state) => state.unit.savePending;
export const getDeletePending = (state) => state.unit.deletePending;
export const getDeleteId = (state) => state.unit.deleteId;
export const getUnitsError = (state) => state.unit.error;
export const getUnitsTotal = (state) => state.unit.total;
export const getUnitsCurrentPage = (state) => state.unit.currentPage;
export const getUnitsTotalPages = (state) => state.unit.totalPages;

export const fetchUnitsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('product-units' + query)
                .then((response) => {
                    dispatch(setUnits(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addUnitAction =
    ({ unitDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('product-units', unitDto)
                .then((response) => {
                    dispatch(addUnit(response?.data));
                    onSuccess?.(response?.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stopSave());
            onFailure?.(e);
            return console.error(e.message);
        }
    };

export const editUnitAction =
    ({ id, unitDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('product-units/' + id, unitDto)
                .then((response) => {
                    dispatch(editUnit(response.data));
                    if (onSuccess) {
                        onSuccess(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteUnitAction = (id, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startDelete(id));
        api.delete('product-units/' + id)
            .then((response) => {
                dispatch(deleteUnit(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        dispatch(stopDelete());
        return console.error('error', e);
    }
};

export default unitSlice.reducer;
