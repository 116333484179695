import Item from './Item';
import useStylesTerms from './styles/useStylesTerms.style';

const TermsENv3 = () => {
    const { classes, cx } = useStylesTerms();
    return (
        <div className={cx(classes.root)}>
            <h1 className={cx(classes.title)}>TERMS OF SERVICE ONLIVE PLATFORM</h1>

            <ol>
                <Item title={'Preliminary Information'}>
                    <p>
                        This service agreement (hereinafter, the <b>“Contract”</b> or
                        <b>“SERVICE AGREEMENT”</b>) regulates access and use of the services offered by Onlive Site,
                        S.L. (hereinafter, <b>“ONLIVE”</b>) and establishes the Legal Terms and Conditions which shall
                        be applicable to the use of the services and sites (as defined below).
                    </p>
                </Item>

                <Item title={'Definitions'}>
                    <p>
                        <b>“CUSTOMER”</b> shall mean the customer.
                    </p>
                    <p>
                        <b>“CUSTOMER Data”</b> shall mean any information, data or other content provided by the
                        CUSTOMER related to the use of the Service, including but not limited to any data and
                        information provided to the Service through CUSTOMER Bookshelves.
                    </p>
                    <p>
                        <b>“CUSTOMER Bookshelves”</b> shall mean the CUSTOMER bookshelves provided by ONLIVE and
                        installed in the CUSTOMER’s applications for the purpose of collecting CUSTOMER Data and sending
                        such data to the ONLIVE platform.
                    </p>
                    <p>
                        <b>“Documentation”</b> shall mean the implementation manuals or guides; help information and
                        other User documentation related to the Service provided by ONLIVE to the CUSTOMER in electronic
                        or any other format.
                    </p>
                    <p>
                        <b>“Confidential Information”</b> shall mean all technical, legal, economic, commercial,
                        organizational or any other type of information corresponding to either of the Parties which due
                        to the nature thereof may be considered confidential irrespective of the medium on which it is
                        found or resources used to disclose it, as well as any analysis, compilation, study, summary or
                        extract that either of the Parties or both, jointly, may have prepared based on
                        information/documentation initially disclosed by either one of them.
                    </p>
                    <p>
                        <b>“ONLIVE Platform”</b> shall mean the platform owned by ONLIVE that helps brands boost their
                        online sales and customer loyalty through the creation of fun, interactive real-time shopping
                        shows. With ONLIVE, any retailer can easily create their own audiovisual channel to sell, launch
                        and demonstrate their products in real time. Brands can put a face and voice to their products
                        while interacting with their customers, as the buying experience and customer service become
                        more realistic, interesting and innovative.
                    </p>
                    <p>
                        <b>“ONLIVE Property”</b> shall mean all ideas, concepts, inventions, systems, platforms,
                        computing programs, interfaces, tools, utilities, templates, forms, techniques, methods,
                        processes, algorithms, technical knowledge, commercial secrets and other technologies and
                        information acquired, created, developed and authorized by ONLIVE before or outside the scope of
                        this Contract and any enhancement, modification, expansion or other work deriving from them and
                        all intellectual property rights to them including but not limited to the Service, CUSTOMER
                        Bookshelves, Documentation, Report Templates and Aggregate Data. ONLIVE property excludes
                        CUSTOMER Data and Results.
                    </p>
                    <p>
                        <b>“Active Monthly Users”</b> shall mean each one of the individual installations of the
                        CUSTOMER application that may transmit CUSTOMER Data during the contract period.
                    </p>
                    <p>
                        <b>“Register”</b> shall mean a register in reference to this Contract and the specific services
                        agreed and signed mutually between the Parties.
                    </p>
                    <p>
                        <b>“Results”</b> shall mean the work resulting from the Services performed by ONLIVE for the
                        CUSTOMER through the Service, including any recorded show, based on whatever may be required in
                        the CUSTOMER Data. The Results expressly exclude any ONLIVE Property.
                    </p>
                    <p>
                        <b>“Service”</b> shall mean the ONLIVE service provided to the CUSTOMER as identified in the
                        Register.
                    </p>
                    <p>
                        <b>“Sites”</b> shall mean websites or other sites managed by ONLIVE which are accessible by the
                        CUSTOMER under the provisions of this Contract.
                    </p>
                    <p>
                        <b>“User”</b> shall mean a natural person authorized by the CUSTOMER to use the Service for whom
                        the CUSTOMER has acquired a subscription (or, in the case of Services performed by ONLIVE for
                        free, for whom the Service is performed), and to whom the CUSTOMER (or, as applicable, ONLIVE at
                        the request of the CUSTOMER) has provided a username and password (for Services requiring
                        authentication). For guidance only, Users may include the CUSTOMER’s employees, consultants,
                        suppliers or agents, as well as third parties with whom the CUSTOMER maintains a commercial
                        relationship.
                    </p>
                </Item>

                <Item title={'Acceptance of the Contract'}>
                    <p>
                        By accepting the CONTRACT, the customer (hereinafter, the <b>“CUSTOMER”</b>) undertakes to act
                        at all times in compliance with the provisions set forth in the clauses of this legal text which
                        define the rights and obligations pertaining to ONLIVE and the CUSTOMER under the contractual
                        relationship deriving from the use of the services accessible via the website{' '}
                        <a href="https://onlive.site/">www.onlive.site</a> (hereinafter, <b>“WEBSITE”</b> or
                        <b>“ONLIVE Platform”</b>).
                    </p>
                    <p>
                        Access, registration, browsing, use, hosting and/or downloading of content and/or use of the
                        services on the WEBSITE by minors (under 18 years of age) is prohibited. By accepting this
                        Contract, the CUSTOMER declares they are 18 years old or older and/or have legal capacity to
                        contract and use the services present at the WEBSITE.
                    </p>
                </Item>

                <Item title={'Service Conditions'}>
                    <ol>
                        <Item title={'License'}>
                            <p>
                                ONLIVE grants the CUSTOMER a limited, non-exclusive, non-transferable license (with no
                                sublicensing rights) for the term of the Service specified in the Register for (a)
                                remote access and use of the ONLIVE Platform as described in the Register, (b)
                                downloading, installing and using the CUSTOMER Bookshelves in relation to the use of the
                                Service by the CUSTOMER.
                            </p>
                            <p>
                                The Service will be made available to the CUSTOMER only as hosted by or in
                                representation of ONLIVE and nothing in the Contract may be understood as any granting
                                of any right to the CUSTOMER to receive any copies whatsoever of the Service or software
                                (other than the Customer Bookshelves).
                            </p>
                            <p>
                                Access and use of the Service by the CUSTOMER shall be subject to compliance with the
                                conditions established in the Register and Documentation (such as, for example, any
                                requirement relating to the format of data, number of Users or prohibited uses).
                            </p>
                        </Item>
                        <Item title={'Access to the Service and Availability'}>
                            <ol>
                                <Item title={'CUSTOMER Systems'}>
                                    <p>
                                        The CUSTOMER shall be liable for (i) the equipment components, subscriptions and
                                        credentials necessary so ONLIVE may receive the CUSTOMER Data and (ii) the
                                        servers, devices, storage, programs (various CUSTOMER Bookshelves), databases,
                                        networks and communications equipment and auxiliary services necessary to
                                        establish a connection, access or in any way use the Services at their
                                        facilities (collectively, <b>“CUSTOMER Systems”</b>). The CUSTOMER hereby
                                        guarantees that all CUSTOMER Systems are compatible with the Service and comply
                                        with all the configurations and specifications described in the Documentation.
                                    </p>
                                </Item>
                                <Item title={'Access to the Service'}>
                                    <p>
                                        As part of the implementation process, the CUSTOMER will create a primary
                                        username and password which shall be used to set up the CUSTOMER account. The
                                        CUSTOMER may use the primary username and password to create subaccounts for its
                                        Users (each one with their own username and password).
                                    </p>
                                    <p>
                                        The CUSTOMER shall be liable for all acts or omissions by anyone accessing the
                                        Service using the passwords created by the CUSTOMER. ONLIVE reserves the right
                                        to reject registration or to cancel accounts that violate the terms and
                                        conditions established in this Contract. Upon gaining knowledge of such, the
                                        CUSTOMER shall immediately notify ONLIVE of any unauthorized use of the CUSTOMER
                                        account or any other security failure or problem.
                                    </p>
                                    <p>
                                        From time to time, ONLIVE personnel may access the Service through the CUSTOMER
                                        account for Service maintenance and enhancements, including technical assistance
                                        for the CUSTOMER in relation to technical aspects or billing. The CUSTOMER
                                        hereby acknowledges knowledge thereof and, as is required, consent for any such
                                        access. Any such access shall be carried out using an ONLIVE user account and
                                        under no circumstance by accessing individual user accounts or under the
                                        identify of any CUSTOMER Users.
                                    </p>
                                </Item>
                            </ol>
                        </Item>
                        <Item title={'Service Availability'}>
                            <p>
                                ONLIVE will make all reasonable commercial efforts to keep the Service available for
                                sending and receiving data, subject to any outages deriving from maintenance, repairs
                                and updates. To the extent possible, ONLIVE will attempt to notify the CUSTOMER
                                electronically through the Service of any scheduled Service interruptions.
                            </p>
                            <p>
                                Notwithstanding the foregoing, ONLIVE will not be liable for any failures in the Service
                                or any other problems related to (a) CUSTOMER data or CUSTOMER Systems, or (b) outages
                                in telecommunications networks or Internet access providers, networks or servers, or
                                other equipment or services off the ONLIVE facilities or out of its control.
                            </p>
                        </Item>
                        <Item title={'Service Support'}>
                            <p>
                                ONLIVE will provide the CUSTOMER with email support for the use of the Service during
                                ONLIVE business hours. The CUSTOMER agrees that ONLIVE will not be liable for providing
                                support in relation to problems, errors or queries concerning the CUSTOMER Systems.
                            </p>
                        </Item>
                        <Item title={'Professional Services'}>
                            <p>
                                The CUSTOMER may periodically request and ONLIVE may agree to provide customization,
                                consulting, training or other professional services mutually agreed upon between the
                                Parties (<b>“Professional Services”</b>).
                            </p>
                            <p>
                                The terms and conditions of any such agreements for the provision of Professional
                                Services shall be established in a specific order document to be agreed upon by the
                                Parties with specific reference to this Contract (“Register”), each of which shall be
                                subject to the provisions of this Contract. Unless otherwise agreed by and between the
                                Parties and established in the corresponding Register, all intellectual property and
                                derivative rights, created by ONLIVE during the provision of the Professional Services
                                shall exclusively correspond to ONLIVE.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Free Demo'}>
                    <p>
                        If ONLIVE has offered the CUSTOMER a free demo, ONLIVE will offer one or more Services as a free
                        trial until (a) the end of the free trial period for which the CUSTOMER has registered and with
                        respect to the Services applicable, or (b) the start date of any subscription to Services
                        specifically arranged by the CUSTOMER, or (c) termination by ONLIVE at its sole discretion. The
                        free trial may be subject to additional terms and conditions as detailed on the website at any
                        given time. Any such additional terms and conditions will be considered included by reference in
                        this Contract and will be considered legally binding.
                    </p>
                </Item>

                <Item title={'Restrictions and Obligations'}>
                    <ol>
                        <Item title={'License Restrictions'}>
                            <p>
                                If ONLIVE has offered the CUSTOMER a free demo, ONLIVE will offer one or more Services
                                as a free trial until (a) the end of the free trial period for which the CUSTOMER has
                                registered and with respect to the Services applicable, or (b) the start date of any
                                subscription to Services specifically arranged by the CUSTOMER, or (c) termination by
                                ONLIVE at its sole discretion. The free trial may be subject to additional terms and
                                conditions as detailed on the website at any given time. Any such additional terms and
                                conditions will be considered included by reference in this Contract and will be
                                considered legally binding.
                            </p>
                        </Item>
                        <Item title={'Unauthorized Uses of the Service'}>
                            <p>
                                The CUSTOMER may not directly or indirectly: (i) exceed the active number of Active
                                Monthly Users; (ii) use unique values or a continuous set of values as inputs for event
                                attributes; (iii) interfere or attempt to interfere with the adequate functioning of the
                                Service or any activity carried out under the Service; (iv) evade the privacy options or
                                measures ONLIVE may use to prevent or restrict access to the Service (or other accounts,
                                computer systems or networks connected to the Service); (v) use email lists, any form of
                                auto-response or “spam” in the Service; or (vi) use manual or automatic software,
                                devices or other processes to track any page of the Sites.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Register, Price and Payment'}>
                    <ol>
                        <Item title={'Registers'}>
                            <p>
                                The parties may agree to one or more Registers in virtue of this Contract. Each Register
                                must detail the Service to be provided, the price of the Service, the term during which
                                the Service is to be provided, the billing terms, and any other terms agreed upon by and
                                between the parties. ONLIVE shall be exclusively liable for providing the Service
                                identified in a Register for the period specified in said Register.
                            </p>
                            <p>
                                The Register will be agreed upon user registration as they may choose which “Addons”
                                they wish to subscribe to and for how long they wish to do so, with the option to choose
                                a monthly, quarterly or annual license. Likewise, on limited occasions, a Purchase Order
                                may be provided when the user requests a physical signature. Any such purchase order
                                shall indicate the characteristics of the service, the form of service provision, the
                                duration and other stipulations related to the Contract.
                            </p>
                        </Item>
                        <Item title={'Price of the Service'}>
                            <p>
                                The CUSTOMER must pay the price corresponding to the use of the Service (
                                <b>“Price of the Service”</b>) in the quantity and in accordance with the billing
                                schedule established in the Register. Unless otherwise stated in the Register, ONLIVE
                                will bill the Price of the Service annually.
                            </p>
                        </Item>
                        <Item title={'Price of the Professional Services'}>
                            <p>
                                The CUSTOMER must pay the price of the Professional Services pursuant to the rates and
                                billing schedule established in the Register.
                            </p>
                        </Item>
                        <Item title={'Payment Terms'}>
                            <p>
                                Payments shall be made in euros to the ONLIVE account in full without compensation,
                                reconvention or deductions in accordance with the terms and conditions established in
                                the Register. In the event of a payment default, ONLIVE may demand delay interest at
                                1.5% per month or the maximum sum permitted by law, whichever is lower.
                            </p>
                            <p>
                                On the other hand, the CUSTOMER undertakes to reimburse ONLIVE all expenses (including
                                attorney fees) incurred by ONLIVE to collect late payments. ONLIVE may also, at its full
                                discretion, suspend CUSTOMER access to the Service or terminate this Contract if the
                                CUSTOMER is not current on the payment of the sums owed to ONLIVE.
                            </p>
                        </Item>
                        <Item title={'Taxes'}>
                            <p>
                                The CUSTOMER shall be liable for paying the Taxes applicable and shall pay ONLIVE for
                                the Services performed without any type of discount in the corresponding sums. If in
                                accordance with applicable regulations, ONLIVE is required to collect or pay Taxes, they
                                will be billed to the CUSTOMER except when ONLIVE is provided with a valid tax exemption
                                certificate issued by the pertinent tax authority.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Confidentiality'}>
                    <ol>
                        <Item title={'Scope'}>
                            <p>
                                CUSTOMER Confidential Information shall particularly mean (a) the Service, <b>ONLIVE</b>{' '}
                                Property and information on the prices will be considered <b>ONLIVE</b> Confidential
                                Information, and (b) CUSTOMER Data.
                            </p>
                            <p>
                                Confidential Information expressly excludes any information when the recipient may prove
                                that said information (a) was known by said party prior to receiving it from the
                                disclosing party without restrictions, (b) was provided legitimately and without
                                restrictions by a third party who has not breached any obligation with the disclosing
                                party, (c) is available to the general public without any breach of this Contract or (d)
                                was developed independently by the recipient without referring to the Confidential
                                Information of the disclosing party and without using it.
                            </p>
                        </Item>
                        <Item title={'Confidentiality'}>
                            <p>
                                With respect to Confidential Information, both Parties undertake to (i) keep it secret,
                                where this obligation includes any disclosure of information not only to third parties
                                but also to any unauthorized person in virtue of this Contract; (ii) take all necessary
                                measures intended to prevent such information from being known by third parties not
                                authorized by the owner thereof.
                            </p>
                            <p>
                                To this end, each of the Parties shall protect the other party’s Confidential
                                Information using the same protection resources used to protect its own Confidential
                                Information and never less-performing measures than can be reasonably expected in view
                                of the nature of the information and state of technology; (iii) not use the Confidential
                                Information directly or indirectly for any purpose other than the provision of the
                                Services; (iv) restrict access to the Confidential Information to only the employees and
                                subcontracted employees who must know it in order to perform the Contract; (v) not
                                reproduce or copy the Confidential Information unless essential for compliance with the
                                provisions of this Contract or prior written consent is secured from the party to whom
                                it belongs.
                            </p>
                            <p>
                                None of the provisions of this Contract shall prevent either of the Parties from{' '}
                                <b>disclosing</b> this Contract or any Confidential Information owned by the other Party
                                as necessary in virtue of a court order or any legal, regulatory or similar{' '}
                                <b>requirement</b>; whenever, prior to any such <b>disclosure</b>, the recipient party
                                makes reasonable efforts to (a) quickly notify the <b>disclosing</b> party in writing of
                                such disclosure requirement and (b) cooperate with the <b>disclosing</b> party to
                                protect or minimize such disclosure or obtain a protection order.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Intellectual Property'}>
                    <ol>
                        <Item title={'CUSTOMER Results and Data'}>
                            <p>
                                The CUSTOMER shall be the owner of all rights, titles, interests (including intellectual
                                property and other property rights) associated with the Results (including any customer
                                show recorded through the ONLIVE Platform), CUSTOMER Data and CUSTOMER Systems.
                            </p>
                            <p>
                                The CUSTOMER grants ONLIVE a non-exclusive right free of cost and with a license to
                                access, use, copy, process and store CUSTOMER Data for the sole purpose of providing the
                                Service. The CUSTOMER hereby acknowledges and agrees that the Service depends on the
                                availability of the CUSTOMER Data. The CUSTOMER shall be the sole party liable for the
                                CUSTOMER Data collected on Users during the use of the Services by the CUSTOMER,
                                including the accuracy and integrity of such information. Unless otherwise established
                                on the Register, ONLIVE shall have no obligation whatsoever to store CUSTOMER Data or
                                Results. Except for the rights and limited licenses granted herein, no other licenses
                                are granted, none other is permitted and the Customer shall retain all rights, titles
                                and interests (including all intellectual property and property rights) to the Results,
                                CUSTOMER Data and CUSTOMER Systems.
                            </p>
                            <p>
                                Moreover, the CUSTOMER hereby grants ONLIVE a non-exclusive right and a non-exclusive
                                license free of rights to access, use, copy, process and store the Results, including
                                CUSTOMER shows recorded through the ONLIVE Platform which may be stored, edited and
                                distributed through the ONLIVE Platform and ONLIVE social media accounts unless
                                otherwise set forth in the Register.
                            </p>
                        </Item>
                        <Item title={'Service Feedback'}>
                            <p>
                                The CUSTOMER may, at its sole discretion, propose changes, modifications or improvements
                                to the Service via <a href="mailto:hello@onlive.site">hello@onlive.site</a>{' '}
                                (“Feedback”). Any Feedback generated shall be the exclusive property of ONLIVE
                                (including any intellectual property rights that may derive) and shall be considered
                                ONLIVE Confidential Information. The CUSTOMER undertakes to engage in any actions
                                necessary to formalize such ownership.
                            </p>
                        </Item>
                        <Item title={'ONLIVE Property'}>
                            <p>
                                The CUSTOMER hereby declares knowing and agreeing that the ONLIVE Platform, including
                                ONLIVE Software and any other associated software, hardware and documentation has been
                                created by ONLIVE, which shall maintain all intellectual property, industrial property
                                or any other rights to it which may not be subject of any later modification, copying,
                                alteration, reproduction, adaptation or translation by the CUSTOMER.
                            </p>
                            <p>
                                The structure, characteristics, codes, work methods, information systems, development
                                tools, know-how, methodologies, processes, technologies or algorithms used for the
                                ONLIVE Platform are the property of ONLIVE or its suppliers, in the latter case, they
                                are subject of a license or assignment by said suppliers, and are protected by Spanish
                                or international intellectual and industrial property regulations. Therefore, they may
                                not be subject of any subsequent modification, copying, alteration, reproduction,
                                adaptation or translation by the CUSTOMER.
                            </p>
                            <p>
                                Access by the CUSTOMER to the ONLIVE Platform does not in any case imply the assignment
                                of their ownership or the granting of any right of use to the CUSTOMER other than as set
                                forth in this Contract or in the Registers.
                            </p>
                            <p>
                                As a result, any use by the CUSTOMER of the ONLIVE Platform or the Associated Materials
                                without ONLIVE authorization, including the exploitation, reproduction, dissemination,
                                transformation, distribution, transmission by any means, subsequent publication,
                                exhibition, public disclosure or full or partial representation thereof is absolutely
                                prohibited. Engagement in any of these practices, therefore, shall be considered a
                                violation of ONLIVE intellectual and industrial property rights and thus sanctionable
                                under the laws in effect.
                            </p>
                        </Item>
                        <Item title={'Aggregate Data'}>
                            <p>
                                The CUSTOMER hereby declares knowing and agreeing that the ONLIVE Platform, including
                                ONLIVE Software and any other associated software, hardware and documentation has been
                                created by ONLIVE, which shall maintain all intellectual property, industrial property
                                or any other rights to it which may not be subject of any later modification, copying,
                                alteration, reproduction, adaptation or translation by the CUSTOMER.
                            </p>
                            <p>
                                The structure, characteristics, codes, work methods, information systems, development
                                tools, know-how, methodologies, processes, technologies or algorithms used for the
                                ONLIVE Platform are the property of ONLIVE or its suppliers, in the latter case, they
                                are subject of a license or assignment by said suppliers, and are protected by Spanish
                                or international intellectual and industrial property regulations. Therefore, they may
                                not be subject of any subsequent modification, copying, alteration, reproduction,
                                adaptation or translation by the CUSTOMER.
                            </p>
                            <p>
                                Access by the CUSTOMER to the ONLIVE Platform does not in any case imply the assignment
                                of their ownership or the granting of any right of use to the CUSTOMER other than as set
                                forth in this Contract or in the Registers.
                            </p>
                            <p>
                                As a result, any use by the CUSTOMER of the ONLIVE Platform or the Associated Materials
                                without ONLIVE authorization, including the exploitation, reproduction, dissemination,
                                transformation, distribution, transmission by any means, subsequent publication,
                                exhibition, public disclosure or full or partial representation thereof is absolutely
                                prohibited. Engagement in any of these practices, therefore, shall be considered a
                                violation of ONLIVE intellectual and industrial property rights and thus sanctionable
                                under the laws in effect.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Liability'}>
                    <ol>
                        <Item title={'ONLIVE Indemnity for Breach'}>
                            <p>
                                Except as set forth below, ONLIVE undertakes to (a) defend the CUSTOMER against any
                                claim, complaint, action, proceedings or lawsuit (each of them, a “Complaint”) filed by
                                any third party insinuating that the authorized use of the Service by the CUSTOMER
                                violates any patent or copyright or improperly appropriates any commercial secret of
                                said third party and (b) indemnify the CUSTOMER for the sums of any settlement or
                                damages, liabilities, costs and expenses (including reasonable attorney fees,
                                “Loss(es)”) granted to said third party by a competent court or agreed as part of a
                                monetary settlement deriving from said Complaint; whenever (i) the CUSTOMER notifies
                                ONLIVE in writing and provides it with reasonable cooperation, information and
                                assistance in relation to it, and (ii) ONLIVE has exclusive control and authority to
                                defend against, resolve or settle said Complaint.
                            </p>
                            <p>
                                If the Service becomes or, in the opinion of ONLIVE, is likely to become the subject of
                                any legal order preventing its use as intended in this document, ONLIVE may, at its
                                choice, (1) obtain the right for the CUSTOMER to continue using the Service or (2)
                                substitute or modify the Service so it is no longer in violation without substantially
                                compromising the principal features.
                            </p>
                            <p>
                                If (1) and (2) are not reasonably within the reach of ONLIVE, it may terminate this
                                Contract early by notifying the CUSTOMER in writing and reimburse the CUSTOMER for any
                                prepaid service fee not used, pro rata with the rest of the prepaid period. ONLIVE shall
                                not have any liability or obligation towards the CUSTOMER in virtue of this Contract
                                with respect to any Complaint or Loss to the extent it is based on (a) any unauthorized
                                use of the Service, (b) any modification or combination of the Service with data,
                                software, hardware or systems not provided by ONLIVE, (c) any part of the Service that
                                implements the CUSTOMER’s specific requirements, (d) the continuation of the alleged
                                breaching activity by the CUSTOMER after notified to cease the use thereof as provided
                                for in this document or (e) the continued use by the CUSTOMER of any version of the
                                ONLINE Property after modifications have been provided that would have prevented the
                                alleged violation. The foregoing establishes the sole and exclusive liability on the
                                part of ONLIVE and the sole and exclusive recourse for the CUSTOMER with respect to any
                                real or alleged breach of intellectual property rights by the Service or any part of it
                                or due to the use or functioning thereof.
                            </p>
                        </Item>
                        <Item title={'Indemnity by the CUSTOMER'}>
                            <p>
                                The CUSTOMER agrees (i) to defend ONLIVE against any third party claim or complaint
                                resulting from (a) a breach by the CUSTOMER of its obligations deriving from the
                                Contract, (b) a breach by the CUSTOMER of the declarations and guarantees established in
                                this Contract, or (c) any breach of third party rights (including any CUSTOMER user);
                                and (ii) to indemnify ONLIVE in relation to any losses declared by a competent court or
                                judge or agreed as part of an out-of-court settlement in relation to any such claim or
                                complaint; to the extent that (i) ONLIVE immediately notifies the CUSTOMER in writing
                                and provides reasonable cooperation, information and assistance in relation to it, and
                                (ii) the CUSTOMER has direct control to defend itself and reach any agreements related
                                to any such claim or complaint.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Limitation of Liability'}>
                    <p>
                        The parties shall not be liable under any circumstance for any indirect, unexpected, special or
                        accidental damages including a loss of profits, earnings, data or use suffered by either of the
                        parties or by a third party, even if the other party or any other person was warned of the
                        possibility of such damage. As a result, the customer and not ONLIVE will be required to make
                        backup copies at sufficient frequency as well as install security mechanisms and obtain licenses
                        and install antivirus, firewall and any other program that may be necessary to maintain the
                        integrity, confidentiality and security of the customer’s data and systems.
                    </p>
                    <p>
                        ONLIVE will be liable for any damages caused due to its fault or negligence deriving from its
                        actions yet under no circumstance may the indemnity exceed the sum corresponding to the price of
                        the license actually paid by the customer to ONLIVE at the time the cause of the indemnity
                        occurs. Among others, ONLIVE will not be liable towards the customer in the following
                        situations: (i) when the software is not used pursuant to the documentation provided to the
                        customer by ONLIVE; (ii) when the failure is caused by software manipulations not previously
                        authorized by ONLIVE or by third-party software.
                    </p>
                </Item>

                <Item title={'Term and Early Termination'}>
                    <ol>
                        <Item title={'Term of the CONTRACT'}>
                            <p>
                                The Contract shall commence on the Effective Date and shall remain valid until (i) one
                                of the Parties notifies the other Party of the termination of the Contract in writing at
                                least thirty (30) days in advance whenever there is no Register in effect, or (ii) if
                                there is one or more Register in effect, upon expiration of the final item to be expired
                                on such order forms existing, whichever occurs later.
                            </p>
                        </Item>
                        <Item title={'Register Term'}>
                            <p>
                                The initial term shall be considered as the one set forth in the Register (
                                <b>“Initial Term”</b>). Registers shall be automatically renewed for additional periods
                                of one (1) additional year (<b>“Renewal Term”</b>) upon completion of the Initial Term
                                and each one of the Renewal Terms (the Initial Term and the Renewal Term collectively
                                considered as the <b>“Term”</b>), unless one of the parties communicates in writing the
                                non-renewal to the other at least thirty (30) days in advance of the end of the Term.
                                Registers will not be subject to termination except as set forth in section 12.3.
                            </p>
                        </Item>
                        <Item title={'Register Cancellation'}>
                            <p>
                                Registers may be cancelled by either of the Parties if the other Party substantially
                                breaches the Contract or Register in question and does not proceed to correct said
                                breach within a period of thirty (30) days after written notification thereof by the
                                other Party.
                            </p>
                        </Item>
                        <Item title={'Effects of Early Termination'}>
                            <p>
                                In the event of the cancellation or early termination of this Contract (including the
                                Registers): (i) any rights granted by either of the parties to the other shall
                                immediately cease; (ii) ONLIVE shall allow the CUSTOMER to access and export the
                                CUSTOMER Data with the ONLIVE rates applicable in effect at that time with respect to
                                the Services applicable in question; (iii) following a period of ninety (90) days ONLIVE
                                will delete the CUSTOMER Data, erasing the CUSTOMER Data indicators from the ONLIVE
                                active and replicated servers; and (iv) each Party, at the request of the other, shall
                                make all reasonable efforts to immediately return or destroy any Confidential
                                Information it may have in its possession.
                            </p>
                        </Item>
                        <Item title={'Personal Data Protection'}>
                            <p>
                                This Contract aims to guarantee the security and confidentiality of the personal data
                                CUSTOMERS provide in compliance with the provisions of Regulation 2016/679 of 27 April
                                2016 on the protection of natural persons with regard to the processing of personal data
                                and on the free movement of such data (hereinafter, <b>“GDPR”</b>) and Spanish Organic
                                Law 3/2018, of 5 December, on the Protection of Personal Data and the guarantee of
                                Digital Rights (hereinafter, <b>“LOPDGDD”</b>). In virtue of this contract, the Parties
                                undertake to sign a data processing agreement which is included as an Annex hereto.
                            </p>
                            <p>
                                To this end, the CUSTOMER is hereby informed that, as concerns the privacy and
                                protection of their personal data, the following rights may be exercised:
                            </p>
                            <ol type="i">
                                <li>Access to their personal data;</li>
                                <li>A request for the rectification of any inaccurate data;</li>
                                <li>A request for the erasure of their data;</li>
                                <li>A request to restrict the processing of their data;</li>
                                <li>An objection to the processing of their personal data;</li>
                                <li>
                                    A request for the portability of their data. Likewise, the CUSTOMER shall have the
                                    right not to be subject to decisions based solely on automated data processing.
                                </li>
                            </ol>
                            <p>
                                The CUSTOMER may exercise all these rights by sending an e-mail to [
                                <a href="mailto:protecciondedatos@onlive.site">protecciondedatos@onlive.site</a>],
                                stating the reason for their request or exercise their rights by writing to the
                                following postal address: Avenida Diagonal, 383, 3º, 2ª – 08008 Barcelona. Moreover, the
                                CUSTOMER may address any complaints to the Spanish Data Protection Agency
                                (https://www.aepd.es/es) if they believe they were not able to properly exercise their
                                rights.
                            </p>
                            <p>
                                Furthermore, the CUSTOMER hereby guarantees that it will not request any data considered
                                “special personal data categories” from any Users that use the ONLIVE Platform under the
                                terms stated in this Contract, in virtue of article 9 GDPR. To this end, the CUSTOMER
                                may not ask Users using the ONLIVE Platform to disclose their ethnic origin or race,
                                political opinions, religious or philosophical convictions, union membership or any
                                health-related data or data related to their sexual life or orientation. This is due to
                                the fact that special personal data categories requiring greater protection must only be
                                processed for purposes related to health when necessary to achieve such purposes in
                                benefit of the natural persons and society at large, which is understood as unnecessary
                                under the terms of this Contract.
                            </p>
                        </Item>
                    </ol>
                </Item>

                <Item title={'Applicable Law and Jurisdiction'}>
                    <p>
                        Any disputes arising from the interpretation or execution of this Contract or of any of its
                        possible amendments, as well as any breach thereof shall be interpreted in accordance with the
                        laws of Spain.
                    </p>
                    <p>
                        In order to resolve any dispute related to the provisions of this Contract or in execution
                        thereof, ONLIVE and the CUSTOMER hereby expressly submit to the Courts and Tribunals of
                        Barcelona, waiving any other forum that would otherwise correspond. The language to be used
                        shall be Spanish.
                    </p>
                    <p>
                        Each one of the Parties must submit to any laws and regulations applicable in relation to
                        compliance with their obligations and the exercise of their rights as provided for under this
                        Contract.
                    </p>
                </Item>

                <Item title={'Force Majeure'}>
                    <p>
                        The Parties will not be liable for any breach of the obligations set forth in this Contract to
                        the extent such breach is due to causes reasonably outside the control of the breaching Party
                        such as but not limited to fire, flooding, strikes, labor conflicts or other social disorder, a
                        shortage or unavailability of fuel or power, the unavailability or anomalous functioning of
                        communications networks, accidents, wars (declared or non-declared), commercial embargoes,
                        blockades, disturbances or insurrections.
                    </p>
                </Item>

                <Item title={'Advertising'}>
                    <p>
                        The CUSTOMER agrees that ONLIVE may include the CUSTOMER name or brand in a list of ONLIVE
                        customers either online or in promotional materials. Moreover, the CUSTOMER also agrees that
                        ONLIVE may verbally refer to the CUSTOMER as a CUSTOMER of ONLIVE products or services as
                        stipulated in this CONTRACT.
                    </p>
                </Item>

                <Item title={'Notifications'}>
                    <p>
                        Any notification or communication of termination or breach of this Contract must be made in
                        writing and sent to the other Party at the address specified in the Register. Notifications
                        shall be understood as delivered at the time of receipt as proven by the corresponding
                        electronic or physical proof of receipt (as applicable in each case).
                    </p>
                </Item>

                <Item title={'Assignment'}>
                    <p>
                        Neither of the parties may assign their rights or obligations arising from this Contract without
                        the prior written consent of the other party except in cases of assignments in favor of any
                        Company in the same Group, whenever: (i) the assignee undertakes in writing to be bound to the
                        terms of this Contract; (ii) the assignor communicates the assignment to the other party; and
                        (iii) in cases where the assignor is the CUSTOMER, the assignee shall satisfactorily pass all
                        credit controls required by ONLIVE.
                    </p>
                    <p>
                        ONLIVE may fully or partially outsource the compliance of its obligations under this Contract
                        without needing to secure prior consent in writing from the CUSTOMER. ONLIVE nonetheless will
                        continue to be fully liable for the compliance thereof, in particular for any actions and/or
                        omissions by its subcontractors to this end, as if its own actions and/or omissions.
                    </p>
                </Item>

                <Item title={'Modifications'}>
                    <p>
                        The information that appears on the ONLIVE Platform is valid as of the date of the last update.
                        ONLIVE reserves the right to update, modify or delete the information on it as well as this
                        Contract, the Privacy Policy or any other information.
                    </p>
                    <p>
                        Any modifications to the Contract shall enter into force as soon as published on the ONLIVE
                        Platform.
                    </p>
                </Item>

                <Item title={'Severability'}>
                    <p>
                        All clauses and circumstances in this Contract must be interpreted independently and
                        autonomously without being affected by the other stipulations if any of them are declared null
                        and void by a final court ruling or arbitration decision. The clause or clauses affected shall
                        be replaced with another one or other ones preserving the effects pursued by the Contract.
                    </p>
                </Item>
            </ol>

            <h2>DATA PROCESSING AGREEMENT</h2>
            <p>
                The Parties hereby agree to sign this data processing agreement to regulate all personal data ONLIVE, as
                the Data Processor (hereinafter, <b>the DATA PROCESSOR</b>), shall process on behalf of the CUSTOMER, as{' '}
                <b>the Data Controller</b> (hereinafter, the DATA CONTROLLER).
            </p>

            <h3>ONE. - DEFINITIONS</h3>
            <ol type="a">
                <Item>Object of the Contract: It shall mean as indicated in Stipulation 2 of this Contract.</Item>
                <Item>Start Date: It shall mean the date this Contract is signed.</Item>
                <Item>
                    Data Controller: It shall mean the natural or legal person, pubItemc authority, service or other
                    body which, alone or jointly with others, determines the purposes and means of the processing of
                    personal data; if EU Law or Member State Law sets forth the purposes and means of processing, the
                    data controller or specific criteria for the appointment thereof may be estabItemshed by EU Law or
                    Member State Law.
                </Item>
                <Item>
                    Data Processor: It shall mean the natural person or legal entity, pubItemc authority, service or
                    other body which has isolated access to data for which the Data Controller is Itemable, and which
                    does not decide the purpose of the processing of such data.
                </Item>
                <Item>
                    Data Protection Authority: It shall mean the corresponding Data Protection Authority in the
                    territories where the Parties to this Contract are located.
                </Item>
                <Item>
                    Data Protection Laws: this shall mean Regulation 2016/679 of the European ParItemament and of the
                    Council, of 27 April 2016, on the protection of natural persons with regard to the processing of
                    personal data and on the free movement of such data (hereinafter, “GDPR“) and any laws and/or
                    regulation implemented or enacted in accordance with it, or which modifies or replaces it, along
                    with any best practice guides and codes issued by the Spanish Data Protection Agency (AEPD) or by
                    any supervisory authority as appItemcable to the corresponding activities of processing personal
                    data owned by the Parties or in any other manner to Shared Personal Data.
                </Item>
                <Item>Parties: The Parties signing the Contract.</Item>
                <Item>
                    Security breach: It shall mean any unauthorized or illegal effective loss, destruction, modification
                    or disclosure of the Personal Data, and/or any other irregularity in the processing of Personal
                    Data.
                </Item>
                <Item>User or data subject rights: It shall mean as intended by Data Protection Laws.</Item>
                <Item>
                    Creation of profiles: It shall mean any form of automatic personal data processing consisting of
                    using personal data to assess certain personal aspects of a natural person, in particular to analyze
                    or predict aspects relating to the professional performance, economic status, health, personal
                    preferences, interests, reItemabiItemty, behavior, location or movements of said natural person.
                </Item>
                <Item>Data subject: It shall mean persons affected by the processing of their data.</Item>
                <Item>
                    Data processing: It shall mean any operation or set of operations performed on personal data or on
                    sets of personal data, whether or not by automated means, such as collection, recording,
                    organization, structuring, storage, adaptation or alteration, retrieval, consultation, use,
                    disclosure by transmission, dissemination or otherwise making available, aItemgnment or combination,
                    restriction, erasure or destruction.
                </Item>
                <Item>
                    Data subject consent: It shall mean any specific, informed and unequivocal manifestation of free
                    will by which a data subject agrees to the processing of their personal data either through a
                    declaration or a clear affirmative action.
                </Item>
            </ol>

            <h3>TWO. - OBJECT OF THE CONTRACT</h3>
            <ol>
                <Item>
                    The object of this Contract is to regulate the processing by the DATA PROCESSOR of the personal data
                    held by the DATA CONTROLLER for the purpose of providing the services defined in the main contract
                    (hereinafter, “the Services”) commissioned by the DATA CONTROLLER consisting of the organization of
                    virtual spaces for the sale of the DATA CONTROLLER’s products to the DATA CONTROLLER’s users.
                </Item>
                <Item>
                    This Contract gives rise to access to third-party data and not to the assignment or disclosure of
                    personal data meaning the DATA CONTROLLER continues to control the uses and purposes of the
                    processing of the personal data it holds as DATA CONTROLLER.
                </Item>
            </ol>

            <h3>THREE. - TERM</h3>
            <ol>
                <Item>
                    The data processing described in this stipulation shall survive as long as the obligations deriving
                    from this contract survive, as well as the survival of the main contract.
                </Item>
                <Item>
                    Once the obligations under this contract and the main contract end, the DATA PROCESSOR must return
                    or destroy the data and any copies that may exist at the discretion of the DATA CONTROLLER.
                </Item>
            </ol>

            <h3>FOUR. - PERSONAL DATA PROCESSING</h3>
            <ol>
                <Item>
                    These stipulations hereby enable the DATA PROCESSOR to process the necessary personal data on behalf
                    of the DATA CONTROLLER to provide the Services.
                </Item>
                <Item>
                    The DATA PROCESSOR has been selected in view of the fact that it offers sufficient guarantees to
                    apply the appropriate technical and organizational measures so the processing complies with the
                    legal requirements and guarantees protection of user rights.
                </Item>
                <Item title={'Types of personal data processed'}>
                    <p>The DATA PROCESSOR may process the following categories of personal data:</p>
                    <ul>
                        <Item>Identification and contact details.</Item>
                    </ul>
                </Item>
                <Item title={'THE DATA PROCESSOR shall be able to engage in the following processing tasks'}>
                    <ul>
                        <Item>Collection</Item>
                        <Item>Registration</Item>
                        <Item>Storage</Item>
                        <Item>Use</Item>
                        <Item>Communication</Item>
                        <Item>Restriction</Item>
                        <Item>Erasure</Item>
                        <Item>Destruction</Item>
                    </ul>
                </Item>
            </ol>

            <h3>FIVE. - OBLIGATIONS OF THE DATA PROCESSOR</h3>
            <p>The DATA PROCESSOR, and all its personnel, hereby undertake to:</p>
            <ol>
                <Item>
                    User the personal data subject of processing or collected or processed due to the provision of the
                    Services described in this Contract solely for the purpose of providing the Services to the DATA
                    CONTROLLER. Under no circumstance may the data be used for purposes other than those provided for in
                    this Contract.
                </Item>
                <Item>
                    Process the data in accordance with the instructions from the DATA CONTROLLER. If the DATA PROCESSOR
                    believes any of the instructions violates Data Protection Laws, said party must immediately notify
                    the DATA CONTROLLER.
                </Item>
                <Item>
                    Guarantee that all persons authorized to process personal data expressly undertake in writing to
                    respect the confidentiality thereof and comply with the corresponding security measures.
                </Item>
                <Item>
                    Take measures to guarantee that any person acting under its authority and has access to personal
                    data can only process them following the instructions from the DATA CONTROLLER.
                </Item>
                <Item>
                    Not disclose the data to third parties, except where it has the express authorization of the DATA
                    CONTROLLER.
                </Item>
                <Item>
                    Disclose data to other data processors as per the instructions from the DATA CONTROLLER. In such
                    case, the DATA CONTROLLER shall identify, previously and in writing, the entity to which data shall
                    be disclosed, the data to be disclosed and the safety measures applicable to such disclosure.
                </Item>
                <Item>
                    Support the DATA CONTROLLER in performing previous consultations with the relevant supervisory
                    authority, when appropriate.
                </Item>
                <Item>
                    Implement appropriate security measures to guarantee the security of the information processed and
                    the rights of the affected parties as per the corresponding security level.
                </Item>
                <Item>
                    Make available to the DATA CONTROLLER all information necessary in order to demonstrate compliance
                    with the obligations established in this Contract, and to permit and contribute to the completion of
                    audits, including inspections by the DATA CONTROLLER or any other auditor authorized by the DATA
                    CONTROLLER.
                </Item>
                <Item>
                    When international data transfers are to be performed, take adequate measures to guarantee the
                    processing and international data transfer occurs in a secure environment based on the Adequacy
                    Decisions the European Commission has signed with each territory or international organization or by
                    signing the European Commission Standard Contractual Clauses, as applicable in each case.
                </Item>
                <Item
                    title={
                        'Provide a general description of the technical and organizational security measures regarding:'
                    }
                >
                    <ol type="a">
                        <Item>Personal data pseudonymization and encryption.</Item>
                        <Item>
                            The ability to guarantee the confidentiality, integrity, availability and permanent
                            resilience of processing systems and services.
                        </Item>
                        <Item>
                            The ability to restore the availability and access to personal data quickly in the event of
                            a physical or technical incident.
                        </Item>
                        <Item>
                            Regular verification, assessment and evaluation processes to determine the efficacy of
                            technical and organizational measures to guarantee processing security.
                        </Item>
                    </ol>
                </Item>
                <Item title={'Notify the DATA CONTROLLER if any of the services are to be outsourced whenever:'}>
                    <ol type="a">
                        <Item>
                            There is written authorization from the DATA CONTROLLER (authorization which must not be
                            denied, conditioned upon or delayed without justification); or
                        </Item>
                        <Item>
                            There is a contract binding the processing outsourcer to substantially similar conditions as
                            those for the processing of the DATA CONTROLLER’s personal data by the DATA PROCESSOR
                            pursuant to the provisions of this Contract;
                        </Item>
                    </ol>
                </Item>
                <p>
                    The processing outsourcer shall likewise be bound by the obligations established in this document
                    for the DATA PROCESSOR and the instructions from the DATA CONTROLLER.
                </p>
                <Item
                    title={
                        'The DATA PROCESSOR undertakes to assist the DATA CONTROLLER and cooperate with said party, when necessary, in the following situations:'
                    }
                >
                    <ol type="a">
                        <Item>
                            So the DATA CONTROLLER may comply with the obligation of responding to requests to exercise
                            data subject rights (access, rectification, deletion, objection, restriction of processing,
                            portability and the right not to be subject of automatic individualized decisions, including
                            the creation of profiles) and, as appropriate, the right to be forgotten.
                        </Item>
                        <Item>
                            To this end, when persons affected exercise such rights before the DATA PROCESSOR, said
                            party must notify the DATA CONTROLLER by email immediately and never more than one business
                            day after receipt of such a request. Any such notification will be accompanied by all other
                            information that may be relevant in resolving the request.
                        </Item>
                        <Item>
                            So the DATA CONTROLLER may comply with the obligations of applying the pertinent security
                            measures to the processing.
                        </Item>
                        <Item>When performing impact assessment regarding data protection, as appropriate.</Item>
                    </ol>
                </Item>
            </ol>

            <h3>SIX. - OBLIGATIONS OF THE DATA CONTROLLER</h3>
            <p>The DATA CONTROLLER must:</p>
            <ol>
                <Item>
                    Ensure compliance with all the technical and organizational measures necessary to guarantee the
                    security of the personal data, preventing the unauthorized alteration, loss, processing or access
                    thereof.
                </Item>
                <Item>
                    Communicate to the DATA PROCESSOR any variation in the personal data provided in order to update
                    them.
                </Item>
                <Item>Collect the personal data subject of processing in compliance with Data Protection laws.</Item>
                <Item title={'THE DATA CONTROLLER hereby guarantees:'}>
                    <ol type="a">
                        <Item>
                            That the personal data in its databases have been directly obtained from the data subjects
                            and that the latter have been expressly, precisely and unequivocally informed prior to the
                            collection of such data of:
                            <ul>
                                <li>The existence of a personal data database.</li>
                                <li>The purpose of data collection.</li>
                                <li>The recipients of the information.</li>
                                <li>The consequences of obtaining the data or any refusal to provide them.</li>
                                <li>
                                    The possibility of exercising their rights of access, rectification, erasure,
                                    objection, restriction of processing, portability and to be forgotten.
                                </li>
                                <li>The identity and address of the Data Controller.</li>
                            </ul>
                        </Item>
                        <Item>
                            That it has informed the data subject of the explicit and legitimate purposes for which
                            their data are to be used.
                        </Item>
                        <Item>
                            That it offers data subjects or recipients the possibility of objecting to the processing of
                            their data through a simple procedure free of cost.
                        </Item>
                        <Item>That it has implemented the necessary security measures to guarantee data security.</Item>
                        <Item>
                            That the personal data are solely processed by employees who need to know them for the
                            contractual purpose and that they have been warned and trained on their obligations with
                            respect to personal data protection.
                        </Item>
                        <Item>That the data processing done is legitimate.</Item>
                        <Item>
                            That, if necessary for their organization, it has appointed a Data Protection Officer.
                        </Item>
                        <Item>That it has an Activity Log.</Item>
                    </ol>
                </Item>
            </ol>

            <h3>SEVEN. - NOTIFICATION OF SECURITY BREACHES AND IMPACT ASSESSMENTS</h3>
            <ol>
                <Item title={'Notification of Data Security Breaches'}>
                    <p>
                        The DATA PROCESSOR will notify the DATA CONTROLLER by email without any undue delay, and if
                        possible, within a maximum period of 48 hours of any security breaches involving the personal
                        data under its responsibility of which it becomes aware. Any such communication shall be
                        accompanied by all relevant information to document and communicate the incident.
                    </p>
                    <p>
                        Notification will not be necessary when it is unlikely said security breach poses a risk to the
                        data subjects’ rights and liberties.
                    </p>
                    <p>The following minimum information will be provided, if available:</p>
                    <ol type="a">
                        <Item>
                            A description of the nature of the personal data security breach, including, where possible,
                            the categories and approximate number of data subjects who have been affected, and the
                            categories and approximate number of personal data records which have been affected.
                        </Item>
                        <Item>
                            The name and contact data of the data protection officer or any other contact person from
                            whom further information may be obtained.
                        </Item>
                        <Item>A description of the possible consequences of a personal data security breach.</Item>
                        <Item>
                            A description of the measures adopted or proposed to remedy the personal data security
                            breach including, if appropriate, the measures adopted to mitigate possible negative
                            effects.
                        </Item>
                    </ol>
                    <p>
                        If it is not possible to provide the information simultaneously and, to the extent it is not,
                        the information shall be gradually provided without undue delay.
                    </p>
                </Item>
                <Item title={'Impact Assessments'}>
                    <p>
                        The DATA PROCESSOR hereby undertakes to support the DATA CONTROLLER in performing impact
                        assessment regarding data protection, as appropriate.
                    </p>
                </Item>
            </ol>

            <h3>EIGHT. – THE DESTINATION OF THE DATA UPON TERMINATION OF THE RELATIONSHIP</h3>
            <ol>
                <Item>
                    <p>
                        Once the contractual relationship has terminated, the DATA PROCESSOR shall ask for instructions
                        in writing from the DATA CONTROLLER as to the destination of the personal data processed. The
                        DATA CONTROLLER shall communicate to the DATA PROCESSOR the action that must be taken with the
                        data, among the following:
                    </p>
                    <ol type="a">
                        <Item>
                            Returning the data and, if applicable, all media with personal data to the DATA CONTROLLER
                            once the service has been completed. Said return shall involve the total deletion of the
                            data existing on the computer equipment used by the DATA PROCESSOR.
                        </Item>
                        <Item>
                            Delivering the data to another data processor designated in writing by the DATA CONTROLLER
                            and, if applicable, the media with personal data. Said return shall involve the total
                            deletion of the data existing on the computer equipment used by the DATA PROCESSOR.
                        </Item>
                        <Item>A description of the possible consequences of a personal data security breach.</Item>
                        <Item>
                            Destroying the data once the service has been performed. The DATA PROCESSOR must certify the
                            destruction thereof in writing and deliver the certificate to the DATA CONTROLLER.
                        </Item>
                    </ol>
                </Item>
                <p>
                    In any case, the DATA PROCESSOR may retain a copy of the data, duly blocked, for as long as
                    liabilities may arise from the performance of said provision.
                </p>
            </ol>

            <h3>NINE. - LIABILITY IN THE EVENT OF A COMPLAINT</h3>
            <ol>
                <Item>
                    If the DATA PROCESSOR is involved in any investigation or administrative procedure initiated by a
                    supervisory authority or any third-party complaint, this shall be immediately communicated to the
                    DATA CONTROLLER, describing the alleged facts and actions taken. Once the procedure has concluded,
                    it must deliver a copy of the Decision rendered.
                </Item>
                <Item>
                    If a supervisory authority were to sanction the DATA CONTROLLER or any third party as a direct or
                    indirect result of the DATA PROCESSOR not complying with the stipulations of this contract, the DATA
                    PROCESSOR shall indemnify the DATA CONTROLLER or, where appropriate, the third party with a sum
                    equal to the sanction plus legal interest plus the defense and procedural costs involved plus the
                    quantification of any damages that may have been incurred.
                </Item>
                <Item>
                    Without prejudice to the foregoing, both parties by mutual agreement hereby undertake to be liable
                    for the all damages caused to the other party in all cases of negligent or reckless conduct in the
                    breach of the contractual and regulatory obligations that apply respectively, under the terms of
                    this contract.
                </Item>
            </ol>

            <h3>TEN. - ENSURING COMPLIANCE WITH THIS CONTRACT</h3>
            <ol>
                <Item>
                    The DATA PROCESSOR shall make all information necessary available to the DATA CONTROLLER in order to
                    demonstrate compliance with the obligations established in this contract, and to permit and
                    contribute to the completion of audits, including inspections by the DATA CONTROLLER or any other
                    third party authorized by the latter. Said controls must be done with the corresponding advance
                    notice and causing the DATA PROCESSOR the least possible inconvenience.
                </Item>
                <Item>
                    A lack of proof that the DATA PROCESSOR is properly complying with the obligations undertake in this
                    contract shall be cause for the early termination hereof.
                </Item>
            </ol>

            <h3>ELEVEN. - CONFIDENTIALITY </h3>
            <ol>
                <Item>
                    The DATA PROCESSOR and the DATA CONTROLLER wish to prevent certain Confidential Information
                    exchanged during the course of the commercial relationship between them or to be between them
                    (hereinafter, the <b>“Purpose”</b>) from reaching third parties and, therefore, both parties agree
                    to regulate the system under which said Confidential Information is used pursuant to the following
                    paragraphs:
                </Item>
                <Item title={'DEFINITIONS'}>
                    <p>
                        <b>“Confidential Information”</b> shall mean all confidential information the Parties may
                        exchange as tangible, intangible, oral, visual, electronic information in the present or future
                        irrespective of the means and moment acquired, including but not limited to:
                    </p>
                    <ol type="a">
                        <Item>
                            commercial secrets (whether or not they are subject to or protected by copyrights, patents
                            or trademarks [registered or not registered]);
                        </Item>
                        <Item>
                            financial information, including but not limited to economic forecasts or financial plans
                            for the future;
                        </Item>
                        <Item>
                            sensitive information including but not limited to operations, plans, marketing actions and
                            products, technology or any other type of project, developments, etc.;
                        </Item>
                        <Item>
                            the terms and conditions of any agreement signed by and between the Parties and any
                            discussions, negotiations and proposals related to any such contracts;
                        </Item>
                        <Item>
                            information on the contracts signed with customers, email messages and any other
                            communication.
                        </Item>
                        <ul>
                            <Item>
                                <b>“Discloser”</b> shall mean the Party that reveals or discloses Confidential
                                Information.
                            </Item>
                            <Item>
                                <b>“Recipient”</b> shall mean the Party that receives the Confidential Information.
                            </Item>
                            <Item>
                                <b>“Personnel”</b> shall mean employees, management, professional advisors, agents or
                                third party outsourcers of either of the Parties.
                            </Item>
                            <Item>
                                <b>“Order”</b> shall mean by application of the law or an order by a court or other
                                administrative agency.
                            </Item>
                        </ul>
                    </ol>
                </Item>
                <Item title={'USE OF THE INFORMATION'}>
                    <p>The Recipient shall use the Confidential Information under the following circumstances:</p>
                    <ol type="a">
                        <Item>Only for the Purpose of this Contract.</Item>
                        <Item>
                            In such manner that prevents any use of the Confidential Information that breaches the
                            provisions of this stipulation and/or the communication of the Confidential Information to
                            any unauthorized third party.
                        </Item>
                        <Item>
                            The Confidential Information may only be provided or shown to Personnel with a strict need
                            to know and only for or in relation to the Purpose. The Recipient hereby agrees that its
                            Personnel shall be bound by adequate and sufficient confidentiality agreements to protect
                            the confidentiality of the Confidential Information.
                        </Item>
                    </ol>
                </Item>
                <Item title={'LIABILITY OF THE RECIPIENT OF THE INFORMATION'}>
                    <p>
                        The Recipient shall be liable for any breach of this Agreement and any damages caused due to the
                        disclosure of the Confidential Information without authorization from the other Party.
                    </p>
                    <p>
                        This Agreement does not impose any obligation on the Recipient in relation to any Confidential
                        Information that:
                    </p>
                    <ol type="a">
                        <Item>Was in the possession of the Parties prior to the date of disclosure.</Item>
                        <Item>
                            Enters the public domain without any offense, action, omission or intervention by the
                            Recipient.
                        </Item>
                        <Item>
                            Is received by the Recipient from a third party without any confidentiality obligation
                            towards the Discloser.
                        </Item>
                        <Item>Must be provided as a result of a court order or legal requirement.</Item>
                        <Item>
                            Unless otherwise agreed by the Parties in writing, their obligation to protect the
                            Confidential Information shall be indefinite.
                        </Item>
                        <Item>
                            The Recipient shall only make copies of any document or other material (through any means)
                            including any Confidential Information when reasonably necessary for the Purpose.
                            Immediately after receipt of a written request from the Discloser, the Recipient shall
                            return all the documents or other original material containing or including Confidential
                            Information to the Discloser along with all copies or will certify in writing that all
                            Confidential Information and copies thereof have been destroyed.
                        </Item>
                    </ol>
                </Item>
                <Item title={'ASSIGNMENT OF THE AGREEMENT'}>
                    <p>
                        This Agreement may not be assigned or transferred by either of the Parties without the prior
                        written consent of the other Party.
                    </p>
                </Item>
                <Item title={'ECONOMIC QUANTIFICATION OF THE CONFIDENTIALITY AGREEMENT'}>
                    <p>
                        The parties hereby declare and agree that the Confidential Information is unique and
                        economically quantifiable meaning any disclosure without consent will be considered a breach of
                        the provisions of this stipulation and it shall generate damages with the harmed party reserving
                        any legal actions applicable to claim damages actually caused.
                    </p>
                    <p>
                        The DATA PROCESSOR shall apply all technical and organizational measures necessary to maintain
                        the information confidentiality obligation, specifically those referring to the processing of
                        personal data belonging to the DATA CONTROLLER as set forth in the data protection regulations.
                    </p>
                    <p>
                        This limitation shall be applied indefinitely and remain in effect following the termination for
                        any cause of this Contract from the communication of the information, irrespective of the term
                        of this Contract. Both parties hereby agree that any confidential information received by the
                        other party must be used for the sole purposes of providing or receiving services under the
                        scope of this Contract.
                    </p>
                    <p>The above restrictions shall not apply to any information:</p>
                    <ol type="a">
                        <Item>
                            That is or becomes accessible to the general public for any reason other than a breach of an
                            obligation deriving from this stipulation; or
                        </Item>
                        <Item>
                            That is obtained from a third party not bound to any confidentiality obligation in relation
                            to the information; or
                        </Item>
                        <Item>That is known by it previously; or</Item>
                        <Item>When the disclosure thereof by the recipient is imposed by law.</Item>
                    </ol>
                </Item>
            </ol>

            <h3>TWELVE. - BREACH AND EARLY TERMINATION</h3>
            <ol>
                <Item>
                    In the event of a breach of any of the obligations by the DATA CONTROLLER established above, the
                    DATA PROCESSOR may terminate this Contract with no further action necessary except communicating
                    such decision to the DATA CONTROLLER. In the event of a breach of any of the obligations by the DATA
                    PROCESSOR, the DATA CONTROLLER may also terminate the Contract, communicating its decision to the
                    DATA PROCESSOR.
                </Item>
            </ol>

            <h3>THIRTEEN. - APPLICABLE FORUM AND LAW</h3>
            <ol>
                <Item>
                    The parties waive their own forum and expressly submit to the jurisdiction of the Courts and
                    Tribunals of Madrid for any type of legal dispute that may arise from a breach of this Contract, and
                    agree that the law applicable shall be Spanish.
                </Item>
                <Item>
                    If any of the stipulations of this Contract are declared null and void, illegal or unenforceable,
                    the validity, legality and enforceability of the rest of the stipulations shall not be affected or
                    harmed. The stipulations of this Contract shall be interpreted pursuant to good faith and in the
                    straight-forward, inherent and usual meaning of the words stated.
                </Item>
            </ol>
        </div>
    );
};

export default TermsENv3;
