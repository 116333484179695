import { createSlice } from '@reduxjs/toolkit';
import { shoppableApi } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const shoppableSlice = createSlice({
    name: 'shoppable',
    initialState: {
        pending: false,
        savePending: false,
        shoppable: [],
        error: null,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 0,
        pendingTotal: false,
        errorTotal: null,
        newShoppable: null,
        editIsPublicStatusPending: false,
        pendingTvChannels: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setShoppable: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.shoppable = action.payload.items;
        },
        setNewShoppable: (state, action) => {
            state.newShoppable = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.shoppable = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addShoppable: (state, action) => {
            state.savePending = false;
            state.shoppable.splice(-1, 1);
            state.shoppable = [action.payload, ...state.shoppable];
            state.total = state.total + 1;
        },
        editShoppable: (state, action) => {
            state.savePending = false;
            const index = state.shoppable.findIndex((shoppable) => shoppable._id === action.payload._id);
            if (index !== -1) {
                state.shoppable = [
                    ...state.shoppable.slice(0, index),
                    action.payload,
                    ...state.shoppable.slice(index + 1),
                ];
            }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
        },
        deleteShoppable: (state, action) => {
            state.deletePending = false;
            const index = state.shoppable.findIndex(({ _id }) => _id === action.payload._id);
            if (index !== -1) {
                state.shoppable.splice(index, 1);
                state.total = state.total - 1;
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        setTotal: (state, action) => {
            state.pendingTotal = false;
            state.total = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        startFetchTotal: (state, action) => {
            state.pendingTotal = true;
            state.total = 0;
        },
        setErrorTotal: (state, action) => {
            state.pendingTotal = false;
            state.total = 0;
            state.errorTotal = action.payload;
        },
        startEditIsPublicStatus: (state, action) => {
            state.editIsPublicStatusPending = true;
        },
        stopEditIsPublicStatus: (state, action) => {
            state.editIsPublicStatusPending = false;
        },
        setPendingTvChannels: (state, action) => {
            state.pendingTvChannels = action.payload;
        },
    },
});

export const {
    start,
    stop,
    stopSave,
    setShoppable,
    setError,
    startSave,
    addShoppable,
    editShoppable,
    setSaveError,
    startDelete,
    deleteShoppable,
    setDeleteError,
    setTotal,
    setTotalPages,
    startFetchTotal,
    setErrorTotal,
    setNewShoppable,
    startEditIsPublicStatus,
    stopEditIsPublicStatus,
    setPendingTvChannels,
} = shoppableSlice.actions;

export const getShoppable = (state) => state.shoppable.shoppable;
export const getShoppableCount = (state) => state.shoppable.count;
export const getShoppableTotal = (state) => state.shoppable.total;
export const getShoppablePending = (state) => state.shoppable.pending;
export const getShoppableSavePending = (state) => state.shoppable.savePending;
export const getShoppableDeletePending = (state) => state.shoppable.deletePending;
export const getShoppableError = (state) => state.shoppable.error;
export const getShoppableId = (state) => state.shoppable.shoppableId;
export const getTotalShoppable = (state) => state.shoppable.total;
export const getTotalPagesShoppable = (state) => state.shoppable.totalPages;
export const getCurrentPageShoppable = (state) => state.shoppable.currentPage;
export const getPendingTotalShoppable = (state) => state.shoppable.pendingTotal;
export const getNewShoppableCreated = (state) => state.shoppable.newShoppable;
export const getEditIsPublicStatusPending = (state) => state.shoppable.editIsPublicStatusPending;

export const fetchShoppableAction = (queryParams, success) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        shoppableApi
            .get(query)
            .then((response) => {
                dispatch(setShoppable(response.data));
                success?.(response.data.items);
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const fetchPublicShoppableAction = (queryParams) => async (dispatch, getState) => {
    try {
        const state = getState();
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        shoppableApi
            .get(`organization/${state.organization.organization.id}` + query)
            .then((response) => {
                dispatch(setShoppable(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const addShoppableAction = (shoppableDto, success) => async (dispatch) => {
    try {
        dispatch(start());
        await shoppableApi
            .post('', shoppableDto)
            .then((response) => {
                if (success) {
                    success();
                }
                dispatch(setNewShoppable(response.data));
                dispatch(addShoppable(response.data));
                dispatch(stop());
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(setSaveError(`On multimedia shoppable: ${e.message}`));
        console.error(`On multimedia shoppable: ${e.message}`);
    }
};
export const deleteShoppableAction = (shoppableId, onSuccess) => async (dispatch) => {
    try {
        dispatch(startDelete(shoppableId));
        shoppableApi
            .delete(shoppableId)
            .then((response) => {
                dispatch(deleteShoppable(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const shoppableTvChannels =
    ({ id, data, success, failure }) =>
    async (dispatch) => {
        try {
            dispatch(setPendingTvChannels(true));
            shoppableApi
                .post('' + id, data)
                .then((response) => {
                    dispatch(setPendingTvChannels(false));
                    dispatch(editShoppable(response.data));
                    success(response.data);
                    return response;
                })
                .catch(() => {
                    failure();
                    dispatch(setPendingTvChannels(false));
                });
        } catch (e) {
            failure();
            dispatch(setPendingTvChannels(false));
            return console.error(e.message);
        }
    };

export default shoppableSlice.reducer;
