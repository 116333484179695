import { makeStyles } from 'tss-react/mui';

const useStylesImageContainer = makeStyles()((theme) => {
    return {
        root: {
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: '10px',
            overflow: 'hidden',
            height: '90px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
        },

        big: {
            height: '120px',
        },

        primary: {
            color: theme.palette.primary.main,
        },

        secondary: {
            color: '#3283D3',
        },

        default: {
            color: '#000000',
            opacity: '0.5',
        },

        closeIcon: {
            position: 'absolute',
            right: '0px',
            zIndex: '9999',
            border: '1px solid #00000021',
            borderRadius: '22px',
            backgroundColor: theme.palette.default.main,
            width: '22px',
            height: '22px',

            '& svg': {
                fontSize: '1rem',
                color: 'rgb(0 0 0 / 0.30)',
            },

            '&:hover': {
                backgroundColor: theme.palette.default.main,
            },
        },
    };
});

export default useStylesImageContainer;
