import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addContactAction } from 'redux/slices/contactSlice';
import { closeNestedDialogAction } from 'redux/slices/nestedDialogSlice';
import ContactForm from './ContactForm';

const EmptyContact = {
    name: '',
    email: '',
    phone: '',
};

const ContactCreate = ({ seed, onSubmit, onCancel }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const handleSubmit = ({ contactDto, onSuccess, onFailure }) => {
        dispatch(
            addContactAction({
                contactDto,
                onSuccess: (d) => {
                    if (onSubmit) {
                        try {
                            onSubmit(d);
                        } catch (e) {
                            console.error(`Error executing create contact callback: ${e.message}`);
                        }
                    }
                    onSuccess();
                    dispatch(closeNestedDialogAction());
                },
                onFailure: onFailure,
            }),
        );
    };

    return (
        <ContactForm
            action={t('contact.form.newContact')}
            contact={seed || EmptyContact}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    );
};

export default ContactCreate;
