import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeCustomDialogAction, openCustomDialog } from 'redux/slices/customDialogSlice';
import { closeNestedDialogAction, openNestedDialog } from 'redux/slices/nestedDialogSlice';
import 'suneditor/dist/css/suneditor.min.css';
import useStylesSettings from '../../settings/styles/useStylesSettings.styles';
import SecurityCardItem from '../SecurityCardItem';
import SecurityStatusItem from '../SecurityStatusItem';
import AuthenticatorForm from './AuthenticatorForm';
import AuthenticatorFormConfirm from './AuthenticatorFormConfirm';

const Authenticator = ({ factor, postMethod, onDelete, confirmMethod }) => {
    const { t } = useTranslation();
    const { classes } = useStylesSettings();
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);

    const onConfigure = () => {
        setPending(true);
        postMethod({
            method: 'authenticator',
            callback: (data) => {
                setPending(false);
                dispatch(
                    openCustomDialog(<AuthenticatorForm data={data} onConfirm={onConfirm} />, {
                        showCloseIcon: false,
                    }),
                );
            },
        });
    };

    const onConfirm = () => {
        dispatch(openNestedDialog(<AuthenticatorFormConfirm confirmMethod={sendConfirmMethod} pending={pending} />));
    };

    const sendConfirmMethod = ({ code, onError }) => {
        setPending(true);
        confirmMethod({
            method: 'authenticator',
            code,
            callback: () => {
                setPending(false);
                dispatch(closeNestedDialogAction());
                dispatch(closeCustomDialogAction());
            },
            onError: () => {
                onError();
                setPending(false);
            },
        });
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <QrCodeScannerOutlinedIcon />
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('profile.security.authenticator')}
                    </Typography>
                    <SecurityStatusItem factor={factor} text={t('profile.security.authenticatorConfirmedWarning')} />
                </Stack>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {t('profile.security.authenticatorMessage')}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {t('profile.security.authenticatorDownload') + ' '}
                        <a href={config.authenticatorApp.playStore} target="_blank" rel="noreferrer">
                            Google Play Store
                        </a>{' '}
                        {t('profile.security.authenticatorOr') + ' '}
                        <a href={config.authenticatorApp.appStore} target="_blank" rel="noreferrer">
                            App Store de iOS
                        </a>
                        .
                    </Typography>

                    {factor?.configured ? (
                        <SecurityCardItem
                            icon={<QrCodeScannerOutlinedIcon />}
                            title={t('profile.security.yourAuthenticator')}
                            content={t('profile.security.authenticator')}
                            onConfigure={onConfigure}
                            confirmed={factor?.confirmed}
                            onConfirm={onConfirm}
                            warningText={t('profile.security.authenticatorConfirmedWarning')}
                            onDelete={() => onDelete({ method: 'authenticator' })}
                        />
                    ) : (
                        <Stack direction="row" justifyContent="flex-start">
                            <ActionButton
                                disabled={pending}
                                color="primary"
                                type="submit"
                                autoFocus
                                fitWidth={true}
                                spinner={pending}
                                onClick={onConfigure}
                            >
                                {t('profile.security.configureAuthenticator')}
                            </ActionButton>
                        </Stack>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default Authenticator;
