import { FormControlLabel, FormGroup, FormLabel, Switch } from '@mui/material';
import useStylesItemSettings from './styles/useStylesItemSettings.styles';

const ItemSettings = (props) => {
    const { onChange, label, value, keySettings, name, paddingBottom, disabled = false } = props;
    const { classes, cx } = useStylesItemSettings();

    return (
        <div className={cx(paddingBottom && classes.paddingBottom)}>
            <FormGroup>
                <FormControlLabel
                    disabled={disabled}
                    control={
                        <Switch
                            checked={value}
                            onChange={(e) => onChange(e, keySettings)}
                            name={name}
                            color={'primary'}
                        />
                    }
                    label={<FormLabel className={classes.text}>{label}</FormLabel>}
                />
            </FormGroup>
        </div>
    );
};

export default ItemSettings;
