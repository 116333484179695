import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Badge, CircularProgress, Typography } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import HandlebarsCodeInputMonacoEditor from 'common/components/code-editor/HandlebarsCodeInputMonacoEditor';
import useFlexBoxStyles from 'common/flexBox';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUpdateWordsBagAction,
    fetchWordsBagAction,
    getWordsBag,
    getWordsBagPending,
} from 'redux/slices/settingSlice';

const ChatEditWordsBag = () => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const dispatch = useDispatch();
    const pending = useSelector(getWordsBagPending);
    const currentBag = useSelector(getWordsBag);
    const [content, setContent] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!currentBag) {
            dispatch(fetchWordsBagAction());
        } else {
            setContent(currentBag.join('\n'));
        }
    }, [currentBag, currentBag?.length, dispatch]);

    const handleOnChange = (event) => {
        let file = event?.target?.files[0] || null;
        if (file) {
            if ('text/plain'.includes(file.type.toLowerCase())) {
                file.text().then((text) => {
                    let textIn = text.split('\n');
                    let oldText = content.split('\n');
                    let newArr = [...new Set([...oldText, ...textIn])];
                    setContent(newArr.join('\n'));
                });
            } else {
                enqueueSnackbar(t('media.unexpectedFormat'), {
                    variant: 'error',
                });
            }
        }
    };

    const handleDownloadFile = () => {
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'WordsBag');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    };

    const submit = (e) => {
        e.preventDefault();
        const array = content.split('\n');
        dispatch(createUpdateWordsBagAction({ data: array }));
    };

    return (
        <div className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden)}>
            <div
                className={cx(
                    flexBox.flex,
                    flexBox.gap2,
                    flexBox.column,
                    flexBox.overflowXHidden,
                    flexBox.scrollbarNone,
                )}
            >
                <Typography color="primary" className={flexBox.opacity07}>
                    {t('setting.chat.recommendedWordsBag')}
                </Typography>
                <div className={cx(flexBox.flex, flexBox.gap1, flexBox.justifyContentEnd)}>
                    <input
                        accept={'text/plain'}
                        style={{ display: 'none' }}
                        id={'button-file-words-bag'}
                        type="file"
                        onChange={handleOnChange}
                        hidden
                        value={''}
                    />

                    <CustomTooltip arrow title={t('Cargar nube de palabras')} placement={'bottom'}>
                        <CustomIconButton size="medium" htmlFor="button-file-words-bag" component="label">
                            <FileUploadOutlinedIcon />
                        </CustomIconButton>
                    </CustomTooltip>

                    <CustomTooltip arrow title={t('Descargar nube de palabras')} placement={'bottom'}>
                        <CustomIconButton size="medium" component="label" onClick={handleDownloadFile}>
                            <CloudDownloadOutlinedIcon />
                        </CustomIconButton>
                    </CustomTooltip>

                    <CustomTooltip arrow title={t('Guardar cambios')} placement={'bottom'}>
                        <CustomIconButton
                            size="medium"
                            disabled={currentBag?.join('\n') === content}
                            onClick={(e) => submit(e)}
                        >
                            <Badge variant="dot" invisible={currentBag?.join('\n') === content} color="primary">
                                <SaveOutlinedIcon component="svg" />
                            </Badge>
                        </CustomIconButton>
                    </CustomTooltip>
                </div>

                <div className={cx(flexBox.w100, flexBox.h100, flexBox.relative)}>
                    <HandlebarsCodeInputMonacoEditor
                        value={content}
                        height="200px"
                        width="100%"
                        onChange={(value) => {
                            setContent(value);
                        }}
                    >
                        {pending && (
                            <div className={flexBox.absoluteCentered}>
                                <CircularProgress />
                            </div>
                        )}
                    </HandlebarsCodeInputMonacoEditor>
                </div>
            </div>
        </div>
    );
};

export default ChatEditWordsBag;
