import axios from 'axios';
import { ErrorCode } from 'common/constants/errorCodes';
import { isValidHttpUrl } from 'common/utils/utils';
import config from 'config';
import { logoutAction, refreshToken } from 'redux/slices/authSlice';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import store from '../redux';

const instance = axios.create({
    baseURL: config.api.baseURL,
    headers: {
        // Authorization: `Bearer ${getLocalIdToken()}`,
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    withCredentials: true,
});

let isRefreshing = false;
let isLoggingOut = false;
let failedQueue = [];

const processQueue = (error) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

const logout = () => {
    store.dispatch(
        logoutAction({
            success: () => (window.location.href = window.location.origin + '/login'),
            fail: () => (window.location.href = window.location.origin + '/login?forceLogout=true'),
            tokenCsrf: store.getState().auth.tokenCsrf,
        }),
    );
};

function createAxiosRequestInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use((conf) => {
        let headers = conf.headers;
        if (!headers) {
            conf.headers = headers = {};
        }
        headers['x-onlive-organization-id'] = store.getState().organization.organization.id;

        return conf;
    });
}

function createAxiosResponseInterceptor(axiosInstance, UNAUTHORIZED_CODES, notification = false) {
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (isLoggingOut) {
                return Promise.reject(new Error('Session expired...'));
            }
            const originalConfig = error.config;

            if (UNAUTHORIZED_CODES.includes(error.response?.status) && !originalConfig._retry) {
                if (error.response.data?.code === ErrorCode.InvalidState) {
                    window.location.href = window.location.origin + '/login';
                }
                if (error.response.data?.code === ErrorCode.LicenseRevoked) {
                    logout();
                }
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then(() => {
                            return axios(originalConfig);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }

                originalConfig._retry = true;
                isRefreshing = true;
                return new Promise((resolve, reject) => {
                    refreshToken(store.getState().auth.tokenCsrf, store.dispatch)
                        .then((userData) => {
                            return resolve(axios(originalConfig));
                        })
                        .then(() => processQueue(null))
                        .catch((err) => {
                            processQueue(err);
                            reject(err);
                            isLoggingOut = true;
                            logout();
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }

            if (error.response?.status === 303 && isValidHttpUrl(error.response?.data?.description) && !notification) {
                window.location.href = error.response?.data?.description;
            }

            return Promise.reject(error);
        },
    );
}

createAxiosResponseInterceptor(instance, [401]);
createAxiosRequestInterceptor(instance);

export default instance;

export const shoppableApi = axios.create({
    baseURL: config.shoppable.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(shoppableApi, [401, 403]);
createAxiosRequestInterceptor(shoppableApi);

export const ShoppableService = {
    find: (params) => from(shoppableApi.get('/', { params })).pipe(map(({ data }) => data)),
    findPublic: ({ id, ...params }) =>
        from(shoppableApi.get(`/organization/${id}`, { params })).pipe(map(({ data }) => data)),
};

export const VodApi = axios.create({
    baseURL: config.helpApi.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(VodApi, [401, 403]);
createAxiosRequestInterceptor(VodApi);

export const streamingGateway = axios.create({
    baseURL: config.streaming.baseURL,
    withCredentials: true,
});

export const StreamingService = {
    getConnectors: () => from(streamingGateway.get('connectors')).pipe(map(({ data }) => data)),

    getAuthorizationUrlFor: (provider) =>
        from(streamingGateway.get(`${provider}/authorization`)).pipe(
            map(({ data }) => data),
            catchError(() => of(null)),
        ),

    processOAuthCallback: (params) =>
        from(streamingGateway.get('oauth/callback', { params })).pipe(map(({ data }) => data)),
};

createAxiosResponseInterceptor(streamingGateway, [401, 403]);
createAxiosRequestInterceptor(streamingGateway);

export const BffApi = axios.create({
    baseURL: config.bff.baseURL,
    withCredentials: true,
});

export const calendarApi = axios.create({
    baseURL: config.calendarApi.baseURL,
    withCredentials: true,
});

createAxiosRequestInterceptor(calendarApi);
createAxiosResponseInterceptor(calendarApi, [401, 403]);

export const calendarApiForNotification = axios.create({
    baseURL: config.calendarApi.baseURL,
    withCredentials: true,
});

createAxiosRequestInterceptor(calendarApiForNotification);
createAxiosResponseInterceptor(calendarApiForNotification, [401, 403], true);

export const communicationApi = axios.create({
    baseURL: config.communicationApi.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(communicationApi, [401, 403]);
createAxiosRequestInterceptor(communicationApi);

export const calendarApiV3 = axios.create({
    baseURL: config.calendarApiV3.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(calendarApiV3, [401, 403]);
createAxiosRequestInterceptor(calendarApiV3);

export const genaiHubApi = axios.create({
    baseURL: config.genaiHub.api.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(genaiHubApi, [401, 403]);
createAxiosRequestInterceptor(genaiHubApi);

export const leadApi = axios.create({
    baseURL: config.leadApi.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(leadApi, [401, 403]);
createAxiosRequestInterceptor(leadApi);

export const accountsApi = axios.create({
    baseURL: config.accounts.baseURL,
    withCredentials: true,
});

createAxiosResponseInterceptor(accountsApi, [401, 403]);
createAxiosRequestInterceptor(accountsApi);
