import { makeStyles } from 'tss-react/mui';

const useStylesViewerExtraFields = makeStyles()((theme) => {
    return {
        scroll: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            height: 'calc(100% - 55px)',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },

        optionsRow: {
            display: 'flex',
            alignItems: 'center',
        },

        buttonStyle: {
            paddingRight: '4px',
            borderRadius: '8px',
            fontSize: '0.7rem',
            width: '100%',
            height: '38px',
            textTransform: 'none',
            fontWeight: '500',
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xl')]: {
                height: '42px',
                borderRadius: theme.custom.borderRadiusLarge,
                fontSize: '14px',
                fontWeight: '600',
            },
        },

        addButton: {
            fontWeight: '500',
            '&:hover': {
                backgroundColor: 'red',
                color: '#fff',
                fontWeight: '600',
            },
            color: '#3283D3',
            [theme.breakpoints.up('xl')]: {
                fontWeight: '600',
                '&:hover': {
                    fontWeight: '700',
                },
            },
        },

        addOption: {
            width: '100px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #00000021',
            borderRadius: '10px',
            opacity: '1',
            fontWeight: '500',
            '&:hover': {
                backgroundColor: 'red',
                color: '#fff',
                fontWeight: '600',
            },
            color: '#3283D3',
            [theme.breakpoints.up('xl')]: {
                fontWeight: '600',
                '&:hover': {
                    fontWeight: '700',
                },
            },
        },

        saveButton: {
            color: '#FFF',
            fontWeight: '600',
            [theme.breakpoints.up('xl')]: {
                fontWeight: '600',
            },
        },

        positionEnd: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
        },

        fieldOption: {
            width: 'fit-content',
            wordBreak: 'break-word',
        },

        fieldRowSpan: {
            fontSize: '0.75rem',
            fontFamily: 'Montserrat',
            lineHeight: '1.5',
        },

        productForm: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            height: '100%',
        },

        fieldContent: {
            background: ' #FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #00000021',
            borderRadius: '10px',
        },

        mtNeg10px: {
            marginTop: '-10px',
        },

        p10px: {
            padding: '10px',
        },

        pb10px: {
            paddingBottom: '10px',
        },

        mlr10px: {
            margin: '0px 10px 0px',
        },

        pt10px: {
            paddingTop: '10px',
        },

        mb20px: {
            marginBottom: '20px',
        },

        varOptText: {
            fontWeight: 'bold',
        },

        editfield: {
            borderRadius: '10px 10px 0px 0px',
            background: 'red',
            color: '#FFFFFF',
        },

        divider: {
            marginLeft: '12px',
            marginRight: '4px',
            height: '20px',
            [theme.breakpoints.up('xl')]: {
                height: '24px',
                marginLeft: '15px',
                marginRight: '5px',
            },
        },

        dividerColor: {
            background: '#FFFFFF',
        },

        autocomplete: {
            width: 'auto !important',
            '& .MuiAutocomplete-inputRoot': {
                padding: '4px !important',
                height: '36px',
            },

            '& .MuiChip-root': {
                borderRadius: '5px',
                border: '1px solid #00000021',
                cursor: 'pointer',
                height: '26px',
                /* margin: 2px; */
                display: 'flex',
                fontSize: '12px',
                background: '#F5F5F5',
                '& svg': {
                    width: '14px',
                    color: 'gray',
                },
            },
        },

        descriptionError: {
            '& > div': {
                border: '1px solid red !important',
            },
        },

        actionsIcons: {
            '& .MuiSvgIcon-root': {
                fontSize: '18px !important',
                color: 'black',
            },
        },

        contentWidth: {
            width: '50%',
        },

        addPosition: {
            display: 'flex',
            justifyContent: 'flex-end',
        },

        mr0px: {
            marginRight: '0px !important',
        },

        check: {
            display: 'flex',
            width: '100%',
            margin: '0 !important',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                color: `${theme.palette.primary.main} !important`,
                fontSize: '1.18rem !important',
            },
            '& .MuiFormControlLabel-root': {
                margin: '0 !important',
            },
            '& .MuiButtonBase-root': {
                padding: '0 !important',
            },
        },

        checkDisable: {
            '& .MuiSvgIcon-root': {
                color: `${theme.palette.action.disabled} !important`,
            },
        },
    };
});

export default useStylesViewerExtraFields;
