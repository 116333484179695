import { createSlice } from '@reduxjs/toolkit';

import { calendarApiV3 as api } from 'api';
import gatewayApi from 'api/gateway';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState: {
        pending: false,
        savePending: false,
        appointments: [],
        appointment: null,
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 1,
        currentPage: 1,
        totalPages: 1,
        pendingDownload: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addAppointment: (state, action) => {
            state.savePending = false;
            state.appointments = [action.payload, ...state.appointments];
        },
        editAppointment: (state, action) => {
            state.savePending = false;
            const index = state.appointments.findIndex((appointment) => appointment.id === action.payload.id);
            if (index !== -1) {
                state.appointments = [
                    ...state.appointments.slice(0, index),
                    { ...state.appointments[index], ...action.payload },
                    ...state.appointments.slice(index + 1),
                ];
            }
        },
        deleteAppointment: (state, action) => {
            state.deletePending = false;
            const index = state.appointments.findIndex((appointment) => appointment.id === action.payload.id);
            if (index !== -1) {
                state.appointments = [...state.appointments.slice(0, index), ...state.appointments.slice(index + 1)];
            }
        },
        setAppointments: (state, action) => {
            state.appointments = action.payload.items;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.pending = false;
        },
        setAppointment: (state, action) => {
            state.appointment = action.payload;
            state.pending = false;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDownload: (state, action) => {
            state.pendingDownload = true;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
        },
    },
});

export const {
    start,
    stop,
    setAppointments,
    setAppointment,
    stopDelete,
    setError,
    addAppointment,
    editAppointment,
    deleteAppointment,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
    startDownload,
    stopDownload,
} = appointmentSlice.actions;

export const getAppointments = (state) => state.appointment.appointments;
export const getAppointmentsCurrentPage = (state) => state.appointment.currentPage;
export const getAppointmentsTotal = (state) => state.appointment.total;
export const getAppointmentsTotalPages = (state) => state.appointment.totalPages;
export const getAppointmentsPending = (state) => state.appointment.pending;
export const getAppointmentsSavePending = (state) => state.appointment.savePending;
export const getDeletePending = (state) => state.appointment.deletePending;
export const getDeleteId = (state) => state.appointment.deleteId;
export const getAppointmentsError = (state) => state.appointment.error;
export const getAppointment = (state) => state.appointment.appointment;

export const fetchAppointmentsByCalendarAction =
    ({ calendarId, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get(`calendars/${calendarId}/appointments` + query)
                .then((response) => {
                    dispatch(setAppointments(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const reassignCalendarAppointmentsAction =
    ({ queryParams, reassignDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.post(`appointments/reassign-calendar` + query, reassignDto)
                .then((response) => {
                    dispatch(stop());
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };
export const reassignAgentAppointmentsAction =
    ({ queryParams, reassignDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.post(`appointments/reassign-agent` + query, reassignDto)
                .then((response) => {
                    dispatch(stop());
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchAppointmentsAction =
    ({ queryParams, onSuccess, onFailure, set = true } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            if (set) {
                dispatch(start());
            }
            api.get(`appointments` + query)
                .then((response) => {
                    if (set) {
                        dispatch(setAppointments(response.data));
                    }
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (set) {
                        dispatch(setError(error));
                    }
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            if (set) {
                dispatch(stop());
            }
            return console.error(e.message);
        }
    };

export const addAppointmentAction =
    ({ calendarId, appointmentDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(`calendars/${calendarId}/appointments`, appointmentDto)
                .then((response) => {
                    dispatch(addAppointment(response?.data));
                    onSuccess?.(response?.data);

                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const getAppointmentAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get('appointments/' + id)
                .then((response) => {
                    dispatch(setAppointment(response.data));
                    if (onSuccess) {
                        onSuccess(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const getAppointmentVotesAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            api.get(`appointments/${id}/votes`)
                .then((response) => {
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    onFailure?.(error);
                });
        } catch (e) {
            return console.error('error', e);
        }
    };

export const editAppointmentAction =
    ({ id, appointmentDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('appointments/' + id, appointmentDto)
                .then((response) => {
                    dispatch(editAppointment(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteAppointmentAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete('appointments/' + id)
                .then((response) => {
                    dispatch(deleteAppointment(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error('error', e);
        }
    };

export const fetchAppointmentsReportAction =
    ({ param, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            const { organizationId } = param;
            dispatch(startDownload());
            api.get(`${process.env.REACT_APP_API_URL}/auth/cube-token`)
                .then((data) => {
                    return gatewayApi
                        .get(`report/appointments/${organizationId}`, {
                            params: queryParams,
                            headers: {
                                'Cube-Authorization': data.data.token,
                                ...(queryParams?.format === 'xlsx'
                                    ? { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
                                    : {}),
                            },
                            ...(queryParams?.format === 'xlsx' ? { responseType: 'arraybuffer' } : {}),
                        })
                        .then((response) => {
                            if (onSuccess) {
                                const fileName = response.headers['content-disposition'].split('filename=')[1];
                                onSuccess(response.data, fileName);
                            }
                            dispatch(stopDownload());
                            return response;
                        })
                        .catch((error) => {
                            if (onFailure) {
                                onFailure(error);
                            }
                        });
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopDownload());
            return console.error(e.message);
        }
    };

export default appointmentSlice.reducer;
