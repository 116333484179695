import { Check, ForwardToInbox, MarkEmailRead, Star, StarOutline, Sync, Unsubscribe } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import InputAdornment from '@mui/material/InputAdornment';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
    addEmailIdentityAction,
    deleteEmailIdentityAction,
    emailIdentityCheckVerificationStatusAction,
    emailIdentityResendVerificationAction,
    emailIdentitySetAsDefaultAction,
} from 'redux/slices/organizationSlice';

const EmailIdentity = (props) => {
    const { identity, onAdded, onDeleted, onMarkAsDefault, onStatusCheck } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState(identity.name);
    const [verified, setVerified] = useState(identity.status === 'SUCCESS');
    const [isDefault, setIsDefault] = useState(identity.default);
    const [tempEmail, setTempEmail] = useState(identity.name);
    const [pending, setPending] = useState(false);
    const [valid, setValid] = useState(false);
    const { classes: flexBox, cx } = useFlexBoxStyles();

    const handleValidChange = useCallback((validParam) => {
        setValid(validParam);
    }, []);

    const handleAdd = useCallback(
        (email) => {
            setPending(true);
            dispatch(
                addEmailIdentityAction(
                    tempEmail,
                    (identity) => {
                        setPending(false);
                        setEmail(identity.name);
                        setIsDefault(identity.default);
                        setVerified(identity.status === 'SUCCESS');
                        onAdded(identity);
                        enqueueSnackbar(t('profile.settings.emailsSettings.emailSettings.emailIdentityAdded'), {
                            variant: 'success',
                        });
                    },
                    () => {
                        setPending(false);
                        enqueueSnackbar(t('profile.settings.emailsSettings.emailSettings.failedToAddEmailIdentity'), {
                            variant: 'error',
                        });
                    },
                ),
            );
        },
        [dispatch, onAdded, setPending, tempEmail, enqueueSnackbar, t],
    );

    const handleResendVerification = useCallback(
        (email) => {
            setPending(true);
            dispatch(
                emailIdentityResendVerificationAction(
                    tempEmail,
                    (identity) => {
                        setPending(false);
                        enqueueSnackbar(
                            t('profile.settings.emailsSettings.emailSettings.emailIdentityVerificationForwarded'),
                            { variant: 'success' },
                        );
                    },
                    () => {
                        setPending(false);
                        enqueueSnackbar(
                            t('profile.settings.emailsSettings.emailSettings.failedToResendEmailIdentityVerification'),
                            { variant: 'error' },
                        );
                    },
                ),
            );
        },
        [dispatch, setPending, tempEmail, enqueueSnackbar, t],
    );

    const handleDelete = useCallback(() => {
        setPending(true);
        dispatch(
            deleteEmailIdentityAction(
                tempEmail,
                (identity) => {
                    setPending(false);
                    onDeleted(identity);
                    enqueueSnackbar(t('profile.settings.emailsSettings.emailSettings.emailIdentityDeleted'), {
                        variant: 'success',
                    });
                },
                () => {
                    setPending(false);
                    enqueueSnackbar(t('profile.settings.emailsSettings.emailSettings.failedToDeleteEmailIdentity'), {
                        variant: 'error',
                    });
                },
            ),
        );
    }, [dispatch, onDeleted, setPending, tempEmail, enqueueSnackbar, t]);

    const handleCheckVerifyStatus = useCallback(() => {
        setPending(true);
        dispatch(
            emailIdentityCheckVerificationStatusAction(
                tempEmail,
                (identity) => {
                    setPending(false);
                    setIsDefault(identity.default);
                    setVerified(identity.status === 'SUCCESS');
                    onStatusCheck(identity);
                    enqueueSnackbar(t('profile.settings.emailsSettings.emailSettings.emailIdentityStatusVerified'), {
                        variant: 'success',
                    });
                },
                () => {
                    setPending(false);
                    enqueueSnackbar(
                        t('profile.settings.emailsSettings.emailSettings.failedToVerifyEmailIdentityStatus'),
                        { variant: 'error' },
                    );
                },
            ),
        );
    }, [dispatch, onStatusCheck, setPending, tempEmail, enqueueSnackbar, t]);

    const handleSetAsDefault = useCallback(() => {
        setPending(true);
        dispatch(
            emailIdentitySetAsDefaultAction(
                tempEmail,
                (identity) => {
                    setPending(false);
                    setIsDefault(identity.default);
                    onMarkAsDefault(identity);
                    enqueueSnackbar(t('profile.settings.emailsSettings.emailSettings.emailIdentityUpdatedAsDefault'), {
                        variant: 'success',
                    });
                },
                () => {
                    setPending(false);
                    enqueueSnackbar(
                        t('profile.settings.emailsSettings.emailSettings.failedToSetEmailIdentityAsDefault'),
                        { variant: 'error' },
                    );
                },
            ),
        );
    }, [dispatch, onMarkAsDefault, setPending, tempEmail, enqueueSnackbar, t]);

    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: '100%', marginTop: '.5rem' }}
            >
                <CustomTextValidator
                    value={tempEmail}
                    variant="outlined"
                    onChange={(e) => setTempEmail(e.target.value)}
                    fullWidth={true}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                        t('app.common.required'),
                        t('profile.settings.emailsSettings.emailSettings.invalidEmail'),
                    ]}
                    placeholder={t('profile.settings.emailsSettings.emailSettings.emailIdentity')}
                    disabled={!!email || pending}
                    validatorListener={handleValidChange}
                    InputProps={
                        verified
                            ? {
                                  style: {
                                      width: '100%',
                                      fontSize: '11px',
                                      ...(verified ? { border: '1px solid #4caf50' } : {}),
                                  },
                                  endAdornment: (
                                      <InputAdornment position="start">
                                          <CustomTooltip
                                              arrow
                                              placement={'bottom'}
                                              title={
                                                  verified
                                                      ? t(
                                                            'profile.settings.emailsSettings.emailSettings.verifiedIdentity',
                                                        )
                                                      : t(
                                                            'profile.settings.emailsSettings.emailSettings.unVerifiedIdentity',
                                                        )
                                              }
                                          >
                                              <Check component="svg" style={{ color: '#4caf50' }} />
                                          </CustomTooltip>
                                      </InputAdornment>
                                  ),
                              }
                            : {}
                    }
                ></CustomTextValidator>
                <Box className={cx(flexBox.flex, flexBox.gap05)}>
                    {!email && (
                        <CustomTooltip
                            arrow
                            title={t('profile.settings.emailsSettings.emailSettings.addEmailIdentity')}
                            placement={'bottom'}
                        >
                            <CustomIconButton onClick={handleAdd} disabled={!valid} hideBorder={true}>
                                <ForwardToInbox component="svg" />
                            </CustomIconButton>
                        </CustomTooltip>
                    )}
                    {!!email && (
                        <CustomTooltip
                            arrow
                            title={t('profile.settings.emailsSettings.emailSettings.deleteEmailIdentity')}
                            placement={'bottom'}
                        >
                            <CustomIconButton onClick={handleDelete} hideBorder={true}>
                                <Unsubscribe component="svg" />
                            </CustomIconButton>
                        </CustomTooltip>
                    )}
                    {!!email && !verified && (
                        <CustomTooltip
                            arrow
                            title={t('profile.settings.emailsSettings.emailSettings.resendEmailIdentityVerification')}
                            placement={'bottom'}
                        >
                            <CustomIconButton onClick={handleResendVerification} hideBorder={true}>
                                <Sync component="svg" />
                            </CustomIconButton>
                        </CustomTooltip>
                    )}
                    {!!email && !verified && (
                        <CustomTooltip
                            arrow
                            title={t('profile.settings.emailsSettings.emailSettings.checkEmailIdentityStatus')}
                            placement={'bottom'}
                        >
                            <CustomIconButton
                                onClick={handleCheckVerifyStatus}
                                disabled={!email || verified || pending}
                                hideBorder={true}
                            >
                                <MarkEmailRead component="svg" />
                            </CustomIconButton>
                        </CustomTooltip>
                    )}
                    {!!email && (
                        <CustomTooltip
                            arrow
                            title={t('profile.settings.emailsSettings.emailSettings.markDefaultEmailIdentity')}
                            placement={'bottom'}
                        >
                            <CustomIconButton
                                onClick={handleSetAsDefault}
                                hideBorder={true}
                                disabled={pending || isDefault}
                            >
                                {isDefault ? <Star component="svg" /> : <StarOutline component="svg" />}
                            </CustomIconButton>
                        </CustomTooltip>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default EmailIdentity;
