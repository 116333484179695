import { Button } from '@mui/material';
import useStylesTextButton from './styles/useStylesTextButton.styles';
const TextButton = (props) => {
    const { children, color, size, ...rest } = props;

    const { classes } = useStylesTextButton();
    let colorClass = classes.default;
    if (color === 'primary') {
        colorClass = classes.primary;
    }
    if (color === 'secondary') {
        colorClass = classes.secondary;
    }
    if (size === 'big') {
        colorClass += ` ${classes.big}`;
    }
    return (
        <Button className={classes.root + (colorClass ? ' ' + colorClass : '')} variant="text" {...rest}>
            {children}
        </Button>
    );
};

export default TextButton;
