import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Accordion,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    TextField,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import TextButton from 'common/components/TextButton';
import CssCodeInputMonacoEditor from 'common/components/code-editor/CssCodeInputMonacoEditor';
import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import { isValidHttpUrl } from 'common/validators/validators';
import config from 'config';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { testFeatureEnabled } from 'redux/slices/authSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';

const WidgetOneToMany = (props) => {
    const {
        widgetState,
        toggleChange,
        handleSubmit,
        hasChanges,
        widgetLandingVersion,
        widgetIternalBrowserTv,
        channelSubscriptionEnable,
        clientBaseUrl,
        customStylesTVWidget,
        rulesTVWidget,
        optionsTVWidget,
    } = props;
    const { t } = useTranslation();

    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };
    const [inputError, setInputError] = useState({
        rules_tv_widget: null,
        options_tv_widget: null,
        custom_styles_tv_widget: null,
    });
    const organization = useSelector(getOrganization);
    const settingsDirty = useSelector(getSettingsDirty);

    const { enqueueSnackbar } = useSnackbar();
    const showTestFeature = useSelector(testFeatureEnabled);

    const widgetTvCode = `
    <div id="onlive-tv-widget"></div>
    <script>
        (function (w, d, s, o, f, js, fjs) {
            w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }(window, document, "script", "_hw", "${config.widgets.widgetOneToManyCDN}"));
        _hw("init", { 
            debug: true, 
            embed: true, 
            organizationId: "${organization.id}", 
            serviceBaseUrl: "${config.api.baseURL}" });
    </script>
    `;

    const widgetLandingCode =
        widgetLandingVersion === 'v3'
            ? `<script>
    (function (o, n, l, i, v, e) {
     v = o.createElement(n);v.onload = i;v.async = 1;v.src = l;
     e = o.getElementsByTagName(n)[0];e.parentNode.insertBefore(v, e);
    })(document, 'script', '${config.widgets.widgetOneToOneCDNV3}', function () {
         window.onliveManager.loader.load("${organization.id}");
      });
</script>`
            : `
    <div id="onlive-landing"></div>
    <script>
        (function (w, d, s, o, f, js, fjs) {
            w.onliveWidgets = w.onliveWidgets || {};
            w['Onlive-Widget-tv'] = w.onliveWidgets['Onlive-Widget-tv'] = o;
            w[o] =
                w[o] ||
                function () {
                    (w[o].q = w[o].q || []).push(arguments);
                };
            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
            js.id = o;
            js.src = f;
            js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
        })(window, document, 'script', 'onliveWtv', "${config.widgets.widgetOneToOneCDNV2}");
        onliveWtv('init', {
            landing: true,
            targetElementId: 'onlive-landing',
            organizationId: "${organization.id}",
        });
    </script>
    `;
    const handleValueChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };
    const validBaseUrl = () => {
        return isValidHttpUrl(clientBaseUrl) || clientBaseUrl === '' || !clientBaseUrl;
    };

    const handleValueChangeCode = (value, settingName, errors) => {
        const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
        const error2Input = codeHaveErrors ? errors : null;

        setInputError({ ...inputError, [settingName]: error2Input });
        toggleChange(settingName, value);
    };

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const handleVersionChanged = (version) => {
        toggleChange(SettingsKey.TV_WIDGET_VERSION, version);
    };

    const showSaveButton = () =>
        settingsDirty &&
        hasChanges &&
        validBaseUrl() &&
        inputError.options_tv_widget === null &&
        inputError.rules_tv_widget === null &&
        inputError.custom_styles_tv_widget === null;

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.widgetOneToManySettings.widgetOneToMany.tvChannel')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    {/*<Typography className={classes.text}>{t("profile.settings.widgetOneToManySettings.widgetOneToMany.setUse")}</Typography>*/}

                    <ItemSettings
                        label={t('profile.settings.widgetOneToManySettings.widgetOneToMany.setUse')}
                        onChange={handleCheckChange}
                        name={'widgetTv'}
                        keySettings={SettingsKey.ENABLE_TV_WIDGET}
                        value={widgetState}
                    />

                    <Box sx={{ display: showSaveButton() ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('profile.settings.widgetOneToManySettings.widgetOneToMany.save')}
                        </Button>
                    </Box>
                </Box>
                {widgetState && (
                    <Fragment>
                        <ItemSettings
                            label={t('settings.widgetOneToMany.channelSubscriptionEnable')}
                            onChange={handleCheckChange}
                            name={'channelSubscriptionEnable'}
                            keySettings={SettingsKey.ENABLE_WIDGET_CHANNEL_SUBSCRIPTION}
                            value={channelSubscriptionEnable}
                            paddingBottom
                        />

                        {showTestFeature && (
                            <Fragment>
                                <Typography className={classes.text}>
                                    {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.setVersion')}
                                </Typography>
                                <FormGroup className={classes.reactionRow}>
                                    <FormControlLabel
                                        key="center"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetLandingVersion === 'v1'}
                                                onChange={() => handleVersionChanged('v1')}
                                                name="reactionNumber"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={<div className={classes.text}>{t('version1')}</div>}
                                    />
                                    <FormControlLabel
                                        key="right-1"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetLandingVersion === 'v2'}
                                                onChange={() => handleVersionChanged('v2')}
                                                name="reactionNumber"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={<div className={classes.text}>{t('version2')}</div>}
                                    />
                                    <FormControlLabel
                                        key="right-2"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetLandingVersion === 'v3'}
                                                onChange={() => handleVersionChanged('v3')}
                                                name="reactionNumber"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={<div className={classes.text}>{t('version3')}</div>}
                                    />
                                </FormGroup>
                            </Fragment>
                        )}
                        <React.Fragment>
                            {widgetLandingVersion === 'v2' && (
                                <div style={{ paddingBottom: '20px', marginTop: '5px' }}>
                                    <Typography className={classes.text}>
                                        {t('profile.settings.seoSettings.setBaseUrl')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ width: '100%' }}
                                    >
                                        <Box style={{ width: '50%' }}>
                                            <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                                <FormControl>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        color={validBaseUrl() ? 'primary' : 'red'}
                                                        size="small"
                                                        label={t('profile.settings.seoSettings.baseUrl')}
                                                        variant="outlined"
                                                        value={clientBaseUrl}
                                                        onChange={(e) =>
                                                            handleValueChange(e, SettingsKey.SEO_CLIENT_BASE_URL)
                                                        }
                                                    />
                                                    <FormHelperText error={!validBaseUrl()}>
                                                        {!validBaseUrl() && t('profile.settings.urlValidationError')}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                            )}

                            {['v2', 'v3'].includes(widgetLandingVersion) && (
                                <ItemSettings
                                    label={t(
                                        'profile.settings.widgetOneToManySettings.widgetOneToMany.enableInternalBrowser',
                                    )}
                                    onChange={handleCheckChange}
                                    name={'widgetIternalBrowserTv'}
                                    keySettings={SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_TV_WIDGET}
                                    value={widgetIternalBrowserTv}
                                    paddingBottom
                                />
                            )}
                        </React.Fragment>

                        <Typography className={classes.text}>
                            {t('profile.settings.widgetOneToManySettings.widgetOneToMany.code')}
                        </Typography>
                        <div className={classes.codeBox}>
                            <pre>
                                <code>{widgetLandingVersion === 'v1' ? widgetTvCode : widgetLandingCode}</code>
                            </pre>
                            <CopyToClipboard text={widgetLandingVersion === 'v1' ? widgetTvCode : widgetLandingCode}>
                                <TextButton
                                    color="secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        enqueueSnackbar(
                                            t('profile.settings.widgetOneToManySettings.widgetOneToMany.codeCopied'),
                                            { variant: 'success' },
                                        );
                                    }}
                                >
                                    {t('profile.settings.widgetOneToManySettings.widgetOneToMany.copyCode')}
                                </TextButton>
                            </CopyToClipboard>
                        </div>

                        {
                            /*widgetLandingVersion === 'v3'*/ false && (
                                <React.Fragment>
                                    <FormLabel className={classes.title}>{t('installationSettings')}</FormLabel>

                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel>{t('Rules (JSON)')}</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            <JsonCodeInputMonacoEditor
                                                name="rules"
                                                value={rulesTVWidget}
                                                onChange={(value, errors) =>
                                                    handleValueChangeCode(value, SettingsKey.RULES_TV_WIDGET, errors)
                                                }
                                            />
                                            <SimpleCodeErrorView
                                                id={SettingsKey.RULES_TV_WIDGET}
                                                mainErrorMessage={t('JsonError')}
                                                errorList={inputError[SettingsKey.RULES_TV_WIDGET]}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel>{t('Options (JSON)')}</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            <JsonCodeInputMonacoEditor
                                                name="options_tv_widget"
                                                value={optionsTVWidget}
                                                onChange={(value, errors) =>
                                                    handleValueChangeCode(value, SettingsKey.OPTIONS_TV_WIDGET, errors)
                                                }
                                            />
                                            <SimpleCodeErrorView
                                                id={SettingsKey.OPTIONS_TV_WIDGET}
                                                mainErrorMessage={t('JsonError')}
                                                errorList={inputError[SettingsKey.OPTIONS_TV_WIDGET]}
                                            />
                                        </FormControl>
                                    </FormGroup>

                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel>{t('Custom Styles (CSS)')}</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            <CssCodeInputMonacoEditor
                                                name="custom_styles_tv_widget"
                                                value={customStylesTVWidget}
                                                onChange={(value, errors) =>
                                                    handleValueChangeCode(
                                                        value,
                                                        SettingsKey.CUSTOM_STYLES_TV_WIDGET,
                                                        errors,
                                                    )
                                                }
                                            />
                                            <SimpleCodeErrorView
                                                id={SettingsKey.CUSTOM_STYLES_TV_WIDGET}
                                                mainErrorMessage={t('CssError')}
                                                errorList={inputError[SettingsKey.CUSTOM_STYLES_TV_WIDGET]}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </React.Fragment>
                            )
                        }
                    </Fragment>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default WidgetOneToMany;
