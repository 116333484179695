const DateRange = {
    ALL: 'all',
    CUSTOM: 'custom',
    TODAY: 'today',
    LAST_7_DAYS: 'last_7_days',
    LAST_30_DAYS: 'last_30_days',
    LAST_90_DAYS: 'last_90_days',
};

export default DateRange;
