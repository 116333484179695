import { Button, CircularProgress, Typography } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        position: 'relative',
        borderRadius: theme.spacing(3),
        height: theme.spacing(4),
        textTransform: 'uppercase',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '&:hover': {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up('xl')]: {
            borderRadius: theme.spacing(4),
            height: theme.spacing(4.5),
        },
        '&:disabled': {
            color: theme.palette.default.main,
        },
        '&:not([disabled])': {
            '&.primary': {
                color: theme.palette.getContrastText(theme.palette.background.red),
                backgroundColor: theme.palette.background.red,
                '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.common.black,
                },
            },

            '&.default': {
                border: theme.custom.blackBorder,
                color: theme.palette.common.black,
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                    color: theme.palette.common.black,
                    backgroundColor: '#DEDEDE',
                },
            },
            '&.secondary': {
                color: theme.palette.getContrastText(theme.palette.secondary.main),
                backgroundColor: theme.palette.secondary.main,
                border: theme.custom.darkBorder,
                '&:hover': {
                    border: theme.custom.darkBorder,
                    color: theme.palette.getContrastText(theme.palette.secondary.main),
                    backgroundColor: theme.palette.secondary.main,
                },
            },
            '&.transparent': {
                border: '1px solid #FFFFFF80',
                backgroundColor: '#FFFFFF78',
                '&:hover': {
                    backgroundColor: '#FFFFFF78',
                },
            },
        },
        '&.small': {
            height: theme.spacing(3),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(3.75),
            },
        },
    },
}));

const ActionButton = (props) => {
    const { children, color, variant, size, additionalClass, fitWidth, spinner, ...rest } = props;

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    return (
        <Button
            className={cx(classes.root, color, variant, size, !fitWidth && flexBox.w100, additionalClass)}
            variant="contained"
            disableElevation={true}
            {...rest}
        >
            {spinner && (
                <div
                    className={cx(
                        flexBox.zIndex2,
                        flexBox.w100,
                        flexBox.h100,
                        flexBox.flex,
                        flexBox.alignItemsCenter,
                        flexBox.justifyContentCenter,
                        flexBox.absolute,
                    )}
                >
                    <CircularProgress />
                </div>
            )}
            <Typography
                variant="subtitle2"
                fontWeight="bold"
                className={cx(flexBox.flex, flexBox.gap1, flexBox.alignItemsCenter, flexBox.padding01)}
            >
                {children}
            </Typography>
        </Button>
    );
};

export default ActionButton;
