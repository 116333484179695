import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AlertDialog from './AlertDialog';

/**
 * Selects images and/or videos according to the formats defined in the
 * imageFormats and videoFormats props.
 *
 * To select only one of these two types of file, define the other as empty.
 * Example: For images only, define videoFormat prop as [].
 * @param {*} param0
 * @returns
 */
const FileInput = ({
    uploadAssetFn,
    disabled,
    inputId,
    imageFormats = [],
    videoFormats = [],
    textFormats = [],
    customMimeTypes = [],
    customExtensions = [],
    // multiple
}) => {
    const [showWarning, setShowWarning] = useState(false);

    const inputRef = useRef(null);

    const { t } = useTranslation();

    const configFormats = [...imageFormats, ...videoFormats, ...textFormats, ...customExtensions];
    const warningMessage = `${t('media.expectedFormat')} ${configFormats.join(', ')}`;
    const allowedImageFileFormats = imageFormats.map((format) => `image/${format}`.toLocaleLowerCase());

    const allowedVideoFileFormats = videoFormats.map((format) => `video/${format}`.toLocaleLowerCase());

    const allowedTextFormats = textFormats.map((format) => `text/${format}`.toLocaleLowerCase());

    const allowedCustomExtensions = customExtensions.map((ext) => (`.${ext}`));

    const allowedFileFormats = [
        ...allowedImageFileFormats,
        ...allowedVideoFileFormats,
        ...allowedTextFormats,
        ...allowedCustomExtensions,
        ...customMimeTypes,
    ].join(',');

    const isAllowedFormat = useCallback(
        (fileObj) => {
            try {
                if (fileObj) {
                    return allowedFileFormats.includes(fileObj.type.toLowerCase());
                } else {
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },
        [allowedFileFormats],
    );

    const handleOnChange = (event) => {
        event.preventDefault();
        const file = event?.target?.files[0] || null;

        if (file) {
            if (isAllowedFormat(file)) {
                uploadAssetFn(file);
            } else {
                setShowWarning(true);
            }
        }
    };

    const clearInput = () => {
        inputRef.current.value = null;
    };

    useEffect(() => {
        return clearInput();
    }, []);

    return (
        <div>
            <input
                ref={inputRef}
                accept={allowedFileFormats}
                style={{ display: 'none' }}
                id={inputId}
                type="file"
                onChange={handleOnChange}
                hidden
                disabled={disabled}
                value={''}
                // multiple={multiple}
            />
            <AlertDialog
                openDialog={showWarning}
                title={t('media.unexpectedFormat')}
                messageToConfirm={warningMessage}
                onOkButtonClick={() => {
                    setShowWarning(false);
                }}
            />
        </div>
    );
};

export default FileInput;
