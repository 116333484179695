import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import useStylesFieldForm from 'app/profile/settings/styles/useStylesFieldForm.styles';
import useStylesSettings from 'app/profile/settings/styles/useStylesSettings.styles';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import OrganizationVerticalManagementSetting from './OrganizationVerticalManagementSetting';
import VspClusterRegionSetting from './VspClusterRegionSetting';

const NewFeaturesSettings = (props) => {
    const {
        enableGroups,
        enableProductUnit,
        instantSearch,
        enableLabels,
        newCalendar,
        toggleChange,
        handleSubmit,
        hasChanges,
        enableChime,
        widgetInternalBrowser,
        supportWidgetScript,
        newInstallations,
        installationsMenu,
        dashboardSummary,
        shareFiles,
        enableAircalls,
        enableProductDiscount,
        enableTvChannelManager,
        immersiveAppSizeLimit,
        enableFormManage,
        enableGenAI,
        enableSocialVideos,
        productCheck,
    } = props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);
    const user = useSelector(getUser);

    const isRoot = user.role === 'root';

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const handleChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const handleNumberValueChange = (event, settingName) => {
        toggleChange(settingName, +event.target.value);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('New features')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('Save changes')}
                        </Button>
                    </Box>
                </Box>

                <ItemSettings
                    label={t('Internal browser (only for cms)')}
                    onChange={handleCheckChange}
                    name={'widgetInternalBrowser'}
                    keySettings={SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER}
                    value={widgetInternalBrowser}
                    paddingBottom
                />

                {isRoot ? (
                    <>
                        <ItemSettings
                            label={t('settings.enableGroups')}
                            onChange={handleCheckChange}
                            name={'enableGroups'}
                            keySettings={SettingsKey.ENABLE_USER_GROUPS}
                            value={enableGroups}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('settings.enableProductUnit')}
                            onChange={handleCheckChange}
                            name={'enableProductUnit'}
                            keySettings={SettingsKey.ENABLE_PRODUCT_UNIT}
                            value={enableProductUnit}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('Instant search')}
                            onChange={handleCheckChange}
                            name={'instantSearch'}
                            keySettings={SettingsKey.INSTANT_SEARCH}
                            value={instantSearch}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('settings.enableLabels')}
                            onChange={handleCheckChange}
                            name={'enableLabels'}
                            keySettings={SettingsKey.ENABLE_LABELS}
                            value={enableLabels}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('New Calendar (only for cms)')}
                            onChange={handleCheckChange}
                            name={'newCalendar'}
                            keySettings={SettingsKey.NEW_CALENDAR}
                            value={newCalendar}
                            paddingBottom
                        />

                        <ItemSettings
                            label={'New installations'}
                            onChange={handleCheckChange}
                            name={'newInstallations'}
                            keySettings={SettingsKey.NEW_INSTALLATIONS}
                            value={newInstallations}
                            paddingBottom
                        />

                        <ItemSettings
                            label={'Show installations menu'}
                            onChange={handleCheckChange}
                            name={'installationsMenu'}
                            keySettings={SettingsKey.ENABLE_INSTALLATIONS_MENU}
                            value={installationsMenu}
                            paddingBottom
                        />

                        <ItemSettings
                            label={'Send dashboard summary emails'}
                            onChange={handleCheckChange}
                            name={'dashboardSummary'}
                            keySettings={SettingsKey.ENABLE_DASHBOARD_SUMMARY_EMAILS}
                            value={dashboardSummary}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('profile.settings.oneToOneSettings.enableChime')}
                            onChange={handleCheckChange}
                            name={'enableChime'}
                            keySettings={SettingsKey.ENABLE_CHIME}
                            value={enableChime}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('Share files (only for O2O)')}
                            onChange={handleCheckChange}
                            name={'shareFiles'}
                            keySettings={SettingsKey.SHARE_FILES}
                            value={shareFiles}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('Aircalls (only for O2O)')}
                            onChange={handleCheckChange}
                            name={'enableAircalls'}
                            keySettings={SettingsKey.ENABLE_AIRCALLS}
                            value={enableAircalls}
                            paddingBottom
                        />

                        {/* <ItemSettings
                            label={t('profile.settings.newFeaturesSettings.enableImmersive')}
                            onChange={handleCheckChange}
                            name={'enableImmersive'}
                            keySettings={SettingsKey.ENABLE_IMMERSIVE}
                            value={enableImmersive}
                            paddingBottom
                        /> */}

                        {/* <ItemSettings
                            label={t('profile.settings.newFeaturesSettings.hideSectionOneToMany')}
                            onChange={handleCheckChange}
                            name={'hideSectionOneToMany'}
                            keySettings={SettingsKey.HIDE_SECTION_ONE_TO_MANY}
                            value={hideSectionOneToMany}
                            paddingBottom
                        /> */}

                        {/* <ItemSettings
                            label={t('profile.settings.newFeaturesSettings.hideSectionOneToOne')}
                            onChange={handleCheckChange}
                            name={'hideSectionOneToOne'}
                            keySettings={SettingsKey.HIDE_SECTION_ONE_TO_ONE}
                            value={hideSectionOneToOne}
                            paddingBottom
                        /> */}

                        {/* <ItemSettings
                            label={t('profile.settings.newFeaturesSettings.showSectionLeads')}
                            onChange={handleCheckChange}
                            name={'showSectionLeads'}
                            keySettings={SettingsKey.SHOW_SECTION_LEADS}
                            value={showSectionLeads}
                            paddingBottom
                        /> */}

                        <ItemSettings
                            label={t('profile.settings.newFeaturesSettings.enableProductDiscount')}
                            onChange={handleCheckChange}
                            name={'enableProductDiscount'}
                            keySettings={SettingsKey.ENABLE_PRODUCT_DISCOUNT}
                            value={enableProductDiscount}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('settings.newFeaturesSettings.enableTvChannelManager')}
                            onChange={handleCheckChange}
                            name={'enableTvChannelManager'}
                            keySettings={SettingsKey.ENABLE_TV_CHANNEL_MANAGER}
                            value={enableTvChannelManager}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('settings.newFeaturesSettings.enableFormManage')}
                            onChange={handleCheckChange}
                            name={'enableFormManage'}
                            keySettings={SettingsKey.ENABLE_FORM_MANAGE}
                            value={enableFormManage}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('settings.newFeaturesSettings.enableGenAI')}
                            onChange={handleCheckChange}
                            name={'enableGenAI'}
                            keySettings={SettingsKey.ENABLE_GENAI}
                            value={enableGenAI}
                            paddingBottom
                        />

                        <ItemSettings
                            label={t('settings.newFeaturesSettings.enableSocialVideos')}
                            onChange={handleCheckChange}
                            name={'enableSocialVideos'}
                            keySettings={SettingsKey.ENABLE_SOCIAL_VIDEOS}
                            value={enableSocialVideos}
                            paddingBottom
                        />
                        
                        <ItemSettings
                            label={t('settings.newFeaturesSettings.productCheck')}
                            onChange={handleCheckChange}
                            name={'productCheck'}
                            keySettings={SettingsKey.PRODUCTS_CHECK_ENABLED}
                            value={productCheck}
                            paddingBottom
                        />
                        <Stack direction="column" spacing={3} mt={2}>
                            <TextField
                                label="Support widget script"
                                value={supportWidgetScript}
                                onChange={(e) => handleChange(e, SettingsKey.SUPPORT_WIDGET_SCRIPT)}
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                placeholder={'Script URL'}
                            />

                            <TextField
                                label="Immersive app limit size"
                                value={+immersiveAppSizeLimit}
                                onChange={(e) => handleNumberValueChange(e, SettingsKey.IMMERSIVE_APP_SIZE_LIMIT)}
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                // InputProps={{ inputProps: { min: 15, max: 50 } }}
                                type={'number'}
                                placeholder={'max size app (default 15 GB)'}
                            />
                        </Stack>
                        <VspClusterRegionSetting />
                        <OrganizationVerticalManagementSetting />
                    </>
                ) : null}
            </AccordionDetails>
        </Accordion>
    );
};

export default NewFeaturesSettings;
