import { useEffect, useState } from 'react';

export const useExternalScript = (initialUrl) => {
    let [url, setUrl] = useState(initialUrl);
    let [state, setState] = useState(initialUrl ? "loading" : "idle");

    const handleScript = (e) => {
        setState(e.type === "load" ? "ready" : "error");
    };

    const removeScript = (scriptUrl) => {
        const script = document.querySelector(`script[src="${scriptUrl}"]`);
        if (script) {
            script.removeEventListener("load", handleScript);
            script.removeEventListener("error", handleScript);
            script.remove();
        }
    }

    useEffect(() => {
        if (!url) {
            setState("idle");
            return;
        }

        let script = document.querySelector(`script[src="${url}"]`);

        if (!script) {
            script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
            script.addEventListener("load", handleScript);
            script.addEventListener("error", handleScript);
        }

        script.addEventListener("load", handleScript);
        script.addEventListener("error", handleScript);

        return () => {
            script.removeEventListener("load", handleScript);
            script.removeEventListener("error", handleScript);
        };
    }, [url]);

    return [
        state,
        setUrl,
        removeScript,
    ];
};