import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        hideUpcomingEvents: false,
        hideRecentEvents: false,
        notesOpenId: null,
        contentDialogMode: false,
    },
    reducers: {
        setNotesOpenId: (state, action) => {
            state.notesOpenId = action.payload;
        },
        setHideUpcomingEvents: (state, action) => {
            state.hideUpcomingEvents = action.payload;
        },
        setHideRecentEvents: (state, action) => {
            state.hideRecentEvents = action.payload;
        },
        setContentDialogMode: (state, action) => {
            state.contentDialogMode = action.payload;
        },
    },
});

export const { setHideUpcomingEvents, setHideRecentEvents, setNotesOpenId, setContentDialogMode } = appSlice.actions;

export const getNotesOpenId = (state) => state.app.notesOpenId;
export const getHideUpcomingEvents = (state) => state.app.hideUpcomingEvents;
export const getHideRecentEvents = (state) => state.app.hideRecentEvents;
export const getContentDialogMode = (state) => state.app.contentDialogMode;

export default appSlice.reducer;
