import { Stack, Typography } from '@mui/material';

import CustomTableRow from '../../../common/features/tables/CustomTableRow';

const MyAvailabilityTableRow = (props) => {
    const { workingHours } = props;

    const getRow = (day) => {
        return (
            <Stack direction="column" spacing={1}>
                {workingHours?.[day]?.map((item, idx) => (
                    <Typography fontWeight={400} variant="subtitle2" key={idx}>
                        {item[0]} - {item[1]}
                    </Typography>
                ))}
            </Stack>
        );
    };

    const rowConfig = {
        columns: [
            {
                content: getRow(0),
            },
            {
                content: getRow(1),
            },
            {
                content: getRow(2),
            },
            {
                content: getRow(3),
            },
            {
                content: getRow(4),
            },
            {
                content: getRow(5),
            },
            {
                content: getRow(6),
            },
        ],
    };

    return <CustomTableRow rowConfig={rowConfig} />;
};

export default MyAvailabilityTableRow;
