import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const reportSlice = createSlice({
    name: 'report',
    initialState: {
        pending: false,
        reports: [],
        error: null,
        deletePending: false,
        deleteError: null,
        total: 0,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        pendingDownload: false,
        downloadId: null,
    },
    reducers: {
        startDownload: (state, action) => {
            state.pendingDownload = true;
            state.downloadId = action.payload;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
            state.downloadId = null;
        },
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setReports: (state, action) => {
            state.pending = false;
            state.reports = action.payload.items;
            state.total = action.payload.total;
            state.count = action.payload.count;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.savePending = false;
            // state.reports = [];
            state.error = action.payload;
        },
        addReport: (state, action) => {
            state.savePending = false;
            state.reports = [action.payload, ...state.reports];
        },
        editReport: (state, action) => {
            state.savePending = false;
            const index = state.reports.findIndex((report) => report.id === action.payload.id);
            if (index !== -1) {
                state.reports = [...state.reports.slice(0, index), action.payload, ...state.reports.slice(index + 1)];
            }
        },
        deleteReport: (state, action) => {
            state.deletePending = false;
            state.deleteId = null;
            const index = state.reports.findIndex((report) => report.id === action.payload.id);
            if (index !== -1) {
                state.reports = [...state.reports.slice(0, index), ...state.reports.slice(index + 1)];
            }
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        setErrorDelete: (state, action) => {
            state.deletePending = false;
            state.deleteError = action.payload;
            state.deleteId = null;
        },
    },
});

export const {
    start,
    stop,
    setReports,
    setError,
    addReport,
    editReport,
    deleteReport,
    startDelete,
    setErrorDelete,
    startDownload,
    stopDownload,
} = reportSlice.actions;

export const getReports = (state) => state.report.reports;
export const getReportsPending = (state) => state.report.pending;
export const getDeletePending = (state) => state.report.deletePending;
export const getPendingDownload = (state) => state.report.pendingDownload;
export const getReportsTotal = (state) => state.report.total;
export const getReportsTotalPages = (state) => state.report.totalPages;
export const getReportsCurrentPage = (state) => state.report.currentPage;

export const fetchReportsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get(`reports` + query)
                .then((response) => {
                    dispatch(setReports(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const deleteReportAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete('reports/' + id)
                .then((response) => {
                    dispatch(deleteReport(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setErrorDelete(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(setErrorDelete(e));
            return console.error(e.message);
        }
    };

export const fetchReportDownloadURLAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDownload(id));
            api.get(`reports/download-url/${id}`)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(stopDownload());
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopDownload());
            return console.error(e.message);
        }
    };

export const generateReportAction =
    ({ requestDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDownload());
            api.post('reports/request', requestDto)
                .then((data) => {
                    onSuccess?.(data);
                    return data;
                })
                .catch((error) => {
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(stopDownload());
            return console.error(e.message);
        }
    };

export default reportSlice.reducer;
