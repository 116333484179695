import { Grid, Stack, Typography } from '@mui/material';
import DateRange from 'common/components/DateRange';
import useFlexBoxStyles from 'common/flexBox';
import { useIncludeDateValidation } from 'common/hooks/useIncludeDateValidation';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const transformDates = (dates) => {
    return dates.map((dateRange) => ({
        startDate: dateRange[0],
        endDate: dateRange[1],
    }));
};

const OutOfTheOffice = ({ fields, handleFieldChange, timeZone }) => {
    const { t } = useTranslation();
    const { classes: flexBox } = useFlexBoxStyles();
    const { isValidDateRange, error, validationType, setError } = useIncludeDateValidation();
    const [showAddForm, setShowAddForm] = useState(false);
    const dates = fields?.configuration?.outsOfTheOffice || [];

    const handleEditDate = (newDate, idx) => {
        const newRange = [...dates.slice(0, idx), [newDate.start, newDate.end], ...dates.slice(idx + 1)];
        saveRange(newRange);
    };

    const handleAddDate = (newDate) => {
        const newRange = [...dates, [newDate.start, newDate.end]];
        saveRange(newRange);
    };

    const handleDeleteDate = (idx) => {
        if (error?.idx === idx) {
            setError(null);
        }
        const newRange = [...dates.slice(0, idx), ...dates.slice(idx + 1)];
        saveRange(newRange);
    };

    const saveRange = (data) => {
        handleFieldChange({
            target: {
                name: 'configuration',
                value: { ...fields?.configuration, outsOfTheOffice: data },
            },
        });
    };

    const handleChange = (date, idx, callback) => {
        const ranges = transformDates(dates);
        const isValid = isValidDateRange({ ranges, date, idx, type: validationType.UNSELECTED });
        if (isValid) {
            if (idx === -1) {
                handleAddDate(date);
                setShowAddForm(false);
                callback?.();
            } else {
                handleEditDate(date, idx);
            }
        }
    };

    return (
        <Stack direction="column" spacing={1} className={flexBox.paddingTop2}>
            <Typography>{t('services.form.card.excludeRangeDate').toUpperCase()}</Typography>
            <Grid container>
                <Grid item sm={1.5}>
                    <Typography variant="subtitle2" fontWeight={400} className={flexBox.paddingTop1}>
                        {t('services.form.dateFrom').toUpperCase()}
                    </Typography>
                </Grid>

                <Grid item sm={10.5}>
                    <Stack direction="column" spacing={1}>
                        {dates?.map((date, idx) => (
                            <DateRange
                                key={idx}
                                type="datetime-local"
                                date={{
                                    start: moment.tz(date[0], timeZone).format('YYYY-MM-DDTHH:mm'),
                                    end: moment.tz(date[1], timeZone).format('YYYY-MM-DDTHH:mm'),
                                }}
                                onChange={(date) => handleChange(date, idx)}
                                onDelete={() => handleDeleteDate(idx)}
                                error={error?.idx === idx ? error?.msg : null}
                                disabled={error ? error?.idx !== idx : false}
                            />
                        ))}

                        <DateRange
                            type="datetime-local"
                            isDates={dates?.length}
                            showAddForm={showAddForm}
                            setShowAddForm={setShowAddForm}
                            onChange={(date, callback) => handleChange(date, -1, callback)}
                            onDelete={() => setError(null)}
                            error={error?.idx === -1 ? error?.msg : null}
                            disabled={error ? error?.idx !== -1 : false}
                        />

                        <Typography display="block" gutterBottom component="div">
                            {t('calendar.rangeNote')}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default OutOfTheOffice;
