import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const labelsSlice = createSlice({
    name: 'labels',
    initialState: {
        labelsSuggested: [],
    },
    reducers: {
        setLabelsSuggested: (state, action) => {
            state.labelsSuggested = action.payload;
        },
        addLabelSuggested: (state, action) => {
            state.labelsSuggested = [...state.labelsSuggested, action.payload];
        },
    },
});

export const { setLabelsSuggested, addLabelSuggested } = labelsSlice.actions;

export const getLabelsSuggested = (state) => state.labels.labelsSuggested;

export const fetchLabelsAction = (namespace) => async (dispatch) => {
    try {
        return api
            .get('labels/' + namespace)
            .then((response) => {
                dispatch(setLabelsSuggested(response.data));
                return response.data;
            })
            .catch((error) => {
                console.error('error', error);
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const deleteLabelsAction = (namespace, value) => async (dispatch) => {
    try {
        return api
            .delete('labels/' + namespace + '/' + value)
            .then((response) => {
                dispatch(setLabelsSuggested(response.data));
                return response.data;
            })
            .catch((error) => {
                console.error('error', error);
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export default labelsSlice.reducer;
