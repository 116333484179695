import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button, FormControlLabel, FormGroup, FormLabel, Grid, Switch, TextField } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import CustomTooltip from 'common/components/CustomTooltip';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { messageNotAuthorize } from 'common/utils/CONST';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesSettings from '../styles/useStylesSettings.styles';

const HooksSettings = (props) => {
    const { settingsState, setSettingsState, enableNotifyMe, handleSubmit, hasChanges } = props;
    const { t } = useTranslation();

    const { classes, cx } = useStylesSettings();

    const settingsDirty = useSelector(getSettingsDirty);

    const isAuthorize = useAuthLicenseAddOns();

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const handleChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const toggleChange = (settingKey, settingValue) => {
        setSettingsState({ ...settingsState, [settingKey]: { ...settingsState[settingKey], settingValue } });
    };

    const renderHooks = ({ disabled }) => (
        <Accordion disabled={disabled} className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.hooksSettings.hooks')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    {Object.keys(settingsState).length > 0 && (
                        <ItemSettings
                            label={t('profile.settings.hooksSettings.setUse')}
                            onChange={handleCheckChange}
                            name={'eventHooks'}
                            keySettings={SettingsKey.ENABLE_EVENT_WEBHOOKS}
                            value={settingsState[SettingsKey.ENABLE_EVENT_WEBHOOKS]?.settingValue}
                        />
                    )}

                    <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('profile.settings.hooksSettings.save')}
                        </Button>
                    </Box>
                </Box>
                {Object.keys(settingsState).length > 0 && (
                    <FormGroup>
                        {settingsState[SettingsKey.ENABLE_EVENT_WEBHOOKS]?.settingValue && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                settingsState[SettingsKey.OVERWRITE_DEFAULT_WEBHOOKS_ACTIONS]
                                                    ?.settingValue
                                            }
                                            onChange={(e) =>
                                                handleCheckChange(e, SettingsKey.OVERWRITE_DEFAULT_WEBHOOKS_ACTIONS)
                                            }
                                            name="overwriteDefaultHooksActions"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <FormLabel>{t('profile.settings.hooksSettings.overwriteDefault')}</FormLabel>
                                    }
                                />
                                <TextField
                                    label={t('profile.settings.hooksSettings.webHookKey')}
                                    value={settingsState[SettingsKey.WEBHOOK_TOKEN]?.settingValue}
                                    onChange={(e) => handleChange(e, SettingsKey.WEBHOOK_TOKEN)}
                                    className={classes.hookTextField}
                                    variant="outlined"
                                    size="small"
                                />

                                <FormLabel>{t('profile.settings.hooksSettings.webHook')}</FormLabel>
                                <Grid container spacing={1} className={classes.hookUrlContainer}>
                                    {enableNotifyMe && (
                                        <TextField
                                            label={t('profile.settings.hooksSettings.notifyMe')}
                                            value={settingsState[SettingsKey.EVENT_SUBSCRIPTION_WEBHOOK]?.settingValue}
                                            onChange={(e) => handleChange(e, SettingsKey.EVENT_SUBSCRIPTION_WEBHOOK)}
                                            className={cx(classes.hookTextField, classes.w100pct)}
                                            variant="outlined"
                                            size="small"
                                            placeholder={t('profile.settings.hooksSettings.notifyMePlaceholder')}
                                        />
                                    )}
                                    <TextField
                                        label={t('profile.settings.hooksSettings.crateViewer')}
                                        value={settingsState[SettingsKey.CREATE_VIEWER_WEBHOOK]?.settingValue}
                                        onChange={(e) => handleChange(e, SettingsKey.CREATE_VIEWER_WEBHOOK)}
                                        className={cx(classes.hookTextField, classes.w100pct)}
                                        variant="outlined"
                                        size="small"
                                        placeholder={t('profile.settings.hooksSettings.crateViewerPlaceholder')}
                                    />
                                    <TextField
                                        label={t('profile.settings.hooksSettings.gamePrice')}
                                        value={settingsState[SettingsKey.GAME_WINNER_WEBHOOK]?.settingValue}
                                        onChange={(e) => handleChange(e, SettingsKey.GAME_WINNER_WEBHOOK)}
                                        className={cx(classes.hookTextField, classes.w100pct, classes.mb0)}
                                        variant="outlined"
                                        size="small"
                                        placeholder={t('profile.settings.hooksSettings.gamePricePlaceholder')}
                                    />
                                </Grid>
                            </>
                        )}
                    </FormGroup>
                )}
            </AccordionDetails>
        </Accordion>
    );

    return (
        <>
            {!isAuthorize(['full_look_and_feel']) ? (
                <CustomTooltip arrow title={t(messageNotAuthorize)} placement={'left'}>
                    <span>{renderHooks({ disabled: true })}</span>
                </CustomTooltip>
            ) : (
                renderHooks({ disabled: false })
            )}
        </>
    );
};

export default HooksSettings;
