import { makeStyles } from 'tss-react/mui';

const useStylesIntegration = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary.main,
            flexGrow: 1,
            overflow: 'hidden',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        body: {
            padding: theme.spacing(1, 7),
        },
        p1: {
            padding: theme.spacing(1) + ' !important',
        },
        paginator: {
            paddingTop: theme.spacing(2) + ' !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        formTab: {
            position: 'relative',
        },
        pending: {
            width: '50%',
            position: 'absolute',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
        },
        searchTextField: {
            '& .MuiOutlinedInput-root': {
                borderRadius: theme.spacing(3),
                background: theme.palette.background.paper,
                height: theme.spacing(4.5),
                paddingRight: theme.spacing(0.25),
            },
        }
    };
});

export default useStylesIntegration;
