import 'common/validators/ValidationRulesManager';
import { useTranslation } from 'react-i18next';
import { TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '& .MuiInputBase-root': {
                height: theme.spacing(4.5),
                background: '#FFFF',
                borderRadius: theme.spacing(4.5),
                paddingRight: 0,
                '& > fieldset': {
                    borderColor: '#E2E2E2',
                },
                '& > input': {
                    padding: theme.spacing(0, 1),
                    height: '100%',
                    fontSize: '12px',
                    fontWeight: '500',
                    position: 'relative',
                    '&:focus': {
                        borderRadius: theme.spacing(4.5),
                    },
                },
            },

            '& .MuiFormHelperText-root': {
                [theme.breakpoints.up('xl')]: {
                    fontSize: '14px',
                },
            },
        },
        rootMultiline: {
            '& .MuiInputBase-root': {
                height: 'auto',
                borderRadius: theme.spacing(2.5),
            },
        },
    };
});

const CustomTextValidator = (props) => {
    const { t } = useTranslation();
    const { className, validators = [], useRegExpText = true, errorMessages = [], ...rest } = props;

    const { classes, cx } = useStyles();

    return (
        <TextValidator
            className={cx(classes.root, { [classes.rootMultiline]: rest?.multiline }, className)}
            {...rest}
            validators={[...validators, ...((useRegExpText && !validators.includes('urlMatch')) ? ['isValidText'] : [])]}
            errorMessages={[...errorMessages, ...(useRegExpText ? [t('common.customTextValidator.invalid')] : [])]}
        ></TextValidator>
    );
};

export default CustomTextValidator;
