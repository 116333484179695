import useStylesItems from './styles/useStylesItems.style';

const Item = ({ title, children }) => {
    const { classes } = useStylesItems();
    return (
        <>
            <li className={classes.root}>
                {!!title && (
                    <p>
                        <b>{title}</b>
                    </p>
                )}
                {children}
            </li>
        </>
    );
};

export default Item;
