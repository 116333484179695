import { createSlice } from '@reduxjs/toolkit';
import { calendarApiV3 as api } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const templateTypeSlice = createSlice({
    name: 'templateType',
    initialState: {
        pending: false,
        templateTypes: [],
        error: null,
        deletePending: false,
        deleteError: null,
        total: 0,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        pendingDownload: false,
    },
    reducers: {
        startDownload: (state, action) => {
            state.pendingDownload = true;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
        },
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setTemplateTypes: (state, action) => {
            state.pending = false;
            state.templateTypes = action.payload.items;
            state.total = action.payload.total;
            state.count = action.payload.count;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addTemplateType: (state, action) => {
            state.pending = false;
            state.templateTypes = [action.payload, ...state.templateTypes];
        },
        editTemplateType: (state, action) => {
            state.pending = false;
            const index = state.templateTypes.findIndex((templateType) => templateType.slug === action.payload.slug);
            if (index !== -1) {
                state.templateTypes = [
                    ...state.templateTypes.slice(0, index),
                    action.payload,
                    ...state.templateTypes.slice(index + 1),
                ];
            }
        },
        deleteTemplateType: (state, action) => {
            state.deletePending = false;
            const index = state.templateTypes.findIndex((templateType) => templateType.slug === action.payload.slug);
            if (index !== -1) {
                state.templateTypes = [...state.templateTypes.slice(0, index), ...state.templateTypes.slice(index + 1)];
            }
        },
        startDelete: (state, action) => {
            state.deletePending = true;
        },
        setErrorDelete: (state, action) => {
            state.deletePending = false;
            state.deleteError = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setTemplateTypes,
    setError,
    addTemplateType,
    editTemplateType,
    deleteTemplateType,
    startDelete,
    setErrorDelete,
    startDownload,
    stopDownload,
} = templateTypeSlice.actions;

export const getTemplateTypes = (state) => state.templateType.templateTypes;
export const getTemplateTypesPending = (state) => state.templateType.pending;
export const getDeletePending = (state) => state.templateType.deletePending;
export const getTemplateTypesPagination = (state) => ({
    count: state.templateType.count,
    currentPage: state.templateType.currentPage,
    totalPages: state.templateType.totalPages,
    total: state.templateType.total,
});

export const fetchTemplateTypesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('template-types' + query)
                .then((response) => {
                    dispatch(setTemplateTypes(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const updateTemplateTypeAction =
    ({ id, templateTypeDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.put('template-types/' + id, templateTypeDto)
                .then((response) => {
                    dispatch(editTemplateType(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(stop());
                    onFailure?.();
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addTemplateTypeAction =
    ({ templateTypeDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.post('template-types', templateTypeDto)
                .then((response) => {
                    dispatch(addTemplateType(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(stop());
                    onFailure?.();
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const deleteTemplateTypeAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete('template-types/' + id)
                .then((response) => {
                    dispatch(deleteTemplateType(response.data));
                    onSuccess?.();
                    return response;
                })
                .catch((error) => {
                    dispatch(setErrorDelete(error));
                    onFailure?.(error);
                });
        } catch (e) {
            dispatch(setErrorDelete(e));
            return console.error(e.message);
        }
    };

export default templateTypeSlice.reducer;
