import UserAvailability from 'app/user/UserAvailability';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeCustomDialogAction, openCustomDialog } from 'redux/slices/customDialogSlice';
import { editAvailabilityAction } from 'redux/slices/myAvailabilitySlice';
import MyAvailabilityForm from './MyAvailabilityForm';

const MyAvailabilityEdit = ({ availability, userData }) => {
    const { userView, userGroupId } = userData;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSubmit = (payload, callbackOk, callbackError) => {
        dispatch(
            editAvailabilityAction({
                id: availability.id,
                dto: payload,
                onSuccess: () => {
                    callbackOk?.();
                    close();
                },
                onFailure: () => callbackError?.(),
            }),
        );
    };

    const close = () => {
        if (userView) {
            dispatch(openCustomDialog(<UserAvailability userData={userData} />));
        } else {
            dispatch(closeCustomDialogAction());
        }
    };

    return (
        <MyAvailabilityForm
            availability={availability}
            onSubmit={handleSubmit}
            title={t('profile.myAvailability.form.editTitle')}
            edit={true}
            onClose={close}
            userGroupId={userGroupId}
        />
    );
};

export default MyAvailabilityEdit;
