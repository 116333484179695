import Editor from '@monaco-editor/react';
import { useRef, useState } from 'react';

const BaseCodeInputMonacoEditor = ( { height, defaultLanguage, defaultValue, value, onChange, width, readOnly }) => {
    const editorRef = useRef(null);

    const [cursor, setCursor] = useState({ line: 0, column: 0 });

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            allowComments: false,
            schemas: [],
            enableSchemaRequest: true,
        });

        editor.onDidChangeCursorPosition(function (e) {
            setCursor({
                line: e.position.lineNumber,
                column: e.position.column,
            });
        });
    };

    const handleEditorValidation = (markers) => {
        const currentErrors = markers.map((m) => `Ln ${m.startLineNumber}, Col ${m.startColumn}: ${m.message}`);
        onChange(editorRef.current.getValue(), currentErrors);
    };

    const handleEditorChange = (content) => {
        onChange(editorRef.current.getValue(), []);
    };

    return (
        <div>
            <div>
                <Editor
                    height={height || '200px'}
                    width={width || '608px'}
                    defaultLanguage={defaultLanguage || 'javascript'}
                    defaultValue={defaultValue || ''}
                    onValidate={handleEditorValidation}
                    value={value}
                    onChange={(value, event) => handleEditorChange(value)}
                    onMount={handleEditorDidMount}
                    options={{
                        minimap: {
                            enabled: false,
                        },
                        readOnly,
                    }}
                />
            </div>
            <div>
                <span style={{ fontSize: 'smaller' }}>{cursor && `Ln: ${cursor.line}, Col: ${cursor.column}`}</span>
            </div>
        </div>
    );
};

export default BaseCodeInputMonacoEditor;
