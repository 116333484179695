import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useFlexBoxStyles from 'common/flexBox';
import { RenderDateInput } from 'common/utils/utils';
import moment from 'moment';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        '.MuiPickersCalendarHeader-label, .MuiPickersDay-root, .MuiDayPicker-weekDayLabel': {
            ...theme.typography.subtitle2,
        },
    },
}));

const DateTimeField = ({ id, value, onChange }) => {
    const { classes } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    const parseDateString = (dateString) => {
        if (!dateString) return null;
        return new Date(dateString);
    };

    const [date, setDate] = useState(parseDateString(value));

    const handleDateChange = (v) => {
        setDate(v);
        const formattedDate = moment(v).utc().format();
        onChange(formattedDate);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                classes={{ root: classes.root }}
                PaperProps={{
                    classes: { root: flexBox.borderRadius2 },
                }}
                value={date}
                onChange={handleDateChange}
                variant="inline"
                size="small"
                inputVariant="outlined"
                inputFormat="dd/MM/yyyy HH:mm"
                renderInput={(params) => <RenderDateInput id={id} {...params} size="medium" bg="#FFFF" />}
            />
        </LocalizationProvider>
    );
};

export default DateTimeField;
