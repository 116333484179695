import { createSlice } from '@reduxjs/toolkit';

import { VodApi } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const helpSlice = createSlice({
    name: 'videoHelp',
    initialState: {
        pending: false,
        savePending: false,
        videoHelp: [],
        error: null,
        total: 0,
        pendingTotal: false,
        errorTotal: null,
        progress: 0,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setVideoHelp: (state, action) => {
            state.pending = false;
            state.videoHelp = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.videoHelp = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
            state.progress = 0;
        },
        addVideoHelp: (state, action) => {
            state.savePending = false;
            state.videoHelp = [...state.videoHelp, action.payload];
        },
        editVideoHelp: (state, action) => {
            state.savePending = false;
            const index = state.videoHelp.findIndex((multimedia) => multimedia.id === action.payload.id);
            if (index !== -1) {
                state.videoHelp = [
                    ...state.videoHelp.slice(0, index),
                    action.payload,
                    ...state.videoHelp.slice(index + 1),
                ];
            }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.videoId = action.payload;
        },
        deleteVideoHelp: (state, action) => {
            state.deletePending = false;
            state.videoId = null;
            const index = state.videoHelp.findIndex((multimedia) => multimedia.id === action.payload.id);
            if (index !== -1) {
                state.videoHelp = [...state.videoHelp.slice(0, index), ...state.videoHelp.slice(index + 1)];
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setVideoHelp,
    setError,
    startSave,
    addVideoHelp,
    editVideoHelp,
    setSaveError,
    startDelete,
    deleteVideoHelp,
    setDeleteError,
    setProgress,
    stopSave,
} = helpSlice.actions;

export const getVideoHelp = (state) => state.videoHelp.videoHelp;
export const getVideoHelpPending = (state) => state.videoHelp.pending;
export const getVideoHelpSavePending = (state) => state.videoHelp.savePending;
export const getVideoHelpDeletePending = (state) => state.videoHelp.deletePending;
export const getVideoHelpError = (state) => state.videoHelp.error;
export const getVideoHelpId = (state) => state.videoHelp.videoId;
export const getVideoHelpProgressUpload = (state) => state.videoHelp.progress;

export const fetchHelpVideoAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        VodApi
            .get('shared' + query)
            .then((response) => {
                dispatch(setVideoHelp(response.data?.items));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const addVideoHelpAction = (videoHelpDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        const formData = new FormData();
        formData.append('file', videoHelpDto.file);
        formData.append('filename', videoHelpDto.name);
        formData.append('tags', JSON.stringify(videoHelpDto.tags)); // Se puede probar pasar un objeto sin el stringify, pero asi funciona
        VodApi
            .post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    dispatch(setProgress((progressEvent.loaded * 100) / videoHelpDto.file.size));
                },
            })
            .then((response) => {
                dispatch(addVideoHelp(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error(e.message);
    }
};

export const editVideoHelpAction = (videoId, videoHelpDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        if (videoHelpDto.file) {
            dispatch(
                deleteVideoHelpAction(videoId, () => {
                    dispatch(addVideoHelpAction(videoHelpDto, success));
                }),
            );
        } else {
            VodApi
                .post('/' + videoId, videoHelpDto)
                .then((response) => {
                    dispatch(editVideoHelp(response.data));
                    if (success) {
                        success();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                });
        }
    } catch (e) {
        dispatch(stopSave());
        return console.error(e.message);
    }
};

export const deleteVideoHelpAction = (helpId, onDelete) => async (dispatch) => {
    try {
        dispatch(startDelete(helpId));
        VodApi
            .delete('/' + helpId + '?permanently=true')
            .then((response) => {
                dispatch(deleteVideoHelp(response.data));
                if (onDelete) {
                    onDelete();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export default helpSlice.reducer;
