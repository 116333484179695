import {
    Apps,
    Article,
    BusinessCenterOutlined,
    BusinessOutlined,
    ChatBubbleOutline,
    CodeOutlined,
    DiscountOutlined,
    EventNoteOutlined,
    EventOutlined,
    HelpOutlineOutlined,
    InstallDesktop,
    KeyOutlined,
    ListAltOutlined,
    PaymentsOutlined,
    // PieChartOutline,
    QuizOutlined,
    SettingsApplicationsOutlined,
    ShoppingCartOutlined,
    VideoLibraryOutlined,
    WebhookOutlined,
} from '@mui/icons-material';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import GenAIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PhoneMissedOutlinedIcon from '@mui/icons-material/PhoneMissedOutlined';
import DeliveryIcon from '@mui/icons-material/PollOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ICallIcon from 'common/assets/icons/iCallIcon';
import ILiveIcon from 'common/assets/icons/iLiveIcon';
import IVideoIcon from 'common/assets/icons/iVideoIcon';
import UserRole from 'common/enums/roles';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { testFeatureEnabled } from 'redux/slices/authSlice';
import { getLicense } from 'redux/slices/licenseSlice';
import SettingsKey from '../../app/profile/settings/utils/settingsTypes';
import { useSettingValue } from '../../common/hooks/useSettingValue';
import loadLazyComponent from '../../common/utils/loadLazyComponent';
import LazySuspense from '../lazy/LazySuspense';
import MicroFront from '../micro-front/MicroFront';

export function useMenuEntries() {
    const license = useSelector(getLicense);
    const isTestFeatureEnabled = useSelector(testFeatureEnabled);

    const hideO2M = !license?.services?.oneToMany?.iLive;
    const hideShoppable = !license?.services?.oneToMany?.shoppable;
    const hideChannel = !license?.services?.oneToMany?.channel;
    const hideO2O = !license?.services?.oneToOne?.iCall;
    const hideIChat = !license?.services?.oneToOne?.iChat;
    const hideICalendar = !license?.services?.oneToOne?.iCalendar;
    const hideLeads = !license?.services?.leads;
    const hideInteractions = hideO2M && hideO2O;
    const hideMedia = hideO2M && hideO2O;

    const enableFormMange = useSettingValue(SettingsKey.ENABLE_FORM_MANAGE);

    const enableInstallations = useSettingValue(SettingsKey.ENABLE_INSTALLATIONS_MENU);
    const enableNewInstallations = useSettingValue(SettingsKey.NEW_INSTALLATIONS);
    const hideLicense = license?.price === 0;

    const genaiEnabled = useSettingValue(SettingsKey.ENABLE_GENAI);

    const LazyOneToManyView = loadLazyComponent(() => import('app/appViewsHandle/components/OneToManyViewHandle'));
    const LazyOneToOneView = loadLazyComponent(() => import('app/appViewsHandle/components/OneToOneViewHandle'));
    const LazyShoppableView = loadLazyComponent(() => import('app/appViewsHandle/components/ShoppableViewHandle'));
    const LazyIChatView = loadLazyComponent(() => import('app/appViewsHandle/components/IChatViewHandle'));

    const LazyMultimediaView = loadLazyComponent(() => import('app/appViewsHandle/components/MultimediaViewHandle'));
    const LazyEngagementView = loadLazyComponent(() => import('app/appViewsHandle/components/EngagementViewHandle'));
    const LazyProductsView = loadLazyComponent(() => import('app/appViewsHandle/components/ProductsViewHandle'));
    const LazyContactsView = loadLazyComponent(() => import('app/appViewsHandle/components/ContactsViewHandle'));
    const LazyContactRequestView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/ContactRequestViewHandle'),
    );
    const LazyLostOpportunityView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/LostOpportunityViewHandle'),
    );

    const LazySocialMediaView = loadLazyComponent(() => import('app/appViewsHandle/components/SocialMediaViewHandle'));
    const LazyTvChannelView = loadLazyComponent(() => import('app/appViewsHandle/components/TvChannelViewHandle'));
    const LazyTvChannelsConnectors = loadLazyComponent(() => import('app/tvChannel/TvChannelsConnectors'));
    const LazyEditTvChannelView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/EditTvChannelViewHandle'),
    );

    const LazyGenAIView = loadLazyComponent(() => import('app/appViewsHandle/components/GeAIViewHandle'));
    const LazyOneToOneDashboard = loadLazyComponent(() =>
        import('app/appViewsHandle/components/OneToOneDashboardViewHandle'),
    );
    const LazyUsersView = loadLazyComponent(() => import('app/appViewsHandle/components/UsersViewHandle'));
    const LazyWebhooksView = loadLazyComponent(() => import('app/appViewsHandle/components/WebhooksViewHandle'));
    const LazyFormsView = loadLazyComponent(() => import('app/appViewsHandle/components/FormsViewHandle'));
    const LazyBillingView = loadLazyComponent(() => import('app/appViewsHandle/components/BillingViewHandle'));
    const LazyBillingContent = loadLazyComponent(() => import('app/billing/BillingContent'));
    const LazyPlansEdit = loadLazyComponent(() => import('app/billing/plans/PlansEdit'));
    const LazyAccountManagerView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/AccountManagerViewHandle'),
    );
    const LazyInstallationsView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/InstallationsViewHandle'),
    );
    const LazyInstallationTable = loadLazyComponent(() => import('app/installation/InstallationTable'));
    const LazyTriagesView = loadLazyComponent(() => import('app/appViewsHandle/components/TriagesViewHandle'));
    const LazyContentsView = loadLazyComponent(() => import('app/appViewsHandle/components/ContentsViewHandle'));
    const LazyContentsTable = loadLazyComponent(() => import('app/content/ContentsTable'));

    const LazyLicenseView = loadLazyComponent(() => import('app/appViewsHandle/components/LicenseViewHandle'));
    const LazyAddOnsView = loadLazyComponent(() => import('app/appViewsHandle/components/AddOnsViewHandle'));
    const LazyCustomersView = loadLazyComponent(() => import('app/appViewsHandle/components/CustomersViewHandle'));
    const LazyCustomerTable = loadLazyComponent(() => import('app/customer/CustomerTable'));
    const LazyCustomerDetails = loadLazyComponent(() => import('app/customer/CustomerDetails'));
    const LazyVideoHelpView = loadLazyComponent(() => import('app/appViewsHandle/components/VideoHelpViewHandle'));
    const LazyCouponsView = loadLazyComponent(() => import('app/appViewsHandle/components/CouponsViewHandle'));
    const LazyCouponTable = loadLazyComponent(() => import('app/coupon/CouponTable'));
    const LazyCouponDetails = loadLazyComponent(() => import('app/coupon/CouponDetails'));
    const LazyCalendarGlobalView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/CalendarGlobalViewHandle'),
    );
    const LazyApplicationsView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/ApplicationsViewHandle'),
    );
    const LazyApplicationTable = loadLazyComponent(() => import('app/application/ApplicationTable'));
    const LazyApplicationDetails = loadLazyComponent(() => import('app/application/ApplicationDetails'));
    // const LazyAdminDashboardsView = loadLazyComponent(() =>
    //     import('app/appViewsHandle/components/AdminDashboardsViewHandle'),
    // );
    // const LazyAdminDashboardTable = loadLazyComponent(() => import('app/adminDashboard/AdminDashboardTable'));
    // const LazyAdminDashboardDetails = loadLazyComponent(() => import('app/adminDashboard/AdminDashboardDetails'));
    const LazyFormsGeneralView = loadLazyComponent(() =>
        import('app/appViewsHandle/components/FormsGeneralViewHandle'),
    );
    const LazyTaxonomies = loadLazyComponent(() => import('app/appViewsHandle/components/TaxonomiesViewHandle'));
    const LazyReports = loadLazyComponent(() => import('app/appViewsHandle/components/ReportsViewHandle'));
    const LazyServicesView = loadLazyComponent(() => import('app/appViewsHandle/components/ServicesViewHandle'));
    const LazyICalendarView = loadLazyComponent(() => import('app/appViewsHandle/components/ICalendarViewHandle'));

    const LazyOnliveDeliveryDashboard = loadLazyComponent(() =>
        import('app/dashboard/components/OnliveDeliveryDashboard'),
    );
    const LazySystemLogView = loadLazyComponent(() => import('app/appViewsHandle/components/SystemLogViewHandle'));
    const LazyVerticalManagement = loadLazyComponent(() =>
        import('app/appViewsHandle/components/VerticalManagementHandle'),
    );
    const LazyScriptsView = loadLazyComponent(() => import('app/appViewsHandle/components/ScriptsViewHandle'));

    const showInstallations = useCallback(
        (role) => {
            return (
                enableNewInstallations && (role === UserRole.ROOT || (role === UserRole.ADMIN && enableInstallations))
            );
        },
        [enableInstallations, enableNewInstallations],
    );

    return useMemo(
        () => [
            {
                namespace: 'menu.events',
                items: [
                    {
                        name: 'menu.oneToMeny',
                        subTitle: 'menu.subTitle.oneToMany',
                        path: '/one-to-many',
                        component: (
                            <LazySuspense>
                                <LazyOneToManyView />
                            </LazySuspense>
                        ),
                        icon: <ILiveIcon />,
                        hide: hideO2M,
                    },
                    {
                        name: 'menu.oneToOne',
                        subTitle: 'menu.subTitle.oneToOne',
                        path: '/one-to-one',
                        component: (
                            <LazySuspense>
                                <LazyOneToOneView />
                            </LazySuspense>
                        ),
                        icon: <ICallIcon />,
                        hide: hideO2O,
                    },
                    {
                        name: 'menu.shoppables',
                        subTitle: 'menu.subTitle.shoppables',
                        path: '/shoppable',
                        component: (
                            <LazySuspense>
                                <LazyShoppableView />
                            </LazySuspense>
                        ),
                        icon: <IVideoIcon />,
                        hide: hideShoppable,
                    },
                    {
                        name: 'menu.ichat',
                        subTitle: 'menu.subTitle.ichat',
                        path: '/i-chat',
                        component: (
                            <LazySuspense>
                                <LazyIChatView />
                            </LazySuspense>
                        ),
                        icon: <ChatBubbleOutline />,
                        hide: hideIChat,
                    },
                    {
                        name: 'menu.iCalendar',
                        subTitle: 'menu.subTitle.iCalendart',
                        path: '/i-calendar',
                        component: (
                            <LazySuspense>
                                <LazyICalendarView />
                            </LazySuspense>
                        ),
                        icon: <CalendarTodayOutlinedIcon />,
                        hide: hideICalendar,
                    },
                ],
                roles: ['admin', 'editor', 'creator'],
                section: 'events',
            },
            {
                namespace: 'menu.assets',
                items: [
                    {
                        name: 'menu.multimedia',
                        path: '/multimedia',
                        component: (
                            <LazySuspense>
                                <LazyMultimediaView />
                            </LazySuspense>
                        ),
                        icon: <VideoLibraryOutlined />,
                        hide: hideMedia,
                    },
                    {
                        name: 'menu.engagement',
                        path: '/games',
                        component: (
                            <LazySuspense>
                                <LazyEngagementView />
                            </LazySuspense>
                        ),
                        icon: <QuizOutlined />,
                        hide: hideInteractions,
                    },
                    {
                        name: 'menu.products',
                        path: '/products',
                        component: (
                            <LazySuspense>
                                <LazyProductsView />
                            </LazySuspense>
                        ),
                        icon: <ShoppingCartOutlined />,
                    },
                ],
                roles: ['admin', 'editor', 'creator'],
                collapsible: true,
            },
            {
                namespace: 'menu.dashboards',
                items: [
                    {
                        name: 'menu.chatDashboard',
                        path: '/dashboard-i-chat',
                        component: <MicroFront slug="dashboard" path="i-chat" />,
                        icon: <LineAxisIcon />,
                        hide: !isTestFeatureEnabled || hideIChat,
                    },
                    {
                        name: 'menu.iCallDashboard',
                        path: '/o2o-dashboard',
                        component: (
                            <LazySuspense>
                                <LazyOneToOneDashboard />
                            </LazySuspense>
                        ),
                        icon: <AutoGraphOutlinedIcon />,
                    },
                    {
                        name: 'menu.iCallMFDashboard',
                        path: '/dashboard-i-call',
                        component: <MicroFront slug="dashboard" path="i-call" />,
                        icon: <BarChartOutlinedIcon />,
                        hide: !isTestFeatureEnabled,
                    },
                ],
                roles: ['admin', 'editor'],
                collapsible: true,
                hide: hideIChat && hideO2O,
            },
            {
                namespace: 'menu.contacts',
                items: [
                    {
                        name: 'menu.contactList',
                        path: '/leads',
                        component: (
                            <LazySuspense>
                                <LazyContactsView />
                            </LazySuspense>
                        ),
                        icon: <ContactsOutlinedIcon />,
                        hide: hideLeads,
                    },
                    {
                        name: 'menu.contactRequestList',
                        path: '/contact-requests',
                        component: (
                            <LazySuspense>
                                <LazyContactRequestView />
                            </LazySuspense>
                        ),
                        icon: <RecentActorsOutlinedIcon />,
                        hide: !isTestFeatureEnabled,
                    },
                    {
                        name: 'menu.lostOpportunityList',
                        path: '/lost-opportunities',
                        component: (
                            <LazySuspense>
                                <LazyLostOpportunityView />
                            </LazySuspense>
                        ),
                        icon: <PhoneMissedOutlinedIcon />,
                        hide: !isTestFeatureEnabled,
                    },
                ],
                collapsible: true,
                roles: ['admin', 'editor', 'creator'],
                hide: hideLeads,
            },
            {
                namespace: 'menu.channels',
                items: [
                    // {
                    //     name: 'menu.yourWeb',
                    //     path: '/web-tv-channel',
                    //     component: <WebTvChannelViewHandle />,
                    //     icon: <TvOutlinedIcon />,
                    //     hide: hideTvWidget,
                    // },
                    // {
                    //     name: 'menu.widgets',
                    //     path: '/widgets',
                    //     component: <WidgetsViewHandle />,
                    //     icon: <SmartButtonOutlinedIcon />,
                    // },
                    // {
                    //     name: 'menu.agentPage',
                    //     path: '/agent',
                    //     component: <AgentPageViewHandle />,
                    //     icon: <CoPresentOutlinedIcon />,
                    // },
                    {
                        name: 'menu.socialMedia',
                        path: '/social-media',
                        component: (
                            <LazySuspense>
                                <LazySocialMediaView />
                            </LazySuspense>
                        ),
                        icon: <ThumbUpOffAltOutlinedIcon />,
                        hide: hideChannel,
                    },
                    {
                        name: 'menu.tvChannels',
                        path: '/tv-channels',
                        component: (
                            <LazySuspense>
                                <LazyTvChannelView />
                            </LazySuspense>
                        ),
                        icon: <DesktopMacOutlinedIcon />,
                        childrenRoutes: (
                            <React.Fragment>
                                <Route
                                    index={true}
                                    element={
                                        <LazySuspense>
                                            <LazyTvChannelsConnectors />
                                        </LazySuspense>
                                    }
                                />
                                <Route
                                    exact
                                    path=":channelId"
                                    element={
                                        <LazySuspense>
                                            <LazyEditTvChannelView />
                                        </LazySuspense>
                                    }
                                />
                            </React.Fragment>
                        ),
                        hide: hideChannel,
                    },
                ],
                roles: ['admin'],
                collapsible: true,
                hide: hideChannel,
            },
            {
                namespace: 'menu.configuration',
                items: [
                    {
                        name: 'menu.user',
                        path: '/users',
                        component: (
                            <LazySuspense>
                                <LazyUsersView />
                            </LazySuspense>
                        ),
                        icon: <ManageAccountsOutlinedIcon />,
                    },
                    {
                        name: 'menu.billing',
                        path: '/billing',
                        component: (
                            <LazySuspense>
                                <LazyBillingView />
                            </LazySuspense>
                        ),
                        icon: <PaymentsOutlined />,
                        childrenRoutes: (
                            <React.Fragment>
                                <Route
                                    index={true}
                                    element={
                                        <LazySuspense>
                                            <LazyBillingContent />
                                        </LazySuspense>
                                    }
                                />
                                <Route
                                    exact
                                    path=":edit-plan"
                                    element={
                                        <LazySuspense>
                                            <LazyPlansEdit />
                                        </LazySuspense>
                                    }
                                />
                            </React.Fragment>
                        ),
                        hiddenIfProfile: true,
                        roles: ['admin'],
                        hide: hideLicense,
                    },
                    {
                        name: 'menu.accounts',
                        path: '/accounts',
                        component: (
                            <LazySuspense>
                                <LazyAccountManagerView />
                            </LazySuspense>
                        ),
                        icon: <BusinessOutlined />,
                        permissions: ['multiAccount', 'multiProfile'],
                        mainPermission: true,
                        roles: ['admin'],
                    },

                    {
                        name: 'menu.installations',
                        path: '/installations',
                        component: (
                            <LazySuspense>
                                <LazyInstallationsView />
                            </LazySuspense>
                        ),
                        childrenRoutes: (
                            <Route
                                index={true}
                                element={
                                    <LazySuspense>
                                        <LazyInstallationTable />
                                    </LazySuspense>
                                }
                            />
                        ),
                        icon: <InstallDesktop />,
                        roles: ['admin'],
                        hide: (role) => !showInstallations(role),
                    },

                    {
                        name: 'menu.triages',
                        path: '/triages',
                        component: (
                            <LazySuspense>
                                <LazyTriagesView />
                            </LazySuspense>
                        ),
                        icon: <AccountTreeOutlinedIcon />,
                        roles: ['root'],
                    },

                    {
                        name: 'menu.contents',
                        path: '/contents',
                        component: (
                            <LazySuspense>
                                <LazyContentsView />
                            </LazySuspense>
                        ),
                        childrenRoutes: (
                            <Route
                                index={true}
                                element={
                                    <LazySuspense>
                                        <LazyContentsTable />
                                    </LazySuspense>
                                }
                            />
                        ),
                        icon: <Article />,
                        roles: ['root'],
                        hide: !isTestFeatureEnabled,
                    },

                    // {
                    //     name: 'menu.qaRecords',
                    //     path: '/qa-records',
                    //     component: (
                    //         <LazySuspense>
                    //             <LazyShoppableView qaMode={true} />
                    //         </LazySuspense>
                    //     ),
                    //     icon: <VoicemailOutlinedIcon />,
                    //     hide: hideO2O,
                    //     roles: ['admin'],
                    // },
                    {
                        name: 'menu.webhooks',
                        path: '/webhooks',
                        component: (
                            <LazySuspense>
                                <LazyWebhooksView />
                            </LazySuspense>
                        ),
                        icon: <WebhookOutlined />,
                        hide: !isTestFeatureEnabled,
                        roles: ['admin'],
                    },
                    {
                        name: 'profile.settings.genaiSettings.genai',
                        path: '/genai',
                        component: (
                            <LazySuspense>
                                <LazyGenAIView />
                            </LazySuspense>
                        ),
                        icon: <GenAIcon />,
                        hide: !isTestFeatureEnabled || !genaiEnabled,
                        roles: ['admin'],
                    },
                    {
                        name: 'menu.forms',
                        path: '/forms',
                        component: (
                            <LazySuspense>
                                <LazyFormsView />
                            </LazySuspense>
                        ),
                        icon: <ListAltOutlined />,
                        hide: !enableFormMange,
                        roles: ['root'],
                    },
                    {
                        name: 'menu.services',
                        path: '/services',
                        component: (
                            <LazySuspense>
                                <LazyServicesView />
                            </LazySuspense>
                        ),
                        icon: <DatasetOutlinedIcon />,
                        hide: hideICalendar,
                    },
                    {
                        name: 'menu.taxonomy',
                        path: '/taxonomies',
                        component: (
                            <LazySuspense>
                                <LazyTaxonomies />
                            </LazySuspense>
                        ),
                        icon: <DeviceHubOutlinedIcon />,
                        roles: ['root'],
                    },
                    {
                        name: 'menu.reports',
                        path: '/reports',
                        component: (
                            <LazySuspense>
                                <LazyReports />
                            </LazySuspense>
                        ),
                        icon: <SummarizeOutlinedIcon />,
                    },
                ],
                roles: ['root', 'admin', 'creator', 'editor'],
                collapsible: true,
            },
            {
                namespace: 'menu.manage',
                items: [
                    {
                        name: 'menu.license',
                        path: '/licenses',
                        component: (
                            <LazySuspense>
                                <LazyLicenseView />
                            </LazySuspense>
                        ),
                        icon: <KeyOutlined />,
                    },
                    {
                        name: 'menu.AddOns',
                        path: '/add-ons',
                        component: (
                            <LazySuspense>
                                <LazyAddOnsView />
                            </LazySuspense>
                        ),
                        icon: <SettingsApplicationsOutlined />,
                    },
                    {
                        name: 'menu.customers',
                        path: '/customers',
                        component: (
                            <LazySuspense>
                                <LazyCustomersView />
                            </LazySuspense>
                        ),
                        childrenRoutes: (
                            <React.Fragment>
                                <Route
                                    index={true}
                                    element={
                                        <LazySuspense>
                                            <LazyCustomerTable />
                                        </LazySuspense>
                                    }
                                />
                                <Route
                                    path=":customerId"
                                    element={
                                        <LazySuspense>
                                            <LazyCustomerDetails />
                                        </LazySuspense>
                                    }
                                />
                            </React.Fragment>
                        ),
                        icon: <BusinessCenterOutlined />,
                    },
                    {
                        name: 'menu.videoHelp',
                        path: '/video-help',
                        component: (
                            <LazySuspense>
                                <LazyVideoHelpView />
                            </LazySuspense>
                        ),
                        icon: <HelpOutlineOutlined />,
                    },
                    {
                        name: 'menu.coupons',
                        path: '/coupons',
                        component: (
                            <LazySuspense>
                                <LazyCouponsView />
                            </LazySuspense>
                        ),
                        childrenRoutes: (
                            <React.Fragment>
                                <Route
                                    index={true}
                                    element={
                                        <LazySuspense>
                                            <LazyCouponTable />
                                        </LazySuspense>
                                    }
                                />
                                <Route
                                    exact
                                    path=":couponId"
                                    element={
                                        <LazySuspense>
                                            <LazyCouponDetails />
                                        </LazySuspense>
                                    }
                                />
                            </React.Fragment>
                        ),
                        icon: <DiscountOutlined />,
                    },
                    {
                        name: 'menu.calendarGlobal',
                        path: '/general-calendar',
                        component: (
                            <LazySuspense>
                                <LazyCalendarGlobalView />
                            </LazySuspense>
                        ),
                        icon: <EventOutlined />,
                    },
                    {
                        name: 'menu.formsGeneral',
                        path: '/general-forms',
                        component: (
                            <LazySuspense>
                                <LazyFormsGeneralView />
                            </LazySuspense>
                        ),
                        icon: <ListAltOutlined />,
                        // hide: !enableFormMange,
                    },
                    {
                        name: 'menu.admin.scripts',
                        path: '/admin-scripts',
                        component: (
                            <LazySuspense>
                                <LazyScriptsView />
                            </LazySuspense>
                        ),
                        icon: <CodeOutlined />,
                    },
                    {
                        name: 'menu.applications',
                        path: '/applications',
                        component: (
                            <LazySuspense>
                                <LazyApplicationsView />
                            </LazySuspense>
                        ),
                        childrenRoutes: (
                            <React.Fragment>
                                <Route
                                    index={true}
                                    element={
                                        <LazySuspense>
                                            <LazyApplicationTable />
                                        </LazySuspense>
                                    }
                                />
                                <Route
                                    exact
                                    path=":applicationId"
                                    element={
                                        <LazySuspense>
                                            <LazyApplicationDetails />
                                        </LazySuspense>
                                    }
                                />
                            </React.Fragment>
                        ),
                        icon: <Apps />,
                    },
                    /*
                    {
                        name: 'menu.admin.dashboards',
                        path: '/admin-dashboards',
                        component: (
                            <LazySuspense>
                                <LazyAdminDashboardsView />
                            </LazySuspense>
                        ),
                        childrenRoutes: (
                            <React.Fragment>
                                <Route
                                    index={true}
                                    element={
                                        <LazySuspense>
                                            <LazyAdminDashboardTable />
                                        </LazySuspense>
                                    }
                                />
                                <Route
                                    exact
                                    path=":adminDashboardId"
                                    element={
                                        <LazySuspense>
                                            <LazyAdminDashboardDetails />
                                        </LazySuspense>
                                    }
                                />
                            </React.Fragment>
                        ),
                        icon: <PieChartOutline />,
                    },
                    */
                    {
                        name: 'analytics.shoppable.tabs.delivery',
                        path: '/delivery',
                        component: (
                            <LazySuspense>
                                <LazyOnliveDeliveryDashboard />
                            </LazySuspense>
                        ),
                        icon: <DeliveryIcon />,
                    },
                    {
                        name: 'menu.systemLogAuth',
                        path: '/system-logs',
                        component: (
                            <LazySuspense>
                                <LazySystemLogView />
                            </LazySuspense>
                        ),
                        icon: <EventNoteOutlined />,
                    },
                    {
                        name: 'menu.verticalManagement',
                        path: '/vertical-management',
                        component: (
                            <LazySuspense>
                                <LazyVerticalManagement />
                            </LazySuspense>
                        ),
                        icon: <CategoryOutlinedIcon />,
                    },
                    {
                        name: 'menu.genaiManagement',
                        path: '/genai/administration',
                        component: <MicroFront slug="genai" path="administration" />,
                        icon: <GenAIcon />,
                    },
                ],
                roles: ['root'],
                collapsible: true,
            },
        ],
        [
            hideO2M,
            hideO2O,
            hideShoppable,
            hideIChat,
            hideICalendar,
            hideMedia,
            hideInteractions,
            hideLeads,
            isTestFeatureEnabled,
            hideChannel,
            hideLicense,
            genaiEnabled,
            enableFormMange,
            showInstallations,
        ],
    );
}
