import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const webhookSlice = createSlice({
    name: 'webhook',
    initialState: {
        pending: false,
        savePending: false,
        webhooks: [],
        error: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 0,
        editedWebhooks: [],
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setWebhooks: (state, action) => {
            state.pending = false;
            state.webhooks = action.payload.items;
            state.total = action.payload.total;
            state.count = action.payload.count;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setInitialEditedWebhooks: (state, action) => {
            state.editedWebhooks = action.payload;
        },
        setEditedWebhooks: (state, action) => {
            const index = state.editedWebhooks.findIndex((hook) => hook.id === action.payload.id);
            if (index !== -1) {
                state.editedWebhooks = [
                    ...state.editedWebhooks.slice(0, index),
                    action.payload,
                    ...state.editedWebhooks.slice(index + 1),
                ];
            } else {
                state.editedWebhooks = [...state.editedWebhooks, action.payload];
            }
        },
        deleteEditedWebhooks: (state, action) => {
            const index = state.editedWebhooks.findIndex((hook) => hook.id === action.payload.id);
            if (index !== -1) {
                state.editedWebhooks = [
                    ...state.editedWebhooks.slice(0, index),
                    ...state.editedWebhooks.slice(index + 1),
                ];
            }
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addWebhook: (state, action) => {
            state.savePending = false;
            state.webhooks = [action.payload, ...state.webhooks];
        },
        editWebhook: (state, action) => {
            state.savePending = false;
            const index = state.webhooks.findIndex((webhook) => webhook.id === action.payload.id);
            if (index !== -1) {
                state.webhooks = [
                    ...state.webhooks.slice(0, index),
                    action.payload,
                    ...state.webhooks.slice(index + 1),
                ];
            }
        },
        deleteWebhook: (state, action) => {
            state.pending = false;
            const index = state.webhooks.findIndex((user) => user.id === action.payload.id);
            if (index !== -1) {
                state.webhooks = [...state.webhooks.slice(0, index), ...state.webhooks.slice(index + 1)];
            }
        },
    },
});

export const {
    start,
    stop,
    setWebhooks,
    setEditedWebhooks,
    setInitialEditedWebhooks,
    deleteEditedWebhooks,
    setError,
    editWebhook,
    deleteWebhook,
    addWebhook,
    startSave,
    stopSave,
} = webhookSlice.actions;

export const getWebhooks = (state) => state.webhook.webhooks;
export const getWebhooksPending = (state) => state.webhook.pending;
export const getWebhooksSavePending = (state) => state.webhook.savePending;
export const getEditedWebhooks = (state) => state.webhook.editedWebhooks;
export const getWebhooksCurrentPage = (state) => state.webhook.currentPage;
export const getWebhooksTotalPages = (state) => state.webhook.totalPages;

export const fetchWebhooksAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('webhooks' + query)
                .then((response) => {
                    dispatch(setWebhooks(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const createWebhookAction =
    ({ data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            const dto = { ...data, channels: data?.channels?.map((channel) => channel?.id) || [] };
            api.post('webhooks', dto)
                .then((response) => {
                    dispatch(addWebhook(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure();
                    }
                    dispatch(setError(error));
                    dispatch(stopSave());
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const updateWebhookAction =
    ({ id, data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            const dto = { ...data, channels: data?.channels?.map((channel) => channel?.id) || [] };
            api.put('webhooks/' + id, dto)
                .then((response) => {
                    dispatch(editWebhook(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure();
                    }
                    dispatch(setError(error));
                    dispatch(stopSave());
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteWebhookAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(start(id));
            api.delete('webhooks/' + id)
                .then((response) => {
                    dispatch(deleteWebhook(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export default webhookSlice.reducer;
