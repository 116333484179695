import api from 'api';
import axios from 'axios';
import pLimit from 'p-limit';

const defaultUploadOption = {
    size: 5 * 1024 * 1024, // 5MB
    path: null,
    onInit: (size) => {
        console.debug(size);
    },
    onProgress: (loaded, total) => {
        console.debug(loaded, total);
    },
    onComplete: (url) => {
        console.debug(url);
    },
};

const startUpload = async (filename, path, mimeType, signal) => {
    const response = await api.post(
        'file/start-upload',
        {
            filename,
            path,
            mimeType,
        },
        {
            signal,
        },
    );
    return response.data;
};

const abortUpload = async (uploadId, key) => {
    const response = await api.put('file/abort-upload', {
        uploadId,
        key,
    });
    return response.data;
};

const uploadPart = async (
    buffer,
    mimeType,
    uploadId,
    partNumber,
    key,
    onProgress = (partNumber, loaded, total) => {},
    onComplete = (partNumber) => {},
    signal,
) => {
    if (signal?.aborted) {
        return Promise.reject(new DOMException('Aborted', 'AbortError'));
    }
    try {
        const file = new Blob([buffer], { type: mimeType });
        let formData = new FormData();
        formData.append('file', file);
        formData.append('uploadId', uploadId);
        formData.append('partNumber', partNumber);
        formData.append('key', key);
        const response = await api.post('file/upload-file-part', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                onProgress(partNumber, progressEvent.loaded, buffer.size);
            },
            signal,
        });
        onComplete(partNumber);
        return response.data;
    } catch (error) {
        return Promise.reject({ PartNumber: partNumber, error });
    }
};

const completeUpload = async (uploadId, succeededParts, key, signal) => {
    const response = await api.put(
        'file/complete-upload',
        {
            uploadId,
            succeededParts,
            key,
        },
        {
            signal,
        },
    );
    return response.data;
};

export const uploadFile = (file, options, signal) => {
    options = { ...defaultUploadOption, ...options };
    const chunkSize = options.size ?? defaultUploadOption.size;
    const filename = file.name;
    const mimeType = file.type;
    const fileReader = new FileReader();
    const limit = pLimit(5);

    fileReader.onload = async (event) => {
        if (!event.target || !event.target.result) {
            Promise.reject(new Error('Can not read file data!'));
            return;
        }
        const data = event.target.result;
        const size = data.byteLength;
        options.onInit(size);
        const iterations = Math.ceil(size / chunkSize);
        const arr = Array.from(Array(iterations).keys());
        const partProgress = new Array(iterations).fill(0);
        const complete = new Array(iterations).fill(false);
        let uploadId, key, interval;
        try {
            const upload = await startUpload(filename, options.path, mimeType, signal);
            uploadId = upload.uploadId;
            key = upload.key;

            interval = setInterval(() => {
                let loaded = partProgress.reduce(
                    (sum, progress, index) => sum + (progress || 0) * (complete[index] ? 1 : 0.5),
                    0,
                );
                options.onProgress(loaded, size);
            }, 1000);

            signal.onabort = () => {
                abortUpload(uploadId, key);
            };

            const uploads = arr.map((item) => {
                const PartNumber = item + 1;
                return limit(() =>
                    uploadPart(
                        data.slice((PartNumber - 1) * chunkSize, Math.min(PartNumber * chunkSize, data.byteLength)),
                        mimeType,
                        uploadId,
                        PartNumber,
                        key,
                        (partNumber, loaded, total) => {
                            partProgress[partNumber - 1] = loaded;
                        },
                        (partNumber) => {
                            complete[partNumber - 1] = true;
                        },
                        signal,
                    ),
                );
            });

            const parts = await Promise.allSettled(uploads);

            const failedParts = parts.filter((part) => part.status === 'rejected').map((part) => part.reason);
            const succeededParts = parts.filter((part) => part.status === 'fulfilled').map((part) => part.value);

            let retriedParts = [];
            if (failedParts.length !== 0 && !signal?.aborted) {
                const failedUploads = failedParts.map(({ PartNumber }) =>
                    limit(() =>
                        uploadPart(
                            data.slice((PartNumber - 1) * chunkSize, Math.min(PartNumber * chunkSize, data.byteLength)),
                            mimeType,
                            uploadId,
                            PartNumber,
                            key,
                            (partNumber, loaded, total) => {
                                partProgress[partNumber - 1] = loaded;
                            },
                            (partNumber) => {
                                complete[partNumber - 1] = true;
                            },
                            signal,
                        ),
                    ),
                );
                retriedParts = await Promise.all(failedUploads);
            }
            succeededParts.push(...retriedParts);
            const completeResponse = await completeUpload(
                uploadId,
                succeededParts.sort((a, b) => a.PartNumber - b.PartNumber),
                key,
                signal,
            );
            clearInterval(interval);
            options.onComplete(completeResponse);
        } catch (err) {
            clearInterval(interval);
            console.error(err, err.stack);
        }
    };

    fileReader.onerror = (error) => {
        console.error('UPLOAD ERROR', error);
        Promise.reject(error);
    };

    fileReader.readAsArrayBuffer(file);
};

const getSignedUrlUpload = async (filename, path, mimeType, bucketType, loadDirection) => {
    const response = await api.post('file/generate-signed-url', {
        filename,
        path,
        mimeType,
        bucketType,
        loadDirection,
    });
    return response.data;
};

export const uploadFileSignedUrl = async (file, options) => {
    const filename = file.name;
    const mimeType = file.type;
    const bucketType = options.bucketType;
    const signal = options.signal;
    const { onProgress } = options;
    try {
        const signedUrlUpload = await getSignedUrlUpload(
            filename,
            options.path,
            mimeType,
            bucketType || 'resources',
            'up',
        );
        const uploadURL = signedUrlUpload.signedUrl;
        const key = signedUrlUpload.key;
        const url = signedUrlUpload.url;

        const config = {
            onUploadProgress: function (progressEvent) {
                onProgress && onProgress(progressEvent.loaded, progressEvent.total);
            },
            signal,
        };
        await axios.put(uploadURL, file, config);
        return { key, url };
    } catch (err) {
        console.error(err);
    }
};

const abortUploadSignedUrl = async (uploadId, key, bucketType) => {
    const response = await api.put('file/abort-upload-signed-url', {
        uploadId,
        key,
        bucketType,
    });
    return response.data;
};

// const uploadPartSignedUrl = async (url, buffer, mimeType, partNumber, onProgress = (partNumber, loaded, total) => {}, onComplete = (partNumber) => {}, signal) => {
//     if (signal?.aborted) {
//         return Promise.reject(new DOMException('Aborted', 'AbortError'));
//     }
//     try {
//         // const file = new Blob([buffer], {type: mimeType});
//         const response = await axios.put(url, buffer, {
//             onUploadProgress: progressEvent => {
//                 onProgress(partNumber, progressEvent.loaded, buffer.size);
//             },
//             signal,
//         });
//         onComplete(partNumber);
//         return {
//             ETag: response.headers.get('Etag'),
//             PartNumber: partNumber
//         };
//     } catch (error) {
//         return Promise.reject({PartNumber: partNumber, error});
//     }
// }

const startUploadMultipart = async (filename, path, mimeType, bucketType, numberOfParts, signal) => {
    const response = await api.post(
        'file/start-upload-signed-url',
        {
            filename,
            path,
            mimeType,
            bucketType,
            numberOfParts,
        },
        {
            signal,
        },
    );
    return response.data;
};

/**
 * 
 * @param {*} uploadId 
 * @param {*} succeededParts 
 * @param {*} bucketType 
 * @param {*} key 
 * @param {*} signal 
 * @param {*} responseType "url" | "uri"
 * @returns 
 */
const completeUploadSigned = async (uploadId, succeededParts, bucketType, key, signal, responseType = 'url') => {
    const response = await api.put(
        'file/complete-upload-signed-url',
        {
            uploadId,
            succeededParts,
            key,
            bucketType,
        },
        {
            signal,
            params: {
                responseType
            }
        },
    );
    return response.data;
};

export const uploadFileMultipartSignedUrl = async (file, options, signal) => {
    options = { ...defaultUploadOption, ...options };
    const chunkSize = options.size ?? defaultUploadOption.size;
    const filename = file.name;
    const mimeType = file.type;
    const bucketType = options.bucketType;
    const limit = pLimit(5);

    const readBlock = async (blob) => {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.onload = async (event) => {
                    if (!event.target || !event.target.result) {
                        reject(new Error('Can not read file data!'));
                        return;
                    }
                    const data = event.target.result;
                    resolve(data);
                };
                fileReader.onerror = (error) => {
                    console.error('UPLOAD ERROR', error);
                    reject(error);
                };
                fileReader.readAsArrayBuffer(blob);
            } catch (error) {
                console.error('Error Read Buffer', error);
                reject(error);
            }
        });
    };

    const uploadPartSignedUrl = async (
        url,
        block,
        mimeType,
        partNumber,
        onProgress = (partNumber, loaded, total) => {},
        onComplete = (partNumber) => {},
        signal,
    ) => {
        return new Promise((resolve, reject) => {
            if (signal?.aborted) {
                reject(new DOMException('Aborted', 'AbortError'));
            }
            readBlock(block)
                .then((data) => {
                    axios
                        .put(url, data, {
                            onUploadProgress: (progressEvent) => {
                                onProgress(partNumber, progressEvent.loaded, data.size);
                            },
                            signal,
                        })
                        .then((response) => {
                            onComplete(partNumber);
                            resolve({
                                ETag: response.headers.get('Etag'),
                                PartNumber: partNumber,
                            });
                        })
                        .catch((error) => {
                            reject({ PartNumber: partNumber, error });
                        });
                })
                .catch((error) => {
                    reject({ PartNumber: partNumber, error });
                });
        });
    };

    try {
        const size = file.size;

        options.onInit(size);
        const iterations = Math.ceil(size / chunkSize);
        const arr = Array.from(Array(iterations).keys());
        const partProgress = new Array(iterations).fill(0);
        const complete = new Array(iterations).fill(false);
        const upload = await startUploadMultipart(
            filename,
            options.path,
            mimeType,
            bucketType,
            `${iterations}`,
            signal,
        );
        const uploadId = upload.uploadId;
        const key = upload.key;
        const signedUrls = upload.signedUrls;

        let interval;
        interval = setInterval(() => {
            let loaded = partProgress.reduce((sum, progress, index) => sum + (progress || 0), 0);
            options.onProgress(loaded, size);
        }, 1000);

        signal.onabort = () => {
            abortUploadSignedUrl(uploadId, key, bucketType);
        };
        const uploads = arr.map((item) => {
            const PartNumber = item + 1;
            return limit(() =>
                uploadPartSignedUrl(
                    signedUrls[PartNumber - 1],
                    file.slice((PartNumber - 1) * chunkSize, Math.min(PartNumber * chunkSize, file.size)),
                    mimeType,
                    PartNumber,
                    (partNumber, loaded, total) => {
                        partProgress[partNumber - 1] = loaded;
                    },
                    (partNumber) => {
                        complete[partNumber - 1] = true;
                    },
                    signal,
                ),
            );
        });

        const parts = await Promise.allSettled(uploads);
        const failedParts = parts.filter((part) => part.status === 'rejected').map((part) => part.reason);
        const succeededParts = parts.filter((part) => part.status === 'fulfilled').map((part) => part.value);

        let retriedParts = [];
        if (failedParts.length !== 0 && !signal?.aborted) {
            const failedUploads = failedParts.map(({ PartNumber }) =>
                limit(() =>
                    uploadPartSignedUrl(
                        signedUrls[PartNumber - 1],
                        file.slice((PartNumber - 1) * chunkSize, Math.min(PartNumber * chunkSize, file.size)),
                        mimeType,
                        PartNumber,
                        (partNumber, loaded, total) => {
                            partProgress[partNumber - 1] = loaded;
                        },
                        (partNumber) => {
                            complete[partNumber - 1] = true;
                        },
                        signal,
                    ),
                ),
            );
            retriedParts = await Promise.all(failedUploads);
        }
        succeededParts.push(...retriedParts);
        const completeResponse = await completeUploadSigned(
            uploadId,
            succeededParts.sort((a, b) => a.PartNumber - b.PartNumber),
            bucketType,
            key,
            signal,
            options.responseType
        );
        clearInterval(interval);
        signal.onabort = null;
        options.onComplete(completeResponse);
    } catch (e) {
        if (options.onError) {
            options.onError(e);
        }
    }
};

export const handleUploadFileSignedUrl = ({
    file,
    size,
    onInit,
    onProgress,
    onComplete,
    onError,
    bucketType,
    signal,
    path,
}) => {
    uploadFileMultipartSignedUrl(
        file,
        {
            size: size || 1024 * 1024 * 100, // 100MB
            ...(path ? { path: path } : {}),
            onInit: (size) => {
                onInit && onInit();
            },
            onProgress: (loaded, total) => {
                onProgress && onProgress(loaded, total);
            },
            onComplete: (url) => {
                onComplete && onComplete(url);
            },
            onError: (error) => {
                onError && onError(error);
            },
            bucketType: bucketType || 'resources',
        },
        signal,
    );
};
