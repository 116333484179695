import TextFieldForPicker from 'common/components/TextFieldForPicker';
import { AsYouType } from 'libphonenumber-js';
import moment from 'moment-timezone';

import { findDialCodeByCountryCode } from './countryList';

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
export const IS_STAGING = process.env.REACT_APP_ENVIRONMENT === 'staging';
export const IS_DEVELOP = process.env.REACT_APP_ENVIRONMENT === 'develop';
const lightColors = ['#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB'];

export function getColor(index) {
    return lightColors[index % lightColors.length];
}

export const getNameFromType = (type) => {
    if (type === 'quiz') {
        return 'Quiz';
    } else if (type === 'survey') {
        return 'Survey';
    } else if (type === 'auction') {
        return 'Auction';
    } else {
        return '';
    }
};

export const isOnliveUser = () => {
    let user = localStorage?.getItem('user');
    return user && JSON.parse(user)?.isOnliveUser;
};

export const generateStateID = () => {
    const dateKey = Date.now().toString(36);
    const random = Math.random().toString(36).substring(2, 12);
    const gap = 10 - random.length;
    return random + dateKey.substring(0, gap);
};

export const generateRandomId = () => {
    return generateStateID();
};

function getActiveSearchIndex() {
    const userData = localStorage?.getItem('user');
    const organizationData = localStorage?.getItem('organization');

    if (userData && organizationData) {
        const organizationId = JSON.parse(userData)?.organizationId;
        const providerId = JSON.parse(organizationData)?.providerId;
        return `${organizationId}_${providerId}`;
    }
}

export function buildURLParams(queryParams) {
    return Object.keys(queryParams)
        .filter((key) => queryParams[key] || queryParams[key] === 0)
        .map((key) => {
            return `${key}=${queryParams[key]}`;
        })
        .join('&');
}

export const activeSearchIndex = getActiveSearchIndex();

const getToken = (url) => {
    const urlSplitted = url.split('/');
    return urlSplitted[urlSplitted.length - 1];
};

const getMeetToken = (actor, user, params = false) => {
    const token = getToken(actor.url);

    return token;
};

export const handleJoinNow = (actor, user, callback, event) => {
    if (event.type === 'one_to_one') {
        if (event.publicId) {
            window.onliveManager.services.onliveMeet.joinToMeet(event.publicId);
        } else {
            window.onliveManager.services.onliveMeet.jointToMeetWithToken(getMeetToken(actor, user));
        }
        callback();
    } else {
        const url = getUrl(null, actor, user);
        window.open(url, '_blank');
        callback();
    }
};

export const getUrl = (event, actor = null, user = null) => {
    if (!actor) {
        return `${process.env.REACT_APP_EVENTS_URL}/event/${event?.id}`;
    } else {
        if (event?.type === 'one_to_one' && actor.role === 'guest') {
            return `${process.env.REACT_APP_EVENTS_URL}/event/${event?.id}/?token=${getToken(actor.url)}`;
        } else {
            return event?.type === 'one_to_one'
                ? `${process.env.REACT_APP_ONE2ONE_URL}#/?token=${getMeetToken(actor, user, true)}`
                : actor.url;
        }
    }
};

export const getRecapUrl = (iVideoId) => `${process.env.REACT_APP_EVENTS_URL}/recap/${iVideoId}`;

export const RenderDateInput = (params) => (
    <TextFieldForPicker
        {...params}
        InputProps={{
            ...params.InputProps,
            // sx: {
            //     padding: 1,
            // },
            inputProps: {
                ...params.inputProps,
                //   placeholder,
                // sx: { paddingTop: 1 },
            },
        }}
    />
);

export const objectToB64 = (object) => {
    return window.btoa(unescape(encodeURIComponent(JSON.stringify(object))));
};

export const b64ToObject = (str) => {
    return JSON.parse(decodeURIComponent(escape(window.atob(str))));
};

export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const navigateTo = (operation, view, date) => {
    let newDate;
    const sign = operation === 'next' ? 1 : -1;
    if (view === 'month') {
        newDate = new Date(date.setMonth(date.getMonth() + sign));
    } else if (view === 'week') {
        newDate = new Date(date.setDate(date.getDate() + 7 * sign));
    } else {
        newDate = new Date(date.setDate(date.getDate() + sign));
    }
    return newDate;
};

export const checkBool = (bool) =>
    typeof bool === 'boolean' || (typeof bool === 'object' && bool !== null && typeof bool.valueOf() === 'boolean');

export const getPageLimit = () => {
    const width = window.innerWidth;
    if (width < 1360) {
        return 6;
    } else if (width >= 1360 && width < 1625) {
        return 8;
    } else if (width >= 1625 && width < 1890) {
        return 10;
    } else if (width >= 1890) {
        return 12;
    }
};

export const containsText = (text, searchText) => text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;
export const getCountryDialCode = (phone, defaultValue = 'ES') => {
    // Set default country code to US if no real country code is specified
    const defaultCountryCode = phone?.substr(0, 1) !== '+' ? defaultValue : null;
    let formatted = phone ? new AsYouType(defaultCountryCode).input(phone) : '';
    let dialCode = '';
    let withoutDialCode = formatted;

    if (defaultCountryCode === defaultValue) {
        dialCode = findDialCodeByCountryCode(defaultValue);
        formatted = dialCode + ' ' + formatted;
    } else {
        const parts = formatted.split(' ');
        dialCode = parts.length > 1 ? parts.shift() : '';
        withoutDialCode = parts.join(' ');
    }

    return {
        formatted,
        withoutDialCode,
        dialCode,
    };
};

/**
 * Check if a date is in UTC format
 * @param {string} date - The date to check
 * @returns {boolean} - True if the date is in UTC format, false otherwise
 */
export const isUTC = (date) => {
    if (typeof date !== 'string') return false;
    const parsedDate = moment(date);
    const utcDate = parsedDate.utc().format();
    return date.startsWith(utcDate.slice(0, -1));
};

/**
 * Parse string to number. Return 0 if the value is not a number
 * @param {string} value
 * @returns {number}
 */
export const parseNUmber = (value) => {
    if (value === undefined || value === null) {
        return 0;
    }

    const stringValue = typeof value === 'string' ? value : value.toString();

    // Delete white spaces and commas
    let cleanValue = stringValue.replace(/\s+/g, '').replace(/(?<=\d),(?=\d{3}(?:,|\.|$))/g, '');

    // If there are a comma before last two digits, covert to dot
    cleanValue = cleanValue.replace(/,(\d{1,2})$/, '.$1');

    const result = parseFloat(cleanValue);
    return isNaN(result) ? 0 : result;
};
