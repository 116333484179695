import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Accordion,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    TextField,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import calendlyCalendar from 'common/assets/calendly.png';
import googleCalendar from 'common/assets/gCalendar.png';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import CustomTextEvent from '../CustomTextEvent/CustomTextEvent';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';

const defaultTextStartPage = [
    {
        value: 'The show is about to start. The moderator is getting everything ready.',
        languageCode: 'en',
    },
    {
        value: 'El show comenzará pronto. El moderador está preparándolo todo.',
        languageCode: 'es',
    },
    {
        value: 'Le Show va bientôt commencer. Le modérateur est en train de tout préparer.',
        languageCode: 'fr',
    },
    {
        value: 'ILo show sta per cominciare. Il moderatore sta preparando tutto.',
        languageCode: 'it',
    },
    {
        value: 'O show começará em breve. O moderador está a preparar tudo.',
        languageCode: 'pt',
    },
    {
        value: 'O show começará em breve. O moderador está preparando tudo.',
        languageCode: 'pt-br',
    },
    {
        value: 'Die Show beginnt in Kürze. Der Moderator bereitet alles vor.',
        languageCode: 'de',
    },
    {
        value: 'Show rozpocznie się już wkrótce. Trwają przygotowania moderatora.',
        languageCode: 'pl',
    },
    {
        value: 'Шоу ось-ось розпочнеться. Модератор все готує.',
        languageCode: 'uk',
    },
    {
        value: 'Spectacolul este pe cale să înceapă. Moderatorul pregătește totul.',
        languageCode: 'ro',
    },
    {
        value: 'De voorstelling zal spoedig beginnen. De moderator bereidt alles voor.',
        languageCode: 'nl-be',
    },
    {
        value: 'Showen börjar snart. Moderatorn förbereder allt.',
        languageCode: 'sv',
    },
];

const defaultTextEndPage = [
    {
        value: 'The show is over but you still have time to make the most of it. Review the products shown during the event and make your purchase before the time runs out.\n\nHurry up! Time is running out.\n',
        languageCode: 'en',
    },
    {
        value: 'El evento ha terminado pero todavía estás a tiempo de sacarle el máximo partido. Revisa los productos que se han presentado durante el evento y termina tu compra antes de que se acabe el tiempo.\n\n¡Date prisa! El tiempo se está acabando\n',
        languageCode: 'es',
    },
    {
        value: 'L’événement est terminé, mais il vous reste encore du temps pour en profiter au maximum. Revoyez les produits présentés pendant l’événement et terminez vos achats avant que le temps ne soit écoulé.\n\nDépêchez-vous ! C’est bientôt terminé\n',
        languageCode: 'fr',
    },
    {
        value: "L'evento si è concluso, ma fai ancora in tempo ad approfittarne al massimo. Controlla i prodotti che sono stati presentati durante l'evento e concludi l'acquisto prima che scada il tempo.\n\nFai presto! Il tempo sta scadendo\n",
        languageCode: 'it',
    },
    {
        value: 'O evento terminou, mas ainda vai a tempo de o aproveitar ao máximo. Reveja os produtos que foram apresentados durante o evento e termine a sua compra antes que o tempo se esgote.\n\nDespache-se! O tempo está a acabar\n',
        languageCode: 'pt',
    },
    {
        value: 'O evento terminou, mas ainda há tempo de aproveitar ao máximo. Revise os produtos que foram apresentados durante o evento e termine sua compra antes que o tempo acabe.\n\nCorra! O tempo está acabando\n',
        languageCode: 'pt-br',
    },
    {
        value: 'Die Veranstaltung ist vorbei, aber Sie haben noch Zeit, das Beste daraus zu machen. Schauen Sie sich die Produkte an, die während der Show präsentiert wurden, und schließen Sie Ihren Kauf ab, bevor die Zeit abläuft.\n\nBeeilen Sie sich! Die Zeit läuft ab\n',
        languageCode: 'de',
    },
    {
        value: 'Wydarzenie zostało zakończone, ale możesz jeszcze maksymalnie je wykorzystać. Przejrzyj produkty zaprezentowane podczas wydarzenia i zakończ zakupy przed upływem wyznaczonego czasu.\n\nPospiesz się! Czas się kończy\n',
        languageCode: 'pl',
    },
    {
        value: 'Шоу закінчилося, але у вас ще є час, щоб отримати максимум від нього. Перегляньте товари, представлені під час події, і зробіть покупку до закінчення часу.\n\nПоспішайте! Час спливає.\n',
        languageCode: 'uk',
    },
    {
        value: 'Spectacolul s-a terminat, dar mai ai timp să-l profiti la maximum. Examinați produsele afișate în timpul evenimentului și efectuați achiziția înainte de expirarea timpului.\n\nGrăbeşte-te! Timpul se scurge.\n',
        languageCode: 'ro',
    },
    {
        value: 'Het evenement is voorbij, maar je hebt nog tijd om er het beste uit te halen. Bekijk de producten die tijdens het evenement zijn gepresenteerd en voltooi uw aankoop voordat de tijd om is.\n\nWel opschieten! De klok tikt door.\n',
        languageCode: 'nl-be',
    },
    {
        value: 'Evenemanget har avslutats men du har fortfarande tid att få ut det mesta av det. Granska produkterna som har presenterats under eventet och avsluta ditt köp innan tiden går ut.\n\nSkynda sig! Tiden rinner iväg.\n',
        languageCode: 'sv',
    },
];

const EventSettings = (props) => {
    const {
        notifyMeValue,
        calendar,
        eventFinalizedTime,
        setCalendar,
        toggleChange,
        remarketingEmail,
        handleSubmit,
        hasChanges,
        enableRegister,
        enableRegisterInsideTheShow,
        enableDiscount,
        enableGift,
        enableShareLink,
        enableShowNumberOfParticipants,
        enableEnterEventAsGuest,
        textStartPageEvent,
        textEndPageEvent,
        toggleMultipleSettingsChange,
    } = props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };
    const settingsDirty = useSelector(getSettingsDirty);
    const isAuthorize = useAuthLicenseAddOns();
    const [eventFinalizedTimeValid, setEventFinalizedTimeValid] = useState(true);

    const handleCheckChange = (event, settingName) => {
        const checked = event.target.checked;
        const settingsChange = [
            {
                settingKey: settingName,
                settingValue: checked,
            },
        ];
        if (settingName === SettingsKey.ENABLE_ENTER_EVENT_AS_GUEST && !checked && !enableRegister) {
            settingsChange.push({ settingKey: SettingsKey.ENABLE_REGISTER, settingValue: true });
        } else if (settingName === SettingsKey.ENABLE_REGISTER && !checked && !enableEnterEventAsGuest) {
            settingsChange.push({ settingKey: SettingsKey.ENABLE_ENTER_EVENT_AS_GUEST, settingValue: true });
        }
        toggleMultipleSettingsChange(settingsChange);
    };

    const handleCalendarChange = (event) => {
        if (event.target.name === 'google') {
            setCalendar({ google: true, calendly: false });
        } else {
            setCalendar({ google: false, calendly: true });
        }
    };

    const handleNumberValueChange = (event, settingName) => {
        setEventFinalizedTimeValid(event.target.value <= 120 && event.target.value > 0);
        toggleChange(settingName, +event.target.value);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.eventSettings.events')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <div style={{ paddingBottom: '20px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <ItemSettings
                            label={t('profile.settings.eventSettings.notifyMe')}
                            onChange={handleCheckChange}
                            name={'widgetNotifyMe'}
                            keySettings={SettingsKey.ENABLE_NOTIFY_ME}
                            value={notifyMeValue}
                        />

                        <Box
                            sx={{
                                display:
                                    settingsDirty && hasChanges && eventFinalizedTimeValid ? 'inline-flex' : 'none',
                            }}
                        >
                            <Button
                                className={classes.redColor}
                                variant="contained"
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('profile.settings.eventSettings.save')}
                            </Button>
                        </Box>
                    </Box>
                </div>

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.remarketingEmail')}
                    onChange={handleCheckChange}
                    name={'remarketingEmail'}
                    keySettings={SettingsKey.ENABLE_REMARKETING_EMAIL}
                    value={remarketingEmail}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.organization.enableEnterEventAsGuest')}
                    onChange={handleCheckChange}
                    name={'enableEnterEventAsGuest'}
                    keySettings={SettingsKey.ENABLE_ENTER_EVENT_AS_GUEST}
                    value={enableEnterEventAsGuest}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.settings.eventSettings.enableRegisterInHomePage')}
                    onChange={handleCheckChange}
                    name={'enableRegister'}
                    keySettings={SettingsKey.ENABLE_REGISTER}
                    value={enableRegister}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.settings.eventSettings.enableRegisterInsideTheShow')}
                    onChange={handleCheckChange}
                    name={'enableRegisterInsideTheShow'}
                    keySettings={SettingsKey.ENABLE_REGISTER_INSIDE_THE_SHOW}
                    value={enableRegisterInsideTheShow}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.organization.enableDiscount')}
                    onChange={handleCheckChange}
                    name={'enableDiscount'}
                    keySettings={SettingsKey.ENABLE_DISCOUNT_CODES}
                    value={enableDiscount}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.organization.enableGifts')}
                    onChange={handleCheckChange}
                    name={'enableGift'}
                    keySettings={SettingsKey.ENABLE_SHOPIFY_GIFT_PRODUCTS}
                    value={enableGift}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.organization.enableShareLink')}
                    onChange={handleCheckChange}
                    name={'enableShareLink'}
                    keySettings={SettingsKey.ENABLE_EVENT_SHARE_LINK}
                    value={enableShareLink}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.organization.enableShowNumberOfParticipants')}
                    onChange={handleCheckChange}
                    name={'enableShowNumberOfParticipants'}
                    keySettings={SettingsKey.ENABLE_SHOW_NUMBER_OF_PARTICIPANTS}
                    value={enableShowNumberOfParticipants}
                    paddingBottom
                />

                <div style={{ paddingBottom: '20px', marginTop: '5px' }}>
                    <Typography className={classes.text}>{t('settings.events.finalizedTimeTitle')}</Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: '60%', marginTop: '10px' }}
                    >
                        <Box style={{ width: '100%' }}>
                            <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                <FormControl>
                                    <TextField
                                        style={{ width: '100%' }}
                                        size="small"
                                        label={t('settings.events.finalizedTimePh')}
                                        variant="outlined"
                                        type="number"
                                        InputProps={{ inputProps: { min: 5, max: 120 } }}
                                        value={+eventFinalizedTime}
                                        onChange={(e) => handleNumberValueChange(e, SettingsKey.EVENT_FINALIZED_TIME)}
                                    />
                                    {!eventFinalizedTimeValid && (
                                        <FormHelperText error={true}>
                                            {t('settings.events.finalizedTimeError')}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </div>

                <div>
                    <Typography className={classes.text}>
                        {t('profile.settings.eventSettings.calendarIntegration')}
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            disabled={!isAuthorize(['calendarIntegration'])}
                            className={classes.checkboxLabel + ' ' + classes.pb10px}
                            control={
                                <Checkbox
                                    checked={calendar.calendly}
                                    onChange={handleCalendarChange}
                                    name="calendly"
                                    className={classes.checkBox}
                                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                                />
                            }
                            label={
                                <div className={classes.calendarContent}>
                                    <div className={classes.calendarBody}>
                                        <div className={classes.image + ' ' + classes.calendly}>
                                            <img src={calendlyCalendar} alt={''} />
                                        </div>
                                        <div className={classes.calendarInfo}>
                                            <Typography className={classes.text}>
                                                {t('profile.settings.eventSettings.calendly')}
                                            </Typography>
                                            <Typography className={classes.text + ' ' + classes.subTitle}>
                                                {t('profile.settings.eventSettings.calendlySub')}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        <FormControlLabel
                            disabled={!isAuthorize(['calendarIntegration'])}
                            className={classes.checkboxLabel + ' ' + classes.pb10px}
                            control={
                                <Checkbox
                                    checked={calendar.google}
                                    onChange={handleCalendarChange}
                                    name="google"
                                    className={classes.checkBox}
                                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                                />
                            }
                            label={
                                <div className={classes.calendarContent}>
                                    <div className={classes.calendarBody}>
                                        <div className={classes.image + ' ' + classes.gCalendar}>
                                            <img src={googleCalendar} alt={''} />
                                        </div>
                                        <div className={classes.calendarInfo}>
                                            <Typography className={classes.text}>
                                                {t('profile.settings.eventSettings.googleCalendar')}
                                            </Typography>
                                            <Typography className={classes.text + ' ' + classes.subTitle}>
                                                {t('profile.settings.eventSettings.googleCalendarSub')}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </FormGroup>
                </div>

                <div className={classes.pb10px}>
                    <CustomTextEvent
                        valueSetting={textStartPageEvent}
                        keySetting={SettingsKey.TEXT_START_PAGE_EVENT}
                        label={t('profile.settings.eventSettings.configureTextStartPage')}
                        toggleChange={toggleChange}
                        initText={defaultTextStartPage}
                    />
                </div>
                <div>
                    <CustomTextEvent
                        valueSetting={textEndPageEvent}
                        keySetting={SettingsKey.TEXT_END_PAGE_EVENT}
                        label={t('profile.settings.eventSettings.configureTextEndPage')}
                        toggleChange={toggleChange}
                        initText={defaultTextEndPage}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default EventSettings;
