import { makeStyles } from 'tss-react/mui';

const useStylesTermsConditions = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            padding: '50px 50px 8px 50px',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                padding: '8px',
            },
        },
        changeLang: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
            gap: '15px',
        },
        disabledText: {
            color: 'gray',
            cursor: 'not-allowed',
        },
    };
});

export default useStylesTermsConditions;
