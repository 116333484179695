import { makeStyles } from 'tss-react/mui';

const useStylesIntegrationOnliveForm = makeStyles()((theme) => {
    return {
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        mr1: {
            marginRight: theme.spacing(1),
        },
        fileUploadedLabel: {
            display: 'flex',
            alignItems: 'center',
        }
    };
});

export default useStylesIntegrationOnliveForm;
