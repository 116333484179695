import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openNestedDialog } from '../../../redux/slices/nestedDialogSlice';
import ContactCreate from '../../../app/contacts/contactForm/ContactCreate';


const CreateContactAction = ({ payload, onComplete }) => {
    const dispatch = useDispatch();
    useEffect(() => dispatch(
        openNestedDialog(<ContactCreate seed={payload} onSubmit={onComplete} onCancel={() => onComplete(null)}/>)
        // eslint-disable-next-line
    ), []);
};

export default CreateContactAction;
