import { useTranslation } from 'react-i18next';
import { findSettingTranslation } from '../../settings/settingTranslation';
import { CustomStatusChip } from './CustomStatusChip';
import { getStatusSetting } from './helpers/StatusHelper';

const DynamicStatusChip = ({ status, setting, size = 'small' }) => {
    const { t, i18n } = useTranslation();
    const statusSetting = getStatusSetting(status, setting);

    const getLabel = () => {
        const label = statusSetting?.label
            ? findSettingTranslation(statusSetting.label, i18n.language) ||
              findSettingTranslation(statusSetting.label, 'en') ||
              t(statusSetting.label)
            : status;
        return label.toUpperCase();
    };

    const getStatusChipConfig = () => {
        return {
            icon: statusSetting?.icon || null,
            size,
            label: getLabel(),
            styleClass: statusSetting?.style || null,
        };
    };

    if (!status) {
        return null;
    }

    return <CustomStatusChip {...getStatusChipConfig()} />;
};

export default DynamicStatusChip;
