import { Box, CircularProgress } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplatesAction, getPendingTemplate, getTemplates } from 'redux/slices/ocalendarSlice';
import AvailabilityList from './AvailabilityList';

const Availability = ({ requestUser, scrollable }) => {
    const dispatch = useDispatch();
    const templates = useSelector(getTemplates);
    const pending = useSelector(getPendingTemplate);

    const loadData = useCallback(() => {
        dispatch(fetchTemplatesAction(requestUser));
    }, [dispatch, requestUser]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Box sx={{ my: 2 }}>
            {!pending ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <AvailabilityList templates={templates} requestUser={requestUser} scrollable={scrollable} />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        p: '2rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...(!scrollable && { width: '60vw' }),
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
        </Box>
    );
};

export default Availability;
