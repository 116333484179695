import LanguageIcon from '@mui/icons-material/Language';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Stack } from '@mui/material';

import FormI18nLabel from 'app/form/form/FormI18nLabel';
import ActionButton from 'common/components/ActionButton';
import CustomMenuItem from 'common/components/CustomMenuItem';
import CustomSelectValidator from 'common/components/CustomSelectValidators';
import CustomTextValidator from 'common/components/CustomTextValidators';
import PhoneCountrySelect from 'common/components/PhoneCountrySelect';
import RegexInput from 'common/components/RegexInput';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { cleanContent } from 'common/utils/cleanContent';
import { useFormFields } from 'libs/hooksLib';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { openNestedDialog } from 'redux/slices/nestedDialogSlice';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import OptionValues from './components/FormFieldOptions';
import FormFieldSelectForm from './components/FormFieldSelectForm';

const types = [
    'input',
    'checkbox',
    'phone',
    'email',
    'privacy-policy',
    'select',
    'radio',
    'form',
    'number',
    'float',
    'date',
];

const NAME_REGEX = /^[a-z]+([A-Z][a-z]+)*$/g;

const FieldForm = (props) => {
    const { field, fieldsExtra, setFields, close, index, id } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [error, setError] = useState(-1);
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2, cx } = useStylesForm();
    const classes = { ...classes1, ...classes2 };
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();

    const { enqueueSnackbar } = useSnackbar();

    const [labels, setLabels] = useState(field.label);
    const [options, setOptions] = useState(
        field?.options?.length > 0 ? field?.options : [{ value: '', label: [{ languageCode: 'en', value: '' }] }],
    );
    const [isValidRegex, setIsValidRegex] = useState(true);

    const [fields, handleFieldChange] = useFormFields({
        type: field.type,
        name: field.name,
        label: field.label || [{ languageCode: 'en', value: '' }],
        required: field.required,
        checked: field.checked !== undefined ? field.checked : false,
        multiple: field.multiple !== undefined ? field.multiple : false,
        active: field.active !== undefined ? field.active : true,
        readOnly: field.readOnly !== undefined ? field.readOnly : false,
        hideCountryCode: field.hideCountryCode !== undefined ? field.hideCountryCode : false,
        defaultCountryCode: field.defaultCountryCode !== undefined ? field.defaultCountryCode : 'ES',
        formId: field.formId,
        regexValidator: field.regexValidator,
        isDateTime: field.isDateTime !== undefined ? field.isDateTime : false,
        useDefault: field.useDefault !== undefined ? field.useDefault : false,
    });

    const initialLabelValue = useMemo(
        () => ({
            textLabelEn: labels?.find((l) => l.languageCode === 'en')?.value || '',
        }),
        [labels],
    );

    const [labelValue, handleLabelValueChange] = useFormFields(initialLabelValue);

    const addField = (e) => {
        e.preventDefault();

        const updatedLabels = fields.label
            .map((label) => {
                if (label.languageCode === 'en') {
                    return { languageCode: 'en', value: labelValue.textLabelEn };
                }
                return label;
            })
            .filter((item) => item.value !== '');

        setFields({
            ...fields,
            options: options.filter((opt) => opt.value !== ''),
            label: updatedLabels.length ? updatedLabels : [{ languageCode: 'en', value: labelValue.textLabelEn }],
        });

        close();
    };

    ValidatorForm.addValidationRule('nameMatch', (value) => {
        return Boolean(value.match(NAME_REGEX));
    });

    ValidatorForm.addValidationRule('nameUnique', (value) => {
        let idx = fieldsExtra.findIndex((f) => f.name === value);
        return idx === -1 || idx === index;
    });

    const handleError = () => {
        enqueueSnackbar(t('profile.settings.viewerExtraField.fieldForm.formError'), {
            variant: 'error',
        });
    };

    const handleSubmit = ({ name, value }, onSuccess) => {
        handleFieldChange({
            target: {
                name,
                value,
            },
        });
        setLabels(value);
        onSuccess();
    };

    const formValid = () => {
        return (
            error === -1 &&
            (fields.type || fields.useDefault) &&
            Boolean(fields.name.match(NAME_REGEX)) &&
            labelValue.textLabelEn !== '' &&
            ((fields.type === 'select' || fields.type === 'radio') && !fields.useDefault
                ? options.length && options[0].value
                : true) &&
            (fields.type === 'form' ? fields.formId : true)
        );
    };

    return (
        <div key={index}>
            <ValidatorForm onSubmit={() => { }} onError={handleError} className={cx(classes.form, classes.settingsForm)}>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.settings.viewerExtraField.fieldForm.name')}</FormLabel>
                            <CustomTextValidator
                                className={classes.formControl}
                                name="name"
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                autoComplete="new-link"
                                value={fields.name}
                                onChange={handleFieldChange}
                                validators={['required', 'nameMatch', 'nameUnique']}
                                errorMessages={[
                                    t('profile.settings.viewerExtraField.fieldForm.nameRequired'),
                                    t('profile.settings.viewerExtraField.fieldForm.nameCamelCase'),
                                    t('profile.settings.viewerExtraField.fieldForm.nameUnique'),
                                ]}
                                disabled={field.readOnly}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item md={4}>
                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{t('profile.settings.viewerExtraField.fieldForm.label')}</FormLabel>
                            <CustomTextValidator
                                className={classes.formControl}
                                name="textLabelEn"
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                autoComplete="new-label-action"
                                value={labelValue.textLabelEn}
                                onChange={handleLabelValueChange}
                                validators={['required']}
                                errorMessages={[t('app.common.required')]}
                                helperText={t('profile.settings.viewerExtraField.fieldForm.enDefaultLabelHelper')}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => {
                                                dispatch(
                                                    openNestedDialog(
                                                        <FormI18nLabel
                                                            fieldLabels={labels}
                                                            onSubmit={handleSubmit}
                                                            // onCancel={() => setOpenEditLabel(false)}
                                                            type={fields.type}
                                                        />,
                                                    ),
                                                );
                                            }}
                                        >
                                            <LanguageIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item md={4}>
                        {!fields.useDefault && (
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('profile.settings.viewerExtraField.fieldForm.type')}</FormLabel>
                                <CustomSelectValidator
                                    name="type"
                                    select
                                    className={classes.formControl}
                                    value={fields.type || ' '}
                                    onChange={handleFieldChange}
                                    validators={['required']}
                                    errorMessages={[t('profile.settings.viewerExtraField.fieldForm.typeRequired')]}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    disabled={field.readOnly && field.name !== 'email'}
                                >
                                    <CustomMenuItem value=" " disabled>
                                        <em>{t('profile.settings.viewerExtraField.fieldForm.selectType')}</em>
                                    </CustomMenuItem>

                                    {field.name === 'onlivePolicy' && (
                                        <CustomMenuItem value="checkbox" noBorder={true}>
                                            Onlive Policy Terms
                                        </CustomMenuItem>
                                    )}
                                    {field.name !== 'onlivePolicy' &&
                                        types.map((type) => {
                                            return (
                                                <CustomMenuItem key={type} value={type} noBorder={true}>
                                                    {capitalize(type)}
                                                </CustomMenuItem>
                                            );
                                        })}
                                </CustomSelectValidator>
                            </FormGroup>
                        )}
                    </Grid>

                    <Grid item md={4}>
                        <FormGroup className={cx(classes.formGroup, classes.check)}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="useDefault"
                                        checked={fields.useDefault ?? false}
                                        onChange={handleFieldChange}
                                        color="primary"
                                    />
                                }
                                label={t('settings.viewerExtraField.useDefault')}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item md={4}>
                        <FormGroup className={cx(classes.formGroup, classes.check)}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="required"
                                        checked={fields.required ?? false}
                                        onChange={handleFieldChange}
                                        color="primary"
                                        disabled={
                                            field.disabledCheck ||
                                            (field.readOnly && field.name !== 'email' && field.name !== 'onlivePolicy')
                                        }
                                    />
                                }
                                label={t('profile.settings.viewerExtraField.fieldForm.required')}
                            />
                        </FormGroup>
                    </Grid>

                    {fields.type === 'phone' ? (
                        <Grid item md={4}>
                            <FormGroup className={cx(classes.formGroup, classes.check)}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="hideCountryCode"
                                            checked={fields.hideCountryCode}
                                            onChange={handleFieldChange}
                                            color="primary"
                                        />
                                    }
                                    label={t('form.fieldForm.hideCountryCode')}
                                />
                            </FormGroup>
                        </Grid>
                    ) : null}

                    {fields.type === 'select' ? (
                        <Grid item md={4}>
                            <FormGroup className={cx(classes.formGroup, classes.check)}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="multiple"
                                            checked={fields.multiple ?? false}
                                            onChange={handleFieldChange}
                                            color="primary"
                                        />
                                    }
                                    label={t('form.formFields.multipleOptions')}
                                />
                            </FormGroup>
                        </Grid>
                    ) : null}

                    {(fields.type === 'checkbox' || fields.type === 'privacy-policy') && (
                        <Grid item md={4}>
                            <FormGroup className={cx(classes.formGroup, classes.check)}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checked"
                                            checked={fields.checked ?? false}
                                            onChange={handleFieldChange}
                                            color="primary"
                                        />
                                    }
                                    label={t('settings.viewerExtraField.checked')}
                                />
                            </FormGroup>
                        </Grid>
                    )}

                    {fields.type === 'date' && (
                        <Grid item md={4}>
                            <FormGroup className={cx(classes.formGroup, classes.check)}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isDateTime"
                                            checked={fields.isDateTime ?? false}
                                            onChange={handleFieldChange}
                                            color="primary"
                                        />
                                    }
                                    label={t('settings.viewerExtraField.isDateTime')}
                                />
                            </FormGroup>
                        </Grid>
                    )}

                    {fields.type === 'phone' && !fields.hideCountryCode ? (
                        <Grid item md={6}>
                            <FormGroup>
                                <Stack spacing={1} marginBottom={1}>
                                    <FormLabel>{t('form.fieldForm.defaultCountryCode')}</FormLabel>
                                    <PhoneCountrySelect
                                        value={fields.defaultCountryCode}
                                        name={'defaultCountryCode'}
                                        onChange={handleFieldChange}
                                    />
                                </Stack>
                            </FormGroup>
                        </Grid>
                    ) : null}

                    {['input', 'phone', 'email'].includes(fields.type) && (
                        <Grid item md={6}>
                            <Stack spacing={1} marginBottom={2}>
                                <FormGroup>
                                    <FormLabel>{t('profile.settings.viewerExtraField.fieldForm.regex')}</FormLabel>
                                    <RegexInput
                                        name="regexValidator"
                                        value={fields.regexValidator}
                                        className={classes.formControl}
                                        onValidRegex={(value) => {
                                            handleFieldChange({ target: { name: 'regexValidator', value } });
                                        }}
                                        size={'large'}
                                        setIsValidRegex={setIsValidRegex}
                                    />
                                </FormGroup>
                            </Stack>
                        </Grid>
                    )}

                    {fields.type === 'form' ? (
                        <Grid item md={12}>
                            <FormFieldSelectForm fields={fields} handleFieldChange={handleFieldChange} id={id} />
                        </Grid>
                    ) : null}

                    {fields.type === 'select' || fields.type === 'radio' ? (
                        <Grid item md={12}>
                            <OptionValues options={options} setOptions={setOptions} error={error} setError={setError} />
                        </Grid>
                    ) : null}

                    {(fields.type === 'checkbox' || fields.type === 'privacy-policy') && labelValue.textLabelEn && (
                        <Grid item md={12}>
                            <FormLabel>{t('profile.settings.viewerExtraField.fieldForm.preview')}</FormLabel>
                            <Stack
                                container
                                spacing={1}
                                className={cx(
                                    flexBox.padding1,
                                    flexBox.borderRadius1,
                                    onlive.borderMediumGray,
                                    flexBox.margin20,
                                )}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: cleanContent(labelValue.textLabelEn),
                                    }}
                                />
                            </Stack>
                        </Grid>
                    )}
                </Grid>

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    <ActionButton onClick={close} color="default" fitWidth>
                        {t('multimedia.multimediaForm.cancel')}
                    </ActionButton>

                    <ActionButton color="primary" autoFocus fitWidth onClick={addField} disabled={!formValid() || !isValidRegex}>
                        {t('profile.settings.viewerExtraField.fieldForm.save')}
                    </ActionButton>
                </Stack>
            </ValidatorForm>
        </div>
    );
};

export default FieldForm;
