import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Divider, LinearProgress } from '@mui/material';

import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenCsrf, logoutAction } from 'redux/slices/authSlice';
import Logo from './Logo';
import useStylesTopBar from './styles/useStylesTopBar.styles';

import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import SettingsMenu from 'common/features/settingsMenu/SettingsMenu';
import { getMeetLoaded } from 'redux/slices/realtimeSlice';
import { getCurrentOne2OneEvent } from 'redux/slices/userSlice';
import UserInfo from './UserInfo';

const TopBar = ({ className }) => {
    const { t } = useTranslation();
    const { classes, cx } = useStylesTopBar();
    const dispatch = useDispatch();
    const tokenCsrf = useSelector(getTokenCsrf);
    const one2oneCurrentEvent = useSelector(getCurrentOne2OneEvent);
    const meetLoaded = useSelector(getMeetLoaded);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleSettingsClicked = (event) => {
        setAnchorEl(event.currentTarget);
        setSettingsOpen(true);
    };

    const handleSettingsOnClose = () => {
        setSettingsOpen(false);
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(
            logoutAction({
                success: () => {
                    window.location.href = window.location.origin + '/login?forceLogout=true';
                    // navigate('/login?forceLogout=true');
                },
                tokenCsrf,
            }),
        );
    };

    function openTab() {
        const { url } = one2oneCurrentEvent;
        window.onliveManager.services.onliveMeet.joinToMeet(url);
    }

    return (
        <>
            <div className={cx(classes.root, className)}>
                <div className={cx(classes.logoContent)}>
                    <Logo />
                </div>

                <div className={classes.grow1}>
                    {one2oneCurrentEvent && (
                        <div className={classes.currentEventDetails}>
                            <div className={classes.currentEvent}>
                                <div className={classes.currentEventHead}>
                                    <DevicesOutlinedIcon sx={{ color: 'gray', fontSize: 24 }} />
                                    <p className={classes.currentEventText}>{` ${t('CallInProgress')} `}</p>
                                </div>
                                <LinearProgress color="error" sx={{ height: '2px', width: '100%' }} />
                            </div>
                            <Button className={classes.pickUpButton} onClick={openTab} disabled={!meetLoaded}>
                                {t('Pick up call here')}
                            </Button>
                        </div>
                    )}
                </div>
                <Divider className={classes.divLine} />

                <UserInfo />

                <Divider className={classes.divLine} />

                <div className={classes.leftSpace} />

                <CustomTooltip arrow placement={'bottom'} title={t('common.components.breadcrumb.config')}>
                    <CustomIconButton
                        id="onlive-button-setting"
                        onClick={handleSettingsClicked}
                        hideBorder={true}
                        className={Boolean(settingsOpen) && 'selected'}
                    >
                        <MoreVertIcon component="svg" />
                    </CustomIconButton>
                </CustomTooltip>

                <div className={classes.ml2} />
                <CustomTooltip arrow placement={'bottom'} title={t('topBar.exit')}>
                    <CustomIconButton id="onlive-button-logout" onClick={handleLogout} hideBorder={true}>
                        <LogoutOutlinedIcon component="svg" />
                    </CustomIconButton>
                </CustomTooltip>

                <div className={classes.rightSpace} />
            </div>
            <div className={classes.absolute}>
                <SettingsMenu open={settingsOpen} handleOnClose={handleSettingsOnClose} anchorEl={anchorEl} />
            </div>
        </>
    );
};

export default TopBar;
