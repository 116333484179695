import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'stickers';

export const stickersSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        stickers: [],
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addSticker: (state, action) => {
            state.savePending = false;
            state.stickers = [action.payload, ...state.stickers];
        },
        editSticker: (state, action) => {
            state.savePending = false;
            const index = state.stickers.findIndex((sticker) => sticker.id === action.payload.id);
            if (index !== -1) {
                state.stickers = [
                    ...state.stickers.slice(0, index),
                    action.payload,
                    ...state.stickers.slice(index + 1),
                ];
            }
        },
        deleteSticker: (state, action) => {
            state.deletePending = false;
            const index = state.stickers.findIndex((sticker) => sticker.id === action.payload.id);
            if (index !== -1) {
                state.stickers = [...state.stickers.slice(0, index), ...state.stickers.slice(index + 1)];
            }
        },
        setStickers: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.stickers = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setStickers,
    setError,
    addSticker,
    editSticker,
    deleteSticker,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
} = stickersSlice.actions;

export const getStickers = (state) => state.sticker.stickers;
export const getStickersTotal = (state) => state.sticker.total;
export const getStickersPending = (state) => state.sticker.pending;
export const getDeletePending = (state) => state.sticker.deletePending;
export const getDeleteId = (state) => state.sticker.deleteId;
export const getStickersError = (state) => state.sticker.error;
export const getStickersSavePending = (state) => state.sticker.savePending;

export const fetchStickersAction =
    ({ filters, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get(resource + query)
                .then((response) => {
                    dispatch(setStickers(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addStickerAction =
    ({ dataDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(resource, dataDto)
                .then((response) => {
                    dispatch(addSticker(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const editStickerAction =
    ({ id, dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put(`${resource}/${id}`, dataDto)
                .then((response) => {
                    dispatch(editSticker(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteStickerAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete(resource + '/' + id)
                .then((response) => {
                    dispatch(deleteSticker(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export default stickersSlice.reducer;
