import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import searchApi from 'api/search';
import { handleUploadFileSignedUrl } from 'common/utils/file';
import { isEqual } from 'lodash';

export const assetSlice = createSlice({
    name: 'asset',
    initialState: {
        pending: false,
        uploading: false,
        progress: 0,
        assets: [],
        error: null,
    },
    reducers: {
        upload: (state, action) => {
            state.uploading = true;
        },
        resetAssets: (state, action) => {
            state.assets = [];
        },
        addAsset: (state, action) => {
            state.uploading = false;
            state.assets = [...state.assets, action.payload];
        },
        deleteAsset: (state, action) => {
            state.assets.splice(action.payload, 1);
        },
        setError: (state, action) => {
            state.uploading = false;
            state.pending = false;
            state.error = action.payload;
        },
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setAssets: (state, action) => {
            state.pending = false;
            state.assets = action.payload;
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
        deleteAssetById: (state, action) => {
            const idProperty = action.payload?.idProperty || 'id';
            state.assets = state.assets.filter((asset) => !isEqual(asset[idProperty], action.payload.value));
        },
    },
});

export const {
    upload,
    resetAssets,
    addAsset,
    deleteAsset,
    setError,
    stop,
    start,
    setAssets,
    setProgress,
    deleteAssetById,
} = assetSlice.actions;

export const getAssets = (state) => state.asset.assets;
export const getAssetsUploading = (state) => state.asset.uploading;
export const getAssetsUploadProgress = (state) => state.asset.progress;
export const getAssetsPending = (state) => state.asset.pending;
export const getAssetsError = (state) => state.asset.error;

export const fetchEventAssetsAction =
    ({ eventId, onSuccess } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get('assets/event/' + eventId)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(setAssets(response.data));
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchPresetProductsAction =
    ({ assets, products, instantSearchEnabled, searchEngine } = {}) =>
    async (dispatch) => {
        try {
            if (instantSearchEnabled && products?.length) {
                searchApi
                    .groups(searchEngine, {
                        collapse: true,
                        groupIds: products.join(','),
                    })
                    .then((response) => {
                        const assetsWithProduct = [
                            ...assets,
                            ...(response?.data?.results?.map((item) => ({ ...item, type: 'product' })) || []),
                        ];
                        dispatch(setAssets(assetsWithProduct));
                        return assets;
                    });
            } else {
                dispatch(setAssets(assets));
            }
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchAssetsAction =
    ({ filter, onSuccess } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get('assets', { params: { ...filter } })
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(setAssets(response.data));
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const uploadAssetAction = (file, signal) => async (dispatch) => {
    handleUploadFileSignedUrl({
        file,
        size: 1024 * 1024 * 100, // 100MB
        onInit: (size) => {
            dispatch(setProgress(0));
            dispatch(upload());
        },
        onProgress: (loaded, total) => {
            setProgress(Math.min(100, Math.round((loaded / total) * 100)));
        },
        onComplete: (url) => {
            dispatch(addAsset(url));
            return url;
        },
        onError: (error) => {
            dispatch(stop());
            if (error.code !== 'ERR_CANCELED') {
                dispatch(setError(error));
            }
            return console.error(error.message);
        },
        bucketType: 'resources',
        signal,
    });
};

export default assetSlice.reducer;
