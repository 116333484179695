import { makeStyles } from 'tss-react/mui';

const useStylesProfileImage = makeStyles()((theme) => {
    return {
        profileImage: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        avatarProfile: {
            backgroundColor: '#EAEAEA',
            color: '#CBCBCB',
            height: theme.spacing(14),
            width: theme.spacing(14),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(17),
                width: theme.spacing(17),
            },
        },
        progressProfile: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: theme.spacing(14),
            width: theme.spacing(14),
            [theme.breakpoints.up('xl')]: {
                height: theme.spacing(17),
                width: theme.spacing(17),
            },
        },
        control: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: theme.spacing(2),
            [theme.breakpoints.up('xl')]: {
                marginLeft: theme.spacing(3),
            },
        },
        dFlex: {
            display: 'flex',
        }
    };
});

export default useStylesProfileImage;
