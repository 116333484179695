import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { communicationApi } from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const emailSlice = createSlice({
    name: 'email',
    initialState: {
        pending: false,
        savePending: false,
        error: null,
        defaultEmailTemplates: {},
        previewEmailTemplateDialog: null,
    },
    reducers: {
        start: (state, action) => {
            state.error = null;
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.pending = false;
        },
        openPreviewEmailTemplateDialog: (state, action) => {
            state.previewEmailTemplateDialog = action.payload;
        },
        setPending: (state, action) => {
            state.pending = action.payload;
        },
        setDefaultEmailTemplates: (state, action) => {
            const { lang, template, part, data } = action.payload;
            let newValue = _.cloneDeep(state.defaultEmailTemplates);

            if (!newValue[lang]) {
                newValue[lang] = {};
            }
            if (!newValue[lang][template]) {
                newValue[lang][template] = {
                    name: template,
                    lang: lang,
                    text: '',
                    html: '',
                    subject: '',
                };
            }

            newValue[lang][template][part] = data;
            state.defaultEmailTemplates = newValue;
        },
        setSavePending: (state, action) => {
            state.savePending = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setError,
    openPreviewEmailTemplateDialog,
    setPending,
    setDefaultEmailTemplates,
    setSavePending,
} = emailSlice.actions;


export const getPending = (state) => state.email.pending;
export const getEmailError = (state) => state.email.error;
export const getPreviewEmailTemplateDialog = (state) => state.email.previewEmailTemplateDialog;
export const getDefaultEmailTemplates = (state) => state.email.defaultEmailTemplates;
export const getSavePending = (state) => state.email.savePending;

export const previewEmailTemplateAction = (templateName, template, part, lang, success) => async (dispatch) => {
    try {
        dispatch(start());
        communicationApi.post('email-templates/preview', {
            templateName,
            template,
            part,
            lang,
        })
            .then(({ data }) => {
                dispatch(setPending(false));
                success(data);
            })
            .catch((error) => {
                dispatch(setPending(false));
                dispatch(openPreviewEmailTemplateDialog(null));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const sendTestEmailAction = (templateName, success) => async (dispatch) => {
    try {
        dispatch(start());
        communicationApi.post('email-templates/test', {
            templateName: templateName,
        })
            .then(({ data }) => {
                success(`Test email successfully sent to ${data.email}`);
                dispatch(setPending(false));
            })
            .catch((error) => {
                dispatch(setPending(false));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const loadDefaultEmailTemplateAction = (template, part, lang, success) => async (dispatch) => {
    try {
        dispatch(start());
        let query = transformFiltersToQuery({
            templateName: template,
            templatePart: part,
            lang,
        });
        communicationApi.get('email-templates/default' + query)
            .then(({ data }) => {
                dispatch(setDefaultEmailTemplates({ lang, template, part, data: data.template }));
                dispatch(setPending(false));
                success(data);
            })
            .catch((error) => {
                dispatch(setPending(false));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const closePreviewEmailTemplateAction = () => {
    return (dispatch) => {
        dispatch(openPreviewEmailTemplateDialog(null));
    };
};

export default emailSlice.reducer;
