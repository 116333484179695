import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CustomTextValidator from './CustomTextValidators';

const RegexInput = ({ name, value, onValidRegex, className, setIsValidRegex, size = 'small' | 'medium' | 'large' }) => {
    const { t } = useTranslation();

    const handleChange = (e) => {
        const value = e.target.value;
        // Always update parent value
        onValidRegex(value);

        try {
            new RegExp(value);
            setIsValidRegex(true);
        } catch (error) {
            setIsValidRegex(false);
            console.debug('🚀 ~ handleChange ~ error:', error);
        }
    };

    ValidatorForm.addValidationRule('isValidRegex', (value) => {
        try {
            new RegExp(value);
            return true;
        } catch (error) {
            return false;
        }
    });

    return (
        <ValidatorForm onSubmit={() => {}} onError={() => {}}>
            <CustomTextValidator
                className={className}
                name={name}
                fullWidth={true}
                variant="outlined"
                size={size}
                value={value}
                onChange={handleChange}
                validators={['isValidRegex']}
                errorMessages={[t('profile.settings.viewerExtraField.fieldForm.invalidRegex')]}
                useRegExpText={false}
            />
        </ValidatorForm>
    );
};

export default RegexInput;
