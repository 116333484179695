import { Button, Stack } from '@mui/material';
import onliveApi from 'api/onliveApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicSelectSetting from '../components/BasicSelectSetting';
import useStylesSettings from '../styles/useStylesSettings.styles';

const VspClusterRegionSetting = () => {
    const { t } = useTranslation();
    const { classes } = useStylesSettings();
    const [clusterData, setClusterData] = useState({
        defaultCluster: null,
        availableClusters: [],
        newDefaultCluster: null,
    });

    const fetchClusterData = async () => {
        try {
            const currentClusterData = await onliveApi.getVspClusterData();

            setClusterData({ ...currentClusterData });
        } catch (error) {
            console.error('Error getting Milicast Cluster Regions.');
            console.error(error);
            setClusterData({
                defaultCluster: null,
                availableClusters: [],
                newDefaultCluster: null,
            });
        }
    };

    useEffect(() => {
        fetchClusterData();
    }, []);

    const onChange = (event) => {
        try {
            const selectedValidCluster =
                event?.target?.value &&
                typeof event?.target?.value === 'string' &&
                // && event.target.value !== clusterData.defaultCluster
                clusterData.availableClusters.map((c) => c.id).includes(event.target.value);

            if (selectedValidCluster) {
                setClusterData({
                    ...clusterData,
                    newDefaultCluster: event.target.value,
                });
            }
        } catch (error) {
            console.error('Error setting cluster value.');
            console.error(error);
        }
    };

    const canSave = () =>
        !!clusterData.newDefaultCluster &&
        typeof clusterData.newDefaultCluster === 'string' &&
        clusterData.newDefaultCluster !== clusterData.defaultCluster;

    const handleSave = async () => {
        try {
            if (canSave()) {
                const savedOk = await onliveApi.setVspDefaultCluster(clusterData.newDefaultCluster);

                if (savedOk) {
                    setClusterData({
                        ...clusterData,
                        defaultCluster: clusterData.newDefaultCluster,
                        // newDefaultCluster: null,
                    });
                }
            }
        } catch (error) {
            console.error('Error saving cluster setting.');
            console.error(error);
        }
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mt={2}>
            <BasicSelectSetting
                label={t('profile.settings.newFeaturesSettings.vspClusterRegion')}
                onChange={onChange}
                name={'milicastClusterRegion'}
                value={clusterData.newDefaultCluster || clusterData.defaultCluster}
                valuesList={clusterData.availableClusters}
            />

            {canSave() && (
                <Button variant="contained" className={classes.redColor} size="small" onClick={handleSave}>
                    {t('Save changes')}
                </Button>
            )}
        </Stack>
    );
};

export default VspClusterRegionSetting;
