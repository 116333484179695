import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from '@mui/material';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import {
    addEnvironmentVariablesAction,
    deleteEnvironmentVariablesAction,
    fetchEnvironmentVariablesAction,
    getImmersiveEnvironmentVariables,
    getImmersivePending,
    getImmersiveSavePending,
} from 'redux/slices/ImmersiveSlice';
import useStylesSettings from '../styles/useStylesSettings.styles';
import EnvironmentVariableForm from './EnvironmentVariableForm';

const ImmersiveEnvironments = () => {
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const environmentVariables = useSelector(getImmersiveEnvironmentVariables);
    const pending = useSelector(getImmersivePending);
    const savePending = useSelector(getImmersiveSavePending);

    const [error, setError] = useState(-1);
    const [variables, setVariables] = useState([]);

    const loadData = useCallback(() => {
        dispatch(fetchEnvironmentVariablesAction());
    }, [dispatch]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (environmentVariables && environmentVariables.length === 0) {
            setVariables([{ key: '', value: '' }]);
        } else if (environmentVariables && environmentVariables.length > 0) {
            setVariables(environmentVariables);
        }
    }, [environmentVariables]);

    const saveEnvironment = (dto) => {
        dispatch(
            addEnvironmentVariablesAction({
                environmentDto: {
                    environment: dto,
                },
                success: () => {
                    enqueueSnackbar(t('profile.settings.immersiveEnvironmentsVariables.form.createdSuccess'), {
                        variant: 'success',
                    });
                },
                failure: () => {
                    enqueueSnackbar(t('app.common.error'), { variant: 'error' });
                },
            }),
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const dto = variables.reduce((acc, curr) => {
            if (curr.key !== '' && curr.value !== '') {
                acc[curr.key] = curr.value;
            }
            return acc;
        }, {});

        saveEnvironment(dto);
    };

    const handleDelete = (_id, idx) => {
        let newVariables = [...variables];
        newVariables.splice(idx, 1);
        if (error === idx) {
            setError(-1);
        }
        setVariables(newVariables);

        if (newVariables.length === 0) {
            dispatch(
                deleteEnvironmentVariablesAction({
                    success: () => {
                        enqueueSnackbar(t('app.common.wasDeleted'), { variant: 'success' });
                    },
                    failure: () => {
                        enqueueSnackbar(t('app.common.deleteError'), { variant: 'error' });
                    },
                }),
            );
        }
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.immersiveEnvironmentsVariables')}
                </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.eventSection}>
                {!pending ? (
                    <ValidatorForm onSubmit={(e) => {}} onError={(e) => {}}>
                        <EnvironmentVariableForm
                            handleSubmit={handleSubmit}
                            variables={variables}
                            setVariables={setVariables}
                            error={error}
                            setError={setError}
                            savePending={savePending}
                            handleDelete={handleDelete}
                        />
                    </ValidatorForm>
                ) : (
                    <Box sx={{ display: 'flex', width: '100%', my: 1, justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default ImmersiveEnvironments;
