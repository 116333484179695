import { FormGroup, FormLabel } from '@mui/material';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useTranslation } from 'react-i18next';

const PrestashopForm = (props) => {
    const { fields, handleFieldChange } = props;
    const { classes } = useStylesForm();
    const { t } = useTranslation();

    return (
        <>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.prestashopForm.apiKey')}</FormLabel>
                <CustomTextValidator
                    name="apiKey"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-apiKey"
                    value={fields.apiKey}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.prestashopForm.apiKeyRequired')]}
                    disabled
                />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.prestashopForm.shopDomain')}</FormLabel>
                <CustomTextValidator
                    name="shopUri"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-shopUri"
                    value={fields.shopUri}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.prestashopForm.shopDomainRequired')]}
                    disabled
                />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.prestashopForm.version')}</FormLabel>
                <CustomTextValidator
                    name="version"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-version"
                    value={fields.version}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.prestashopForm.versionRequired')]}
                    disabled
                />
            </FormGroup>
        </>
    );
};

export default PrestashopForm;
