import AircallPhone from 'aircall-everywhere';

export class AirCallPanel {

    constructor() {
        this.aircallPhone = new AircallPhone({
            onLogin: (settings) => {
                console.log('phone loaded');
                //doStuff();
            },
            onLogout: () => {},
            domToLoadPhone: '#aircall',
            size: 'big',
        });
        this.close();
    }
    
    show() {
        const phoneContainer = document.querySelector('#aircall-container');
        phoneContainer?.classList.remove('hidden');
    }

    close() {
        const phoneContainer = document.querySelector('#aircall-container');
        phoneContainer?.classList.add('hidden');
    }

    call(phoneNumber) {
        this.show();
        this.aircallPhone.send('dial_number', { phone_number: phoneNumber }, (success, data) => {
            console.log('success of dial');
          });
    }

    listen(event, payload, callback) {
        this.aircallPhone.on(event, (data) => callback(data, event, payload));
    }
}