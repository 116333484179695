import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import WebUrlSources from 'common/enums/WebUrlSources';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getSettings } from 'redux/slices/settingSlice';

export function useSettingsInitialValue() {
    const settings = useSelector(getSettings);
    const organization = useSelector(getOrganization);

    const getSettingValue = useCallback((key) => settings?.find((s) => s.settingKey === key)?.settingValue, [settings]);

    const defaultValues = useMemo(
        () => ({
            [SettingsKey.ENABLE_EVENT_CHAT]: false,
            [SettingsKey.CALL_DOMAIN_BASE_URL]: '',
            [SettingsKey.ENABLE_CAM_ON_START_ICALL]: false,
            [SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER]: false,
            [SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_TV_WIDGET]: false,
            [SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_CLIENT]: false,
            [SettingsKey.ENABLE_CHAT_WIDGET]: false,
            [SettingsKey.ENABLE_TV_WIDGET]: false,
            [SettingsKey.CUSTOM_STYLES_TV_WIDGET]: null,
            [SettingsKey.TV_WIDGET_VERSION]: 'v1',
            [SettingsKey.LANDING_AGENT_PATH]: null,
            [SettingsKey.RULES_LANDING_AGENT]: {
                paths: [`^${getSettingValue(SettingsKey.LANDING_AGENT_PATH) || '/landingAgent'}.*$`],
                excludePaths: [],
            },
            [SettingsKey.OPTIONS_LANDING_AGENT]: '',
            [SettingsKey.CUSTOM_STYLES_LANDING_AGENT]: null,
            [SettingsKey.ENABLE_EVENT_CART]: true,
            [SettingsKey.DEFAULT_LANGUAGE]: 'es',
            [SettingsKey.VIEWER_EXTRA_FIELDS]: [],
            [SettingsKey.ENABLE_NOTIFY_ME]: false,
            [SettingsKey.ENABLE_SHOP_IN_STORE]: false,
            [SettingsKey.ORGANIZATION_EMAILS_TEMPLATES]: false,
            [SettingsKey.WIDGET_COLOR]: organization.primaryColor,
            [SettingsKey.WIDGET_VERSION]: 'v1',
            [SettingsKey.ICALL_VERSION]: '',
            [SettingsKey.ICHAT_VERSION]: '',
            [SettingsKey.ICALL_OPTIONS]: '',
            [SettingsKey.CUSTOM_STYLES_WIDGET]: '',
            [SettingsKey.OPTIONS_WIDGET]: '',
            [SettingsKey.WIDGET_DESIGN]: 'traditional',
            [SettingsKey.RULES_WIDGET]: {
                paths: ['^.*$'],
                excludePaths: [],
            },
            [SettingsKey.WIDGET_ORIENTATION]: 'right',
            [SettingsKey.WIDGET_ACTIONS]: ['chat', 'call', 'calendar'],
            [SettingsKey.ENABLE_REMARKETING_EMAIL]: true,
            [SettingsKey.ENABLE_EMAIL_POPUP_NOTIFICATION]: true,
            [SettingsKey.ENABLE_HOST_ICALL_EMAIL_REMINDER]: false,
            [SettingsKey.SHOW_VIEW_TIME_LEFT_SHOPPING]: true,
            [SettingsKey.MEET_RECORDING_ENABLED]: false,
            [SettingsKey.MEET_RECORDING_LAYOUT]: 'host-only',
            [SettingsKey.MEET_QUALITY_CONTROL_ENABLED]: false,
            [SettingsKey.RECORDING_RANDOM_PROBABILITY]: 50,
            [SettingsKey.MEET_QUALITY_CONTROL_RECORDING_LAYOUT]: 'host-only',
            [SettingsKey.RULES_TV_WIDGET]: {
                paths: ['^/live-shopping.*$'],
                excludePaths: [],
            },
            [SettingsKey.OPTIONS_TV_WIDGET]: '',
            [SettingsKey.ENABLE_WIDGET_CHANNEL_SUBSCRIPTION]: false,
            [SettingsKey.WIDGET_USE_PRESELECTED_FLOW]: true,
            [SettingsKey.INSTANT_SEARCH]: false,
            [SettingsKey.TIMEZONE]: 'Europe/Madrid',
            [SettingsKey.TWELVE_HOUR_FORMAT]: false,
            [SettingsKey.SERVICE_APPOINTMENT_CREATE_TRIAGE_ID]: '',
            [SettingsKey.APPOINTMENT_RATING_INSTALLATION]: '',
            [SettingsKey.ENABLE_ORGANIZATION_QUEUE]: false,
            [SettingsKey.ENABLE_PERSONAL_QUEUE]: false,
            [SettingsKey.ENABLE_SHOPPING]: true,
            [SettingsKey.WIDGET_BTN_OPEN_AT_START]: false,
            [SettingsKey.WIDGET_BTN_DISTRIBUTION]: 'around',
            [SettingsKey.WIDGET_HIDE_VIDEO_CALL]: false,
            [SettingsKey.ENABLE_REGISTER]: true,
            [SettingsKey.ENABLE_REGISTER_INSIDE_THE_SHOW]: true,
            [SettingsKey.ENABLE_CHIME]: true,
            [SettingsKey.SEO_CLIENT_BASE_URL]: null,
            [SettingsKey.SEO_CLIENT_WEB_URL]: null,
            [SettingsKey.SEO_CLIENT_WEB_URL_SOURCE]: WebUrlSources.ONLIVE_SITE,
            [SettingsKey.SEO_CLIENT_AUTO_INDEX]: false,
            [SettingsKey.EMAIL_SENDER_NAME]: '',
            [SettingsKey.TEXT_ACTION_SUBMIT_JOIN_FORM]: [],
            [SettingsKey.NEW_CALENDAR]: true,
            [SettingsKey.SHOW_PRICE]: true,
            [SettingsKey.ENABLE_DISCOUNT_CODES]: false,
            [SettingsKey.ENABLE_SHOPIFY_GIFT_PRODUCTS]: false,
            [SettingsKey.ENABLE_EVENT_SHARE_LINK]: true,
            [SettingsKey.TEXT_START_PAGE_EVENT]: [],
            [SettingsKey.TEXT_END_PAGE_EVENT]: [],
            [SettingsKey.ENABLE_ENTER_EVENT_AS_GUEST]: true,
            [SettingsKey.ENABLE_SHOW_NUMBER_OF_PARTICIPANTS]: true,
            [SettingsKey.SUPPORT_WIDGET_SCRIPT]: '',
            [SettingsKey.NEW_INSTALLATIONS]: false,
            [SettingsKey.ENABLE_INSTALLATIONS_MENU]: false,
            [SettingsKey.ENABLE_LABELS]: false,
            [SettingsKey.ENABLE_PRESETS]: false,
            [SettingsKey.SHOW_ICALL_CATEGORY_IN_TABLE]: false,
            [SettingsKey.SHOW_ICALL_RESULT_IN_TABLE]: false,
            [SettingsKey.ENABLE_POOL]: false,
            [SettingsKey.SHARE_FILES]: false,
            [SettingsKey.ICALL_CATEGORIES]: [],
            [SettingsKey.ICALL_RESULTS]: [],
            [SettingsKey.ENABLE_AIRCALLS]: false,
            [SettingsKey.CART_EMPTY_AFTER_CHECKOUT]: false,
            [SettingsKey.EVENT_FINALIZED_TIME]: 30,
            [SettingsKey.STORE_UPDATE_CHECKOUT_BEHAVIOR]: 'new',
            [SettingsKey.LEADS_MANAGEMENT_END_POINT]: '',
            [SettingsKey.LEADS_MANAGEMENT_CLIENT_ID]: '',
            [SettingsKey.LEADS_MANAGEMENT_CLIENT_SECRET]: '',
            [SettingsKey.LEADS_STATUS]: {},
            [SettingsKey.FORMAT_DATE_EMAIL]: '',
            [SettingsKey.FORMAT_TIME_EMAIL]: '',
            [SettingsKey.ENABLE_DASHBOARD_SUMMARY_EMAILS]: false,
            [SettingsKey.ORGANIZATION_EMAIL_IDENTITIES]: [],
            [SettingsKey.SHARE_EMAIL_IDENTITIES]: false,
            [SettingsKey.HIDE_INPUT_NAME]: false,
            [SettingsKey.COMMUNICATION_OUTCOME]: [],
            [SettingsKey.COMMUNICATION_LOGS_ENABLED]: false,
            [SettingsKey.ICALL_TRANSLATIONS]: {},
            [SettingsKey.ICHAT_TRANSLATIONS]: {},
            [SettingsKey.ICALL_EMAIL_REMINDERS]: [],
            [SettingsKey.ICALENDAR_EMAIL_PAST_REMINDERS]: [],
            [SettingsKey.PARAMS_CHARACTER]: false,
            [SettingsKey.ENABLE_PRODUCT_DISCOUNT]: false,
            [SettingsKey.MILICAST_CLUSTER_REGION]: 'fra-1',
            [SettingsKey.ENABLE_WORD_BAG]: false,
            [SettingsKey.ENABLE_TV_CHANNEL_MANAGER]: false,
            [SettingsKey.IMMERSIVE_APP_SIZE_LIMIT]: 15,
            [SettingsKey.AVAILABILITY_TYPES]: [],
            [SettingsKey.BOOKING_SHARE_URL]: '',
            [SettingsKey.BOOKING_TITLE]: '',
            [SettingsKey.ENABLE_FORM_MANAGE]: false,
            [SettingsKey.ENABLE_GENAI]: false,
            [SettingsKey.ENABLE_SOCIAL_VIDEOS]: false,
            [SettingsKey.GENAI_AUTOMATIC_LANGUAGE_IDENTIFICATION]: false,
            [SettingsKey.GENAI_VOCABULARIES_LANGUAGE_IDENTIFICATION]: false,
            [SettingsKey.ENABLE_USER_GROUPS]: false,
            [SettingsKey.ENABLE_PRODUCT_UNIT]: false,
            [SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT]: false,
            [SettingsKey.APPOINTMENT_STATUS]: {},
            [SettingsKey.ENABLE_ADD_LEAD_MANUAL]: true,
            [SettingsKey.ENABLE_SEND_LEAD_REMARKETING_EMAIL]: true,
            [SettingsKey.APPOINTMENT_ORIGIN]: {},
            [SettingsKey.ENABLE_AGENT_EDIT_AVAILABILITY]: true,
            [SettingsKey.ENABLE_AGENT_EDIT_GROUP_APPOINTMENT]: true,
            [SettingsKey.PRODUCTS_CHECK_ENABLED]: false,
            [SettingsKey.ICALL_SCHEMA]: '',
            [SettingsKey.REQUIRE_TWO_FACTOR_AUTHENTICATION]: false,
        }),
        [getSettingValue, organization.primaryColor],
    );

    const defaultNotificationsValue = useMemo(
        () => ({
            [SettingsKey.ICALL_NOTIFICATIONS]: {
                [SettingsKey.DIRECT_INCOMING_CALL]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: false,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                [SettingsKey.ORGANIZATION_INCOMING_CALL]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: false,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                [SettingsKey.CONTENT_CREATION]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                [SettingsKey.LOST_OPPORTUNITY_CREATION]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                [SettingsKey.CALENDAR_REMINDER]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                [SettingsKey.ICALL_QUEUE_INCOMING]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                [SettingsKey.ICALL_QUEUE_OUTGOING]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
                ...getSettingValue(SettingsKey.ICALL_NOTIFICATIONS),
            },
            [SettingsKey.ICHAT_NOTIFICATIONS]: getSettingValue(SettingsKey.ICHAT_NOTIFICATIONS)?.defaultValue || {
                [SettingsKey.INCOMING_CHAT_REQUEST]: {
                    [SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION]: false,
                    [SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION]: true,
                    [SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION]: true,
                    [SettingsKey.URL_BROWSER_SOUND_NOTIFICATION]: '',
                },
            },
        }),
        [getSettingValue],
    );

    const defaultValueHooks = useMemo(
        () => ({
            [SettingsKey.ENABLE_EVENT_WEBHOOKS]: false,
            [SettingsKey.OVERWRITE_DEFAULT_WEBHOOKS_ACTIONS]: false,
            [SettingsKey.EVENT_SUBSCRIPTION_WEBHOOK]: '',
            [SettingsKey.CREATE_VIEWER_WEBHOOK]: '',
            [SettingsKey.GAME_WINNER_WEBHOOK]: '',
            [SettingsKey.WEBHOOK_TOKEN]: '',
        }),
        [],
    );

    const getInitialSettingValue = useCallback(
        (values) => {
            return Object.entries(values).reduce((acc, [key, defaultValue]) => {
                const settingValue = getSettingValue(key) ?? defaultValue;
                acc[key] = { settingKey: key.toLowerCase(), settingValue };
                return acc;
            }, {});
        },
        [getSettingValue],
    );

    const getInitialNotificationsSettingValue = useCallback((values) => {
        return Object.entries(values).reduce((acc, [key, defaultValue]) => {
            acc[key] = { settingKey: key.toLowerCase(), settingValue: defaultValue };
            return acc;
        }, {});
    }, []);

    const initialNotificationValue = useMemo(() => {
        return getInitialNotificationsSettingValue(defaultNotificationsValue);
    }, [defaultNotificationsValue, getInitialNotificationsSettingValue]);

    const initialValue = useMemo(() => {
        return getInitialSettingValue(defaultValues);
    }, [defaultValues, getInitialSettingValue]);

    const initialValueHooks = useMemo(() => {
        return getInitialSettingValue(defaultValueHooks);
    }, [defaultValueHooks, getInitialSettingValue]);

    return { initialValue: { ...initialValue, ...initialNotificationValue }, initialValueHooks };
}
