import Popover from '@mui/material/Popover';
import useColorsStyles from 'common/colors';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import CloseIconButton from './CloseIconButton';

const CustomPopover = (props) => {
    const {
        open = false,
        handleOnClose,
        children,
        anchorEl,
        hideCloseButton,
        strictClose,
        disableFocus,
        disablePortal,
        anchorOrigin,
        transformOrigin,
    } = props;

    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();
    const { classes: colors } = useColorsStyles();

    const id = open ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={strictClose ? () => {} : handleOnClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            className={flexBox.zIndex7}
            elevation={0}
            disableAutoFocus={!!disableFocus}
            disableEnforceFocus={!!disableFocus}
            disablePortal={disablePortal}
            classes={{
                paper: cx(
                    onlive.boxShadowWeb,
                    flexBox.borderRadius2,
                    colors.bgTransparent,
                    flexBox.hAuto,
                    flexBox.marginTop05,
                    flexBox.overflowVisible,
                ),
            }}
        >
            {!hideCloseButton && handleOnClose && <CloseIconButton onClick={handleOnClose} inside={true} />}
            {children}
        </Popover>
    );
};

export default CustomPopover;
