import { createSlice } from '@reduxjs/toolkit';
import api from 'api';

import store from '../';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        pending: false,
        users: [],
        error: null,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
        usersStatistics: null,
        cmsFingerPrint: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        currentOne2OneEvent: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setUsers: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.users = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setUsersStatistics: (state, action) => {
            state.pending = false;
            state.usersStatistics = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        editUser: (state, action) => {
            state.pending = false;
            const index = state.users.findIndex((user) => user.id === action.payload.id);
            if (index !== -1) {
                state.users = [...state.users.slice(0, index), action.payload, ...state.users.slice(index + 1)];
            }
        },
        deleteUser: (state, action) => {
            state.pending = false;
            const index = state.users.findIndex((user) => user.id === action.payload.id);
            if (index !== -1) {
                state.users = [...state.users.slice(0, index), ...state.users.slice(index + 1)];
            }
        },
        setCurrentOne2OneEvent: (state, action) => {
            state.currentOne2OneEvent = action.payload;
        },
    },
});

export const { start, stop, setUsers, setError, editUser, deleteUser, setCurrentOne2OneEvent, setUsersStatistics } =
    userSlice.actions;

export const getUsers = (state) => state.user.users;
export const getUsersTotal = (state) => state.user.total;
export const getUsersCurrentPage = (state) => state.user.currentPage;
export const getUsersTotalPages = (state) => state.user.totalPages;
export const getUsersPending = (state) => state.user.pending;
export const getUsersError = (state) => state.user.error;
export const getCmsFingerPrint = (state) => state.user.cmsFingerPrint;
export const getCurrentOne2OneEvent = (state) => state.user.currentOne2OneEvent;
export const getUsersStatistics = (state) => state.user.usersStatistics;

export const fetchUsersAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                const user = store.getState()?.auth?.user;
                let query = transformFiltersToQuery({
                    ...queryParams,
                    ...(user?.userGroupId ? { userGroupId: user.userGroupId } : {}),
                });
                dispatch(start());
                api.get('users' + query)
                    .then((response) => {
                        if (queryParams?.page && queryParams?.limit) {
                            dispatch(setUsers(response.data));
                            onSuccess?.(response.data.items);
                        } else {
                            dispatch(
                                setUsers({
                                    count: response.data.length,
                                    total: response.data.length,
                                    items: response.data,
                                    currentPage: 1,
                                    totalPages: 1,
                                }),
                            );
                            onSuccess?.(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stop());
                return console.error('error', e);
            }
        };

export const updateUserRoleAction = (userId, role, success) => async (dispatch) => {
    try {
        api.put('users/' + userId, { role })
            .then((response) => {
                dispatch(editUser(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const updateUserGroupAction = (userId, group, success) => async (dispatch) => {
    try {
        api.put('users/' + userId, { userGroupId: group })
            .then((response) => {
                dispatch(editUser(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const deleteUserAction =
    ({ id, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(start(id));
                api.delete('users/' + id)
                    .then((response) => {
                        dispatch(deleteUser(response.data));
                        onSuccess?.(response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        onFailure?.();
                    });
            } catch (e) {
                dispatch(stop());
                onFailure?.();

                return console.error('error', e);
            }
        };

export const usersStatisticAction =
    ({ onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(start());
                api.get('users/stats/role')
                    .then((response) => {
                        dispatch(setUsersStatistics(response.data));
                        onSuccess?.(response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        onFailure?.();
                    });
            } catch (e) {
                dispatch(stop());
                onFailure?.();

                return console.error('error', e);
            }
        };

export const addUserAction =
    ({ userDto, onSuccess, onFailure }) =>
        async (dispatch) => {
            try {
                dispatch(start());
                api.post('users', userDto)
                    .then((response) => {
                        if (onSuccess) {
                            onSuccess();
                        }
                        dispatch(stop());
                        return response;
                    })
                    .catch((error) => {
                        dispatch(stop());
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure();
                        }
                    });
            } catch (e) {
                dispatch(stop());
                return console.error(e.message);
            }
        };

export default userSlice.reducer;
