import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useSupportedLangs() {
    const { t } = useTranslation();

    const supportedLangs = useMemo(() => {
        return [
            {
                languageCode: 'en',
                language: t('common.constants.lang.en'),
            },
            {
                languageCode: 'es',
                language: t('common.constants.lang.es'),
            },
            {
                languageCode: 'de',
                language: t('common.constants.lang.de'),
            },
            {
                languageCode: 'fr',
                language: t('common.constants.lang.fr'),
            },
            {
                languageCode: 'it',
                language: t('common.constants.lang.it'),
            },
            {
                languageCode: 'pl',
                language: t('common.constants.lang.pl'),
            },
            {
                languageCode: 'pt',
                language: t('common.constants.lang.pt'),
            },
            {
                languageCode: 'pt-br',
                language: t('common.constants.lang.ptBr'),
            },
            {
                languageCode: 'uk',
                language: t('common.constants.lang.uk'),
                unsupportedTranscription: true,
            },
            {
                languageCode: 'ro',
                language: t('common.constants.lang.ro'),
            },
            {
                languageCode: 'nl-be',
                language: t('common.constants.lang.nlBe'),
                unsupportedTranscription: true,
            },
            {
                languageCode: 'sr',
                language: t('common.constants.lang.sr'),
            },
            {
                languageCode: 'nl',
                language: t('common.constants.lang.nl'),
            },
            {
                languageCode: 'sq',
                language: t('common.constants.lang.sq'),
                unsupportedTranscription: true,
            },
            {
                languageCode: 'mk',
                language: t('common.constants.lang.mk'),
            },
            {
                languageCode: 'sv',
                language: t('common.constants.lang.sv'),
            },
        ];
    }, [t]);

    return supportedLangs;
}
