import { makeStyles } from 'tss-react/mui';

const useStylesLayout = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            background: '#F8F8F8',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: '1 !important',
            marginTop: '80px',
            overflow: 'hidden',
            transition: 'all ease-in-out 0.2s',
            '&.contentDialogMode': {
                marginTop: 0,
            }
        },

        sidebar: {
            maxWidth: '18.28%',
            flex: '0 0 18.28%',
            display: 'flex',
            flexDirection: 'column',
        },

        drawerPaper: {
            width: '18.28%',
        },

        content: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            height: 'calc(100vh - 80px)',
            boxSizing: 'border-box',
            '&.contentDialogMode': {
                height: '100vh'
            }
        },

        contentArea: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            overflow: 'hidden',
        },

        paddingSpaceWidget: {
            paddingBottom: '50px',
        },
        scroll: {
            overflowX: 'hidden',
            // height: 'calc(100% - 163px)',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    };
});

export default useStylesLayout;
