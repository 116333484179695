import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const actorSlice = createSlice({
    name: 'actor',
    initialState: {
        pending: false,
        savePending: false,
        actors: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        resetActors: (state, action) => {
            state.actors = [];
        },
        setActors: (state, action) => {
            state.pending = false;
            state.actors = action.payload?.sort((a, b) => {
                if (a.isAdmin || a.role === 'admin') return -1;
                if (b.isAdmin || b.role === 'admin') return 1;
                if (a.role === 'host') return -1;
                if (b.role === 'host') return 1;
                return 0;
            });
        },
        addActor: (state, action) => {
            state.actors = [...(state.actors ? state.actors : []), action.payload];
        },
        editActor: (state, action) => {
            state.savePending = false;
            const index = state.actors.findIndex((actor) => actor.id === action.payload.id);
            if (index !== -1) {
                state.actors = [...state.actors.slice(0, index), action.payload, ...state.actors.slice(index + 1)];
            }
        },
        deleteActor: (state, action) => {
            const index = action.payload;
            state.actors = [...state.actors.slice(0, index), ...state.actors.slice(index + 1, state.actors.length)];
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const { start, startSave, resetActors, setActors, addActor, editActor, deleteActor, setError, setSaveError } =
    actorSlice.actions;

export const getActors = (state) => state.actor.actors;
export const getActorsPending = (state) => state.actor.pending;
export const getActorsSavePending = (state) => state.actor.savePending;
export const getActorsError = (state) => state.actor.error;

export const fetchEventActorsAction =
    (eventId, { onSuccess, onError } = {}) =>
    async (dispatch) => {
        if (!eventId) return dispatch(setActors([]));
        try {
            dispatch(start());
            api.get('actors/event/' + eventId)
                .then((response) => {
                    dispatch(setActors(response.data));

                    if (onSuccess) {
                        onSuccess(response);
                    }
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onError) {
                        onError(error);
                    }
                });
        } catch (e) {
            dispatch(setError(false));
            if (onError) {
                onError(e);
            }
            return console.error(e.message);
        }
    };

export const resendActorEmailAction = (eventId, actorId) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('actors/resend-email/event/' + eventId + '/actor/' + actorId, {})
            .then((response) => {
                dispatch(editActor(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(setError(false));
        return console.error(e.message);
    }
};

export default actorSlice.reducer;
