import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import { IconButton } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Menu from './Menu';
import useStylesSidebar from './styles/useStylesSidebar.styles';

const drawerWidth = 240;
const drawerWidthXL = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('xl')]: {
        width: drawerWidthXL,
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(5)} + 1px)`,
    [theme.breakpoints.up('xl')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    minWidth: theme.spacing(6.5),
    zIndex: 2,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'hsl(240 4.8% 95.9%)',
    [theme.breakpoints.up('xl')]: {
        width: drawerWidthXL,
    },
    '& .MuiDrawer-paper': {
        overflowX: 'hidden',
        // height: 'calc(100% - 163px)',
        /* Hide scrollbar for IE, Edge and Firefox */
        scrollbarWidth: 'none',
        /* Hide scrollbar for Chrome, Safari and Opera */
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const Sidebar = ({ className }) => {
    const { classes, cx } = useStylesSidebar();
    const [open, setOpen] = useState(true);

    const handleDrawerClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResize = () => {
        if (window.innerWidth < 1024) {
            setOpen(false);
        }
    };

    return (
        <Drawer
            className={cx(classes.drawer, className)}
            variant="permanent"
            anchor="left"
            open={open}
            classes={{
                paper: cx(classes.drawerPaper, classes.scroll),
            }}
        >
            <DrawerHeader>
                <IconButton size="small" onClick={handleDrawerClick}>
                    {open ? <MenuOpenOutlinedIcon /> : <MenuIcon />}
                </IconButton>
            </DrawerHeader>

            <Menu openMenu={open} />
        </Drawer>
    );
};

export default Sidebar;
