import { makeStyles } from 'tss-react/mui';

const useStylesProduct = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary.main,
            flexGrow: 1,
            overflow: 'hidden',
        },
        scroll: {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 32px)',
            width: '100%',
            overflowX: 'hidden',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        body: {
            padding: theme.spacing(7, 7, 0),
        },
        productsTitle: {
            flexGrow: 1,
            fontWeight: theme.typography.fontWeightMedium,
        },
        searchField: {
            width: '25ch',
            // height: '20px'
        },
        productsHeader: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: theme.spacing(2),
        },

        productsBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        },

        searchButton: {
            marginLeft: theme.spacing(1),
            textTransform: 'none',
        },

       pagination: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            position: 'absolute',
            bottom: theme.spacing(1),
        },

        syncAlertArea: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // height: '100%',
            width: '100%',
            marginBottom: theme.spacing(2),
        },

        productArea: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
        },

        syncingAlert: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            '& > :last-child': {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexDirection: 'row',
            },
        },
    };
});

export default useStylesProduct;
