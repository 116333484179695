import { useTranslation } from "react-i18next";

const Footer = () => {

    const { t } = useTranslation();

    return (
        <div className="footer">
            <span>{t("components.footer.onliveYear", {year: 2022})} &copy; {t("components.footer.allRights")}</span>
        </div>
    );
}

export default Footer;