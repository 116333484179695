import { Stack, Typography } from '@mui/material';
import OCalendar from 'app/ocalendar/OCalendar';
import MyAvailability from 'app/profile/availability/MyAvailability';
import MyAvailabilityCreateAction from 'app/profile/availability/MyAvailabilityCreateAction';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import useFlexBoxStyles from 'common/flexBox';
import LayoutForm from 'common/layouts/LayoutForm';
import { ValidatorForm } from 'react-material-ui-form-validator';

const UserAvailability = ({ userData }) => {
    const { userId, title } = userData;

    const { classes: flexBox, cx } = useFlexBoxStyles();
    const isAuthorize = useAuthLicenseAddOns();
    const iCalendar = isAuthorize(['oneToOne.iCalendar']);

    return (
        <LayoutForm
            size="big"
            customClasses={flexBox.vH70}
            extra={
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Stack sx={{ minWidth: 0 }}>
                        <Typography direction="row" fontWeight="bold" variant="onliveH3" noWrap>
                            {title?.toUpperCase()}
                        </Typography>
                    </Stack>
                    <Stack>
                        <MyAvailabilityCreateAction userData={userData} />
                    </Stack>
                </Stack>
            }
        >
            <Stack direction="column" className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone, flexBox.h100)}>
                {iCalendar ? (
                    <MyAvailability userData={userData} />
                ) : (
                    <ValidatorForm onSubmit={() => {}}>
                        <OCalendar userId={userId} isReadOnly={false} scrollable={true} />
                    </ValidatorForm>
                )}
            </Stack>
        </LayoutForm>
    );
};

export default UserAvailability;
