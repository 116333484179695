import { makeStyles } from 'tss-react/mui';

const useFullLoadingStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background:
            ' transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #DBDBDB 100%) 0% 0% no-repeat padding-box',
    },
    icon: {
        height: '16vh',
        width: '16vh',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ebebeb',
        '& svg': {
            fontSize: '12vh',
            color: '#c3c3c3',
        },
    },
    text: {
        marginTop: '3vh',
        fontSize: '2vh',
        marginBottom: '1vh',
        '&:first-letter': {
            textTransform: 'capitalize',
        },
    },
    logo: {
        position: 'absolute',
        bottom: 0,
        '& img': {
            height: '3vh',
        },
    },
    backgroundImage: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        '& img': {
            width: '100%',
        },
    },
}));

export default useFullLoadingStyles;
