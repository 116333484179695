import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const couponsSlice = createSlice({
    name: 'coupons',
    initialState: {
        pending: false,
        savePending: false,
        coupons: [],
        error: null,
        total: 0,
        pendingTotal: false,
        errorTotal: null,
        savePromotionalCodePending: false,
        errorSavePromotionalCode: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setCoupons: (state, action) => {
            state.pending = false;
            state.coupons = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.savePending = false;
            state.coupons = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startSavePromotionalCode: (state, action) => {
            state.savePromotionalCodePending = true;
        },
        stopSavePromotionalCode: (state, action) => {
            state.savePromotionalCodePending = false;
        },
        addCoupon: (state, action) => {
            state.savePending = false;
            state.coupons = [...state.coupons, action.payload];
        },
        editCoupon: (state, action) => {
            state.savePending = false;
            const index = state.coupons.findIndex((coupon) => coupon.id === action.payload.id);
            if (index !== -1) {
                state.coupons = [...state.coupons.slice(0, index), action.payload, ...state.coupons.slice(index + 1)];
            }
        },
        addPromotionalCodeInCoupon: (state, action) => {
            state.savePromotionalCodePending = false;
            state.pending = false;
            const newCoupons = state.coupons.map((coupon) =>
                coupon.id === action.payload.id
                    ? {
                          ...coupon,
                          promotionalCodes: action.payload.data,
                      }
                    : { ...coupon },
            );

            state.coupons = newCoupons;
        },
        setErrorSavePromotionalCode: (state, action) => {
            state.savePromotionalCodePending = false;
            state.pending = false;
            state.errorSavePromotionalCode = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.couponId = action.payload;
        },
        deleteCoupon: (state, action) => {
            state.deletePending = false;
            state.couponId = null;
            const index = state.coupons.findIndex((coupon) => coupon.id === action.payload.id);
            if (index !== -1) {
                state.coupons = [...state.coupons.slice(0, index), ...state.coupons.slice(index + 1)];
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        setTotal: (state, action) => {
            state.pendingTotal = false;
            state.total = action.payload;
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
        startFetchTotal: (state, action) => {
            state.pendingTotal = true;
            state.total = 0;
        },
        setErrorTotal: (state, action) => {
            state.pendingTotal = false;
            state.total = 0;
            state.errorTotal = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
    },
});

export const {
    start,
    stop,
    setCoupons,
    setError,
    startSave,
    addCoupon,
    editCoupon,
    setSaveError,
    startDelete,
    deleteCoupon,
    setDeleteError,
    setProgress,
    addPromotionalCodeInCoupon,
    startSavePromotionalCode,
    setErrorSavePromotionalCode,
    stopSave,
    stopSavePromotionalCode,
} = couponsSlice.actions;

export const getCoupons = (state) => state.coupons.coupons;
export const getCouponsPending = (state) => state.coupons.pending;
export const getCouponSavePending = (state) => state.coupons.savePending;
export const getCouponDeletePending = (state) => state.coupons.deletePending;
export const getCouponError = (state) => state.coupons.error;
export const getCouponId = (state) => state.coupons.videoId;
export const getErrorSavePromotionalCode = (state) => state.coupons.errorSavePromotionalCode;
export const getSavePromotionalCodePending = (state) => state.coupons.savePromotionalCodePending;
export const getCouponsById = (id) => (state) => {
    const coupons = state.coupons.coupons.filter((item) => item.id === id);
    return coupons.length > 0 ? coupons[0] : null;
};

export const fetchCouponsAction =
    ({ queryParams } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('stripe/coupon' + query)
                .then((response) => {
                    dispatch(setCoupons(response.data));
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchPromotionalCodesOfCouponAction =
    ({ couponId } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get('stripe/promotion-code/coupon/' + couponId)
                .then((response) => {
                    dispatch(addPromotionalCodeInCoupon({ id: couponId, data: response.data }));
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchPromotionCode =
    ({code, onSuccess, onFailure} = {}) =>
        async (dispatch) => {
            try {
                dispatch(start());
                api.get('stripe/promotion-code/code/' + code)
                    .then((response) => {
                        dispatch(stop());
                        onSuccess && onSuccess(response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(stop());
                        onFailure && onFailure(error);
                    });
            } catch (e) {
                dispatch(stop());
                onFailure && onFailure(e);
                return console.error(e.message);
            }
        };

export const fetchPromotionCodeById =
    ({id, onSuccess, onFailure} = {}) =>
        async (dispatch) => {
            try {
                dispatch(start());
                api.get('stripe/promotion-code/' + id)
                    .then((response) => {
                        dispatch(stop());
                        onSuccess && onSuccess(response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(stop());
                        onFailure && onFailure(error);
                    });
            } catch (e) {
                dispatch(stop());
                onFailure && onFailure(e);
                return console.error(e.message);
            }
        };

export const addCouponAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('stripe/coupon', data)
                .then((response) => {
                    dispatch(addCoupon(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    console.error(error);
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const addPromotionalCodeAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSavePromotionalCode());
            api.post('stripe/promotion-code', data)
                .then((response) => {
                    dispatch(fetchPromotionalCodesOfCouponAction({ couponId: data.coupon }));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setErrorSavePromotionalCode(error));
                    console.error(error);
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSavePromotionalCode());
            return console.error(e.message);
        }
    }; 

export const updatePromotionalCodeAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSavePromotionalCode());
            api.put('stripe/promotion-code/' + id, data)
                .then((response) => {
                    dispatch(fetchPromotionalCodesOfCouponAction({ couponId: data?.coupon?.id }));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setErrorSavePromotionalCode(error));
                    console.error(error);
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSavePromotionalCode());
            return console.error(e.message);
        }
    };

export const editCouponAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('stripe/coupon/' + id, data)
                .then((response) => {
                    dispatch(editCoupon(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    console.error(error);
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const deleteCouponAction =
    ({ id } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.delete('coupons/' + id)
                .then((response) => {
                    dispatch(deleteCoupon(response.data));
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export default couponsSlice.reducer;
