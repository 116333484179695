import { makeStyles } from 'tss-react/mui';

const triageAppStyles = makeStyles()((theme) => {
    return {
        content: {
            width: '60vw',
            padding: '106px 50px 0px 50px',
            maxWidth: '440px',
            maxHeight: '90vh',
            minHeight: '260px',
            position: 'relative',
            overflow: 'auto',
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineClamp: 2,
            boxOrient: 'vertical',
            whiteSpace: 'normal',
            //webkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
        },
        titleContent: {
            width: '80%',
            paddingTop: theme.spacing(4),
            position: 'absolute',
            left: '10%',
            top: '0',
            backgroundColor: '#fff',
        },
    };
});

export default triageAppStyles;
