import { createSlice } from '@reduxjs/toolkit';
import { calendarApiV3 as api } from 'api';

export const icalendarProviderSlice = createSlice({
    name: 'icalendarProvider',
    initialState: {
        pending: false,
        isConnected: false,
        providerType: '',
        credentialLevel: '',
        hasCredential: false,
        pendingHasCredential: true,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startHasCredential: (state, action) => {
            state.pendingHasCredential = true;
        },
        setIntegrationStatus: (state, action) => {
            state.isConnected = action.payload.isConnected;
            state.providerType = action.payload.providerType;
            state.credentialLevel = action.payload.credentialLevel;
            state.pending = false;
        },
        setHasCredential: (state, action) => {
            state.hasCredential = action.payload.hasCredential;
            state.pendingHasCredential = false;
        },
    },
});

export const {
    start,
    startHasCredential,
    setIntegrationStatus,
    setHasCredential,
} = icalendarProviderSlice.actions;

export const getPendingIntegration = (state) => state.icalendarProvider.pending;
export const getIsConnected = (state) => state.icalendarProvider.isConnected;
export const getProviderType = (state) => state.icalendarProvider.providerType;
export const getCredentialLevel = (state) => state.icalendarProvider.credentialLevel;
export const getHasCredential = (state) => state.icalendarProvider.hasCredential;
export const getPendingHasCredential = (state) => state.icalendarProvider.pendingHasCredential;

export const fetchCalendarIntegrationAction = (organizationId, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(start());
        api.get(`integrations?organizationId=${organizationId}`)
            .then((response) => {
                if (response.data && !response.data.deletedAt && response.data.status) {
                    dispatch(setIntegrationStatus({
                        isConnected: true,
                        providerType: response.data.provider,
                        credentialLevel: response.data.credentialLevel,
                    }));
                } else {
                    dispatch(setIntegrationStatus({
                        isConnected: false,
                        providerType: '',
                        credentialLevel: '',
                    }));
                }
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export const enableCalendarIntegrationAction = (organizationId, data, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(start());
        api.post(`integrations?organizationId=${organizationId}`, {
            credentialLevel: data.credentialLevel,
            provider: data.provider,
            status: true,
        })
            .then((response) => {
                dispatch(setIntegrationStatus({
                    isConnected: true,
                    providerType: data.provider,
                    credentialLevel: data.credentialLevel,
                }));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export const disableCalendarIntegrationAction = (organizationId, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(start());
        api.post(`integrations/disable?organizationId=${organizationId}`)
            .then((response) => {
                dispatch(setIntegrationStatus({
                    isConnected: false,
                    providerType: '',
                    credentialLevel: '',
                }));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export const fetchHasCredentialAction = (organizationId, calendarId, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startHasCredential());
        let queryParams = `${organizationId ? `?organizationId=${organizationId}` : ''}`;
        const separator = queryParams ? '&' : '?';
        queryParams += `${calendarId ? `${separator}calendarId=${calendarId}` : ''}`;
        api.get(`integrations/has-credential${queryParams}`)
            .then((response) => {
                dispatch(setHasCredential(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export default icalendarProviderSlice.reducer;