import { useDispatch } from 'react-redux';
import { trackAction } from '../../redux/slices/trackCallCenter';

export function useTrackingCallCenter() {
    const dispatch = useDispatch();
    /**
     * @param type
     * @param data
     * @private
     */
    const track = ({ type, data }) => {
        dispatch(trackAction({ type, data }));
    };

    return {
        track,
    };
}
