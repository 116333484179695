import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Stack, Typography } from '@mui/material';
import useColorsStyles from 'common/colors';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { useTranslation } from 'react-i18next';
import 'suneditor/dist/css/suneditor.min.css';

const SecurityCardItem = ({
    icon,
    title,
    content,
    managedByOrganization,
    confirmed,
    warningText,
    onConfigure,
    onDelete,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: colors } = useColorsStyles();
    const { classes: onlive } = useOnliveStyles();

    return (
        <Stack
            direction="column"
            className={cx(flexBox.padding2, flexBox.borderRadius2, onlive.borderMediumGray, colors.bgPaper)}
        >
            <Stack direction="column" spacing={2}>
                <Typography variant="h6">{title}</Typography>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        {icon}
                        <Typography variant="subtitle1" fontWeight={400}>
                            {content}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            {onConfigure ? (
                                <CustomTooltip
                                    arrow
                                    title={t('profile.settings.emailsSettings.editEmailTemplate.reset')}
                                    aria-label="reload"
                                    placement="bottom"
                                >
                                    <CustomIconButton size="small" aria-label="download report" onClick={onConfigure}>
                                        <RefreshOutlinedIcon />
                                    </CustomIconButton>
                                </CustomTooltip>
                            ) : null}

                            {!confirmed && Boolean(onConfirm) && (
                                <CustomTooltip
                                    arrow
                                    title={t('app.common.validate')}
                                    aria-label="confirm"
                                    placement="bottom"
                                >
                                    <CustomIconButton size="small" aria-label="delete" onClick={onConfirm}>
                                        <PasswordOutlinedIcon />
                                    </CustomIconButton>
                                </CustomTooltip>
                            )}

                            {onDelete && (
                                <CustomTooltip
                                    arrow
                                    title={t('app.common.delete')}
                                    aria-label="delete report"
                                    placement="bottom"
                                >
                                    <CustomIconButton size="small" aria-label="delete" onClick={onDelete}>
                                        <DeleteOutlinedIcon />
                                    </CustomIconButton>
                                </CustomTooltip>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
                {!confirmed && (
                    <Stack direction="row" spacing={2} alignItems="center">
                        <WarningAmberOutlinedIcon className={colors.textWarning} />
                        <Typography variant="subtitle1" fontWeight={400}>
                            {warningText}
                        </Typography>
                    </Stack>
                )}
                {managedByOrganization && (
                    <Stack direction="row" spacing={2} alignItems="center">
                        <InfoOutlinedIcon fontSize="small" color="warning" />
                        <Typography variant="subtitle2" fontWeight={400}>
                            {t('profile.security.method.managedByOrganization')}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default SecurityCardItem;
