import SelectAssets from 'app/iCalendar/filters/SelectAssets';
import SelectGroups from 'app/iCalendar/filters/SelectGroups';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { customMenuPropsRight } from 'common/utils/menuProps';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getUser } from 'redux/slices/authSlice';
import { getGroupsByIds } from 'redux/slices/groupSlice';
import { fetchUnitsAction } from 'redux/slices/unitSlice';
import SelectTemplateType from './SelectTemplateType';

const ServicesFilter = () => {
    const user = useSelector(getUser);
    const enableGroups = useSettingValue(SettingsKey.ENABLE_USER_GROUPS);
    const showGroupFilter = enableGroups && !user.userGroupId && user.role !== 'creator';
    const dispatch = useDispatch();
    const groups = useSelector(getGroupsByIds);
    const [params, setSearchParams] = useSearchParams();
    const groupId = params.get('group') || 'all';

    useEffect(() => {
        dispatch(fetchUnitsAction());
    }, [dispatch]);

    const onSubmit = async (newValue, type) => {
        let updatedSearchParams = new URLSearchParams(params.toString());
        if (newValue) {
            updatedSearchParams.set(type, newValue.target.value);
            setSearchParams(updatedSearchParams.toString());
        } else {
            updatedSearchParams.delete(type);
            setSearchParams(updatedSearchParams.toString());
        }
    };

    return (
        <>
            <SelectTemplateType
                customMenuProps={customMenuPropsRight}
                onSubmit={onSubmit}
                fullWidth={false}
                size="smallWidth"
            />

            {showGroupFilter ? (
                <SelectGroups
                    customMenuProps={customMenuPropsRight}
                    onSubmit={onSubmit}
                    groupId={groupId}
                    selectAll={true}
                    selectedGroupById={groups?.[0]}
                    fullWidth={false}
                    size="smallWidth"
                />
            ) : null}
            <SelectAssets
                customMenuProps={customMenuPropsRight}
                onSubmit={onSubmit}
                size="smallWidth"
                fullWidth={false}
            />
        </>
    );
};

export default ServicesFilter;
