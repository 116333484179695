import { createSlice } from '@reduxjs/toolkit';
import { createTracking } from 'api/request/tracking';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { getLocationOrigin } from 'common/utils/location';

const initialState = {};

export const trackSlice = createSlice({
    name: 'tracking_call_center',
    initialState,
    reducers: {},
});

/**
 * Track
 * @param data
 * @param type
 */
export const trackAction =
    ({ type, data }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const organization = state.organization?.organization;
            const user = state.auth?.user;
            const widgetVersion =
                state.setting?.settings?.find((s) => s.settingKey === SettingsKey.WIDGET_VERSION)?.settingValue || 'v1';
            const channelURL = getLocationOrigin();
            const payloadToSend = {
                type,
                organizationId: organization?.id,
                data: {
                    eventType: 'one_to_one',
                    organizationId: organization?.id,
                    organizationName: organization?.name,
                    organizationDemo: organization?.demo,
                    actorName: user?.firstName,
                    actorEmail: user?.email,
                    agentName: user?.firstName,
                    agentEmail: user?.email,
                    agentId: user?.userId,
                    callDirection: (data.interactionId && 'incoming') || 'outgoing',
                    channelURL,
                    widgetVersion,
                    ...data,
                },
            };

            createTracking(data.id, payloadToSend);
        } catch (e) {
            return console.error(e.message);
        }
    };
export default trackSlice.reducer;
