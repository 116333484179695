import { makeStyles } from 'tss-react/mui';

const useStylesProfileOrganization = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary.main,
            flexGrow: 1,
            overflow: 'hidden',
        },
        scroll: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflowX: 'hidden',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        body: {
            padding: theme.spacing(1, 7),
        },
        profileFormContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        logoContainer: {
            alignItems: 'center',
            marginTop: theme.spacing(1),
            display: 'flex',
            height: '100%',
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(1.5),
            },
        },
        logo: {
            width: 'auto',
            height: theme.spacing(3),
            [theme.breakpoints.up('xl')]: {
                marginTop: theme.spacing(3.5),
            },
        },
        mainImage: {
            width: '100%',
            height: 'auto',
        },

        parentInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        buttonSave: {
            margin: 0,
            width: '120px !important',
            top: 'auto',
            right: 120,
            bottom: 48,
            left: 'auto',
            position: 'fixed',
            [theme.breakpoints.down('md')]: {
                bottom: 16,
            },
        },
        pending: {
            width: '50px',
            margin: '20px auto',
        },
        uploadContent: {
            display: 'flex',
            alignItems: 'center',
            '& >label': {
                color: '#000',
                fontWeight: 500,
                fontSize: theme.spacing(1.5),
                marginRight: theme.spacing(1.5),
                [theme.breakpoints.up('xl')]: {
                    fontSize: theme.spacing(2),
                    marginRight: theme.spacing(2),
                },
            },
        },
        selectAssetItem: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            fontSize: theme.typography.fontSize,
            padding: theme.spacing(1),
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(1.5),
            },
        },
        actionAsset: {
            position: 'absolute',
            border: '0.5px solid #E2E2E2',
            top: theme.spacing(1.5),
            right: theme.spacing(1.5),
            borderRadius: '50%',
            zIndex: 2,
            background: theme.palette.background.paper,
            '& span': {
                padding: theme.spacing(0.75),
                [theme.breakpoints.up('xl')]: {
                    padding: theme.spacing(1),
                },
            },
            [theme.breakpoints.up('xl')]: {
                top: theme.spacing(2),
                right: theme.spacing(2),
            },
            '& > :first-of-type': {
                height: theme.spacing(3.25),
                width: theme.spacing(3.25),
                [theme.breakpoints.up('xl')]: {
                    height: theme.spacing(3.75),
                    width: theme.spacing(3.75),
                },
            },
        },
        selectAssetItemImage: {
            height: theme.spacing(13),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            '& > img': {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: theme.spacing(2),
                height: theme.spacing(20),
            },
        },
        effectHeader: {
            height: theme.spacing(4.5),
            display: 'flex',
            alignItems: 'center',
            color: '#000',
            fontSize: theme.spacing(1.5),
            fontWeight: 500,
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(2),
                height: theme.spacing(5),
            },
        },
        containerForm: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
        },
        boxGeneralSettings: {
            width: '100%',
        },
        rowCenter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        iconCopyClipboard: {
            padding: 0,
            paddingLeft: '4px',
        },
    };
});

export default useStylesProfileOrganization;
