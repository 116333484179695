import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'communication-logs';

export const communicationLogSlice = createSlice({
    name: 'communicationLog',
    initialState: {
        pending: false,
        communicationLogs: [],
        error: null,
        currentPage: 0,
        totalPages: 0,
        count: 0,
        savePending: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        setCommunicationLogs: (state, action) => {
            state.pending = false;
            // state.communicationLogs = action.payload.items;
            // state.currentPage = action.payload.currentPage;
            // state.totalPages = action.payload.totalPages;
            // state.count = action.payload.count;
            state.communicationLogs = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addCommunicationLog: (state, action) => {
            state.savePending = false;
            state.communicationLogs = [...state.communicationLogs, action.payload];
        },
        editCommunicationLog: (state, action) => {
            state.savePending = false;
            const index = state.communicationLogs.findIndex(
                (communicationLog) => communicationLog.id === action.payload.id,
            );
            if (index !== -1) {
                state.communicationLogs = [
                    ...state.communicationLogs.slice(0, index),
                    action.payload,
                    ...state.communicationLogs.slice(index + 1),
                ];
            }
        },
        deleteCommunicationLog: (state, action) => {
            state.pending = false;
            const index = state.communicationLogs.findIndex(
                (communicationLog) => communicationLog.id === action.payload.id,
            );
            if (index !== -1) {
                state.communicationLogs = [
                    ...state.communicationLogs.slice(0, index),
                    ...state.communicationLogs.slice(index + 1),
                ];
            }
        },
        resetCommunicationLogs: (state, action) => {
            state.communicationLogs = [];
            state.currentPage = 0;
            state.totalPages = 0;
            state.count = 0;
        },
    },
});

export const {
    start,
    addCommunicationLog,
    stop,
    setCommunicationLogs,
    setError,
    editCommunicationLog,
    deleteCommunicationLog,
    resetCommunicationLogs,
    startSave,
    stopSave,
    setSaveError,
} = communicationLogSlice.actions;

export const getCommunicationLogs = (state) => state.communicationLog.communicationLogs;
export const getCommunicationLogsPending = (state) => state.communicationLog.pending;
export const getCommunicationLogsError = (state) => state.communicationLog.error;
export const getSavePending = (state) => state.communicationLog.savePending;

export const addCommunicationLogAction = (dto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post(resource, dto)
            .then((response) => {
                dispatch(addCommunicationLog(response.data));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const editCommunicationLogAction = (id, dto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put(`${resource}/${id}`, dto)
            .then((response) => {
                dispatch(editCommunicationLog(response.data));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteCommunicationLogAction = (id, success, failure) => async (dispatch) => {
    try {
        dispatch(start());
        api.delete(`${resource}/${id}`)
            .then((response) => {
                dispatch(deleteCommunicationLog(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchCommunicationLogsCsvAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get(`${resource}/report`, {
                params: queryParams,
            })
                .then((response) => {
                    if (onSuccess) {
                        const fileName = response.headers['content-disposition'].split('filename=')[1];
                        onSuccess(response.data, fileName);
                    }
                    dispatch(stop());
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchCommunicationLogsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            let query = transformFiltersToQuery(queryParams);
            api.get(`${resource}` + query)
                .then((response) => {
                    dispatch(setCommunicationLogs(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchCommunicationLogsContentInstanceAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            let query = transformFiltersToQuery(queryParams);
            api.get(`${resource}/content-instance` + query)
                .then((response) => {
                    dispatch(setCommunicationLogs(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export default communicationLogSlice.reducer;
