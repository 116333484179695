import { createSlice } from '@reduxjs/toolkit';

export const calendarNotificationSlice = createSlice({
    name: 'calendarNotification',
    initialState: {
        initialized: false,
    },
    reducers: {
        setInitialized: (state, action) => {
            state.initialized = action.payload;
        }
    },
});

export const { setInitialized } = calendarNotificationSlice.actions;

export const getInitialized = (state) => state.calendarNotification.initialized;

export default calendarNotificationSlice.reducer;