const INTERNAL_ONLIVE = "onlive";

const FILE_CSV = "csv";

const FILE_GOOGLE_FEED_RSS = "google_feed_rss";

const FILE_GOOGLE_FEED_ATOM = "google_feed_atom";

const SHOPIFY_PRIVATE = "shopify-private";

const PRESTASHOP = "prestashop";

const CLOUDSTORE = "cloudstore";

const WOOCOMMERCE = "woocommerce";

const MODDO = "moddo";

const ECOMMERCE_PROVIDERS_LIST = [
    SHOPIFY_PRIVATE,
    PRESTASHOP,
    CLOUDSTORE,
    WOOCOMMERCE,
    MODDO
];

const FILE_PROVIDERS_LIST = [
    FILE_CSV,
    FILE_GOOGLE_FEED_ATOM,
    FILE_GOOGLE_FEED_RSS
];

const isInternalIntegrationFile = (integrationType) => {
    return [
        FILE_CSV,
        FILE_GOOGLE_FEED_RSS,
        FILE_GOOGLE_FEED_ATOM
    ].includes(integrationType);
};

const isInternalIntegration = (integrationType) => {
    return [
        INTERNAL_ONLIVE,
        FILE_CSV,
        FILE_GOOGLE_FEED_RSS,
        FILE_GOOGLE_FEED_ATOM
    ].includes(integrationType);
}

const isEditableIntegration = (integrationType) => {
    return !ECOMMERCE_PROVIDERS_LIST.includes(integrationType) || integrationType === SHOPIFY_PRIVATE;
}

const isExternalIntegrationSource = (integrationType) => {
    return (
        ECOMMERCE_PROVIDERS_LIST.includes(integrationType)
        ||
        FILE_PROVIDERS_LIST.includes(integrationType)
    );
}

export {
    INTERNAL_ONLIVE,
    FILE_CSV,
    FILE_GOOGLE_FEED_RSS,
    FILE_GOOGLE_FEED_ATOM,
    SHOPIFY_PRIVATE,
    PRESTASHOP,
    CLOUDSTORE,
    WOOCOMMERCE,
    MODDO,
    ECOMMERCE_PROVIDERS_LIST,
    FILE_PROVIDERS_LIST,
    
    isInternalIntegration,
    isInternalIntegrationFile,
    isExternalIntegrationSource,
    isEditableIntegration,
};