import { Divider, Stack, Typography } from '@mui/material';
import ReloadButton from 'common/components/ReloadButton';
import CustomDateRange from 'common/features/filters/components/CustomDateRange';
import GridTableViewMode from 'common/features/filters/components/GridTableViewModeFilter';
import SearchInput from 'common/features/filters/components/SearchInput';
import { FiltersProvider } from 'common/features/filters/FiltersContext';
import useFlexBoxStyles from 'common/flexBox';
import useFontStyles from 'common/fontStyles';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        filter: {
            '& > *': {
                marginTop: theme.spacing(0.5) + ' !important',
                marginBottom: theme.spacing(0.5) + ' !important',
            },
        },
    };
});

const LayoutSectionHeader = (props) => {
    const { filters, total, extra, actions, onReload, pending, children, viewMode } = props;
    const { showSearch, showDateFilter, customFilters, maxDate, searchMinLength, showFilters = true } = filters ?? {};
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: fonts } = useFontStyles();
    const { classes } = useStyles();

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            className={cx(flexBox.overflowHidden, flexBox.overflowHidden)}
        >
            {showFilters ? (
                <FiltersProvider fullWidth={false}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        className={cx(flexBox.padding10, flexBox.w100, flexBox.wrap, classes.filter)}
                    >
                        {showSearch && <SearchInput searchMinLength={searchMinLength} size="small" />}
                        {onReload ? <ReloadButton onReload={onReload} pending={pending} /> : null}
                        {viewMode ? <GridTableViewMode /> : null}
                        {actions}
                        {showDateFilter && <CustomDateRange maxDate={maxDate} size="smallWidth" />}
                        {customFilters}
                    </Stack>
                </FiltersProvider>
            ) : null}

            {showFilters && (extra || Boolean(total)) ? <Divider className={flexBox.w100} /> : null}

            {extra || Boolean(total) ? (
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    className={cx(flexBox.padding20, flexBox.w100)}
                >
                    {extra}
                    {Boolean(total) && (
                        <Typography
                            variant="subtitle2"
                            noWrap
                            className={cx(fonts.fontStyleItalic, flexBox.paddingRight1, flexBox.overflowVisible)}
                        >
                            {t('event.event.total') + ': ' + total}
                        </Typography>
                    )}
                </Stack>
            ) : null}

            {children}
        </Stack>
    );
};

export default LayoutSectionHeader;
