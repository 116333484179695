export const ErrorCode = {
    DuplicateEntity: 'DUPLICATE_ENTITY',
    GeneralError: 'GENERAL_ERROR',
    InvalidId: 'INVALID_ID',
    InvalidName: 'INVALID_NAME',
    InvalidPayload: 'INVALID_PARAMETERS',
    InvalidToken: 'INVALID_TOKEN',
    LicenseRevoked: 'LICENSE_REVOKED',
    MissingId: 'MISSING_ID',
    MissingPermission: 'MISSING_PERMISSION',
    Unauthorized: 'UNAUTHORIZED',
    UnknownEntity: 'UNKNOWN_ENTITY',
    UnknownError: 'UNKNOWN_ERROR',
    UnexpectedError: 'UNEXPECTED_ERROR',
    InvalidState: 'INVALID_STATE',
    PaymentError: 'PAYMENT_ERROR',
    QueryError: 'QUERY_ERROR',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
};