import { Alert, CircularProgress } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getContentDialogMode, setContentDialogMode } from '../../redux/slices/appSlice';
import { getUser, testFeatureEnabled } from '../../redux/slices/authSlice';
import { closeCustomDialogAction, openCustomDialog } from '../../redux/slices/customDialogSlice';
import { getMicroFrontsConfig, getOrganization } from '../../redux/slices/organizationSlice';
import { getLanguage, getSettings } from '../../redux/slices/settingSlice';
import { useSupportedLangs } from '../../common/constants/langs';
import YesNoDialog from '../../common/components/YesNoDialog';
import { useNavigate } from 'react-router-dom';
import EditContactAction from './actions/EditContactAction';
import CreateContactAction from './actions/CreateContactAction';
import { useMicroFrontContext } from './MicroFrontContext';

const useMicroFrontUrl = (slug) => {
    const microFrontsConfig = useSelector(getMicroFrontsConfig);
    return microFrontsConfig?.[slug]?.url;
};


const Actions = Object.freeze({
    CREATE_CONTACT: CreateContactAction,
    EDIT_CONTACT: EditContactAction,
})

const MicroFront = ({ slug, path, query, url: urlProp, className, resizable }) => {
    const iframe = useRef(null);
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const locale = useSelector(getLanguage);
    const { enqueueSnackbar } = useSnackbar();
    const origin = useRef('*');
    const [askForConfirmation, setAskForConfirmation] = useState(null);
    const [dialog, setDialog] = useState(null);
    const languages = useSupportedLangs();
    const slugUrl = useMicroFrontUrl(slug);
    const [size, setSize] = useState({});
    const [ready, setReady] = useState(false);
    const user = useSelector(getUser);
    const organization = useSelector(getOrganization);
    const contentDialogMode = useSelector(getContentDialogMode);
    const settings = useSelector(getSettings);
    const isTestFeatureEnabled = useSelector(testFeatureEnabled);
    const navigate = useNavigate();
    const [action, setAction] = useState(null);
    const { getMicroFrontState, updateMicroFrontState } = useMicroFrontContext();
    
    const Action = useMemo(() => Actions[action?.key], [action]);
    
    const settingsHash = useMemo(() => settings.reduce((hash, setting) => {
        hash[setting.settingKey] = setting.settingValue;
        return hash;
    }, { ready }), [settings, ready]);

    const dispatch = useDispatch();

    const completeAction = useCallback(
        (result) => {
            setAction((currentAction) => {
                if (currentAction) {
                    iframe?.current?.contentWindow.postMessage(
                        {
                            __ONLIVE_MSG_KEY: 'RESPONSE',
                            correlationId: currentAction.correlationId,
                            result,
                        },
                        origin.current,
                    );
                }
                return null;
            });
        }, [setAction],
    );
    
    const closeDialog = useCallback(
        (result) =>
            setDialog((currentDialog) => {
                if (currentDialog) {
                    iframe?.current?.contentWindow.postMessage(
                        {
                            __ONLIVE_MSG_KEY: 'RESPONSE',
                            correlationId: currentDialog.correlationId,
                            result,
                        },
                        origin.current,
                    );
                    setTimeout(() => dispatch(closeCustomDialogAction()));
                }
                return null;
            }),
        [dispatch],
    );

    useEffect(() => {
        if (dialog) {
            const { microFront = {}, fullScreen = false, enableAutoClose = fullScreen, backspaceLabel, title } = dialog;
            const { slug, path, query, url } = microFront;
            dispatch(
                openCustomDialog(MicroFront, {
                    contentProperties: {
                        slug,
                        path,
                        query,
                        url,
                        resizable: !fullScreen,
                        className: fullScreen ? flexBox.padding5 : flexBox.margin5,
                    },
                    fullScreen,
                    enableAutoClose,
                    backspaceLabel,
                    onClose: () => closeDialog(null),
                    title,
                    titleClass: fullScreen ? flexBox.paddingLeft5 : undefined,
                }),
            );
        }
        // eslint-disable-next-line
    }, [dialog]);

    useEffect(() => {
        const subscription = fromEvent(window, 'message')
            .pipe(
                filter(
                    (msg) =>
                        msg.source === iframe.current?.contentWindow || msg.data.__ONLIVE_MSG_KEY === 'CLOSE_DIALOG',
                ),
            )
            .subscribe((msg) => {
                const { data } = msg;
                switch (data.__ONLIVE_MSG_KEY) {
                    case 'REGISTER':
                        origin.current = msg.origin;
                        msg.source?.postMessage(
                            {
                                __ONLIVE_MSG_KEY: 'RESPONSE',
                                correlationId: data.correlationId,
                                resizable,
                            },
                            msg.origin,
                        );
                        setReady(true);
                        break;
                    case 'ENQUEUE_SNACKBAR':
                        enqueueSnackbar(data.message, {
                            variant: data.variant,
                        });
                        break;
                    case 'ASK_FOR_CONFIRMATION':
                        setAskForConfirmation(data);
                        break;
                    case 'OPEN_DIALOG':
                        setDialog(data);
                        break;
                    case 'CLOSE_DIALOG':
                        closeDialog(data.result);
                        break;
                    case 'RESIZE':
                        setSize(data.size);
                        break;
                    case 'SET_CONTENT_DIALOG_MODE':
                        dispatch(setContentDialogMode(!!data.active));
                        break;
                    case 'NAVIGATE_TO':
                        dispatch(closeCustomDialogAction());
                        navigate(data.path, data.options);
                        break;
                    case 'EXECUTE_ACTION':
                        setAction(data);
                        break;
                    case 'UPDATE_MICRO_FRONT_STATE':
                        updateMicroFrontState(slug, data.state);
                        break;
                    default:
                        break;
                }
            });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ready) {
            const subscription = getMicroFrontState(slug).subscribe(microFrontState => {
                iframe?.current?.contentWindow.postMessage(
                    {
                        __ONLIVE_MSG_KEY: 'UPDATE_CONTEXT',
                        microFrontState,
                    },
                    origin.current,
                );
            });
            
            return () => subscription.unsubscribe();
        }
    }, [ready, getMicroFrontState, slug]);

    useEffect(() => {
        if (ready) {
            iframe?.current?.contentWindow.postMessage(
                {
                    __ONLIVE_MSG_KEY: 'UPDATE_CONTEXT',
                    locale,
                    languages: languages.map(({ languageCode: key, language: name, unsupportedTranscription }) => ({
                        key,
                        name,
                        unsupportedTranscription,
                    })),
                },
                origin.current,
            );
        }
    }, [ready, locale, languages]);

    useEffect(() => {
        if (ready && user) {
            iframe?.current?.contentWindow.postMessage(
                {
                    __ONLIVE_MSG_KEY: 'UPDATE_CONTEXT',
                    user: {
                        ...user,
                        id: user.userId,
                        fullName: user.name,
                    },
                },
                origin.current,
            );
        }
    }, [ready, user]);

    useEffect(() => {
        if (ready && organization) {
            iframe?.current?.contentWindow.postMessage(
                {
                    __ONLIVE_MSG_KEY: 'UPDATE_CONTEXT',
                    organization,
                },
                origin.current,
            );
        }
    }, [ready, organization]);

    useEffect(() => {
        if (ready) {
            iframe?.current?.contentWindow.postMessage(
                {
                    __ONLIVE_MSG_KEY: 'UPDATE_CONTEXT',
                    state: {
                        contentDialogMode,
                        testFeatureEnabled: isTestFeatureEnabled
                    },
                },
                origin.current,
            );
        }
    }, [ready, contentDialogMode, isTestFeatureEnabled]);

    useEffect(() => {
        if (ready) {
            iframe?.current?.contentWindow.postMessage(
                {
                    __ONLIVE_MSG_KEY: 'UPDATE_CONTEXT',
                    settings: settingsHash,
                },
                origin.current,
            );
        }
    }, [ready, settingsHash]);

    const handleConfirmation = useCallback((correlationId, confirmation) => {
        iframe?.current?.contentWindow.postMessage(
            {
                __ONLIVE_MSG_KEY: 'RESPONSE',
                correlationId,
                confirmation,
            },
            origin.current,
        );
        setAskForConfirmation(null);
    }, []);

    const url = useMemo(() => {
        if (urlProp) {
            return urlProp;
        }
        if (slugUrl) {
            const url = new URL(slugUrl);
            if (path) {
                url.pathname = `${url.pathname}/${path.startsWith('/') ? path.substring(1) : path}`;
            }
            if (query) {
                Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value));
            }
            return url.toString();
        }
        return urlProp || '';
    }, [slugUrl, path, query, urlProp]);

    const iFrame = url ? (
        <>
            <iframe
                title="micro-front"
                ref={iframe}
                src={url}
                allow="clipboard-read; clipboard-write; fullscreen"
                className={cx(
                    flexBox.w100,
                    flexBox.h100,
                    flexBox.borderNone,
                    flexBox.borderBox,
                    className,
                    !ready && flexBox.displayNone,
                )}
                style={resizable ? { width: size.width, height: size.height } : undefined}
            />
            {ready ? null : (
                <div
                    className={cx(
                        flexBox.flex,
                        flexBox.w100,
                        flexBox.h100,
                        flexBox.borderBox,
                        flexBox.alignItemsCenter,
                        flexBox.justifyContentCenter,
                        flexBox.minVW25,
                        flexBox.minVH25,
                    )}
                >
                    <CircularProgress />
                </div>
            )}
        </>
    ) : (
        <div
            className={cx(
                flexBox.flex,
                flexBox.w100,
                flexBox.h100,
                flexBox.borderBox,
                flexBox.alignItemsCenter,
                flexBox.justifyContentCenter,
                className,
            )}
        >
            <Alert severity="error">
                {slug
                    ? `Could not found URL for micro-front ${slug}`
                    : 'At least one of micro-front properties slug or URL is required, in this case both are missing.'}
            </Alert>
        </div>
    );

    return (
        <>
            {iFrame}
            {askForConfirmation ? (
                <YesNoDialog
                    openDialog={true}
                    title={askForConfirmation.title}
                    messageToConfirm={askForConfirmation.message}
                    delayToEnable={askForConfirmation.delay}
                    yesButtonText={askForConfirmation.confirmationLabel}
                    noButtonText={askForConfirmation.cancelLabel}
                    yesButtonCallback={() => handleConfirmation(askForConfirmation.correlationId, true)}
                    noButtonCallback={() => handleConfirmation(askForConfirmation.correlationId, false)}
                />
            ) : null}
            {Action ? <Action onComplete={completeAction} payload={action.payload}/> : null}
        </>
    );
};

export default MicroFront;
