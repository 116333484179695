import { capitalize } from 'lodash';

export const findSettingTranslation = (labelObject, language) => {
    try {
        if (typeof labelObject === 'object' && language) {
            for (const langKey of Object.keys(labelObject)) {
                // This match 'pt' for 'pt-br' and 'pt' for 'pt'.
                if (language.includes(langKey)) {
                    return labelObject[langKey];
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
    return null;
};

export const getSettingTranslation = (settingValue, setting, language, translationKey = 'label') => {
    try {
        const isValidConfig =
            setting &&
            settingValue &&
            typeof setting === 'object' &&
            setting.hasOwnProperty(settingValue) &&
            setting[settingValue].hasOwnProperty(translationKey) &&
            typeof setting[settingValue][translationKey] === 'object';

        if (isValidConfig) {
            return (
                findSettingTranslation(setting[settingValue][translationKey], language) ||
                findSettingTranslation(setting[settingValue][translationKey], 'en')
            );
        }
    } catch (error) {
        console.error(error);
    }
    // Return setting value as given.
    return settingValue;
};

/**
 * Given a string setting value like 'one_to_many', returns 'One To Many'.
 * Otherwise it returns the value as given.
 *
 * @param {*} settingValue Some string setting value like this one: 'one_to_many'
 */
export const capSplitSettingStrValue = (settingValue) => {
    try {
        if (settingValue && typeof settingValue === 'string') {
            return settingValue
                .split('_')
                .map((w) => capitalize(w))
                .join(' ');
        }
    } catch (error) {
        console.error(error);
    }
    return settingValue;
};

export const translateSettingOrTransform = (
    settingValue,
    setting,
    language,
    translationKey = 'label',
    textTransformFn = capSplitSettingStrValue,
) => {
    if (getSettingTranslation(settingValue, setting, language, translationKey) !== settingValue) {
        return getSettingTranslation(settingValue, setting, language, translationKey);
    } else {
        return typeof textTransformFn === 'function'
            ? textTransformFn(settingValue)
            : capSplitSettingStrValue(settingValue);
    }
};
