import { Add } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment-timezone';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UUID } from 'uuidjs';

import CustomTextValidator from 'common/components/CustomTextValidators';
import { isAfter } from 'date-fns';
import { useFormFields } from 'libs/hooksLib';
import OHoursRange from './OHoursRange';

const ODateOverride = (props) => {
    const { classes, openDialog, onCloseDialog, onAddDateOverride, title, simple, timeZone } = props;

    const { t } = useTranslation();
    const [hours, setHours] = useState([]);
    const [fields, handleFieldChange] = useFormFields({
        firstDate: moment().format('YYYY-MM-DD'),
        secondDate: moment().format('YYYY-MM-DD'),
    });

    const handleDeleteHourRange = (hourId) => {
        let tempHours = hours;
        const indexHour = tempHours.findIndex((h) => h.id === hourId);
        tempHours.splice(indexHour, 1);
        setHours([...tempHours]);
    };

    const handleAddHourRange = () => {
        setHours([
            ...hours,
            {
                id: UUID.generate(),
                startTime: moment.tz({ hour: 9 }, timeZone).format('HH:mm'),
                endTime: moment.tz({ hour: 18 }, timeZone).format('HH:mm'),
            },
        ]);
    };

    const handleHourRangeChange = (hoursRange) => {
        let tempHours = hours;
        const indexHour = tempHours.findIndex((h) => h.id === hoursRange.id);
        tempHours.splice(indexHour, 1, hoursRange);
        setHours([...tempHours]);
    };

    const handleOk = () => {
        const value = {};
        value['id'] = UUID.generate();

        if (simple) {
            value['startDate'] = fields.firstDate;
            value['endDate'] = fields.secondDate;
        } else {
            value['date'] = fields.firstDate;
            value['hours'] = [...hours];
        }
        onAddDateOverride(value, simple);
    };

    useEffect(() => {
        setHours([
            {
                id: UUID.generate(),
                startTime: moment.tz({ hour: 9 }, timeZone).format('HH:mm'),
                endTime: moment.tz({ hour: 18 }, timeZone).format('HH:mm'),
            },
        ]);
    }, [openDialog, timeZone]);

    useEffect(() => {
        if (simple) {
            if (fields.firstDate && fields.secondDate) {
                if (isAfter(new Date(fields.firstDate), new Date(fields.secondDate)))
                    handleFieldChange({
                        target: {
                            name: 'secondDate',
                            value: fields.firstDate,
                        },
                    });
            }
        }
    }, [fields, handleFieldChange, simple]);

    return (
        <Dialog
            open={openDialog}
            onClose={onCloseDialog}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="date-override-dialog"
            className={classes.customDialogWidth}
        >
            <DialogTitle id="date-override-dialog-title">{title}</DialogTitle>
            <DialogContent className={classes.dateOverrideDialogContent}>
                <Grid container spacing={1}>
                    {Boolean(simple) && (
                        <Grid item md={2} display={'flex'} alignItems={'center'}>
                            <span>{t('common.from')}</span>
                        </Grid>
                    )}
                    <Grid item md={Boolean(!simple) ? 3 : 4}>
                        <CustomTextValidator
                            name="firstDate"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            value={fields.firstDate}
                            onChange={handleFieldChange}
                            type="date"
                            InputProps={{
                                inputProps: {
                                    shrink: true,
                                    min: moment().format('YYYY-MM-DD'),
                                },
                            }}
                            validators={['required']}
                            errorMessages={[t('license.licenseForm.emailRequired')]}
                        />
                    </Grid>
                    {Boolean(!simple) && (
                        <Fragment>
                            <Grid item md={8}>
                                <div className={classes.dateOverrideHourContainer}>
                                    {hours.map((h) => {
                                        return (
                                            <OHoursRange
                                                id={h.id}
                                                key={h.id}
                                                initialHourBegin={h.startTime}
                                                initialHourEnd={h.endTime}
                                                onDelete={() => handleDeleteHourRange(h.id)}
                                                onChange={handleHourRangeChange}
                                                timeZone={timeZone}
                                            />
                                        );
                                    })}
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <IconButton
                                    aria-label="add"
                                    className={classes.dateOverridePlusBtn}
                                    onClick={handleAddHourRange}
                                    size="large"
                                >
                                    <Add fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Fragment>
                    )}
                    {Boolean(simple) && (
                        <Fragment>
                            <Grid item md={2} display={'flex'} alignItems={'center'}>
                                <span>{t('common.to')}</span>
                            </Grid>
                            <Grid item md={4}>
                                <CustomTextValidator
                                    name="secondDate"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    value={fields.secondDate}
                                    onChange={handleFieldChange}
                                    type="date"
                                    InputProps={{
                                        inputProps: {
                                            shrink: true,
                                            min: moment().format('YYYY-MM-DD'),
                                        },
                                    }}
                                    validators={['required']}
                                    errorMessages={[t('license.licenseForm.emailRequired')]}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </DialogContent>
            {Boolean(simple) && (
                <Typography display="block" gutterBottom style={{ margin: 0, padding: '0 24px' }}>
                    {t('calendar.rangeNote')}
                </Typography>
            )}
            <DialogActions>
                <Button autoFocus onClick={onCloseDialog} color="primary">
                    {t('common.term.Cancel')}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t('common.term.OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ODateOverride;
