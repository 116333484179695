import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomTooltip from './CustomTooltip';

const WrapWithTooltip = (props) => {
    const { title, children, wrap, placement } = props;
    const { t } = useTranslation();
    return wrap ? (
        <CustomTooltip arrow title={t(title)} placement={placement}>
            <span>{children}</span>
        </CustomTooltip>
    ) : (
        <>{children}</>
    );
};

WrapWithTooltip.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    wrap: PropTypes.bool,
    placement: PropTypes.string,
};

export default WrapWithTooltip;
