import { createSlice } from '@reduxjs/toolkit';
import { calendarApiV3 as api } from 'api';

import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const myAvailabilitySlice = createSlice({
    name: 'myAvailability',
    initialState: {
        pending: false,
        savePending: false,
        availabilities: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addAvailability: (state, action) => {
            state.savePending = false;
            state.availabilities = [action.payload, ...state.availabilities];
        },
        editAvailability: (state, action) => {
            state.savePending = false;
            const index = state.availabilities.findIndex((availability) => availability.id === action.payload.id);
            if (index !== -1) {
                state.availabilities = [
                    ...state.availabilities.slice(0, index),
                    { ...state.availabilities[index], ...action.payload },
                    ...state.availabilities.slice(index + 1),
                ];
            }
        },
        deleteAvailability: (state, action) => {
            state.deletePending = false;
            const index = state.availabilities.findIndex((availability) => availability.id === action.payload.id);
            if (index !== -1) {
                state.availabilities = [
                    ...state.availabilities.slice(0, index),
                    ...state.availabilities.slice(index + 1),
                ];
            }
        },
        setAvailabilities: (state, action) => {
            state.pending = false;
            state.availabilities = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setAvailabilities,
    addAvailability,
    editAvailability,
    deleteAvailability,
    stopDelete,
    setError,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
} = myAvailabilitySlice.actions;

export const getAvailabilities = (state) => state.myAvailability.availabilities;
export const getAvailabilitiesPending = (state) => state.myAvailability.pending;
export const getSAvailabilitiesSavePending = (state) => state.myAvailability.savePending;
export const getDeletePending = (state) => state.myAvailability.deletePending;
export const getDeleteId = (state) => state.myAvailability.deleteId;
export const getServicesError = (state) => state.myAvailability.error;

export const fetchAvailabilitiesByCalendarAction =
    (calendarId, { queryParams, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(start());
                api.get(`calendars/${calendarId}/weekly-schedules`)
                    .then((response) => {
                        dispatch(setAvailabilities(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stop());
                return console.error(e.message);
            }
        };

export const fetchAvailabilitiesAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                let query = transformFiltersToQuery(queryParams);
                dispatch(start());
                api.get(`weekly-schedules` + query)
                    .then((response) => {
                        dispatch(setAvailabilities(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stop());
                return console.error(e.message);
            }
        };

export const addAvailabilityAction =
    ({ calendarId, dto, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.post(`calendars/${calendarId}/weekly-schedules`, dto)
                    .then((response) => {
                        dispatch(addAvailability(response?.data));
                        onSuccess?.(response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setSaveError(error));
                        onFailure?.(error);
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error(e.message);
            }
        };

export const editAvailabilityAction =
    ({ id, dto, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.put(`weekly-schedules/` + id, dto)
                    .then((response) => {
                        dispatch(editAvailability(response.data));
                        onSuccess?.(response.data);
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setSaveError(error));
                        onFailure?.(error);
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error('error', e);
            }
        };

export const deleteAvailabilityAction = (id, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startDelete(id));
        api.delete('weekly-schedules/' + id)
            .then((response) => {
                dispatch(deleteAvailability({ id }));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        dispatch(stopDelete());
        return console.error('error', e);
    }
};

export default myAvailabilitySlice.reducer;
