const DEFAULT_LAZY_LOAD_ATTEMPTS = 5;
const ATTEMPTS_DELAYS = [500, 1000, 1500, 2000, 3000];

/**
 * @param {() => Promise<any> } loader
 * @param {number} attempts
 * @returns {Promise<unknown>}
 */
export default function resolveWithRetry(loader, attempts) {
    return _resolveWithRetry(loader, Math.max(1, attempts || DEFAULT_LAZY_LOAD_ATTEMPTS));
}

function _resolveWithRetry(loader, maxAttempts, attemptsIndex = 1) {
    return new Promise((resolve, reject) => {
        loader()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (attemptsIndex < maxAttempts) {
                        _resolveWithRetry(loader, attemptsIndex + 1, maxAttempts).then(resolve, reject);
                    } else {
                        // The page can't be continue loading
                        // TODO: can be send a custom error for show dialog to reload manually
                        window.location.reload();
                        // reject(error);
                    }
                }, ATTEMPTS_DELAYS[attemptsIndex - 1] || ATTEMPTS_DELAYS[ATTEMPTS_DELAYS.length - 1]);
            });
    });
}
