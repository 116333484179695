import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const requestSlice = createSlice({
    name: 'requests',
    initialState: {
        pending: false,
        requests: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setRequests: (state, action) => {
            state.pending = false;
            state.requests = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.requests = [];
            state.error = action.payload;
        },
    },
});

export const { start, stop, setRequests, setError } = requestSlice.actions;

export const getRequests = (state) => state.requests.requests;
export const getRequestsPending = (state) => state.requests.pending;
export const getRequestsError = (state) => state.requests.error;

export const fetchRequestsAction = (queryParams, callback) => async (dispatch) => {
    try {
        dispatch(start());
        let query = transformFiltersToQuery(queryParams);
        api.get('license-requests' + query)
            .then((response) => {
                dispatch(setRequests(response.data));
                if (callback) {
                    callback(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default requestSlice.reducer;
