import { FormHelperText, FormLabel, Stack } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { findCountryByCode, findCountryCodeByDialCode, findDialCodeByCountryCode } from 'common/utils/countryList';
import { getCountryDialCode } from 'common/utils/utils';
import { useFormFields } from 'libs/hooksLib';
import { isString } from 'lodash';
import { forwardRef, useCallback, useEffect } from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';
import PhoneCountrySelect from './PhoneCountrySelect';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            input: {
                padding: '2px 5px',
                border: '1px solid #dedede',
                borderRadius: '24px',
                width: '100%',
                height: '30px',
                [theme.breakpoints.up('xl')]: {
                    fontSize: '14px',
                },
            },

            '& .MuiFormHelperText-root': {
                [theme.breakpoints.up('xl')]: {
                    fontSize: '14px',
                },
            },
        },
        containerInput: {
            margin: '5px 0',
            paddingBottom: '8px',
        },
        label: {
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 400,
            margin: '5px',
            alignSelf: 'flex-start',
        },
        phoneInputContainer: {
            display: 'flex',
        },

        phoneInputError: {
            border: `1px solid ${theme.palette.error.main} !important`,
        },
        phoneCode: {
            display: 'flex',
            alignItems: 'center',
            padding: '0px 8px',
            borderBottomLeftRadius: '24px',
            borderTopLeftRadius: '24px',
            fontSize: '13px',
            fontWeight: '500',
            border: '1px solid #dedede',
        },
        phoneCodeError: {
            border: `1px solid ${theme.palette.error.main} !important`,
        },
    };
});

const PhoneInputForm = forwardRef(function PhoneInputRender(props, ref) {
    const {
        fields,
        handleFieldChange,
        label,
        hideLabel,
        required,
        error,
        hideCountryCode = false,
        name: inputPhoneName,
    } = props;
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    const filterInput = (input) => {
        let filteredInput = input.trim().substring(0, 15);
        filteredInput = filteredInput.replace(/\D/g, '');

        return filteredInput;
    };

    return (
        <Stack direction="column" className={cx(classes.containerInput, flexBox.w100)} aria-required={required}>
            {!hideLabel && <FormLabel>{label}</FormLabel>}
            <Stack direction="column" className={classes.root}>
                <Stack direction={hideCountryCode ? 'column' : 'row'}>
                    {!hideCountryCode ? (
                        <PhoneCountrySelect
                            value={fields.code}
                            name={'code'}
                            onChange={(event) => handleFieldChange(event)}
                            error={error}
                        />
                    ) : null}
                    <input
                        className={cx(classes.phoneInput, ...(error ? [classes.phoneInputError] : []))}
                        value={fields[inputPhoneName]}
                        name={inputPhoneName}
                        onChange={(event) =>
                            handleFieldChange({
                                target: { value: filterInput(event.target.value), name: inputPhoneName },
                            })
                        }
                        type="tel"
                        ref={ref}
                    ></input>
                </Stack>
                {error}
            </Stack>
        </Stack>
    );
});

class PhoneInputComponent extends ValidatorComponent {
    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, ...rest } = this.props;

        return (
            <PhoneInputForm
                {...rest}
                ref={(r) => {
                    this.input = r;
                }}
                error={this.errorElement()}
            />
        );
    }

    errorElement() {
        return !this.state.isValid ? (
            <FormHelperText error variant="outlined">
                {this.getErrorMessage()}
            </FormHelperText>
        ) : null;
    }
}

const PhoneInput = (props) => {
    const { value, hideCountryCode, defaultCountryCode, onChange, name: inputPhoneName = 'phone', ...rest } = props;

    const [fields, handleFieldChange, resetForm] = useFormFields({
        code: '',
        [inputPhoneName]: '',
    });

    const handleOnChange = useCallback(
        (newValue) => {
            // Prevent sending only the country code when the phone input is cleared
            const isOnlyDialCode = newValue.trim() === findCountryByCode(fields.code)[0]?.dial_code;

            if (value !== newValue && !isOnlyDialCode) {
                onChange({
                    target: {
                        name: inputPhoneName,
                        value: newValue,
                    },
                });
            }
        },
        [fields.code, value, onChange, inputPhoneName],
    );

    useEffect(() => {
        if (fields[inputPhoneName]) {
            const formattedPhone = hideCountryCode
                ? fields[inputPhoneName]
                : `${findCountryByCode(fields.code)[0]?.dial_code} ${fields[inputPhoneName]}`.trim();

            handleOnChange(formattedPhone);
        } else {
            handleOnChange(''); // Send an empty string when the input is cleared
        }
    }, [fields, handleOnChange, hideCountryCode, inputPhoneName]);

    useEffect(() => {
        const validValue = /^(\+\d+)? ?\d+$/;
        if (!fields.code) {
            let code = 'ES';
            let phone = '';
            if (isString(value) && validValue.test(value)) {
                const parts = value.split(' ');
                const countryDialCode =
                    parts?.length > 1 ? parts[0] : getCountryDialCode(value, defaultCountryCode || 'ES')?.dialCode;
                code = findCountryCodeByDialCode(countryDialCode);
                phone =
                    parts?.length > 1
                        ? parts[1]
                        : value?.startsWith(countryDialCode)
                        ? value.substring(countryDialCode.length)
                        : value;
            } else {
                const countryDialCode = findDialCodeByCountryCode(defaultCountryCode || 'ES');
                code = findCountryCodeByDialCode(countryDialCode);
                phone = '';
            }
            resetForm({ code, [inputPhoneName]: phone });
        }
    }, [value, fields, resetForm, defaultCountryCode, inputPhoneName]);

    return (
        <PhoneInputComponent
            fields={fields}
            handleFieldChange={handleFieldChange}
            name={inputPhoneName}
            value={fields[inputPhoneName]}
            hideCountryCode={hideCountryCode}
            {...rest}
        />
    );
};

export default PhoneInput;
