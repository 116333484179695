import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const indexerApiCredentialSlice = createSlice({
    name: 'indexerApiCredential',
    initialState: {
        pending: false,
        indexerAccessTokens: [],
        error: null,
    },
    reducers: {
        resetIndexerAccessTokens: (state, action) => {
            state.indexerAccessTokens = [];
        },
        addIndexerAccessToken: (state, action) => {
            state.pending = false;
            state.indexerAccessTokens = [...state.indexerAccessTokens, action.payload];
        },
        deleteIndexerAccessToken: (state, action) => {
            state.pending = false;
            const index = state.indexerAccessTokens.findIndex(
                (indexerAccessToken) => indexerAccessToken.id === action.payload.id,
            );
            if (index !== -1) {
                state.indexerAccessTokens = [
                    ...state.indexerAccessTokens.slice(0, index),
                    ...state.indexerAccessTokens.slice(index + 1),
                ];
            }
        },
        updateIndexerAccessToken: (state, action) => {
            state.pending = false;
            const index = state.indexerAccessTokens.findIndex(
                (indexerAccessToken) => indexerAccessToken.id === action.payload.id,
            );
            if (index !== -1) {
                state.indexerAccessTokens = [
                    ...state.indexerAccessTokens.slice(0, index),
                    action.payload,
                    ...state.indexerAccessTokens.slice(index + 1),
                ];
            }
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setIndexerAccessTokens: (state, action) => {
            state.pending = false;
            state.indexerAccessTokens = action.payload;
        },
    },
});

export const {
    resetIndexerAccessTokens,
    addIndexerAccessToken,
    deleteIndexerAccessToken,
    updateIndexerAccessToken,
    setError,
    start,
    stop,
    setIndexerAccessTokens,
} = indexerApiCredentialSlice.actions;

export const getIndexerAccessTokens = (state) => state.indexerApiCredential.indexerAccessTokens;
export const getIndexerAccessTokenSavePending = (state) => state.indexerApiCredential.savePending;
export const getIndexerAccessTokenPending = (state) => state.indexerApiCredential.pending;
export const getIndexerAccessTokenError = (state) => state.indexerApiCredential.error;

export const fetchIndexerAccessTokensAction = () => async (dispatch) => {
    try {
        dispatch(start());
        api.get('indexer-access-token')
            .then((response) => {
                dispatch(setIndexerAccessTokens(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const addIndexerAccessTokenAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.post('indexer-access-token', data)
                .then((response) => {
                    dispatch(addIndexerAccessToken(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const deleteIndexerAccessTokenAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.delete('indexer-access-token/' + id)
                .then((response) => {
                    dispatch(deleteIndexerAccessToken(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export default indexerApiCredentialSlice.reducer;
