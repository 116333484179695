import { Stack, Typography } from '@mui/material';
import { accountsApi } from 'api';
import useFlexBoxStyles from 'common/flexBox';
import LayoutSectionHeader from 'common/layouts/LayoutSectionHeader';
import LayoutView from 'common/layouts/LayoutView';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { from } from 'rxjs';
import 'suneditor/dist/css/suneditor.min.css';
import useStylesSettings from '../settings/styles/useStylesSettings.styles';
import Authenticator from './authenticator/Authenticator';
import EmailVerification from './emailVerification/EmailVerification';
import VerificationCodes from './verificationCodes/VerificationCodes';

const Security = () => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes } = useStylesSettings();
    const [twoFactor, setTwoFactor] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const getData = useCallback(() => {
        const subscription = from(accountsApi.get('/two-factor-auth')).subscribe({
            next: ({ data }) => {
                setTwoFactor(data);
            },
            error: (err) => {
                console.error(`Error fetching`);
            },
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    const postMethod = ({ method, callback }) => {
        accountsApi
            .post('/two-factor-auth', { method })
            .then(({ data }) => {
                callback?.(data);
                getData();
                enqueueSnackbar(t('profile.settings.settings.saveSuccess'), { variant: 'success' });
            })
            .catch((error) => {
                console.debug(error);
                enqueueSnackbar(t('app.common.error'), { variant: 'error' });
            });
    };

    const confirmMethod = ({ method, code, callback, onError }) => {
        accountsApi
            .put('/two-factor-auth', { method, code })
            .then(() => {
                callback?.();
                getData();
                enqueueSnackbar(t('profile.security.confirmed'), { variant: 'success' });
            })
            .catch((error) => {
                onError?.();
                enqueueSnackbar(t('security.authenticator.codeError'), { variant: 'error' });
            });
    };

    const onDelete = ({ method }) => {
        accountsApi
            .delete(`/two-factor-auth/${method}`)
            .then(() => {
                getData();
                enqueueSnackbar(t('profile.settings.settings.saveSuccess'), { variant: 'success' });
            })
            .catch((error) => {
                console.debug(error);
                enqueueSnackbar(t('app.common.error'), { variant: 'error' });
            });
    };
    return (
        <LayoutView>
            <LayoutSectionHeader>
                <Stack
                    direction="column"
                    className={cx(flexBox.w100, flexBox.h100, flexBox.overflowXHidden, flexBox.scrollbarNone)}
                    spacing={3}
                >
                    <Stack>
                        <Typography variant="h6">{t('profile.security.title')}</Typography>
                        <Typography variant="subtitle1" fontWeight={400}>
                            {t('profile.security.subtitle')}
                        </Typography>
                    </Stack>

                    <Stack direction="column" className={classes.mW80}>
                        <Authenticator
                            confirmMethod={confirmMethod}
                            postMethod={postMethod}
                            onDelete={onDelete}
                            factor={twoFactor.find((item) => item.type === 'authenticator')}
                        />
                        <EmailVerification
                            postMethod={postMethod}
                            onDelete={onDelete}
                            factor={twoFactor.find((item) => item.type === 'email')}
                        />
                        <VerificationCodes
                            confirmMethod={confirmMethod}
                            postMethod={postMethod}
                            onDelete={onDelete}
                            factor={twoFactor.find((item) => item.type === 'alternative_codes')}
                        />
                    </Stack>
                </Stack>
            </LayoutSectionHeader>
        </LayoutView>
    );
};

export default Security;
