import { makeStyles } from 'tss-react/mui';

const useColorsStyles = makeStyles()((theme) => ({
    bgInherit: {
        background: 'inherit',
    },
    bgPrimary: {
        background: theme.palette.primary.main,
    },
    bgPrimaryLight: {
        background: theme.palette.primary.light,
    },
    bgSecondaryMain: {
        background: theme.palette.secondary.main,
    },
    bgSecondaryLight: {
        background: theme.palette.secondary.light,
    },
    bgSecondaryDark: {
        background: theme.palette.secondary.dark,
    },
    bgPaper: {
        background: theme.palette.background.paper,
    },
    bgWhite: {
        background: theme.palette.common.white,
    },
    bgBlack: {
        background: theme.palette.common.black,
    },
    bgRed: {
        background: theme.palette.common.red,
    },
    bgGreen: {
        background: theme.palette.common.green,
    },
    bgSoftBlack: {
        background: theme.palette.common.softBlack,
    },
    bgLightGreen: {
        background: theme.palette.common.lightGreen,
    },
    bgGray: {
        background: theme.palette.common.gray,
    },
    bgMediumGray: {
        background: theme.palette.common.mediumGray,
    },
    bgLightGray: {
        background: theme.palette.common.lightGray,
    },
    bgSlightlyGray: {
        background: theme.palette.common.slightlyGray,
    },
    bgLime: {
        background: theme.palette.common.lime,
    },
    bgLightBlue: {
        background: theme.palette.common.lightBlue,
    },
    textLime: {
        color: theme.palette.common.lime,
    },
    textInitial: {
        color: 'initial',
    },
    textInherit: {
        color: 'inherit',
    },
    bgActionDisabled: {
        background: theme.palette.action.disabled,
    },
    textSecondaryMain: {
        color: theme.palette.secondary.main,
    },
    textPrimaryLight: {
        color: theme.palette.primary.light,
    },
    textActionDisabledContrast: {
        color: theme.palette.getContrastText(theme.palette.action.disabled),
    },
    textPrimaryMainContrast: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    textPrimaryLightContrast: {
        color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    textSecondaryMainContrast: {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
    textSecondaryLightContrast: {
        color: theme.palette.getContrastText(theme.palette.secondary.light),
    },
    bgSecondaryMainContrast: {
        background: theme.palette.getContrastText(theme.palette.secondary.main),
    },
    bgPrimaryMainContrast: {
        background: theme.palette.getContrastText(theme.palette.primary.main),
    },
    textPaperContrast: {
        color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    textRed: {
        color: theme.palette.common.red,
    },
    textGreen: {
        color: theme.palette.common.green,
    },
    textBlue: {
        color: '#06B3E6',
    },
    textWhite: {
        color: theme.palette.common.white,
    },
    textGray: {
        color: theme.palette.common.gray,
    },
    textLightGreen: {
        color: theme.palette.common.lightGreen,
    },
    textError: {
        color: theme.palette.error.main,
    },
    textSuccess: {
        color: theme.palette.success.main + ' !important',
    },
    textLink: {
        color: theme.palette.common.link,
    },
    textLinkDark: {
        color: theme.palette.common.linkDark,
    },
    textPrimaryMain: {
        color: theme.palette.primary.main,
    },
    bgTranslucent: {
        background: theme.palette.common.translucent,
    },
    bgTranslucentDark: {
        background: theme.palette.common.translucentDark,
    },
    bgTransparent: {
        background: 'transparent',
    },
    textCurrent: {
        color: 'currentColor',
    },
    fillCurrentColor: {
        fill: 'currentColor',
    },
    textDisabled: {
        color: theme.palette.common.disabled,
    },
    textWarning: {
        color: theme.palette.warning.main + ' !important',
    },
    textBlack: {
        color: theme.palette.common.black,
    },
    hoverBgLightGray: {
        '&:hover': {
            background: theme.palette.common.lightGray,
        },
    },
    //
    bgNeutral100: {
        background: theme.palette.common.neutral100,
    },
}));

export default useColorsStyles;
