import { withIcon } from './whitIcon';

const ILiveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.238 19.683">
        <path
            id="videocam_FILL0_wght300_GRAD0_opsz20"
            d="M5.975,24.1a2.261,2.261,0,0,1-1.652-.7,2.26,2.26,0,0,1-.7-1.653V6.767a2.243,2.243,0,0,1,.7-1.671,2.29,2.29,0,0,1,1.652-.679H20.923a2.334,2.334,0,0,1,1.689.679,2.243,2.243,0,0,1,.7,1.671v6.279l4.553-4.553V20.024L23.31,15.471V21.75a2.26,2.26,0,0,1-.7,1.653,2.3,2.3,0,0,1-1.689.7Zm0-1.909H20.923a.469.469,0,0,0,.347-.129.425.425,0,0,0,.129-.312V6.767a.425.425,0,0,0-.129-.312.469.469,0,0,0-.347-.129H5.975a.439.439,0,0,0-.441.441V21.75a.439.439,0,0,0,.441.441Zm-.441,0v0Z"
            transform="translate(-3.625 -4.417)"
        />
    </svg>
);

export default withIcon(ILiveIcon);
