import axios from 'axios';
import config from 'config';

const http = axios.create({
    baseURL: config.instantSearch.baseURL,
});

class SearchApi {
    search(index, queryParams) {
        return http.get(`search`, {
            params: {
                index,
                ...queryParams,
            },
        });
    }

    groups(searchEngine, queryParams) {
        return http.get(`groups`, {
            params: {
                searchEngine,
                ...queryParams,
            },
        });
    }
}

const searchApi = new SearchApi();

export default searchApi;
