import 'firebase/compat/database';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Button } from '@mui/material';
import { NotificationService } from '@onlive.site/front-core';
import api from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import AlertDialog from 'common/components/AlertDialog';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { getColor, isValidHttpUrl } from 'common/utils/utils';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getAgentAvailable, getPool, getSessionRequest, setPool } from 'redux/slices/realtimeSlice';
import { getCmsFingerPrint, getCurrentOne2OneEvent } from 'redux/slices/userSlice';

import { getFlagByCountryCode } from 'common/utils/countryList';
import PhoneAnimated from './phoneAnimated';
import useStylesPoolManager from './styles/poolManager.styles';

const PoolManager = (props) => {
    const user = useSelector(getUser);
    const { ...agent } = { ...user, id: user.userId, role: 'personal_shopper' };
    const pool = useSelector(getPool);
    const { classes, cx } = useStylesPoolManager();
    const { realtimePools } = props;
    const [poolSubscribed, setPoolSubscribed] = useState(false);
    const [enableAnimation, setEnableAnimation] = useState(false);
    const [poolOpen, setPoolOpen] = useState(false);
    const cmsFingerPrint = useSelector(getCmsFingerPrint);
    const organization = useSelector(getOrganization);
    const dispatch = useDispatch();
    const session = useSelector(getSessionRequest);
    const isPoolOpened = useMemo(() => pool.length > 0 && poolOpen && !session, [pool.length, poolOpen, session]);
    const available = useSelector(getAgentAvailable);
    const currentEventOne2One = useSelector(getCurrentOne2OneEvent);
    const [showWarning, setShowWarning] = useState(false);
    const icallNotifications = useSettingValue(SettingsKey.ICALL_NOTIFICATIONS);
    const icallOptions = useSettingValue(SettingsKey.ICALL_OPTIONS);
    const enableBrowserSoundNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ORGANIZATION_INCOMING_CALL]?.[
            SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION
        ];
    }, [icallNotifications]);
    const enableSystemTrayNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ORGANIZATION_INCOMING_CALL]?.[
            SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION
        ];
    }, [icallNotifications]);
    const enableBrowserPushNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ORGANIZATION_INCOMING_CALL]?.[
            SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION
        ];
    }, [icallNotifications]);
    const ringAudio = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dataSound = useCallback(() => {
        const url =
            icallNotifications?.[SettingsKey.DIRECT_INCOMING_CALL]?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableBrowserSoundNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_WIDGET_ASSETS_URL}/call_phone_ring_tone.mp3`;
    }, [icallNotifications, enableBrowserSoundNotification]);

    useEffect(() => {
        ringAudio.current = NotificationService.notify('sound_notification', dataSound());
    }, [dataSound]);

    const notify = useCallback(() => {
        const title = `${t('incomingCall')}`;
        const icon = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/icon-128.png`;

        if (enableSystemTrayNotification) {
            const action = (key) => (
                <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={() => closeSnackbar(key)}
                    style={{ minWidth: '32px' }}
                >
                    <CloseIcon />
                </Button>
            );
            NotificationService.notify('vms_notification', { title, action }, (data) => {
                enqueueSnackbar(data.title, { variant: 'info', persist: true, action: data.action });
            });
        }

        if (enableBrowserPushNotification) {
            const body = '';
            if (!('Notification' in window)) {
                console.log('This browser does not support desktop notification');
            } else {
                if (Notification.permission === 'granted') {
                    const notification = NotificationService.notify('push_notification', { body, title, icon });
                    notification.onclick = () => {
                        window.focus();
                    };
                } else {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            const notification = NotificationService.notify('push_notification', { body, title, icon });
                            notification.onclick = () => {
                                window.focus();
                            };
                        }
                    });
                }
            }
        }
    }, [closeSnackbar, enableBrowserPushNotification, enableSystemTrayNotification, enqueueSnackbar]);

    const acceptSession = async (session, pickUp) => {
        realtimePools
            .setAtendedBy(session.inviteeUserId, agent.id)
            .then(() => {
                const payload = {
                    eventDto: {
                        name: `${t('common.session.request.instant')} ${organization.name}`,
                        description: '',
                        releaseDate: new Date(),
                        assets: [],
                        adminUserId: user?.userId,
                        type: 'one_to_one',
                        instant: true,
                        sessionRequestId: session?.id,
                        organizationId: agent?.organizationId,
                        streamConnectors: [],
                        interactionId: session?.onliveInteractionId,
                        cmsFingerPrint,
                        isTemplate: true,
                        inviteeName: session?.inviteeName,
                        inviteeEmail: session?.inviteeEmail,
                        actors: [
                            {
                                role: 'host',
                                isAdmin: true,
                                email: user?.email,
                                userId: session?.userId,
                            },
                            {
                                role: 'guest',
                                email: session?.userInfo?.email,
                                name: session?.userInfo?.name,
                                userId: session?.inviteeUserId,
                                extraFields: session?.userInfo?.extraFields,
                            },
                        ],
                        callOrigin: session?.callOrigin,
                        extraFields: session?.extraFields,
                    },
                    type: 'pool',
                    requestSession: session,
                };
                window.dispatchEvent(new CustomEvent('finalize-event'));
                api.post(`${process.env.REACT_APP_API_URL}/events/accept-call`, payload)
                    .then((response) => {
                        const event = response?.data;

                        realtimePools.setRoomId(session.inviteeUserId, event.publicId);
                    })
                    .catch((error) => {
                        console.debug('error:', error);
                    });
            })
            .catch((error) => {
                if (pickUp && pool.length > 1) {
                    acceptFirstSession();
                } else {
                    setShowWarning(true);
                }
            });
    };

    const acceptFirstSession = () => {
        if (pool.length > 0) {
            acceptSession(pool[0]);
        } else {
            setShowWarning(true);
        }
    };

    const convertObjectQueueToArrayQueue = (objectQueue) => {
        const arrayQueue = Object.values(objectQueue || {}).sort((a, b) => a.callTime - b.callTime);
        return arrayQueue;
    };

    const playAudioandAnimate = useCallback(() => {
        if (!currentEventOne2One && available) {
            setEnableAnimation(true);
            if (enableBrowserSoundNotification) {
                ringAudio.current.play();
            }
        }
    }, [currentEventOne2One, available, enableBrowserSoundNotification]);

    const startAudioAndAnimation = useCallback(() => {
        playAudioandAnimate();
    }, [playAudioandAnimate]);

    const stopAudioAndAnimation = useCallback(() => {
        setEnableAnimation(false);
        if (enableBrowserSoundNotification) {
            ringAudio.current.pause();
            ringAudio.current.currentTime = 0;
        }
    }, [enableBrowserSoundNotification]);

    //TODO: refact this function. pool dependency infinite problems
    const setNewPool = useCallback(
        (newPool) => {
            let poolsNotCancelled = newPool.filter((session) => !session?.canceledBy);
            if (pool.length === 0 && poolsNotCancelled.length === 1) {
                notify();
            }
            dispatch(setPool(newPool));
        },
        [dispatch, pool.length, notify],
    );

    const collapseClick = () => {
        setPoolOpen(!poolOpen);
    };

    const renderFlag = (countryCode) => {
        const flag = icallOptions?.flagsList?.[countryCode] || getFlagByCountryCode(countryCode);
        return flag;
    };

    useEffect(() => {
        ringAudio.current.loop = true;
    }, []);

    useEffect(() => {
        if (session || currentEventOne2One || !available) {
            setPoolOpen(false);
            stopAudioAndAnimation();
        } else if (pool.length > 0) {
            startAudioAndAnimation();
        } else {
            stopAudioAndAnimation();
        }
    }, [session, pool, currentEventOne2One, available, startAudioAndAnimation, stopAudioAndAnimation]);

    useEffect(() => {
        if (realtimePools && user) {
            if (available && !poolSubscribed) {
                realtimePools.observePool(({ detail }) => {
                    if (detail) {
                        let result = convertObjectQueueToArrayQueue(detail);
                        result = result.sort((a, b) => a.callTime - b.callTime);
                        result = result.filter((session) => !session?.attendedBy);
                        setNewPool(result);
                    } else {
                        setNewPool([]);
                        setPoolOpen(false);
                    }
                });
                setPoolSubscribed(true);
            } else if (!available && poolSubscribed) {
                realtimePools.stopObservePool();
                setNewPool([]);
                setPoolSubscribed(false);
            }
        }
    }, [realtimePools, user, available, poolSubscribed, setNewPool]);

    const getOriginOfSession = (session) => {
        return session?.callOrigin && !['widget', 'direct'].includes(session?.callOrigin)
            ? ` ${session?.callOrigin}`
            : '';
    };

    if (pool.length > 0)
        return (
            <>
                <div className={cx(classes.poolManager, isPoolOpened && classes.poolManagerOpen)}>
                    <div className={classes.poolContainer}>
                        <div className={classes.poolManagerHeader}>
                            <div className={classes.poolHeaderTitle}>
                                <p>INCOMING CALLS</p>
                                <Button onClick={acceptFirstSession}>PICK UP</Button>
                            </div>
                            <div className={classes.callIcon}>
                                <PhoneAnimated animated={enableAnimation} />
                                <div className={classes.poolQty}>{pool.length}</div>
                            </div>

                            <Button className={classes.collapseContent} onClick={collapseClick}>
                                {isPoolOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                        </div>
                        <div className={classes.poolManagerBody}>
                            <ul className={classes.sessionList}>
                                {pool.map((session, index) => (
                                    <li
                                        aria-hidden="true"
                                        key={session.inviteeUserId}
                                        className={classes.sessionItem}
                                        onClick={() => acceptSession(session)}
                                    >
                                        <div className={classes.avatar} style={{ backgroundColor: getColor(index) }}>
                                            <span>
                                                {session?.userInfo?.name?.charAt(0).toUpperCase() +
                                                    session?.userInfo?.name?.charAt(1).toUpperCase()}
                                            </span>
                                            {session?.userInfo?.preferredLanguage && (
                                                <div className={classes.sessionFlag}>
                                                    {renderFlag(session?.userInfo?.preferredLanguage)}
                                                </div>
                                            )}
                                        </div>
                                        <div className={classes.sessionInfo}>
                                            <div className={classes.sessionName}>
                                                <p>
                                                    {session?.userInfo?.name}{' '}
                                                    <span>{`(${getOriginOfSession(session)})`}</span>
                                                </p>
                                            </div>
                                            <div className={classes.sessionEmail}>
                                                <p>{session?.userInfo?.email}</p>
                                            </div>
                                        </div>
                                        <div className={classes.SessionCallIcon}>
                                            <PhoneOutlinedIcon />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <AlertDialog
                    openDialog={showWarning}
                    title={t('pool.attendByAnotherAgent')}
                    onOkButtonClick={() => {
                        setShowWarning(false);
                    }}
                />
            </>
        );
};

export default PoolManager;
