import { Typography } from '@mui/material';
import { VodApi } from 'api';
import { ArcElement, BarElement, Chart, Colors, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DateRange from 'common/enums/dateRange';
import useFlexBoxStyles from 'common/flexBox';
import formatSize from 'common/utils/formatSize';
import moment from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { getDateRange } from 'redux/slices/dashboardSlice';
import { from } from 'rxjs';
import AnalyticsDateRangeSelector from '../common/AnalyticsDateRangeSelector';
import Granularity from '../enums/granularity';
import ChartLoading from './ChartLoading';
import ChartNoData from './ChartNoData';

Chart.register(ChartDataLabels, TimeScale, LinearScale, BarElement, ArcElement, Colors, Tooltip, Legend);

const PieOptions = {
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            formatter: formatSize,
        },
    },
};

const VodDeliveryDashboard = ({ organizationId, vodIds: vodIdsProp, className, title, hideSelector }) => {
    const { classes: flexBox, cx } = useFlexBoxStyles();

    const vodIds = useMemo(
        () => (vodIdsProp && !Array.isArray(vodIdsProp) ? [vodIdsProp] : vodIdsProp),
        [vodIdsProp]
    );

    const [granularity, setGranularity] = useState(Granularity.YEAR);
    const [egress, setEgress] = useState({});
    const [loading, setLoading] = useState(true);
    const dateRange = useSelector(getDateRange);

    const [startTime, endTime, period] = useMemo(() => {
        if (Array.isArray(dateRange)) {
            const start = new Date(dateRange[0]);
            const end = new Date(dateRange[1]);
            return [start.toISOString(), end.toISOString()];
        }
        let daysToSubtract = 0;
        if (dateRange === DateRange.LAST_7_DAYS) {
            daysToSubtract = 6;
        } else if (dateRange === DateRange.LAST_30_DAYS) {
            daysToSubtract = 29;
        } else if (dateRange === DateRange.LAST_90_DAYS) {
            daysToSubtract = 89;
        }
        return [
            moment().subtract(daysToSubtract, 'days').startOf('day').toISOString(),
            moment().endOf('day').toISOString(),
            daysToSubtract ? Granularity.DAY : Granularity.HOUR,
        ];
    }, [dateRange]);

    useEffect(() => {
        setLoading(true);
        const subscription = from(
            VodApi.post('egress/describe', {
                organizationId,
                vodIds,
                startTime,
                endTime,
                period,
            }),
        ).subscribe({
            next: ({ data: { egress, period } }) => {
                setEgress(egress);
                setGranularity(period);
            },
            error: (err) => {
                console.error(err);
                setEgress({});
                setGranularity(Granularity.YEAR);
            },
        });
        return () => subscription.unsubscribe();
    }, [startTime, endTime, granularity, organizationId, vodIds, period]);

    const barData = useMemo(() => {
        const labels = Array.from(
            new Set(
                Object.keys(egress)
                    .map((format) => Object.keys(egress[format]))
                    .reduce((flat, a) => flat.concat(a), []),
            ),
        ).sort();
        const datasets = Object.keys(egress)
            .sort()
            .map((format) => ({
                label: format.toUpperCase(),
                data: labels.map((timestamp) => egress[format][timestamp] || 0),
            }));
        labels.forEach((timestamp, index) => (labels[index] = moment(timestamp).endOf(granularity).toDate()));
        setLoading(false);
        return { labels, datasets };
    }, [egress, granularity]);

    const pieData = useMemo(
        () => ({
            labels: barData.datasets.map(({ label }) => label),
            datasets: [
                {
                    data: barData.datasets.map(({ data }) => data.reduce((acc, value) => acc + value, 0)),
                },
            ],
        }),
        [barData],
    );

    const barOptions = useMemo(
        () => ({
            scales: {
                x: {
                    stacked: true,
                    type: 'time',
                    time: {
                        unit: granularity,
                        round: granularity,
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: formatSize,
                    },
                },
            },
            plugins: {
                datalabels: {
                    formatter: formatSize,
                    display: function (context) {
                        const {
                            dataset: { data },
                            datasetIndex,
                            dataIndex,
                        } = context;
                        return data[dataIndex] || !(datasetIndex || data.find((v) => v));
                    },
                },
            },
        }),
        [granularity],
    );

    const charts = loading ? (
        <ChartLoading />
    ) : !barData.labels.length ? (
        <ChartNoData />
    ) : (
        <div className={cx(flexBox.flex, flexBox.gap2, flexBox.alignItemsCenter, flexBox.justifyContentCenter)}>
            <div className={cx(flexBox.flex, flexBox.column, flexBox.maxW30)}>
                <Typography variant="h5">
                    {formatSize(pieData.datasets[0].data.reduce((total, value) => total + value, 0))}
                </Typography>
                <div>
                    <Pie data={pieData} options={PieOptions} />
                </div>
            </div>
            <div className={flexBox.grow1}>
                <Bar data={barData} options={barOptions} />
            </div>
        </div>
    );

    return (
        <div className={cx(flexBox.flex, flexBox.column, flexBox.gap1, className)}>
            {title ? <Typography variant="h4">{title}</Typography> : null}
            {hideSelector ? null : (
                <div className={flexBox.flex}>
                    <div className={flexBox.grow1} />
                    <AnalyticsDateRangeSelector />
                </div>
            )}
            {charts}
        </div>
    );
};

export default VodDeliveryDashboard;
