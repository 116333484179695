import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const addOnsSlice = createSlice({
    name: 'addOns',
    initialState: {
        pending: false,
        addOns: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setAddOns: (state, action) => {
            state.pending = false;
            state.addOns = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        deleteAddOns: (state, action) => {
            state.pending = false;
            const index = state.addOns.findIndex((addOn) => addOn.id === action.payload.id);
            if (index !== -1) {
                state.customers = [...state.addOns.slice(0, index), ...state.addOns.slice(index + 1)];
            }
        },
    },
});

export const { start, stop, setAddOns, setError, deleteAddOns } = addOnsSlice.actions;

export const getAddOns = (state) => state.addOns.addOns;
export const getAddOnsPending = (state) => state.addOns.pending;
export const getAddOnsError = (state) => state.addOns.error;

export const fetchAddOnsAvailableAction = ({organizationId, onSuccess, onFailure} = {}) => async (dispatch) => {
    try {
        dispatch(start());
        api.get(`add-ons/available` + (organizationId ? `/${organizationId}` : ''))
            .then((response) => {
                dispatch(setAddOns(response.data));
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const fetchAddOnsAction = ({onSuccess, onFailure} = {}) => async (dispatch) => {
    try {
        dispatch(start());
        api.get('add-ons')
            .then((response) => {
                dispatch(setAddOns(response.data));
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return response;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const deleteAddOnsAction = ({id, onSuccess, onFailure} = {}) => async (dispatch) => {
    try {
        dispatch(start(id));
        api.delete('add-ons/' + id)
            .then((response) => {
                dispatch(deleteAddOns(response.data));
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export default addOnsSlice.reducer;
