import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';
import { NotificationService } from '@onlive.site/front-core';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { getColor, isValidHttpUrl } from 'common/utils/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addContactAction } from 'redux/slices/contactSlice';
import { getCurrentOne2OneEvent } from 'redux/slices/userSlice';
const { SmsOutlined, WhatsApp } = require('@mui/icons-material');
const { useSelector, useDispatch } = require('react-redux');
const { getIchatRequests, getIChat, getSessionRequest, getPool } = require('redux/slices/realtimeSlice');
const { default: useStylesChatRequests } = require('./styles/chatRequests.styles');

function IChatRequests() {
    const requests = useSelector(getIchatRequests);
    const session = useSelector(getSessionRequest);
    const pool = useSelector(getPool);
    const { classes, cx } = useStylesChatRequests();
    const ichat = useSelector(getIChat);
    const navigate = useNavigate();
    const [lastReq, setLastReq] = useState(null);
    const [open, setOpen] = useState(false);
    const [expand, setexpand] = useState(false);
    const audioRef = useRef(null);
    const ichatNotifications = useSettingValue(SettingsKey.ICHAT_NOTIFICATIONS);
    const enableBrowserChatNotification =
        ichatNotifications?.[SettingsKey.INCOMING_CHAT_REQUEST]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION];
    const currentOne2OneEvent = useSelector(getCurrentOne2OneEvent);
    const dataSound = useCallback(() => {
        const url =
            ichatNotifications?.[SettingsKey.INCOMING_CHAT_REQUEST]?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableBrowserChatNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_WIDGET_ASSETS_URL}/call_phone_ring_tone.mp3`;
    }, [ichatNotifications, enableBrowserChatNotification]);
    const dispatch = useDispatch();

    useEffect(() => {
        audioRef.current = NotificationService.notify('sound_notification', dataSound());
    }, [dataSound]);

    const createLead = (request) => {
        if (request) {
            dispatch(
                addContactAction({
                    contactDto: {
                        name: request.request_data.nickname,
                        actorId: request.user_id,
                        email: request.request_data?.extraFields?.email,
                        phone: request.request_data?.extraFields?.phone,
                        callOrigin: 'ichat_inbound',
                        extra: request.request_data.extraFields || {},
                        origin: {
                            origin: request.origin === 'whatsapp' ? 'whatsapp' : 'ichat',
                            channelUrl: request.request_data.channelUrl,
                            interactionId: request.request_data.interactionId,
                            name: 'ichat',
                            type: 'ichat',
                        },
                    },
                }),
            );
        }
    };

    const pickUp = () => {
        navigate(`/i-chat`);
        setLastReq(requests[0].request_id);
        ichat?.agentIChat.pickupCall(requests[0].request_id);
        createLead(requests[0]);
    };

    const acceptRequest = (requestId) => {
        navigate(`/i-chat`);
        setLastReq(requestId);
        ichat?.agentIChat.pickupCall(requestId);
        const request = requests.find((req) => req.request_id === requestId);
        createLead(request);
    };

    const closeRequests = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setOpen(false);
    };

    useEffect(() => {
        if (requests?.length > 0 && audioRef.current?.paused && !currentOne2OneEvent) {
            audioRef.current.play();
            setOpen(true);
        } else {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [requests, currentOne2OneEvent, audioRef]);

    const collapseClick = () => {
        setexpand(!expand);
    };

    const getOriginOfRequest = (request) => {
        return request?.origin && !['widget', 'direct'].includes(request?.origin) ? ` ${request?.origin}` : '';
    };

    return (
        <div
            className={cx(classes.requests, {
                [classes.requests_open]: requests.length && open > 0,
                [classes.right]: !!session || pool?.length > 0,
                [classes.requests_expand]: requests.length > 0 && expand,
            })}
        >
            <div className={classes.hideBtn}>
                <Button onClick={closeRequests}>
                    <CloseIcon />
                </Button>
            </div>
            <div className={classes.requestsMenu}>
                <div className={classes.request_icon}>
                    <SmsOutlined />
                </div>
                <p className={classes.new_request}>NEW CLIENT</p>
                <button className={classes.pick_up} onClick={pickUp} disabled={lastReq === requests[0]?.request_id}>
                    PICK UP
                </button>
            </div>
            <Button className={classes.collapseContent} onClick={collapseClick}>
                {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
            <div className={classes.requests_list}>
                {requests?.map((request, index) => (
                    <div
                        key={request.request_id}
                        className={classes.request}
                        onClick={() => acceptRequest(request.request_id)}
                    >
                        <div className={classes.request_icon_user} style={{ backgroundColor: getColor(index) }}>
                            {request.request_data.nickname[0]}
                        </div>
                        <p className={classes.request_name}>{`${request.request_data.nickname} ${getOriginOfRequest(
                            request.request_data,
                        )}`}</p>
                        {request.origin === 'whatsapp' && <WhatsApp sx={{ color: '#25D366' }} />}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default IChatRequests;
