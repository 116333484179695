import { makeStyles } from 'tss-react/mui';

const useStylesDashboard = makeStyles()((theme) => ({
    root: {
        // position: "absolute",
        // left: "50%",
        // top: "50%",
        // transform: "translate(-50%,-50%)"
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%"
    }
}));

export default useStylesDashboard;