import Close from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { countCurrentIntegrationErrorsAction } from 'redux/slices/integrationErrorSlice';
import {
    fetchOrganizationSyncProgressAction,
    getOrganization,
    getOrganizationSyncProgress,
    getProvider
} from 'redux/slices/organizationSlice';
import useStylesProduct from '../../app/product/styles/useStylesProduct.styles';

/**
 *
 * @loadDataFn For client components like Product. Otherwise, it must be null.
 *
 * @clickHereAction False for components like Product, that needs to navigate to other router.
 * Otherwise, it must be True and define a function in clickHereFn property.
 *
 * @clickHereFn A function that is executed when clickHereAction property is true AND the "here" link is clicked.
 * Otherwise, it must be null.
 *
 * @showClickHereMessage Set true to show the click here message, set false to hide it.
 */
const OrganizationSyncProgress = ({ loadDataFn, clickHereAction, clickHereFn, showClickHereMessage }) => {
    const { classes } = useStylesProduct();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const organization = useSelector(getOrganization);
    const syncProgress = useSelector(getOrganizationSyncProgress);
    const provider = useSelector(getProvider);

    const [syncing, setSyncing] = useState(Boolean(organization?.syncing));
    const [open, setOpen] = useState(true);
    const [currentErrors, setCurrentErrors] = useState(0);

    const countCurrentErrors = useCallback(async () => {
        const { data } = await dispatch(countCurrentIntegrationErrorsAction());
        setCurrentErrors(data);
    }, [dispatch]);

    const onClickHere = (event) => {
        event.preventDefault();
        clickHereFn();
    };

    const getClickHereChunk = () => {
        return clickHereAction ? (
            <a href="/my-profile/sync-status" onClick={onClickHere}>
                {t('app.common.here')}
            </a>
        ) : (
            <Link to="/my-profile/sync-status">{t('app.common.here')}</Link>
        );
    };

    const clickHereMessage = () => (
        <>
            {t('product.product.warningMessage1')} {getClickHereChunk()} {t('product.product.warningMessage2')}
        </>
    );

    useEffect(() => {
        if (syncing && !organization?.syncing) {
            loadDataFn && loadDataFn();
        }
        setSyncing(Boolean(organization?.syncing));
    }, [organization, syncing, loadDataFn]);

    useEffect(() => {
        const { id, providerId } = organization;
        let syncProgressInterval;
        if (syncing) {
            syncProgressInterval = setInterval(
                () => dispatch(fetchOrganizationSyncProgressAction(id, providerId)),
                10000,
            );
        } else {
            clearInterval(syncProgressInterval);
        }
        return () => {
            clearInterval(syncProgressInterval);
        };
    }, [dispatch, organization, syncing]);

    useEffect(() => {
        countCurrentErrors();
    }, [countCurrentErrors, provider?.type]);

    return (
        <div>
            {organization?.syncing && organization?.provider && (
                <div className={classes.syncAlertArea}>
                    <Alert severity="info" className={classes.syncingAlert}>
                        <span>
                            {t('product.product.syncing') +
                                ' ' +
                                organization?.provider?.split('-')[0] +
                                ' ' +
                                t('product.product.products') +
                                ' ' +
                                syncProgress?.synchronizedProducts +
                                ' ' +
                                t('product.product.of') +
                                ' ' +
                                syncProgress?.totalProductsToSync}
                        </span>
                        <span>
                            {t('product.product.elapsedTime') +
                                ' ' +
                                (
                                    (new Date().getTime() - new Date(organization.syncStartedAt).getTime()) /
                                    1000
                                )?.toFixed(0) +
                                's'}
                        </span>
                    </Alert>
                </div>
            )}

            {!organization?.syncing && currentErrors ? (
                <Collapse in={open}>
                    <Alert
                        severity="warning"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <AlertTitle>Warning</AlertTitle>
                        {showClickHereMessage ? (
                            <>{clickHereMessage()}</>
                        ) : (
                            <>{t('organizationSyncProgress.errorNotification.withNoLink')}</>
                        )}
                    </Alert>
                </Collapse>
            ) : null}
        </div>
    );
};

export default OrganizationSyncProgress;
