import { MenuItem } from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import { containsText } from 'common/utils/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getTemplateTypes } from 'redux/slices/templateTypeSlice';

const SelectTemplateType = ({ customMenuProps, onSubmit, fullWidth, size = 'small' }) => {
    const { t } = useTranslation();
    const allTemplateTypes = useSelector(getTemplateTypes);
    const [templateTypes, setTemplateTypes] = useState([]);
    const [params] = useSearchParams();
    const typeId = params.get('typeId') || 'all';
    const [open, setOpen] = useState(false);

    const onTypeFilter = (text) => {
        const newTypes = allTemplateTypes.filter((option) => containsText(option.name + option.externalId, text));
        setTemplateTypes(newTypes);
    };

    useEffect(() => {
        if (!open) {
            setTemplateTypes([...allTemplateTypes]);
        }
    }, [allTemplateTypes, open]);

    return (
        <RoundedSelect
            bold={true}
            value={typeId}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(e) => {
                onSubmit(e, 'typeId');
            }}
            size={size}
            search={allTemplateTypes.length > 10}
            searchPlaceholder={t('iCalendar.filter.searchTemplateTypes')}
            onSearchFilter={onTypeFilter}
            customMenuProps={customMenuProps}
            fullWidth={fullWidth}
        >
            <MenuItem value="all">{t('iCalendar.filter.allTemplateTypes')}</MenuItem>
            {templateTypes?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                    {type.name}
                </MenuItem>
            ))}
        </RoundedSelect>
    );
};

export default SelectTemplateType;
