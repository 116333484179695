import { createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'scripts';

export const scriptSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        scripts: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 0,
        updatedAt: new Date(),
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        addScript: (state, action) => {
            state.savePending = false;
            state.scripts = [action.payload, ...state.scripts];
            state.updatedAt = new Date();
        },
        editScript: (state, action) => {
            state.savePending = false;
            const index = state.scripts.findIndex((application) => application.id === action.payload.id);
            if (index !== -1) {
                state.scripts = [...state.scripts.slice(0, index), action.payload, ...state.scripts.slice(index + 1)];
                state.updatedAt = new Date();
            }
        },
        deleteScript: (state, action) => {
            state.deletePending = false;
            state.scripts = [...state.scripts.filter((ad) => ad.id !== action.payload.id)];
            state.updatedAt = new Date();
        },
        setScripts: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.scripts = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.total = action.payload.total;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        // setScriptsPage: (state, action) => {
        //     state.currentPage = action.payload;
        // },
    },
});

export const {
    start,
    stop,
    setScripts,
    setUninstallableScripts,
    setError,
    addScript,
    editScript,
    updateUninstallable,
    deleteScript,
    startDelete,
    startSave,
    setSaveError,
    stopSave,
    // setScriptsPage
} = scriptSlice.actions;

export const getScripts = (state) => state.scripts.scripts;
export const getScriptsPending = (state) => state.scripts.pending;
export const getDeletePending = (state) => state.scripts.deletePending;
export const getDeleteId = (state) => state.scripts.deleteId;
export const getScriptsError = (state) => state.scripts.error;
export const getSavePending = (state) => state.scripts.savePending;
export const getScriptById = (id) => (state) => state.scripts.scripts.find((item) => item.id === id);
export const getScriptsCount = (state) => state.scripts.count;
export const getScriptsCurrentPage = (state) => state.scripts.count;
export const getScriptsTotalPages = (state) => state.scripts.totalPages;
export const getScriptsTotal = (state) => state.scripts.total;

export const getScriptPagination = createSelector(
    [getScriptsCount, getScriptsCurrentPage, getScriptsTotalPages, getScriptsTotal],
    (count, currentPage, totalPages, total) => ({
        count,
        currentPage,
        totalPages,
        total,
    }),
);

export const getScriptUpdatedAt = (state) => state.scripts.updatedAt;

export const fetchScriptsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get(resource + query)
                .then((response) => {
                    dispatch(setScripts(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addScriptAction =
    ({ dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(resource, dataDto)
                .then((response) => {
                    dispatch(addScript(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editScriptAction =
    ({ id, dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            api.put(`${resource}/${id}`, dataDto)
                .then((response) => {
                    dispatch(editScript(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const deleteScriptAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.delete(resource + '/' + id)
                .then((response) => {
                    dispatch(deleteScript({ id }));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export default scriptSlice.reducer;
