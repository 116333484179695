import { CircularProgress, FormGroup, FormLabel } from '@mui/material';

import ActionButton from 'common/components/ActionButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useFormFields } from 'libs/hooksLib';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { inviteUserAction } from 'redux/slices/invitationSlice';
import {
    detachOrganizationAction,
    fetchParentOrganizationAction,
    getOrganization,
    getOrganizationDetachPending,
    getOrganizationParentPending,
    getParentOrganization,
} from 'redux/slices/organizationSlice';
import ItemInfo from './ItemInfo';
import useStylesProfileOrganization from './styles/useStylesProfileOrganization.styles';

const OrganizationManager = () => {
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2, cx } = useStylesProfileOrganization();
    const classes = { ...classes1, classes2 };

    const organization = useSelector(getOrganization);
    const pendingOrganizationDetach = useSelector(getOrganizationDetachPending);
    const pendingParentOrganization = useSelector(getOrganizationParentPending);
    const parentOrganization = useSelector(getParentOrganization);
    const user = useSelector(getUser);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [fields, handleFieldChange, resetForm] = useFormFields({});
    const [savingInvitation, setSavingInvitation] = useState(false);

    const initialForm = useMemo(() => ({
        email: '',
    }), []);

    useEffect(() => {
        if (organization?.parentOrganizationId) {
            resetForm(initialForm);
            dispatch(fetchParentOrganizationAction());
        }
    }, [organization?.parentOrganizationId, dispatch, initialForm, resetForm]);

    const handleRemoveParentOrganization = () => {
        dispatch(
            detachOrganizationAction({
                userId: user?.userId,
                onSuccess: () => {
                    enqueueSnackbar(t('profile.organization.managerRemoveSuccess'), {
                        variant: 'success',
                    });
                },
                onFailure: () => {
                    enqueueSnackbar(t('app.common.error'), {
                        variant: 'error',
                    });
                },
            }),
        );
    };

    function validateForm() {
        return fields.email;
    }

    function handleSubmit(event) {
        event.preventDefault();
        setSavingInvitation(true);
        dispatch(
            inviteUserAction({
                userDto: {
                    ...fields,
                    type: 'manager-request',
                },
                callback: (error) => {
                    setSavingInvitation(false);
                    if (error) {
                        enqueueSnackbar(t('profile.organization.managerFormError'), {
                            variant: 'error',
                        });
                    } else {
                        enqueueSnackbar(t('profile.organization.managerSaveSuccess'), {
                            variant: 'success',
                        });
                    }
                },
            }),
        );
    }

    function handleError() {
        enqueueSnackbar(t('profile.organization.managerFormError'), {
            variant: 'error',
        });
    }

    return (
        <div className={classes.w100pct}>
            {pendingParentOrganization && (
                <div className={classes.pending}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>
            )}

            {!pendingParentOrganization && (
                <>
                    {parentOrganization && (
                        <div className={classes.parentInfoContainer}>
                            <ItemInfo
                                title={t('profile.organization.organizationName')}
                                value={parentOrganization?.name}
                            />
                            <ItemInfo
                                title={t('profile.organization.managerEmail')}
                                value={parentOrganization?.email}
                            />

                            {!organization?.isProfile && (
                                <div>
                                    <ActionButton
                                        disabled={pendingOrganizationDetach}
                                        type="button"
                                        className={classes.proceedButton}
                                        onClick={handleRemoveParentOrganization}
                                    >
                                        {pendingOrganizationDetach && (
                                            <div className={classes.spinner}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                        <span> {t('profile.organization.removeManager')}</span>
                                    </ActionButton>
                                </div>
                            )}
                        </div>
                    )}

                    {!parentOrganization && (
                        <ValidatorForm onSubmit={handleSubmit} className={classes.form} onError={handleError}>
                            <FormGroup className={cx(classes.formGroup, classes.w32)}>
                                <FormLabel>{t('profile.organization.managerEmail')}</FormLabel>
                                <CustomTextValidator
                                    fullWidth
                                    name="email"
                                    value={fields.email}
                                    placeholder={'example@onlive.site'}
                                    onChange={handleFieldChange}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        t('profile.organization.managerEmailRequired'),
                                        t('profile.organization.managerInvalidEmail'),
                                    ]}
                                    variant="outlined"
                                    size="small"
                                />
                            </FormGroup>

                            <div>
                                <ActionButton
                                    disabled={!validateForm() || savingInvitation}
                                    type="submit"
                                    className={classes.proceedButton}
                                >
                                    {savingInvitation && (
                                        <div className={classes.spinner}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                    <span>{t('profile.organization.managerRequest')}</span>
                                </ActionButton>
                            </div>
                        </ValidatorForm>
                    )}
                </>
            )}
        </div>
    );
};

export default OrganizationManager;
