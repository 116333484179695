import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import InfoIcon from '@mui/icons-material/Info';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import CustomTooltip from 'common/components/CustomTooltip';
import ErrorBoundary from 'common/components/ErrorBoundary';
import WebUrlSources from 'common/enums/WebUrlSources';
import { isValidHttpUrl } from 'common/validators/validators';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';

const SeoSettings = (props) => {
    const { t } = useTranslation();
    const { toggleChange, handleSubmit, hasChanges, clientWebUrl, clientWebUrlSource, clientAutoIndex, saving } = props;

    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);
    const user = useSelector(getUser);

    const handleValueChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };
    /**
     * Like `handleValueChange` but for target controls with `checked` attribute.
     * Take current value from `checked` instead of `value`.
     */
    const handleCheckedChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const canSave = () => settingsDirty && hasChanges;

    const validWebUrl = () => {
        const otherValidUrl = clientWebUrlSource === WebUrlSources.OTHER && isValidHttpUrl(clientWebUrl);
        const autoUrls = clientWebUrlSource === WebUrlSources.INTEGRATION_DOMAIN_URL;
        return otherValidUrl || autoUrls;
    };

    const isRoot = user.role === 'root';

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.seoSettings.Seo')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <ErrorBoundary>
                    <div style={{ paddingBottom: '20px' }}>
                        <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flexDirection="column"
                                style={{ width: '50%' }}
                            >
                                <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                    <FormControl size="small" variant="outlined" className={classes.seoWebUrlSelect}>
                                        <FormLabel id="seo-radio-buttons">
                                            {t('profile.settings.seoSettings.webUrlSource')}
                                            <CustomTooltip
                                                arrow
                                                title={t('profile.settings.seoSettings.webUrlSourceTooltip')}
                                            >
                                                <InfoIcon />
                                            </CustomTooltip>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="seo-radio-buttons"
                                            name="radio-buttons-group"
                                            value={clientWebUrlSource}
                                            onChange={(e) =>
                                                handleValueChange(e, SettingsKey.SEO_CLIENT_WEB_URL_SOURCE)
                                            }
                                            style={{ display: 'flex', flexDirection: 'row' }}
                                        >
                                            <FormControlLabel
                                                value={WebUrlSources.INTEGRATION_DOMAIN_URL}
                                                control={<Radio />}
                                                label={t('profile.settings.seoSettings.webUrlModeProvider')}
                                            />
                                            <FormControlLabel
                                                value={WebUrlSources.OTHER}
                                                control={<Radio />}
                                                label={t('profile.settings.seoSettings.webUrlModeOther')}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    {clientWebUrlSource === WebUrlSources.OTHER && (
                                        <FormControl style={{ marginLeft: '20px' }}>
                                            <TextField
                                                style={{ width: '100%', marginTop: '10px' }}
                                                size="small"
                                                label={t('profile.settings.seoSettings.webUrlModeOtherLabel')}
                                                variant="outlined"
                                                value={clientWebUrl}
                                                onChange={(e) => handleValueChange(e, SettingsKey.SEO_CLIENT_WEB_URL)}
                                            />
                                            <FormHelperText error={!validWebUrl()}>
                                                {validWebUrl()
                                                    ? t('profile.settings.seoSettings.webUrlModeOtherHelp')
                                                    : t('profile.settings.urlValidationError')}
                                            </FormHelperText>
                                        </FormControl>
                                    )}

                                    {clientWebUrlSource === WebUrlSources.INTEGRATION_DOMAIN_URL && (
                                        <FormControl style={{ marginLeft: '20px' }}>
                                            <TextField
                                                style={{ width: '100%', marginTop: '10px' }}
                                                color={validWebUrl() ? 'primary' : 'red'}
                                                size="small"
                                                label={t('profile.settings.seoSettings.webUrlModeProviderLabel')}
                                                variant="outlined"
                                                value={t('profile.settings.seoSettings.webUrlModeProviderValue')}
                                                disabled={true}
                                            />
                                            <FormHelperText>
                                                {t('profile.settings.seoSettings.webUrlModeProviderHelp')}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Box>
                                {isRoot && (
                                    <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                        <FormLabel id="seo-radio-buttons" style={{ marginTop: '20px' }}>
                                            Internet search engine crawling
                                        </FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={clientAutoIndex}
                                                    onChange={(e) =>
                                                        handleCheckedChange(e, SettingsKey.SEO_CLIENT_AUTO_INDEX)
                                                    }
                                                    name="seoAutoIndex"
                                                    color="primary"
                                                />
                                            }
                                            label={<FormLabel>{'Auto submit to Google Search Index'}</FormLabel>}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: validWebUrl() && canSave() ? 'inline-flex' : 'none',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    className={classes.redColor}
                                    size="small"
                                    onClick={handleSubmit}
                                    disabled={saving}
                                >
                                    {t('profile.settings.cartSettings.save')}
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </ErrorBoundary>
            </AccordionDetails>
        </Accordion>
    );
};

export default SeoSettings;
