const emptyAssets = `${process.env.REACT_APP_ASSETS_URL}/empty_assets.svg`;
const emptyResource = `${process.env.REACT_APP_ASSETS_URL}/empty_resource.svg`;
const emptyMultimedia = `${process.env.REACT_APP_ASSETS_URL}/empty_multimedia.svg`;
const emptyProducts = `${process.env.REACT_APP_ASSETS_URL}/empty_products.svg`;
const emptySearch = `${process.env.REACT_APP_ASSETS_URL}/empty_search.svg`;
const emptyRecycleBin = `${process.env.REACT_APP_ASSETS_URL}/empty_recycle_bin.svg`;
const emptyReassignAgents = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/icon_no_asset_agent.svg`;

const EmptyTypes = {
    EMPTY_RESOURCE: { name: 'empty_resource', src: emptyResource },
    EMPTY_ASSETS: { name: 'empty_assets', src: emptyAssets },
    EMPTY_PRODUCTS: { name: 'empty_products', src: emptyProducts },
    EMPTY_MULTIMEDIA: { name: 'empty_multimedia', src: emptyMultimedia },
    EMPTY_SEARCH: { name: 'empty_search', src: emptySearch },
    EMPTY_RECYCLE_BIN: { name: 'empty_recycle_bin', src: emptyRecycleBin, style: { marginLeft: '20px' } },
    EMPTY_REASSIGN_AGENTS: { name: 'empty_reassign_agents', src: emptyReassignAgents },
};

export default EmptyTypes;
