import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { setNotesOpenId } from './appSlice';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'icalls';

export const iCallSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        iCalls: [],
        error: null,
        iCallDetailsDialog: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 0,
        pendingNoteSave: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        openICallDetailsDialog: (state, action) => {
            state.iCallDetailsDialog = action.payload;
        },
        addICall: (state, action) => {
            state.savePending = false;
            state.iCalls = [action.payload, ...state.iCalls];
        },
        editICall: (state, action) => {
            state.pendingNoteSave = false;
            state.savePending = false;
            const index = state.iCalls.findIndex((iCall) => iCall.id === action.payload.id);
            if (index !== -1) {
                state.iCalls = [...state.iCalls.slice(0, index), action.payload, ...state.iCalls.slice(index + 1)];
            }
        },
        deleteICall: (state, action) => {
            state.deletePending = false;
            const index = state.iCalls.findIndex((iCall) => iCall.id === action.payload.id);
            if (index !== -1) {
                state.iCalls = [...state.iCalls.slice(0, index), ...state.iCalls.slice(index + 1)];
            }
        },
        setICalls: (state, action) => {
            state.pending = false;
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.iCalls = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        startNoteSave: (state, action) => {
            state.pendingNoteSave = true;
        },
        stopNoteSave: (state, action) => {
            state.pendingNoteSave = false;
        },
    },
});

export const {
    start,
    stop,
    setICalls,
    setError,
    openICallDetailsDialog,
    addICall,
    editICall,
    deleteICall,
    startDelete,
    setTotalPages,
    startSave,
    setSaveError,
    stopSave,
    startNoteSave,
    stopNoteSave,
} = iCallSlice.actions;

export const getICalls = (state) => state.iCalls.iCalls;
export const getICallsCount = (state) => state.iCalls.count;
export const getICallsTotal = (state) => state.iCalls.total;
export const getICallsPending = (state) => state.iCalls.pending;
export const getDeletePending = (state) => state.iCalls.deletePending;
export const getOpenedEventDetails = (state) => state.iCalls.iCallDetailsDialog;
export const getTotalPages = (state) => state.iCalls.totalPages;
export const getCurrentPage = (state) => state.iCalls.currentPage;
export const getDeleteId = (state) => state.iCalls.deleteId;
export const getICallsError = (state) => state.iCalls.error;
export const getSavePending = (state) => state.iCalls.savePending;
export const getICallsById = (id) => (state) => state.iCalls.iCalls.find((item) => item.id === id);
export const getPendingNoteSave = (state) => state.iCalls.pendingNoteSave;

export const fetchICallsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get(resource + query)
                .then((response) => {
                    dispatch(setICalls(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addICallAction =
    ({ dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(resource, dataDto)
                .then((response) => {
                    dispatch(addICall(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const deleteICallAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete(resource + '/' + id)
                .then((response) => {
                    dispatch(deleteICall(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export const fetchLostICallsCsvAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get(`${resource}/lost-icalls-csv`, {
                params: queryParams,
            })
                .then((response) => {
                    if (onSuccess) {
                        const fileName = response.headers['content-disposition'].split('filename=')[1];
                        onSuccess(response.data, fileName);
                    }
                    dispatch(stop());
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const editICallNotesAction = (id, notes, success, failure) => async (dispatch) => {
    try {
        dispatch(startNoteSave());
        api.put('icalls/notes/' + id, notes)
            .then((response) => {
                dispatch(editICall(response.data));
                dispatch(setNotesOpenId(null));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                dispatch(stopNoteSave());
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopNoteSave());
        return console.error(e.message);
    }
};

export const closeICallDetailsAction = () => async (dispatch) => {
    dispatch(openICallDetailsDialog(null));
};

export default iCallSlice.reducer;
