import { makeStyles } from 'tss-react/mui';

const useStylesSidebar = makeStyles()((theme) => {
    return {
        drawer: {
            '&.contentDialogMode': {
                display: 'none'
            }
        },
        sidebar: {
            maxWidth: '18.28%',
            flex: '0 0 18.28%',
            display: 'flex',
            flexDirection: 'column',
        },
        drawerPaper: {
            minWidth: theme.spacing(6.5),
            width: '18.28%',
            marginTop: theme.spacing(10),
            height: 'calc(100% - 80px)',
            borderRadius: '0 20px 0 0',
            border: 0,
            zIndex: 950,
        },
        main: {
            maxWidth: '100%',
            flex: '0 0 100%',
            display: 'flex',
            flexDirection: 'column',
        },
        menu: {
            padding: '15px 8px',
        },

        logo: {
            height: '65px',
            // backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
            '& img': {
                maxWidth: '80%',
                height: 'auto',
                maxHeight: '60px',
            },
        },
        scroll: {
            overflowX: 'hidden',
            // height: 'calc(100% - 163px)',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    };
});

export default useStylesSidebar;
