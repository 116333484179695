import { Stack, TableCell, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { minWidth }) => {
    return {
        rootTableRow: {
            ...theme.typography.subtitle2,
            padding: theme.spacing(0.5),
            minWidth: minWidth ? theme.spacing(minWidth) : 'auto',
            [theme.breakpoints.up('xl')]: {
                ...theme.typography.subtitle2,
                padding: theme.spacing(1),
            },
        },
        borderBottom: {
            borderBottom: '3px solid #DEDEDE',
            [theme.breakpoints.up('xl')]: {
                borderBottomWidth: theme.spacing(0.5),
            },
        },
        borderRight: {
            borderRight: '1px solid #E9E9E9',
        },
        statusBorder: {
            borderLeft: '6px solid',
            minWidth: theme.spacing(14.25),
            borderLeftColor: 'transparent',
        },
        sticky: {
            position: 'sticky',
            right: 0,
            background: '#F8F8F8',
        },
    };
});

const CustomTableRow = (props) => {
    const { rowConfig } = props;
    const { columns, actions, columnsWidth, actionsColumnWidth, minWidth } = rowConfig;

    const { classes, cx } = useStyles({ minWidth });

    return (
        <TableRow>
            {columns.map((col, idx) => (
                <TableCell
                    key={col.key || idx}
                    classes={{
                        root: cx(
                            classes.rootTableRow,
                            classes.borderBottom,
                            classes.borderRight,
                            col?.statusBorder && classes.statusBorder,
                        ),
                    }}
                    align={col?.align || 'center'}
                    padding="none"
                    style={col?.customStyle || null}
                    width={columnsWidth || null}
                >
                    {col?.content || '-'}
                </TableCell>
            ))}

            {actions && (
                <TableCell
                    classes={{ root: cx(classes.rootTableRow, classes.borderBottom, classes.sticky) }}
                    align="center"
                    padding="none"
                    width={actionsColumnWidth || null}
                    sx={{ minWidth: 40 }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={1.5}>
                        {actions}
                    </Stack>
                </TableCell>
            )}
        </TableRow>
    );
};

export default CustomTableRow;
