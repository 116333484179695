import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { t } from 'i18next';

export default function ConfirmDialog({ classes, open, title, content, onConfirm, onCancel }) {
    return (
        <Dialog
            open={open}
            className={classes.confirmDialog}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {t('app.common.cancel')}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {t('profile.profile.closeConfirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
