import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { Divider, Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import LayoutForm from 'common/layouts/LayoutForm';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cancelCloseCustomDialogAction, closeCustomDialogAction } from 'redux/slices/customDialogSlice';
import 'suneditor/dist/css/suneditor.min.css';

const VerificationCodesForm = ({ data, onReload, confirmMethod }) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const dispatch = useDispatch();
    const cancelConfirmTitle = t('security.verificationCodes.closeConfirm');
    const cancelConfirmMessage = t('security.verificationCodes.closeMessage');
    const [openCancelDlg, setOpenCancelDlg] = useState(false);

    const finalCancel = () => {
        dispatch(closeCustomDialogAction());
    };

    const cancelDlg = () => {
        setOpenCancelDlg(false);
        dispatch(cancelCloseCustomDialogAction());
    };

    const close = (event) => {
        event.preventDefault();
        setOpenCancelDlg(true);
    };
    const handleDownload = () => {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const blob = new Blob([data.codes.join('\n')], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `RecoveryCodes ${date}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        confirmMethod();
    };

    const actions = (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            <CustomTooltip arrow title={t('common.reloadButton.action')} aria-label="reload" placement="bottom">
                <CustomIconButton size="small" aria-label="download report" onClick={onReload}>
                    <RefreshOutlinedIcon />
                </CustomIconButton>
            </CustomTooltip>
            {/* <CustomTooltip arrow title={t('app.common.download')} aria-label="download report" placement="bottom">
                <CustomIconButton size="small" aria-label="download" onClick={handleDownload}>
                    <CloudDownloadOutlinedIcon />
                </CustomIconButton>
            </CustomTooltip> */}
        </Stack>
    );

    return (
        <LayoutForm
            title={t('security.verificationCodes.form.title')}
            confirmDlg={{
                open: openCancelDlg,
                accept: finalCancel,
                cancel: cancelDlg,
                titleDlg: cancelConfirmTitle,
                messageDlg: cancelConfirmMessage,
            }}
        >
            <Stack direction="column" spacing={2} className={flexBox.overflowHidden}>
                <Stack direction="column" spacing={4} className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone)}>
                    <Stack direction="row" spacing={8} alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle1" fontWeight={500}>
                            {t('security.verificationCodes.form.message')}
                        </Typography>
                        {actions}
                    </Stack>

                    <Divider />

                    <Stack direction="row" spacing={8} alignItems="center" justifyContent="center">
                        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                            {data?.codes?.slice(0, 5).map((item, idx) => (
                                <Typography key={idx} variant="subtitle1" fontWeight={400}>
                                    {item}
                                </Typography>
                            ))}
                        </Stack>
                        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                            {data?.codes?.slice(5, 10).map((item, idx) => (
                                <Typography key={idx} variant="subtitle1" fontWeight={400}>
                                    {item}
                                </Typography>
                            ))}
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="flex-end" className={flexBox.paddingTop4}>
                        <ActionButton disabled={false} onClick={close} color="default" fitWidth>
                            {t('common.customPopover.closeIcon')}
                        </ActionButton>
                        <ActionButton disabled={false} onClick={handleDownload} color="primary" fitWidth>
                            <CloudDownloadOutlinedIcon /> {t('app.common.download')}
                        </ActionButton>
                    </Stack>
                </Stack>
            </Stack>
        </LayoutForm>
    );
};

export default VerificationCodesForm;
