import { useSelector } from 'react-redux';
import { getLicense } from 'redux/slices/licenseSlice';

export function useDefaultServiceRoute() {
    const license = useSelector(getLicense);

    if (license) {
        if (license?.services?.oneToMany?.iLive) {
            return '/one-to-many';
        } else if (license?.services?.oneToOne?.iCall) {
            return '/one-to-one';
        } else if (license?.services?.oneToOne?.iCalendar) {
            return '/i-calendar';
        } else if (license?.services?.oneToOne?.iChat) {
            return '/i-chat';
        } else if (license?.services?.oneToMany?.shoppable) {
            return '/shoppable';
        }
    }

    return '/my-profile';
}