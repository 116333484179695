import { FormGroup, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import React from 'react';

const BasicSelectSetting = ({
    onChange,
    label,
    value,
    valuesList
}) => {
    const renderOptionsList = () => {
        if (!valuesList) {
            return []
        } else {
            return (
                valuesList.map(({ id, name }) => {
                    return (
                        <MenuItem value={id} key={`settingList.${id}`}>
                            {name}
                        </MenuItem>
                    );
                })
            );
        }
    }

    const getValue = () => {
        if (Array.isArray(valuesList) && valuesList.length > 0 && value) {
            return value;
        } else {
            return '';
        }
    }

    return (
        <div>
            <FormGroup>
                {label && <InputLabel>{label}</InputLabel>}
                <Select
                    value={getValue()}
                    onChange={onChange}
                >
                    {renderOptionsList()}
                </Select>
                { !valuesList && <LinearProgress /> }
            </FormGroup>
        </div>
    );
};

export default BasicSelectSetting;
