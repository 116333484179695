import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const multimediaSlice = createSlice({
    name: 'multimedia',
    initialState: {
        pending: false,
        savePending: false,
        multimedia: [],
        allMultimedia: [],
        pendingAllMultimedia: false,
        currentPageAll: 0,
        totalPagesAll: 1,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
        error: null,
        allError: null,
        pendingTotal: false,
        errorTotal: null,
        version: 0,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startFetchAll: (state, action) => {
            state.pendingAllMultimedia = true;
        },
        stopFetchAll: (state, action) => {
            state.pendingAllMultimedia = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setMultimedia: (state, action) => {
            state.pending = false;
            state.multimedia = action.payload.items;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        resetMultimedia: (state, action) => {
            state.multimedia = [];
            state.count = 0;
            state.currentPage = 0;
            state.totalPages = 0;
        },
        setAllMultimedia: (state, action) => {
            state.pendingAllMultimedia = false;
            const offset = (action.payload.currentPage - 1) * action.payload.perPage;
            state.allMultimedia = [...state.allMultimedia.slice(0, offset), ...action.payload.items];
            state.currentPageAll = action.payload.currentPage;
            state.totalPagesAll = action.payload.totalPages;
        },
        resetAllMultimedia: (state, action) => {
            state.pendingAllMultimedia = false;
            state.allMultimedia = [];
            state.currentPageAll = 0;
            state.totalPagesAll = 1;
        },
        setError: (state, action) => {
            state.pending = false;
            state.multimedia = [];
            state.error = action.payload;
        },
        setAllError: (state, action) => {
            state.pendingAllMultimedia = false;
            state.allMultimedia = [];
            state.allError = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addMultimedia: (state, action) => {
            state.savePending = false;
            state.multimedia = [...state.multimedia, action.payload];
        },
        editMultimedia: (state, action) => {
            state.savePending = false;
            const index = state.multimedia.findIndex((multimedia) => multimedia.id === action.payload.id);
            if (index !== -1) {
                state.multimedia = [
                    ...state.multimedia.slice(0, index),
                    action.payload,
                    ...state.multimedia.slice(index + 1),
                ];
            }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.multimediaId = action.payload;
        },
        deleteMultimedia: (state, action) => {
            state.deletePending = false;
            const index = state.multimedia.findIndex((multimedia) => multimedia.id === action.payload.id);
            if (index !== -1) {
                state.multimedia = [...state.multimedia.slice(0, index), ...state.multimedia.slice(index + 1)];
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        updateVersion: (state, action) => {
            state.version = state.version + 1;
        },
    },
});

export const {
    start,
    stop,
    setMultimedia,
    setError,
    startSave,
    addMultimedia,
    editMultimedia,
    setSaveError,
    startDelete,
    deleteMultimedia,
    setDeleteError,
    stopSave,
    startFetchAll,
    setAllMultimedia,
    stopFetchAll,
    updateVersion,
    resetMultimedia,
    setAllError,
    resetAllMultimedia,
} = multimediaSlice.actions;

export const getMultimedia = (state) => state.multimedia.multimedia;
export const getMultimediaTotal = (state) => state.multimedia.total;
export const getMultimediaUIVersion = (state) => state.multimedia.version;
export const getMultimediaCount = (state) => state.multimedia.count;
export const getMultimediaCurrentPage = (state) => state.multimedia.currentPage;
export const getMultimediaTotalPages = (state) => state.multimedia.totalPages;
export const getMultimediaPending = (state) => state.multimedia.pending;
export const getMultimediaSavePending = (state) => state.multimedia.savePending;
export const getMultimediaDeletePending = (state) => state.multimedia.deletePending;
export const getMultimediaError = (state) => state.multimedia.error;

export const getAllMultimediaError = (state) => state.multimedia.allError;
export const getMultimediaId = (state) => state.multimedia.multimediaId;
export const getAllMultimediaPending = (state) => state.multimedia.pendingAllMultimedia;
export const getAllMultimedia = (state) => state.multimedia.allMultimedia;
export const getMultimediaCurrentPageAll = (state) => state.multimedia.currentPageAll;
export const getMultimediaTotalPageAll = (state) => state.multimedia.totalPagesAll;

export const fetchMultimediaAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(resetMultimedia());
        dispatch(start());
        api.get('multimedia' + query)
            .then((response) => {
                dispatch(setMultimedia(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchAllMultimediaAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(startFetchAll());
            api.get('multimedia' + query)
                .then((response) => {
                    dispatch(setAllMultimedia({ ...response.data, perPage: filters.limit }));
                    onSuccess && onSuccess(response.data);
                    return response;
                })
                .catch((error) => {
                    dispatch(setAllError(error));
                    onFailure && onFailure(error);
                });
        } catch (e) {
            dispatch(stopFetchAll());
            onFailure && onFailure(e);
            return console.error('error', e);
        }
    };

export const addMultimediaAction = (multimediaDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('multimedia', multimediaDto)
            .then((response) => {
                dispatch(addMultimedia(response.data));
                dispatch(updateVersion());
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const editMultimediaAction = (multimediaId, multimediaDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('multimedia/' + multimediaId, multimediaDto)
            .then((response) => {
                dispatch(editMultimedia(response.data));
                dispatch(updateVersion());
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteMultimediaAction = (multimediaId, isShoppable) => async (dispatch) => {
    try {
        dispatch(startDelete(multimediaId));
        api.delete('multimedia/' + multimediaId)
            .then((response) => {
                dispatch(deleteMultimedia(response.data));
                dispatch(updateVersion());
                return response;
            })
            .catch((error) => {
                if (
                    isShoppable &&
                    error.response?.data?.description === 'There is a shoppable video associated with the multimedia'
                ) {
                    isShoppable();
                }
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default multimediaSlice.reducer;
