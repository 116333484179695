import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useStylesItemInfo from './styles/useStylesItemInfo.styles';

const ItemInfo = (props) => {
    const { title, value } = props;
    const { classes } = useStylesItemInfo();

    return (
        <div className={classes.text}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.content}>{value || ''}</Typography>
        </div>
    );
};

ItemInfo.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
};

export default ItemInfo;
