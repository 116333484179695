import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import useStylesFieldForm from 'app/profile/settings/styles/useStylesFieldForm.styles';
import useStylesSettings from 'app/profile/settings/styles/useStylesSettings.styles';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { isValidHttpUrl } from 'common/utils/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';

const LeadsManagement = (props) => {
    const { leadsEndPoint, leadsClientId, leadsClientSecret, toggleChange, handleSubmit, hasChanges } = props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();

    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);

    const handleChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const isValidEndPointUrl = () => {
        return isValidHttpUrl(leadsEndPoint);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('Leads Management')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('Save changes')}
                        </Button>
                    </Box>
                </Box>

                <Typography className={classes.text}>{t('profile.settings.leadsSettings.setLeadsEndPoint')}</Typography>

                <FormControl>
                    <TextField
                        style={{ width: '100%', marginTop: '10px' }}
                        color={isValidEndPointUrl() ? 'primary' : 'error'}
                        size="small"
                        label={t('profile.settings.leadsSettings.leadsEndPoint')}
                        variant="outlined"
                        value={leadsEndPoint}
                        onChange={(e) => handleChange(e, SettingsKey.LEADS_MANAGEMENT_END_POINT)}
                    />
                    <FormHelperText error={!isValidEndPointUrl()}>
                        {!isValidEndPointUrl() && t('profile.settings.urlValidationError')}
                    </FormHelperText>
                </FormControl>

                <TextField
                    label={t('profile.settings.leadsSettings.clientId')}
                    value={leadsClientId}
                    onChange={(e) => handleChange(e, SettingsKey.LEADS_MANAGEMENT_CLIENT_ID)}
                    style={{ width: '100%', marginTop: '10px' }}
                    variant="outlined"
                    size="small"
                    placeholder={t('profile.settings.leadsSettings.clientId')}
                />

                <TextField
                    label={t('profile.settings.leadsSettings.clientSecret')}
                    value={leadsClientSecret}
                    onChange={(e) => handleChange(e, SettingsKey.LEADS_MANAGEMENT_CLIENT_SECRET)}
                    style={{ width: '100%', marginTop: '10px' }}
                    variant="outlined"
                    size="small"
                    placeholder={t('profile.settings.leadsSettings.clientSecret')}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default LeadsManagement;
