import { Stack } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { range } from 'lodash';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        boxSizing: 'border-box',
        minWidth: theme.spacing(33),
        flexBasis: theme.spacing(30),
    },
}));

const EventGridComplete = (props) => {
    const { rows = 4 } = props;

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    return (
        <>
            {range(0, rows, 1).map((i) => {
                return <Stack key={i} className={cx(classes.root, flexBox.grow1, flexBox.borderBox)}></Stack>;
            })}
        </>
    );
};

export default EventGridComplete;
