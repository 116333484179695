import { DeleteOutlineOutlined } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import {
    DELETE_TEMPLATE_MODE,
    deleteTemplateAction,
    setTemplateAction,
    setTemplateView,
    startEditTemplateMode,
} from 'redux/slices/ocalendarSlice';
import { fetchUsersAction } from 'redux/slices/userSlice';
import useStylesAvailabilityCard from './styles/useStylesAvailabilityCard.styles';

const AvailabilityCard = ({ template, customWidth }) => {
    const { classes } = useStylesAvailabilityCard();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const [users, setUsers] = useState([]);
    const templateUser = useMemo(() => users.find((u) => u.id === template.userId), [users, template]);
    const bookingUrl = useSettingValue(SettingsKey.BOOKING_SHARE_URL);

    const handleEditTemplate = (template) => {
        dispatch(setTemplateView(template));
        dispatch(startEditTemplateMode(template));
    };

    const handleDeleteTemplate = () => {
        const userId = templateUser?.id || user.userId;
        dispatch(setTemplateAction(DELETE_TEMPLATE_MODE));
        dispatch(deleteTemplateAction(template.id, userId));
    };

    useEffect(() => {
        if (user.role === 'creator') {
            setUsers([
                {
                    ...user,
                    id: user.userId,
                },
            ]);
        } else {
            dispatch(
                fetchUsersAction({
                    onSuccess: (userOrg) => {
                        setUsers(userOrg);
                    },
                }),
            );
        }
    }, [dispatch, user]);

    const getBookingShareUrl = () => {
        const queryParams = {
            olOrganizationId: user.organizationId,
            olBookingType: template.availabilityType,
            olAgent: user.username,
        };

        const searchParams = new URLSearchParams(queryParams);
        const url = new URL(bookingUrl);
        url.search = searchParams.toString();
        return url.toString();
    };

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                ...(customWidth && { width: customWidth }),
            }}
        >
            <CardHeader
                action={
                    <IconButton aria-label="delete" onClick={() => handleDeleteTemplate()}>
                        <DeleteOutlineOutlined />
                    </IconButton>
                }
                title={template.title}
                subheader={template.availabilityType || 'default'}
            />
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        pb: 3,
                    }}
                >
                    <Avatar
                        key={templateUser?.id}
                        src={templateUser?.avatar || '/broken-image.jpg'}
                        className={classes.avatar}
                    />
                </Box>
                <Typography color="text.secondary">{template.timeBetweenEvents} minutes between events</Typography>
                <Typography color="text.secondary">
                    {template.duration + ' ' + t('iCalendar.schedule.minutesPerEvents')}
                </Typography>
            </CardContent>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2} sx={{ p: 2 }}>
                {bookingUrl && (
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <CopyToClipboard text={getBookingShareUrl()}>
                            <IconButton
                                aria-label="copy to share"
                                edge="end"
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    enqueueSnackbar(t('profile.organization.tabs.availability.urlCopied'), {
                                        variant: 'success',
                                    });
                                }}
                            >
                                <ShareIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </Stack>
                )}
                <Stack alignItems="center" direction="row" spacing={1}>
                    <Button
                        size="small"
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            handleEditTemplate(template);
                        }}
                    >
                        View
                    </Button>
                </Stack>
            </Stack>
        </Card>
    );
};

export default AvailabilityCard;
