import { ValidatorForm } from 'react-material-ui-form-validator';

const textRegExp = /^[\p{L}\p{N} \t\n"_()[\]@.,\\/#&|*+-:¡!¿?]*$/u;

class ValidationRulesManager {
    constructor() {
        this.initialized = false;
        this.init();
    }

    init() {
        if (this.initialized) return;

        ValidatorForm.addValidationRule('isValidText', (value) => {
            return value ? Boolean((value + '').match(textRegExp)) : true;
        });

        ValidatorForm.addValidationRule('urlMatch', (value) => {
            const regex =
                // eslint-disable-next-line no-useless-escape
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
            return Boolean(value.match(regex));
        });

        this.initialized = true;
    }
}

const validationRulesManager = new ValidationRulesManager();

export default validationRulesManager;