import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        language: localStorage?.getItem('preferredLanguage') || null,
        pending: false,
        pendingSettingEmail: false,
        errorSettingEmail: null,
        settings: [],
        loadedSettings: false,
        error: null,
        deleteNode: '',
        savePending: false,
        wordsBag: null,
        wordsBagPending: false,
        emailTemplatesDirty: false,
    },
    reducers: {
        start: (state, action) => {
            state.error = null;
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startFetchSettingEmail: (state, action) => {
            state.errorSettingEmail = null;
            state.pendingSettingEmail = true;
        },
        stopFetchSettingEmail: (state, action) => {
            state.pendingSettingEmail = false;
        },
        addSetting: (state, action) => {
            state.settings = [...state.settings, action.payload];
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.pending = false;
        },
        setErrorFetchSettingEmail: (state, action) => {
            state.errorSettingEmail = action.payload;
            state.pendingSettingEmail = false;
        },
        setDeleteNode: (state, action) => {
            state.deleteNode = action.payload;
        },
        setSettings: (state, action) => {
            state.pending = false;
            state.settings = action.payload;
            state.loadedSettings = true;
        },
        updateSetting: (state, action) => {
            const index = state.settings.findIndex((s) => s.settingKey === action.payload.settingKey);
            if (index === -1) {
                state.settings = [...state.settings, action.payload];
            } else {
                state.settings = [
                    ...state.settings.slice(0, index),
                    action.payload,
                    ...state.settings.slice(index + 1),
                ];
            }
        },
        setLanguage: (state, action) => {
            if (action.payload) {
                localStorage.setItem('preferredLanguage', action.payload);
                state.language = action.payload;
            }
        },
        setPending: (state, action) => {
            state.pending = action.payload;
        },
        setSavePending: (state, action) => {
            state.savePending = action.payload;
        },
        setEmailTemplatesDirty: (state, action) => {
            state.emailTemplatesDirty = action.payload;
        },
        setWordsBagPending: (state, action) => {
            state.wordsBagPending = action.payload;
        },
        setWordsBag: (state, action) => {
            state.wordsBag = action.payload;
            state.wordsBagPending = false;
        },
    },
});

export const {
    addSetting,
    setError,
    setDeleteNode,
    start,
    stop,
    setSettings,
    setLanguage,
    updateSetting,
    setPending,
    setWordsBagPending,
    setWordsBag,
    setSavePending,
    startFetchSettingEmail,
    stopFetchSettingEmail,
    setErrorFetchSettingEmail,
    setEmailTemplatesDirty,
} = settingSlice.actions;

export const getLanguage = (state) => state.setting.language;
export const getSettings = (state) => state.setting.settings;
export const getLoadedSettings = (state) => state.setting.loadedSettings;
export const getDeleteNode = (state) => state.setting.deleteNode;
export const getSettingsPending = (state) => state.setting.pending;
export const getSettingsEmailPending = (state) => state.setting.pendingSettingEmail;
export const getSettingsError = (state) => state.setting.error;
export const getSavePending = (state) => state.setting.savePending;
export const getEmailTemplatesDirty = (state) => state.setting.emailTemplatesDirty;
export const getWordsBag = (state) => state.setting.wordsBag;
export const getWordsBagPending = (state) => state.setting.wordsBagPending;

export const fetchSettingsAction = () => async (dispatch) => {
    try {
        dispatch(start());
        api.get('settings')
            .then((response) => {
                dispatch(setSettings(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchEmailTemplateSettingsAction = (callback) => async (dispatch) => {
    try {
        dispatch(startFetchSettingEmail());
        api.get('settings/organization_emails_templates')
            .then((response) => {
                callback(response.data.settingValue);
                dispatch(stopFetchSettingEmail());
                return response.data;
            })
            .catch((error) => {
                dispatch(setErrorFetchSettingEmail(error));
            });
    } catch (e) {
        dispatch(stopFetchSettingEmail());
        return console.error('error', e);
    }
};

export const createOrUpdateSetting = (settingsDto, success) => async (dispatch) => {
    try {
        dispatch(start());
        dispatch(setSavePending(true));
        const settingsKeys = Object.keys(settingsDto);
        api.post('settings', {
            settings: settingsKeys.map((key) => {
                const s = settingsDto[key];
                return {
                    settingKey: s.settingKey,
                    settingValue: s.settingValue,
                };
            }),
        })
            .then((response) => {
                dispatch(fetchSettingsAction());
                dispatch(setSavePending(false));
                dispatch(setEmailTemplatesDirty(false));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSavePending(false));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const generateIndexerAccessTokenAction = (callback) => async (dispatch) => {
    try {
        dispatch(start());
        api.post('indexer-account')
            .then((response) => {
                dispatch(setPending(false));
                if (callback) {
                    callback('success');
                }
                dispatch(updateSetting(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setSavePending(false));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchWordsBagAction = () => async (dispatch) => {
    try {
        dispatch(setWordsBagPending(true));
        api.get('settings/lexicons')
            .then((response) => {
                dispatch(setWordsBag(response?.data));
            })
            .catch((error) => {
                dispatch(setWordsBagPending(false));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(setWordsBagPending(false));
        return console.error('error', e);
    }
};

export const createUpdateWordsBagAction =
    ({ data, success, failed }) =>
    async (dispatch) => {
        try {
            dispatch(setWordsBagPending(true));
            api.post('settings/lexicons', data)
                .then((response) => {
                    dispatch(setWordsBag(response?.data));
                    if (success) {
                        success(response?.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setWordsBagPending(false));
                    if (failed) {
                        failed(error);
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(setWordsBagPending(false));
            return console.error('error', e);
        }
    };

export default settingSlice.reducer;
