import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'admin-dashboard';

export const adminDashboardSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        adminDashboards: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        updatedAt: new Date()
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        addDashboard: (state, action) => {
            state.savePending = false;
            state.adminDashboards = [action.payload, ...state.adminDashboards];
            state.updatedAt = new Date();
        },
        editDashboard: (state, action) => {
            state.savePending = false;
            const index = state.adminDashboards.findIndex((application) => application.id === action.payload.id);
            if (index !== -1) {
                state.adminDashboards = [
                    ...state.adminDashboards.slice(0, index),
                    action.payload,
                    ...state.adminDashboards.slice(index + 1),
                ];
                state.updatedAt = new Date();
            }
        },
        deleteDashboard: (state, action) => {
            state.deletePending = false;
            state.adminDashboards = [...state.adminDashboards.filter((ad) => (ad.id !== action.payload.id))];
            state.updatedAt = new Date();
        },
        setAdminDashboards: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.adminDashboards = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setAdminDashboardsPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setAdminDashboards,
    setUninstallableAdminDashboards,
    setError,
    addDashboard,
    editDashboard,
    updateUninstallable,
    deleteDashboard,
    startDelete,
    startSave,
    setSaveError,
    stopSave,
    setAdminDashboardsPage
} = adminDashboardSlice.actions;

export const getAdminDashboards = (state) => state.adminDashboards.adminDashboards;
export const getAdminDashboardsPending = (state) => (state.adminDashboards.pending);
export const getDeletePending = (state) => state.adminDashboards.deletePending;
export const getDeleteId = (state) => state.adminDashboards.deleteId;
export const getAdminDashboardsError = (state) => state.adminDashboards.error;
export const getSavePending = (state) => state.adminDashboards.savePending;
export const getDashboardById = (id) => (state) => state.adminDashboards.adminDashboards.find((item) => item.id === id);
export const getAdminDashboardPagination = (state) => ({
    count: state.adminDashboards.count,
    currentPage: state.adminDashboards.currentPage, 
    totalPages: state.adminDashboards.totalPages, 
});
export const getAdminDashboardUpdatedAt = (state) => (state.adminDashboards.updatedAt);

export const fetchAdminDashboardsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                let query = transformFiltersToQuery(filters);
                dispatch(start());
                api.get(resource + query)
                    .then((response) => {
                        dispatch(setAdminDashboards(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stop());
                return console.error(e.message);
            }
        };

export const addDashboardAction =
    ({ dataDto, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.post(resource, dataDto)
                    .then((response) => {
                        dispatch(addDashboard(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setSaveError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error(e.message);
            }
        };

export const editDashboardAction =
    ({ id, dataDto, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                api.put(`${resource}/${id}`, dataDto)
                    .then((response) => {
                        dispatch(editDashboard(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setSaveError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error(e.message);
            }
        };

export const deleteDashboardAction =
    ({ id, onSuccess, onFailure }) =>
        async (dispatch) => {
            try {
                api.delete(resource + '/' + id)
                    .then((response) => {
                        dispatch(deleteDashboard({ id }));
                        if (onSuccess) {
                            onSuccess();
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure();
                        }
                    });
            } catch (e) {
                return console.error(e.message);
            }
        };

export default adminDashboardSlice.reducer;
