import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDefaultServiceRoute } from '../../common/hooks/useDefaultServiceRoute';
import OAuthTransition from '../../views/OAuthTransition';

const LoadingSetting = () => {

    const navigate = useNavigate();
    const defaultRedirect = useDefaultServiceRoute();

    const { t } = useTranslation();

    useEffect(() => {
        navigate(defaultRedirect);
    }, [navigate, defaultRedirect]);

    return <OAuthTransition message={t('app.loadingSetting')} />;
};

export default LoadingSetting;
