import { Box, Card, CardActions, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    cardMedia: {
        height: 150,
    },
    cardActions: {
        padding: theme.spacing(2),
        '&.right': {
            justifyContent: 'flex-end',
        },
    },
}));

const CustomCard = ({
    image,
    title,
    subtitles,
    titleDivider,
    cardBody,
    actions,
    borderRadius = 0,
    actionsAlign = 'left' | 'center' | 'right',
}) => {
    const { classes, cx } = useStyles();

    const SubtitleText = ({ text }) => {
        return (
            <Typography color="text.secondary" gutterBottom component="div">
                {text}
            </Typography>
        );
    };

    const subtitleContent = () => {
        if (Array.isArray(subtitles)) {
            return subtitles.map((sub, idx) => <SubtitleText text={sub} key={idx} />);
        }

        return <SubtitleText text={subtitles || ''} />;
    };

    return (
        <Card sx={{ borderRadius }}>
            {image && (
                <CardMedia component="img" className={classes.cardMedia} image={image} alt={title} height="140" />
            )}
            <CardContent>
                {title && (
                    <Typography variant="h6" component="div" gutterBottom>
                        {title}
                    </Typography>
                )}
                {subtitleContent()}
                <Box
                    sx={{
                        my: 1,
                    }}
                >
                    {titleDivider && <Divider />}
                </Box>
                {cardBody}
            </CardContent>
            {actions && <CardActions className={cx(classes.cardActions, actionsAlign || '')}>{actions}</CardActions>}
        </Card>
    );
};

export default CustomCard;
