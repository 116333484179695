import { Add } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    LinearProgress,
    MenuItem,
    Stack,
} from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import CustomTooltip from 'common/components/CustomTooltip';

import SelectGroups from 'app/iCalendar/filters/SelectGroups';
import RoundedSelect from 'common/components/RoundedSelect';
import UserRole from 'common/enums/roles';
import ExtraFieldsCard from 'common/features/extraFields/ExtraFieldsCard';
import ExtraFieldsEditor from 'common/features/extraFields/ExtraFieldsEditor';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { getNestedData } from 'common/features/renderForms/utils';
import useFlexBoxStyles from 'common/flexBox';
import { useSettingValue } from 'common/hooks/useSettingValue';
import LayoutForm from 'common/layouts/LayoutForm';
import { useFormFields } from 'libs/hooksLib';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { cancelCloseCustomDialogAction, closeCustomDialogAction } from 'redux/slices/customDialogSlice';
import { getFormConfig } from 'redux/slices/formSlice';
import { getGroupAction } from 'redux/slices/groupSlice';
import { getNestedDialogStateData } from 'redux/slices/nestedDialogSlice';
import { fetchServicesAction, getServicesSavePending } from 'redux/slices/serviceSlice';
import { fetchUnitsAction, getUnits } from 'redux/slices/unitSlice';
import { makeStyles } from 'tss-react/mui';
import ServiceImage from '../components/ServiceImage';

import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import { getTemplateTypes } from 'redux/slices/templateTypeSlice';
import ServiceFormUnits from './ServiceFormUnits';
import ServicePeriodOfService from './ServicePeriodOfService';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            maxWidth: theme.spacing(140),
            width: '70vw',
            [theme.breakpoints.up('xl')]: {
                maxHeight: theme.spacing(160),
                maxWidth: theme.spacing(140),
                width: '55vw',
            },
            [theme.breakpoints.down('lg')]: {
                width: '80vw',
            },
            [theme.breakpoints.down('mg')]: {
                width: '50vw',
            },
            [theme.breakpoints.down('md')]: {
                width: '70vw',
            },
            [theme.breakpoints.down('ms')]: {
                width: '80vw',
            },
        },
        mW12: {
            minWidth: theme.spacing(14),
        },
    };
});

const ServiceForm = (props) => {
    const { action, service, onSubmit } = props;
    const { classes } = useStyles();
    const { classes: flexBox, cx } = useFlexBoxStyles();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const timeZone = useSettingValue(SettingsKey.TIMEZONE) || 'Europe/Madrid';
    const savePending = useSelector(getServicesSavePending);
    const nestedDialogStateData = useSelector(getNestedDialogStateData);
    const user = useSelector(getUser);
    const [subForm, setSubForm] = useState(null);
    const [selectedUnits, setSelectedUnits] = useState([]);

    const confirmTitle = t('common.cancelConfirm');
    const confirmMessage = t('services.cancelMessage');
    const allUnits = useSelector(getUnits);
    const enableGroups = useSettingValue(SettingsKey.ENABLE_USER_GROUPS) && !user?.userGroupId;
    const [initialGroup, setInitialGroup] = useState();
    const formConfig = useSelector(getFormConfig);
    const isAuthorize = useAuthLicenseAddOns();
    const iCall = isAuthorize(['oneToOne.iCall']);
    const templateTypes = useSelector(getTemplateTypes);

    useEffect(() => {
        if (service?.relatedCalendars?.length) {
            const newUnits = service?.relatedCalendars?.map((re) => {
                const unit = allUnits?.find((un) => un.id === re.calendarId);
                return {
                    ...re,
                    ...unit,
                };
            });
            setSelectedUnits(newUnits);
        }
    }, [allUnits, service?.relatedCalendars]);

    const initialForm = useMemo(() => {
        const initial = {
            name: service?.name || '',
            dateRanges: service?.dateRanges || [],
            userGroupId: service?.userGroupId || user?.userGroupId || null,
            image: service?.image || '',
            duration: service?.duration ?? 0,
            timeBetween: service?.timeBetween ?? 0,
            minTimeToSchedule: service?.minTimeToSchedule ?? 0,
            maxTimeToSchedule: service?.maxTimeToSchedule ?? 0,
            incrementTime: service?.incrementTime ?? 0,
            typeId: service?.typeId || '',
            allowOverlapping: service?.allowOverlapping ?? true,
            hasICall: service?.hasICall ?? false,
            deniedMultipleAppointment: service?.deniedMultipleAppointment ?? false,
            active: service?.active ?? true,
            bookingUntil: service?.bookingUntil ?? undefined,
            extraFields: service?.extraFields || null,
            sharingParams: service?.sharingParams || null,
        };
        return { ...initial };
    }, [user, service]);

    const [fields, handleFieldChange, resetForm] = useFormFields(nestedDialogStateData || initialForm);

    const [openCancelDlg, setOpenCancelDlg] = useState(false);

    useEffect(() => {
        dispatch(fetchUnitsAction());
    }, [dispatch]);

    useEffect(() => {
        if (service?.userGroupId) {
            dispatch(getGroupAction({ id: service?.userGroupId, onSuccess: (data) => setInitialGroup(data) }));
        }
    }, [dispatch, service]);

    const onSetGroup = (g) => {
        const extra = { ...fields.extraFields, ...g?.extraFields };
        handleFieldChange({
            target: {
                name: 'extraFields',
                value: Object.keys(extra).length ? extra : null,
            },
        });
    };

    const onGroupChange = (id) => {
        dispatch(getGroupAction({ id, onSuccess: (data) => onSetGroup(data) }));
    };

    const userDidChange = useCallback(() => {
        const { ...original } = initialForm;
        const { ...current } = fields;
        return !isEqual(original, current);
    }, [fields, initialForm]);

    const restart = useCallback(() => {
        resetForm(initialForm);
    }, [initialForm, resetForm]);

    const finalCancel = useCallback(() => {
        restart();
        dispatch(closeCustomDialogAction());
    }, [dispatch, restart]);

    const cancelDlg = (event) => {
        setOpenCancelDlg(false);
        dispatch(cancelCloseCustomDialogAction());
    };

    const manageCancel = useCallback(() => {
        if (!userDidChange()) {
            finalCancel();
        } else {
            setOpenCancelDlg(true);
        }
    }, [finalCancel, userDidChange]);

    const cancel = (event) => {
        event.preventDefault();
        manageCancel();
    };

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(
            user.organizationId,
            {
                name: fields.name,
                dateRanges: fields.dateRanges,
                userGroupId: fields.userGroupId,
                image: fields.image,
                duration: fields.duration,
                timeBetween: fields.timeBetween,
                minTimeToSchedule: fields.minTimeToSchedule,
                maxTimeToSchedule: fields.maxTimeToSchedule,
                incrementTime: fields.incrementTime,
                allowOverlapping: fields.allowOverlapping,
                hasICall: fields.hasICall,
                deniedMultipleAppointment: fields.deniedMultipleAppointment,
                active: fields.active,
                typeId: fields.typeId,
                bookingUntil: fields.bookingUntil,
                relatedCalendars: selectedUnits?.map((unit) => {
                    return {
                        id: unit.id,
                        calendarId: unit.id,
                        active: unit.active ?? unit.available,
                        restrictionDates: unit.restrictionDates,
                        timezone: timeZone,
                        type: 'asset',
                        name: unit.name || unit.productName,
                    };
                }),
                relatedCondition: selectedUnits?.length ? 'one' : 'none',
                extraFields: fields.extraFields,
                sharingParams: fields.sharingParams,
            },
            () => {
                dispatch(
                    fetchServicesAction({
                        queryParams: { expand: ['relatedCalendars', 'dateRanges'] },
                        onSuccess: () => {
                            dispatch(closeCustomDialogAction());
                            resetForm(initialForm);
                            dispatch(closeCustomDialogAction());
                        },
                    }),
                );
            },
            () => enqueueSnackbar(t('app.common.error'), { variant: 'error' }),
        );
    }

    const closeSubForm = () => {
        setSubForm(null);
    };

    const openSubForm = (form) => {
        setSubForm(form);
    };

    const addExtraFields = () => {
        openSubForm(
            <ExtraFieldsEditor
                formConfig={formConfig}
                close={closeSubForm}
                value={fields.extraFields}
                onConfirm={(value) => {
                    handleFieldChange({
                        target: {
                            name: 'extraFields',
                            value: formConfig ? getNestedData(formConfig, value) : value,
                        },
                    });
                }}
                sectionName={t('iCalendar.appointmentForm.extraFieldsSub')}
            />,
        );
    };

    const isValidForm = () => {
        return fields.name.length > 0 && fields.image && fields.typeId;
    };

    const handleError = (error) => {
        console.error('🚀 ~ handleError ~ error:', error);
    };

    const incrementTimeArray = useMemo(() => [5, 10, 15, 20, 30, 60], []);

    const busyCapacity = useCallback(() => {
        const total = service?.totalCapacities ?? 0;
        const available = service?.availableCapacities ?? 0;
        const busy = service?.busyCapacities ?? 0;
        return `${total - available} ${t('services.form.occupation')}(${busy}%)`;
    }, [service, t]);

    const [inputError, setInputError] = useState({ sharingParams: null });

    const updateField = useCallback(
        (name, value) => {
            handleFieldChange({
                target: {
                    name,
                    value,
                },
            });
        },
        [handleFieldChange],
    );

    return (
        <LayoutForm
            size={subForm ? 'medium' : ''}
            title={action}
            customClasses={cx(flexBox.vH70, classes.root)}
            confirmDlg={{
                open: openCancelDlg,
                accept: finalCancel,
                cancel: cancelDlg,
                titleDlg: confirmTitle,
                messageDlg: confirmMessage,
            }}
        >
            <Stack direction="column" spacing={2} className={cx(flexBox.overflowHidden, flexBox.h100)}>
                <Stack
                    direction="column"
                    spacing={3}
                    className={cx(flexBox.overflowHidden, flexBox.h100, subForm && flexBox.displayNone)}
                >
                    <ValidatorForm
                        onSubmit={() => {}}
                        onError={handleError}
                        className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden, flexBox.h100)}
                    >
                        <Stack
                            direction="column"
                            className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone, flexBox.h100)}
                        >
                            <Grid container spacing={10}>
                                <Grid item mg={6} sm={12}>
                                    <Stack direction="column" spacing={3}>
                                        {user.role === UserRole.ROOT && service && (
                                            <Box sx={{ width: '100%' }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={service?.busyCapacities ?? 0}
                                                />
                                                <div>
                                                    <FormLabel>{busyCapacity()}</FormLabel>
                                                </div>
                                                <div>
                                                    <FormLabel>{`${service?.totalCapacities ?? 0} ${t(
                                                        'services.form.appointments',
                                                    )}`}</FormLabel>
                                                </div>
                                            </Box>
                                        )}
                                        <FormGroup>
                                            <FormLabel>{t('services.form.title')}</FormLabel>
                                            <CustomTextValidator
                                                required
                                                name="name"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                autoComplete="new-name"
                                                value={fields.name}
                                                onChange={handleFieldChange}
                                                validators={['maxStringLength: 100']}
                                                errorMessages={[t('services.form.titleMaxLength')]}
                                            />
                                            { !fields?.name ? <FormHelperText error>{t('app.common.required')}</FormHelperText> : null }
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{t('services.form.periodOfService')}</FormLabel>

                                            <ServicePeriodOfService
                                                fields={fields}
                                                handleFieldChange={handleFieldChange}
                                                timeZone={timeZone}
                                            />
                                        </FormGroup>

                                        {enableGroups ? (
                                            <FormGroup>
                                                <FormLabel>{t('users.group')}</FormLabel>
                                                <SelectGroups
                                                    fullWidth={true}
                                                    size="big"
                                                    groupId={fields.userGroupId || 'all'}
                                                    onSubmit={(e) => {
                                                        handleFieldChange({
                                                            target: {
                                                                name: 'userGroupId',
                                                                value: e.target.value,
                                                            },
                                                        });
                                                        onGroupChange(e.target.value);
                                                    }}
                                                    selectedGroupById={initialGroup}
                                                />
                                            </FormGroup>
                                        ) : null}

                                        {/* <ServiceFormTaxonomy
                                            service={service}
                                            selectedTaxonomies={selectedTaxonomies}
                                            setSelectedTaxonomies={setSelectedTaxonomies}
                                        /> */}

                                        <FormGroup>
                                            <FormLabel>{t('service.label.templateType')}</FormLabel>
                                            <RoundedSelect
                                                bold={true}
                                                size="big"
                                                fullWidth={true}
                                                name="typeId"
                                                value={fields.typeId}
                                                onChange={handleFieldChange}
                                            >
                                                {templateTypes.map((type) => (
                                                    <MenuItem key={type.id} value={type.id}>
                                                        {type.name}
                                                    </MenuItem>
                                                ))}
                                            </RoundedSelect>
                                            { !fields?.typeId ? <FormHelperText error>{t('app.common.required')}</FormHelperText> : null }
                                        </FormGroup>

                                        <ServiceFormUnits
                                            selectedUnits={selectedUnits}
                                            setSelectedUnits={setSelectedUnits}
                                            timeZone={timeZone}
                                            groupId={fields.userGroupId}
                                        />

                                        <FormGroup>
                                            <FormLabel>{t('services.form.image')}</FormLabel>

                                            <ServiceImage image={fields?.image} handleFieldChange={handleFieldChange} />
                                            { !fields?.image ? <FormHelperText error>{t('app.common.required')}</FormHelperText> : null }
                                        </FormGroup>

                                        <FormGroup>
                                            <Stack direction="column" spacing={1} className={flexBox.w100}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    className={flexBox.w100}
                                                >
                                                    <FormLabel>{t('services.form.extraFields')}</FormLabel>

                                                    {fields?.extraFields || subForm ? null : (
                                                        <CustomTooltip
                                                            arrow
                                                            title={t('calendar.common.add')}
                                                            placement="bottom"
                                                        >
                                                            <CustomIconButton
                                                                id="onlive-button-edit"
                                                                size="small"
                                                                onClick={addExtraFields}
                                                            >
                                                                <Add />
                                                            </CustomIconButton>
                                                        </CustomTooltip>
                                                    )}
                                                </Stack>
                                                <ExtraFieldsCard
                                                    fields={fields.extraFields}
                                                    onEdit={addExtraFields}
                                                    showEdit={!subForm}
                                                />
                                            </Stack>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{t('app.common.sharingParams')}</FormLabel>

                                            <JsonCodeInputMonacoEditor
                                                width={480}
                                                name="sharingParams"
                                                value={fields?.sharingParams || ''}
                                                onChange={(value, errors) => {
                                                    const codeHaveErrors =
                                                        errors && Array.isArray(errors) && errors.length > 0;
                                                    const newErrors = codeHaveErrors ? errors : null;
                                                    setInputError({ sharingParams: newErrors });
                                                    updateField('sharingParams', value);
                                                }}
                                            />
                                            <SimpleCodeErrorView
                                                id={'error-context'}
                                                mainErrorMessage={t('JsonError')}
                                                errorList={inputError['sharingParams']}
                                            />
                                        </FormGroup>
                                    </Stack>
                                </Grid>

                                <Grid item md={6} sm={12}>
                                    <Stack direction="column" spacing={3}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="active"
                                                        className={cx(flexBox.padding0, flexBox.margin01)}
                                                        checked={fields.active}
                                                        onChange={handleFieldChange}
                                                        inputProps={{ 'aria-label': 'disabled checkbox' }}
                                                    />
                                                }
                                                label={<FormLabel>{t('service.form.active')}</FormLabel>}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="deniedMultipleAppointment"
                                                        className={cx(flexBox.padding0, flexBox.margin01)}
                                                        checked={fields.deniedMultipleAppointment}
                                                        onChange={handleFieldChange}
                                                        inputProps={{ 'aria-label': 'disabled checkbox' }}
                                                    />
                                                }
                                                label={
                                                    <FormLabel>{t('service.form.deniedMultipleAppointment')}</FormLabel>
                                                }
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="allowOverlapping"
                                                        className={cx(flexBox.padding0, flexBox.margin01)}
                                                        checked={fields.allowOverlapping}
                                                        onChange={handleFieldChange}
                                                        inputProps={{ 'aria-label': 'disabled checkbox' }}
                                                    />
                                                }
                                                label={<FormLabel>{t('service.form.allowOverlapping')}</FormLabel>}
                                            />
                                        </FormGroup>

                                        {iCall && (user.role === UserRole.ROOT || user.role === UserRole.ADMIN) ? (
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="hasICall"
                                                            className={cx(flexBox.padding0, flexBox.margin01)}
                                                            checked={fields.hasICall}
                                                            onChange={handleFieldChange}
                                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                                        />
                                                    }
                                                    label={<FormLabel>{t('service.form.hasICall')}</FormLabel>}
                                                />
                                            </FormGroup>
                                        ) : null}

                                        <FormGroup>
                                            <FormLabel>{`${t('common.term.Duration')} (${t(
                                                'common.term.Minutes',
                                            )})`}</FormLabel>
                                            <CustomTextValidator
                                                name="duration"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                value={fields.duration}
                                                onChange={handleFieldChange}
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0, max: 60, step: 5 },
                                                }}
                                                validators={['required']}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{`${t('calendar.timeBetweenEvents')} (${t(
                                                'common.term.Minutes',
                                            )})`}</FormLabel>
                                            <CustomTextValidator
                                                name="timeBetween"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                value={fields.timeBetween}
                                                onChange={handleFieldChange}
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0, max: 60, step: 5 },
                                                }}
                                                validators={['required']}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{`${t('calendar.minimumTimeToSchedule')} (${t(
                                                'common.term.Days',
                                            )})`}</FormLabel>
                                            <CustomTextValidator
                                                name="minTimeToSchedule"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                value={fields.minTimeToSchedule}
                                                onChange={handleFieldChange}
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0, max: 30, step: 1 },
                                                }}
                                                validators={['required']}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{`${t('calendar.maximumTimeToSchedule')} (${t(
                                                'common.term.Days',
                                            )})`}</FormLabel>
                                            <CustomTextValidator
                                                name="maxTimeToSchedule"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                value={fields.maxTimeToSchedule}
                                                onChange={handleFieldChange}
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0, step: 1 },
                                                }}
                                                validators={['required']}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{`${t('calendar.timeIncrementation')} (${t(
                                                'common.term.Minutes',
                                            )})`}</FormLabel>
                                            <RoundedSelect
                                                bold={true}
                                                size="big"
                                                fullWidth={true}
                                                name="incrementTime"
                                                value={fields.incrementTime}
                                                onChange={handleFieldChange}
                                            >
                                                {incrementTimeArray.map((time) => (
                                                    <MenuItem key={time} value={time}>
                                                        {time}
                                                    </MenuItem>
                                                ))}
                                            </RoundedSelect>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{t('calendar.bookingUntil')}</FormLabel>
                                            <CustomTextValidator
                                                name="bookingUntil"
                                                className={classes.mW12}
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                value={
                                                    fields.bookingUntil
                                                        ? moment
                                                              .tz(fields.bookingUntil, timeZone)
                                                              .format('YYYY-MM-DDTHH:mm')
                                                        : undefined
                                                }
                                                onChange={handleFieldChange}
                                                type={'datetime-local'}
                                                InputProps={{
                                                    inputProps: {
                                                        shrink: true,
                                                        min: moment.tz(timeZone).format('YYYY-MM-DDTHH:mm'),
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </ValidatorForm>

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                        className={flexBox.paddingTop2}
                    >
                        <ActionButton onClick={cancel} color="default" fitWidth>
                            {t('app.common.cancel')}
                        </ActionButton>

                        <ActionButton
                            color="primary"
                            autoFocus
                            fitWidth
                            disabled={savePending || !isValidForm()}
                            onClick={handleSubmit}
                        >
                            {t('app.common.save')}
                        </ActionButton>
                    </Stack>
                </Stack>

                {subForm}
            </Stack>
        </LayoutForm>
    );
};

export default ServiceForm;
