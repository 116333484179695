import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useFlexBoxStyles from 'common/flexBox';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import TableSkeleton from './TableSkeleton';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            borderCollapse: 'inherit',
        },
        tableHead: {
            '& th': {
                backgroundColor: '#D1D1D1',
                padding: theme.spacing(0.5),
                fontSize: theme.spacing(1.5),
                fontWeight: 'bold',
                [theme.breakpoints.up('xl')]: {
                    padding: theme.spacing(0.75),
                    fontSize: theme.spacing(1.75),
                },
            },
        },

        rootTableRow: {
            padding: theme.spacing(1),
            fontSize: theme.spacing(1.5),
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(2),
            },
        },
        borderRight: {
            borderRight: '1px solid #E9E9E9',
        },
        textHeader: {
            textWrap: 'nowrap',
            textTransform: 'capitalize',
        },

        sticky: {
            position: 'sticky',
            right: 0,
        },
    };
});

const CustomTable = (props) => {
    const { children, tableConfig } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const { columns, pending, customSkeleton, actionColumn, allSortable, onOrderBy, maxHeight, customTableSx } =
        tableConfig;
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        const ord = isAsc ? 'desc' : 'asc';
        if (orderBy === property && order !== 'asc') {
            setOrderBy('');
            setOrder('asc');
            onOrderBy(null);
        } else {
            setOrder(ord);
            setOrderBy(property);
            onOrderBy(property, ord);
        }
    };

    return (
        <TableContainer className={cx(flexBox.overflowAuto, flexBox.w100)} sx={{ ...(maxHeight && { maxHeight }) }}>
            <Table sx={customTableSx} classes={{ root: cx(classes.root) }} stickyHeader>
                {pending || Boolean(children?.length > 0) ? (
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {columns.map((col, idx) => (
                                <TableCell
                                    key={idx}
                                    classes={{
                                        root: cx(classes.rootTableRow, classes.borderRight, classes.textHeader),
                                    }}
                                    align="center"
                                    padding="none"
                                    sortDirection={orderBy === col.id ? order : false}
                                    style={col.style ? col.style : null}
                                >
                                    {allSortable || col?.sortable ? (
                                        <TableSortLabel
                                            active={orderBy === col.id}
                                            direction={orderBy === col.id ? order : 'asc'}
                                            onClick={createSortHandler(col.id)}
                                        >
                                            {col.content}
                                            {orderBy === col.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        col.content
                                    )}
                                </TableCell>
                            ))}

                            {actionColumn ? (
                                <TableCell
                                    classes={{ root: cx(classes.rootTableRow, classes.sticky) }}
                                    align="center"
                                    padding="none"
                                    style={actionColumn.style ? actionColumn.style : null}
                                />
                            ) : null}
                        </TableRow>
                    </TableHead>
                ) : null}

                <TableBody>
                    {children}
                    {pending && children?.length === 0
                        ? [1, 2, 3, 4, 5].map((i) => {
                              return (
                                  <React.Fragment key={i}>
                                      {customSkeleton || <TableSkeleton columnNumb={columns?.length + 2 || 0} />}
                                  </React.Fragment>
                              );
                          })
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomTable;
