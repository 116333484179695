import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import useStylesFieldForm from 'app/profile/settings/styles/useStylesFieldForm.styles';
import useStylesSettings from 'app/profile/settings/styles/useStylesSettings.styles';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';

const Security = (props) => {
    const { requireTwoFactorAuthentication, toggleChange, handleSubmit, hasChanges } = props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);

    const handleCheckChange = (event, settingName) => {
        const checked = event.target.checked;
        toggleChange(settingName, checked);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.security')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('profile.settings.leadsSettings.save')}
                        </Button>
                    </Box>
                </Box>
                <ItemSettings
                    label={t('profile.settings.security.enableTwoFactor')}
                    onChange={handleCheckChange}
                    name={'requireTwoFactorAuthentication'}
                    keySettings={SettingsKey.REQUIRE_TWO_FACTOR_AUTHENTICATION}
                    value={requireTwoFactorAuthentication}
                    paddingBottom
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default Security;
