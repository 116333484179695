import { Checkbox, FormControlLabel, FormLabel, Grid, Stack, Typography } from '@mui/material';
import DateRange from 'common/components/DateRange';
import useFlexBoxStyles from 'common/flexBox';
import { useIncludeDateValidation } from 'common/hooks/useIncludeDateValidation';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        unavailableText: {
            paddingRight: '14%',
            height: theme.spacing(4.5),
        },
        paddingTop75: {
            paddingTop: theme.spacing(0.75),
        },
    };
});

const transformDates = (dates) => {
    return dates.map((dateRange) => ({
        startDate: dateRange[0],
        endDate: dateRange[1],
    }));
};

const WorkingHours = ({ fields, handleFieldChange }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const [showAddForm, setShowAddForm] = useState(false);
    const { isValidHourRange, error, validationType, setError } = useIncludeDateValidation();

    const workingHours = fields?.configuration?.workingHours;

    const handleEditDate = (newDate, idx, dayIndex) => {
        const dates = workingHours[dayIndex] || [];
        const newRange = [...dates.slice(0, idx), [newDate.start, newDate.end], ...dates.slice(idx + 1)];
        saveRange(newRange, dayIndex);
    };

    const handleAddDate = (newDate, dayIndex) => {
        const dates = workingHours[dayIndex] || [];
        const newRange = [...dates, [newDate.start, newDate.end]];
        saveRange(newRange, dayIndex);
    };

    const handleDeleteDate = (idx, dayIndex) => {
        deleteError(idx, dayIndex);
        const dates = workingHours[dayIndex] || [];
        const newRange = [...dates.slice(0, idx), ...dates.slice(idx + 1)];
        saveRange(newRange, dayIndex);
    };

    const deleteError = (idx, dayIndex) => {
        if (error?.idx === idx && error?.dayIndex === dayIndex) {
            setError(null);
        }
    };

    const saveRange = (data, dayIndex) => {
        handleFieldChange({
            target: {
                name: 'configuration',
                value: { ...fields?.configuration, workingHours: { ...workingHours, [dayIndex]: data } },
            },
        });
    };

    const checkBoxChange = (e, dayIndex) => {
        handleFieldChange({
            target: {
                name: 'configuration',
                value: {
                    ...fields?.configuration,
                    workingHours: { ...workingHours, [dayIndex]: e.target.checked ? [] : null },
                },
            },
        });
    };

    const handleChange = (date, idx, dayIndex, callback) => {
        const ranges = transformDates(workingHours[dayIndex]);
        const isValid = isValidHourRange({ ranges, date, idx, dayIndex, type: validationType.UNSELECTED });
        if (isValid) {
            if (idx === -1) {
                handleAddDate(date, dayIndex);
                setShowAddForm(false);
                callback?.();
            } else {
                handleEditDate(date, idx, dayIndex);
            }
        }
    };

    return (
        <Stack direction="column" spacing={1}>
            <FormLabel>{t('calendar.setWeeklyHours').toUpperCase()}</FormLabel>

            <Stack direction="column" spacing={2}>
                {Object.keys(workingHours).map((dayIndex) => (
                    <Grid container key={dayIndex}>
                        <Grid item xs={3} alignItems="center">
                            <FormControlLabel
                                className={classes.paddingTop75}
                                control={
                                    <Checkbox
                                        className={cx(flexBox.padding0, flexBox.margin01)}
                                        checked={Boolean(workingHours[dayIndex])}
                                        onChange={(e) => checkBoxChange(e, dayIndex)}
                                        inputProps={{ 'aria-label': 'disabled checkbox' }}
                                    />
                                }
                                label={<Typography>{moment().day(dayIndex).format('dddd').toUpperCase()}</Typography>}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <>
                                {workingHours[dayIndex] ? (
                                    <Stack direction="column" spacing={1}>
                                        {workingHours[dayIndex]?.map((hours, idx) => (
                                            <div key={idx}>
                                                {hours?.length > 0 ? (
                                                    <DateRange
                                                        type="time"
                                                        date={{ start: hours[0], end: hours[1] }}
                                                        onChange={(date) => handleChange(date, idx, dayIndex)}
                                                        onDelete={() => handleDeleteDate(idx, dayIndex)}
                                                        error={
                                                            error?.idx === idx && error.dayIndex === dayIndex
                                                                ? error?.msg
                                                                : null
                                                        }
                                                        disabled={
                                                            error
                                                                ? error?.idx !== idx || error.dayIndex !== dayIndex
                                                                : false
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        ))}

                                        <DateRange
                                            type="time"
                                            isDates={workingHours[dayIndex]?.length}
                                            showAddForm={showAddForm}
                                            setShowAddForm={setShowAddForm}
                                            divider={false}
                                            onChange={(date, callback) => handleChange(date, -1, dayIndex, callback)}
                                            onDelete={() => deleteError(-1, dayIndex)}
                                            error={error?.idx === -1 && error.dayIndex === dayIndex ? error?.msg : null}
                                            disabled={error ? error?.idx !== -1 || error.dayIndex !== dayIndex : false}
                                        />
                                    </Stack>
                                ) : (
                                    <Stack
                                        direction="column"
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.unavailableText}
                                    >
                                        <Typography variant="subtitle2" fontWeight={400}>
                                            {t('common.term.unavailable')}
                                        </Typography>
                                    </Stack>
                                )}
                            </>
                        </Grid>
                    </Grid>
                ))}
            </Stack>
        </Stack>
    );
};

export default WorkingHours;
