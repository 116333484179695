import { makeStyles } from 'tss-react/mui';

const useStylesAvailabilityCard = makeStyles()((theme) => {
    return {
        root: {
            flex: 1,
            minWidth: '180px',
            maxWidth: '220px',
            margin: '24px',
            flexGrow: 1,
            boxSizing: 'border-box',
            flexBasis: '240px',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '20px',
            overflow: 'auto',
            backgroundColor: '#FFFFFF',
            position: 'relative',
        },
        avatar: {
            color: '#FFFF',
            width: theme.spacing(4),
            height: theme.spacing(4),
            marginRight: theme.spacing(0.5),
            [theme.breakpoints.up('xl')]: {
                marginRight: theme.spacing(1),
                width: theme.spacing(4, 75),
                height: theme.spacing(4, 75),
            },
        },
        rootAdd: {
            flex: 1,
            minWidth: '180px',
            maxWidth: '220px',
            margin: '24px',
            flexGrow: 1,
            boxSizing: 'border-box',
            flexBasis: '240px',
            borderRadius: '20px',
            border: '2px dashed #DEDEDE',
            overflow: 'auto',
            backgroundColor: '#F8F8F8',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& svg': {
                width: '50%',
                height: '50%',
                color: '#DEDEDE',
            },

            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#e0e0e0',

                '& svg': {
                    color: '#b9b9b9',
                },
            },
        },
        rootTableRow: {
            borderLeft: '6px solid',
        },
        content: {
            paddingBottom: theme.spacing(1.5),
            overflow: 'auto',
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
            '& .hoverEffect': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 0, 0, 0.85)',
                opacity: '0',
                zIndex: '-1',
                transition: 'opacity 0.3s ease-in-out',

                '& svg': {
                    color: '#FFFFFF',
                    width: '4rem',
                    height: '4rem',
                },
            },
            ' & .disabledHoverEffect': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: '#f2f2f2',
                opacity: '0.5',
                filter: 'blur(2px)',
                pointerEvents: 'none',
            },
            '&:hover .hoverEffect': {
                opacity: '1',
                zIndex: '1',
            },
        },
        contentImage: {
            display: 'flex',
            justifyContent: 'center',
            background: '#FFFF',
            '& img': {
                width: '100%',
                height: theme.spacing(18),
                objectFit: 'cover',
                [theme.breakpoints.up('xl')]: {
                    height: theme.spacing(20),
                },
            },
        },

        body: {
            height: theme.spacing(9),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: theme.spacing(1),
            [theme.breakpoints.up('xl')]: {
                marginTop: theme.spacing(1.5),
                height: theme.spacing(10),
            },
            padding: theme.spacing(1, 1.5),
        },

        timeInfo: {
            border: 'initial',
        },
        title: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: theme.spacing(2),

            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            // whiteSpace: 'pre-wrap',
        },

        description: {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 5,
            WebkitBoxOrient: 'vertical',
            fontWeight: 400,
            fontSize: theme.spacing(1.5),
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(1.75),
            },
        },
        statistics: {
            fontWeight: 400,
            margin: theme.spacing(0.5, 0),
            fontSize: theme.spacing(1.5),
            [theme.breakpoints.up('xl')]: {
                margin: theme.spacing(1, 0),
                fontSize: theme.spacing(1.75),
            },
        },
        label: {
            fontSize: theme.spacing(1.25),
            [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(1.5),
            },
        },
        divider: {
            borderTop: '1px solid #DEDEDE',
        },

        footer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#FFFFFF',
            padding: theme.spacing(1.5),
        },
        ml12px: {
            marginLeft: theme.spacing(1.5),
        },
        fg1: {
            flexGrow: 1,
        },
        assetsIcons: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.2),
            '& svg': {
                backgroundColor: '#E2E2E2',
                borderRadius: '50%',
                padding: theme.spacing(1),
                transform: 'scale(0.8)',
            },
        },
    };
});

export default useStylesAvailabilityCard;
