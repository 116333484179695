import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openNestedDialog } from '../../../redux/slices/nestedDialogSlice';
import ContactEdit from '../../../app/contacts/contactForm/ContactEdit';


const EditContactAction = ({ payload, onComplete }) => {
    const dispatch = useDispatch();
    useEffect(() => dispatch(
        openNestedDialog(<ContactEdit contact={payload} onSubmit={onComplete} onCancel={() => onComplete(null)}/>)
        // eslint-disable-next-line
    ), []);
};

export default EditContactAction;
