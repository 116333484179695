import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import searchApi from 'api/search';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        pending: false,
        savePending: false,
        products: [],
        productsOptions: [],
        totalProducts: 0,
        error: null,
        hasMore: true,
        pendingProductById: false,
        shouldRefreshProducts: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startFetchProductById: (state, action) => {
            state.pendingProductById = true;
        },
        stopFetchProductById: (state, action) => {
            state.pendingProductById = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setProducts: (state, action) => {
            state.pending = false;
            state.products = [...state.products.slice(0, action.payload.offset), ...action.payload.data];
            if (action.payload.data.length < 20) {
                state.hasMore = false;
            } else {
                state.hasMore = true;
            }
        },
        setProductsList: (state, action) => {
            state.pending = false;
            state.products = [...action.payload];
        },
        setError: (state, action) => {
            state.pending = false;
            state.products = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        addProduct: (state, action) => {
            state.savePending = false;
            state.products = [...state.products, action.payload];
        },
        editProduct: (state, action) => {
            state.savePending = false;
            // const index = state.products.findIndex((product) => (
            //     product.id === action.payload.currentId ||
            //     (product.variantGroupId && product.variantGroupId === action.payload.currentId))
            // );
            // if (index !== -1) {
            //     state.products = [
            //         ...state.products.slice(0, index),
            //         action.payload.productUpdated,
            //         ...state.products.slice(index + 1),
            //     ];
            // }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.productId = action.payload;
        },
        deleteProduct: (state, action) => {
            state.deletePending = false;
            // const index = state.products.findIndex((product) => (product.id === action.payload.id || product.id === action.payload.variantGroupId));
            // if (index !== -1) {
            //     state.products = [...state.products.slice(0, index), ...state.products.slice(index + 1)];
            // }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        setTotalProducts: (state, action) => {
            state.totalProducts = action.payload;
        },
        setProductsOptions: (state, action) => {
            state.productsOptions = action.payload;
        },
        resetProducts: (state, action) => {
            state.products = [];
            state.productsOptions = [];
            state.totalProducts = 0;
        },
        requestProductRefresh: (state) => {
            state.shouldRefreshProducts = true;
        },
        completeProductRefresh: (state) => {
            state.shouldRefreshProducts = false;
        },
    },
});

export const {
    start,
    stop,
    setProducts,
    setTotalProducts,
    setProductsList,
    setProductsOptions,
    setError,
    startSave,
    addProduct,
    editProduct,
    setSaveError,
    startDelete,
    deleteProduct,
    setDeleteError,
    resetProducts,
    startFetchProductById,
    stopFetchProductById,
    requestProductRefresh,
    completeProductRefresh,
} = productSlice.actions;

export const getProducts = (state) => state.product.products;
export const getProductsOptions = (state) => state.product.productsOptions;
export const getProductsPending = (state) => state.product.pending;
export const getProductsSavePending = (state) => state.product.savePending;
export const getProductsDeletePending = (state) => state.product.deletePending;
export const getProductsError = (state) => state.product.error;
export const getProductId = (state) => state.product.productId;
export const hasMoreProducts = (state) => state.product.hasMore;
export const getTotalProducts = (state) => state.product.totalProducts;
export const getProductByIdPending = (state) => state.product.pendingProductById;
export const getShouldRefresh = ((state) => state.product.shouldRefreshProducts);

export const fetchProductsAction = (queryParams, onSuccess) => async (dispatch) => {
    try {
        if (queryParams) {
            if (queryParams.offset === 0) {
                dispatch(
                    setProducts({
                        // reset products
                        data: [],
                        offset: 0,
                    }),
                );
            }
        }
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('products' + query)
            .then((response) => {
                dispatch(
                    setProducts({
                        data: response.data,
                        offset: queryParams.offset ? queryParams.offset : 0,
                    }),
                );
                if (onSuccess) {
                    onSuccess(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchProductsV2Action = (searchIndex, offset, queryParams) => async (dispatch) => {
    try {
        if (offset === 0) {
            dispatch(
                setProducts({
                    // reset products
                    data: [],
                    offset: 0,
                }),
            );
        }
        dispatch(start());
        searchApi
            .search(searchIndex, queryParams)
            .then((response) => {
                dispatch(
                    setProducts({
                        data: response.data.results.map((product) => ({ ...product, type: 'product' })), //temporary hack to know that a asset is a product when instantSearch is enabled
                        offset: offset ? offset : 0,
                    }),
                );
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchProductsByIdsAction = (ids) => async (dispatch) => {
    try {
        dispatch(
            setProducts({
                data: [],
                offset: 0,
            }),
        );
        dispatch(start());

        const productsFetch = ids.map((id) => api.get('products/' + id));

        Promise.all(productsFetch)
            .then((responses) => {
                const products = responses.map((resp) => resp.data);
                dispatch(
                    setProducts({
                        data: products,
                        offset: 0,
                    }),
                );
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchProductsListAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('products' + query)
            .then((response) => {
                dispatch(setProductsList(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchProductsListV2Action = (searchIndex, queryParams) => async (dispatch) => {
    try {
        dispatch(start());
        searchApi
            .search(searchIndex, queryParams)
            .then((response) => {
                dispatch(setProductsList(response.data.results));
                dispatch(setTotalProducts(response.data.total_found));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchTotalProductsAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        api.get('products/total' + query)
            .then((response) => {
                dispatch(setTotalProducts(+response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchProductsOptionsAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        api.get('product-options/name-suggestions' + query)
            .then((response) => {
                dispatch(setProductsOptions(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const addProductAction = (productDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post('products', productDto)
            .then((response) => {
                dispatch(addProduct(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const editProductAction = (productId, productDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('products/' + productId, productDto)
            .then((response) => {
                // pass the current product id because when the user updates the external id,
                // the returned updated product may be reused, so the id and the returned externalId may not be found in the current list of products
                dispatch(editProduct({ currentId: productId, productUpdated: response.data }));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const deleteProductAction = (productId, success) => async (dispatch) => {
    try {
        dispatch(startDelete(productId));
        api.delete('products/' + productId)
            .then((response) => {
                dispatch(deleteProduct(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const syncCloudStoreProductsAction = (organizationId, callback) => async (dispatch) => {
    try {
        dispatch(start());
        api.post('cloudstore/sync-request/' + organizationId)
            .then((response) => {
                dispatch(stop());
                if (callback) {
                    callback('success');
                }
                return response;
            })
            .catch((error) => {
                if (callback) {
                    callback('error');
                }
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const syncWoocommerceProductsAction = (organizationId, callback) => async (dispatch) => {
    try {
        dispatch(start());
        api.post('woocommerce/sync-request/' + organizationId)
            .then((response) => {
                dispatch(stop());
                if (callback) {
                    callback('success');
                }
                return response;
            })
            .catch((error) => {
                if (callback) {
                    callback('error');
                }
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const getProductAction = (productId, success) => async (dispatch) => {
    try {
        dispatch(startFetchProductById());
        api.get('products/' + productId)
            .then(({ data: product }) => {
                dispatch(stopFetchProductById());
                if (success) {
                    success(product);
                }
                return product;
            })
            .catch((error) => {
                dispatch(stopFetchProductById());
                return console.error('error', error);
            });
    } catch (e) {
        dispatch(stopFetchProductById());
        return console.error('error', e);
    }
};

export const getExternalProductAction = (productId, success) => async (dispatch) => {
    try {
        dispatch(startFetchProductById());
        api.get('products/external/' + productId)
            .then(({ data: product }) => {
                dispatch(stopFetchProductById());
                if (success) {
                    success(product);
                }
                return product;
            })
            .catch((error) => {
                dispatch(stopFetchProductById());
                return console.error('error', error);
            });
    } catch (e) {
        dispatch(stopFetchProductById());
        return console.error('error', e);
    }
};

export const fetchProductFieldValues =
    ({ field, onSuccess, onFailure } = {}) =>
    async () => {
        try {
            api.get('products/values/' + field)
                .then((response) => {
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    onFailure?.(error);
                });
        } catch (e) {
            onFailure?.();
            return console.error(e.message);
        }
    };

export default productSlice.reducer;
