import React, { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useFlexBoxStyles from 'common/flexBox';

const LazySuspense = ({ children, fallback, withLoader }) => {
    const { classes: flexBox, cx } = useFlexBoxStyles();

    const fb = fallback ||
        (withLoader && (
            <div className={cx(flexBox.flex, flexBox.w100, flexBox.justifyContentCenter)}>
                <CircularProgress size={24} />
            </div>
        )) || <div />;

    return <Suspense fallback={fb}>{children}</Suspense>;
};

export default LazySuspense;
