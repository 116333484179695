import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import gatewayApi from 'api/gateway';
import EventSortaleFields from 'common/enums/eventSortableFields';
import { StatusType } from 'common/enums/statusEnum';
import CalendarNotificationService from 'services/CalendarNotificationService';
import { setNotesOpenId } from './appSlice';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        count: 0,
        total: 0,
        events: [],
        currentPage: 1,
        totalPages: 1,
        highlightEvent: null,
        pending: false,
        savePending: false,
        error: null,
        upcomingPending: false,
        upcomingCount: 0,
        upcomingTotal: 0,
        upcomingEvents: [],
        upcomingCurrentPage: 1,
        upcomingTotalPages: 1,
        eventDetailsDialog: null,
        instantEventDialog: null,
        queryParams: null,
        deletePending: false,
        finalizePending: false,
        eventId: null,
        highlightEventPending: false,
        expandAccordionEdited: '',
        editIsPublicStatusPending: false,
        pendingNoteSave: false,
        patching: false,
        pendingDownload: false,
        pendingTvChannels: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startDownload: (state, action) => {
            state.pendingDownload = true;
        },
        stopDownload: (state, action) => {
            state.pendingDownload = false;
        },
        setEvents: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.events = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setUpcomingEvents: (state, action) => {
            state.upcomingPending = false;
            state.upcomingCount = action.payload.count;
            state.upcomingTotal = action.payload.total;
            state.upcomingEvents = action.payload.items;
            state.upcomingCurrentPage = action.payload.currentPage;
            state.upcomingTotalPages = action.payload.totalPages;
        },
        setTotalEvents: (state, action) => {
            state.totalEvents = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.pendingDownload = false;
            state.events = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addEvent: (state, action) => {
            state.savePending = false;
        },
        editEvent: (state, action) => {
            state.pendingNoteSave = false;
            state.patching = false;
            state.savePending = false;
            const index = state.events.findIndex((event) => event.id === action.payload.id);
            if (index !== -1) {
                state.events[index] = { ...state.events[index], ...action.payload };
            }
        },
        editUpcomingEvent: (state, action) => {
            state.pendingNoteSave = false;
            state.patching = false;
            state.savePending = false;
            const index = state.upcomingEvents.findIndex((event) => event.id === action.payload.id);
            if (index !== -1) {
                state.upcomingEvents = [
                    ...state.upcomingEvents.slice(0, index),
                    action.payload,
                    ...state.upcomingEvents.slice(index + 1),
                ];
            }
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.eventId = action.payload;
        },
        deleteEvent: (state, action) => {
            state.deletePending = false;
            const index = state.events.findIndex((event) => event.id === action.payload.id);
            if (index !== -1) {
                state.events = [...state.events.slice(0, index), ...state.events.slice(index + 1)];
            }
        },
        deleteUpcomingEvent: (state, action) => {
            state.deletePending = false;
            const index = state.upcomingEvents.findIndex((event) => event.id === action.payload.id);
            if (index !== -1) {
                state.upcomingEvents = [
                    ...state.upcomingEvents.slice(0, index),
                    ...state.upcomingEvents.slice(index + 1),
                ];
            }
        },
        setDeleteError: (state, action) => {
            state.deletePending = false;
            state.error = action.payload;
        },
        startUpcoming: (state, action) => {
            state.upcomingPending = true;
        },
        stopUpcoming: (state, action) => {
            state.upcomingPending = false;
        },
        openEventDetailsDialog: (state, action) => {
            state.eventDetailsDialog = action.payload;
        },
        openInstantEvent: (state, action) => {
            state.instantEventDialog = action.payload;
        },
        setQueryParams: (state, action) => {
            state.queryParams = action.payload;
        },
        startFinalize: (state, action) => {
            state.finalizePending = true;
            state.eventId = action.payload;
        },
        stopFinalize: (state, action) => {
            state.finalizePending = false;
            state.eventId = null;
        },
        finalizeEvent: (state, action) => {
            state.finalizePending = false;
            const index = state.events.findIndex((event) => event.id === action.payload.id);
            if (index !== -1) {
                state.events = [
                    ...state.events.slice(0, index),
                    {
                        ...state.events[index],
                        status: 'finalized',
                    },
                    ...state.events.slice(index + 1),
                ];
            }

            const upcomingIndex = state.upcomingEvents.findIndex((event) => event.id === action.payload.id);
            if (upcomingIndex !== -1) {
                state.upcomingEvents = [
                    ...state.upcomingEvents.slice(0, upcomingIndex),
                    {
                        ...state.upcomingEvents[upcomingIndex],
                        status: 'finalized',
                    },
                    ...state.upcomingEvents.slice(upcomingIndex + 1),
                ];
            }
        },
        setFinalizeError: (state, action) => {
            state.finalizePending = false;
            state.error = action.payload;
        },
        startHighlightEvent: (state, action) => {
            state.highlightEventPending = true;
        },
        stopHighlightEvent: (state, action) => {
            state.highlightEventPending = false;
        },
        setExpandAccordionEdited: (state, action) => {
            state.expandAccordionEdited = action.payload;
        },
        setHighlightEvent: (state, action) => {
            state.highlightEventPending = false;
            state.highlightEvent = action.payload;
        },
        startEditIsPublicStatus: (state, action) => {
            state.editIsPublicStatusPending = true;
        },
        stopEditIsPublicStatus: (state, action) => {
            state.editIsPublicStatusPending = false;
        },
        startNoteSave: (state, action) => {
            state.pendingNoteSave = true;
        },
        stopNoteSave: (state, action) => {
            state.pendingNoteSave = false;
        },
        startPatching: (state, action) => {
            state.patching = true;
        },
        stopPatching: (state, action) => {
            state.patching = false;
        },
        setPendingTvChannels: (state, action) => {
            state.pendingTvChannels = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setEvents,
    setTotalEvents,
    setError,
    startSave,
    stopSave,
    addEvent,
    editEvent,
    setSaveError,
    startDelete,
    deleteEvent,
    deleteUpcomingEvent,
    editUpcomingEvent,
    setDeleteError,
    startUpcoming,
    setUpcomingEvents,
    setHighlightEvent,
    openEventDetailsDialog,
    openInstantEvent,
    setQueryParams,
    startFinalize,
    finalizeEvent,
    setFinalizeError,
    stopFinalize,
    startHighlightEvent,
    stopHighlightEvent,
    stopUpcoming,
    setExpandAccordionEdited,
    startEditIsPublicStatus,
    stopEditIsPublicStatus,
    startNoteSave,
    stopNoteSave,
    startPatching,
    stopPatching,
    startDownload,
    stopDownload,
    setPendingTvChannels,
} = eventSlice.actions;

export const getEventsCount = (state) => state.event.count;
export const getEventsTotal = (state) => state.event.total;
export const getEvents = (state) => state.event.events;
export const getEventsCurrentPage = (state) => state.event.currentPage;
export const getEventsTotalPages = (state) => state.event.totalPages;
export const getEventsPending = (state) => state.event.pending;
export const getExpandAccordionEdited = (state) => state.event.expandAccordionEdited;
export const getEventsSavePending = (state) => state.event.savePending;
export const getEventsDeletePending = (state) => state.event.deletePending;
export const getEventsFinalizePending = (state) => state.event.finalizePending;
export const getEventsError = (state) => state.event.error;
export const getEventId = (state) => state.event.eventId;
export const getUpcomingPending = (state) => state.event.upcomingPending;
export const getUpcomingEventsCount = (state) => state.event.upcomingCount;
export const getUpcomingEventsTotal = (state) => state.event.upcomingTotal;
export const getUpcomingEvents = (state) => state.event.upcomingEvents;
export const getUpcomingCurrentPage = (state) => state.event.upcomingCurrentPage;
export const getUpcomingTotalPages = (state) => state.event.upcomingTotalPages;
export const getHighlightEvent = (state) => state.event.highlightEvent;
export const getOpenedEventDetails = (state) => state.event.eventDetailsDialog;
export const getOpenedInstantEvent = (state) => state.event.instantEventDialog;
export const getQueryParams = (state) => state.statistics.queryParams;
export const getHighlightEventPending = (state) => state.event.highlightEventPending;
export const getEditIsPublicStatusPending = (state) => state.event.editIsPublicStatusPending;
export const getNoteSavePending = (state) => state.event.pendingNoteSave;
export const getPendingDownload = (state) => state.event.pendingDownload;
export const getPendingTvChannels = (state) => state.event.pendingTvChannels;

export const fetchEventsAction = (queryParams, success) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('events' + query)
            .then((response) => {
                if (success) {
                    success?.(response.data.items);
                } else {
                    dispatch(setEvents(response.data));
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const fetchUpcomingEventsAction = (queryParams) => async (dispatch) => {
    try {
        const params = {
            ...queryParams,
            sort: `${EventSortaleFields.RELEASE_DATE} ASC`,
            status: [
                StatusType.PENDING.name,
                StatusType.RUNNING.name,
                StatusType.PAUSED.name,
                StatusType.INITIALIZED.name,
            ],
        };
        let query = transformFiltersToQuery(params);
        dispatch(startUpcoming());
        api.get('events' + query)
            .then((response) => {
                dispatch(setUpcomingEvents(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stopUpcoming());
        return console.error(e.message);
    }
};

export const fetchUpcomingTvChannelEventsAction = (queryParams) => async (dispatch, getState) => {
    try {
        const state = getState();
        const params = {
            ...queryParams,
            sort: `${EventSortaleFields.RELEASE_DATE} ASC`,
            status: [
                StatusType.PENDING.name,
                StatusType.RUNNING.name,
                StatusType.PAUSED.name,
                StatusType.INITIALIZED.name,
            ],
        };
        let query = transformFiltersToQuery(params);
        dispatch(startUpcoming());
        api.get(`events/upcoming/${state.organization.organization.id}` + query)
            .then((response) => {
                dispatch(setUpcomingEvents(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stopUpcoming());
        return console.error(e.message);
    }
};

export const fetchHighlightEventAction = (queryParams) => async (dispatch, getState) => {
    try {
        const state = getState();
        const params = {
            ...queryParams,
        };
        let query = transformFiltersToQuery(params);
        dispatch(startHighlightEvent());
        api.get(`events/highlight/${state.organization.organization.id}` + query)
            .then((response) => {
                dispatch(setHighlightEvent(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                dispatch(stopHighlightEvent());
            });
    } catch (e) {
        dispatch(stopHighlightEvent());
        return console.error(e.message);
    }
};

export const addEventAction = (eventDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        if (eventDto.type === 'one_to_one') {
            api.post('events/create-room', eventDto)
                .then((response) => {
                    dispatch(stopSave());
                    if (success) {
                        success(response.data);
                    }
                    CalendarNotificationService.addNewEvent(response.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setSaveError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        } else {
            api.post('events', eventDto)
                .then((response) => {
                    dispatch(stopSave());
                    dispatch(openInstantEvent(response.data));

                    if (success) {
                        success(response.data);
                    }
                    CalendarNotificationService.addNewEvent(response.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setSaveError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        }
    } catch (e) {
        dispatch(stopSave());
        return console.error(e.message);
    }
};

export const updateExtraFields =
    ({ eventId, extraFieldsDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.post('events/extra-fields/' + eventId, extraFieldsDto)
                .then((response) => {
                    onSuccess();
                    dispatch(editEvent(response.data));
                    return response;
                })
                .catch((error) => {
                    onFailure(error);
                    return error;
                });
        } catch (e) {
            return console.error('error', e);
        }
    };

export const editEventAction = (eventId, eventDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('events/' + eventId, eventDto)
            .then((response) => {
                dispatch(editEvent(response.data));
                dispatch(editUpcomingEvent(response.data));
                if (success) {
                    success(response.data);
                }
                CalendarNotificationService.updateEvent({
                    eventId: response.data.id,
                    releaseDate: response.data.releaseDate,
                    name: response.data.name,
                    publicUrl: response.data.publicUrl,
                    publicId: response.data.publicId,
                    description: response.data.description,
                });
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error(e.message);
    }
};

export const editEventNotesAction = (eventId, notes, success, failure) => async (dispatch) => {
    try {
        dispatch(startNoteSave());
        api.put('events/notes/' + eventId, notes)
            .then((response) => {
                dispatch(editEvent(response.data));
                dispatch(editUpcomingEvent(response.data));
                dispatch(setNotesOpenId(null));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                dispatch(stopNoteSave());
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopNoteSave());
        return console.error(e.message);
    }
};

export const editIsPublicStatusEventAction =
    ({ id, data, success }) =>
    async (dispatch) => {
        try {
            dispatch(startEditIsPublicStatus());
            api.put('events/public-status/' + id, data)
                .then((response) => {
                    dispatch(editEvent(response.data));
                    dispatch(editUpcomingEvent(response.data));
                    dispatch(stopEditIsPublicStatus());
                    if (success) {
                        success(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                });
        } catch (e) {
            dispatch(stopEditIsPublicStatus());
            return console.error(e.message);
        }
    };

export const editEventCategoryAction = (eventId, categories, success, failure) => async (dispatch) => {
    try {
        dispatch(startPatching());
        api.patch('events/categories/' + eventId, categories)
            .then((response) => {
                dispatch(editEvent(response.data));
                dispatch(editUpcomingEvent(response.data));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                dispatch(stopNoteSave());
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopNoteSave());
        return console.error(e.message);
    }
};

export const editEventResultAction = (eventId, result, success, failure) => async (dispatch) => {
    try {
        dispatch(startPatching());
        api.patch('events/result/' + eventId, result)
            .then((response) => {
                dispatch(editEvent(response.data));
                dispatch(editUpcomingEvent(response.data));
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                dispatch(stopNoteSave());
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopNoteSave());
        return console.error(e.message);
    }
};

export const deleteEventAction = (eventId, onSuccess) => async (dispatch) => {
    try {
        dispatch(startDelete(eventId));
        api.delete('events/' + eventId)
            .then((response) => {
                dispatch(deleteEvent(response.data));
                dispatch(deleteUpcomingEvent(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                CalendarNotificationService.deleteEvent(eventId);
                return response;
            })
            .catch((error) => {
                dispatch(setDeleteError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const finalizeEventAction =
    (eventId, onSuccessFn = null) =>
    async (dispatch) => {
        try {
            dispatch(startFinalize(eventId));
            api.put('events/finalize/' + eventId)
                .then((response) => {
                    dispatch(finalizeEvent(response.data));
                    !!onSuccessFn && onSuccessFn(response.data);
                    CalendarNotificationService.deleteEvent(eventId);
                    return response;
                })
                .catch((error) => {
                    dispatch(setFinalizeError(error));
                });
        } catch (e) {
            dispatch(stopFinalize());
            return console.error(e.message);
        }
    };

export const closeEventDetailsAction = () => async (dispatch) => {
    dispatch(openEventDetailsDialog(null));
};

export const closeInstantEventAction = () => async (dispatch) => {
    dispatch(openInstantEvent(null));
};

export const fetchICallsCsvAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(startDownload());
            api.get(`events/export-icalls-csv/${query}`)
                .then((response) => {
                    if (onSuccess) {
                        const fileName = response.headers['content-disposition'].split('filename=')[1];
                        onSuccess(response.data, fileName);
                    }
                    dispatch(stopDownload());
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopDownload());
            return console.error(e.message);
        }
    };

export const fetchO2OCsvReportAction =
    ({ param, queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            const { reportType, organizationId } = param;
            dispatch(startDownload());
            api.get(`${process.env.REACT_APP_API_URL}/auth/cube-token`)
                .then((data) => {
                    return gatewayApi
                        .get(`report/one-to-one-${reportType}/${organizationId}`, {
                            params: queryParams,
                            headers: { 'Cube-Authorization': data.data.token },
                        })
                        .then((response) => {
                            if (onSuccess) {
                                const fileName = response.headers['content-disposition'].split('filename=')[1];
                                onSuccess(response.data, fileName);
                            }
                            dispatch(stopDownload());
                            return response;
                        })
                        .catch((error) => {
                            if (onFailure) {
                                onFailure(error);
                            }
                        });
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopDownload());
            return console.error(e.message);
        }
    };

export const fetchO2MCsvUsersRegister =
    ({ eventId, queryParams, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            gatewayApi
                .get(`event/usersRegister/${eventId}`, {
                    params: queryParams,
                })
                .then((response) => {
                    if (onSuccess) {
                        const tzName = queryParams ? queryParams?.timezone.replace(/\//g, '_') : '';
                        const HEADER_KEY_CONTENT_DISPOSITION = 'content-disposition';
                        // Must match with the url returned by onlive-api endpoint: actors/register/event/:eventId
                        // and match the filename given in the onlive-serverless-api.
                        // `${eventId}-register.csv`
                        let fileName = `${eventId}-register${tzName ? `-${tzName}` : ''}`;

                        if (Object.keys(response.headers).includes(HEADER_KEY_CONTENT_DISPOSITION)) {
                            fileName = response.headers[HEADER_KEY_CONTENT_DISPOSITION].split('filename=')[1];
                        }

                        onSuccess(response.data, fileName);
                    }
                    return response;
                })
                .catch((error) => {
                    console.error('Error downloading CSV file of viewers registrations.');
                    console.error(error);
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export const eventTvChannelsAction =
    ({ id, data, success, failure }) =>
    async (dispatch) => {
        try {
            dispatch(setPendingTvChannels(true));
            api.put('events/channels/' + id, data)
                .then((response) => {
                    dispatch(setPendingTvChannels(false));
                    dispatch(editEvent(response.data));
                    dispatch(editUpcomingEvent(response.data));
                    success(response.data);
                    return response;
                })
                .catch(() => {
                    failure();
                    dispatch(setPendingTvChannels(false));
                });
        } catch (e) {
            failure();
            dispatch(setPendingTvChannels(false));
            return console.error(e.message);
        }
    };

export default eventSlice.reducer;
