import { makeStyles } from 'tss-react/mui';

const useStylesOCalendar = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary.main,
            flexGrow: 1,
            overflow: 'hidden',
        },
        scroll: {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 32px)',
            width: '100%',
            overflowX: 'hidden',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        body: {
            padding: theme.spacing(1, 7),
        },
        calendarContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minHeight: '600px',
        },
        tabContainer: {
            backgroundColor: theme.palette.default.main,
            borderRadius: '0.5rem',
            flexGrow: 1,
            padding: theme.spacing(2.5),
            paddingBottom: 0,
            paddingTop: theme.spacing(1.5),
            display: 'flex',
            flexDirection: 'column',
        },        
        hoursPicker: {
            padding: '.3rem',
        },
        checkBox: {
            '& svg': {
                color: '#848484 !important',
                fontSize: '20px !important',
            },
        },
        textUndefined: {
            display: 'flex',
            justifyContent: 'center',
            opacity: '.5',
            padding: '0.8rem',
        },
        dateOverrideGridContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        dateOverrideTitle: {
            marginBlockStart: '0 !important',
            marginBlockEnd: '1em !important',
        },
        dateOverrideDialogContent: {
            display: 'flex',
            alignItems: 'center',
        },
        dateOverrideHourContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 1rem',
        },
        dateOverridePlusBtn: {
            alignSelf: 'baseline',
        },
        listItemDateOverride: {
            padding: '0 0 5px',
            fontSize: '.8rem',
        },
        dividerDateOverride: {
            padding: '0 .2rem',
        },
        listItemDateOverrideContent: {
            display: 'flex',
            alignItems: 'center',
        },
        listItemDateOverrideContentHours: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        listItemDateOverrideContentBtn: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        calendarLoader: {
            display: 'flex',
            padding: '2rem',
            justifyContent: 'center',
            alignItems: 'center',
        },
        displayFlex: {
            display: 'flex',
        },
        paddingY1: {
            padding: '1rem 0',
        },
        buttonSave: {
            width: '25%',
            maxWidth: '30%',
            alignSelf: 'end',
        },
        timePicker: {
            '& .MuiSvgIcon-root': {
                fontSize: `${theme.spacing(2)} !important`,
            },
        },
        pt0: {
            paddingTop: '0 !important',
        },
        deleteTime: {
            display: 'flex',
            alignItems: 'end',
        },
        titleAction: {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                flex: '1 1',
            }
        },
        customDialogWidth: {
            '& .MuiDialog-paper': {
                width: 'auto',
                minWidth: '35%',
            }
        }
    };
});

export default useStylesOCalendar;
