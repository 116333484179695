import * as moment from 'moment-timezone';

export const transformKey = (key) => {
    return key
        ?.replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
};
/**
 * Transforms an object label based on the provided fields, language, and translation function.
 *
 * @param {object} extra - The extra object containing field values.
 * @param {array} fields - The array of field objects.
 * @param {string} lang - The language code.
 * @param {function} t - The translation function.
 * @param {object} defaultFields - contact default fields (name, email, phone, status, subStatus).
 * @return {object} The transformed object label.
 */
export const transformObjectLabel = (extra, fields, lang, t, defaultFields) => {
    const labelKeyMap = new Map();

    return fields?.reduce((obj, field, idx) => {
        // jump to next iteration if field is not active
        if (!field?.active) return obj;

        let label = field?.label?.find((l) => l.languageCode === lang)?.value || field?.label?.[0]?.value || field.name;

        let value = '';
        const fieldType = field?.type?.toLowerCase().trim();

        if (fieldType === 'select') {
            if (field?.multiple && Array.isArray(extra?.[field.name])) {
                const selectedValues = extra?.[field.name]?.map((v) => v.toLowerCase().trim());

                const values = [];
                field?.options?.forEach((o) => {
                    if (selectedValues.includes(o.value?.toLowerCase().trim())) {
                        values.push(o.label?.find((l) => l.languageCode === lang)?.value || '');
                    }
                });
                value = values.join(', ');
            } else {
                value =
                    field?.options
                        ?.find((o) => o.value?.toLowerCase().trim() === extra?.[field.name]?.toLowerCase().trim())
                        ?.label?.find((l) => l.languageCode === lang)?.value || '';
            }
        } else if (fieldType === 'date' && field?.isDateTime && extra?.[field.name]) {
            const timezone = moment.tz.guess();
            value = moment(extra?.[field.name]).tz(timezone).format('DD/MM/YYYY HH:mm');
        } else {
            value = extra?.[field.name] === false ? false : extra?.[field.name] || defaultFields?.[field.name] || '';
        }

        // Handle duplicate labels
        if (labelKeyMap.has(label)) {
            label = `${label}.`;
            labelKeyMap.set(label, field.name);
        } else {
            labelKeyMap.set(label, field.name);
        }

        return {
            ...obj,
            [label]: value === true ? t('app.common.yes') : value === false ? t('app.common.no') : value,
        };
    }, {});
};

export const renderFields = (object) => {
    let html = `<ul 
        style="
        font-size: 12px;
        overflow: hidden;
        padding-inline-start: 0px;
        list-style-type: none; 
        margin-block-start: 0;
        margin-block-end: 0;
        ">`;
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            if (typeof value === 'object') {
                html += `<li style="padding-left: 20px; padding-bottom: 8px;"><span style="font-weight: 500;">${transformKey(
                    key,
                )}:</span>`;
                html += renderFields(value);
                html += '</li>';
            } else {
                html += `<li style="padding-bottom: 8px;"><span><span style="font-weight: 500;">${transformKey(
                    key,
                )}:</span> ${value}</span></li>`;
            }
        }
    }

    html += '</ul>';
    return html;
};