import { Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import useStylesOCalendar from '../../../ocalendar/styles/useStylesOCalendar.styles';

const ListDateOverride = (props) => {
    const { datesOverride, onDeleteDate, timeZone } = props;
    const { classes } = useStylesOCalendar();
    const { i18n } = useTranslation();

    moment.locale(i18n.language);

    return (
        <div>
            {datesOverride.map((d, index) => {
                return (
                    <Grid container spacing={1} key={index} className={classes.listItemDateOverride}>
                        <Grid item md={4} className={classes.listItemDateOverrideContent}>
                            {moment.tz(`${d.date} 00:00`, timeZone).format('LL')}
                        </Grid>
                        <Grid item md={6} className={classes.listItemDateOverrideContentHours}>
                            {d.hours.map((h, index) => {
                                return (
                                    <div key={index}>
                                        {h.startTime !== null
                                            ? moment.tz(`${d.date} ${h.startTime}`, timeZone).format('LT')
                                            : ''}
                                        <span className={classes.dividerDateOverride}>-</span>
                                        {h.endTime !== null
                                            ? moment.tz(`${d.date} ${h.endTime}`, timeZone).format('LT')
                                            : ''}
                                    </div>
                                );
                            })}
                        </Grid>
                        <Grid item md={2} className={classes.listItemDateOverrideContentBtn}>
                            <IconButton
                                aria-label="delete"
                                className={classes.margin}
                                onClick={() => onDeleteDate(index)}
                                size="large"
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })}
        </div>
    );
};

export default ListDateOverride;
