import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getUser } from 'redux/slices/authSlice';
import { fetchSettingsAction } from 'redux/slices/settingSlice';
import useStylesLayout from './styles/useStylesLayout.styles';

const Router = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const { classes } = useStylesLayout();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (user?.organizationId) {
            dispatch(fetchSettingsAction());
        }
        if (pathname === '/') {
            navigate('/loading');
        }
        if (!user?.organizationId) {
            navigate('/login');
        }
    }, [pathname, dispatch, user?.organizationId, navigate]);

    return (
        <div className={classes.contentArea}>
            <Outlet />
        </div>
    );
};

export default Router;
