import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const contactRequestSlice = createSlice({
    name: 'contactRequest',
    initialState: {
        contactRequests: [],
        error: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 1,
        pending: false,
        savePending: false,
        deletePending: false,
        hasMore: true,
        allContactRequests: [],
        pendingAllContactRequests: [],
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startAll: (state, action) => {
            state.pendingAllContactRequests = true;
        },
        stopAll: (state, action) => {
            state.pendingAllContactRequests = false;
        },
        setContactRequest: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.contactRequests = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setAllContactRequests: (state, action) => {
            state.pendingAllContacts = false;
            state.allContactRequests = [...state.allContactRequests.slice(0, action.payload.offset), ...action.payload.items];
            if (action.payload.items.length < 20) {
                state.hasMore = false;
            } else {
                state.hasMore = true;
            }
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
        },
        deleteContactRequest: (state, action) => {
            state.pending = false;
            const index = state.contactRequests.findIndex((contactRequest) => contactRequest.id === action.payload.id);
            if (index !== -1) {
                state.contactRequests = [...state.contactRequests.slice(0, index), ...state.contactRequests.slice(index + 1)];
            }
        },
    },
});

export const {
    start,
    stop,
    setContactRequest,
    setError,
    setAllContactRequests,
    startAll,
    stopAll,
    startSave,
    stopSave,
    startDelete,
    deleteContactRequest,
} = contactRequestSlice.actions;

export const getContactRequests = (state) => state.contactRequest.contactRequests;
export const getAllContactRequests = (state) => state.contactRequest.allContactRequests;
export const getPendingAllContactRequests = (state) => state.contactRequest.pendingAllContactRequests;

export const getHasMore = (state) => state.contactRequest.hasMore;
export const getContactRequestsPending = (state) => state.contactRequest.pending;
export const getContactRequestsError = (state) => state.contactRequest.error;
export const getContactRequestsTotal = (state) => state.contactRequest.total;
export const getContactRequestsCurrentPage = (state) => state.contactRequest.currentPage;
export const getContactRequestsTotalPages = (state) => state.contactRequest.totalPages;

export const fetchContactRequestsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setContactRequest({
                            total: 0,
                            items: [],
                            count: 0,
                            currentPage: 1,
                            totalPages: 1,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('contact-requests' + query)
                .then((response) => {
                    dispatch(
                        setContactRequest(response.data),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchAllContactRequestsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setAllContactRequests({
                            items: [],
                            offset: 0,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(startAll());
            api.get('contact-requests' + query)
                .then((response) => {
                    dispatch(
                        setAllContactRequests({
                            ...response.data,
                            offset: queryParams.page ? (queryParams.page - 1) * 20 : 0,
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopAll());
            return console.error('error', e);
        }
    };

export const addContactRequestAction =
    ({ contactRequestDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('contact-requests', contactRequestDto)
                .then((response) => {
                    dispatch(
                        fetchContactRequestsAction({
                            queryParams: {
                                page: 1,
                            },
                        }),
                    );
                    if (onSuccess) {
                        onSuccess();
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const updateContactRequestAction =
    ({ contactRequestId, contactRequestDto, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            api.put('contact-requests/' + contactRequestId, contactRequestDto)
                .then((response) => {
                    dispatch(
                        fetchContactRequestsAction({
                            queryParams: {
                                page: 1,
                            },
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    dispatch(stopSave());
                    return response;
                })
                .catch((error) => {
                    dispatch(stopSave());
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const deleteContactRequestAction = (contactRequestId) => async (dispatch) => {
    try {
        api.delete('contact-requests/' + contactRequestId)
            .then((response) => {
                dispatch(deleteContactRequest(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export default contactRequestSlice.reducer;
