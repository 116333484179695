import { ArrowBack } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import { useTranslation } from 'react-i18next';

const LayoutSubForm = (props) => {
    const {
        children,
        sectionName,
        close,
        done,
        showCloseButton = true,
        showDoneButton = true,
        customClasses,
        disabled,
    } = props;

    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();

    return (
        <Stack direction="column" className={cx(flexBox.h100, flexBox.overflowHidden, flexBox.relative, customClasses)}>
            <Stack direction="row" alignItems="center" className={cx(flexBox.paddingBottom2, flexBox.gap1)}>
                <CustomTooltip arrow title={t('navigate.back')} aria-label="back-main-form" placement="bottom">
                    <CustomIconButton id="onlive-button-delete" onClick={close} hideBorder={true} size="small">
                        <ArrowBack component="svg" />
                    </CustomIconButton>
                </CustomTooltip>
                <Typography fontWeight={500}>{t('navigate.back')}</Typography>
                {sectionName ? <Typography fontWeight={'bold'}>{'/ ' + sectionName.toUpperCase()}</Typography> : null}
            </Stack>

            <Stack className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone, flexBox.h100)}>{children}</Stack>

            {(showCloseButton || showDoneButton) && (
                <Stack direction="row" justifyContent="flex-end" className={cx(flexBox.gap1, flexBox.paddingTop2)}>
                    {showCloseButton && (
                        <ActionButton onClick={close} color="default" fitWidth>
                            {t('multimedia.multimediaForm.cancel')}
                        </ActionButton>
                    )}

                    {showDoneButton && (
                        <ActionButton
                            color="primary"
                            type="submit"
                            autoFocus
                            fitWidth
                            onClick={done}
                            disabled={disabled}
                        >
                            {t('event.eventFormSelectAssets.save')}
                        </ActionButton>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default LayoutSubForm;
