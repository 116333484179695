import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const licenseSlice = createSlice({
    name: 'license',
    initialState: {
        pending: false,
        pendingAll: false,
        licenses: [],
        totalLicenses: 0,
        licensesCommon: [],
        allLicenses: [],
        customLicense: null,
        license: null,
        invitations: [],
        addOns: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startAll: (state, action) => {
            state.pendingAll = true;
        },
        stop: (state, action) => {
            state.pending = false;
            state.pendingAll = false;
        },
        addLicense: (state, action) => {
            state.savePending = false;
            state.licenses = [action.payload, ...state.licenses];
        },
        setLicenses: (state, action) => {
            state.pending = false;
            state.licenses = action.payload;
        },
        setAllLicenses: (state, action) => {
            state.pendingAll = false;
            state.allLicenses = action.payload;
        },
        setTotalLicenses: (state, action) => {
            state.pending = false;
            state.totalLicenses = action.payload;
        },
        setCustomLicense: (state, action) => {
            state.customLicense = action.payload;
        },
        setLicense: (state, action) => {
            state.license = action.payload;
        },
        setInvitations: (state, action) => {
            state.pending = false;
            state.invitations = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        editLicense: (state, action) => {
            state.savePending = false;
            const index = state.licenses.findIndex((license) => license.id === action.payload.id);
            if (index !== -1) {
                state.licenses = [
                    ...state.licenses.slice(0, index),
                    action.payload,
                    ...state.licenses.slice(index + 1),
                ];
            }
        },
        deleteLicense: (state, action) => {
            state.pending = false;
            const index = state.licenses.findIndex((license) => license.id === action.payload.id);
            if (index !== -1) {
                state.licenses = [...state.licenses.slice(0, index), ...state.licenses.slice(index + 1)];
            }
        },
        setLicensesCommon: (state, action) => {
            state.pending = false;
            state.licensesCommon = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    startAll,
    stop,
    setLicenses,
    setAllLicenses,
    setInvitations,
    setError,
    editLicense,
    deleteLicense,
    addLicense,
    setLicensesCommon,
    setCustomLicense,
    setLicense,
    startSave,
    stopSave,
    setSaveError,
    setTotalLicenses,
} = licenseSlice.actions;

export const getLicenses = (state) => state.licenses.licenses;
export const getAllLicenses = (state) => state.licenses.allLicenses;
export const getAddOns = (state) => state.licenses.addOns;
export const getInvitations = (state) => state.licenses.invitations;
export const getLicensesPending = (state) => state.licenses.pending;
export const getLicensesPendingAll = (state) => state.licenses.pendingAll;
export const getLicensesSavePending = (state) => state.licenses.savePending;
export const getAddOnsPending = (state) => state.licenses.pending;
export const getLicensesError = (state) => state.licenses.error;
export const getLicensesCommon = (state) => state.licenses.licensesCommon;
export const getTotalLicenses = (state) => state.licenses.totalLicenses;
export const getCustomLicense = (state) => state.licenses.customLicense;
export const getLicense = (state) => state.licenses.license;

export const fetchLicensesAction = (queryParams, callback) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        if (!queryParams) {
            dispatch(startAll());
        } else {
            dispatch(start());
        }
        api.get('licenses' + query)
            .then((response) => {
                if (!queryParams) {
                    dispatch(setAllLicenses(response.data));
                } else {
                    dispatch(setLicenses(response.data));
                }
                if (callback) {
                    callback(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const countLicensesAction = (queryParams, callback) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('licenses/count' + query)
            .then((response) => {
                dispatch(setTotalLicenses(response.data.total));
                if (callback) {
                    callback(response.data.total);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchOneLicenseAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            let query = transformFiltersToQuery({ pricingId: state.organization.organization.pricingId });
            dispatch(start());
            api.get(`licenses/${id}` + query)
                .then((response) => {
                    dispatch(setLicense(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }

                    dispatch(stop());
                    return response;
                })
                .catch((error) => {
                    dispatch(stop());
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchLicensesCommonAction =
    ({ organizationId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get(`licenses/available/${organizationId}`)
                .then((response) => {
                    dispatch(setLicensesCommon(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const getCustomerLicenseAction =
    ({ organizationId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            api.get(`licenses/organization/${organizationId}`)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const createLicenseAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('licenses', { ...data, price: Number(data.price) })
                .then((response) => {
                    dispatch(addLicense(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const editLicenseAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('licenses/' + id, { ...data, price: Number(data.price) })
                .then((response) => {
                    dispatch(editLicense(response.data));
                    if (onSuccess) {
                        onSuccess(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const inviteLicenseAction =
    ({ data, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('licenses/invite', data)
                .then((response) => {
                    dispatch(stopSave());
                    if (onSuccess) {
                        onSuccess(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteLicenseAction = (licenseId) => async (dispatch) => {
    try {
        dispatch(start(licenseId));
        api.delete('licenses/' + licenseId)
            .then((response) => {
                dispatch(deleteLicense(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default licenseSlice.reducer;
