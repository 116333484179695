import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import OAuthTransition from 'views/OAuthTransition';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function SocialOAuthCallback() {
    const { t } = useTranslation();
    const query = useQuery();

    const code = query.get('code');
    const state = query.get('state');

    useEffect(() => {
        window?.opener?.postMessage({ token: state, code }, '*');
        window.close();
    }, [code, state]);

    return <OAuthTransition message={t('profile.settings.streamingSettings.connector.connecting')} />;
}
