const TrackingTypes = {
    NOT_RESPONSE_AGENT: 'not_response_agent',
    ACCEPT_INCOMING_CALL: 'accept_incoming_call',
    CANCEL_INCOMING_MEET: 'cancel_incoming_meet',
    ADD_USER_TO_CALL: 'add_user_to_call',
    PICKUP_FROM_QUEUE: 'pickup_from_queue',
    USER_SNIPED_DETAILS: 'user_sniped_details',
    USER_ADD_TO_CART: 'user_add_to_cart',
    USER_LAUNCH_REACTION: 'user_launch_reaction',
    USER_ARRIVES_TO_WELCOME_PAGE: 'user_arrives_to_welcome_page',
    USER_RELOAD_OR_CLOSE_TAB: 'user_reload_or_close_tab',
    USER_LEAVE_SHOW: 'user_leave_show',
    USER_REGISTER: 'user_register',
    USER_ARRIVES_BY_LINK: 'user_arrives_by_link',
    USER_ENTER_SHOW: 'user_enter_show',
    USER_EVENT_EVALUATION: 'user_event_evaluation',
    USER_CLICK_CALL_TO_ACTION: 'user_click_call_to_action',
    USER_CHECKOUT_TO_STORE: 'user_checkout_to_store',
    SUBTRACT_ITEM_COUNT_IN_CART: 'subtract_item_count_in_cart',
    USER_WRITE_CHAT: 'user_write_chat',
    USER_ANSWER_QUIZ: 'user_answer_quiz',
    USER_ANSWER_SURVEY: 'user_answer_survey',
    USER_SHARE_URL: 'user_share_url',
    USER_WIN_GAME: 'user_win_game',
};

export default TrackingTypes;
