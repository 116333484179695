import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, FormGroup, FormLabel, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';

import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useSupportedLangs } from 'common/constants/langs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/slices/settingSlice';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesViewerExtraFields from '../styles/useStylesViewerExtraFields.styles';
import CustomTooltip from 'common/components/CustomTooltip';

const CustomTextEvent = (props) => {
    const { t, i18n } = useTranslation();
    const {
        toggleChange,
        valueSetting,
        keySetting,
        initText,
        label,
        tooltipEdit = t('profile.settings.viewerExtraField.fieldsRow.edit'),
    } = props;

    const { classes: classes1 } = useStylesForm();
    const { classes: classes2, cx } = useStylesViewerExtraFields();
    const { classes: classes3 } = useStylesFieldForm();

    const classes = { ...classes1, ...classes2, ...classes3 };

    const langs = useSupportedLangs();

    const initialValue = valueSetting && valueSetting.length > 0 ? valueSetting : initText;
    const [value, setValue] = useState(initialValue);
    const [showForm, setShowForm] = useState(false);

    const langStore = useSelector(getLanguage);
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || 'en';
    let language = langStore ? langStore : getCurrentLng();

    const getTextActionSubmit = () => {
        const textActionTranslate = value;
        const labelI18 = textActionTranslate.find((item) => item.languageCode.toLowerCase() === language.toLowerCase());
        const textValue = labelI18 && 'value' in labelI18 ? labelI18.value : null;

        return [...textValue.matchAll(/[^\n]+|\n/g)].map((match) => (
            <span className={cx(classes.fieldRowSpan, classes.varOptText)}>
                {match[0] === '\n' ? <br /> : match[0]} {/* Handle blank lines */}
            </span>
        ));
    };

    const handleLangActionChange = (e, languageCode) => {
        let newLabels = value.filter((label) => label.languageCode !== languageCode);
        newLabels.push({ languageCode: languageCode, value: e.target.value });
        setValue(newLabels);
    };

    const handleSaveAction = () => {
        toggleChange(keySetting, value);
        setShowForm(false);
    };
    const handleError = (e) => {
        console.debug('🚀 ~ file: CustomTextEvent.js ~ handleError ~ e', e);
    };

    const onClose = () => {
        setValue(initialValue);
        setShowForm(false);
    };

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={cx(classes.fullWidth, classes.pb10px)}
            >
                <Typography className={classes.text}>{label}</Typography>
            </Box>

            <div className={classes.contentWidth}>
                <div className={classes.fieldContent}>
                    <div className={classes.p10px}>
                        <Grid container spacing={1} className={classes.optionsRow}>
                            <Grid item md={10} className={cx(classes.fieldOption)}>
                                {getTextActionSubmit()}
                            </Grid>

                            <Grid
                                item
                                md={2}
                                className={cx(classes.optionsRow, classes.positionEnd, classes.actionsIcons)}
                            >
                                {!showForm && (
                                    <CustomTooltip arrow placement="bottom" title={tooltipEdit}>
                                        <IconButton
                                            edge="end"
                                            size="small"
                                            aria-label="up"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowForm(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                )}
                            </Grid>
                        </Grid>
                    </div>

                    {showForm && (
                        <div className={classes.p10px}>
                            <ValidatorForm
                                onSubmit={handleSaveAction}
                                onError={handleError}
                                className={cx(classes.form, classes.settingsForm)}
                            >
                                <Grid container spacing={1} className={classes.langContainer}>
                                    {langs.map((lang) => (
                                        <Grid key={lang.languageCode} item md={6}>
                                            <FormGroup className={classes.formGroup}>
                                                <FormLabel>{lang.language}</FormLabel>
                                                <TextValidator
                                                    className={classes.formControl}
                                                    name={lang.languageCode}
                                                    fullWidth={true}
                                                    size="small"
                                                    autoComplete="new-link"
                                                    multiline
                                                    value={
                                                        value.find(
                                                            (l) =>
                                                                l.languageCode.toLowerCase() ===
                                                                lang.languageCode.toLowerCase(),
                                                        )?.value || ''
                                                    }
                                                    onChange={(e) => handleLangActionChange(e, lang.languageCode)}
                                                    variant="outlined"
                                                    validators={['required']}
                                                    errorMessages={[t('app.common.required')]}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid container spacing={1} className={cx(classes.optionsRow, classes.positionEnd)}>
                                    <Grid item md={3}>
                                        <Button
                                            field="contained"
                                            size="small"
                                            className={cx(classes.buttonStyle)}
                                            disableElevation={true}
                                            onClick={onClose}
                                        >
                                            {t('app.common.cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Button
                                            field="contained"
                                            size="small"
                                            type="submit"
                                            color="primary"
                                            className={cx(classes.buttonStyle, classes.saveButton)}
                                            disableElevation={true}
                                        >
                                            {t('app.common.save')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CustomTextEvent;
