import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const systemLogSlice = createSlice({
    name: 'systemLog',
    initialState: {
        systemLogs: [],
        error: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 1,
        pending: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setSystemLogs: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.systemLogs = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setSystemLogs,
    setError,
} = systemLogSlice.actions;

export const getSystemLogs = (state) => state.systemLog.systemLogs;
export const getSystemLogsPending = (state) => state.systemLog.pending;
export const getSystemLogsError = (state) => state.systemLog.error;
export const getSystemLogsTotal = (state) => state.systemLog.total;
export const getSystemLogsCurrentPage = (state) => state.systemLog.currentPage;
export const getSystemLogsTotalPages = (state) => state.systemLog.totalPages;

export const fetchSystemLogsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            if (queryParams) {
                if (queryParams.page === 1) {
                    dispatch(
                        setSystemLogs({
                            total: 0,
                            items: [],
                            count: 0,
                            currentPage: 1,
                            totalPages: 1,
                            offset: 0,
                        }),
                    );
                }
            }
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('system-logs/auth' + query)
                .then((response) => {
                    dispatch(setSystemLogs({...response.data, offset: queryParams.page ? (queryParams.page - 1) * 10 : 0,}));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export default systemLogSlice.reducer;
