import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircleOutline';
import Error from '@mui/icons-material/ErrorOutline';
import PauseIcon from '@mui/icons-material/Pause';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import SnoozeIcon from '@mui/icons-material/Snooze';
import Timelapse from '@mui/icons-material/Timelapse';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOff from '@mui/icons-material/TimerOff';
import UpdateIcon from '@mui/icons-material/Update';

export const CommonStatus = {
    // --------- Common Status ----------
    PENDING: {
        name: 'pending',
        label: 'common.status.pending',
        icon: <SnoozeIcon />,
        iconName: 'SnoozeIcon',
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
        },
    },
    ACCEPTED: {
        name: 'accepted',
        label: 'common.status.accepted',
        icon: <CheckCircle />,
        iconName: 'CheckCircle',
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
        },
    },
    CANCELED: {
        name: 'canceled',
        label: 'common.status.canceled',
        icon: <Cancel />,
        iconName: 'Cancel',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
    READY: {
        name: 'ready',
        label: 'common.status.ready',
        icon: <CheckCircle />,
        iconName: 'CheckCircle',
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
        },
    },
    ERROR: {
        name: 'error',
        label: 'common.status.error',
        icon: <Error />,
        iconName: 'Error',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
};

export const SessionRequestStatus = {
    // --------- Session Request Status ----------
    // PENDING
    // ACCEPTED
    // CANCELED
    QUEUED: {
        name: 'queued',
        label: 'common.status.queued',
        icon: <CheckCircle />,
        iconName: 'CheckCircle',
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
        },
    },
};

export const EventStatus = {
    // --------- Event Status ----------
    // -------- Video Calls Status -------------
    // PENDING
    INITIALIZED: {
        name: 'initialized',
        label: 'common.status.initialized',
        icon: <TimerIcon />,
        iconName: 'TimerIcon',
        style: {
            backgroundColor: '#ffff00',
            color: '#000000',
        },
    },
    RUNNING: {
        name: 'running',
        label: 'common.status.running',
        icon: <UpdateIcon />,
        iconName: 'UpdateIcon',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
    PAUSED: {
        name: 'paused',
        label: 'common.status.paused',
        icon: <PauseIcon />,
        iconName: 'PauseIcon',
        style: {
            backgroundColor: '#000000',
            color: '#FFFFFF',
        },
    },
    FINALIZED: {
        name: 'finalized',
        label: 'common.status.finalized',
        icon: <TimerOff />,
        iconName: 'TimerOff',
        style: {
            backgroundColor: '#b3b3b3',
            color: '#FFFFFF',
        },
    },
};

export const ContactRequestStatus = {
    // --------- Contact Request Status ----------
    // PENDING
    // ACCEPTED
    REJECTED: {
        name: 'rejected',
        label: 'common.status.rejected',
        icon: <CheckCircle />,
        iconName: 'CheckCircle',
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
        },
    },
};

// --------- Lost Opportunity Status ----------
// PENDING
// ACCEPTED
// REJECTED

// --------- Lead Status ----------
// BySettings

// --------- ICalendar Status ----------
// BySettings

export const ICallStatus = {
    // --------- ICall Status ----------
    AGENT_NOT_RESPOND: {
        name: 'agent_not_respond',
        label: 'common.status.agentNotRespond',
        icon: <Cancel />,
        iconName: 'Cancel',
        style: {
            backgroundColor: '#fc6f03',
            color: '#FFFFFF',
        },
    },
    USER_CANCEL: {
        name: 'user_cancel',
        label: 'common.status.userCancel',
        icon: <Cancel />,
        iconName: 'Cancel',
        style: {
            backgroundColor: '#fc6f03',
            color: '#FFFFFF',
        },
    },
    AGENT_CANCEL: {
        name: 'agent_cancel',
        label: 'common.status.agentCancel',
        icon: <Cancel />,
        iconName: 'Cancel',
        style: {
            backgroundColor: '#fc6f03',
            color: '#FFFFFF',
        },
    },
    USER_CANCEL_QUEUE: {
        name: 'user_cancel_queue',
        label: 'common.status.userCancelQueue',
        icon: <Cancel />,
        iconName: 'Cancel',
        style: {
            backgroundColor: '#fc6f03',
            color: '#FFFFFF',
        },
    },
    NO_AGENTS_AVAILABLE: {
        name: 'no_agents_available',
        label: 'common.status.noAgentsAvailable',
        icon: <SignalWifiBadIcon />,
        iconName: 'SignalWifiBadIcon',
        style: {
            backgroundColor: '#b3b3b3',
            color: '#FFFFFF',
        },
    },
    AGENT_UNAVAILABLE: {
        name: 'agent_unavailable',
        label: 'common.status.agentUnavailable',
        icon: <SignalWifiBadIcon />,
        iconName: 'SignalWifiBadIcon',
        style: {
            backgroundColor: '#b3b3b3',
            color: '#FFFFFF',
        },
    },
};

export const MultimediaStatus = {
    // --------- Multimedia Status ----------
    // READY
    // ERROR
    NEED_ACTION: {
        name: 'need_action',
        label: 'common.status.needAction',
        icon: <Timelapse />,
        iconName: 'Timelapse',
        style: {
            backgroundColor: '#EC6C01',
            color: '#FFFFFF',
        },
    },
};

export const ReportStatus = {
    // --------- Report Status ----------
    // READY
    // ERROR
    PROCESSING: {
        name: 'processing',
        label: 'common.status.processing',
        icon: <UpdateIcon />,
        iconName: 'UpdateIcon',
        style: {
            backgroundColor: '#ffff00',
            color: '#000',
        },
    },
};
export const ShoppableStatus = {
    // -------- Shoppable Status -------------
    // READY
    VIDEO_NOT_YET: {
        name: 'video_not_yet',
        label: 'common.status.videoNotYet',
        icon: <Timelapse />,
        iconName: 'Timelapse',
        style: {
            backgroundColor: '#FFFFFF',
            color: '#000',
        },
    },
    CONVERTING: {
        name: 'converting',
        label: 'common.status.converting',
        icon: <SnoozeIcon />,
        iconName: 'SnoozeIcon',
        style: {
            backgroundColor: '#434343',
            color: '#FFFFFF',
        },
    },
};

export const UserInvitationStatus = {
    // -------- User Invitation Status -------------
    // PENDING
    // CANCELED
    CONFIRMED: {
        name: 'confirmed',
        label: 'common.status.confirmed',
        icon: <CheckCircle />,
        iconName: 'CheckCircle',
        style: {
            backgroundColor: '#00cc00',
            color: '#FFFFFF',
        },
    },
    EXPIRED: {
        name: 'confirmed',
        label: 'common.status.expired',
        icon: <TimerOff />,
        iconName: 'TimerOff',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
};

export const InvoiceStatus = {
    // -------- Invoice Status -------------
    DRAFT: {
        name: 'draft',
        label: 'common.status.draft',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
    OPEN: {
        name: 'open',
        label: 'common.status.open',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
    PAID: {
        name: 'paid',
        label: 'common.status.paid',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
    UNCOLLECTIBLE: {
        name: 'uncollectible',
        label: 'common.status.uncollectible',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
    VOID: {
        name: 'void',
        label: 'common.status.void',
        style: {
            backgroundColor: '#ff0000',
            color: '#FFFFFF',
        },
    },
};

export const WebHookStatus = {
    // -------- Web Hooks Status -------------

    FAILING: {
        name: 'failing',
        label: 'common.status.failing',
        style: {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
        },
    },
    HEALTHY: {
        name: 'healthy',
        label: 'common.status.healthy',
        style: {
            backgroundColor: '#16C144',
            color: '#FFFFFF',
        },
    },
    NEW: {
        name: 'new',
        label: 'common.status.new',
        style: {
            backgroundColor: '#0288D1',
            color: '#FFFFFF',
        },
    },
    REVIEWING: {
        name: 'reviewing',
        label: 'common.status.reviewing',
        style: {
            backgroundColor: '#FF9933',
            color: '#FFFFFF',
        },
    },
    WAITING: {
        name: 'waiting',
        label: 'common.status.waiting',
        style: {
            backgroundColor: '#DEDEDE',
            color: '#FFFFFF',
        },
    },
    RECOVERED: {
        name: 'recovered',
        label: 'common.status.recovered',
        style: {
            backgroundColor: '#FF9933',
            color: '#FFFFFF',
        },
    },
};

export const OthersStatus = {
    // --------- Other Status ----------
    DONE: {
        name: 'done',
        label: 'common.status.done',
        icon: <CheckCircle />,
        style: {
            backgroundColor: '#01D049',
            color: '#FFFFFF',
        },
    },
    CALENDAR_PENDING: {
        name: 'calendar_pending',
        label: 'common.status.pending',
        icon: <SnoozeIcon />,
        style: {
            backgroundColor: '#b3b3b3',
            color: '#000000',
        },
    },
    PROGRAMED: {
        name: 'programed',
        label: 'common.status.programed',
        icon: <TimerIcon />,
        style: {
            backgroundColor: '#00D049',
            color: '#FFFFFF',
        },
    },
};

export const StatusType = {
    ...CommonStatus,
    ...SessionRequestStatus,
    ...EventStatus,
    ...ContactRequestStatus,
    ...ICallStatus,
    ...MultimediaStatus,
    ...ReportStatus,
    ...ShoppableStatus,
    ...UserInvitationStatus,
    ...InvoiceStatus,
    ...WebHookStatus,
    ...OthersStatus,
};
