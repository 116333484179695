import ERROR_IMAGE_URL from '../common/constants/errorImageUrl';

const logo = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/logo-onlive-site.png`;

export function ErrorFallback() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '70vh',
                }}
            >
                <img
                    src={ERROR_IMAGE_URL}
                    alt="Crash"
                    style={{
                        height: '25vh',
                    }}
                />
                <span
                    style={{
                        fontWeight: 400,
                        fontSize: '2vh',
                        paddingTop: '2vh',
                    }}
                >
                    Sorry! An unexpected error has occurred
                </span>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                }}
            >
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        width: '8vh',
                        marginBottom: '2vh',
                    }}
                />
            </div>
        </div>
    );
}
