import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import TextButton from 'common/components/TextButton';
import CssCodeInputMonacoEditor from 'common/components/code-editor/CssCodeInputMonacoEditor';
import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import config from 'config';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import useStylesWidgetOneToOne from '../styles/useStylesWidgetOneToOne.styles';
import WidgetActions from './WidgetActions';

const WidgetOneToOne = (props) => {
    const {
        widgetSetting,
        toggleChange,
        widgetActionsSetting,
        widgetColorSetting,
        widgetPositionSetting,
        widgetVersionSetting,
        widgetVersion2Design,
        widgetBtnOpenAtStart,
        widgetBtnDistribution,
        widgetHideVideoCall,
        hasChanges,
        handleSubmit,
        rulesWidget,
        customStylesWidget,
        optionsWidget,
    } = props;

    const { t } = useTranslation();

    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesFieldForm();
    const { classes: classes3 } = useStylesSettings();
    const { classes: classes4 } = useStylesWidgetOneToOne();
    const classes = { ...classes1, ...classes2, ...classes3, ...classes4 };

    const organization = useSelector(getOrganization);
    const settingsDirty = useSelector(getSettingsDirty);
    const [inputError, setInputError] = useState({ rules_widget: null, options_widget: null });

    const flatVersions = {
        v2: '2',
        v3: '3',
        v4: '4',
    };
    const [selectedVersion, setSelectedVersion] = useState(flatVersions[widgetVersionSetting]);

    const { enqueueSnackbar } = useSnackbar();

    const widgetChatCode = `             <script>
            (function (o, n, l, i, v, e) {
              v = o.createElement(n);v.onload = i;v.async = 1;v.src = l;
              e = o.getElementsByTagName(n)[0];e.parentNode.insertBefore(v, e);
            })(document, 'script', '${config.loader.baseURL}', function () {
              window.onliveManager.loader.load("${organization.id}");
            });
        </script>`;

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const handleValueChangeCode = (value, settingName, errors) => {
        const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
        const error2Input = codeHaveErrors ? errors : null;

        setInputError({ ...inputError, [settingName]: error2Input });
        toggleChange(settingName, value);
    };

    const handleColorChanged = (color) => {
        toggleChange(SettingsKey.WIDGET_COLOR, color);
    };

    const handlePositionChanged = (position) => {
        toggleChange(SettingsKey.WIDGET_ORIENTATION, position);
    };

    const handleVersionChanged = (version) => {
        toggleChange(SettingsKey.WIDGET_VERSION, version);
        setSelectedVersion(flatVersions[version]);
    };
    const handleDesignChanged = (design) => {
        toggleChange(SettingsKey.WIDGET_DESIGN, design);
    };

    const handleDistributionChanged = (distribution) => {
        toggleChange(SettingsKey.WIDGET_BTN_DISTRIBUTION, distribution);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.widgetOneOne')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <ItemSettings
                        label={t('profile.settings.widgetOneToOneSettings.widgetOneToOne.setUse')}
                        onChange={handleCheckChange}
                        name={'widgetChat'}
                        keySettings={SettingsKey.ENABLE_CHAT_WIDGET}
                        value={widgetSetting}
                    />

                    <Box
                        sx={{
                            display:
                                settingsDirty &&
                                hasChanges &&
                                inputError.options_widget === null &&
                                inputError.rules_widget === null
                                    ? 'inline-flex'
                                    : 'none',
                        }}
                    >
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.save')}
                        </Button>
                    </Box>
                </Box>

                {/*<FormGroup>*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <Switch*/}
                {/*        checked={widgetSetting}*/}
                {/*        onChange={(e) => handleCheckChange(e, "enable_chat_widget")}*/}
                {/*        name="widgetChat"*/}
                {/*        color="primary"*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label={*/}
                {/*      <FormLabel>*/}
                {/*        {widgetSetting ?*/}
                {/*          t("makeDisable")*/}
                {/*          :*/}
                {/*          t("makeAvailable")*/}
                {/*        }*/}
                {/*      </FormLabel>*/}
                {/*    }*/}
                {/*  />*/}
                {/*</FormGroup>*/}

                {widgetSetting && (
                    <Grid container spacing={1} className={classes.pt20px}>
                        <Grid item md={3}>
                            <Typography className={classes.text}>
                                {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.setPosition')}
                            </Typography>
                            <FormGroup className={classes.reactionRow}>
                                <FormControlLabel
                                    key="left"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetPositionSetting === 'left'}
                                            onChange={() => handlePositionChanged('left')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className={classes.text}>
                                            {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.left')}
                                        </div>
                                    }
                                />
                                <FormControlLabel
                                    key="center"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetPositionSetting === 'center'}
                                            onChange={() => handlePositionChanged('center')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className={classes.text}>
                                            {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.center')}
                                        </div>
                                    }
                                />
                                <FormControlLabel
                                    key="right"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetPositionSetting === 'right'}
                                            onChange={() => handlePositionChanged('right')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className={classes.text}>
                                            {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.right')}
                                        </div>
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6} className={classes.mr10px}>
                            <Typography className={classes.text}>
                                {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.setColor')}
                            </Typography>
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('callToAction.callToActionForm.color')}</FormLabel>
                                <div className={classes.inputColor}>
                                    <HexColorInput
                                        className={classes.inputColor}
                                        color={widgetColorSetting}
                                        onChange={handleColorChanged}
                                    />
                                </div>
                                <HexColorPicker
                                    className={classes.p2}
                                    color={widgetColorSetting}
                                    onChange={handleColorChanged}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                )}

                {widgetSetting && (
                    <WidgetActions toggleChange={toggleChange} widgetActionsSetting={widgetActionsSetting} />
                )}

                {widgetSetting && (
                    <Grid container className={classes.pt20px}>
                        <Fragment>
                            <Typography className={classes.text}>
                                {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.setVersion')}
                            </Typography>
                            <FormGroup className={classes.reactionRow}>
                                {/*<FormControlLabel
                                    key="widget-one-to-one-v1"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetVersionSetting === 'v1'}
                                            onChange={() => handleVersionChanged('v1')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={<div className={classes.text}>{t('version1')}</div>}
                                />*/}
                                <FormControlLabel
                                    key="widget-one-to-one-v2"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetVersionSetting === 'v2'}
                                            onChange={() => handleVersionChanged('v2')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={<div className={classes.text}>{t('version2')}</div>}
                                />
                                <FormControlLabel
                                    key="widget-one-to-one-v3"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetVersionSetting === 'v3'}
                                            onChange={() => handleVersionChanged('v3')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={<div className={classes.text}>{t('version3')}</div>}
                                />
                                <FormControlLabel
                                    key="widget-one-to-one-v4"
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={widgetVersionSetting === 'v4'}
                                            onChange={() => handleVersionChanged('v4')}
                                            name="reactionNumber"
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={<div className={classes.text}>{t('version4')}</div>}
                                />
                            </FormGroup>
                        </Fragment>
                        {['v2', 'v3'].includes(widgetVersionSetting) && (
                            <Fragment>
                                <Typography className={classes.text}>
                                    {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.setDesignV2', {
                                        version: selectedVersion,
                                    })}
                                </Typography>
                                <FormGroup className={classes.reactionRow}>
                                    <FormControlLabel
                                        key="center"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetVersion2Design === 'traditional'}
                                                onChange={() => handleDesignChanged('traditional')}
                                                name="reactionNumber"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={
                                            <div className={classes.text}>
                                                {t(
                                                    'profile.settings.widgetOneToOneSettings.widgetOneToOne.version2.traditional',
                                                )}
                                            </div>
                                        }
                                    />
                                    <FormControlLabel
                                        key="right"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetVersion2Design === 'modern'}
                                                onChange={() => handleDesignChanged('modern')}
                                                name="reactionNumber"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={
                                            <div className={classes.text}>
                                                {t(
                                                    'profile.settings.widgetOneToOneSettings.widgetOneToOne.version2.modern',
                                                )}
                                            </div>
                                        }
                                    />
                                </FormGroup>
                            </Fragment>
                        )}
                    </Grid>
                )}

                <div style={{ marginTop: '20px' }}>
                    {widgetSetting && (
                        <ItemSettings
                            label={t('profile.settings.widgetOneToOneSettings.widgetOneToOne.hideVideoCall')}
                            onChange={handleCheckChange}
                            name={'widgetHideVideoCall'}
                            keySettings={SettingsKey.WIDGET_HIDE_VIDEO_CALL}
                            value={widgetHideVideoCall}
                        />
                    )}

                    {widgetSetting && (
                        <Grid container spacing={1} className={classes.pb20px}>
                            <Grid item md={2} className={classes.mr10px}>
                                <ItemSettings
                                    label={t('profile.settings.widgetOneToOneSettings.widgetOneToOne.btnOpenAtStart')}
                                    onChange={handleCheckChange}
                                    name={'widgetBtnOpenAtStart'}
                                    keySettings={SettingsKey.WIDGET_BTN_OPEN_AT_START}
                                    value={widgetBtnOpenAtStart}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Typography className={classes.text}>
                                    {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.btnDistribution')}
                                </Typography>
                                <FormGroup className={classes.reactionRow}>
                                    <FormControlLabel
                                        key="around"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetBtnDistribution === 'around'}
                                                onChange={() => handleDistributionChanged('around')}
                                                name="reactionDistribution"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={
                                            <div className={classes.text}>
                                                {t(
                                                    'profile.settings.widgetOneToOneSettings.widgetOneToOne.btnDistributionAround',
                                                )}
                                            </div>
                                        }
                                    />
                                    <FormControlLabel
                                        key="vertical"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetBtnDistribution === 'vertical'}
                                                onChange={() => handleDistributionChanged('vertical')}
                                                name="reactionDistribution"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={
                                            <div className={classes.text}>
                                                {t(
                                                    'profile.settings.widgetOneToOneSettings.widgetOneToOne.btnDistributionVertical',
                                                )}
                                            </div>
                                        }
                                    />
                                    <FormControlLabel
                                        key="horizontal"
                                        className={classes.checkboxLabel}
                                        control={
                                            <Checkbox
                                                checked={widgetBtnDistribution === 'horizontal'}
                                                onChange={() => handleDistributionChanged('horizontal')}
                                                name="reactionDistribution"
                                                className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                            />
                                        }
                                        label={
                                            <div className={classes.text}>
                                                {t(
                                                    'profile.settings.widgetOneToOneSettings.widgetOneToOne.btnDistributionHorizontal',
                                                )}
                                            </div>
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    )}
                </div>

                {
                    /*widgetSetting*/ false && (
                        <>
                            <FormLabel className={classes.title}>{t('installationSettings')}</FormLabel>
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('Rules (JSON)')}</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <JsonCodeInputMonacoEditor
                                        name="rules"
                                        value={rulesWidget}
                                        onChange={(value, errors) =>
                                            handleValueChangeCode(value, SettingsKey.RULES_WIDGET, errors)
                                        }
                                    />
                                    <SimpleCodeErrorView
                                        id={SettingsKey.RULES_WIDGET}
                                        mainErrorMessage={t('JsonError')}
                                        errorList={inputError[SettingsKey.RULES_WIDGET]}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('Custom Styles (CSS)')}</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <CssCodeInputMonacoEditor
                                        name="custom_styles_widget"
                                        value={customStylesWidget}
                                        onChange={(value, errors) =>
                                            handleValueChangeCode(value, SettingsKey.CUSTOM_STYLES_WIDGET, errors)
                                        }
                                    />
                                    <SimpleCodeErrorView
                                        id={SettingsKey.CUSTOM_STYLES_WIDGET}
                                        mainErrorMessage={t('CssError')}
                                        errorList={inputError[SettingsKey.CUSTOM_STYLES_WIDGET]}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                                <FormLabel>{t('Options (JSON)')}</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <JsonCodeInputMonacoEditor
                                        name="options_widget"
                                        value={optionsWidget}
                                        onChange={(value, errors) =>
                                            handleValueChangeCode(value, SettingsKey.OPTIONS_WIDGET, errors)
                                        }
                                    />
                                    <SimpleCodeErrorView
                                        id={SettingsKey.OPTIONS_WIDGET}
                                        mainErrorMessage={t('JsonError')}
                                        errorList={inputError[SettingsKey.OPTIONS_WIDGET]}
                                    />
                                </FormControl>
                            </FormGroup>
                        </>
                    )
                }

                {widgetSetting && (
                    <React.Fragment>
                        <Typography className={classes.text}>
                            {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.codeSniped')}
                        </Typography>
                        <div className={classes.codeBox}>
                            <pre>
                                <code>{widgetChatCode}</code>
                            </pre>
                            <div>
                                <CopyToClipboard text={widgetChatCode}>
                                    <TextButton
                                        color="secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            enqueueSnackbar(
                                                t(
                                                    'profile.settings.widgetOneToOneSettings.widgetOneToOne.successCopied',
                                                ),
                                                { variant: 'success' },
                                            );
                                        }}
                                    >
                                        {t('profile.settings.widgetOneToOneSettings.widgetOneToOne.copyCode')}
                                    </TextButton>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default WidgetOneToOne;
