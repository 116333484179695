import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import CustomTooltip from 'common/components/CustomTooltip';
import { useTranslation } from 'react-i18next';
import CustomIconButton from './CustomIconButton';

const ReloadButton = (props) => {
    const { t } = useTranslation();
    const { onReload, pending, customClass } = props;

    return (
        <CustomTooltip
            arrow
            key={`action-export`}
            placement="bottom"
            title={t('common.reloadButton.action')}
            aria-label="reload-data"
        >
            <CustomIconButton
                disabled={pending}
                onClick={onReload}
                size="small"
                pending={pending}
                className={customClass}
            >
                <CachedOutlinedIcon />
            </CustomIconButton>
        </CustomTooltip>
    );
};

export default ReloadButton;
