import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormGroup, FormHelperText, Stack } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

const EnvironmentVariableInput = ({
    variable,
    onChange,
    id,
    index,
    error,
    onDelete,
    disableDelete,
    onErrorChange,
    count,
}) => {
    const { t } = useTranslation();
    const { classes: flexBox } = useFlexBoxStyles();

    const isValidKey = (key) => key.trim() !== '' && !/[.#$/[\]]/.test(key);
    const isValidValue = (value) => value.trim() !== '';

    const addValidationRules = useCallback(() => {
        ValidatorForm.addValidationRule(`validKey-${id}`, (value) => {
            const isEmptyAllowed = count === 1 && variable.value.trim() === '' && variable.key.trim() === '';
            return isEmptyAllowed || isValidKey(value);
        });
        ValidatorForm.addValidationRule(`noEmpty-${id}`, (value) => {
            const isEmptyAllowed = count === 1 && variable.key.trim() === '';
            return isEmptyAllowed || isValidValue(value);
        });
        return () => {
            ValidatorForm.removeValidationRule(`validKey-${id}`);
            ValidatorForm.removeValidationRule(`noEmpty-${id}`);
        };
    }, [id, variable.key, variable.value, count]);

    useEffect(addValidationRules, [addValidationRules]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedVariable = { ...variable, [name]: value };

        onChange(updatedVariable);
        onErrorChange({ [name]: name === 'key' ? !isValidKey(value) : !isValidValue(value) });
    };

    const handleInputDelete = useCallback(() => {
        onErrorChange({ key: false, value: false });
        onDelete(variable?.id);
    }, [onErrorChange, onDelete, variable?.id]);

    return (
        <Stack direction="row" alignItems="flex-start" spacing={2}>
            <FormGroup className={flexBox.w40}>
                <CustomTextValidator
                    name="key"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={variable.key}
                    disabled={error !== -1 && error !== index}
                    onChange={handleInputChange}
                    validators={[`validKey-${id}`, ...(count > 1 ? [`noEmpty-${id}`] : [])]}
                    errorMessages={[
                        t('profile.settings.immersiveEnvironmentsVariables.form.validKey'),
                        t('profile.settings.immersiveEnvironmentsVariables.form.noEmptyKey'),
                    ]}
                />
                {error === index && (
                    <FormHelperText error className={flexBox.paddingLeft2}>
                        {t('profile.settings.immersiveEnvironmentsVariables.form.keyUniq')}
                    </FormHelperText>
                )}
            </FormGroup>

            <FormGroup className={flexBox.w40}>
                <CustomTextValidator
                    name="value"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={variable.value}
                    onChange={handleInputChange}
                    validators={[`noEmpty-${id}`]}
                    errorMessages={[t('profile.settings.immersiveEnvironmentsVariables.form.noEmptyValue')]}
                />
            </FormGroup>

            <div className={flexBox.grow1} />

            <CustomTooltip arrow title={t('contact.remove')} placement="bottom">
                <CustomIconButton
                    aria-label="delete"
                    onClick={handleInputDelete}
                    size="small"
                    className={flexBox.marginTop05}
                    disabled={disableDelete}
                >
                    <DeleteOutlineOutlinedIcon />
                </CustomIconButton>
            </CustomTooltip>
        </Stack>
    );
};

export default EnvironmentVariableInput;
