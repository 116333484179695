import { Autocomplete } from '@mui/material';

import useStylesCustomAutocomplete from './styles/useStylesCustomAutocomplete.styles';

const CustomAutocomplete = (props) => {
    const { ...rest } = props;
    const { classes, cx } = useStylesCustomAutocomplete();
    return <Autocomplete {...rest} className={cx(classes.root, rest.className)} />;
};

export default CustomAutocomplete;
