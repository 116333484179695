import { createSlice } from '@reduxjs/toolkit';
import api, { calendarApiV3 } from 'api';
import { refreshUserAction } from './authSlice';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        pending: false,
        savePending: false,
        detachPending: false,
        parentOrganizationPending: false,
        syncProgressPending: false,
        testPending: false,
        testResult: null,
        error: null,
        mainOrganization: null,
        parentOrganization: null,
        organization: localStorage?.getItem('organization')
            ? JSON.parse(localStorage?.getItem('organization'))
            : {
                  // TODO: get from bucket images
                  logo: process.env.PUBLIC_URL + '/img/logo.png',
                  mainImage: process.env.PUBLIC_URL + '/login_tmp.png',
                  primaryColor: '#FF0000',
                  secondaryColor: '#FFFFFF',
              },
        provider: null,
        indexerAccountId: '',
        syncProgress: {
            totalProductsToSync: 0,
            synchronizedProducts: 0,
            synchronizedErrors: 0,
        },
        coupon: null,
        errorFetchCoupon: null,
        pendingFetchCoupon: false,
        productsSearchEngine: null,
        nextInvoice: null,
        purchaseInvoice: null,
        pendingPurchase: false,
        pendingProvider: false,
        pendingProductsSearchEngine: false,
        total: 0,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        microFrontsConfig: {},
        iCalendarStoreKeyForImporting: 'undefined',
        childrenOrganizations: [],
        childrenOrganizationsPending: false,
    },
    reducers: {
        start: (state, action) => {
            state.error = null;
            state.pending = true;
        },
        startDetach: (state, action) => {
            state.detachPending = true;
        },
        startParentOrganization: (state, action) => {
            state.parentOrganizationPending = true;
        },
        startSyncProgressPending: (state, action) => {
            state.syncProgressPending = true;
        },
        stop: (state, action) => {
            state.error = null;
            state.pending = false;
        },
        setOrganization: (state, action) => {
            const newOrganization =
                state.organization.id === action.payload.id
                    ? { ...state.organization, ...mapOrganization(action.payload) }
                    : mapOrganization(action.payload);
            state.organization = newOrganization;
            const { email, ...rest } = newOrganization;
            localStorage.setItem('organization', JSON.stringify(rest));
            state.pending = false;
        },
        setMicroFrontsConfig: (state, action) => {
            state.microFrontsConfig = action.payload;
        },
        setMainOrganization: (state, action) => {
            state.mainOrganization = {
                ...state.mainOrganization,
                ...mapOrganization(action.payload),
            };
            state.pending = false;
        },
        setParentOrganization: (state, action) => {
            state.parentOrganization = action.payload;
            state.parentOrganizationPending = false;
        },

        setProvider: (state, action) => {
            state.savePending = false;
            state.provider = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.detachPending = false;
            state.testPending = false;
            state.savePending = false;
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startTest: (state, action) => {
            state.testPending = true;
        },
        setTestResult: (state, action) => {
            state.testPending = false;
            state.testResult = action.payload;
        },
        stopDetach: (state, action) => {
            state.detachPending = false;
        },
        setSyncProgress: (state, action) => {
            state.syncProgress = action.payload;
            state.syncProgressPending = false;
        },
        setCoupon: (state, action) => {
            state.coupon = action.payload;
            state.pendingFetchCoupon = false;
        },
        setNextInvoice: (state, action) => {
            state.pending = false;
            state.nextInvoice = action.payload;
        },
        setPurchaseInvoice: (state, action) => {
            state.pendingPurchase = false;
            state.purchaseInvoice = action.payload;
        },
        setPendingPurchase: (state, action) => {
            state.pendingPurchase = action.payload;
        },
        startFetchCoupon: (state, action) => {
            state.pendingFetchCoupon = true;
            state.errorFetchCoupon = null;
        },
        stopFetchCoupon: (state, action) => {
            state.pendingFetchCoupon = false;
        },
        setErrorFetchCoupon: (state, action) => {
            state.coupon = null;
            state.errorFetchCoupon = action.payload;
        },
        setProductsSearchEngine: (state, action) => {
            state.productsSearchEngine = action.payload;
        },
        setProviderPending: (state, action) => {
            state.pendingProvider = action.payload;
        },
        setProductsSearchEnginePending: (state, action) => {
            state.pendingProductsSearchEngine = action.payload;
        },
        startOrganizationSearch: (state, action) => {
            state.searchPending = true;
            state.searchError = null;
        },
        stopOrganizationSearch: (state, action) => {
            state.searchPending = false;
        },
        setOrganizationSearchResults: (state, action) => {
            state.searchPending = false;
            const { items, total, count, currentPage, totalPages } = action.payload;
            state.searchResults = items;
            state.total = total;
            state.count = count;
            state.currentPage = currentPage;
            state.totalPages = totalPages;
        },
        setOrganizationSearchError: (state, action) => {
            state.searchError = action.payload;
        },
        setICalendarStoreKeyForImporting: (state, action) => {
            state.iCalendarStoreKeyForImporting = action.payload;
        },
        setChildrenOrganizations: (state, action) => {
            state.childrenOrganizations = action.payload;
            state.childrenOrganizationsPending = false;
        },
        startFetchChildrenOrganizations: (state, action) => {
            state.childrenOrganizationsPending = true;
        },
    },
});

export const {
    start,
    stop,
    setOrganization,
    setMainOrganization,
    setParentOrganization,
    setProvider,
    setError,
    startSave,
    startTest,
    setTestResult,
    // updateOrganization,
    startDetach,
    stopDetach,
    startParentOrganization,
    setSyncProgress,
    startSyncProgressPending,
    setCoupon,
    startFetchCoupon,
    stopFetchCoupon,
    setErrorFetchCoupon,
    setProductsSearchEngine,
    setNextInvoice,
    setPurchaseInvoice,
    setPendingPurchase,
    setProviderPending,
    setProductsSearchEnginePending,
    startOrganizationSearch,
    stopOrganizationSearch,
    setOrganizationSearchResults,
    setOrganizationSearchError,
    setMicroFrontsConfig,
    setICalendarStoreKeyForImporting,
    setChildrenOrganizations,
    startFetchChildrenOrganizations,
} = organizationSlice.actions;

export const getOrganization = (state) => state.organization.organization;
export const getMainOrganization = (state) => state.organization.mainOrganization;
export const getParentOrganization = (state) => state.organization.parentOrganization;
export const getProvider = (state) => state.organization.provider;
export const getOrganizationPending = (state) => state.organization.pending;
export const getOrganizationSavePending = (state) => state.organization.savePending;
export const getOrganizationProviderTestPending = (state) => state.organization.testPending;
export const getOrganizationProviderTestResult = (state) => state.organization.testResult;
export const getOrganizationError = (state) => state.organization.error;
export const getOrganizationDetachPending = (state) => state.organization.detachPending;
export const getOrganizationParentPending = (state) => state.organization.parentOrganizationPending;
export const getOrganizationSyncProgress = (state) => state.organization.syncProgress;
export const getOrganizationCoupon = (state) => state.organization.coupon;
export const getOrganizationPendingFetchCoupon = (state) => state.organization.pendingFetchCoupon;
export const getOrganizationErrorFetchCoupon = (state) => state.organization.errorFetchCoupon;
export const getProductsSearchEngine = (state) => state.organization.productsSearchEngine;
export const getNextInvoice = (state) => state.organization.nextInvoice;
export const getPurchaseInvoice = (state) => state.organization.purchaseInvoice;
export const getPendingPurchase = (state) => state.organization.pendingPurchase;
export const getProviderPending = (state) => state.organization.pendingProvider;
export const getProductsSearchEnginePending = (state) => state.organization.pendingProductsSearchEngine;
export const getOrganizationSearchPending = (state) => state.organization.searchPending;
export const getOrganizationSearchResults = (state) => state.organization.searchResults;
export const getScriptPagination = (state) => ({
    count: state.organization.count,
    currentPage: state.organization.currentPage,
    totalPages: state.organization.totalPages,
    total: state.organization.total,
});
export const getMicroFrontsConfig = (state) => state.organization.microFrontsConfig;
export const getICalendarStoreKeyForImporting = (state) => state.organization.iCalendarStoreKeyForImporting;
export const getChildrenOrganizations = (state) => state.organization.childrenOrganizations;

export const fetchOrganizationSearchAction =
    ({ search, page, limit }) =>
    async (dispatch) => {
        try {
            dispatch(startOrganizationSearch());
            const { data } = await api.get('organizations', { params: { search, page, limit } });

            dispatch(setOrganizationSearchResults(data));
            return data;
        } catch (error) {
            dispatch(stopOrganizationSearch());
            dispatch(setOrganizationSearchError());
            console.error('Error searching organization.');
            return null;
        }
    };

export const fetchOrganizationAction = () => async (dispatch) => {
    try {
        dispatch(start());
        const organizationId = localStorage?.getItem('user')
            ? JSON.parse(localStorage?.getItem('user'))?.organizationId
            : '';
        api.get('organizations/' + organizationId)
            .then((response) => {
                dispatch(setOrganization(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchMainOrganizationAction = () => async (dispatch) => {
    try {
        dispatch(start());
        const mainOrganizationId = localStorage?.getItem('user')
            ? JSON.parse(localStorage?.getItem('user'))?.mainOrganizationId
            : null;

        if (!mainOrganizationId) {
            dispatch(stop());
            return;
        }

        api.get('organizations/' + mainOrganizationId)
            .then((response) => {
                dispatch(setMainOrganization(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchOrganizationProviderAction = () => async (dispatch) => {
    try {
        dispatch(start());
        dispatch(setProviderPending(true));
        api.get('providers')
            .then((response) => {
                dispatch(setProvider(response.data));
                dispatch(setProviderPending(false));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                dispatch(setProviderPending(false));
            });
    } catch (e) {
        dispatch(stop());
        dispatch(setProviderPending(false));
        return console.error('error', e);
    }
};

export const testConnectionAction = (testDto) => async (dispatch) => {
    try {
        dispatch(startTest());
        api.post('providers/test-connection', testDto)
            .then((response) => {
                if (!response.data) {
                    dispatch(setTestResult('error'));
                } else {
                    dispatch(setTestResult('success'));
                }
                return response;
            })
            .catch(() => {
                dispatch(setTestResult('error'));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const clearTestResultAction = () => async (dispatch) => {
    dispatch(setTestResult(null));
};

export const editOrganizationAction = (organizationDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        const organizationId = localStorage?.getItem('user')
            ? JSON.parse(localStorage?.getItem('user'))?.organizationId
            : '';
        api.put('organizations/' + organizationId, organizationDto)
            .then((response) => {
                dispatch(setOrganization(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const editOrganizationProviderAction = (organizationDto, callback) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('providers', organizationDto)
            .then((response) => {
                dispatch(setProvider(response.data));
                if (callback) {
                    callback('success');
                }
                return response;
            })
            .catch((error) => {
                if (callback) {
                    callback('error');
                }
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const editOrganizationVerticalAction =
    ({ slug, callback }) =>
    async (dispatch, getState) => {
        try {
            dispatch(startSave());
            const state = getState();
            api.put(`organizations/${state.organization.organization.id}/vertical`, { slug })
                .then((response) => {
                    dispatch(setOrganization(response.data));
                    if (callback) {
                        callback('success');
                    }
                    return response;
                })
                .catch((error) => {
                    if (callback) {
                        callback('error');
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const detachOrganizationAction =
    ({ userId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startDetach());
            api.post('organizations/detach-parent')
                .then((response) => {
                    dispatch(setParentOrganization(null));
                    dispatch(stopDetach());
                    dispatch(refreshUserAction({ id: userId }));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    setCatchError(error, onFailure);
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchParentOrganizationAction =
    ({ onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startParentOrganization());
            api.get('organizations/parent')
                .then((response) => {
                    dispatch(setParentOrganization(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    setCatchError(error, onFailure);
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

const setCatchError = (error, onFailure) => async (dispatch) => {
    dispatch(setError(error));
    if (onFailure) {
        onFailure(error);
    }
};

export const fetchOrganizationSyncProgressAction = (organizationId, providerId) => async (dispatch) => {
    try {
        dispatch(startSyncProgressPending());
        api.get(`product-synchronization/progress?organizationId=${organizationId}&providerId=${providerId}`)
            .then((response) => {
                dispatch(setSyncProgress(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const editOrganizationCalendarProviderAction = (calendarProviderDto, success) => async (dispatch) => {
    try {
        dispatch(startSave());
        const organizationId = localStorage?.getItem('user')
            ? JSON.parse(localStorage?.getItem('user'))?.organizationId
            : '';
        api.put('organizations/calendar-provider/' + organizationId, calendarProviderDto)
            .then((response) => {
                dispatch(setOrganization(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

const mapOrganization = (organization) => {
    return organization
        ? {
              id: organization.id,
              name: organization.name,
              email: organization.email,
              pricingId: organization.pricingId,
              taxId: organization.taxId,
              primaryColor: organization.primaryColor,
              secondaryColor: organization.secondaryColor,
              logo: organization.logo,
              mainImage: organization.mainImage,
              syncCalendly: organization.syncCalendly,
              coupon: organization.coupon,
              country: organization.country,
              promotionCode: organization.promotionCode,

              ...(organization.providerType && { provider: organization.providerType }),
              ...(organization.providerId && { providerId: organization.providerId }),
              ...(organization.syncing !== null &&
                  organization.syncing !== undefined && { syncing: organization.syncing }),
              ...(!isNaN(+organization.totalProductsToSync) && {
                  totalProductsToSync: +organization.totalProductsToSync,
              }),
              ...(!isNaN(+organization.synchronizedProducts) && {
                  synchronizedProducts: +organization.synchronizedProducts,
              }),
              ...(!isNaN(+organization.syncErrors) && { syncErrors: +organization.syncErrors }),
              ...(organization.syncStartedAt && { syncStartedAt: organization.syncStartedAt }),
              ...(organization.licenseId && { licenseId: organization.licenseId }),

              billingInterval: organization.billingInterval,
              parentOrganizationId: organization.parentOrganizationId,
              indexerAccountId: organization.indexerAccountId,
              calendarProvider: organization.calendarProvider,
              isAuthorizedByTheProvider: organization.isAuthorizedByTheProvider,
              microsoftTenant: organization.microsoftTenant,
              isProfile: organization.isProfile,
              verticalSlug: organization.verticalSlug,
          }
        : {};
};

export const fetchOrganizationCouponAction =
    ({ organizationId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startFetchCoupon());
            api.get('organizations/coupon/' + organizationId)
                .then((response) => {
                    dispatch(setCoupon(response.data));
                    return response;
                })
                .catch((error) => {
                    dispatch(setErrorFetchCoupon(error));
                });
        } catch (e) {
            dispatch(stopFetchCoupon());
            return console.error('error', e);
        }
    };

export const fetchProductsSearchEngineAction = (id) => async (dispatch) => {
    try {
        dispatch(setProductsSearchEnginePending(true));
        api.get(`search-engine/${id}`)
            .then((response) => {
                dispatch(setProductsSearchEngine(response.data));
                dispatch(setProductsSearchEnginePending(false));
                return response;
            })
            .catch((error) => {
                dispatch(setProductsSearchEnginePending(false));
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(setProductsSearchEnginePending(false));
        return console.error('error', e);
    }
};

export const fetchHasReachedMaximunUsersAction = (id, onSuccess, onError = (e) => true) => {
    try {
        api.get(`organizations/maximun-users/${id}`)
            .then((response) => {
                onSuccess(response.data.hasReachedMaximumUsers);
                return response;
            })
            .catch((error) => {
                onError(error);
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const fetchNumberOfUsersIncludedByPlanAction = (id, onSuccess, onError = (e) => true) => {
    try {
        api.get(`organizations/users-included-plan/${id}`)
            .then((response) => {
                onSuccess(response.data.numberOfUsersIncludedByPlan);
                return response;
            })
            .catch((error) => {
                onError(error);
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const fetchOrganizationNextInvoiceAction =
    ({ onSuccess, onFailure } = {}) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            api.get(`/organizations/next-invoice/${state.organization.organization.id}`)
                .then((response) => {
                    dispatch(setNextInvoice(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchOrganizationPurchaseInvoiceAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch, getState) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            const state = getState();
            dispatch(setPendingPurchase(true));
            api.get(`/organizations/purchase-invoice/${state.organization.organization.id}` + query)
                .then((response) => {
                    dispatch(setPurchaseInvoice(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setPendingPurchase(false));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(setPendingPurchase(false));
            return console.error('error', e);
        }
    };

export const fetchChildrenOrganizationsAction =
    ({ onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(startFetchChildrenOrganizations());
                api.get('organizations/children')
                    .then((response) => {
                        dispatch(setChildrenOrganizations(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        setCatchError(error, onFailure);
                    });
            } catch (e) {
                dispatch(stop());
                return console.error('error', e);
            }
        };

export const addEmailIdentityAction = (email, onSuccess, onFailure) => async (dispatch) => {
    try {
        api.post(`/organizations/email-identity`, { email })
            .then(({ data }) => {
                if (onSuccess) {
                    onSuccess(data);
                }
                return data;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const emailIdentityResendVerificationAction = (email, onSuccess, onFailure) => async (dispatch) => {
    try {
        api.post(`/organizations/email-identity/resend-verification`, { email })
            .then(({ data }) => {
                if (onSuccess) {
                    onSuccess(data);
                }
                return data;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const deleteEmailIdentityAction = (email, onSuccess, onFailure) => async (dispatch) => {
    try {
        api.post(`/organizations/email-identity/delete`, { email })
            .then(({ data }) => {
                if (onSuccess) {
                    onSuccess(data);
                }
                return data;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const emailIdentityCheckVerificationStatusAction = (email, onSuccess, onFailure) => async (dispatch) => {
    try {
        api.post(`/organizations/email-identity/status`, { email })
            .then(({ data }) => {
                if (onSuccess) {
                    onSuccess(data);
                }
                return data;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const emailIdentitySetAsDefaultAction = (email, onSuccess, onFailure) => async (dispatch) => {
    try {
        api.post(`/organizations/email-identity/default`, { email })
            .then(({ data }) => {
                if (onSuccess) {
                    onSuccess(data);
                }
                return data;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const checkImportStatus = (organizationId, onSuccess, onFailure) => async (dispatch, getState) => {
    try {
        const storeKey = getState().organization.iCalendarStoreKeyForImporting;
        if (!storeKey) {
            return;
        }
        calendarApiV3
            .get(`importer/organizations/${organizationId}/import-data-status?storeKey=${storeKey}`)
            .then((response) => {
                if (response.data === 'finalized') {
                    onSuccess();
                }
            })
            .catch((error) => {
                console.error(error);
                onFailure();
            });
    } catch (error) {
        return console.error('error', error);
    }
};

export default organizationSlice.reducer;
