import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const formSlice = createSlice({
    name: 'form',
    initialState: {
        pending: false,
        savePending: false,
        forms: [],
        formConfig: null,
        error: null,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 0,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setForms: (state, action) => {
            state.pending = false;
            state.forms = action.payload.items;
            state.total = action.payload.total;
            state.count = action.payload.count;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addForm: (state, action) => {
            state.savePending = false;
            state.forms = [action.payload, ...state.forms];
        },
        editForm: (state, action) => {
            state.savePending = false;
            const index = state.forms.findIndex((webhook) => webhook.id === action.payload.id);
            if (index !== -1) {
                state.forms = [...state.forms.slice(0, index), action.payload, ...state.forms.slice(index + 1)];
            }
        },
        deleteForm: (state, action) => {
            state.pending = false;
            const index = state.forms.findIndex((user) => user.id === action.payload.id);
            if (index !== -1) {
                state.forms = [...state.forms.slice(0, index), ...state.forms.slice(index + 1)];
            }
        },
        setFormConfig: (state, action) => {
            state.formConfig = action.payload;
        },
    },
});

export const { start, stop, setForms, setFormConfig, setError, editForm, deleteForm, addForm, startSave, stopSave } =
    formSlice.actions;

export const getForms = (state) => state.form.forms;
export const getFormsTotal = (state) => state.form.total;
export const getFormsPending = (state) => state.form.pending;
export const getFormsSavePending = (state) => state.form.savePending;
export const getFormsError = (state) => state.form.error;
export const getFormConfig = (state) => state.form.formConfig;

export const fetchFormsAction =
    ({ queryParams, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            dispatch(start());
            api.get('forms' + query)
                .then((response) => {
                    dispatch(setForms(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const createFormAction =
    ({ data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('forms', data)
                .then((response) => {
                    dispatch(addForm(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure();
                    }
                    dispatch(setError(error));
                    dispatch(stopSave());
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const updateFormAction =
    ({ id, data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('forms/' + id, data)
                .then((response) => {
                    dispatch(editForm(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure();
                    }
                    dispatch(setError(error));
                    dispatch(stopSave());
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const deleteFormAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(start(id));
            api.delete('forms/' + id)
                .then((response) => {
                    dispatch(deleteForm(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setError(error));
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const fetchFormContextAction =
    ({ queryParams, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(queryParams);
            api.get('forms/context' + query)
                .then((response) => {
                    dispatch(setFormConfig(response.data));
                    onSuccess?.(response.data);
                    return response;
                })
                .catch((error) => {
                    onFailure?.(error);
                    dispatch(setError(error));
                });
        } catch (e) {
            return console.error('error', e);
        }
    };

export default formSlice.reducer;
