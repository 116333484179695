import { makeStyles } from 'tss-react/mui';

const useStylesPoolManager = makeStyles()((theme) => {
    return {
        poolManager: {
            zIndex: '1000000',
            display: 'flex',
            backgroundColor: '#fff',
            borderRadius: '0 0 10px 10px',
            position: 'fixed',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '0',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
            width: '340px',
            maxHeight: '82px',
            overflow: 'hidden',
            transition: 'all ease-in-out 0.3s',
        },
        queueInEvent: {
            zIndex: '1000000001',
            [theme.breakpoints.down('lg')]: {
                left: '16%',
            },

            [theme.breakpoints.up('lg')]: {
                left: '25%',
            },
        },
        queueIcon: {
            position: 'relative',
            width: '46px',
            height: '46px',
            borderRadius: '50%',
            backgroundColor: '#00E851',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '24px',

            '& svg': {
                transform: 'scale(1.5)',
            },
        },

        poolManagerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '50px',
            padding: '10px 24px',
            fontWeight: 'bold',
            fontSize: '14px',
            '& p': {
                marginTop: '24px',
            },
        },

        poolHeaderTitle: {
            display: 'flex',
            alignItems: 'center',

            gap: '1rem',
            '& p': {
                fontSize: '14px',
                textTransform: 'uppercase',
                fontWeight: '400',
                margin: '0',
            },

            '& button': {
                color: '#000',
                backgroundColor: '#fff',
                border: '1px solid #000',
                borderRadius: '20px',
                padding: '0 14px',
                height: '28px',
                fontSize: '12px',
                fontWeight: 'bold',
                textTransform: 'none',
            },
        },
        poolManagerOpen: {
            maxHeight: '600px',
        },

        poolContainer: {
            width: '100%',
        },
        collapsed: {
            position: 'absolute',
            bottom: '-48px',
            left: '50%',
            width: '65px',
            height: '48px',
            backgroundColor: '#fff',
            borderRadius: ' 0 0 10px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            padding: '0 5px',
            zIndex: '1000000',
            transition: 'all 0.3s',
            // boxShadow in left top and bottom
            boxShadow: '-5px 5px 5px rgba(0, 0, 0, 0.1)',
        },
        collapsedOpen: {
            padding: '0',
            left: '80%',
            top: '24px',
            width: '32px',
            height: '24px',
            boxShadow: 'none',

            '& svg': {
                transform: 'scale(1.5)',
            },
        },
        sessionList: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
            maxHeight: '400px',
            padding: '0 5px 20px 0px',
        },
        sessionItem: {
            padding: '10px 5px',
            display: 'flex',
            alignItems: 'center',
            width: '90%',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#F8F8F8',
                //third child
                '& div:nth-of-type(3)': {
                    opacity: '1',
                },
            },
            borderTop: '1px solid #E0E0E0',
        },
        avatar: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        sessionFlag: {
            position: 'absolute',
            bottom: '0',
            right: '-4px',
            width: '18px',
            height: '14px',
            fontSize: '18px',
            color: '#fff',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        sessionName: {
            marginLeft: '12px',
            fontWeight: 'bold',
            fontSize: '14px',
            width: '180px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',

            '& p': {
                margin: '2px 0',
            },

            '& span': {
                fontSize: '10px',
                fontWeight: 'normal',
            },
        },
        sessionEmail: {
            marginLeft: '12px',
            fontSize: '12px',
            width: '180px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '& p': {
                margin: '2px 0',
            },
        },
        sessionTime: {
            marginLeft: '12px',
            fontSize: '12px',
            width: '180px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontStyle: 'italic',
        },
        collapsedDisabled: {
            '& svg': {
                color: '#ccc',
            },
        },
        hidde: {
            display: 'none',
        },
        loadingContainer: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: '1000001',
        },
        showLoading: {
            display: 'flex',
        },
        callIcon: {
            position: 'relative',
        },
        collapseContent: {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            left: '0',
            color: '#000',

            '&:hover': {
                backgroundColor: '#F8F8F8',
            },
            '& svg': {
                transform: 'scale(1.2)',
            },
        },
        poolQty: {
            position: 'absolute',
            top: '-2px',
            right: '1px',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: '#f00',
            fontSize: '12px',
            color: '#fff',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        SessionCallIcon: {
            display: 'flex',
            opacity: '0',
            transition: 'opacity 0.4s',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '14px',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            backgroundColor: '#00E851',
            '& svg': {
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#29BC41',
                    borderRadius: '50%',
                    padding: '6px',
                },
            },
        },
        queueInEventHidden: {
            top: '-100px',
        },

        headerRow: {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        confirmDialog: {
            zIndex: '10000004',
        },
    };
});
export default useStylesPoolManager;
