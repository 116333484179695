import UserAvailability from 'app/user/UserAvailability';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeCustomDialogAction, openCustomDialog } from 'redux/slices/customDialogSlice';
import { addAvailabilityAction } from 'redux/slices/myAvailabilitySlice';
import MyAvailabilityForm from './MyAvailabilityForm';

const MyAvailabilityCreate = ({ userData }) => {
    const { userId, userView, userGroupId } = userData;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSubmit = (payload, callbackOk, callbackError) => {
        dispatch(
            addAvailabilityAction({
                calendarId: userId,
                dto: payload,
                onSuccess: () => {
                    callbackOk?.();
                    close();
                },
                onFailure: (error) => callbackError?.(error),
            }),
        );
    };

    const close = () => {
        if (userView) {
            dispatch(openCustomDialog(<UserAvailability userData={userData} />));
        } else {
            dispatch(closeCustomDialogAction());
        }
    };

    return (
        <MyAvailabilityForm
            onSubmit={handleSubmit}
            title={t('profile.myAvailability.form.createTitle')}
            onClose={close}
            userGroupId={userGroupId}
        />
    );
};

export default MyAvailabilityCreate;
