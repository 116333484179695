import { Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { differenceInMinutes, set } from 'date-fns/esm';
import { useFormFields } from 'libs/hooksLib';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import useStylesOCalendar from './styles/useStylesOCalendar.styles';

const OHoursRange = (props) => {
    const { id, initialHourBegin, initialHourEnd, onChange, onDelete, timeZone, isReadOnly } = props;

    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesOCalendar();
    const classes = { ...classes1, ...classes2 };
    const { t } = useTranslation();

    const [fields, handleFieldChange] = useFormFields({
        hourBegin: initialHourBegin,
        hourEnd: initialHourEnd,
    });

    const handleChange = () => {
        if (fields.hourBegin !== initialHourBegin || fields.hourEnd !== initialHourEnd) {
            const begin = new Date(moment.tz(`${fields.hourBegin}`, 'hh:mm', timeZone).format());
            let end = new Date(moment.tz(`${fields.hourEnd}`, 'hh:mm', timeZone).format());
            if (differenceInMinutes(end, begin) <= 30) {
                end = set(begin, {
                    hours: begin.getHours(),
                    minutes: begin.getMinutes() + 30,
                    seconds: 0,
                });
            }
            onChange({
                id,
                startTime: moment(begin.toISOString()).tz(timeZone).format('HH:mm'),
                endTime: moment(end.toISOString()).tz(timeZone).format('HH:mm'),
            });
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item md={5}>
                <CustomTextValidator
                    name="hourBegin"
                    fullWidth={true}
                    variant="outlined"
                    value={fields.hourBegin}
                    onChange={handleFieldChange}
                    onBlur={handleChange}
                    type="time"
                    InputProps={{
                        inputProps: { step: 300, readOnly: isReadOnly },
                    }}
                    validators={['required']}
                    errorMessages={[t('license.licenseForm.emailRequired')]}
                />
            </Grid>
            <Grid item md={5}>
                <CustomTextValidator
                    name="hourEnd"
                    fullWidth={true}
                    variant="outlined"
                    value={fields.hourEnd}
                    onChange={handleFieldChange}
                    onBlur={handleChange}
                    type="time"
                    InputProps={{
                        inputProps: { step: 300, readOnly: isReadOnly },
                    }}
                    validators={['required']}
                    errorMessages={[t('license.licenseForm.emailRequired')]}
                />
            </Grid>
            {Boolean(!isReadOnly) && (
                <Grid item md={2} className={classes.deleteTime}>
                    <IconButton aria-label="delete" onClick={onDelete} size="large">
                        <Delete fontSize="small" />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

export default OHoursRange;
