import { Triage } from '@onlive.site/front-core';
import { BookingNodeV2, FormNodeV2 } from '@onlive.site/front-core/lib/triage/nodes';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { closeNestedDialogAction } from 'redux/slices/nestedDialogSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getLanguage } from 'redux/slices/settingSlice';
import { styles as themeCustomization } from './styles/theme.customization';
import triageAppStyles from './styles/triageApp.styles';
const TriageApp = ({ service, callback }) => {
    const organization = useSelector(getOrganization);
    const organizationId = organization?.id;
    const triageId = useSettingValue(SettingsKey.SERVICE_APPOINTMENT_CREATE_TRIAGE_ID);
    const lang = useSelector(getLanguage);
    const contentRef = useRef();
    const { classes } = triageAppStyles();
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!organizationId || !triageId || !lang || !contentRef.current || !user) {
            return;
        }

        if (contentRef.current.children.length < 2) {
            const assets = service.relatedCalendars.map((calendar) => ({
                id: calendar.calendarId,
                tags: calendar.tags,
            }));

            const triageEl = new Triage(
                {
                    booking: BookingNodeV2,
                    form: FormNodeV2,
                },
                {
                    organizationId,
                    agentId: user.userId,
                    triageId,
                    lang,
                    organizationQueueEnabled: false,
                    personalQueueEnabled: false,
                    origin: '',
                    appearance: {
                        themeCustomization,
                        config: {
                            useFloatingLabels: false,
                            showBackButton: true,
                        },
                    },
                    serviceId: service?.id,
                    assets,
                    environment: process.env.REACT_APP_ENVIRONMENT,
                    relatedCondition: service?.relatedCondition,
                    duration: service?.duration,
                    initialNodeContext: {
                        geolocation: service?.extraFields,
                    },
                },
            );
            contentRef.current.appendChild(triageEl);
            window.addEventListener('onlive-triage-end', (event) => {
                callback(event.detail);
            });
            window.addEventListener('onlive-triage-exit', () => {
                dispatch(closeNestedDialogAction());
            });

            return () => {
                window.removeEventListener('onlive-triage-end', (event) => {
                    callback(event.detail);
                });
                window.removeEventListener('onlive-triage-exit', () => {
                    dispatch(closeNestedDialogAction());
                });
            };
        }
    }, [organizationId, triageId, lang, contentRef, user, service, callback, dispatch]);

    return (
        <div className={classes.content} ref={contentRef}>
            <div className={classes.titleContent}>
                <p className={classes.title}>{service?.name}</p>
            </div>
        </div>
    );
};

export default TriageApp;
