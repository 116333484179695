import { Add } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormLabel, Stack, Typography } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openNestedDialog } from 'redux/slices/nestedDialogSlice';
import DateOverrideItem from './DateOverrideItem';

const DateOverride = (props) => {
    const { fields, handleFieldChange } = props;

    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();
    const dateOverrides = fields?.configuration?.dateOverrides;
    const dispatch = useDispatch();

    const handleSubmit = (data, onSuccess) => {
        const newOverrides = { ...dateOverrides };
        newOverrides[data.date] = data.overrides;
        handleChange(newOverrides);
        onSuccess();
    };

    const handleDelete = (key) => {
        const newOverrides = { ...dateOverrides };
        if (dateOverrides.hasOwnProperty(key)) {
            delete newOverrides[key];
        }
        handleChange(newOverrides);
    };

    const handleChange = (newOverrides) => {
        handleFieldChange({
            target: {
                name: 'configuration',
                value: { ...fields?.configuration, dateOverrides: newOverrides },
            },
        });
    };

    const handleAdd = () => {
        dispatch(openNestedDialog(<DateOverrideItem onSubmit={handleSubmit} />));
    };

    return (
        <Stack direction="column" className={flexBox.paddingTop2} spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormLabel>{t('calendar.addADateOverride').toUpperCase()}</FormLabel>
                <CustomTooltip arrow title={t('calendar.common.add')} placement={'bottom'}>
                    <CustomIconButton aria-label="delete" onClick={() => handleAdd()} size="small">
                        <Add />
                    </CustomIconButton>
                </CustomTooltip>
            </Stack>
            {Object.keys(dateOverrides)?.map((key) => (
                <Stack
                    direction="row"
                    key={key}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={cx(flexBox.borderRadius1, onlive.borderSecondaryDark, flexBox.padding1)}
                >
                    <Typography variant="subtitle2" fontWeight={400}>
                        {moment(key).format('DD / MM / YYYY')}
                    </Typography>

                    <Stack direction="column" key={key}>
                        {dateOverrides[key]?.map((hour, idx) => (
                            <Stack key={idx} direction="row" spacing={2}>
                                <Typography variant="subtitle2" fontWeight={400}>
                                    {hour[0]}
                                </Typography>
                                <Typography variant="subtitle2" fontWeight={400}>
                                    {t('services.form.dateTo')}
                                </Typography>
                                <Typography variant="subtitle2" fontWeight={400}>
                                    {hour[1]}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>

                    <CustomTooltip arrow title={t('contact.remove')} placement={'bottom'}>
                        <CustomIconButton aria-label="delete" onClick={() => handleDelete(key)} size="small">
                            <DeleteOutlineOutlinedIcon />
                        </CustomIconButton>
                    </CustomTooltip>
                </Stack>
            ))}
        </Stack>
    );
};

export default DateOverride;
