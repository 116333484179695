import { FormGroup, FormLabel, Stack } from '@mui/material';
import CustomTextValidator from 'common/components/CustomTextValidators';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useStylesForm from '../../../../common/components/styles/useStylesForm.styles';

const CalendarWebhooks = (props) => {
    const {
        fields: { createBookingUrl, cancelBookingUrl },
        onChange,
    } = props;

    const { classes } = useStylesForm();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Stack spacing={2} mb={3}>
                <FormGroup className={classes.formGroup}>
                    <FormLabel>{`${t('profile.settings.calendar.webhooks.createBooking.label')}`}</FormLabel>
                    <CustomTextValidator
                        name="createBookingUrl"
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        value={createBookingUrl}
                        type="text"
                        onChange={onChange}
                    />
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{`${t('profile.settings.calendar.webhooks.cancelBooking.label')}`}</FormLabel>
                    <CustomTextValidator
                        name="cancelBookingUrl"
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        value={cancelBookingUrl}
                        type="text"
                        onChange={onChange}
                    />
                </FormGroup>
            </Stack>
        </Fragment>
    );
};

export default CalendarWebhooks;
