import { createSlice } from '@reduxjs/toolkit';
import api from 'api';

export const triageSlice = createSlice({
    name: 'triage',
    initialState: {
        pending: false,
        savePending: false,
        triages: [],
        error: null,
        currentPage: 0,
        totalPages: 0,
        count: 0,
        total: 0,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setTriages: (state, action) => {
            state.pending = false;
            state.triages = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.count = action.payload.count;
            state.total = action.payload.total;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        addTriage: (state, action) => {
            state.savePending = false;
            state.triages = [...state.triages, action.payload];
        },
        editTriage: (state, action) => {
            state.savePending = false;
            const index = state.triages.findIndex((triage) => triage._id === action.payload._id);
            if (index !== -1) {
                state.triages = [...state.triages.slice(0, index), action.payload, ...state.triages.slice(index + 1)];
            }
        },
        deleteTriage: (state, action) => {
            state.pending = false;
            const index = state.triages.findIndex((triage) => triage._id === action.payload._id);
            if (index !== -1) {
                state.triages = [...state.triages.slice(0, index), ...state.triages.slice(index + 1)];
            }
        },
        resetTriages: (state, action) => {
            state.triages = [];
            state.currentPage = 0;
            state.totalPages = 0;
            state.count = 0;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    addTriage,
    stop,
    setTriages,
    setError,
    editTriage,
    deleteTriage,
    resetTriages,
    startSave,
    stopSave,
    setSaveError,
} = triageSlice.actions;

export const getTriages = (state) => state.triage.triages;
export const getTriagesTotal = (state) => state.triage.total;
export const getTriagesPending = (state) => state.triage.pending;
export const getTriagesError = (state) => state.triage.error;
export const getTriagesSavePending = (state) => state.triage.savePending;

export const fetchTriagesAction =
    ({ /*queryParams*/ organizationId, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(start());
            //let query = transformFiltersToQuery(queryParams);
            api.get(`triages/organization/${organizationId}` /*+ query*/)
                .then((response) => {
                    dispatch(
                        setTriages({
                            items: response.data,
                            currentPage: 1,
                            totalPages: 1,
                            count: response.data.length,
                        }),
                    );
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addTriageAction = (triageDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post(`triages`, triageDto)
            .then((response) => {
                dispatch(addTriage(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const editTriageAction = (triageDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('triages', triageDto)
            .then((response) => {
                dispatch(editTriage(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                console.error(error);
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteTriageAction = (triageId, success, failure) => async (dispatch) => {
    try {
        dispatch(start());
        api.delete(`triages/${triageId}`)
            .then((response) => {
                dispatch(deleteTriage(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default triageSlice.reducer;
