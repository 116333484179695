import React from 'react';
import config from '../../config';
import FileInput from './FileInput';

/**
 * Selects images with the configured formats.
 * If no formats are configured, JPEG will be set as default format.
 * 
 * @param {*} param0 
 * @returns 
 */
const ImageFileInput = ({
    uploadAssetFn,
    disabled,
    inputId,
    // multiple
}) => {
    const imageFormats = config?.media?.image?.allowedFormats || ["JPEG"];

    return (
        <div>
            <FileInput
                uploadAssetFn={uploadAssetFn}
                disabled={disabled}
                inputId={inputId}
                imageFormats={imageFormats}
                videoFormats={[]}
                textFormats={[]}
                // multiple={multiple}
            />
        </div>
    );
};

export default ImageFileInput;