import { red } from '@mui/material/colors';

import { createTheme } from '@mui/material';

const COMMON_BLACK = '#060B1A';
const COMMON_GRAY = '#DEDEDE';
const SOFT_BLACK = '#00000021';
const COMMON_RED = '#FF0000';

const defaultTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            ls: 290,
            lt: 380,
            st: 520,
            sm: 640,
            ms: 800,
            md: 1080,
            mg: 1280,
            lg: 1460,
            xg: 1640,
            xl: 1920,
        },
    },
    typography: {
        pxToRem: (px) => `${px}px`,
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: 12,
        color: '#000',
    },
    palette: {
        primary: {
            main: COMMON_RED,
        },
        secondary: {
            main: '#F8F8F8',
            dark: '#EFEFEF',
            medium: '#9D9D9D',
            light: '#F5F5F5',
            contrastText: '#000000',
        },
        default: {
            main: '#fff',
            dark: '#00000021',
            light: '#F5F5F5',
            contrastText: '#000000',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#B9B9B9',
            paper: '#FFFFFF',
            resource: '#FFFFFF',
            red: COMMON_RED,
        },
        common: {
            black: COMMON_BLACK,
            softBlack: SOFT_BLACK,
            white: '#FFFFFF',
            red: COMMON_RED,
            gray: '#808080',
            mediumGray: COMMON_GRAY,
            link: '#00CEF2',
            linkDark: '#0080D0',
            lightGray: '#EFEFEF',
            slightlyGray: '#F9F9F9',
            lime: '#A3FFBC',
            green: '#16C144',
            lightGreen: '#07E751',
            disabled: '#777777',
            translucent: 'rgb(0, 0, 0, 0.26)',
            translucentDark: 'rgba(0, 0, 0, 0.75)',
            lightBlue: '#E3F5FF',
            //
            neutral100: 'hsl(240 4.8% 95.9%)',
        },
        tonalOffset: {
            light: 0.8,
            dark: 0.2,
        },
    },
    custom: {
        borderRadius: '8px',
        borderRadiusLarge: '10px',
        borderRadiusMobile: '10px',
        spaceLarge: '10px',
        defaultBorder: '1px solid #ffffff21',
        lightBorder: '1px solid #ffffff50',
        lightBorder2: '1px solid #ffffff80',
        darkBorder: '1px solid #00000021',
        regularText: { fontSize: '0.75rem' },
        blackBorder: '1px solid #000',
        video: {
            wide: {
                borderRadius: '15px',
                '& video': {
                    borderRadius: '15px',
                },
            },
            medium: {
                borderRadius: '12px',
                '& video': {
                    borderRadius: '12px',
                },
            },
        },
        boxShadow: '2px 6px 6px 0px rgba(0,0,0,0.45)',
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    color: '#2E2E2E',
                },
                tooltip: {
                    backgroundColor: '#2E2E2E',
                    textAlign: 'center',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                    textTransform: 'uppercase',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    marginRight: '-4px',
                    '& svg': {
                        color: '#000',
                        fontSize: '20px',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    maxHeight: '400px',
                },
            },
        },
    },
});

const { breakpoints, typography } = defaultTheme;

const theme = {
    ...defaultTheme,
    typography: {
        ...typography,
        caption: {
            fontSize: 7,
            [breakpoints.up('xl')]: {
                fontSize: 8,
            },
        },
        body1: {
            fontSize: 10,
            [breakpoints.up('xl')]: {
                fontSize: 12,
            },
        },
        body2: {
            fontSize: 9,
            [breakpoints.up('xl')]: {
                fontSize: 10,
            },
        },
        subtitle1: {
            fontSize: 14,
            [breakpoints.up('xl')]: {
                fontSize: 16,
            },
        },
        subtitle2: {
            fontSize: 12,
            [breakpoints.up('xl')]: {
                fontSize: 14,
            },
        },
        onliveH6: {
            fontSize: 16,
            [breakpoints.up('xl')]: {
                fontSize: 18,
            },
        },
        onliveH5: {
            fontSize: 18,
            [breakpoints.up('xl')]: {
                fontSize: 20,
            },
        },
        onliveH4: {
            fontSize: 20,
            [breakpoints.up('xl')]: {
                fontSize: 24,
            },
        },
        onliveH3: {
            fontSize: 24,
            [breakpoints.up('xl')]: {
                fontSize: 32,
            },
        },
        onliveH2: {
            fontSize: 34,
            [breakpoints.up('xl')]: {
                fontSize: 40,
            },
        },
        onliveH1: {
            fontSize: 48,
            [breakpoints.up('xl')]: {
                fontSize: 56,
            },
        },
    },
};

export default theme;
