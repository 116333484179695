import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import ActionButton from './ActionButton';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            minWidth: theme.spacing(56),
        },
        dialogContent: {
            padding: theme.spacing(3, 5, 1),
            display: 'flex',
            flexDirection: 'column',
        },
        dialogActions: {
            padding: theme.spacing(0, 5, 2),
        },
        actions: {
            minWidth: theme.spacing(5),
        },
    };
});

const YesNoDialog = (props) => {
    const {
        openDialog,
        title,
        messageToConfirm,
        yesButtonText,
        disableYes,
        noButtonText,
        yesButtonCallback,
        noButtonCallback,
        dialogContent,
        children,
        delayToEnable = 0,
        hideNoButton = false,
    } = props;

    const { t } = useTranslation();

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();

    const [open, setOpen] = useState(openDialog);
    const [counter, setCounter] = useState(delayToEnable);

    const handleAnswer = (yesButtonClicked) => {
        if (yesButtonClicked && yesButtonCallback) {
            yesButtonCallback();
        }

        if (!yesButtonClicked && noButtonCallback) {
            noButtonCallback();
        }

        setOpen(false);
    };

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                paper: cx(flexBox.borderRadius2, onlive.boxShadow, classes.root),
            }}
        >
            <DialogContent className={classes.dialogContent}>
                <Typography variant="onliveH5" fontWeight="500" className={flexBox.paddingBottom1}>
                    {title}
                </Typography>
                {dialogContent}
                <Typography variant="subtitle2" fontWeight={400} className={flexBox.marginTop1}>
                    {messageToConfirm}
                </Typography>
                {children}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <div className={cx(flexBox.flex, flexBox.gap1, flexBox.justifyContentEnd, flexBox.paddingTop2)}>
                    {hideNoButton === false && (
                        <ActionButton
                            onClick={() => {
                                handleAnswer(false);
                            }}
                            color="default"
                            fitWidth
                        >
                            <span className={classes.actions}>
                                {noButtonText || t('common.components.yesNoDialog.no')}
                            </span>
                        </ActionButton>
                    )}

                    <ActionButton
                        color="primary"
                        type="submit"
                        autoFocus
                        fitWidth
                        disabled={counter > 0 || disableYes}
                        onClick={() => {
                            handleAnswer(true);
                        }}
                    >
                        <span className={classes.actions}>{`${
                            yesButtonText || t('common.components.yesNoDialog.yes')
                        } ${counter > 0 ? `(${counter})` : ''}`}</span>
                    </ActionButton>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default YesNoDialog;
