import { Stack, Tooltip } from '@mui/material';
import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    const mTooltip = theme.spacing(1);
    return {
        tooltip: {
            '&.bottom': {
                transformOrigin: 'center top',
                marginTop: mTooltip,
            },
            '&.top': {
                transformOrigin: 'center bottom',
                marginBottom: mTooltip,
            },
            '&.right': {
                transformOrigin: 'left top',
                marginLeft: mTooltip,
            },
            '&.left': {
                transformOrigin: 'right top',
                marginRight: mTooltip,
            },
        },
        arrow: {
            '&.bottom': {
                top: 0,
                marginTop: '-0.71em',
                '&:before': {
                    transformOrigin: '0 100%',
                },
            },
            '&.top': {
                bottom: 0,
                marginBottom: '-0.71em',
                '&:before': {
                    transformOrigin: '100% 0',
                },
            },
            '&.right': {
                left: 0,
                marginLeft: '-0.71em',
                height: '1em',
                width: '0.71em',
                '&:before': {
                    transformOrigin: '100% 100%',
                },
            },
            '&.left': {
                right: 0,
                marginRight: '-0.71em',
                height: '1em',
                width: '0.71em',
                '&:before': {
                    transformOrigin: '0 0',
                },
            },
        },
    };
});

const CustomTooltip = (props) => {
    const { className, children, title, fullWidth, placement = 'bottom', overflowHidden = false, ...rest } = props;
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    let tooltipClass = cx(classes.tooltip, placement);
    let arrowClass = cx(classes.arrow, placement);

    const getWidth = () => {
        return title && typeof title === 'string' && title.length >= 17 ? '6rem' : 'auto';
    };

    const customComponentProps = {
        tooltip: {
            sx: {
                width: fullWidth ? '100%' : getWidth(),
            },
        },
    };

    return (
        <Tooltip
            {...rest}
            title={title}
            placement={placement}
            componentsProps={customComponentProps}
            classes={{ tooltip: tooltipClass, arrow: arrowClass, className }}
        >
            <Stack className={cx(overflowHidden && flexBox.overflowHidden)}>{children}</Stack>
        </Tooltip>
    );
};

export default CustomTooltip;
