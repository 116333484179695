import BaseCodeInputMonacoEditor from './BaseCodeInputMonacoEditor';

/**
 * This a CodeMonaco based component.
 *
 * onChange (value, errors) => {} value: String. errors: String array.
 * Each item is an error messages with the line and column.
 *
 * value JSON object or a string result of JSON.stringify.
 * Otherwise, an error will be thrown.
 *
 * height Just some CSS valid height property.
 */
const JsonCodeInputMonacoEditor = (props) => {
    const { value, onChange, height, width } = props;

    const propagateChange = (content, codeErrors) => {
        try {
            // Propagate value as Object.
            onChange(JSON.parse(content), codeErrors);
        } catch (error) {
            // Null value is propagated in case of errors.
            onChange(null, codeErrors);
        }
    };

    const getDefaultValue = () => {
        const valueType = typeof value;

        if (valueType === 'object') {
            return JSON.stringify(value, null, '    ');
        } else if (valueType === 'string') {
            return value;
        } else {
            throw new Error(
                `JsonCodeInputMonacoEditor expects a JSON or a stringified JSON as value. ` +
                    `GIVEN VALUE: ${value}' TYPE: ${valueType}'`,
            );
        }
    };

    return (
        <div style={{ margin: '0 0 10px 0' }}>
            <div>
                <BaseCodeInputMonacoEditor
                    height={height || '200px'}
                    width={width}
                    defaultLanguage="json"
                    defaultValue={getDefaultValue()}
                    onChange={(content, errors) => propagateChange(content, errors)}
                />
            </div>
        </div>
    );
};

export default JsonCodeInputMonacoEditor;
