import { createSlice } from '@reduxjs/toolkit';
import { UUID } from 'uuidjs';

export const nestedDialogSlice = createSlice({
    name: 'nestedDialog',
    initialState: {
        dialogOpen: false,
        dialogs: [],
        closeTopPosition: '0px',
        onClose: false,
    },
    reducers: {
        backdropClickHandler: (state, action) => {
            const onCancelProp = state.dialogs[state.dialogs.length - 1]?.content?.props?.onCancel;
            state.dialogs = state.dialogs.filter((_, i) => i !== state.dialogs.length - 1);

            if (state.dialogs.length === 0) {
                state.dialogOpen = false;
                state.onClose = false;
                state.dialogs = [];
            }
            if (typeof onCancelProp === 'function') {
                try {
                    onCancelProp(null);
                } catch (e) {
                    console.error(`Error executing nested dialog cancel callback: ${e.message}`);
                }
            }
        },
        onCloseHandler: (state, action) => {
            state.onClose = true;
            const onCancelProp = state.dialogs[state.dialogs.length - 1]?.content?.props?.onCancel;
            if (typeof onCancelProp === 'function') {
                try {
                    onCancelProp(null);
                } catch (e) {
                    console.error(`Error executing nested dialog cancel callback: ${e.message}`);
                }
            }
        },
        cancelCloseHandler: (state, action) => {
            state.onClose = false;
        },
        setCloseButtonTopPosition: (state, action) => {
            state.closeTopPosition = action.payload;
        },
        addContent: (state, action) => {
            const id = UUID.generate();
            state.dialogOpen = true;
            state.dialogs = [...state.dialogs, { id, content: action.payload }];
        },
        setStateData: (state, action) => {
            const { stateData } = action.payload;
            state.dialogs[state.dialogs.length - 1].stateData = stateData;
        },
    },
});

export const {
    backdropClickHandler,
    setCloseButtonTopPosition,
    onCloseHandler,
    cancelCloseHandler,
    addContent,
    setStateData,
} = nestedDialogSlice.actions;

export const getIsNestedDialogOpen = (state) => state.nestedDialog.dialogOpen;
export const getIsNestedDialogOnClose = (state) => state.nestedDialog.onClose;
export const getNestedDialogContent = (state) => state.nestedDialog.dialogs.slice(-1)[0]?.content || null;
export const getNestedDialogStateData = (state) => state.nestedDialog.dialogs.slice(-1)[0]?.stateData || null;

export const openNestedDialog =
    (content, closeTopPosition, stateData = null) =>
    (dispatch) => {
        if (closeTopPosition) {
            dispatch(setCloseButtonTopPosition(closeTopPosition));
        }
        if (stateData) {
            dispatch(setStateData({ stateData }));
        }
        dispatch(addContent(content));
    };

export const closeNestedDialogAction = () => backdropClickHandler();
export const cancelCloseNestedDialogAction = () => cancelCloseHandler();

export default nestedDialogSlice.reducer;
