import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import CircularProgressWithLabel from 'common/components/CircularProgressWithLabel';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import ImageContainer from 'common/components/ImageContainer';
import ImageFileInput from 'common/components/ImageFileInput';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useAbortController } from 'common/hooks';
import { handleUploadFileSignedUrl } from 'common/utils/file';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ServiceImage = ({ handleFieldChange, image }) => {
    const { classes } = useStylesForm();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const getSignal = useAbortController();

    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploadingImage] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(image);

    const uploadImageFile = (file, setProgress, setUploading, callback) => {
        handleUploadFileSignedUrl({
            file,
            size: 1024 * 1024 * 100, // 100MB
            onInit: (size) => {
                setUploading(true);
                setProgress(0);
            },
            onProgress: (loaded, total) => {
                setProgress(Math.min(100, Math.round((loaded / total) * 100)));
            },
            onComplete: (url) => {
                setUploading(false);
                callback(url);
            },
            onError: (error) => {
                setUploading(false);
                if (error.code !== 'ERR_CANCELED') {
                    enqueueSnackbar(t('app.common.uploadError'), {
                        variant: 'error',
                    });
                }
            },
            bucketType: 'resources',
            signal: getSignal(),
        });
    };

    const handleUploadFile = (imgFile) => {
        if (imgFile.size / 1000 > 500) {
            enqueueSnackbar(t('upload.imageSizeError', { size: '500kb' }), {
                variant: 'error',
            });
        } else {
            uploadImageFile(imgFile, setUploadProgress, setUploadingImage, (imageUrl) => {
                handleFieldChange({
                    target: {
                        name: 'image',
                        value: imageUrl,
                    },
                });
                setUploadedImage(imageUrl);
            });
        }
    };

    return (
        <Stack direction="column">
            {uploadedImage && <ImageContainer asset={uploadedImage} deleteButton={false} size={'small'} />}
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <ImageFileInput
                    inputId={'raised-button-image-file'}
                    uploadAssetFn={handleUploadFile}
                    disabled={uploading}
                />

                <label htmlFor="raised-button-image-file">
                    <CustomTooltip arrow placement={'bottom'}>
                        <CustomIconButton size="small" component="span">
                            {uploading && <CircularProgressWithLabel value={uploadProgress} />}
                            {!uploading && <Add />}
                        </CustomIconButton>
                    </CustomTooltip>
                </label>
            </Stack>

            <span className={classes.imagesRecommendation}>{t('common.components.profileImage.recommendedImage')}</span>
        </Stack>
    );
};

export default ServiceImage;
