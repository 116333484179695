import axios from 'axios';
import config from 'config';

const http = axios.create({
    baseURL: config.api.baseURL,
    withCredentials: true,
    // headers: {
    //     Authorization: getAuthorization(),
    // }
});

class OnliveApi {
    // getCommonHeaders() {
    //     return {
    //         headers: {
    //             // Authorization: getAuthorization(),
    //         }
    //     }
    // }

    /**
     *
     * @param ids String array of products ids.
     * @returns Array of Products.
     */
    getProducts(ids) {
        const requestConfig = {
            // To avoid Authorization lost due config override.
            // ...this.getCommonHeaders(),

            params: {
                ids: ids.join(),
            },
        };

        return http
            .get('products', requestConfig)
            .then(({ data }) => data)
            .catch((error) => {
                console.error('Error getting products.');
                console.error(error);
            });
    }

    getEventEvaluationDetails(eventId) {
        return http
            .get(`event-votes/evaluation-details/${eventId}`)
            .then(({ data }) => data)
            .catch((error) => {
                console.error('Error getting event evaluation details.');
                console.error(error);
            });
    }

    getCommunicationLogChangeHistory(communicationLogId) {
        return http
            .get(`communication-logs/change-history/${communicationLogId}`)
            .then(({ data }) => data)
            .catch((error) => {
                console.error('Error getting change history.');
                console.error(error);
            });
    }

    async getVspClusterData() {
        try {
            const response = await http.get('vsp/cluster');
            return response.data;
        } catch (error) {
            console.error('Error getting VSP cluster data.');
            throw error;
        }
    }

    async setVspDefaultCluster(clusterIdStr) {
        try {
            const response = await http.put('vsp/cluster', { defaultCluster: clusterIdStr });
            return response?.data?.success;
        } catch (error) {
            console.error('Error setting VSP cluster.');
            throw error;
        }
    }
}

const onliveApi = new OnliveApi();

export default onliveApi;
