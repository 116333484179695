import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import {transformFiltersToQuery} from "./utils/transformFilterToQuery";

export const interactiveAppSlice = createSlice({
    name: 'interactiveApp',
    initialState: {
        pending: false,
        interactiveApps: [],
        error: null,
        currentPage: 0,
        totalPages: 0,
        count: 0,
        savePending: false,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        setInteractiveApps: (state, action) => {
            state.pending = false;
            state.interactiveApps = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
            state.count = action.payload.count;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        addInteractiveApp: (state, action) => {
            state.savePending = false;
            state.interactiveApps = [...state.interactiveApps, action.payload];
        },
        editInteractiveApp: (state, action) => {
            state.savePending = false;
            const index = state.interactiveApps.findIndex((interactiveApp) => interactiveApp.id === action.payload.id);
            if (index !== -1) {
                state.interactiveApps = [
                    ...state.interactiveApps.slice(0, index),
                    action.payload,
                    ...state.interactiveApps.slice(index + 1),
                ];
            }
        },
        deleteInteractiveApp: (state, action) => {
            state.pending = false;
            const index = state.interactiveApps.findIndex((interactiveApp) => interactiveApp.id === action.payload.id);
            if (index !== -1) {
                state.interactiveApps = [
                    ...state.interactiveApps.slice(0, index),
                    ...state.interactiveApps.slice(index + 1),
                ];
            }
        },
        resetInteractiveApps: (state, action) => {
            state.interactiveApps = [];
            state.currentPage = 0;
            state.totalPages = 0;
            state.count = 0;
        },
    },
});

export const {
    start,
    startSave,
    addInteractiveApp,
    stop,
    stopSave,
    setInteractiveApps,
    setError,
    editInteractiveApp,
    deleteInteractiveApp,
    resetInteractiveApps,
    setSaveError,
} = interactiveAppSlice.actions;

export const getInteractiveApps = (state) => state.interactiveApp.interactiveApps;
export const getInteractiveAppsPending = (state) => state.interactiveApp.pending;
export const getInteractiveAppsSavePending = (state) => state.interactiveApp.savePending;
export const getInteractiveAppsError = (state) => state.interactiveApp.error;

export const fetchInteractiveAppsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get('interactive-apps' + query)
                .then((response) => {
                    dispatch(setInteractiveApps(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error('error', e);
        }
    };

export const addInteractiveAppAction = (interactiveAppDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.post(`interactive-apps`, interactiveAppDto)
            .then((response) => {
                dispatch(addInteractiveApp(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const editInteractiveAppAction = (interactiveAppId, interactiveAppDto, success, failure) => async (dispatch) => {
    try {
        dispatch(startSave(interactiveAppId));
        api.put('interactive-apps/' + interactiveAppId, interactiveAppDto)
            .then((response) => {
                dispatch(editInteractiveApp(response.data));
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteInteractiveAppAction = (interactiveAppId) => async (dispatch) => {
    try {
        dispatch(start(interactiveAppId));
        api.delete('interactive-apps/' + interactiveAppId)
            .then((response) => {
                dispatch(deleteInteractiveApp(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default interactiveAppSlice.reducer;
