import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const SimpleCodeErrorView = (props) => {
    const {
        id, // For React child keys
        mainErrorMessage,
        errorList
    } = props;

    const showErrors = () => (errorList && Array.isArray(errorList) && errorList.length > 0);

    return (
        <div>
            {
                showErrors() &&
                <Accordion
                    aria-controls="panel2a-content"
                    square={true}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography color={'red'}>
                            {
                                mainErrorMessage || "ERROR"
                            }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            errorList.map((message, i) => (
                                <Typography key={`${id}.errors.${i}`} color={'darkred'}>{message}</Typography>
                            ))
                        }
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    );
};

export default SimpleCodeErrorView;