import { Autocomplete, Chip, TextField } from '@mui/material';
import { useState } from 'react';

const ChipsInput = ({ name, label, placeholder, value, onChange, onBlur, ...props }) => {
    // const [valueState, setValueState] = React.useState(value[0]);
    const [inputValue, setInputValue] = useState('');

    return (
        <Autocomplete
            multiple
            id={`chips-${name}`}
            options={[]}
            value={value}
            freeSolo
            onChange={(e, val) => onChange(val)}
            onBlur={onBlur}
            renderTags={(val, getTagProps) =>
                val.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
            }
            renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
            inputValue={inputValue}
            onInputChange={(reason, newValue) => {
                if (reason === 'clear') {
                    onChange([]);
                } else {
                    setInputValue(newValue);
                }
            }}
            {...props}
        />
    );
};

export default ChipsInput;
