import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'applications';

export const applicationSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        savePending: false,
        applications: [],
        uninstallableApplications: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        addApplication: (state, action) => {
            state.savePending = false;
            state.applications = [action.payload, ...state.applications];
        },
        editApplication: (state, action) => {
            state.savePending = false;
            const index = state.applications.findIndex((application) => application.id === action.payload.id);
            if (index !== -1) {
                state.applications = [
                    ...state.applications.slice(0, index),
                    action.payload,
                    ...state.applications.slice(index + 1),
                ];
            }
        },
        updateUninstallable: (state) => {
            state.uninstallableApplications = state.applications.filter((application) => !application.installable);
        },
        deleteApplication: (state, action) => {
            state.deletePending = false;
            const index = state.applications.findIndex((application) => application.id === action.payload.id);
            if (index !== -1) {
                state.applications = [...state.applications.slice(0, index), ...state.applications.slice(index + 1)];
            }
        },
        setApplications: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.applications = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setUninstallableApplications: (state, action) => {
            state.pending = false;
            state.uninstallableApplications = action.payload.items;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
    },
});

export const {
    start,
    stop,
    setApplications,
    setUninstallableApplications,
    setError,
    addApplication,
    editApplication,
    updateUninstallable,
    deleteApplication,
    startDelete,
    startSave,
    setSaveError,
    stopSave,
} = applicationSlice.actions;

export const getApplications = (state) => state.applications.applications;
export const getUninstallableApplications = (state) => state.applications.uninstallableApplications;
export const getApplicationsPending = (state) => state.applications.pending;
export const getApplicationsTotal = (state) => state.applications.total;
export const getDeletePending = (state) => state.applications.deletePending;
export const getDeleteId = (state) => state.applications.deleteId;
export const getApplicationsError = (state) => state.applications.error;
export const getSavePending = (state) => state.applications.savePending;
export const getApplicationById = (id) => (state) => state.applications.applications.find((item) => item.id === id);

export const fetchApplicationsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get(resource + query)
                .then((response) => {
                    dispatch(setApplications(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const fetchUninstallableApplicationsAction =
    ({ onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery({ installable: 'false' });
            dispatch(start());
            api.get(resource + query)
                .then((response) => {
                    dispatch(setUninstallableApplications(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const addApplicationAction =
    ({ dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post(resource, dataDto)
                .then((response) => {
                    dispatch(addApplication(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const editApplicationAction =
    ({ id, dataDto, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put(`${resource}/${id}`, dataDto)
                .then((response) => {
                    dispatch(editApplication(response.data));
                    dispatch(updateUninstallable());
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const deleteApplicationAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete(resource + '/' + id)
                .then((response) => {
                    dispatch(deleteApplication(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            return console.error(e.message);
        }
    };

export default applicationSlice.reducer;
