import { makeStyles } from 'tss-react/mui';

const useStylesTextButton = makeStyles()((theme) => {
    return {
        root: {
            height: 'min-content',
            width: 'auto',
            padding: '0',
            textTransform: 'none',
            minWidth: '0',
            fontWeight: 'normal',
            fontSize: '12px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px'
            }
        },

        primary: {
            color: theme.palette.primary.main
        },

        secondary: {
            color: '#3283D3',
        },
        
        big: {
          fontSize: '15px'
        },

        default: {
            color: '#000000',
            opacity: '0.5'
        }
    };
});

export default useStylesTextButton;
