import { useSelector } from 'react-redux';
import { getAddOnsPaid, getMainAddOnsPaid } from 'redux/slices/addOnsPaidSlice';
import { getAuthLicense, getAuthMainLicense, getUser } from 'redux/slices/authSlice';

export function useAuthLicenseAddOns() {
    const license = useSelector(getAuthLicense);
    const mainLicense = useSelector(getAuthMainLicense);
    const addOns = useSelector(getAddOnsPaid);
    const mainAddOns = useSelector(getMainAddOnsPaid);
    const user = useSelector(getUser);

    const isAuthorize = (permissions = [], main = false, some = false) => {
        const isMain = main && user.role !== 'root';
        let authorize = true;
        const services =
            isMain && user.mainOrganizationId !== user.organizationId ? mainLicense?.services : license?.services;

        for (const permission of permissions) {
            let obj = { ...services };
            const keys = permission.split('.');
            for (const key of keys) {
                if (key === '*') {
                    obj = Object.values(obj).some((v) => v);
                } else {
                    obj = obj?.[`${key}`];
                }
            }

            const isAuthorizeByService = Boolean(obj);

            const isAuthorizeByLicense =
                isMain && user.mainOrganizationId !== user.organizationId
                    ? mainLicense?.[permission]
                    : license?.[permission];

            const isAuthorizeByAddOns =
                (isMain && user.mainOrganizationId !== user.organizationId ? mainAddOns : addOns)?.findIndex(
                    (addOn) => addOn.key === permission,
                ) !== -1;

            if (some && (isAuthorizeByLicense || isAuthorizeByAddOns || isAuthorizeByService)) {
                return true;
            }

            authorize = authorize && (isAuthorizeByLicense || isAuthorizeByAddOns || isAuthorizeByService);
        }
        return authorize;
    };

    return isAuthorize;
}
