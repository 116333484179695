const AUDIENCE_ENTRIES = "Audience.entries";
const AUDIENCE_EVENT_VIEWERS = "Audience.viewersCount";
const AUDIENCE_TOTAL_VIEWERS = "Audience.totalViewersCount";
const AUDIENCE_PREVIEW_VIEWERS = "Audience.previewViewersCount";
const AUDIENCE_REGISTERS = "Audience.registerCount";
const AUDIENCE_REGISTERS_RATIO = "Audience.registerRatio";
const AUDIENCE_UNIQUE_ACTORS = "Audience.uniqueActors";
const AUDIENCE_COUNT = "Audience.count";

const AUDIENCE_DIMENSION_EVENT_ID = "Audience.eventid";

const AudienceFields = {
    AUDIENCE_ENTRIES,
    AUDIENCE_EVENT_VIEWERS,
    AUDIENCE_TOTAL_VIEWERS,
    AUDIENCE_PREVIEW_VIEWERS,
    AUDIENCE_REGISTERS,
    AUDIENCE_REGISTERS_RATIO,
    AUDIENCE_UNIQUE_ACTORS,
    AUDIENCE_COUNT,

    AUDIENCE_DIMENSION_EVENT_ID,
}

export default AudienceFields;