import './index.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import App from './App';
import { IS_DEVELOP, IS_STAGING, isOnliveUser } from './common/utils/utils';
import { ErrorFallback } from './components/ErrorFallback';
import i18n from './i18n';
import store from './redux';
import reportWebVitals from './reportWebVitals';

import { ResultSet } from '@cubejs-client/core';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
const internalDayjs = (...args) => dayjs(...args).locale({ ...en, weekStart: 1 });

const originalTimeSeries = ResultSet.prototype.timeSeries;

ResultSet.prototype.timeSeries = function (...args) {
    try {
        if (args.length > 0) {
            if (args[0].granularity === 'day') {
                args[0] = {
                    ...args[0],
                    dateRange: args[0].dateRange.map((date) => internalDayjs(date).format('YYYY-MM-DDT00:00:00.000')),
                };
            }
        }
    } catch (e) {
        console.error('Error overloading CUBE timeSeries', e);
    }
    return originalTimeSeries.apply(this, args);
};

if (!IS_DEVELOP) {
    Sentry.init({
        dsn: 'https://19852a2f98d34677a19eea80266174c6@o1126177.ingest.sentry.io/4504683599626240',
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        environment: process.env.REACT_APP_ENVIRONMENT,
        release: process.env.REACT_APP_VERSION,
        allowUrls: [/^https:\/\/.*$/],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            new URL(process.env.REACT_APP_API_URL).hostname,
            new URL(process.env.REACT_APP_BFF_URL).hostname,
            new URL(process.env.REACT_APP_SOCKET_API_URL).hostname,
            new URL(process.env.REACT_APP_INSTANT_SEARCH_API_URL).hostname,
            new URL(process.env.REACT_APP_SHOPPABLE_BASE_URL).hostname,
            new URL(process.env.REACT_APP_ONE2ONE_URL).hostname,
            new URL(process.env.REACT_APP_STREAMING_BASE_URL).hostname,
            new URL(process.env.REACT_APP_API_GATEWAY_URL).hostname,
            new URL(process.env.REACT_APP_OAUTH_API_URL).hostname,
        ]
            .filter(
                (hostname, index, targets) =>
                    hostname !== 'localhost' && targets.findIndex((t) => t === hostname) === index,
            )
            .map((host) => new RegExp(`^https?://${host}`)),
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog={IS_STAGING || isOnliveUser()}>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Provider>
    </Sentry.ErrorBoundary>,
);
reportWebVitals();
