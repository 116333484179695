import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import useStylesImageContainer from './styles/useStylesImageContainer.styles';

const ImageContainer = (props) => {
    const { asset, size, onDelete, deleteButton } = props;

    const { classes } = useStylesImageContainer();

    let rootClass = classes.root;

    if (size === 'big') {
        rootClass += ` ${classes.big}`;
    }

    return (
        <Grid key={asset} item md={3}>
            <div className={rootClass}>
                {deleteButton && (
                    <IconButton className={classes.closeIcon} size={'small'} onClick={onDelete}>
                        <CloseIcon />
                    </IconButton>
                )}
                <img src={asset} alt={asset}></img>
            </div>
        </Grid>
    );
};

export default ImageContainer;
