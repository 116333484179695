import AlarmIcon from '@mui/icons-material/Alarm';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, FormControl, FormGroup, FormLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useFormFields } from 'libs/hooksLib';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from 'tss-react/mui';
import useStylesFieldForm from '../../app/profile/settings/styles/useStylesFieldForm.styles';

const useStyles = makeStyles()((theme) => ({
    reminderItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1rem',
        fontSize: '0.8rem',
        justifyContent: 'space-evenly',
    },
    eventInputSelector: {
        margin: '5px',
        backgroundColor: 'none',
        '& > :first-of-type': {
            height: '30px',
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            width: 'auto',
            backgroundColor: theme.palette.secondary.light,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.light,
            overflow: 'hidden',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.light,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.light,
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: theme.palette.default.main,
        },
    },
    formButton: {
        textTransform: 'none',
        width: '100%',
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.fontSize,
    },
    menu: {
        '& .MuiMenu-paper': {
            borderRadius: '17px',
        },
    },
}));

const ReminderForm = (props) => {
    const { emailReminders = [], toggleChange, settingKey, time = 'before' } = props;

    const { t } = useTranslation();
    const { classes: classes1, cx } = useStyles();
    const { classes: classes2 } = useStylesFieldForm();
    const classes = { ...classes1, ...classes2 };
    const [fields, handleFieldChange, reset] = useFormFields({
        time: 0,
        unit: 'h',
    });

    const handleAddReminder = useCallback(() => {
        const { time, unit } = fields;
        if (time > 0) {
            const newReminder = { time, unit };
            if (emailReminders.some((r) => r.time === fields.time && r.unit === fields.unit)) {
                return;
            }
            toggleChange(settingKey || SettingsKey.ICALL_EMAIL_REMINDERS, [...emailReminders, newReminder]);
            reset({
                time: 0,
                unit: 'h',
            });
        }
    }, [emailReminders, fields, reset, settingKey, toggleChange]);

    const deleteReminder = useCallback(
        (index) => {
            if (index >= 0 && index < emailReminders.length) {
                let tempReminders = JSON.parse(JSON.stringify(emailReminders));
                tempReminders.sort((a, b) => {
                    var current = moment();
                    var currentA = moment(current).add(a.time, a.unit);
                    var currentB = moment(current).add(b.time, b.unit);
                    return currentB.toDate().getTime() - currentA.toDate().getTime();
                });
                tempReminders.splice(index, 1);
                toggleChange(settingKey || SettingsKey.ICALL_EMAIL_REMINDERS, [...tempReminders]);
            }
        },
        [emailReminders, settingKey, toggleChange],
    );

    const renderReminders = useCallback(() => {
        let tempReminders = JSON.parse(JSON.stringify(emailReminders || []));
        tempReminders.sort((a, b) => {
            var current = moment();
            var currentA = moment(current).add(a.time, a.unit);
            var currentB = moment(current).add(b.time, b.unit);
            return currentB.toDate().getTime() - currentA.toDate().getTime();
        });
        return tempReminders?.map((reminder, index) => {
            return (
                <div key={`reminder-${index}`} className={classes.reminderItem}>
                    <Grid container item>
                        <Grid item xs={1}>
                            <AlarmIcon />
                        </Grid>
                        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span>{`${reminder.time}${reminder.unit} ${time}`}</span>
                        </Grid>
                        <Grid item xs={1}>
                            <ClearIcon onClick={() => deleteReminder(index)} />
                        </Grid>
                    </Grid>
                </div>
            );
        });
    }, [classes.reminderItem, deleteReminder, emailReminders, time]);

    return (
        <FormGroup className={classes.formGroup}>
            <FormLabel>{`${t(
                'profile.settings.oneToOneSettings.icallEmailReminders',
            )} (${time.toUpperCase()})`}</FormLabel>
            <ValidatorForm onSubmit={() => {}} onError={() => {}}>
                <FormGroup className={classes.formGroup}>
                    <Typography>{t('event.eventFormReminders.addReminder')}</Typography>
                    <TextValidator
                        name="time"
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        type={'number'}
                        value={fields.time}
                        onChange={handleFieldChange}
                        validators={[`minNumber:0`, 'required']}
                        errorMessages={[t('event.eventFormReminders.minValue'), t('app.common.required')]}
                        InputProps={{
                            endAdornment: (
                                <FormGroup>
                                    <FormControl>
                                        <TextField
                                            name="unit"
                                            select
                                            className={classes.eventInputSelector}
                                            value={fields.unit}
                                            onChange={handleFieldChange}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            variant="outlined"
                                            size="small"
                                        >
                                            <MenuItem key="hour" value="h">
                                                {t('event.eventFormReminders.hours')}
                                            </MenuItem>
                                            <MenuItem key="minute" value="m">
                                                {t('event.eventFormReminders.minutes')}
                                            </MenuItem>
                                            {/* <MenuItem key="second" value="s">
                                                {t('event.eventFormReminders.seconds')}
                                            </MenuItem> */}
                                        </TextField>
                                    </FormControl>
                                </FormGroup>
                            ),
                        }}
                        className={cx(classes.formControl, classes.eventFormInputSelectorContainer)}
                    />
                </FormGroup>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.formButton}
                    type="button"
                    onClick={handleAddReminder}
                >
                    {t('event.eventFormReminders.add')}
                </Button>
                {renderReminders()}
            </ValidatorForm>
        </FormGroup>
    );
};

export default ReminderForm;
