import { GridViewOutlined } from '@mui/icons-material';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const GridTableViewMode = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const tableMode = searchParams.get('tableMode') || false;
    const [value, setValue] = useState(tableMode);

    const onSubmit = useCallback(() => {
        setValue((value) => {
            let updatedSearchParams = new URLSearchParams(searchParams.toString());
            updatedSearchParams.set('tableMode', !value);
            setSearchParams(updatedSearchParams.toString());
            return !value;
        });
    }, [searchParams, setSearchParams]);

    return (
        <CustomTooltip arrow placement="bottom" title={t('navigateBar.gridTable')} aria-label="details application">
            <CustomIconButton key={'grid'} id="onlive-button-setting" onClick={onSubmit} size="small">
                {value ? <ViewListOutlinedIcon component="svg" /> : <GridViewOutlined component="svg" />}
            </CustomIconButton>
        </CustomTooltip>
    );
};

export default GridTableViewMode;
