import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const invitationSlice = createSlice({
    name: 'invitations',
    initialState: {
        pending: false,
        savePending: false,
        invitations: [],
        error: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        stopDelete: (state, action) => {
            state.deletePending = false;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addInvitation: (state, action) => {
            state.savePending = false;
            state.invitations = [action.payload, ...state.invitations];
        },
        editInvitation: (state, action) => {
            state.savePending = false;
            const index = state.invitations.findIndex((invitation) => invitation.id === action.payload.id);
            if (index !== -1) {
                state.invitations = [
                    ...state.invitations.slice(0, index),
                    action.payload,
                    ...state.invitations.slice(index + 1),
                ];
            }
        },
        deleteInvitation: (state, action) => {
            state.deletePending = false;
            const index = state.invitations.findIndex((invitation) => invitation.id === action.payload.id);
            if (index !== -1) {
                state.invitations = [...state.invitations.slice(0, index), ...state.invitations.slice(index + 1)];
            }
        },
        setInvitations: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.invitations = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setInvitations,
    stopDelete,
    setError,
    addInvitation,
    editInvitation,
    deleteInvitation,
    startDelete,
    startSave,
    stopSave,
    setSaveError,
} = invitationSlice.actions;

export const getInvitations = (state) => state.invitations.invitations;
export const getInvitationsPending = (state) => state.invitations.pending;
export const getInvitationsSavePending = (state) => state.invitations.savePending;
export const getDeletePending = (state) => state.invitations.deletePending;
export const getDeleteId = (state) => state.invitations.deleteId;
export const getInvitationsError = (state) => state.invitations.error;
export const getInvitationsTotal = (state) => state.invitations.total;
export const getInvitationsCurrentPage = (state) => state.invitations.currentPage;
export const getInvitationsTotalPages = (state) => state.invitations.totalPages;

export const fetchInvitationsAction =
    ({ filters, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            let query = transformFiltersToQuery(filters);
            dispatch(start());
            api.get('invitations' + query)
                .then((response) => {
                    dispatch(setInvitations(response.data));
                    if (onSuccess) {
                        onSuccess(response.data.items);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stop());
            return console.error(e.message);
        }
    };

export const inviteUserAction =
    ({ userDto, callback } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('invitations', userDto)
                .then((response) => {
                    dispatch(addInvitation(response?.data));
                    if (callback) {
                        callback(null);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (callback) {
                        callback(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error(e.message);
        }
    };

export const resendInvitationAction = (id, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('invitations/resend/' + id, {})
            .then((response) => {
                dispatch(editInvitation(response.data));
                if (onSuccess) {
                    onSuccess(null);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const cancelInvitationAction = (id, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startSave());
        api.put('invitations/cancel/' + id, {})
            .then((response) => {
                dispatch(editInvitation(response.data));
                if (onSuccess) {
                    onSuccess(null);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const deleteInvitationAction =
    ({ id, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(id));
            api.delete('invitations/' + id)
                .then((response) => {
                    dispatch(deleteInvitation(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (onFailure) {
                        onFailure();
                    }
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error('error', e);
        }
    };

export const acceptInvitationAction = (id, onSuccess, onFailure) => async (dispatch) => {
    try {
        dispatch(startSave(id));
        api.put('invitations/confirm-manager-request/' + id)
            .then((response) => {
                dispatch(editInvitation(response.data));
                if (onSuccess) {
                    onSuccess();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setSaveError(error));
                if (onFailure) {
                    onFailure();
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export default invitationSlice.reducer;
