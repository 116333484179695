import { tableCellClasses } from '@mui/material/TableCell';
import CustomTable from 'common/features/tables/CustomTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAvailabilitiesPending } from 'redux/slices/myAvailabilitySlice';

import MyAvailabilityTableRow from './MyAvailabilityTableRow';

const MyAvailabilityTable = (props) => {
    const { availability } = props;
    const { t } = useTranslation();
    const pending = useSelector(getAvailabilitiesPending);
    const workingHours = availability?.configuration?.workingHours;

    const tableConfig = {
        columns: [
            { id: 'sunday', content: t('app.common.days.sunday') },
            { id: 'monday', content: t('app.common.days.monday') },
            { id: 'tuesday', content: t('app.common.days.tuesday') },
            { id: 'wednesday', content: t('app.common.days.wednesday') },
            { id: 'thursday', content: t('app.common.days.thursday') },
            { id: 'friday', content: t('app.common.days.friday') },
            { id: 'saturday', content: t('app.common.days.saturday') },
        ],
        actionColumn: false,
        pending: pending,
    };

    return (
        <CustomTable
            customTableSx={{
                [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none',
                },
            }}
            tableConfig={tableConfig}
        >
            <MyAvailabilityTableRow key={1} workingHours={workingHours} />
            <div key={2} />
        </CustomTable>
    );
};

export default MyAvailabilityTable;
