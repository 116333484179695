import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import CustomTooltip from 'common/components/CustomTooltip';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import useFlexBoxStyles from 'common/flexBox';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import ChatEditWordsBag from './ChatEditWordsBag';

const ChatSettings = (props) => {
    const { settingValue, useWordBag, toggleChange, handleSubmit, hasChanges } = props;
    const { t } = useTranslation();

    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const classes = { ...classes1, ...classes2 };
    const isAuthorize = useAuthLicenseAddOns();
    const professionalPack = useMemo(() => isAuthorize(['professional_pack']), [isAuthorize]);
    const settingsDirty = useSelector(getSettingsDirty);

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.chatSettings.chat')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <div style={{ paddingBottom: '20px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <ItemSettings
                            label={t('profile.settings.chatSettings.setAccess')}
                            onChange={handleCheckChange}
                            name={'eventChat'}
                            keySettings={SettingsKey.ENABLE_EVENT_CHAT}
                            value={settingValue}
                        />
                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('profile.settings.cartSettings.save')}
                            </Button>
                        </Box>
                    </Box>
                </div>

                <CustomTooltip
                    disableHoverListener={professionalPack}
                    arrow
                    title={t('common.utils.const.notAuthorize')}
                    placement={'left'}
                >
                    <ItemSettings
                        disabled={!professionalPack}
                        label={t('settings.chat.useWordsBag')}
                        onChange={handleCheckChange}
                        name={'useWordBag'}
                        keySettings={SettingsKey.ENABLE_WORD_BAG}
                        value={useWordBag}
                        paddingBottom
                    />
                </CustomTooltip>
                {useWordBag && professionalPack && (
                    <div className={cx(flexBox.paddingTop2, flexBox.flex, flexBox.column, flexBox.gap2)}>
                        <Typography>{t('settings.chat.editWordsBag')}</Typography>
                        <ChatEditWordsBag />
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default ChatSettings;
