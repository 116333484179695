import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { color }) => {
    return {
        root: {
            '& div': {
                height: theme.spacing(3.5),
                fontWeight: '600',
                fontSize: theme.spacing(2),
                '& svg': {
                    height: theme.spacing(2.25),
                    width: theme.spacing(2.25),
                    color: color,
                    fill: color,
                },
            },
        },
        small: {
            '& div': {
                height: theme.spacing(3),
                fontSize: theme.spacing(1.5),
                '& svg': {
                    height: theme.spacing(1.75),
                    width: theme.spacing(1.75),
                },
            },
        },
    };
});

export const CustomStatusChip = ({ icon, size, label, styleClass }) => {
    const { classes, cx } = useStyles({ color: styleClass?.color || 'inherit' });

    return (
        <div className={cx(classes.root, size === 'small' && classes.small)}>
            {<Chip icon={icon} size={size || 'small'} label={label} style={styleClass} />}
        </div>
    );
};
