export function loadMeetManager(organizationId, cb, version = 'v1') {
    if (!document.getElementById('meet-manager')) {
        const script = document.createElement('script');
        script.id = 'meet-manager';
        script.async = true;
        let basePath = process.env.REACT_APP_MEET_MANAGER;
        let bundleName = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'onlive-meet.min.js' : 'onlive-meet.js';

        script.src =
            process.env.REACT_APP_ENVIRONMENT === 'production' ? `${basePath}/${version}/${bundleName}` : `${basePath}`;

        script.onload = () => {
            window.addEventListener('onlive-meet-ready', () => {
                cb && cb();
            });
            window.onliveManager.services['onliveMeet'].start(organizationId, { hidden: true });
        };
        document.body.appendChild(script);
    }
}
