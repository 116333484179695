import { Add } from '@mui/icons-material';
import { Button, FormControl, FormGroup, FormLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import useStylesForm from './styles/useStylesForm.styles';
import ImageFileInput from './ImageFileInput';
import useStylesFrontImage from "./styles/useStylesFrontImage.styles";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { handleUploadFileSignedUrl } from "common/utils/file";
import { useAbortController } from "common/hooks";

const ProfileImage = (props) => {
    const {image, setImage, label} = props;
    const [loading, setLoading] = useState(false);
    const [uploadImageProgress, setUploadImageProgress] = useState(0);
    const {t} = useTranslation();

    const { classes: classes1} = useStylesFrontImage();
    const { classes: classes2, cx } = useStylesForm();
    const classes = {...classes1, ...classes2};

    const {enqueueSnackbar} = useSnackbar();
    const getSignal = useAbortController();

    
    const uploadImageFile = (file, setProgress, setUploading, callback) => {
        handleUploadFileSignedUrl({
            file,
            size: 1024 * 1024 * 100, // 100MB
            onInit: (size) => {
                setProgress(0);
                setUploading(true);
            },
            onProgress: (loaded, total) => {
                setProgress(Math.min(100, Math.round((loaded / total) * 100)));
            },
            onComplete: (url) => {
                setUploading(false);
                callback(url);
            },
            onError: (error) => {
                setUploading(false);
                if (error.code !== 'ERR_CANCELED') {
                    enqueueSnackbar(t('app.common.uploadError'), {
                        variant: 'error',
                    });
                }
            },
            bucketType: 'resources',
            signal: getSignal(),
        });
        
        // let formData = new FormData();
        // formData.append('file', file);
        // formData.append('filename', file.name);
        // setProgress(0);
        // setUploading(true);
        // api.post('assets/upload', formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     onUploadProgress: (progressEvent) => {
        //         setProgress((progressEvent.loaded * 100) / file.size);
        //     },
        // })
        //     .then((response) => {
        //         setUploading(false);
        //         if (response.error) {
        //             throw response.error;
        //         }
        //         callback(response.data);
        //     })
        //     .catch((error) => {
        //         setUploading(false);
        //         console.debug(error);
        //     });
    };

    const handleUploadFile = async (imgFile) => {
        if (imgFile.size / 1048576 > 2) {
            enqueueSnackbar(t('common.components.profileImage.imageSizeError'), {
                variant: 'error',
            });
        } else {
            
            uploadImageFile(imgFile, setUploadImageProgress, setLoading,
                (imageUrl) => {
                    imageUrl && setImage(imageUrl);
                });
        }
    }

    return (
        
        <>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{label}</FormLabel>
                <FormControl className={classes.formControl}>
                    <ImageFileInput
                        inputId={"raised-button-front-image-file"}
                        uploadAssetFn={handleUploadFile}
                        disabled={loading}
                    />
                    
                    <label htmlFor="raised-button-front-image-file">
                        <Button component="span" className={cx(classes.uploadImageButton, classes.customUpload)} disabled={loading}>
                            {loading && <CircularProgressWithLabel value={uploadImageProgress} />}
                            {!loading && (
                                <>
                                    <Add />
                                    <span>{t('app.common.uploadImage')}</span>
                                </>
                            )}
                        </Button>

                        {image && image !== '' && <img src={image} className={classes.frontPage} alt="front_page"/>}
                    </label>
                </FormControl>
            </FormGroup>
            </>
    );
};

export default ProfileImage;
