import { Add } from '@mui/icons-material';
import { Box, CircularProgress, FormLabel, Stack } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UUID } from 'uuidjs';
import EnvironmentVariableInput from './EnvironmentVariableInput';

const EnvironmentVariableForm = ({
    handleSubmit,
    variables,
    setVariables,
    error,
    setError,
    handleDelete,
    savePending,
    showActionButton = true,
    border = true,
}) => {
    const { classes } = useStylesForm();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: onlive, cx } = useOnliveStyles();
    const { t } = useTranslation();

    const [inputErrors, setInputErrors] = useState({});

    // Initialize variables with unique IDs
    const [variablesWithIds, setVariablesWithIds] = useState(
        variables.map((variable) => ({
            ...variable,
            id: variable.id || UUID.generate(),
        })),
    );

    // Update inputErrors when errors change
    const handleInputError = useCallback((data, id) => {
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [id]: { ...prevErrors[id], ...data },
        }));
    }, []);

    const addNewVariable = useCallback(() => {
        const newVariable = { key: '', value: '', id: UUID.generate() };
        const updatedVariables = [...variablesWithIds, newVariable];

        setVariablesWithIds(updatedVariables);
        setVariables(updatedVariables.map(({ id, ...rest }) => rest)); // Exclude ID when updating the state
    }, [variablesWithIds, setVariables]);

    const onChange = useCallback(
        (data, id, idx) => {
            const updatedVariables = variablesWithIds.map((v) => (v.id === id ? { ...v, ...data } : v));
            setVariablesWithIds(updatedVariables);
            setVariables(updatedVariables.map(({ id, ...rest }) => rest));

            const duplicateKeyIndex = variables.findIndex((v, index) => v.key === data.key && index !== idx);
            setError(duplicateKeyIndex === -1 ? -1 : idx);

            if (variables.length === 1 && data.key.trim() !== '' && data.value.trim() === '') {
                handleInputError({ key: false, value: true }, id);
            }
        },
        [variablesWithIds, setVariables, variables, handleInputError, setError],
    );

    const handleInputDelete = useCallback(
        (id, idx) => {
            handleDelete(id, idx);
            setVariablesWithIds((prev) => prev.filter((v) => v.id !== id));
            handleInputError({ key: false, value: false }, id);
        },
        [handleDelete, handleInputError],
    );

    // Disable logic moved to a function for clarity
    const isSaveDisabled = () =>
        error !== -1 ||
        savePending ||
        Object.values(inputErrors).some((hasError) => hasError?.key || hasError?.value) ||
        (variables.length === 1 && variables[0]?.key === '');

    const boxStyles = {
        display: 'flex',
        width: '100%',
        my: 1,
    };

    return (
        <Stack className={classes.form}>
            <Stack
                direction="column"
                className={cx(
                    flexBox.padding1,
                    border && onlive.borderMediumGray,
                    flexBox.borderRadius1,
                    flexBox.marginBottom2,
                )}
                spacing={2}
            >
                {variables.length > 0 && (
                    <Stack direction="row" spacing={2}>
                        <FormLabel className={flexBox.w40}>
                            {t('profile.settings.immersiveEnvironmentsVariables.form.key')}
                        </FormLabel>
                        <FormLabel className={flexBox.w40}>
                            {t('profile.settings.immersiveEnvironmentsVariables.form.value')}
                        </FormLabel>
                    </Stack>
                )}

                {variablesWithIds.map((variable, idx) => (
                    <EnvironmentVariableInput
                        key={variable.id}
                        index={idx}
                        id={variable.id}
                        variable={variable}
                        error={error}
                        onChange={(data) => onChange(data, variable.id, idx)}
                        onDelete={() => handleInputDelete(variable.id, idx)}
                        disableDelete={variables.length === 1 && variable.key === ''}
                        onErrorChange={(data) => handleInputError(data, variable.id)}
                        count={variables.length}
                    />
                ))}

                <ActionButton
                    fullWidth
                    color="secondary"
                    disableElevation
                    onClick={addNewVariable}
                    disabled={isSaveDisabled() || variables[0]?.key === '' || variables[0]?.value === ''}
                >
                    <Add />
                    <span>{t('calendar.common.add')}</span>
                </ActionButton>
            </Stack>

            {showActionButton && (
                <Box sx={{ ...boxStyles, justifyContent: 'flex-end' }}>
                    <ActionButton
                        id="variables-save"
                        type="submit"
                        disableElevation
                        className={classes.buttonSave}
                        disabled={isSaveDisabled()}
                        onClick={handleSubmit}
                    >
                        {savePending && (
                            <div className={classes.spinner}>
                                <CircularProgress />
                            </div>
                        )}
                        <span>{t('common.term.saveChanges')}</span>
                    </ActionButton>
                </Box>
            )}
        </Stack>
    );
};

export default EnvironmentVariableForm;
