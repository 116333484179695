import { useCallback, useState } from 'react';

export function useFormFields(initialState) {
    const [fields, setFields] = useState(initialState);

    const getValue = (event) => {
        const type = event.target.type;
        if (type === 'number') {
            return Number(event.target.value);
        }
        if (type === 'checkbox') {
            return Boolean(event.target.checked);
        }
        return event.target.value;
    };

    //Uses useCallback to memoize the handleFieldChange and resetForm functions,
    // potentially optimizing performance and avoiding unnecessary re-renders.
    const handleFieldChange = useCallback(
        (event) => {
            setFields((prevFields) => ({
                ...prevFields,
                ...(Array.isArray(event)
                    ? event.reduce((a, ev) => ({ ...a, [ev.target.name]: getValue(ev) }), {})
                    : { [event.target.name]: getValue(event) }),
            }));
        },
        [setFields],
    );

    const resetForm = useCallback((newState) => {
        setFields(newState);
    }, [setFields]);

    return [fields, handleFieldChange, resetForm];
}
