import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, FormGroup, FormLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { calendarApi } from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ActionButton from 'common/components/ActionButton';
import ChipsInput from 'common/components/ChipsInput';
import CustomTextValidator from 'common/components/CustomTextValidators';
import UserRole from 'common/enums/roles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import useStylesForm from '../../../../common/components/styles/useStylesForm.styles';
import useStylesOCalendar from '../../../ocalendar/styles/useStylesOCalendar.styles';
import ItemSettings from '../components/ItemSettings';
import useStylesSettings from '../styles/useStylesSettings.styles';
import CalendarWebhooks from './CalendarWebhooks';

const CalendarSettings = (props) => {
    const { toggleChange, availabilityTypes, handleSubmit, bookingShareUrl, bookingTitle, sendBookingEmailToAgent } =
        props;
    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStylesSettings();
    const { classes: classes3 } = useStylesOCalendar();
    const classes = { ...classes1, ...classes2, ...classes3 };

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const organization = useSelector(getOrganization);
    const user = useSelector(getUser);

    const [fields, setFields] = useState({ createBookingUrl: '', cancelBookingUrl: '' });
    const [provider, setProvider] = useState({});
    const [availabilityTypesState, setAvailabilityTypesState] = useState(availabilityTypes || []);

    moment.locale(t?.language ? t.language : 'en');

    const handleFormSubmit = async (e) => {
        const { createBookingUrl, cancelBookingUrl } = fields;
        if (createBookingUrl || cancelBookingUrl) {
            e.preventDefault();
            try {
                await calendarApi.patch(
                    `/api/v1/provider/update/${organization.id}?type=${organization.calendarProvider}`,
                    {
                        createBookingUrl,
                        cancelBookingUrl,
                    },
                );

                await fetchProvider();

                enqueueSnackbar(t('common.term.saveSuccess'), {
                    variant: 'success',
                });
            } catch (err) {
                console.error('🚀 => handleFormSubmit => err', err);
                enqueueSnackbar(t('app.common.error'), {
                    variant: 'error',
                });
            }
        }
        handleSubmit(e);
    };

    const fetchProvider = useCallback(async () => {
        const provider = await calendarApi.get(`/api/v1/provider/${organization.id}`);
        setProvider(provider);
    }, [organization.id]);

    useEffect(() => {
        fetchProvider();
    }, [fetchProvider]);

    useEffect(() => {
        if (provider) {
            setFields({
                createBookingUrl: provider.createBookingUrl ?? '',
                cancelBookingUrl: provider.cancelBookingUrl ?? '',
            });
        }
    }, [provider]);

    const handleInputChange = (e) => {
        setFields({
            ...fields,
            [e.target.name]: e.target.value,
        });
    };

    const onBlurAvailabilities = () => toggleChange(SettingsKey.AVAILABILITY_TYPES, availabilityTypesState);

    const onTagChange = (value, setHandler, state, key) => {
        let updatedValues = [];

        if (!value.length) {
            setHandler([]);
            return;
        }

        if (state.length > value.length) {
            updatedValues = state.filter((r) => value.includes(r));
        } else if (state.length < value.length) {
            updatedValues = [...new Set([...state, ...value])];
        }

        setHandler(updatedValues);
        toggleChange(key, state);
    };

    const handleSettingChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('Calendar')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <ItemSettings
                    label={t('profile.settings.iCalendar.sendBookingEmailToAgent')}
                    onChange={handleCheckChange}
                    name={SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT}
                    keySettings={SettingsKey.SEND_BOOKING_EMAIL_TO_AGENT}
                    value={sendBookingEmailToAgent}
                    paddingBottom
                />
                <ValidatorForm onSubmit={handleFormSubmit} onError={() => {}} className={classes.form}>
                    <Typography>
                        <Box component="span" fontWeight="fontWeightMedium" textTransform="uppercase">
                            {t('profile.settings.calendar.webhooks')}
                        </Box>
                    </Typography>

                    {(user?.role === UserRole.ADMIN || user?.role === UserRole.ROOT) && (
                        <CalendarWebhooks onChange={handleInputChange} fields={fields} />
                    )}

                    <div className={classes.mb20px}>
                        <ChipsInput
                            clearOnEscape
                            name={'availabilities'}
                            label={t('profile.settings.calendarSettings.availabilityTypes')}
                            value={availabilityTypesState}
                            placeholder={t('common.input.addTagValue')}
                            onChange={(value) =>
                                onTagChange(
                                    value,
                                    setAvailabilityTypesState,
                                    availabilityTypesState,
                                    SettingsKey.AVAILABILITY_TYPES,
                                )
                            }
                            onBlur={onBlurAvailabilities}
                            variant="outlined"
                        />
                    </div>

                    <div className={classes.mb20px}>
                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{`${t('profile.settings.calendar.bookingShareUrl')}`}</FormLabel>
                            <CustomTextValidator
                                name="bookingShareUrl"
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                value={bookingShareUrl}
                                type="text"
                                onChange={(e) => handleSettingChange(e, SettingsKey.BOOKING_SHARE_URL)}
                            />
                        </FormGroup>
                    </div>

                    <div className={classes.mb20px}>
                        <FormGroup className={classes.formGroup}>
                            <FormLabel>{`${t('profile.settings.calendar.bookingTitle')}`}</FormLabel>
                            <CustomTextValidator
                                name="bookingTitle"
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                value={bookingTitle}
                                type="text"
                                onChange={(e) => handleSettingChange(e, SettingsKey.BOOKING_TITLE)}
                                placeholder={t('profile.settings.calendar.bookingTitlePlaceholder')}
                            />
                        </FormGroup>
                    </div>

                    <ActionButton
                        id="onlive-button-availability-save"
                        type="submit"
                        disableElevation={true}
                        className={classes.buttonSave}
                        disabled={false}
                    >
                        <span>{t('common.term.saveChanges')}</span>
                    </ActionButton>
                </ValidatorForm>
            </AccordionDetails>
        </Accordion>
    );
};

export default CalendarSettings;
