import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Container, Unstable_Grid2 as Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { startCreateTemplateMode } from 'redux/slices/ocalendarSlice';
import AvailabilityCard from './AvailabilityCard';

// TODO: Update and use AvailabilityCardSkeleton
const AvailabilityList = ({ templates, scrollable }) => {
    const dispatch = useDispatch();

    const handleCreateTemplate = (e) => {
        e.preventDefault();
        dispatch(startCreateTemplateMode());
    };

    const ScrollableItems = () => {
        return (
            <Box sx={{ width: '100%', overflow: 'auto', display: 'flex' }}>
                {templates.map((template, i) => (
                    <Box
                        sx={{
                            ...(i === 0 && { my: '16px', mr: '16px', ml: '1px' }),
                            ...(i === templates.length - 1 && { my: '16px', ml: '16px', mr: '1px' }),
                            ...(i !== 0 && i !== templates.length - 1 && { margin: '16px' }),
                        }}
                        key={template?.id}
                    >
                        <AvailabilityCard template={template} customWidth={'250px'} />
                    </Box>
                ))}
            </Box>
        );
    };

    return (
        <>
            {scrollable ? (
                <ScrollableItems />
            ) : (
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 8,
                        width: '70vw',
                        overflow: 'hidden',
                    }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <Stack direction="row" justifyContent="space-between" spacing={4}>
                                <Stack spacing={1}>
                                    <Typography variant="onliveH4">Availabilities</Typography>
                                </Stack>
                                <div>
                                    <Button
                                        startIcon={
                                            <SvgIcon fontSize="small">
                                                <AddOutlinedIcon />
                                            </SvgIcon>
                                        }
                                        variant="contained"
                                        onClick={handleCreateTemplate}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Stack>
                            <Grid container spacing={3}>
                                {templates.map((template) => (
                                    <Grid xs={12} md={4} lg={3} key={template.id}>
                                        <AvailabilityCard template={template} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </Container>
                </Box>
            )}
        </>
    );
};

export default AvailabilityList;
