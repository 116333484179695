import axios from 'axios';
import config from '../config';

const GATEWAY_API = axios.create({
    baseURL: config.gatewayApi.gatewayUrl,
});

GATEWAY_API.interceptors.request.use((req) => {
    return req;
});

GATEWAY_API.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default GATEWAY_API;
