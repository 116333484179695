import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'suneditor/dist/css/suneditor.min.css';
import useStylesSettings from '../../settings/styles/useStylesSettings.styles';
import SecurityCardItem from '../SecurityCardItem';
import SecurityStatusItem from '../SecurityStatusItem';
const EmailVerification = ({ postMethod, factor, onDelete }) => {
    const { t } = useTranslation();
    const { classes } = useStylesSettings();
    const [pending, setPending] = useState(false);

    const onConfigure = () => {
        setPending(true);
        postMethod({
            method: 'email',
            callback: (data) => {
                setPending(false);
            },
        });
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <EmailOutlinedIcon />
                    <Typography variant="subtitle1" fontWeight={500}>
                        {t('profile.security.emailVerification')}
                    </Typography>
                    <SecurityStatusItem factor={factor} text={t('profile.security.authenticatorConfirmedWarning')} />
                </Stack>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {t('profile.security.emailMessage')}
                    </Typography>

                    {factor?.configured ? (
                        <SecurityCardItem
                            icon={<EmailOutlinedIcon />}
                            title={t('profile.security.yourEmail')}
                            content={factor?.userEmail}
                            confirmed={factor?.confirmed}
                            managedByOrganization={factor?.managedByOrganization}
                            onDelete={factor?.managedByOrganization ? null : () => onDelete({ method: 'email' })}
                        />
                    ) : (
                        <Stack direction="row" justifyContent="flex-start">
                            <ActionButton
                                disabled={pending}
                                color="primary"
                                type="submit"
                                autoFocus
                                fitWidth={true}
                                spinner={pending}
                                onClick={onConfigure}
                            >
                                {t('profile.security.configureEmails')}
                            </ActionButton>
                        </Stack>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default EmailVerification;
