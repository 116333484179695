import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

export const launchSlice = createSlice({
    name: 'launch',
    initialState: {
        pending: false,
        eventId: null,
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.eventId = action.payload;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        success: (state, action) => {
            state.pending = false;
            state.event = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.error = action.payload;
        },
    },
});

export const { start, stop, success, setError } = launchSlice.actions;

export const isLaunchPending = (state) => state.launch.pending;
export const getEventId = (state) => state.launch.eventId;
export const getLaunchedEvent = (state) => state.launch.event;

export const launchEventAction = (eventId) => async (dispatch) => {
    try {
        dispatch(start(eventId));
        return api
            .post('events/start/' + eventId)
            .then((response) => {
                dispatch(success(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default launchSlice.reducer;
