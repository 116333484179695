import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import useStylesWidgetOneToOne from '../styles/useStylesWidgetOneToOne.styles';

const WidgetActions = (props) => {
    const { widgetActionsSetting, toggleChange } = props;
    const { t } = useTranslation();

    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const { classes: classes3 } = useStylesWidgetOneToOne();

    const classes = { ...classes1, ...classes2, ...classes3 };

    const { enqueueSnackbar } = useSnackbar();

    const enableSetting = (key) => {
        let idx = widgetActionsSetting.findIndex((a) => a === key);
        return idx !== -1;
    };

    const handleActionChange = (event, action) => {
        let newActions = new Set(widgetActionsSetting);
        if (event.target.checked) {
            newActions.add(action);
        } else if (newActions.has(action)) {
            newActions.delete(action);
        }

        if (newActions.size === 0) {
            enqueueSnackbar(t('profile.settings.widgetOneToOneSettings.widgetActions.selectOne'), { variant: 'error' });
        } else {
            //      setWidgetActions([...newActions])
            toggleChange(SettingsKey.WIDGET_ACTIONS, [...newActions]);
        }
    };

    return (
        <div className={classes.actionsContent}>
            <Typography className={classes.text}>
                {t('profile.settings.widgetOneToOneSettings.widgetActions.setAction')}
            </Typography>
            <div className={classes.widgetActions}>
                <ItemSettings
                    label={t('profile.settings.widgetOneToOneSettings.widgetActions.call')}
                    onChange={handleActionChange}
                    name={'instantSearch'}
                    keySettings={'call'}
                    value={enableSetting('call')}
                />

                <ItemSettings
                    label={t('profile.settings.widgetOneToOneSettings.widgetActions.calendar')}
                    onChange={handleActionChange}
                    name={'widgetActionCalendar'}
                    keySettings={'calendar'}
                    value={enableSetting('calendar')}
                />
            </div>
        </div>
    );
};

export default WidgetActions;
