import { css } from 'lit';

export const styles = css`
    :root,
    :host {
        height: 100%;
        font: 16px var(--ol-font-sans);
        font-weight: var(--ol-font-weight-normal);
        line-height: var(--ol-line-height-normal);
        color: var(--ol-color-primary-950);
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        --ol-color-primary-0: rgb(250 250 250);
        --ol-color-primary-50: rgb(249 249 249);
        --ol-color-primary-100: rgb(236 236 236);
        --ol-color-primary-200: rgb(222 222 222);
        --ol-color-primary-300: rgb(206 206 206);
        --ol-color-primary-400: rgb(186 186 186);
        --ol-color-primary-500: rgb(160 160 160);
        --ol-color-primary-600: rgb(132 132 132);
        --ol-color-primary-700: rgb(102 102 102);
        --ol-color-primary-800: rgb(89 89 89);
        --ol-color-primary-900: rgb(63 63 63);
        --ol-color-primary-950: rgb(27 27 27) !important;
        --ol-color-primary-1000: rgb(0 0 0);
        --ol-spacing-large: 18px;
        --ol-input-border-color: var(--ol-color-primary-400);
        --ol-input-border-color-focus: var(--ol-color-primary-700);
        --ol-font-sans: 'CupraRegular', sans-serif;
        --ol-input-floating-label-initial-position-medium: -4px, 34px;
        --ol-input-floating-label-final-position-medium: -4px, 8px;
        --ol-input-floating-label-background-color: none;
        --ol-input-required-content: initial;
        --ol-calendar-padding: 0;
        --ol-calendar-day-padding: var(--ol-spacing-small) var(--ol-spacing-large);
        --ol-calendar-day-spacing: 0;
        --ol-calendar-day-border-radius: 0;
        --ol-calendar-month-border-radius: 0;
        --ol-calendar-border: none;
        --ol-calendar-selected-background-color: var(--ol-color-primary-950);
        --ol-border-radius-medium: 20px !important;
        --onlive-widget-primary-button-padding: 8px 36px;
        --onlive-widget-primary-button-box-shadow-hover: none !important;
        --ol-calendar-day-border-radius: 20px !important;
    }

    /* Inputs */
    ol-input::part(base) {
        background-color: transparent;
        border-radius: 20px;
        height: 36px;
        box-shadow: none;
        padding: 0 10px;
    }

    .phone-input ol-select::part(combobox) {
        min-height: calc(var(--ol-input-height-medium) - 10px) !important;
    }

    .triage {
        height: calc(100% - 64px) !important;
        overflow: auto !important;
        scrollbar-width: none;
        -ms-overflow-style: none;
        border-radius: 16px;
    }

    .triage::-webkit-scrollbar {
        display: none;
    }

    .form-container .field select {
        height: 36px;
        border-radius: 20px !important;
        border: 1px solid var(--ol-color-primary-400);
    }

    .form-container .fields label {
        margin-left: 0.3em;
        font-size: 14px;
    }

    .booking-details .detail {
        font-weight: bold;
    }

    .booking-details .detail .detail-icon {
        display: none;
    }

    .node-container .content > * {
        margin: 24px 0 !important;
        text-align: left;
        line-height: 1.2em;
    }

    ol-input::part(input) {
        color: var(--ol-color-primary-950);
        padding-inline-start: 0;
    }
    ol-input::part(form-control-label) {
        color: var(--ol-color-primary-600);
    }
    ol-input.has-error::part(base) {
        border-color: var(--ol-color-danger-600);
    }
    ol-input.has-error::part(form-control-help-text) {
        color: var(--ol-color-danger-700);
    }

    /* Checkbox */
    ol-checkbox::part(control--checked) {
        background-color: var(--ol-color-primary-700);
    }
    ol-checkbox {
        color: var(--ol-color-primary-600);
    }
    ol-checkbox a {
        color: var(--ol-color-primary-700);
    }
    ol-checkbox a:hover {
        color: var(--ol-color-primary-950);
    }

    /* Select */
    ol-input ol-select::part(combobox) {
        padding-inline-start: 0;
    }
    ol-input ol-select {
        width: 94px;
    }

    /* Radio */
    ol-radio::part(control--checked) {
        color: var(--ol-color-primary-950);
        border-color: var(--ol-color-primary-300);
        background-color: var(--ol-color-primary-0);
    }
    ol-button[variant='default']:not([disabled]):hover::part(base) {
        background-color: transparent;
        border-color: var(--ol-color-primary-950);
        color: var(--ol-color-primary-950);
    }

    /* Button */
    ol-button[variant='primary']::part(base),
    ol-button[variant='default']::part(base) {
        border-radius: 8px;
        border-color: var(--ol-color-primary-950);
    }
    ol-button[variant='primary']::part(label),
    ol-button[variant='default']::part(label) {
        padding-block: 4px;
    }
    ol-button[variant='primary']::part(prefix),
    ol-button[variant='default']::part(prefix) {
        padding-inline-start: 4px;
    }
    ol-button[variant='primary']::part(suffix),
    ol-button[variant='default']::part(suffix) {
        padding-inline-end: 4px;
    }
    ol-button[variant='primary']::part(base) {
        color: var(--ol-color-primary-0);
        background-color: var(--ol-color-primary-950);
    }
    ol-button[variant='primary']:not([disabled]):hover::part(base) {
        background-color: var(--ol-color-primary-700);
        border-color: var(--ol-color-primary-700);
    }
    ol-button[variant='primary'][disabled]::part(base) {
        background-color: var(--ol-color-primary-400);
        border-color: var(--ol-color-primary-400);
        color: var(--ol-color-primary-700);
    }
    ol-button[variant='default']::part(base) {
        color: var(--ol-color-primary-950);
    }
    ol-button[variant='default']:not([disabled]):hover::part(base) {
        border-color: var(--ol-color-primary-700);
        color: var(--ol-color-primary-700);
    }

    /* Button Group */
    ol-button-group::part(base) {
        flex-wrap: wrap;
        gap: var(--ol-spacing-medium);
    }
    ol-button-group:has([size='small'])::part(base) {
        gap: var(--ol-spacing-small);
    }
    ol-button-group:has([size='large'])::part(base) {
        gap: var(--ol-spacing-large);
    }
    ol-button-group ol-button[variant='default']::part(base) {
        position: relative;
        border-radius: 128px;
    }
    ol-button-group ol-button[variant='default']:not([disabled]):hover::part(base) {
        color: var(--ol-color-primary-950);
    }
    ol-button-group ol-button[variant='default']:not([disabled], [selected]):hover::part(base)::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid var(--ol-color-primary-950);
        border-radius: 128px;
    }
    ol-button-group ol-button[variant='default'][selected]::part(base) {
        color: var(--ol-color-primary-200);
        background-color: var(--ol-color-primary-950);
    }
    ol-button-group ol-button[variant='default'][selected]:hover::part(base) {
        color: var(--ol-color-primary-200);
        background-color: var(--ol-color-primary-700);
    }

    /* Radio Button */
    ol-radio-group::part(button-group__base) {
        gap: var(--ol-spacing-medium);
        justify-content: center;
        flex-wrap: wrap;
    }
    ol-radio-group[size='small']::part(button-group__base) {
        gap: var(--ol-spacing-small);
    }
    ol-radio-group[size='large']::part(button-group__base) {
        gap: var(--ol-spacing-large);
    }
    ol-radio-group ol-radio-button::part(button) {
        color: var(--ol-color-primary-950);
        border-color: var(--ol-color-primary-950);
        position: relative;
        border-radius: 128px;
    }
    ol-radio-group ol-radio-button::part(label) {
        padding-block: 4px;
    }
    ol-radio-group ol-radio-button::part(prefix) {
        padding-inline-start: 4px;
    }
    ol-radio-group ol-radio-button::part(suffix) {
        padding-inline-end: 4px;
    }
    ol-radio-group ol-radio-button:hover:not([disabled], [checked])::part(button) {
        color: var(--ol-color-primary-950);
        background: transparent;
    }
    ol-radio-group ol-radio-button:hover:not([disabled], [checked])::part(button)::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid var(--ol-color-primary-950);
        border-radius: 128px;
    }
    ol-radio-group ol-radio-button::part(button--checked) {
        color: var(--ol-color-primary-200);
        background-color: var(--ol-color-primary-950);
        border-color: var(--ol-color-primary-950);
    }
    ol-radio-group ol-radio-button:hover::part(button--checked) {
        color: var(--ol-color-primary-200);
        background-color: var(--ol-color-primary-700);
        border-color: var(--ol-color-primary-700);
    }

    .node-container .content {
        padding: 0 !important;
    }

    .top__bar {
        padding: 1em 60px !important;
    }

    ol-calendar {
        width: 100%;
    }

    ol-calendar::part(base) {
        width: 100%;
        margin: 1em 0;
    }

    /* Calendar */
`;
