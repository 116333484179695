import { FormGroup, FormLabel, Stack, Typography } from '@mui/material';
import ServiceCard from 'app/services/components/ServiceCard';
import CustomAutocompleteSearch from 'common/components/CustomAutocompleteSearch';
import useFlexBoxStyles from 'common/flexBox';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGroups } from 'redux/slices/groupSlice';
import { getServicesPending } from 'redux/slices/serviceSlice';

const MyAvailabilityFormTemplates = (props) => {
    const { selectedServices, setSelectedServices, services } = props;
    const groups = useSelector(getGroups);
    const pending = useSelector(getServicesPending);
    const { classes: flexBox } = useFlexBoxStyles();
    const { t } = useTranslation();

    const deleteServices = (idx) => {
        setSelectedServices([...selectedServices.slice(0, idx), ...selectedServices.slice(idx + 1)]);
    };

    const renderOption = (props, option) => {
        const group = groups.find((g) => g.id === option.userGroupId);
        return (
            <li aria-hidden="true" {...props} key={option.id}>
                {option.name}
                {group?.name ? <Typography>&nbsp;&nbsp;(&nbsp;{group?.name}&nbsp;)</Typography> : null}
            </li>
        );
    };

    const getOptionLabel = (option) => {
        const group = groups.find((g) => g.id === option.userGroupId);
        return option.name + (group?.name ? ` (${group?.name})` : '');
    };

    return (
        <FormGroup>
            <FormLabel>{t('profile.myAvailability.form.services')}</FormLabel>

            <Stack direction="column" spacing={2} className={flexBox.maxWFillAvailable}>
                <CustomAutocompleteSearch
                    selectedValues={selectedServices}
                    setSelectedValues={setSelectedServices}
                    options={services}
                    keyProp="name"
                    renderOption={renderOption}
                    getOptionLabel={getOptionLabel}
                    pending={pending}
                />

                {selectedServices?.map((service, idx) => (
                    <ServiceCard key={service.id} service={service} deleteService={() => deleteServices(idx)} />
                ))}
            </Stack>
        </FormGroup>
    );
};

export default MyAvailabilityFormTemplates;
