import { Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import CustomAutocompleteSearch from 'common/components/CustomAutocompleteSearch';
import CustomMenuItem from 'common/components/CustomMenuItem';
import CustomSelectValidator from 'common/components/CustomSelectValidators';
import CustomTextFieldDescription from 'common/components/CustomTextFieldDescription';
import CustomTextValidator from 'common/components/CustomTextValidators';
import PhoneInput from 'common/components/PhoneInput';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { cleanContent } from 'common/utils/cleanContent';
import { findCountryCodeByCountryName } from 'common/utils/countryList';
import { isValidHttpUrl } from 'common/validators/validators';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getLanguage } from 'redux/slices/settingSlice';
import DateField from './components/DateField';
import DateTimeField from './components/DateTimeField';

const RenderFormFields = ({ formConfig, fields, handleFieldChange, defaultFields }) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();
    const lang = useSelector(getLanguage);
    const organization = useSelector(getOrganization);

    const handleKeyPress = useCallback(
        (event, field) => {
            const charCode = event.charCode;
            const char = String.fromCharCode(charCode);
            const validChars = '0123456789.-';
            const value = fields[field.name];
            if (char === '.' && value?.includes('.')) {
                event.preventDefault();
            }
            if (!validChars.includes(char)) {
                event.preventDefault();
            }
            if (char === '-' && (value?.includes('-') || event.target.selectionStart !== 0)) {
                event.preventDefault();
            }
            if (charCode === 8 || charCode === 46 || (charCode >= 37 && charCode <= 40)) {
                return;
            }
        },
        [fields],
    );

    const getLabel = useCallback(
        (labels, type) => {
            const label = labels.find((label) => lang.includes(label.languageCode))?.value || labels[0].value;
            if (type === 'privacy-policy') {
                return <span dangerouslySetInnerHTML={{ __html: cleanContent(label) }} />;
            } else {
                return label;
            }
        },
        [lang],
    );

    // const generateMessageBasedOnRegex = (regex) => {
    //     const regexStr = regex.toString();
    //     let message = t('app.common.invalidInput.error');

    //     if (/\\d/.test(regexStr)) {
    //         message += ` ${t('app.common.notNumbers.error')}`;
    //     }
    //     if (/[a-z]/.test(regexStr) && !/[A-Z]/.test(regexStr)) {
    //         message += ` ${t('app.common.onlyLowerCase.error')}`;
    //     }
    //     if (/[A-Z]/.test(regexStr) && !/[a-z]/.test(regexStr)) {
    //         message += ` ${t('app.common.onlyUpperCase.error')}`;
    //     }
    //     if (/[A-Z]/.test(regexStr) && /[a-z]/.test(regexStr)) {
    //         message += ` ${t('app.common.camelCase.error')}`;
    //     }
    //     if (/[\W_]/.test(regexStr)) {
    //         message += ` ${t('app.common.noSpecialCharacter.error')}`;
    //     }

    //     return message;
    // };

    // const generateErrorMessage = (regex, input) => {
    //     const _regex = new RegExp(regex);
    //     if (regex && input && !_regex?.test(input)) {
    //         return generateMessageBasedOnRegex(regex);
    //     }
    //     return null;
    // };

    const getValidator = useCallback(
        (field) => {
            let validators = [];
            if (field.required) {
                validators = [...validators, 'required'];
            }
            if (field.type === 'email' && !field.regexValidator) {
                validators = [...validators, 'isEmail'];
            }
            if (field.type === 'phone' && !field.regexValidator) {
                validators = [...validators, `matchRegexp:^\\d*$`];
            }
            if (field.regexValidator) {
                // validators = [...validators, `matchRegexp:${field.regexValidator}`];
                // const regex = new RegExp(field.regexValidator);
                // const regex = /^[0-9]{10}$/;
                validators = [...validators, `matchRegexp:${field.regexValidator}`];
            }
            if (typeof fields[field.name] === 'string' && isValidHttpUrl(fields[field.name])) {
                validators = [...validators, 'urlMatch'];
            }
            return validators;
        },
        [fields],
    );

    const getErrorMessages = useCallback(
        (field) => {
            let errorMessage = [];
            if (field.required) {
                errorMessage = [...errorMessage, t('app.common.required')];
            }
            if (field.type === 'email' && !field.regexValidator) {
                errorMessage = [
                    ...errorMessage,
                    t('app.common.required'),
                    t('account.accountProfileForm.emailInvalid'),
                ];
            }
            if (field.type === 'phone' && !field.regexValidator) {
                errorMessage = [...errorMessage, t('account.accountProfileForm.phoneInvalid')];
            }
            if (field.regexValidator) {
                const error = t('app.common.invalidInput.error'); // generateErrorMessage(field.regexValidator, fields[field.name]);
                errorMessage = [
                    ...errorMessage,
                    field.type === 'phone' ? t('account.accountProfileForm.phoneInvalid') : error,
                ];
            }
            return errorMessage;
        },
        [t],
    );

    const renderOption = useCallback(
        (props, option) => {
            return (
                <li aria-hidden="true" {...props} key={option.value}>
                    {getLabel(option.label)}
                </li>
            );
        },
        [getLabel],
    );

    const getOptionLabel = useCallback(
        (option) => {
            return getLabel(option.label);
        },
        [getLabel],
    );

    const handleDateChange = useCallback(
        (value, field) => {
            handleFieldChange({ target: { name: field.name, value } });
        },
        [handleFieldChange],
    );

    const handleDateTimeChange = useCallback(
        (value, field) => {
            handleFieldChange({ target: { name: field.name, value } });
        },
        [handleFieldChange],
    );

    const renderField = useCallback(
        (field) => {
            switch (field.type) {
                case 'text':
                    return (
                        <CustomTextFieldDescription
                            name={field.name}
                            value={fields[field.name] ?? ''}
                            onChange={handleFieldChange}
                            variant="outlined"
                            size="small"
                            multiline
                            rows={3}
                        />
                    );
                case 'phone':
                    return (
                        <PhoneInput
                            value={fields[field.name] ?? ''}
                            name={field.name}
                            hideLabel={true}
                            hideCountryCode={field.hideCountryCode ?? false}
                            onChange={handleFieldChange}
                            validators={getValidator(field)}
                            errorMessages={getErrorMessages(field)}
                            defaultCountryCode={
                                field.defaultCountryCode || findCountryCodeByCountryName(organization?.country)
                            }
                        />
                    );
                case 'input':
                case 'email':
                case 'number':
                    return (
                        <CustomTextValidator
                            fullWidth={true}
                            variant="outlined"
                            type={field.type}
                            size="small"
                            name={field.name}
                            value={fields[field.name] ?? (field.type === 'number' ? 0 : '')}
                            onChange={handleFieldChange}
                            validators={getValidator(field)}
                            errorMessages={getErrorMessages(field)}
                        />
                    );
                case 'float':
                    return (
                        <CustomTextValidator
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            name={field.name}
                            value={String(fields[field.name] ?? 0.0)}
                            onChange={handleFieldChange}
                            onKeyPress={(e) => handleKeyPress(e, field)}
                            validators={getValidator(field)}
                            errorMessages={getErrorMessages(field)}
                        />
                    );
                case 'select':
                    if (field.multiple) {
                        const selectedValues = field.options?.filter((opt) => fields[field.name]?.includes(opt?.value));
                        return (
                            <CustomAutocompleteSearch
                                selectedValues={selectedValues}
                                setSelectedValues={(data) =>
                                    handleFieldChange({
                                        target: {
                                            name: field.name,
                                            value: data.map((item) => item.value),
                                        },
                                    })
                                }
                                optionProp="value"
                                options={field.options}
                                renderOption={renderOption}
                                getOptionLabel={getOptionLabel}
                            />
                        );
                    } else {
                        return (
                            <CustomSelectValidator
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                name={field.name}
                                value={fields[field.name] ?? ''}
                                onChange={handleFieldChange}
                                validators={getValidator(field)}
                                errorMessages={getErrorMessages(field)}
                            >
                                {field.options.map((option) => (
                                    <CustomMenuItem key={option.value} value={option.value} noBorder={true}>
                                        {getLabel(option.label)}
                                    </CustomMenuItem>
                                ))}
                            </CustomSelectValidator>
                        );
                    }

                case 'date':
                    if (field.isDateTime) {
                        return (
                            <DateTimeField
                                value={fields[field.name]}
                                onChange={(value) => handleDateTimeChange(value, field)}
                            />
                        );
                    } else {
                        return (
                            <DateField
                                value={fields[field.name]}
                                onChange={(value) => handleDateChange(value, field)}
                            />
                        );
                    }

                case 'checkbox':
                case 'privacy-policy':
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={cx(flexBox.padding0, flexBox.margin01)}
                                    name={field.name}
                                    checked={fields[field.name] ?? false}
                                    onChange={handleFieldChange}
                                    color="primary"
                                    required={field.required}
                                />
                            }
                            label={<FormLabel>{getLabel(field.label, field.type)}</FormLabel>}
                        />
                    );

                case 'radio':
                    return (
                        <RadioGroup name={field.name} value={fields[field.name] ?? false} onChange={handleFieldChange}>
                            {field.options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio color={'primary'} />}
                                    label={<FormLabel>{getLabel(option.label)}</FormLabel>}
                                />
                            ))}
                        </RadioGroup>
                    );
                case 'form': {
                    if (!field?.form) {
                        return null;
                    }
                    return (
                        <Stack
                            direction="column"
                            spacing={2}
                            className={cx(flexBox.padding1, onlive.borderMediumGray, flexBox.borderRadius1)}
                        >
                            <RenderFormFields
                                formConfig={field.form}
                                fields={fields}
                                handleFieldChange={handleFieldChange}
                            />
                        </Stack>
                    );
                }
                default:
                    if (field.useDefault && defaultFields.find((f) => f.name === field.name)) {
                        return defaultFields.find((f) => f.name === field.name)?.component;
                    }
                    return null;
            }
        },
        [
            fields,
            handleFieldChange,
            getValidator,
            getErrorMessages,
            organization?.country,
            cx,
            flexBox.padding0,
            flexBox.margin01,
            flexBox.padding1,
            flexBox.borderRadius1,
            getLabel,
            defaultFields,
            handleKeyPress,
            renderOption,
            getOptionLabel,
            handleDateTimeChange,
            handleDateChange,
            onlive.borderMediumGray,
        ],
    );

    return (
        <Stack direction="column" spacing={2}>
            {(defaultFields || [])
                .filter((defaultField) => !formConfig?.fields?.find((field) => field.name === defaultField.name))
                .map((defaultField) => defaultField.component)}
            {formConfig?.fields?.map((field) => {
                if (field.active !== false) {
                    return (
                        <FormGroup key={field.name}>
                            {!field.useDefault && field.type !== 'checkbox' && field.type !== 'privacy-policy' ? (
                                <FormLabel>{`${getLabel(field.label)}${field.required ? ' *' : ''}`}</FormLabel>
                            ) : null}
                            {renderField(field)}
                        </FormGroup>
                    );
                } else {
                    return null;
                }
            })}
        </Stack>
    );
};

export default RenderFormFields;
