import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';
import useStylesFieldForm from 'app/profile/settings/styles/useStylesFieldForm.styles';
import useStylesSettings from 'app/profile/settings/styles/useStylesSettings.styles';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import useFlexBoxStyles from 'common/flexBox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';

const Leads = (props) => {
    const { enableAddLeadManual, enableSendLeadRemarketingEmail, leadStatus, toggleChange, handleSubmit, hasChanges } =
        props;

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const { classes: flexBox } = useFlexBoxStyles();

    const [inputError, setInputError] = useState({ leadStatus: null });
    const classes = { ...classes1, ...classes2 };

    const settingsDirty = useSelector(getSettingsDirty);

    const handleCheckChange = (event, settingName) => {
        const checked = event.target.checked;
        toggleChange(settingName, checked);
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.leadsSettings.leads')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                        <Button variant="contained" className={classes.redColor} size="small" onClick={handleSubmit}>
                            {t('profile.settings.leadsSettings.save')}
                        </Button>
                    </Box>
                </Box>
                <ItemSettings
                    label={t('profile.settings.leadsSettings.enableAddLeadManual')}
                    onChange={handleCheckChange}
                    name={'enableAddLeadManual'}
                    keySettings={SettingsKey.ENABLE_ADD_LEAD_MANUAL}
                    value={enableAddLeadManual}
                    paddingBottom
                />
                <ItemSettings
                    label={t('profile.settings.leadsSettings.enableSendLeadRemarketingEmail')}
                    onChange={handleCheckChange}
                    name={'enableSendLeadRemarketingEmail'}
                    keySettings={SettingsKey.ENABLE_SEND_LEAD_REMARKETING_EMAIL}
                    value={enableSendLeadRemarketingEmail}
                    paddingBottom
                />
                <Stack className={flexBox.paddingTop2}>
                    <Typography className={classes.emailsTemplatesText}>{`${t(
                        'settings.leads.leadsStatus',
                    )}`}</Typography>
                    <JsonCodeInputMonacoEditor
                        name="leadStatus"
                        value={leadStatus}
                        onChange={(value, errors) => {
                            const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
                            const newErrors = codeHaveErrors ? errors : null;
                            setInputError({ leadStatus: newErrors });
                            toggleChange(SettingsKey.LEADS_STATUS, value);
                        }}
                    />
                    <SimpleCodeErrorView
                        id={'error-context'}
                        mainErrorMessage={t('JsonError')}
                        errorList={inputError['leadStatus']}
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default Leads;
