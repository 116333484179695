import { makeStyles } from 'tss-react/mui';

const useStylesLogo = makeStyles()((theme) => {
    return {
        logo: {
            height: '65px',
            // backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
            '& img': {
                maxWidth: '62%',
                height: 'auto',
                maxHeight: '60px',
            },
        },
    };
});

export default useStylesLogo;
