import { BusinessCenterOutlined, CalendarTodayOutlined, Google } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    SvgIcon,
} from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useFormFields } from 'libs/hooksLib';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { enableCalendarIntegrationAction } from 'redux/slices/icalendarProviderSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    customIntegrationDialogWidth: {
        '& .MuiDialog-paper': {
            width: 'auto',
            minWidth: '25%',
        }
    },
    integrationDialogContent: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const ICalendarIntegrationDialog = (props) => {
    const { openDialog, onCloseDialog } = props;

    const { classes: classes1 } = useStylesForm();
    const { classes: classes2 } = useStyles();
    const classes = { ...classes1, ...classes2 };
    const { t } = useTranslation();
    const organization = useSelector(getOrganization);
    const { id: organizationId } = organization;
    const dispatch = useDispatch();
    const [fields, handleFieldChange] = useFormFields({
        credentialLevel: 'Organization',
        provider: 'Microsoft',
    });

    const handleOk = () => {
        onCloseDialog();
        dispatch(enableCalendarIntegrationAction(organizationId, fields));
    };

    return (
        <Dialog
            open={openDialog}
            onClose={onCloseDialog}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="icalendar-integration-dialog"
            className={classes.customIntegrationDialogWidth}
        >
            <DialogTitle id="icalendar-integration-dialog-title">{t('profile.settings.iCalendar.addCalendarIntegration')}</DialogTitle>
            <DialogContent className={classes.integrationDialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RoundedSelect
                            value={fields.provider}
                            fullWidth
                            name={'provider'}
                            onChange={handleFieldChange}
                            iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                        >
                            <MenuItem
                                value={'Microsoft'}
                                key={'Microsoft'}
                            >
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <SvgIcon color="gray">
                                            <path d="M7.88 12.04q0 .45-.11.87-.1.41-.33.74-.22.33-.58.52-.37.2-.87.2t-.85-.2q-.35-.21-.57-.55-.22-.33-.33-.75-.1-.42-.1-.86t.1-.87q.1-.43.34-.76.22-.34.59-.54.36-.2.87-.2t.86.2q.35.21.57.55.22.34.31.77.1.43.1.88zM24 12v9.38q0 .46-.33.8-.33.32-.8.32H7.13q-.46 0-.8-.33-.32-.33-.32-.8V18H1q-.41 0-.7-.3-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h6.5V2.55q0-.44.3-.75.3-.3.75-.3h12.9q.44 0 .75.3.3.3.3.75V10.85l1.24.72h.01q.1.07.18.18.07.12.07.25zm-6-8.25v3h3v-3zm0 4.5v3h3v-3zm0 4.5v1.83l3.05-1.83zm-5.25-9v3h3.75v-3zm0 4.5v3h3.75v-3zm0 4.5v2.03l2.41 1.5 1.34-.8v-2.73zM9 3.75V6h2l.13.01.12.04v-2.3zM5.98 15.98q.9 0 1.6-.3.7-.32 1.19-.86.48-.55.73-1.28.25-.74.25-1.61 0-.83-.25-1.55-.24-.71-.71-1.24t-1.15-.83q-.68-.3-1.55-.3-.92 0-1.64.3-.71.3-1.2.85-.5.54-.75 1.3-.25.74-.25 1.63 0 .85.26 1.56.26.72.74 1.23.48.52 1.17.81.69.3 1.56.3zM7.5 21h12.39L12 16.08V17q0 .41-.3.7-.29.3-.7.3H7.5zm15-.13v-7.24l-5.9 3.54Z" />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={'Microsoft'} />
                                </ListItem>
                            </MenuItem>
                            <MenuItem
                                value={'Google'}
                                key={'Google'}
                                disabled
                            >
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <Google fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={'Google'} />
                                </ListItem>
                            </MenuItem>
                        </RoundedSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedSelect
                            value={fields.credentialLevel}
                            fullWidth
                            name={'credentialLevel'}
                            onChange={handleFieldChange}
                            iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                        >
                            <MenuItem
                                value={'Organization'}
                                key={'Organization'}
                            >
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <BusinessCenterOutlined fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('profile.profile.organization')} />
                                </ListItem>
                            </MenuItem>
                            <MenuItem
                                value={'Calendar'}
                                key={'Calendar'}
                                disabled
                            >
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <CalendarTodayOutlined fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('systemLog.systemLogTable.user')} />
                                </ListItem>
                            </MenuItem>
                        </RoundedSelect>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCloseDialog} color="primary">
                    {t('common.term.Cancel')}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t('common.term.OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ICalendarIntegrationDialog;