import { createSlice } from '@reduxjs/toolkit';

export const customDialogSlice = createSlice({
    name: 'customDialog',
    initialState: {
        dialogOpen: false,
        content: null,
        onClose: false,
        options: {},
    },
    reducers: {
        backdropClickHandler: (state, action) => {
            state.dialogOpen = false;
            state.onClose = false;
            state.content = null;
        },
        cancelCloseHandler: (state, action) => {
            state.onClose = false;
        },
        setContent: (state, action) => {
            state.dialogOpen = true;
            state.content = action.payload;
        },
        setCustomDialogOptions: (state, action) => {
            state.options = action.payload;
        },
    },
});

export const {
    backdropClickHandler,
    cancelCloseHandler,
} = customDialogSlice.actions;

export const getIsDialogOpen = (state) => state.customDialog.dialogOpen;
export const getIsDialogOnClose = (state) => state.customDialog.onClose;
export const getDialogContent = (state) => state.customDialog.content;
export const getCustomDialogOptions = (state) => state.customDialog.options;

export const openCustomDialog = (content, options = {}) => (dispatch) => {
    dispatch(customDialogSlice.actions.setContent(content));
    dispatch(customDialogSlice.actions.setCustomDialogOptions(options || {}));
};

export const closeCustomDialogAction = () => backdropClickHandler();

export const cancelCloseCustomDialogAction = () => cancelCloseHandler();

export default customDialogSlice.reducer;
