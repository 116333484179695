import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, Box, Button, FormControl, FormGroup, FormLabel } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import CustomTextValidator from 'common/components/CustomTextValidators';
import WrapWithTooltip from 'common/components/WrapWithTooltip';
import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import IChatNotificationsSettings from './IChatNotificationsSettings';

const IChatSettings = (props) => {
    const {
        toggleChange,
        handleSubmit,
        hasChanges,
        iChatVersion,
        ichatNotifications,
        handleChangeNotification,
        ichatTranslations,
    } = props;
    const user = useSelector(getUser);
    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2 } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };
    const isAuthorized = user?.role === 'root';
    const [inputError, setInputError] = useState({ ichat_options: null });

    const settingsDirty = useSelector(getSettingsDirty);

    const onChangeIChatVersion = (event) => {
        toggleChange(SettingsKey.ICHAT_VERSION, event.target.value);
    };

    const handleValueChangeCode = (value, name, errors) => {
        const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
        const error2Input = codeHaveErrors ? errors : null;

        setInputError({ ...inputError, [name]: error2Input });

        toggleChange(name, value);
    };

    // const handleCheckChange = (event, settingName) => {
    //     toggleChange(settingName, event.target.checked);
    // };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ArrowRightIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.ichatSettings.ichat')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <div style={{ paddingBottom: '20px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                        <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}>
                            <Button
                                variant="contained"
                                className={classes.redColor}
                                size="small"
                                onClick={handleSubmit}
                            >
                                {t('Save changes')}
                            </Button>
                        </Box>
                    </Box>
                </div>
                {/* <ItemSettings
                    label={t('profile.settings.ichatSettings.enableIChat')}
                    onChange={handleCheckChange}
                    name={SettingsKey.ENABLE_ICHAT}
                    keySettings={SettingsKey.ENABLE_ICHAT}
                    value={enableIChat}
                    paddingBottom
                /> */}

                {isAuthorized && (
                    <div className={classes.mb20px}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: '100%' }}
                        >
                            <Typography className={classes.emailsTemplatesText}>
                                {t('profile.settings.oneToOneSettings.iChatVersion')}
                            </Typography>
                            <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}></Box>
                        </Box>
                        <ValidatorForm onSubmit={() => {}} onError={() => {}}>
                            <FormControl style={{ width: '50%', margin: '5px 0 0 0' }}>
                                <WrapWithTooltip style={{ width: '100%' }} placement={'left'}>
                                    <CustomTextValidator
                                        name="ichat_version"
                                        value={iChatVersion}
                                        variant="outlined"
                                        onChange={onChangeIChatVersion}
                                        fullWidth={true}
                                        placeholder={!iChatVersion && 'Stable'}
                                    />
                                </WrapWithTooltip>
                            </FormControl>
                        </ValidatorForm>
                    </div>
                )}

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.settings.oneToOneSettings.ichatNotifications')}</FormLabel>
                    <IChatNotificationsSettings
                        notifications={ichatNotifications}
                        onChange={handleChangeNotification}
                    />
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.settings.oneToOneSettings.icallTranslations')}</FormLabel>
                    <FormControl className={classes.formControl}>
                        <JsonCodeInputMonacoEditor
                            name="icall_translations"
                            value={ichatTranslations}
                            onChange={(value, errors) =>
                                handleValueChangeCode(value, SettingsKey.ICHAT_TRANSLATIONS, errors)
                            }
                        />
                        <SimpleCodeErrorView
                            id={SettingsKey.ICHAT_TRANSLATIONS}
                            mainErrorMessage={t('JsonError')}
                            errorList={inputError[SettingsKey.ICHAT_TRANSLATIONS]}
                        />
                    </FormControl>
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );
};

export default IChatSettings;
