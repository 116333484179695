import BackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { isSafari } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeCustomDialogAction,
    getCustomDialogOptions,
    getDialogContent,
    getIsDialogOpen,
} from 'redux/slices/customDialogSlice';
import { setMaximizeChart } from 'redux/slices/dashboardSlice';
import CloseIconButton from './CloseIconButton';

import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '&:not(.fullScreen) .MuiDialog-paperScrollPaper': {
                borderRadius: '16px',
            },

            '& .MuiDialogContent-root': {
                boxSizing: 'border-box',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
            },
        },

        drawerPaper: {
            position: 'initial !important',
        },
        backspace: {
            position: 'absolute',
            left: theme.spacing(6),
            top: theme.spacing(1.5),
        },
    };
});

const CustomDialog = (props) => {
    const { open, onClose: onCloseProp, children } = props;
    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const dispatch = useDispatch();

    const content = useSelector(getDialogContent);
    const options = useSelector(getCustomDialogOptions);

    const {
        maxWidth = false,
        fullScreen = false,
        enableAutoClose = false,
        backspaceLabel,
        contentProperties,
        onClose,
        title,
        showCloseIcon = true,
        titleClass,
    } = options;

    const isDrawerOpen = useSelector(getIsDialogOpen);

    const handleClose =
        onClose ||
        onCloseProp ||
        (() => {
            dispatch(setMaximizeChart(null));
            dispatch(closeCustomDialogAction());
        });

    const dialogContent =
        typeof content === 'function' && !children
            ? React.createElement(content, { handleClose, ...contentProperties })
            : content;

    const actions = (
        <>
            {showCloseIcon ? <CloseIconButton onClick={handleClose} inside={true} /> : null}
            {fullScreen && backspaceLabel ? (
                <Typography
                    component="div"
                    className={cx(
                        !title && classes.backspace,
                        flexBox.flex,
                        flexBox.gap1,
                        flexBox.cursorPointer,
                        flexBox.alignItemsCenter,
                    )}
                    onClick={handleClose}
                >
                    <BackspaceIcon size="small" />
                    {backspaceLabel}
                </Typography>
            ) : null}
        </>
    );

    return (
        <Dialog
            maxWidth={maxWidth}
            className={cx(classes.root, fullScreen && 'fullScreen')}
            fullScreen={fullScreen}
            open={open || isDrawerOpen}
            onClose={enableAutoClose ? handleClose : undefined}
            classes={{
                paper: isSafari ? classes.drawerPaper : '',
            }}
        >
            {title ? (
                <DialogTitle className={titleClass}>
                    {title}
                    {actions}
                </DialogTitle>
            ) : null}
            <DialogContent className={flexBox.relative}>
                {title ? null : actions}
                {children || dialogContent}
            </DialogContent>
        </Dialog>
    );
};

export default CustomDialog;
