import { Add } from '@mui/icons-material';
import { FormLabel, Stack } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { useTranslation } from 'react-i18next';
import FormOptionValue from './FormOptionValue';

const FormFieldOptions = ({ options, setOptions, error, setError }) => {
    const { t } = useTranslation();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: onlive, cx } = useOnliveStyles();

    const handleDelete = (idx) => {
        let newOptions = [...options];
        newOptions.splice(idx, 1);
        if (error === idx) {
            setError(-1);
        }
        setOptions(newOptions);
    };

    const handleChange = (data, idx) => {
        const newOptions = [...options.slice(0, idx), data, ...options.slice(idx + 1)];
        setOptions(newOptions);
    };

    const addOption = () => {
        let newOptions = [{ value: '', label: [{ languageCode: 'en', value: '' }] }, ...options];
        setOptions(newOptions);
    };

    const onChange = (data, idx) => {
        let i = options?.findIndex((f, index) => f.value === data.value && index !== idx);
        setError(i === -1 ? -1 : idx);
        handleChange(data, idx);
    };

    return (
        <Stack
            direction="column"
            className={cx(flexBox.padding1, onlive.borderMediumGray, flexBox.borderRadius1, flexBox.marginBottom2)}
            spacing={2}
        >
            <FormLabel>{t('form.fields.addSelectOption').toUpperCase()}</FormLabel>

            {options.length ? (
                <Stack direction="row" spacing={2}>
                    <FormLabel className={flexBox.w40}>{t('form.fields.optionValue')}</FormLabel>
                    <FormLabel className={flexBox.w40}>
                        {t('profile.settings.viewerExtraField.fieldForm.label')}
                    </FormLabel>
                </Stack>
            ) : null}

            {options?.map((opt, idx) => (
                <FormOptionValue
                    key={idx}
                    index={idx}
                    option={opt}
                    options={options}
                    error={error}
                    onChange={(data) => onChange(data, idx)}
                    onDelete={() => handleDelete(idx)}
                />
            ))}
            <ActionButton fullWidth={true} color="secondary" disableElevation={true} onClick={addOption}>
                <Add />
                <span>{t('calendar.common.add')}</span>
            </ActionButton>
        </Stack>
    );
};

export default FormFieldOptions;
