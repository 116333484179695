import api from "api";
import {createSlice} from "@reduxjs/toolkit";

const resource = 'pricing';

export const pricingSlice = createSlice({
    name: resource,
    initialState: {
        pendingPricing: false,
        pricing: null,
        pricings: [],
        count: 0,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        setPricing: (state, action) => {
            state.pricing = action.payload;
            state.pendingPricing = false;
        },
        setPricings: (state, action) => {
            state.pendingPricing = false;
            state.count = action.payload.count;
            state.pricings = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setErrorFetchPricing: (state, action) => {
            state.pendingPricing = false;
            state.errorFetchPricing = action.payload;
        },
        startFetchPricing: (state, action) => {
            state.pendingPricing = true;
        },
        stopFetchPricing: (state, action) => {
            state.pendingPricing = false;
        },
    },
});

export const { setPricings, setPricing, setErrorFetchPricing, startFetchPricing, stopFetchPricing } =
    pricingSlice.actions;

export const getPricing = (state) => state[resource].pricing;
export const getPricings = (state) => state[resource].pricings;
export const getPendingPricing = (state) => state[resource].pendingPricing;

export const fetchOnePricingAction =
    ({ id, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startFetchPricing());
            api.get(`${resource}/${id}`)
                .then((response) => {
                    dispatch(setPricing(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setErrorFetchPricing(error));
                });
        } catch (error) {
            dispatch(stopFetchPricing());
            return console.error(error.message);
        }
    };

export const fetchOnePricingsAction =
    ({ onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            dispatch(startFetchPricing());
            api.get('pricing')
                .then((response) => {
                    dispatch(setPricings(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setErrorFetchPricing(error));
                });
        } catch (error) {
            dispatch(stopFetchPricing());
            return console.error(error.message);
        }
    };

export default pricingSlice.reducer;
