import { Stack, Typography } from '@mui/material';
import YesNoDialog from 'common/components/YesNoDialog';
import useFlexBoxStyles from 'common/flexBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '&.small': {
                maxWidth: theme.spacing(70),
                width: '30vw',
                [theme.breakpoints.up('xl')]: {
                    maxHeight: theme.spacing(160),
                    maxWidth: theme.spacing(60),
                    width: '22vw',
                },
                [theme.breakpoints.down('mg')]: {
                    width: '60vw',
                },
                [theme.breakpoints.down('ms')]: {
                    width: '80vw',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '70vw',
                },
            },
            '&.medium': {
                maxWidth: theme.spacing(70),
                width: '40vw',
                [theme.breakpoints.up('xl')]: {
                    maxHeight: theme.spacing(160),
                    maxWidth: theme.spacing(100),
                    width: '35vw',
                },
                [theme.breakpoints.down('mg')]: {
                    width: '60vw',
                },
                [theme.breakpoints.down('ms')]: {
                    width: '80vw',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '70vw',
                },
            },
            '&.big': {
                maxWidth: theme.spacing(140),
                width: '65vw',
                [theme.breakpoints.up('xl')]: {
                    maxHeight: theme.spacing(160),
                    maxWidth: theme.spacing(140),
                    width: '55vw',
                },
                [theme.breakpoints.down('mg')]: {
                    width: '80vw',
                },
            },
        },
        padding: {
            padding: theme.spacing(5, 7, 4),
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(6, 8, 5),
            },
            [theme.breakpoints.down('mg')]: {
                padding: theme.spacing(4),
            },
            [theme.breakpoints.down('ms')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
            },
        },
    };
});

const LayoutForm = (props) => {
    const { title, extra, customClasses, size = 'medium', noPadding = false, confirmDlg = {}, children } = props;
    const { accept, cancel, titleDlg, messageDlg, open } = confirmDlg;

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();

    return (
        <Stack
            direction="column"
            spacing={2}
            className={cx(
                classes.root,
                size,
                flexBox.h100,
                flexBox.overflowHidden,
                flexBox.relative,
                customClasses,
                noPadding ? flexBox.maxW100 : classes.padding,
            )}
        >
            <Stack direction="column" className={cx(noPadding && classes.padding, flexBox.paddingBottom2)}>
                {title && (
                    <Typography fontWeight="bold" variant="onliveH5">
                        {title.toUpperCase()}
                    </Typography>
                )}
                {extra}
            </Stack>

            {children}

            {open && (
                <YesNoDialog
                    openDialog={true}
                    title={titleDlg}
                    messageToConfirm={messageDlg}
                    yesButtonCallback={() => {
                        accept();
                    }}
                    noButtonCallback={() => {
                        cancel();
                    }}
                />
            )}
        </Stack>
    );
};

export default LayoutForm;
