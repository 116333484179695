import { makeStyles } from 'tss-react/mui';

const useStylesItemInfo = makeStyles()((theme) => {
    return {
        rootOrgData: {
            display: "flex",
            flexDirection: "column",
        },
        text: {
            fontSize: '12px',
            fontWeight: '500',
            paddingBottom: '20px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
            }
        },
        title: {
            opacity: '0.3'
        },
        content: {},
    };
});

export default useStylesItemInfo;
