import { Grid, Stack, Typography } from '@mui/material';
import DateRange from 'common/components/DateRange';
import useFlexBoxStyles from 'common/flexBox';
import { useIncludeDateValidation } from 'common/hooks/useIncludeDateValidation';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ServicePeriodOfService = ({ fields, handleFieldChange, disabled, timeZone }) => {
    const { t } = useTranslation();
    const { classes: flexBox } = useFlexBoxStyles();
    const { isValidDateRange, error, validationType, setError } = useIncludeDateValidation();
    let dates = fields?.dateRanges || [];
    const [showAddForm, setShowAddForm] = useState(false);
    dates = [...dates].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    const handleEditDate = (newDate, idx) => {
        const newRange = [
            ...dates.slice(0, idx),
            { startDate: moment.tz(newDate.start, timeZone), endDate: moment.tz(newDate.end, timeZone) },
            ...dates.slice(idx + 1),
        ];
        saveRange(newRange);
    };

    const handleAddDate = (newDate) => {
        const newRange = [
            ...dates,
            { startDate: moment.tz(newDate.start, timeZone), endDate: moment.tz(newDate.end, timeZone) },
        ];
        saveRange(newRange);
    };

    const handleDeleteDate = (idx) => {
        const newRange = [...dates.slice(0, idx), ...dates.slice(idx + 1)];
        if (error?.idx === idx) {
            setError(null);
        }
        saveRange(newRange);
    };

    const saveRange = (newRange) => {
        handleFieldChange({
            target: {
                name: 'dateRanges',
                value: newRange,
            },
        });
    };

    const handleChange = (date, idx, callback) => {
        const isValid = isValidDateRange({ ranges: dates, date, idx, type: validationType.UNSELECTED });
        if (isValid) {
            if (idx === -1) {
                handleAddDate(date);
                setShowAddForm(false);
                callback?.();
            } else {
                handleEditDate(date, idx);
            }
        }
    };

    return (
        <Stack direction="column" spacing={1} className={flexBox.paddingTop2}>
            <Grid container>
                <Grid item sm={1.5}>
                    <Typography variant="subtitle2" fontWeight={400} className={flexBox.paddingTop1}>
                        {t('services.form.dateFrom').toUpperCase()}
                    </Typography>
                </Grid>

                <Grid item sm={10.5}>
                    <Stack direction="column" spacing={1}>
                        {dates?.map((date, idx) => (
                            <DateRange
                                key={idx}
                                type="datetime-local"
                                date={{
                                    start: moment.tz(date.startDate, timeZone).format('YYYY-MM-DDTHH:mm'),
                                    end: moment.tz(date.endDate, timeZone).format('YYYY-MM-DDTHH:mm'),
                                }}
                                onChange={(date) => handleChange(date, idx)}
                                onDelete={() => handleDeleteDate(idx)}
                                error={error?.idx === idx ? error?.msg : null}
                                disabled={error ? error?.idx !== idx : disabled}
                            />
                        ))}

                        <DateRange
                            isDates={dates?.length}
                            showAddForm={showAddForm}
                            setShowAddForm={setShowAddForm}
                            type="datetime-local"
                            onChange={(date, callback) => handleChange(date, -1, callback)}
                            onDelete={() => setError(null)}
                            error={error?.idx === -1 ? error?.msg : null}
                            disabled={error ? error?.idx !== -1 : disabled}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default ServicePeriodOfService;
