import React from 'react';
import { SvgIcon } from '@mui/material';

const withIcon =
    (Component) =>
    ({ ...rest }) =>
        (
            <SvgIcon {...rest}>
                <Component />
            </SvgIcon>
        );

export { withIcon };
