import { Add } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import CustomIconButton from 'common/components/CustomIconButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useStylesOCalendar from '../../../ocalendar/styles/useStylesOCalendar.styles';
import HoursRange from './HoursRange';

const WeeklyHours = (props) => {
    const { days, onChange, onHourRangeChange, onAddHourRange, onDeleteHourRange, timeZone } = props;

    const { classes } = useStylesOCalendar();
    const { t, i18n } = useTranslation();

    moment.locale(i18n.language);

    return (
        <FormGroup className={classes.reactionRow}>
            {days.map((d) => {
                return (
                    <div className={classes.hoursPicker} key={d.index}>
                        <Grid container spacing={1}>
                            <Grid item md={3}>
                                <FormControlLabel
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            checked={d.enable}
                                            onChange={(e) => onChange(d.index)}
                                            name={d.name}
                                            className={classes.checkBox}
                                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className={classes.text}>
                                            {moment().day(d.index).format('dddd').toUpperCase()}
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item md={8}>
                                {d.enable && d.hours.length ? (
                                    d.hours.map((h, indexHour) => {
                                        return (
                                            <HoursRange
                                                key={indexHour}
                                                initialHourBegin={h.startTime}
                                                initialHourEnd={h.endTime}
                                                onDelete={() => onDeleteHourRange(d.index, indexHour)}
                                                onChange={(hoursRange) =>
                                                    onHourRangeChange(d.index, indexHour, hoursRange)
                                                }
                                                timeZone={timeZone}
                                            />
                                        );
                                    })
                                ) : (
                                    <Typography className={classes.textUndefined}>
                                        {t('common.term.unavailable')}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item md={1}>
                                <CustomIconButton
                                    aria-label="add"
                                    className={classes.addButton}
                                    onClick={() => onAddHourRange(d.index)}
                                    disabled={!d.enable}
                                    hideBorder={true}
                                >
                                    <Add fontSize="small" />
                                </CustomIconButton>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
        </FormGroup>
    );
};

export default WeeklyHours;
