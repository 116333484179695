import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Divider, Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import { LayoutDetails } from 'common/layouts/LayoutDetails';
import config from 'config';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getLanguage } from 'redux/slices/settingSlice';
import { getTemplateTypes } from 'redux/slices/templateTypeSlice';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        small: {
            width: 'auto',
            minWidth: theme.spacing(60),
            padding: '16px 32px 42px',

            [theme.breakpoints.up('xl')]: {
                width: 'auto',
            },
            [theme.breakpoints.down('mg')]: {
                width: '80vw',
                minWidth: 'auto',
            },
            [theme.breakpoints.down('ms')]: {
                width: '80vw',
                minWidth: 'auto',
            },
        },
    };
});

const ServiceShare = ({ template, group }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes } = useStyles();
    const lang = useSelector(getLanguage);
    const organization = useSelector(getOrganization);
    const domain = useMemo(() => new URL(config.appointment.baseUrl), []);
    const sharingOptions = template?.sharingOptions || [];
    const templateTypes = useSelector(getTemplateTypes);

    const type = templateTypes.find((t) => t.id === template.typeId);

    const getSharingParams = useCallback(
        (baseParams) => {
            if (type?.sharingParams) {
                Object.keys(type.sharingParams)?.forEach((key) => {
                    baseParams.set(key, type.sharingParams[key]);
                });
            }
            if (template?.sharingParams) {
                Object.keys(template.sharingParams)?.forEach((key) => {
                    baseParams.set(key, template.sharingParams[key]);
                });
            }
            return baseParams;
        },
        [type, template],
    );

    const src = useMemo(() => {
        let baseParams = new URLSearchParams({
            olOrgId: organization.id,
            olInstContextType: 'TestDrive',
        });

        if (type) {
            baseParams.set('olServiceType', type.slug);
        } else {
            baseParams.set('olServiceId', template.id);
        }

        if (group?.metadata?.dealerId) {
            baseParams.set('olDealerId', group.metadata.dealerId);
        } else if (group?.metadata?.dealerGroupId) {
            baseParams.set('olDealerGroupId', group.metadata.dealerGroupId);
        }

        baseParams = getSharingParams(baseParams);

        return `${domain}?${baseParams.toString()}`;
    }, [organization, type, group, template, domain, getSharingParams]);

    const iframeCode = `
<iframe
  allow="autoplay; camera; microphone; display-capture; clipboard-write"
  id="onlive-iframe"
  width="100%"
  style="border: none; height: 80px"
  src="${src}"
>
</iframe>
`;

    const getOptionByLang = (option) => {
        const title = option.title?.[lang];
        const defaultTitle = option.title?.en;
        return (title || defaultTitle).toUpperCase();
    };

    return (
        <LayoutDetails className={classes.small}>
            <Stack direction="column" spacing={2} className={classes.container}>
                <Typography variant="onliveH5" fontWeight="600">
                    {t('service.serviceShare.title').toUpperCase()}
                </Typography>

                <Stack direction="row" spacing={2} className={flexBox.paddingTop2}>
                    <CopyToClipboard text={iframeCode} key={`action-copy-code`}>
                        <ActionButton
                            key={`action-copy-code`}
                            color="primary"
                            fitWidth
                            spinner={false}
                            onClick={(e) => {
                                e.preventDefault();
                                enqueueSnackbar(t('service.serviceShare.embedCodeCopied'), {
                                    variant: 'success',
                                });
                            }}
                        >
                            {t('service.serviceShare.embedCode')}
                        </ActionButton>
                    </CopyToClipboard>
                    <CopyToClipboard text={src} key={`action-copy-link`}>
                        <ActionButton
                            key={`action-copy-link`}
                            color="primary"
                            fitWidth
                            spinner={false}
                            onClick={(e) => {
                                e.preventDefault();
                                enqueueSnackbar(t('service.serviceShare.linkCopied'), {
                                    variant: 'success',
                                });
                            }}
                        >
                            {t('service.serviceShare.copyLink')}
                        </ActionButton>
                    </CopyToClipboard>
                </Stack>

                {sharingOptions?.map((option) => {
                    const baseParams = getSharingParams(new URLSearchParams());
                    const url = `${option.url}&${baseParams.toString()}`;

                    return (
                        <>
                            <Divider />

                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography variant="subtitle1" fontWeight="500">
                                    {getOptionByLang(option)}
                                </Typography>
                                <CustomTooltip
                                    key={`action-share-${option.url}`}
                                    arrow
                                    placement="bottom"
                                    title={t('actions.share')}
                                    aria-label="share"
                                >
                                    <CopyToClipboard text={url} key={`action-share-${option.url}`}>
                                        <CustomIconButton
                                            aria-label="copy to share"
                                            size="small"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                enqueueSnackbar(t('service.serviceShare.iframeCopied'), {
                                                    variant: 'success',
                                                });
                                            }}
                                        >
                                            <ContentCopyOutlinedIcon />
                                        </CustomIconButton>
                                    </CopyToClipboard>
                                </CustomTooltip>
                            </Stack>
                        </>
                    );
                })}
            </Stack>
        </LayoutDetails>
    );
};

export default ServiceShare;
