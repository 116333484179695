import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { isArray } from 'lodash';

export const customerSlice = createSlice({
    name: 'customers',
    initialState: {
        pending: false,
        customers: [],
        error: null,
        savePending: false,
        saveError: null,
        deletePending: false,
        deleteError: null,
        deleteId: null,
        demo: false,
        total: 0,
        count: 0,
        currentPage: 1,
        totalPages: 1,
        updatedAt: new Date()
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
            state.error = null;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setCustomers: (state, action) => {
            state.pending = false;
            let items, total, count, currentPage, totalPages;

            if (isArray(action.payload)) {
                items = action.payload;
                total = items.length;
                count = items.length;
                currentPage = 1;
                totalPages = 1;
            } else {
                items = action.payload.items;
                total = action.payload.total;
                count = action.payload.count;
                currentPage = action.payload.currentPage;
                totalPages = action.payload.totalPages;
            }

            state.customers = items;
            state.total = total;
            state.count = count;
            state.currentPage = currentPage;
            state.totalPages = totalPages;
        },
        setError: (state, action) => {
            state.pending = false;
            state.savePending = false;
            // state.customers = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
            state.saveError = null;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
        addCustomer: (state, action) => {
            state.savePending = false;
            state.customers = [action.payload, ...state.customers];
        },
        editCustomer: (state, action) => {
            state.savePending = false;
            const index = state.customers.findIndex((customer) => customer.id === action.payload.id);
            if (index !== -1) {
                state.customers = [
                    ...state.customers.slice(0, index),
                    action.payload,
                    ...state.customers.slice(index + 1),
                ];
            }
        },
        deleteCustomer: (state, action) => {
            state.deletePending = false;
            state.deleteId = null;
            const index = state.customers.findIndex((customer) => customer.id === action.payload.id);
            if (index !== -1) {
                state.customers = [...state.customers.slice(0, index), ...state.customers.slice(index + 1)];
            }
        },
        startDelete: (state, action) => {
            state.deletePending = true;
            state.deleteId = action.payload;
        },
        setErrorDelete: (state, action) => {
            state.deletePending = false;
            state.deleteError = action.payload;
            state.deleteId = null;
        },
    },
});

export const {
    start,
    stop,
    startSave,
    stopSave,
    setCustomers,
    setError,
    addCustomer,
    editCustomer,
    deleteCustomer,
    startDelete,
    setErrorDelete,
    setTotal,
    startFetchTotal,
    setErrorFetchTotal,
} = customerSlice.actions;

export const getCustomers = (state) => state.customers.customers;
export const getCustomersPending = (state) => state.customers.pending;
export const getCustomersError = (state) => state.customers.error;
export const getDeletePending = (state) => state.customers.deletePending;
export const getCustomersSavePending = (state) => state.customers.savePending;
export const getDeleteId = (state) => state.customers.deleteId;
// export const getCustomersTotal = (state) => state.customers.total;
export const getCustomersPagination = (state) => ({
    count: state.customers.count,
    currentPage: state.customers.currentPage,
    totalPages: state.customers.totalPages,
    total: state.customers.total
});

const filtersToQuery = (filters, pagination = false) => {
    const filtersMap = {
        ...filters,
        ...((pagination && { limit: filters.limit || 20, offset: filters.offset || 0 }) || {}),
    };
    let query = '';
    if (filters) {
        query = Object.keys(filtersMap)
            .filter((key) => filtersMap[key] || filtersMap[key] === 0)
            .map((key) => {
                return `${key}=${filtersMap[key]}`;
            })
            .join('&');
        if (query) {
            query = `?${query}`;
        }
    }
    return query;
};

export const fetchCustomersAction = (callback) => async (dispatch) => {
    try {
        dispatch(start());
        api.get('organizations')
            .then((response) => {
                dispatch(setCustomers(response.data));
                if (callback) {
                    callback(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const fetchCustomersFilterAction =
    ({ filters, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(start());
                api.get('organizations', { params: { ...filters } })
                    .then((response) => {
                        dispatch(setCustomers(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        if (onFailure) {
                            onFailure(error);
                        }
                        dispatch(setError(error));
                    });
            } catch (e) {
                dispatch(stop());
                return console.error(e.message);
            }
        };

export const fetchManagedCustomersFilter =
    ({ filters, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                let query = filtersToQuery(filters, true);
                dispatch(start());
                api.get('organizations/managed' + query)
                    .then((response) => {
                        dispatch(setCustomers(response.data));
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        if (onFailure) {
                            onFailure(error);
                        }
                        dispatch(setError(error));
                    });
            } catch (e) {
                dispatch(stop());
                return console.error(e.message);
            }
        };

export const editCustomerAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.put('organizations/' + id, data)
                    .then((response) => {
                        dispatch(editCustomer(response.data));
                        if (onSuccess) {
                            onSuccess();
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error(e.message);
            }
        };

export const editCustomerByRootAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.put('organizations/update-subscription/' + id, data)
                    .then((response) => {
                        dispatch(editCustomer(response.data));
                        if (onSuccess) {
                            onSuccess();
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setError(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error(e.message);
            }
        };

export const deleteCustomerAction =
    ({ id, onSuccess, onFailure }) =>
        async (dispatch) => {
            try {
                dispatch(startDelete(id));
                api.delete('organizations/' + id)
                    .then((response) => {
                        dispatch(deleteCustomer(response.data));
                        if (onSuccess) {
                            onSuccess();
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(setErrorDelete(error));
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                dispatch(setErrorDelete(e));
                return console.error(e.message);
            }
        };

export const downloadInvoicesReportAction =
    ({ data, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                api.post('organizations/invoices-report', data)
                    .then((response) => {
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                return console.error(e.message);
            }
        };

export const getInvoicesReportLinkAction =
    ({ filters, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                const query = filtersToQuery(filters);
                api.get('organizations/invoices-report' + query)
                    .then((response) => {
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                return console.error(e.message);
            }
        };

export const createAccountProfileAction =
    (profileDto, success, failure) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.post('organizations/profile/', profileDto)
                    .then((response) => {
                        dispatch(addCustomer(response.data));
                        if (success) {
                            success(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        dispatch(stopSave());
                        if (failure) {
                            failure(error);
                        }
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error(e.message);
            }
        };

export const editAccountProfileAction =
    (profileId, profileDto, success, failure) =>
        async (dispatch) => {
            try {
                dispatch(startSave());
                api.put('organizations/profile/' + profileId, profileDto)
                    .then((response) => {
                        dispatch(editCustomer(response.data));
                        if (success) {
                            success(null);
                        }
                        return response;
                    })
                    .catch((error) => {
                        if (failure) {
                            failure(error);
                        }
                    });
            } catch (e) {
                dispatch(stopSave());
                return console.error('error', e);
            }
        };

export const setCustomerDemoAction = ({
    id, demo, onSuccess, onFailure
}) => async (dispatch) => {
    try {
        api.put(`organizations/demo/${id}`, { demo })
            .then((response) => {
                dispatch(editCustomer(response.data));
                if (onSuccess) {
                    onSuccess(null);
                }
                return response;
            })
            .catch((error) => {
                if (onFailure) {
                    onFailure(error);
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const sendStatsSummaryEmailsAction =
    ({ id, data, onSuccess, onFailure } = {}) =>
        async (dispatch) => {
            try {
                api.post('organizations/stats-summary/' + id, data)
                    .then((response) => {
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        return response;
                    })
                    .catch((error) => {
                        if (onFailure) {
                            onFailure(error);
                        }
                    });
            } catch (e) {
                return console.error(e.message);
            }
        };

export default customerSlice.reducer;
