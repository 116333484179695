import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginCheck } from 'redux/slices/authSlice';
import { setCalendly } from 'redux/slices/calendarSlice';
import { setOrganization } from 'redux/slices/organizationSlice';
import OAuthTransition from './OAuthTransition';

function Authorize() {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location]);
    const navigate = useNavigate();

    const handleError = useCallback(
        (error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                enqueueSnackbar('Wrong password.', {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
            }
            //get roomId from url #/icall/:roomId and set in the localstorage
            navigate(`/login?state=${query.get('state')}`);
        },
        [navigate, enqueueSnackbar, query],
    );

    const signIn = useCallback(
        (error, data) => {
            if (error || !data) {
                navigate('/login');
            } else {
                enqueueSnackbar(t('views.login.loginSuccess'), {
                    variant: 'success',
                });

                dispatch(setOrganization(data.organization));
                dispatch(
                    setCalendly({
                        syncCalendly: data.organization.syncCalendly,
                        calendly: data.user.account?.calendlySchedulingUrl,
                    }),
                );

                if (isMobile) navigate(`/calls-mobile`);
                else {
                    navigate('/loading');
                }
            }
        },
        [navigate, dispatch, enqueueSnackbar, t],
    );

    useEffect(() => {
        if (location.pathname === '/authorize/callback') {
            dispatch(loginCheck(window.location.href, signIn));
        }
    }, [handleError, location, navigate, signIn, dispatch]);

    return <OAuthTransition message={t('login.welcome')} />;
}

export default Authorize;
