import React, { useRef, useCallback, useContext } from 'react';
import { BehaviorSubject } from 'rxjs';

const C = React.createContext({});

export const MicroFrontContext = ({ children }) => {
    const subjects = useRef({});
    
    const getMicroFrontState = useCallback((slug) => (
        subjects.current[slug] || (subjects.current[slug] = new BehaviorSubject({}))
    ), []);

    const updateMicroFrontState = useCallback((slug, state) => {
        const subject = getMicroFrontState(slug);
        subject.next({ ...subject.value, ...state });
    }, [getMicroFrontState]);
    
    return (
        <C.Provider value={{ getMicroFrontState, updateMicroFrontState }}>
            {children}
        </C.Provider>
    )
};

export const useMicroFrontContext = () => useContext(C);

export default MicroFrontContext;