import { MenuItem } from '@mui/material';
import RoundedSelect from 'common/components/RoundedSelect';
import { containsText } from 'common/utils/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getUser } from 'redux/slices/authSlice';
import { getServices } from 'redux/slices/serviceSlice';
import { getUnits } from 'redux/slices/unitSlice';

const SelectAssets = ({ customMenuProps, onSubmit, fullWidth, size = 'small', }) => {
    const { t } = useTranslation();
    const allAssets = useSelector(getUnits);
    const [assets, setAssets] = useState([]);
    const [params] = useSearchParams();
    const assetId = params.get('asset') || 'all';
    const services = useSelector(getServices);
    const user = useSelector(getUser);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (allAssets?.length > 0 && services?.length > 0) {
            if (user?.userGroupId) {
                const relatedCalendars = services.map((service) => service.relatedCalendarsIds);
                const relatedCalendarsIds = relatedCalendars.flat();
                const filteredAssets = allAssets.filter((asset) => relatedCalendarsIds.includes(asset.id));
                setAssets(filteredAssets);
            } else {
                setAssets(allAssets);
            }
        }
    }, [allAssets, services, setAssets, user?.userGroupId]);

    const onAssetsFilter = (text) => {
        const newAssets = allAssets.filter((option) => containsText(option.name + option.externalId, text));
        setAssets(newAssets);
    };

    useEffect(() => {
        if (!open) {
            setAssets([...allAssets]);
        }
    }, [allAssets, open, setAssets]);

    return (
        <RoundedSelect
            bold={true}
            value={assetId}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(e) => {
                onSubmit(e, 'asset');
            }}
            size={size}
            search={allAssets.length > 10}
            searchPlaceholder={t('iCalendar.filter.searchAssets')}
            onSearchFilter={onAssetsFilter}
            customMenuProps={customMenuProps}
            fullWidth={fullWidth}
        >
            <MenuItem value="all">{t('iCalendar.filter.allAssets')}</MenuItem>
            {assets?.map((asset) => (
                <MenuItem key={asset.id} value={asset.id}>
                    {asset.productName} ( {asset.externalId || asset.id} )
                </MenuItem>
            ))}
        </RoundedSelect>
    );
};

export default SelectAssets;
