import DateRange from 'common/enums/dateRange';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useDateRangeType({ maxDaysAllowed, showAll = true }) {
    const { t } = useTranslation();

    const dateRangeType = useMemo(() => {
        const allDateRanges = [
            ...(showAll ? [{ id: DateRange.ALL, name: t('dateRange.all') }] : []),
            { id: DateRange.CUSTOM, name: t('dateRange.custom') },
            { id: DateRange.TODAY, name: t('dateRange.today') },
            { id: DateRange.LAST_7_DAYS, name: t('dateRange.last7Days') },
            { id: DateRange.LAST_30_DAYS, name: t('dateRange.last30Days') },
            { id: DateRange.LAST_90_DAYS, name: t('dateRange.last90Days') },
        ];

        return maxDaysAllowed
            ? allDateRanges.filter((range) => {
                  switch (range.id) {
                      case DateRange.ALL:
                          return false;
                      case DateRange.LAST_7_DAYS:
                          return maxDaysAllowed >= 7;
                      case DateRange.LAST_30_DAYS:
                          return maxDaysAllowed >= 30;
                      case DateRange.LAST_90_DAYS:
                          return maxDaysAllowed >= 90;
                      default:
                          return true;
                  }
              })
            : allDateRanges;
    }, [maxDaysAllowed, showAll, t]);

    return dateRangeType;
}
