import { useSelector } from 'react-redux';
import { getDateRange } from 'redux/slices/dashboardSlice';
import AnalyticsDateRange from './AnalyticsDateRange';

const AnalyticsDateRangeSelector = () => {
    const dateRange = useSelector(getDateRange);
    
    return <AnalyticsDateRange dateRange={dateRange} />;
};

export default AnalyticsDateRangeSelector;
