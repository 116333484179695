import { makeStyles } from 'tss-react/mui';

const useFlexBoxStyles = makeStyles()((theme) => ({
    flex: {
        display: 'flex',
    },
    displayNone: {
        display: 'none',
    },
    displayOnlineBlock: {
        display: 'inline-block',
    },
    displayContents: {
        display: 'contents',
    },
    column: {
        flexDirection: 'column',
    },
    wrap: {
        flexWrap: 'wrap',
    },
    flex1: {
        flex: 1,
    },
    padding0: {
        padding: theme.spacing(0),
    },
    padding1: {
        padding: theme.spacing(1),
    },
    padding2: {
        padding: theme.spacing(2),
    },
    padding20: {
        padding: theme.spacing(2, 0),
    },
    padding21: {
        padding: theme.spacing(2, 1),
    },
    padding1001: {
        padding: theme.spacing(1, 0, 0, 1),
    },
    padding0111: {
        padding: theme.spacing(0, 1, 1, 1),
    },
    padding05: {
        padding: theme.spacing(0.5),
    },
    padding005: {
        padding: theme.spacing(0, 0.5),
    },
    padding115: {
        padding: theme.spacing(1, 1.5),
    },
    padding051: {
        padding: theme.spacing(0.5, 1),
    },
    padding10: {
        padding: theme.spacing(1, 0),
    },
    padding01: {
        padding: theme.spacing(0, 1),
    },
    padding02: {
        padding: theme.spacing(0, 2),
    },
    padding770: {
        padding: theme.spacing(7, 7, 0),
    },
    padding3: {
        padding: theme.spacing(3),
    },
    padding4: {
        padding: theme.spacing(4),
    },
    padding5: {
        padding: theme.spacing(5),
    },
    padding6: {
        padding: theme.spacing(6),
    },
    paddingTop05: {
        paddingTop: theme.spacing(0.5),
    },
    paddingTop1: {
        paddingTop: theme.spacing(1),
    },
    paddingTop1Important: {
        paddingTop: `${theme.spacing(1)} !important`,
    },
    paddingTop2: {
        paddingTop: theme.spacing(2),
    },
    paddingTop4: {
        paddingTop: theme.spacing(4),
    },
    paddingBottom0: {
        paddingBottom: 0,
    },
    paddingBottom05: {
        paddingBottom: theme.spacing(0.5),
    },
    paddingBottom1: {
        paddingBottom: theme.spacing(1),
    },
    paddingBottom1Important: {
        paddingBottom: `${theme.spacing(1)} !important`,
    },
    paddingRight05: {
        paddingRight: theme.spacing(0.5),
    },
    paddingRight1: {
        paddingRight: theme.spacing(1),
    },
    paddingRight2: {
        paddingRight: theme.spacing(2),
    },
    paddingRight3: {
        paddingRight: theme.spacing(3),
    },
    paddingRight4: {
        paddingRight: theme.spacing(4),
    },
    paddingRight6: {
        paddingRight: theme.spacing(6),
    },
    paddingLeft05: {
        paddingLeft: theme.spacing(0.5),
    },
    paddingLeft1: {
        paddingLeft: theme.spacing(1),
    },
    paddingLeft2: {
        paddingLeft: theme.spacing(2),
    },
    paddingLeft3: {
        paddingLeft: theme.spacing(3),
    },
    paddingLeft4: {
        paddingLeft: theme.spacing(4),
    },
    paddingLeft5: {
        paddingLeft: theme.spacing(5),
    },
    paddingLeft6: {
        paddingLeft: theme.spacing(6),
    },
    paddingBottom2: {
        paddingBottom: theme.spacing(2),
    },
    paddingBottom15: {
        paddingBottom: theme.spacing(1.5),
    },
    paddingBottom3: {
        paddingBottom: theme.spacing(3),
    },
    paddingBottom6: {
        paddingBottom: theme.spacing(6),
    },
    marginAuto: {
        margin: 'auto',
    },
    margin05: {
        margin: theme.spacing(0.5),
    },
    margin005: {
        margin: theme.spacing(0, 0.5),
    },
    margin050: {
        margin: theme.spacing(0.5, 0),
    },
    margin1: {
        margin: theme.spacing(1),
    },
    margin2: {
        margin: theme.spacing(2),
    },
    margin5: {
        margin: theme.spacing(5),
    },
    margin0: {
        margin: 0,
    },
    margin02: {
        margin: theme.spacing(0, 2),
    },
    marginTop05: {
        marginTop: theme.spacing(0.5),
    },
    marginTop1: {
        marginTop: theme.spacing(1),
    },
    marginTop2: {
        marginTop: theme.spacing(2),
    },
    marginLeft05: {
        marginLeft: theme.spacing(0.5),
    },
    marginLeft1: {
        marginLeft: theme.spacing(1),
    },
    marginLeft2: {
        marginLeft: theme.spacing(2),
    },
    margin01: {
        margin: theme.spacing(0, 1),
    },
    margin10: {
        margin: theme.spacing(1, 0),
    },
    margin20: {
        margin: theme.spacing(2, 0),
    },
    marginRight0: {
        marginRight: 0,
    },
    marginRight05: {
        marginRight: theme.spacing(0.5),
    },
    marginRight1: {
        marginRight: theme.spacing(1),
    },
    marginRight2: {
        marginRight: theme.spacing(2),
    },
    marginBottom0: {
        marginBottom: theme.spacing(0),
    },
    marginBottom05: {
        marginBottom: theme.spacing(0.5),
    },
    marginBottom1: {
        marginBottom: theme.spacing(1),
    },
    marginBottom2: {
        marginBottom: theme.spacing(2),
    },
    gap05: {
        gap: theme.spacing(0.5),
    },
    gap0: {
        gap: theme.spacing(0),
    },
    gap1: {
        gap: theme.spacing(1),
    },
    gap15: {
        gap: theme.spacing(1.5),
    },
    gap2: {
        gap: theme.spacing(2),
    },
    gap3: {
        gap: theme.spacing(3),
    },
    gap5: {
        gap: theme.spacing(5),
    },
    gap6: {
        gap: theme.spacing(6),
    },
    columnReverse: {
        flexFlow: 'column-reverse',
    },
    row: {
        flexFlow: 'row',
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    alignSelfEnd: {
        alignSelf: 'flex-end',
    },
    alignItemsStart: {
        alignItems: 'flex-start',
    },
    alignItemsEnd: {
        alignItems: 'flex-end',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    justifyContentEnd: {
        justifyContent: 'end',
    },
    justifyContentStart: {
        justifyContent: 'start',
    },
    justifySpaceBetween: {
        justifyContent: 'space-between',
    },
    justifySpaceAround: {
        justifyContent: 'space-around',
    },
    maxH100: {
        maxHeight: '100%',
    },
    maxH30: {
        maxHeight: '30%',
    },
    maxH50: {
        maxHeight: '50%',
    },
    minH100: {
        minHeight: '100%',
    },
    minHAuto: {
        minHeight: 'auto',
    },
    minH30: {
        minHeight: '30%',
    },
    minW25: {
        minWidth: '25%',
    },
    minW30: {
        minWidth: '30%',
    },
    minW40: {
        minWidth: '40%',
    },
    minW50: {
        minWidth: '50%',
    },
    minW100: {
        minWidth: '100%',
    },
    minWidth2: {
        minWidth: theme.spacing(2),
    },
    maxW50: {
        maxWidth: '50%',
    },
    maxW60: {
        maxWidth: '60%',
    },
    maxW80: {
        maxWidth: '80%',
    },
    maxW100: {
        maxWidth: '100%',
    },
    maxW200: {
        maxWidth: theme.spacing(200),
    },
    maxWFillAvailable: {
        maxWidth: '-webkit-fill-available',
    },
    shrink0: {
        flexShrink: 0,
    },
    grow1: {
        flexGrow: 1,
    },
    aspectRatio179: {
        aspectRatio: 1.79,
    },
    shrink4: {
        flexShrink: 4,
    },
    relative: {
        position: 'relative',
    },
    absolute: {
        position: 'absolute',
    },
    sticky: {
        position: 'sticky',
    },
    fixed: {
        position: 'fixed',
    },
    rightAuto: {
        right: 'auto',
    },
    rightM1: {
        right: theme.spacing(-1),
    },
    right0: {
        right: 0,
    },
    right05: {
        right: theme.spacing(0.5),
    },
    right1: {
        right: theme.spacing(1),
    },
    right2: {
        right: theme.spacing(2),
    },
    right3: {
        right: theme.spacing(3),
    },
    floatLeft: {
        float: 'left',
    },
    floatRight: {
        float: 'right',
    },
    leftAuto: {
        left: 'auto',
    },
    leftM1: {
        left: theme.spacing(-1),
    },
    left0: {
        left: 0,
    },
    left1: {
        left: theme.spacing(1),
    },
    left2: {
        left: theme.spacing(2),
    },
    top05: {
        top: theme.spacing(0.5),
    },
    topM1: {
        top: theme.spacing(-1),
    },
    top0: {
        top: 0,
    },
    top1: {
        top: theme.spacing(1),
    },
    top2: {
        top: theme.spacing(2),
    },
    bottom0: {
        bottom: 0,
    },
    bottom05: {
        bottom: theme.spacing(0.5),
    },
    bottom1: {
        bottom: theme.spacing(1),
    },
    bottom2: {
        bottom: theme.spacing(2),
    },
    wAuto: {
        width: 'auto',
    },
    wFitContent: {
        width: 'fit-content',
    },
    wInherit: {
        width: 'inherit',
    },
    maxW30: {
        maxWidth: '30%',
    },
    w10: {
        width: '10%',
    },
    w20: {
        width: '20%',
    },
    w25: {
        width: '25%',
    },
    w30: {
        width: '30%',
    },
    w40: {
        width: '40%',
    },
    w50: {
        width: '50%',
    },
    w60: {
        width: '60%',
    },
    w70: {
        width: '70%',
    },
    w75: {
        width: '75%',
    },
    w80: {
        width: '80%',
    },
    w90: {
        width: '90%',
    },
    w100: {
        width: '100%',
    },
    vW40: {
        width: '40vw',
    },
    vW50: {
        width: '50vw',
    },
    vW80: {
        width: '80vw',
    },
    vH40: {
        height: '40vh',
    },
    vH70: {
        height: '70vh',
    },
    vH80: {
        height: '80vh',
    },
    vH100: {
        height: '100vh',
    },
    minVH25: {
        minHeight: '25vh',
    },
    minVW25: {
        minWidth: '25vw',
    },
    minHeight2: {
        minHeight: theme.spacing(2),
    },
    hAuto: {
        height: 'auto',
    },
    h50: {
        height: '50%',
    },
    h60: {
        height: '60%',
    },
    h100: {
        height: '100%',
    },
    h1px: {
        height: 1,
    },
    hInherit: {
        height: 'inherit',
    },
    hFitContent: {
        height: 'fit-content',
    },
    hMinContent: {
        height: 'min-content',
    },
    zIndexBase: {
        zIndex: theme.zIndex.base,
    },
    zIndex0: {
        zIndex: 0,
    },
    zIndex1: {
        zIndex: 1,
    },
    zIndex2: {
        zIndex: 2,
    },
    zIndex3: {
        zIndex: 3,
    },
    zIndex7: {
        zIndex: 7,
    },
    zIndex8: {
        zIndex: 8,
    },
    zIndexTooltip: {
        zIndex: theme.zIndex.tooltip,
    },
    zIndexModal: {
        zIndex: theme.zIndex.modal,
    },
    overflowVisible: {
        overflow: 'visible',
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    overflowAuto: {
        overflow: 'auto',
    },
    overflowXHidden: {
        overflowX: 'hidden',
    },
    overflowYHidden: {
        overflowY: 'hidden',
    },
    overflowYAuto: {
        overflowY: 'auto',
    },
    borderNone: {
        border: 'none',
    },
    borderRadius0: {
        borderRadius: 0,
    },
    borderRadius05: {
        borderRadius: theme.spacing(0.5),
    },
    borderRadius1: {
        borderRadius: theme.spacing(1),
    },
    borderRadius50: {
        borderRadius: '50%',
    },
    borderRadius2: {
        borderRadius: theme.spacing(2),
    },
    borderRadius15: {
        borderRadius: theme.spacing(1.5),
    },
    borderTopRadius1: {
        borderRadius: theme.spacing(1, 1, 0, 0),
    },
    borderTopRadius2: {
        borderRadius: theme.spacing(2, 2, 0, 0),
    },
    borderBottomRadius1: {
        borderRadius: theme.spacing(0, 0, 1, 1),
    },
    borderBottomRadius2: {
        borderRadius: theme.spacing(0, 0, 2, 2),
    },
    opacity03: {
        opacity: 0.3,
    },
    opacity05: {
        opacity: 0.5,
    },
    opacity07: {
        opacity: 0.7,
    },
    opacity1: {
        opacity: 1,
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    textAlignStart: {
        textAlign: 'start',
    },
    borderBox: {
        boxSizing: 'border-box',
    },
    contentBox: {
        boxSizing: 'content-box',
    },
    objectFitContain: {
        objectFit: 'contain',
    },
    objectFitCover: {
        objectFit: 'cover',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    visibilityHidden: {
        visibility: 'hidden',
    },
    absoluteCentered: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    boxShadowNone: {
        boxShadow: 'none',
    },
    scrollbarNone: {
        /* Hide scrollbar for IE, Edge and Firefox */
        scrollbarWidth: 'none',
        /* Hide scrollbar for Chrome, Safari and Opera */
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    scrollbarCustom: {
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: theme.spacing(0.75),
            height: theme.spacing(0.75),
        },
        '&:hover': {
            scrollbarWidth: 'auto',
            '&::-webkit-scrollbar': {
                display: 'flex', // Initially set to flex
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.common.lightGray,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D5D5D5',
            borderRadius: theme.spacing(1),
        },
    },
    pointerEventsNone: {
        pointerEvents: 'none',
    },
    pointerEventsAll: {
        pointerEvents: 'all',
    },
    videoHidden: {
        '& video': {
            display: 'none',
        },
    },

    pagination: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: 'auto',
        paddingTop: theme.spacing(2),
    },

    aspectRatio169: {
        aspectRatio: '16 / 9',
    },
}));

export default useFlexBoxStyles;
