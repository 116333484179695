import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import useColorsStyles from 'common/colors';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomTooltip from '../CustomTooltip';

const useStyles = makeStyles()((theme) => ({
    closeIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        '& svg': {
            fontSize: theme.spacing(3),
            color: '#000',
        },
        '&:hover': {
            // background: theme.palette.background.paper,
        },
        '&.small': {
            width: theme.spacing(2),
            height: theme.spacing(2),
            '& svg': {
                fontSize: theme.spacing(1.5),
            },
        },
    },
    positionM1: {
        top: theme.spacing(-1),
        right: theme.spacing(-1),
    },
}));

const CloseIconButton = ({ className, onClick, icon, tooltip, tooltipKey, inside, size }) => {
    const { classes, cx } = useStyles();
    const { classes: colors } = useColorsStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();

    const { t } = useTranslation();

    const closeIconClass = cx(
        flexBox.absolute,
        flexBox.zIndexTooltip,
        flexBox.borderRadius50,
        colors.bgPaper,
        classes.closeIcon,
        inside ? cx(flexBox.right1, flexBox.top1, flexBox.borderNone) : cx(classes.positionM1, onlive.boxShadow),
        size,
    );

    return (
        <IconButton className={cx(closeIconClass, className)} size="small" onClick={onClick}>
            <CustomTooltip
                arrow
                title={tooltip || t(tooltipKey || 'common.customPopover.closeIcon')}
                placement="bottom"
            >
                {icon || <CloseIcon component="svg" />}
            </CustomTooltip>
        </IconButton>
    );
};

export default CloseIconButton;
