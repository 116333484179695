import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    FormGroup,
    FormHelperText,
    FormLabel,
    MenuItem,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import ChipsInput from 'common/components/ChipsInput';
import CustomTextValidator from 'common/components/CustomTextValidators';
import ReminderForm from 'common/components/ReminderForm';
import RoundedSelect from 'common/components/RoundedSelect';
import WrapWithTooltip from 'common/components/WrapWithTooltip';
import JsonCodeInputMonacoEditor from 'common/components/code-editor/JsonCodeInputMonacoEditor';
import SimpleCodeErrorView from 'common/components/code-editor/SimpleCodeErrorView';
import { useAuthLicenseAddOns } from 'common/features/licenseFeatures/useAuthLicenseAddOns';
import { isValidHttpUrl } from 'common/validators/validators';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { getSettingsDirty } from 'redux/slices/profileSlice';
import ItemSettings from '../components/ItemSettings';
import useStylesFieldForm from '../styles/useStylesFieldForm.styles';
import useStylesSettings from '../styles/useStylesSettings.styles';
import ICallNotificationsSettings from './ICallNotificationsSettings';

const boxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const CustomSlider = styled(Slider)(({ theme }) => ({
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow,
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 6,
        width: 2,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));

const OneToOneSettings = (props) => {
    const {
        toggleChange,
        handleSubmit,
        registerNotification,
        timeLeftShopping,
        hasChanges,
        showICallCategory,
        showICallResult,
        categories,
        results,
        iCallVersion,
        icallOptions,
        enablePresets,
        widgetInternalBrowserClient,
        widgetBaseUrl,
        enablePool,
        meetRecordingEnabled,
        qualityControlEnabled,
        recordingRandomProbability,
        meetRecordingLayout,
        meetQualityControlRecordingLayout,
        hideInputName,
        icallNotifications,
        handleChangeNotification,
        communicationOutcome,
        communicationLogsEnabled,
        icallTranslations,
        enableCamOnStartIcall,
        icallEmailReminders,
        paramsCharacter,
        icallSchema,
        enableHostIcallEmailReminder,
    } = props;

    const isAuthorize = useAuthLicenseAddOns();
    const iCalendar = isAuthorize(['oneToOne.iCalendar']);

    const { t } = useTranslation();
    const { classes: classes1 } = useStylesFieldForm();
    const { classes: classes2, cx } = useStylesSettings();
    const classes = { ...classes1, ...classes2 };
    const settingsDirty = useSelector(getSettingsDirty);
    const [stateCategories, setStateCategories] = useState(categories || []);
    const [stateResults, setStateResults] = useState(results || []);
    const user = useSelector(getUser);
    const isAuthorized = user?.role === 'root';
    const [inputError, setInputError] = useState({ icall_options: null });
    const [stateCommunicationOutcome, setStateCommunicationOutcome] = useState(communicationOutcome || []);

    const handleCheckChange = (event, settingName) => {
        toggleChange(settingName, event.target.checked);
    };

    const handleValueChange = (event, settingName) => {
        toggleChange(settingName, event.target.value);
    };

    const onChangeIcallVersion = (event) => {
        toggleChange(SettingsKey.ICALL_VERSION, event.target.value);
    };

    const handleValueChangeCode = (value, name, errors) => {
        const codeHaveErrors = errors && Array.isArray(errors) && errors.length > 0;
        const error2Input = codeHaveErrors ? errors : null;

        setInputError({ ...inputError, [name]: error2Input });

        toggleChange(name, value);
    };

    const onSubmitChanges = (e) => {
        handleSubmit(e);
    };

    const onTagChange = (value, setHandler, state, key) => {
        let updatedValues = [];

        if (!value.length) {
            setHandler([]);
            return;
        }

        if (state.length > value.length) {
            updatedValues = state.filter((r) => value.includes(r));
        } else if (state.length < value.length) {
            updatedValues = [...new Set([...state, ...value])];
        }

        setHandler(updatedValues);
        toggleChange(key, state);
    };

    const onBlurCategories = () => toggleChange(SettingsKey.ICALL_CATEGORIES, stateCategories);
    const onBlurResults = () => toggleChange(SettingsKey.ICALL_RESULTS, stateResults);
    const onBlurCommunicationOutcome = () => toggleChange(SettingsKey.COMMUNICATION_OUTCOME, stateCommunicationOutcome);

    const validBaseUrl = () => {
        return isValidHttpUrl(widgetBaseUrl) || widgetBaseUrl === '' || !widgetBaseUrl;
    };

    const templateSelectorStyle = {
        height: '34px',
        fontSize: '11px',
        marginTop: '5px',
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ArrowRightIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="subtitle1" fontWeight={500}>
                    {t('profile.settings.oneToOneSettings.oneToOne')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.eventSection}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ width: '100%' }}>
                    <Box sx={{ display: settingsDirty && hasChanges && validBaseUrl() ? 'inline-flex' : 'none' }}>
                        <Button className={classes.redColor} variant="contained" size="small" onClick={onSubmitChanges}>
                            {t('profile.settings.oneToOneSettings.save')}
                        </Button>
                    </Box>
                </Box>

                {/* <ItemSettings
                    label={t('profile.settings.oneToOneSettings.enableIcall')}
                    onChange={handleCheckChange}
                    name={SettingsKey.ENABLE_ICALL}
                    keySettings={SettingsKey.ENABLE_ICALL}
                    value={enableIcall}
                    paddingBottom
                /> */}

                {isAuthorized && (
                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.enablePresets')}
                        onChange={handleCheckChange}
                        name={SettingsKey.ENABLE_PRESETS}
                        keySettings={SettingsKey.ENABLE_PRESETS}
                        value={enablePresets}
                        paddingBottom
                    />
                )}

                <ItemSettings
                    label={t('Internal browser (for clients)')}
                    onChange={handleCheckChange}
                    name={'widgetInternalBrowserClient'}
                    keySettings={SettingsKey.ENABLE_WIDGET_INTERNAL_BROWSER_CLIENT}
                    value={widgetInternalBrowserClient}
                    paddingBottom
                />
                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.enableCamOnStart')}
                    onChange={handleCheckChange}
                    name={'enableCamOnStartIcall'}
                    keySettings={SettingsKey.ENABLE_CAM_ON_START_ICALL}
                    value={enableCamOnStartIcall}
                    paddingBottom
                />

                {isAuthorized && (
                    <div className={classes.mb20px}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: '100%' }}
                        >
                            <Typography className={classes.emailsTemplatesText}>
                                {t('profile.settings.oneToOneSettings.iCallVersion')}
                            </Typography>
                            <Box sx={{ display: settingsDirty && hasChanges ? 'inline-flex' : 'none' }}></Box>
                        </Box>
                        <ValidatorForm onSubmit={() => {}} onError={() => {}}>
                            <FormControl style={{ width: '50%', margin: '5px 0 0 0' }}>
                                <WrapWithTooltip style={{ width: '100%' }} placement={'left'}>
                                    <CustomTextValidator
                                        name="icall_version"
                                        value={iCallVersion}
                                        variant="outlined"
                                        onChange={onChangeIcallVersion}
                                        fullWidth={true}
                                        placeholder={!iCallVersion && 'Stable'}
                                    />
                                </WrapWithTooltip>
                            </FormControl>
                        </ValidatorForm>
                    </div>
                )}

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.registerNotification')}
                    onChange={handleCheckChange}
                    name={'registerNotification'}
                    keySettings={SettingsKey.ENABLE_EMAIL_POPUP_NOTIFICATION}
                    value={registerNotification}
                />

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.timeLeftShopping')}
                    onChange={handleCheckChange}
                    name={'timeLeftShopping'}
                    keySettings={SettingsKey.SHOW_VIEW_TIME_LEFT_SHOPPING}
                    value={timeLeftShopping}
                    paddingBottom
                />

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.hideInputName')}
                    onChange={handleCheckChange}
                    name={'hideInputName'}
                    keySettings={SettingsKey.HIDE_INPUT_NAME}
                    value={hideInputName}
                    paddingBottom
                />

                <div className={cx(showICallCategory && classes.mb20px)}>
                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.showICallCategoryInTable')}
                        onChange={handleCheckChange}
                        name={'showICallCategory'}
                        keySettings={SettingsKey.SHOW_ICALL_CATEGORY_IN_TABLE}
                        value={showICallCategory}
                        paddingBottom
                    />
                </div>

                {showICallCategory && (
                    <div className={classes.mb20px}>
                        <ChipsInput
                            clearOnEscape
                            name={'categories'}
                            label={t('event.category')}
                            value={stateCategories}
                            placeholder={t('common.input.addTagValue')}
                            onChange={(value) =>
                                onTagChange(value, setStateCategories, stateCategories, SettingsKey.ICALL_CATEGORIES)
                            }
                            onBlur={onBlurCategories}
                        />
                    </div>
                )}

                <div className={cx(showICallResult && classes.mb20px)}>
                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.showICallResultInTable')}
                        onChange={handleCheckChange}
                        name={'showICallResult'}
                        keySettings={SettingsKey.SHOW_ICALL_RESULT_IN_TABLE}
                        value={showICallResult}
                        paddingBottom
                    />
                </div>

                {showICallResult && (
                    <div className={classes.mb20px}>
                        <ChipsInput
                            name={'results'}
                            label={t('event.result')}
                            value={stateResults}
                            placeholder={t('common.input.addTagValue')}
                            onChange={(value) =>
                                onTagChange(value, setStateResults, stateResults, SettingsKey.ICALL_RESULTS)
                            }
                            onBlur={onBlurResults}
                        />
                    </div>
                )}

                <div>
                    <ItemSettings
                        label={t('profile.settings.oneToOneSettings.enablePool')}
                        onChange={handleCheckChange}
                        name={'enablePool'}
                        keySettings={SettingsKey.ENABLE_POOL}
                        value={enablePool}
                        paddingBottom
                    />
                </div>

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.call_recording')}
                    onChange={handleCheckChange}
                    name={SettingsKey.MEET_RECORDING_ENABLED}
                    keySettings={SettingsKey.MEET_RECORDING_ENABLED}
                    value={meetRecordingEnabled}
                    paddingBottom
                />

                {meetRecordingEnabled && (
                    <>
                        <div className={classes.my10px}>
                            <Typography className={classes.text}>
                                {t('profile.settings.oneToOneSettings.recordingLayout')}
                            </Typography>
                            <RoundedSelect
                                value={meetRecordingLayout}
                                style={templateSelectorStyle}
                                fullWidth
                                onChange={(e) => handleValueChange(e, SettingsKey.MEET_RECORDING_LAYOUT)}
                                iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                            >
                                <MenuItem value="host-only" key="host-only">
                                    {t('profile.settings.oneToOneSettings.hostOnly')}
                                </MenuItem>
                                <MenuItem value="active-speaker" key="active-speaker">
                                    {t('profile.settings.oneToOneSettings.activeSpeaker')}
                                </MenuItem>
                                <MenuItem value="all-participants" key="all-participants">
                                    {t('profile.settings.oneToOneSettings.layoutAllParticipants')}
                                </MenuItem>
                            </RoundedSelect>
                        </div>

                        <ItemSettings
                            label={t('profile.settings.oneToOneSettings.quality_control')}
                            onChange={handleCheckChange}
                            name={SettingsKey.MEET_QUALITY_CONTROL_ENABLED}
                            keySettings={SettingsKey.MEET_QUALITY_CONTROL_ENABLED}
                            value={qualityControlEnabled}
                            paddingBottom
                        />

                        {qualityControlEnabled && (
                            <div className={classes.my20px}>
                                <Box sx={{ width: 300 }}>
                                    <CustomSlider
                                        aria-label="Recording random values"
                                        defaultValue={50}
                                        value={recordingRandomProbability}
                                        step={5}
                                        marks
                                        min={0}
                                        max={100}
                                        valueLabelDisplay="on"
                                        onChange={(_, value) =>
                                            toggleChange(SettingsKey.RECORDING_RANDOM_PROBABILITY, value)
                                        }
                                    />
                                </Box>

                                <Typography display="block" gutterBottom>
                                    {t('profile.settings.oneToOneSettings.meetRecordingTypeRandomCaption')}
                                </Typography>

                                <div className={classes.my10px}>
                                    <Typography className={classes.text}>
                                        {t('profile.settings.oneToOneSettings.qualityControlRecordingLayout')}
                                    </Typography>
                                    <RoundedSelect
                                        value={meetQualityControlRecordingLayout}
                                        style={templateSelectorStyle}
                                        fullWidth
                                        onChange={(e) =>
                                            handleValueChange(e, SettingsKey.MEET_QUALITY_CONTROL_RECORDING_LAYOUT)
                                        }
                                        iconStyle={{ fontSize: '13px', padding: '0 12px', color: 'darkslategrey' }}
                                    >
                                        <MenuItem value="host-only" key="host-only">
                                            {t('profile.settings.oneToOneSettings.hostOnly')}
                                        </MenuItem>
                                        <MenuItem value="active-speaker" key="active-speaker">
                                            {t('profile.settings.oneToOneSettings.activeSpeaker')}
                                        </MenuItem>
                                        <MenuItem value="all-participants" key="all-participants">
                                            {t('profile.settings.oneToOneSettings.layoutAllParticipants')}
                                        </MenuItem>
                                    </RoundedSelect>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {
                    <div style={{ paddingBottom: '20px', marginTop: '20px' }}>
                        <Typography className={classes.text}>
                            {t('profile.settings.seoSettings.setBaseWidgetUrl')}
                        </Typography>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: '60%', marginTop: '10px' }}
                        >
                            <Box style={{ width: '100%' }}>
                                <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                                    <FormControl>
                                        <TextField
                                            style={{ width: '100%' }}
                                            color={validBaseUrl() ? 'primary' : 'error'}
                                            size="small"
                                            label={t('profile.settings.seoSettings.baseUrl')}
                                            variant="outlined"
                                            value={widgetBaseUrl}
                                            onChange={(e) => handleValueChange(e, SettingsKey.CALL_DOMAIN_BASE_URL)}
                                        />
                                        <FormHelperText error={!validBaseUrl()}>
                                            {!validBaseUrl() && t('profile.settings.urlValidationError')}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                }

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('Options (JSON)')}</FormLabel>
                    <FormControl className={classes.formControl}>
                        <JsonCodeInputMonacoEditor
                            name="icall_options"
                            value={icallOptions}
                            onChange={(value, errors) =>
                                handleValueChangeCode(value, SettingsKey.ICALL_OPTIONS, errors)
                            }
                        />
                        <SimpleCodeErrorView
                            id={SettingsKey.ICALL_OPTIONS}
                            mainErrorMessage={t('JsonError')}
                            errorList={inputError[SettingsKey.ICALL_OPTIONS]}
                        />
                    </FormControl>
                </FormGroup>

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.paramsCharacter')}
                    onChange={handleCheckChange}
                    name={SettingsKey.PARAMS_CHARACTER}
                    keySettings={SettingsKey.PARAMS_CHARACTER}
                    value={paramsCharacter}
                    paddingBottom
                />

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.settings.oneToOneSettings.icallNotifications')}</FormLabel>
                    <ICallNotificationsSettings
                        notifications={icallNotifications}
                        onChange={handleChangeNotification}
                    />
                </FormGroup>

                <div style={{ paddingBottom: '20px', marginTop: '20px' }}>
                    <ChipsInput
                        name={'communication-outcome'}
                        label={t('communication.outcome.values')}
                        value={stateCommunicationOutcome}
                        placeholder={t('common.input.addTagValue')}
                        onChange={(value) =>
                            onTagChange(
                                value,
                                setStateCommunicationOutcome,
                                stateCommunicationOutcome,
                                SettingsKey.COMMUNICATION_OUTCOME,
                            )
                        }
                        onBlur={onBlurCommunicationOutcome}
                    />
                </div>

                <ItemSettings
                    label={t('profile.settings.oneToOneSettings.communicationLogs')}
                    onChange={handleCheckChange}
                    name={SettingsKey.COMMUNICATION_LOGS_ENABLED}
                    keySettings={SettingsKey.COMMUNICATION_LOGS_ENABLED}
                    value={communicationLogsEnabled}
                    paddingBottom
                />

                <ItemSettings
                    label={t('settings.oneToOne.enableHostIcallEmailReminder')}
                    onChange={handleCheckChange}
                    name={'enableHostIcallEmailReminder'}
                    keySettings={SettingsKey.ENABLE_HOST_ICALL_EMAIL_REMINDER}
                    value={enableHostIcallEmailReminder}
                />

                {!iCalendar ? <ReminderForm emailReminders={icallEmailReminders} toggleChange={toggleChange} /> : null}

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.settings.oneToOneSettings.icallTranslations')}</FormLabel>
                    <FormControl className={classes.formControl}>
                        <JsonCodeInputMonacoEditor
                            name="icall_translations"
                            value={icallTranslations}
                            onChange={(value, errors) =>
                                handleValueChangeCode(value, SettingsKey.ICALL_TRANSLATIONS, errors)
                            }
                        />
                        <SimpleCodeErrorView
                            id={SettingsKey.ICALL_TRANSLATIONS}
                            mainErrorMessage={t('JsonError')}
                            errorList={inputError[SettingsKey.ICALL_TRANSLATIONS]}
                        />
                    </FormControl>
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <FormLabel>{t('profile.settings.oneToOneSettings.icallSchema')}</FormLabel>
                    <FormControl className={classes.formControl}>
                        <JsonCodeInputMonacoEditor
                            name="icall_schema"
                            value={icallSchema}
                            onChange={(value, errors) => handleValueChangeCode(value, SettingsKey.ICALL_SCHEMA, errors)}
                        />
                        <SimpleCodeErrorView
                            id={SettingsKey.ICALL_SCHEMA}
                            mainErrorMessage={t('JsonError')}
                            errorList={inputError[SettingsKey.ICALL_SCHEMA]}
                        />
                    </FormControl>
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );
};

export default OneToOneSettings;
