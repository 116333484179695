import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { addShoppable, setNewShoppable } from './shoppableSlice';

export const shortVideoSlice = createSlice({
    name: 'shortVideo',
    initialState: {
        pending: false,
        videoDevices: [],
        audioDevices: [],
        videoDeviceId: null,
        audioDeviceId: null,
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.error = null;
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setDevices: (state, action) => {
            state.videoDevices = action.payload.filter(({ kind }) => kind === 'videoinput');
            state.audioDevices = action.payload.filter(({ kind }) => kind === 'audioinput');
            if (!state.videoDeviceId) {
                state.videoDeviceId = state.videoDevices[0]?.deviceId;
            }
            if (!state.audioDeviceId) {
                state.audioDeviceId = state.audioDevices[0]?.deviceId;
            }
        },
        setVideoDevices: (state, action) => {
            state.videoDevices = action.payload;
        },
        setAudioDevices: (state, action) => {
            state.audioDevices = action.payload;
        },
        setVideoDeviceId: (state, action) => {
            state.videoDeviceId = action.payload;
        },
        setAudioDeviceId: (state, action) => {
            state.audioDeviceId = action.payload;
        },
        setPending: (state, action) => {
            state.pending = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const {
    start,
    stop,
    setError,
    setDevices,
    setVideoDevices,
    setAudioDevices,
    setVideoDeviceId,
    setAudioDeviceId,
} = shortVideoSlice.actions;

export const getShortVideoCreatePending = (state) => state.shortVideo.pending;
export const getVideoDevices = (state) => state.shortVideo.videoDevices;
export const getAudioDevices = (state) => state.shortVideo.audioDevices;
export const getVideoDeviceId = (state) => state.shortVideo.videoDeviceId;
export const getAudioDeviceId = (state) => state.shortVideo.audioDeviceId;

export const addShortVideo = (dto, success) => async (dispatch) => {
    try {
        dispatch(start());
        api.post('multimedia/make-video', dto)
            .then((response) => {
                if (success) {
                    success();
                }
                dispatch(setNewShoppable(response.data.shoppable));
                dispatch(addShoppable(response.data.shoppable));
                dispatch(stop());
                return response;
            })
            .catch((error) => {
                dispatch(stop());
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export default shortVideoSlice.reducer;
