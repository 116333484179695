import CloseIcon from '@mui/icons-material/Close';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { Button } from '@mui/material';
import { NotificationService } from '@onlive.site/front-core';
import api from 'api';
import SettingsKey from 'app/profile/settings/utils/settingsTypes';
import clsx from 'clsx';
import TrackingTypes from 'common/enums/trackingTypes';
import { useSettingValue } from 'common/hooks/useSettingValue';
import { useTrackingCallCenter } from 'common/tracking/useTrackingCallCenter';
import { getFlagByCountryCode } from 'common/utils/countryList';
import { getColor, isValidHttpUrl } from 'common/utils/utils';
import 'firebase/compat/database';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/authSlice';
import { finalizeEventAction } from 'redux/slices/eventSlice';
import { getOrganization } from 'redux/slices/organizationSlice';
import { getSessionRequest } from 'redux/slices/realtimeSlice';
import { getCmsFingerPrint, getCurrentOne2OneEvent } from 'redux/slices/userSlice';
import ConfirmDialog from './ConfirmDialog';
import useStylesQueueManager from './styles/poolManager.styles';
TimeAgo.addLocale(en);

const QueueManager = (props) => {
    function useStateCallback(initialState) {
        const [state, setState] = useState(initialState);
        const cbRef = useRef(null);

        const setStateCallback = useCallback((state, cb) => {
            cbRef.current = cb;
            setState(state);
        }, []);

        useEffect(() => {
            if (cbRef.current) {
                cbRef.current(state);
                cbRef.current = null;
            }
        }, [state]);

        return [state, setStateCallback];
    }

    const { t } = useTranslation();
    const user = useSelector(getUser);
    const { ...agent } = { ...user, id: user.userId, role: 'personal_shopper' };
    const { track } = useTrackingCallCenter();
    const [organizationQueue, setOrganizationQueue] = useStateCallback([]);
    const [personalQueue, setPersonalQueue] = useStateCallback([]);
    const { classes, cx } = useStylesQueueManager();
    const [openList, setOpenList] = useState(false);
    const { realtimeQueues } = props;
    const [queueSubscribe, setQueueSubscribe] = useState(false);
    const timeAgo = new TimeAgo('en-US');
    const session = useSelector(getSessionRequest);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const cmsFingerPrint = useSelector(getCmsFingerPrint);
    const organization = useSelector(getOrganization);
    const currentEventOne2One = useSelector(getCurrentOne2OneEvent);
    const [sessionToAccept, setSessionToAccept] = useState(null);
    const dispatch = useDispatch();
    const icallOptions = useSettingValue(SettingsKey.ICALL_OPTIONS);
    const icallNotifications = useSettingValue(SettingsKey.ICALL_NOTIFICATIONS);
    const queues = useMemo(() => [...personalQueue, ...organizationQueue], [personalQueue, organizationQueue]);
    const enableIncomingBrowserSoundNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ICALL_QUEUE_INCOMING]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION];
    }, [icallNotifications]);
    const enableIncomingSystemTrayNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ICALL_QUEUE_INCOMING]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION];
    }, [icallNotifications]);
    const enableIncomingBrowserPushNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ICALL_QUEUE_INCOMING]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION];
    }, [icallNotifications]);
    const enableOutgoingBrowserSoundNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ICALL_QUEUE_OUTGOING]?.[SettingsKey.ENABLE_BROWSER_SOUND_NOTIFICATION];
    }, [icallNotifications]);
    const enableOutgoingSystemTrayNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ICALL_QUEUE_OUTGOING]?.[SettingsKey.ENABLE_SYSTEM_TRAY_NOTIFICATION];
    }, [icallNotifications]);
    const enableOutgoingBrowserPushNotification = useMemo(() => {
        return icallNotifications?.[SettingsKey.ICALL_QUEUE_OUTGOING]?.[SettingsKey.ENABLE_BROWSER_PUSH_NOTIFICATION];
    }, [icallNotifications]);

    const ringIncomingAudio = useRef(null);
    const ringOutgoingAudio = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const titleEntryQueue = useMemo(() => `${t('profile.settings.oneToOneSettings.icallqueueincoming')}`, [t]);
    const titleOutputQueue = useMemo(() => `${t('profile.settings.oneToOneSettings.icallqueueoutgoing')}`, [t]);

    const pickUp = () => {
        if (currentEventOne2One) setShowConfirmDialog(true);
        else pickUpNext();
    };
    const pickUpNext = () => {
        if (personalQueue[0]) acceptPersonalSession(personalQueue[0]);
        else if (organizationQueue[0]) acceptSessionFromOrganizationQueue(organizationQueue[0]);
    };

    const closeEventAndPickUp = () => {
        setShowConfirmDialog(false);
        getEventActiveAndFinalize();
    };

    const getEventActiveAndFinalize = () => {
        if (currentEventOne2One) {
            const roomId = currentEventOne2One?.url;
            api.get(`${process.env.REACT_APP_API_URL}/events/room/${roomId}`)
                .then((response) => {
                    response.data?.id && response.data?.status !== 'finalized'
                        ? dispatch(finalizeEventAction(response.data?.id, () => confirmAction()))
                        : confirmAction();
                })
                .catch((error) => {
                    console.error('Error get event active', error);
                });
        }
    };

    const confirmAction = () => {
        if (sessionToAccept) {
            acceptSession(sessionToAccept);
            setSessionToAccept(null);
        } else {
            pickUpNext();
        }
    };

    const accept = (session) => {
        if (currentEventOne2One) {
            setShowConfirmDialog(true);
            setSessionToAccept(session);
        } else {
            acceptSession(session);
        }
    };

    const acceptSession = (session) => {
        if (session?.userId) acceptPersonalSession(session);
        else acceptSessionFromOrganizationQueue(session);
    };

    const acceptPersonalSession = (session) => {
        realtimeQueues.setAttendedPersonal(session.inviteeUserId, agent.id).then(() => {
            const payload = {
                eventDto: {
                    name: `${t('common.session.request.instant')} ${organization.name}`,
                    description: '',
                    releaseDate: new Date(),
                    assets: [],
                    adminUserId: user?.userId,
                    type: 'one_to_one',
                    instant: true,
                    sessionRequestId: session?.id,
                    organizationId: agent?.organizationId,
                    streamConnectors: [],
                    interactionId: session?.onliveInteractionId,
                    cmsFingerPrint,
                    isTemplate: true,
                    inviteeName: session?.inviteeName,
                    inviteeEmail: session?.inviteeEmail,
                    actors: [
                        {
                            role: 'host',
                            isAdmin: true,
                            email: user?.email,
                            userId: session?.userId,
                        },
                        {
                            role: 'guest',
                            email: session?.userInfo?.email,
                            name: session?.userInfo?.name,
                            userId: session?.inviteeUserId,
                            extraFields: session?.userInfo?.extraFields,
                        },
                    ],
                    callOrigin: session?.callOrigin,
                    extraFields: session?.extraFields,
                },
                type: 'personalQueue',
                requestSession: session,
            };
            window.dispatchEvent(new CustomEvent('finalize-event'));
            api.post(`${process.env.REACT_APP_API_URL}/events/accept-call`, payload)
                .then((response) => {
                    const event = response?.data;
                    const data = {
                        interactionId: session?.onliveInteractionId,
                        id: event?.id,
                        agentId: session?.userId,
                        actorRole: 'admin',
                        actorEmail: user?.email,
                        actorId: user?.userId,
                        eventId: event?.id,
                        eventType: 'one_to_one',
                        callOrigin: event?.callOrigin,
                        interactionType: 'instant',
                        fullChannelURL: session?.channelURL,
                    };
                    track({
                        type: TrackingTypes.PICKUP_FROM_QUEUE,
                        data: {
                            ...data,
                            invitedUserId: session?.inviteeUserId,
                            invitedName: session?.inviteeName,
                            invitedEmail: session?.inviteeEmail,
                            queue: 'personal',
                            timeInQueue: (Date.now() - session?.createdAt) / 1000,
                            requestDuration: Date.now() - session?.createdAt,
                        },
                    });
                })
                .catch((error) => {
                    console.debug('🚀 ~ acceptPersonalSession ~ error:', error);
                });
        });
    };

    const acceptSessionFromOrganizationQueue = (session) => {
        realtimeQueues.setAtendedBy(session.inviteeUserId, agent.id).then(() => {
            const payload = {
                eventDto: {
                    name: `${t('common.session.request.instant')} ${organization.name}`,
                    description: '',
                    releaseDate: new Date(),
                    assets: [],
                    adminUserId: agent?.id,
                    type: 'one_to_one',
                    instant: true,
                    streamConnectors: [],
                    organizationId: agent?.organizationId,
                    interactionId: session?.onliveInteractionId,
                    cmsFingerPrint,
                    isTemplate: true,
                    inviteeName: session?.inviteeName,
                    inviteeEmail: session?.inviteeEmail,
                    actors: [
                        {
                            role: 'host',
                            isAdmin: true,
                            email: agent?.email,
                            userId: agent?.id,
                        },
                        {
                            role: 'guest',
                            email: session?.userInfo?.email,
                            name: session?.userInfo?.name,
                            userId: session?.inviteeUserId,
                            extraFields: session?.userInfo?.extraFields,
                        },
                    ],
                    callOrigin: session?.callOrigin,
                    extraFields: session?.extraFields,
                },
                type: 'organizationQueue',
                requestSession: session,
            };
            window.dispatchEvent(new CustomEvent('finalize-event'));
            api.post(`${process.env.REACT_APP_API_URL}/events/accept-call`, payload)
                .then((response) => {
                    const event = response?.data;
                    const data = {
                        interactionId: session?.onliveInteractionId,
                        id: event?.id,
                        agentId: user?.userId,
                        actorRole: 'admin',
                        actorEmail: user?.email,
                        actorId: user?.userId,
                        eventId: event?.id,
                        eventType: 'one_to_one',
                        callOrigin: event?.callOrigin,
                        interactionType: 'instant',
                        fullChannelURL: session?.channelURL,
                    };
                    track({
                        type: TrackingTypes.PICKUP_FROM_QUEUE,
                        data: {
                            ...data,
                            invitedUserId: session?.inviteeUserId,
                            invitedName: session?.inviteeName,
                            invitedEmail: session?.inviteeEmail,
                            queue: 'organization',
                            timeInQueue: (Date.now() - session?.createdAt) / 1000,
                            requestDuration: Date.now() - session?.createdAt,
                        },
                    });
                })
                .catch((error) => {
                    console.debug('🚀 ~ realtimeQueues.setAtendedBy ~ error:', error);
                });
        });
    };

    const convertObjectQueueToArrayQueue = (objectQueue) => {
        const arrayQueue = Object.values(objectQueue || {}).sort((a, b) => a.callTime - b.callTime);
        return arrayQueue;
    };

    const dataIncomingSound = useCallback(() => {
        const url =
            icallNotifications?.[SettingsKey.ICALL_QUEUE_INCOMING]?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableIncomingBrowserSoundNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_ASSETS_SOUNDS_URL}/notification.mp3`;
    }, [icallNotifications, enableIncomingBrowserSoundNotification]);

    const dataOutgoingSound = useCallback(() => {
        const url =
            icallNotifications?.[SettingsKey.ICALL_QUEUE_OUTGOING]?.[SettingsKey.URL_BROWSER_SOUND_NOTIFICATION];
        if (enableOutgoingBrowserSoundNotification && isValidHttpUrl(url)) {
            return url;
        }
        return `${process.env.REACT_APP_ASSETS_SOUNDS_URL}/notification.mp3`;
    }, [icallNotifications, enableOutgoingBrowserSoundNotification]);

    const notify = useCallback(
        (
            title,
            body,
            redirectURL,
            enableBrowserSoundNotification,
            enableSystemTrayNotification,
            enableBrowserPushNotification,
            audioRef,
        ) => {
            const icon = `${process.env.REACT_APP_ASSETS_IMAGES_URL}/icon-128.png`;

            if (enableBrowserSoundNotification) {
                audioRef.play();
            }

            if (enableSystemTrayNotification) {
                const action = (key) => (
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => closeSnackbar(key)}
                        style={{ minWidth: '32px' }}
                    >
                        <CloseIcon />
                    </Button>
                );
                NotificationService.notify('vms_notification', { title, action }, (data) => {
                    enqueueSnackbar(data.title, {
                        variant: 'info',
                        persist: true,
                        action: data.action,
                    });
                });
            }

            if (enableBrowserPushNotification) {
                if (!('Notification' in window)) {
                    console.log('This browser does not support desktop notification');
                } else {
                    if (Notification.permission === 'granted') {
                        const notification = NotificationService.notify('push_notification', { title, body, icon });
                        notification.onclick = () => {
                            window.location.replace(redirectURL).focus();
                        };
                    } else {
                        Notification.requestPermission().then((permission) => {
                            if (permission === 'granted') {
                                const notification = NotificationService.notify('push_notification', {
                                    title,
                                    body,
                                    icon,
                                });
                                notification.onclick = () => {
                                    window.location.replace(redirectURL).focus();
                                };
                            }
                        });
                    }
                }
            }
        },
        [enqueueSnackbar, closeSnackbar],
    );

    const sendOutputNotification = useCallback(() => {
        notify(
            titleOutputQueue,
            '',
            `${window.location.origin.toString()}/one-to-one`,
            enableOutgoingBrowserSoundNotification,
            enableOutgoingSystemTrayNotification,
            enableOutgoingBrowserPushNotification,
            ringOutgoingAudio.current,
        );
    }, [
        notify,
        enableOutgoingBrowserSoundNotification,
        enableOutgoingSystemTrayNotification,
        enableOutgoingBrowserPushNotification,
        titleOutputQueue,
    ]);

    const sendEntryNotification = useCallback(() => {
        notify(
            titleEntryQueue,
            '',
            `${window.location.origin.toString()}/one-to-one`,
            enableIncomingBrowserSoundNotification,
            enableIncomingSystemTrayNotification,
            enableIncomingBrowserPushNotification,
            ringIncomingAudio.current,
        );
    }, [
        notify,
        enableIncomingBrowserSoundNotification,
        enableIncomingSystemTrayNotification,
        enableIncomingBrowserPushNotification,
        titleEntryQueue,
    ]);

    const updatePersonalQueue = useCallback(
        ({ detail }) => {
            if (detail) {
                let result = convertObjectQueueToArrayQueue(detail);
                result = result.filter((session) => !session?.attended);
                setPersonalQueue((prev) => {
                    if (prev.length > result.length) {
                        sendOutputNotification();
                    } else if (prev.length < result.length) {
                        sendEntryNotification();
                    }
                    return result;
                });
            } else {
                setPersonalQueue((prev) => {
                    if (prev.length) {
                        sendOutputNotification();
                    }
                    return [];
                });
            }
        },
        [setPersonalQueue, sendOutputNotification, sendEntryNotification],
    );

    const updateOrganizationQueue = useCallback(
        ({ detail }) => {
            if (detail) {
                let result = convertObjectQueueToArrayQueue(detail);
                result = result.filter((session) => !session?.attendedBy);
                setOrganizationQueue((prev) => {
                    if (prev.length > result.length) {
                        sendOutputNotification();
                    } else if (prev.length < result.length) {
                        sendEntryNotification();
                    }
                    return result;
                });
            } else {
                setOrganizationQueue((prev) => {
                    if (prev.length) {
                        sendOutputNotification();
                    }
                    return [];
                });
            }
        },
        [setOrganizationQueue, sendOutputNotification, sendEntryNotification],
    );

    useEffect(() => {
        ringIncomingAudio.current = NotificationService.notify('sound_notification', dataIncomingSound());
    }, [dataIncomingSound]);

    useEffect(() => {
        ringOutgoingAudio.current = NotificationService.notify('sound_notification', dataOutgoingSound());
    }, [dataOutgoingSound]);

    const renderFlag = (countryCode) => {
        const flag = icallOptions?.flagsList?.[countryCode] || getFlagByCountryCode(countryCode);
        return flag;
    };

    useEffect(() => {
        if (realtimeQueues && user?.userId && !queueSubscribe) {
            realtimeQueues.observePersonalQueue(user.userId, updatePersonalQueue);
            realtimeQueues.observeOrganizationQueue(updateOrganizationQueue);
            setQueueSubscribe(true);
        }
    }, [realtimeQueues, user?.userId, queueSubscribe, updateOrganizationQueue, updatePersonalQueue]);

    if (queues.length > 0 && !session)
        return (
            <>
                <ConfirmDialog
                    classes={classes}
                    open={showConfirmDialog}
                    title={t('confirmPickUpNextCallTitle')}
                    content={t('confirmPickUpNextCallText')}
                    onConfirm={() => closeEventAndPickUp()}
                    onCancel={() => setShowConfirmDialog(false)}
                />
                <div
                    className={cx(
                        classes.poolManager,
                        openList && classes.poolManagerOpen,
                        currentEventOne2One && classes.queueInEvent,
                    )}
                >
                    <div className={classes.poolContainer}>
                        <div className={classes.poolManagerHeader}>
                            <div className={clsx(classes.poolHeaderTitle, openList && classes.headerRow)}>
                                <p>WAITING ROOM</p>
                                <Button onClick={() => pickUp()}>PICK UP</Button>
                            </div>
                            {!openList && (
                                <div className={classes.queueIcon}>
                                    <GroupsOutlinedIcon />
                                    <div className={classes.poolQty}>{queues.length}</div>
                                </div>
                            )}

                            <Button className={classes.collapseContent} onClick={() => setOpenList(!openList)}>
                                {openList ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                        </div>
                        <div className={classes.poolManagerBody}>
                            <ul className={classes.sessionList}>
                                {queues.map((session, index) => (
                                    <li
                                        aria-hidden={true}
                                        key={session.inviteeUserId}
                                        className={classes.sessionItem}
                                        onClick={() => accept(session)}
                                    >
                                        <div className={classes.avatar} style={{ backgroundColor: getColor(index) }}>
                                            <span>
                                                {session?.userInfo?.name?.charAt(0).toUpperCase() +
                                                    session?.userInfo?.name?.charAt(1).toUpperCase()}
                                            </span>
                                            {session?.userInfo?.preferredLanguage && (
                                                <div className={classes.sessionFlag}>
                                                    {renderFlag(session?.userInfo?.preferredLanguage)}
                                                </div>
                                            )}
                                        </div>
                                        <div className={classes.sessionInfo}>
                                            <div className={classes.sessionName}>
                                                <p>{session?.userInfo?.name}</p>
                                            </div>
                                            {timeAgo && session?.callTime && (
                                                <div className={classes.sessionTime}>
                                                    <span>{`${timeAgo.format(session.callTime, 'mini-now')} ...`}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className={classes.SessionCallIcon}>
                                            <PhoneInTalkOutlinedIcon />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                ;
            </>
        );
};

export default QueueManager;
