import { createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

export const tvChannelSlice = createSlice({
    name: 'tvChannel',
    initialState: {
        tvChannels: [],
        pending: false,
        savePending: false,
        orgTvChannels: [],
        error: null,
    },
    reducers: {
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
        setTvChannels: (state, action) => {
            state.pending = false;
            state.tvChannels = action.payload;
        },
        addTvChannel: (state, action) => {
            state.pending = false;
            const index = state.tvChannels.findIndex((ch) => ch.id === action.payload.id);
            if (index === -1) {
                state.tvChannels = [...state.tvChannels, action.payload];
            } else {
                state.tvChannels = [
                    ...state.tvChannels.slice(0, index),
                    action.payload,
                    ...state.tvChannels.slice(index + 1),
                ];
            }
        },
        addOrgTvChannel: (state, action) => {
            state.pending = false;
            const index = state.orgTvChannels.findIndex((ch) => ch.id === action.payload.id);
            if (index === -1) {
                state.orgTvChannels = [...state.orgTvChannels, action.payload];
            } else {
                state.orgTvChannels = [
                    ...state.orgTvChannels.slice(0, index),
                    action.payload,
                    ...state.orgTvChannels.slice(index + 1),
                ];
            }
        },
        removeOrgTvChannel: (state, action) => {
            state.pending = false;
            const index = state.orgTvChannels.findIndex((ch) => ch.id === action.payload.id);
            if (index !== -1) {
                state.orgTvChannels = [...state.orgTvChannels.slice(0, index), ...state.orgTvChannels.slice(index + 1)];
            }
        },
        setOrgTvChannels: (state, action) => {
            state.pending = false;
            state.orgTvChannels = action.payload;
        },
        setError: (state, action) => {
            state.pending = false;
            state.multimedia = [];
            state.error = action.payload;
        },
        startSave: (state, action) => {
            state.savePending = true;
        },
        stopSave: (state, action) => {
            state.savePending = false;
        },
    },
});

export const {
    start,
    stop,
    setTvChannels,
    addTvChannel,
    setError,
    startSave,
    stopSave,
    setOrgTvChannels,
    addOrgTvChannel,
    removeOrgTvChannel,
} = tvChannelSlice.actions;

export const getTvChannels = (state) => state.tvChannel.tvChannels;
export const getTvChannelsError = (state) => state.tvChannel.error;
export const getTvChannelsPending = (state) => state.tvChannel.pending;
export const getKeyPending = (state) => state.tvChannel.keyPending;
export const getOrgTvChannels = (state) => state.tvChannel.orgTvChannels;

export const fetchTvChannelsAction = (queryParams) => async (dispatch) => {
    try {
        let query = transformFiltersToQuery(queryParams);
        dispatch(start());
        api.get('channels' + query)
            .then((response) => {
                dispatch(setTvChannels(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const fetchOrgTvChannelsAction = () => async (dispatch, getState) => {
    try {
        const state = getState();
        dispatch(start());
        api.get(`channels/organization/${state.organization.organization.id}`)
            .then((response) => {
                dispatch(setOrgTvChannels(response.data));
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    } catch (e) {
        dispatch(stop());
        return console.error('error', e);
    }
};

export const setChannelOrganizationAction = (channelId, active, success, failure) => async (dispatch, getState) => {
    try {
        const state = getState();
        dispatch(startSave());
        api.put(`channels/${channelId}/organization/${state.organization.organization.id}`, { active })
            .then((response) => {
                if (active) {
                    dispatch(addOrgTvChannel(response.data));
                } else {
                    dispatch(removeOrgTvChannel(response.data));
                }
                if (success) {
                    success();
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        dispatch(stopSave());
        return console.error('error', e);
    }
};

export const getChannelKeyAction = (channelId, success, failure) => async (dispatch) => {
    try {
        api.get(`channels/api-key/${channelId}`)
            .then((response) => {
                if (success) {
                    success(response.data);
                }
                return response;
            })
            .catch((error) => {
                dispatch(setError(error));
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        return console.error('error', e);
    }
};

export const setTvChannelAction =
    ({ channelId, channelDto, success, failure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put(`channels/${channelId}`, channelDto)
                .then((response) => {
                    dispatch(addTvChannel(response.data));
                    if (success) {
                        success();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setError(error));
                    if (failure) {
                        failure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export default tvChannelSlice.reducer;
