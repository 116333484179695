import { FormGroup, FormLabel } from '@mui/material';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useTranslation } from 'react-i18next';

const CloudstoreForm = (props) => {
    const { fields, handleFieldChange } = props;
    const { classes } = useStylesForm();
    const { t } = useTranslation();

    return (
        <>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.cloudstoreForm.storeId')}</FormLabel>
                <CustomTextValidator
                    name="storeId"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-storeId"
                    value={fields.storeId}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.cloudstoreForm.storeIdRequired')]}
                    disabled
                />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.cloudstoreForm.accessToken')}</FormLabel>
                <CustomTextValidator
                    name="accessToken"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-accessToken"
                    value={fields.accessToken}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.cloudstoreForm.accessTokenRequired')]}
                    disabled={true}
                />
            </FormGroup>
        </>
    );
};

export default CloudstoreForm;
