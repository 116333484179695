import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStylesOCalendar from '../../../ocalendar/styles/useStylesOCalendar.styles';
import DateOverride from './DateOverride';
import ListDateOverride from './ListDateOverride';
import WeeklyHours from './WeeklyHours';

const Schedule = (props) => {
    const { template, onChange, timeZone } = props;

    const { classes } = useStylesOCalendar();
    const { t } = useTranslation();
    const [weeklyDays, setWeeklyDays] = useState([]);
    const [customDates, setCustomDates] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [pendingChanges, setPendingChanges] = useState(false);


    const handleCheckDayChange = (index) => {
        let tempDays = [...weeklyDays];
        tempDays[index].hours = [];
        if (tempDays[index].enable) {
            tempDays[index].enable = false;
        } else {
            tempDays[index].enable = true;
            tempDays[index].hours.push({
                startTime: moment.tz({ hour: 9 }, timeZone).format('HH:mm'),
                endTime: moment.tz({ hour: 18 }, timeZone).format('HH:mm'),
            });
        }
        setPendingChanges(true);
    };

    const handleHourRangeChange = (_index, indexHour, hoursRange) => {
        let tempDays = [...weeklyDays];
        const index = tempDays.findIndex((d) => d.index === _index);
        tempDays[index].hours.splice(indexHour, 1, hoursRange);
        setWeeklyDays([...tempDays]);
        setPendingChanges(true);
    };

    const handleAddHourRange = (_index) => {
        let tempDays = [...weeklyDays];
        const index = tempDays.findIndex((d) => d.index === _index);
        tempDays[index].hours.push({
            startTime: moment.tz({ hour: 9 }, timeZone).format('HH:mm'),
            endTime: moment.tz({ hour: 18 }, timeZone).format('HH:mm'),
        });
        setWeeklyDays([...tempDays]);
        setPendingChanges(true);
    };

    const handleDeleteHourRange = (_index, indexHour) => {
        let tempDays = [...weeklyDays];
        const index = tempDays.findIndex((d) => d.index === _index);
        tempDays[index].hours.splice(indexHour, 1);
        if (tempDays[index].hours.length === 0) tempDays[index].enable = false;
        setWeeklyDays([...tempDays]);
        setPendingChanges(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleAddDateOverride = (dateOverride) => {
        setCustomDates([...customDates, dateOverride]);
        setOpenDialog(false);
        setPendingChanges(true);
    };

    const handleDeleteDateOverride = (index) => {
        let tempDates = [...customDates];
        tempDates = [
            ...tempDates.slice(0, index),
            ...tempDates.slice(index + 1)
        ];
        setCustomDates([...tempDates]);
        setPendingChanges(true);
    };

    useEffect(() => {
        if (pendingChanges) {
            onChange({
                workingHours: weeklyDays,
                dateOverrides: customDates,
            });
            setPendingChanges(false);
        }
    }, [
        pendingChanges,
        template,
        weeklyDays,
        customDates,
        onChange,
    ]);

    useEffect(() => {
        setPendingChanges(false);
        setWeeklyDays(template.workingHours);
        setCustomDates(template.dateOverrides);
    }, [template]);

    return (
        <Fragment>
            <Grid item md={12}>
                <Typography>{t('calendar.setWeeklyHours')}</Typography>
                <WeeklyHours
                    classes={classes}
                    days={weeklyDays}
                    onChange={handleCheckDayChange}
                    onHourRangeChange={handleHourRangeChange}
                    onAddHourRange={handleAddHourRange}
                    onDeleteHourRange={handleDeleteHourRange}
                    timeZone={timeZone}
                />
            </Grid>
            <Grid item md={12} className={classes.dateOverrideGridContainer}>
                <div className={classes.titleAction}>
                    <Typography className={classes.dateOverrideTitle}>
                        {t('calendar.addDateOverrides')}
                    </Typography>
                    <Button
                        variant="text"
                        color="primary"
                        className={classes.dateOverrideBtn}
                        onClick={() => setOpenDialog(true)}
                        startIcon={<Add />}
                    >
                        {t('calendar.common.add')}
                    </Button>
                </div>
                <ListDateOverride
                    datesOverride={customDates}
                    onDeleteDate={handleDeleteDateOverride}
                    timeZone={timeZone}
                />
            </Grid>
            <DateOverride
                openDialog={openDialog}
                onCloseDialog={handleCloseDialog}
                onAddDateOverride={handleAddDateOverride}
                timeZone={timeZone}
            />
        </Fragment>
    );
};

export default Schedule;
