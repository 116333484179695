import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'productDiscount';

export const productDiscountSlice = createSlice({
    name: resource,
    initialState: {
        pendingProductDiscounts: false,
        productDiscount: null,
        productDiscounts: [],
        count: 0,
        total: 0,
        currentPage: 1,
        totalPages: 1,
        errorFetchProductDiscounts: null,
        pendingDelete: false,
        savePending: false,
        saveError: null,
    },
    reducers: {
        setProductDiscounts: (state, action) => {
            state.productDiscounts = action.payload.items;
            state.pendingProductDiscounts = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        setErrorFetchProductDiscounts: (state, action) => {
            state.pendingProductDiscounts = false;
            state.errorFetchProductDiscounts = action.payload;
        },
        startFetchProductDiscounts: (state, action) => {
            state.pendingProductDiscounts = true;
        },
        stopFetchProductDiscounts: (state, action) => {
            state.pendingProductDiscounts = false;
        },
        deleteProductDiscount: (state, action) => {
            state.pendingDelete = false;
            const index = state.productDiscounts.findIndex(
                (productDiscount) => productDiscount.id === action.payload.id,
            );
            if (index !== -1) {
                state.productDiscounts = [
                    ...state.productDiscounts.slice(0, index),
                    ...state.productDiscounts.slice(index + 1),
                ];
            }
        },
        startDelete: (state, action) => {
            state.pendingDelete = true;
        },
        stopDelete: (state, action) => {
            state.pendingDelete = false;
        },
        addProductDiscount: (state, action) => {
            state.savePending = false;
            state.productDiscounts = [...state.productDiscounts, action.payload];
        },
        editProductDiscount: (state, action) => {
            state.savePending = false;
            const index = state.productDiscounts.findIndex(
                (productDiscount) => productDiscount.id === action.payload.id,
            );
            if (index !== -1) {
                state.productDiscounts = [
                    ...state.productDiscounts.slice(0, index),
                    action.payload,
                    ...state.productDiscounts.slice(index + 1),
                ];
            }
        },
        startSave: (state, action) => {
            state.savePending = true;
            state.saveError = null;
        },
        stopSave: (state, action) => {
            state.savePending = true;
        },
        setSaveError: (state, action) => {
            state.savePending = false;
            state.saveError = action.payload;
        },
    },
});

export const {
    setProductDiscounts,
    setErrorFetchProductDiscounts,
    startFetchProductDiscounts,
    stopFetchProductDiscounts,
    deleteProductDiscount,
    startDelete,
    stopDelete,
    addProductDiscount,
    startSave,
    setSaveError,
    stopSave,
    editProductDiscount,
} = productDiscountSlice.actions;

export const getProductDiscounts = (state) => state[resource].productDiscounts;
export const getProductDiscountsTotal = (state) => state[resource].total;
export const getPendingProductDiscounts = (state) => state[resource].pendingProductDiscounts;
export const getTotalProductDiscounts = (state) => state[resource].count;
export const getProductDiscountSavePending = (state) => state[resource].savePending;

export const fetchProductsDiscountsAction =
    ({ queryParams = {}, onSuccess, onFailure } = {}) =>
    async (dispatch) => {
        try {
            const params = {
                ...queryParams,
            };
            let query = transformFiltersToQuery(params);
            dispatch(startFetchProductDiscounts());
            api.get(`products-discounts${query}`)
                .then((response) => {
                    dispatch(setProductDiscounts(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    if (onFailure) {
                        onFailure(error);
                    }
                    dispatch(setErrorFetchProductDiscounts(error));
                });
        } catch (error) {
            dispatch(stopFetchProductDiscounts());
            return console.error(error.message);
        }
    };

export const addProductDiscountAction =
    ({ data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.post('products-discounts', data)
                .then((response) => {
                    dispatch(addProductDiscount(response.data));
                    if (onSuccess) {
                        onSuccess();
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            return console.error('error', e);
        }
    };

export const editProductDiscountAction =
    ({ id, data, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startSave());
            api.put('products-discounts/' + id, data)
                .then((response) => {
                    dispatch(editProductDiscount(response.data));
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    dispatch(setSaveError(error));
                    if (onFailure) {
                        onFailure(error);
                    }
                });
        } catch (e) {
            dispatch(stopSave());
            if (onFailure) {
                onFailure(e);
            }
            return console.error('error', e);
        }
    };

export const deleteProductDiscountAction =
    ({ productDiscountId, onSuccess, onFailure }) =>
    async (dispatch) => {
        try {
            dispatch(startDelete(productDiscountId));
            api.delete('products-discounts/' + productDiscountId)
                .then((response) => {
                    dispatch(deleteProductDiscount(response.data));
                    return response;
                })
                .catch((error) => {
                    onFailure(error);
                    dispatch(stopDelete());
                });
        } catch (e) {
            dispatch(stopDelete());
            return console.error('error', e);
        }
    };

export default productDiscountSlice.reducer;
