import { BffApi } from 'api';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSessionChecked, loginCheck, userHasAuthenticated, userIsAuthenticating } from 'redux/slices/authSlice';
import { from } from 'rxjs';
import OAuthTransition from 'views/OAuthTransition';

const RedirectServiceLogin = () => {
    const { t } = useTranslation();
    const sessionChecked = useSelector(getSessionChecked);
    const isAuthenticated = useSelector(userHasAuthenticated);
    const isAuthenticating = useSelector(userIsAuthenticating);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!sessionChecked && !isAuthenticating && !Boolean(searchParams.get('forceLogout'))) {
            dispatch(loginCheck(window.location.href));
        }

        if (sessionChecked && !isAuthenticating && isAuthenticated) {
            navigate('/loading');
        }

        let subscription;
        if (Boolean(searchParams.get('forceLogout')) || (sessionChecked && !isAuthenticating && !isAuthenticated)) {
            const roomId = window.location.hash.split('/')[2];
            roomId && localStorage.setItem('onlive-roomId-loaded', roomId);
            subscription = from(
                BffApi.post('oauth/auth-url', {
                    redirectUri: `${window.location.origin.toString()}/authorize/callback`,
                }),
            ).subscribe(({ data }) => {
                window.location.href = data.authorizationUrl;
            });
        }

        if (subscription) {
            return () => subscription.unsubscribe();
        }
    }, [sessionChecked, isAuthenticated, isAuthenticating, dispatch, navigate, searchParams]);

    return <OAuthTransition message={Boolean(searchParams.get('forceLogout')) ? t('login.goodBye') : null} />;
};

export default RedirectServiceLogin;
