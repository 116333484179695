import { FormGroup, FormHelperText, FormLabel, Stack, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useFlexBoxStyles from 'common/flexBox';
import LayoutForm from 'common/layouts/LayoutForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { closeNestedDialogAction } from 'redux/slices/nestedDialogSlice';
import 'suneditor/dist/css/suneditor.min.css';

const AuthenticatorFormConfirm = ({ pending, confirmMethod }) => {
    const { t } = useTranslation();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const [code, setCode] = useState(null);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const close = (event) => {
        dispatch(closeNestedDialogAction());
    };

    const onConfirmMethod = () => {
        confirmMethod({
            code,
            onError: () => {
                setError(true);
            },
        });
    };

    return (
        <LayoutForm title={t('security.authenticator.confirm.title')}>
            <ValidatorForm
                onSubmit={onConfirmMethod}
                onError={() => {}}
                className={cx(flexBox.flex, flexBox.gap2, flexBox.column, flexBox.overflowHidden)}
            >
                <Stack direction="column" spacing={2} className={cx(flexBox.overflowXHidden, flexBox.scrollbarNone)}>
                    <Typography variant="subtitle1" fontWeight={400}>
                        {t('profile.security.authenticatorConfirmedWarning')}
                    </Typography>

                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        className={flexBox.paddingTop4}
                    >
                        <FormGroup>
                            <FormLabel>{t('security.authenticator.code')}</FormLabel>
                            <CustomTextValidator
                                name="name"
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                    setError(false);
                                }}
                                validators={['required']}
                                errorMessages={[t('app.common.required')]}
                            />
                            {error ? (
                                <FormHelperText className={flexBox.paddingLeft2} error>
                                    {t('security.authenticator.codeError')}
                                </FormHelperText>
                            ) : null}
                        </FormGroup>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} justifyContent="flex-end" className={flexBox.paddingTop4}>
                    <ActionButton disabled={false} onClick={close} color="default" fitWidth>
                        {t('common.customPopover.closeIcon')}
                    </ActionButton>
                    <ActionButton disabled={pending} spinner={pending} type="submit" color="primary" fitWidth>
                        {t('form.accept')}
                    </ActionButton>
                </Stack>
            </ValidatorForm>
        </LayoutForm>
    );
};

export default AuthenticatorFormConfirm;
