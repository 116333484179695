import { FormGroup, FormLabel } from '@mui/material';
import CustomTextValidator from 'common/components/CustomTextValidators';
import useStylesForm from 'common/components/styles/useStylesForm.styles';
import { useTranslation } from 'react-i18next';

const ShopifyPrivateForm = (props) => {
    const { fields, handleFieldChange } = props;
    const { t } = useTranslation();
    const { classes } = useStylesForm();
    return (
        <>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.shopifyPrivateForm.accessToken')}</FormLabel>
                <CustomTextValidator
                    name="accessToken"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-accessToken"
                    value={fields.accessToken}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.shopifyPrivateForm.accessTokenRequired')]}
                />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <FormLabel>{t('profile.ecommerceIntegration.shopifyPrivateForm.shopDomain')}</FormLabel>
                <CustomTextValidator
                    name="shopUri"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    autoComplete="new-shopUri"
                    value={fields.shopUri}
                    onChange={handleFieldChange}
                    validators={['required']}
                    errorMessages={[t('profile.ecommerceIntegration.shopifyPrivateForm.shopDomainRequired')]}
                />
            </FormGroup>
        </>
    );
};

export default ShopifyPrivateForm;
