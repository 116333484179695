import BaseCodeInputMonacoEditor from './BaseCodeInputMonacoEditor';

const HandlebarsCodeInputMonacoEditor = ({ value, onChange, height, width, readOnly }) => (
    <div style={{ margin: '0 0 10px 0' }}>
        <div>
            <BaseCodeInputMonacoEditor
                height={height}
                width={width}
                defaultLanguage="handlebars"
                defaultValue={value}
                value={value}
                onChange={(content, errors) => onChange(content, errors)}
                readOnly={readOnly}
            />
        </div>
    </div>
);

export default HandlebarsCodeInputMonacoEditor;
