import { LinearProgress, Pagination, Stack } from '@mui/material';
import useColorsStyles from 'common/colors';
import useFlexBoxStyles from 'common/flexBox';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        body: {
            padding: theme.spacing(1, 7),
        },
        mb5: {
            marginBottom: theme.spacing(5),
        },
        filter: {
            minHeight: theme.spacing(6),
            padding: theme.spacing(0, 6),
            background: '#EFEFEF',
        },
    };
});

const LayoutView = (props) => {
    const { pagination = {}, pending = false, skipPageQueryUpdate, children } = props;
    const { totalPages, currentPage, handlePaginationChange } = pagination;
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = async (e, page) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        if (page) {
            if (!skipPageQueryUpdate) {
                updatedSearchParams.set('page', page);
                setSearchParams(updatedSearchParams.toString());
            }
            handlePaginationChange?.(e, page);
        } else if(!skipPageQueryUpdate) {
            updatedSearchParams.delete('page');
            setSearchParams(updatedSearchParams.toString());
        }
    };

    const { classes, cx } = useStyles();
    const { classes: flexBox } = useFlexBoxStyles();
    const { classes: colors } = useColorsStyles();

    return (
        <Stack direction="column" className={cx(flexBox.grow1, flexBox.overflowHidden, colors.bgSecondaryMain)}>
            {pending ? <LinearProgress style={{ width: '100%' }} /> : null}
            <Stack direction="column" className={cx(flexBox.grow1, flexBox.w100, flexBox.overflowXHidden)}>
                <Stack
                    direction="column"
                    className={cx(
                        classes.body,
                        flexBox.overflowHidden,
                        !pagination?.totalPages && classes.mb5,
                        flexBox.grow1,
                    )}
                >
                    {children}
                </Stack>
            </Stack>
            {pagination?.totalPages ? (
                <Stack
                    direction="row"
                    justifyContent="center"
                    className={cx(flexBox.w100, flexBox.marginBottom05, flexBox.marginTop05)}
                >
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChange}
                        className={classes.eventPagination}
                    />
                </Stack>
            ) : null}
        </Stack>
    );
};

export default LayoutView;
