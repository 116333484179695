import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            '& > div': {
                height: theme.spacing(4.5),
                borderRadius: theme.spacing(4.5),
                paddingLeft: theme.spacing(1),
                background: theme.palette.background.paper,
                width: '100%',
                fontSize: '12px',
                fontWeight: '500',
                position: 'relative',
                '& input': {
                    padding: theme.spacing(1, 0.5),
                },

                '&:focus': {
                    borderRadius: 5,
                },
                '& > fieldset': {
                    borderColor: '#E2E2E2',
                },
            },

            '&.big': {
                '& > div': {
                    height: theme.spacing(6),
                    borderRadius: theme.spacing(4.5),
                    fontSize: theme.spacing(2.25),
                    fontWeight: 400,
                },
            },

            '& > input': {
                display: 'none',
            },
        },
    };
});

const CustomTextField = (props) => {
    const { size, ...rest } = props;

    const { classes, cx } = useStyles();

    return <TextField classes={{ root: cx(classes.root, size) }} size={size} {...rest} />;
};

export default CustomTextField;
