import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { transformFiltersToQuery } from './utils/transformFilterToQuery';

const resource = 'preset';

export const presetSlice = createSlice({
    name: resource,
    initialState: {
        pending: false,
        presets: [],
        count: 0,
        currentPage: 1,
        totalPages: 1,
        total: 0,
    },
    reducers: {
        setPresets: (state, action) => {
            state.pending = false;
            state.count = action.payload.count;
            state.total = action.payload.total;
            state.presets = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        },
        start: (state, action) => {
            state.pending = true;
        },
        stop: (state, action) => {
            state.pending = false;
        },
    },
});

export const { setPresets, start, stop } = presetSlice.actions;

export const getPresets = (state) => state[resource].presets;
export const getPresetsPending = (state) => state[resource].pending;
export const getPresetsTotalPages = (state) => state[resource].totalPages;
export const getPresetsCurrentPage = (state) => state[resource].currentPage;
export const getPresetsTotal = (state) => state[resource].total;

export const fetchPresetsAction = (queryParams) => async (dispatch) => {
    try {
        const params = {
            ...queryParams,
        };
        let query = transformFiltersToQuery(params);
        dispatch(start());
        api.get('presets' + query)
            .then((response) => {
                dispatch(setPresets(response.data));
                return response;
            })
            .catch((error) => {});
    } catch (e) {
        dispatch(stop());
        return console.error(e.message);
    }
};

export const createPresetAction = (preset, success, failure) => async (dispatch) => {
    try {
        api.post('presets', preset)
            .then((response) => {
                if (success) {
                    success(response.data);
                }
            })
            .catch((error) => {
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export const updatePresetAction = (preset, success, failure) => async (dispatch) => {
    try {
        api.put('presets/' + preset.id, preset)
            .then((response) => {
                if (success) {
                    success(response.data);
                }
            })
            .catch((error) => {
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export const deletePresetAction = (presetId, success, failure) => async (dispatch) => {
    try {
        api.delete('presets/' + presetId)
            .then((response) => {
                if (success) {
                    success(response.data);
                }
            })
            .catch((error) => {
                if (failure) {
                    failure(error);
                }
            });
    } catch (e) {
        return console.error(e.message);
    }
};

export default presetSlice.reducer;
