import { createSlice } from '@reduxjs/toolkit';

export const reactionsSlice = createSlice({
    name: 'reactions',
    initialState: {
        reactions: { heart: 0, smile: 0, scissor: 0, sad: 0, angry: 0, like: 0, joy: 0, cupra: 0 },
    },
    reducers: {
        addReactions: (state, action) => {
            let reactions = state.reactions;
            reactions[action.payload]++;
            state.reactions = reactions;
        },
    },
});

export const { addReactions } = reactionsSlice.actions;

export const getReactions = (state) => state.reactions.reactions;

export default reactionsSlice.reducer;
