import { getAllCountries } from 'common/utils/countryList';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        countrySelect: {
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            textIndent: '1px',
            textOverflow: '',
            background: `url(${process.env.PUBLIC_URL + '/caret_down_icon.ico'}) no-repeat right`,
            padding: `0px ${theme.spacing(2)} 0px ${theme.spacing(1)}`,
            marginRight: theme.spacing(0.5),
            cursor: 'pointer',
            borderRadius: '24px',
            backgroundPositionX: '95px',
            backgroundSize: '13px',
            border: '1px solid #dedede',
            fontSize: '14px',
            '&::-ms-expand': {
                display: 'none',
            },

            height: theme.spacing(4.5),
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
            },
        },
        countrySelectError: {
            border: `1px solid ${theme.palette.error.main} !important`,
        },
    };
});

const PhoneCountrySelect = (props) => {
    const { value, name, onChange, error = false } = props;
    const { classes, cx } = useStyles();

    return (
        <select
            className={cx(classes.countrySelect, error ? classes.countrySelectError : '')}
            value={value}
            name={name}
            onChange={onChange}
        >
            {getAllCountries().map((country) => (
                <option value={country.code} key={`item-${country.code}`}>
                    {`${country.code} (${country.dial_code})`} {country.flag}
                </option>
            ))}
        </select>
    );
};

export default PhoneCountrySelect;
