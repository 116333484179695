import { makeStyles } from 'tss-react/mui';

const useStylesSettings = makeStyles()((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary.main,
            flexGrow: 1,
            overflow: 'hidden',
        },
        scroll: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflowX: 'hidden',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        body: {
            padding: theme.spacing(1, 7),
        },
        title: {
            fontSize: '16px',
            fontWeight: 'bold',
            borderBottom: '1px solid #e0e0e0',
            margin: '14px 0',
        },

        text: {
            fontSize: '12px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
            },
        },

        subTitle: {
            opacity: '0.3',
        },

        eventSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },

        accordion: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '& .MuiAccordionSummary-root': {
                // flexDirection: 'row-reverse',
                minHeight: 'auto',
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: '30px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0px',
            },
            '& .MuiAccordionSummary-root .MuiSvgIcon-root': {
                fontSize: theme.spacing(3),
                color: 'black',
                [theme.breakpoints.up('xl')]: {
                    fontSize: theme.spacing(3.75),
                },
            },
            '&::before': {
                borderBottom: '1px solid #E9E9E9',
            },
        },

        checkboxLabel: {
            '& .MuiFormControlLabel-label': {
                fontSize: '13px',
                fontWeight: '500',
                color: 'black',
            },
        },

        checkBox: {
            '& svg': {
                color: '#848484 !important',
                fontSize: '20px !important',
            },
        },

        calendarContent: {
            border: '1px solid #00000021',
            borderRadius: '8px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '10px',
            },
        },

        calendarBody: {
            display: 'flex',
            alignItems: 'center',
            width: '340px',
            padding: '13px 17px',
            [theme.breakpoints.up('xl')]: {
                width: '360px',
                padding: '15px 20px',
            },
        },

        image: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },

        calendly: {
            padding: '5px',
            '& img': {
                width: '40px',
                [theme.breakpoints.up('xl')]: {
                    fontSize: '43px',
                },
            },
        },

        gCalendar: {
            '& img': {
                width: '50px',
                [theme.breakpoints.up('xl')]: {
                    fontSize: '54px',
                },
            },
        },

        calendarInfo: {
            paddingLeft: '17px',
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '20px',
            },
        },

        pb10px: {
            paddingBottom: '10px',
            [theme.breakpoints.up('xl')]: {
                paddingBottom: '12px',
            },
        },

        imageSize: {
            width: '38px',
            height: '38px',
        },

        reactionRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },

        reactionContent: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            '& div': {
                display: 'flex!important',
            },
        },

        pb30px: {
            paddingBottom: '30px',
        },

        w30pct: {
            width: '30%',
        },

        m0px8px: {
            margin: '0px 8px',
        },

        m10px3px: {
            margin: '10px 3px',
        },

        mr10px: {
            marginRight: '10px',
        },

        dFlex: {
            display: 'flex',
        },

        pt20px: {
            paddingTop: '20px',
        },

        my10px: {
            margin: '10px 0',
        },

        my20px: {
            margin: '20px 0',
        },

        square: {
            border: '3px solid red',
            height: '55px',
            position: 'absolute',
            borderRadius: '5px',
        },

        languageSelect: {
            width: '180px',
            marginTop: theme.spacing(1.5),
            marginRight: theme.spacing(1.5),
        },

        emailsSenderName: {
            width: 360 + theme.spacing(1.5),
            marginTop: theme.spacing(1.5),
            marginRight: theme.spacing(1.5),
        },

        emailsTemplateSelect: {
            width: '240px',
            marginTop: theme.spacing(1.5),
        },

        emailsTemplates: {
            width: '100%',
            marginTop: theme.spacing(0.5),
        },

        codeMirrorCustomDialog: {
            '& .CodeMirror': {
                minHeight: '500px !important',
            },
            '& .CodeMirror-fullscreen': {
                zIndex: 999999,
            },
        },

        emailTemplateGrid: {
            marginTop: theme.spacing(1.5),
        },

        emailsTemplatesText: {
            fontSize: '12px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
            },
        },

        codeBox: {
            backgroundColor: '#eeeff0',
            fontSize: '12px',
            overflowX: 'auto',
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
            },
        },

        hookTextField: {
            width: '60%',
            // minWidth: '500px',
            marginBottom: theme.spacing(1.5),
        },

        hookUrlContainer: {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            padding: '6px',
            margin: '0',
            width: '60%',
        },

        w100pct: {
            width: '100% !important',
        },

        h100pct: {
            height: '100% !important',
        },

        mb0: {
            marginBottom: '0',
        },

        widgetActions: {
            display: 'flex',
        },
        mW80: {
            maxWidth: theme.spacing(80),
        },
        actionsContent: {
            padding: '10px 0px',
        },

        mr1: {
            marginRight: theme.spacing(1),
        },

        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },

        redColor: {
            backgroundColor: '#FF0000',
            color: '#FFFF',
            '&:hover': {
                backgroundColor: '#FF0000',
                color: '#FFFF',
                fontWeight: '600',
            },
        },

        seoWebUrlSelect: {
            width: '450px',
            marginTop: theme.spacing(1.5),
            marginRight: theme.spacing(1.5),
        },
        pb20px: {
            paddingBottom: '20px',
        },
        reminderItem: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            fontSize: '0.8rem',
            justifyContent: 'space-evenly',
        },
        eventFormInputSelectorContainer: {
            '& > :first-of-type': {
                paddingRight: '0px',
            },
        },
        eventInputSelector: {
            margin: '5px',
            backgroundColor: 'none',
            '& > :first-of-type': {
                height: '30px',
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
                width: 'auto',
                backgroundColor: theme.palette.secondary.light,
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.light,
                overflow: 'hidden',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.light,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.light,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: theme.palette.default.main,
            },
        },
        formButton: {
            textTransform: 'none',
            width: '100%',
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.fontSize,
        },
        mb20px: {
            marginBottom: '20px',
        },
    };
});

export default useStylesSettings;
