import io from 'socket.io-client';
import config from '../config';

const api = new URL(config.api.socketBaseURL);

let socket = io.connect(`${api.origin}/event`, {
    transports: ['websocket', 'polling'],
});

// let socket = io.connect(`${api.origin}/event`);

export default socket;
