import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomIconButton from 'common/components/CustomIconButton';
import CustomTooltip from 'common/components/CustomTooltip';
import useFlexBoxStyles from 'common/flexBox';
import useOnliveStyles from 'common/onliveStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        image: {
            width: theme.spacing(12),
            height: theme.spacing(9),
            background: '#000',
        },
    };
});

const ServiceCard = ({ service, deleteService }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: flexBox, cx } = useFlexBoxStyles();
    const { classes: onlive } = useOnliveStyles();

    return (
        <Stack
            direction="column"
            className={cx(
                flexBox.relative,
                onlive.borderSecondaryDark,
                flexBox.padding1,
                flexBox.borderRadius1,
                flexBox.overflowHidden,
            )}
        >
            <Stack className={cx(flexBox.absolute, flexBox.right1, flexBox.top1)}>
                <CustomTooltip arrow title={t('contact.remove')} placement={'bottom'}>
                    <CustomIconButton aria-label="delete" onClick={deleteService} size="small">
                        <DeleteOutlineOutlinedIcon />
                    </CustomIconButton>
                </CustomTooltip>
            </Stack>

            <Stack direction="column" spacing={1}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} useFlexGap>
                    <img alt="service" className={classes.image} src={service.image} />

                    <Stack direction="column" className={flexBox.paddingRight4}>
                        <Typography fontWeight={600} variant="onliveH6">
                            {service.name}
                        </Typography>
                        <Typography variant="body1" component="div">
                            {service.duration + ' ' + t('iCalendar.schedule.minutesPerEvents')}
                        </Typography>
                        <Typography variant="body1" component="div">
                            {service.timeBetween + ' ' + t('iCalendar.schedule.minutesBetweenEvents')}
                        </Typography>
                        {/* <Typography variant="body1" component="div">
                            {service.minTimeToSchedule + ' ' + t('iCalendar.schedule.minTimeToSchedule')}
                        </Typography>
                        <Typography variant="body1" component="div">
                            {service.maxTimeToSchedule + ' ' + t('iCalendar.schedule.maxTimeToSchedule')}
                        </Typography> */}
                    </Stack>
                </Stack>

                {service?.dateRanges ? (
                    <>
                        <Divider />

                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="subtitle2" fontWeight={500}>
                                {t('services.form.periodOfService')}
                            </Typography>
                            <Stack direction="column" spacing={0.5}>
                                {service.dateRanges.map((range, idx) => (
                                    <Stack key={range.id} direction="row" spacing={2}>
                                        <Typography fontWeight={400}>
                                            {moment(range.startDate).format('DD / MM / YYYY')}
                                        </Typography>
                                        <Typography fontWeight={400}>{t('services.form.dateTo')}</Typography>
                                        <Typography fontWeight={400}>
                                            {moment(range.endDate).format('DD / MM / YYYY')}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </>
                ) : null}
            </Stack>
        </Stack>
    );
};

export default ServiceCard;
