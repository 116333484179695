import { createSelector, createSlice } from '@reduxjs/toolkit';
import AudienceFields from 'app/dashboard/enums/audienceFields';
import EventFields from 'app/dashboard/enums/eventFields';
import Granularity from 'app/dashboard/enums/granularity';
import DateRangeEnum from 'common/enums/dateRange';
import moment from 'moment';

const initialState = {
    uniqueViewers: 0,
    viewersLoading: true,
    dateRange: null,
    granularity: null,
    eventFinalizedAt: null,
    eventFinalizedAt30m: null,
    maximizeChart: null,
    agents: [],
    /**
     * Object indicating
     */
    audienceGeneral: {},
    engagementGeneral: {},
    funnelGeneral: {},
    eventGeneral: {},
    shoppableScopeGeneral: {},
    shoppableEngagementGeneral: {},
    shoppableTransactionalGeneral: {},
    shoppableEngagementGeneralLoading: true,
    shoppableScopeGeneralLoading: true,
    shoppableTransactionalGeneralLoading: true,
    audienceGeneralLoading: true,
    engagementGeneralLoading: true,
    funnelGeneralLoading: true,
    eventGeneralLoading: true,
    words: [],
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setUniqueViewers: (state, action) => {
            state.uniqueViewers = action.payload;
            state.viewersLoading = false;
        },
        setEventGeneral: (state, action) => {
            state.eventGeneral = action.payload || {};
            state.eventGeneralLoading = false;
        },
        setAudienceGeneral: (state, action) => {
            state.audienceGeneral = action.payload || {};
            state.audienceGeneralLoading = false;
        },
        setEngagementGeneral: (state, action) => {
            state.engagementGeneral = action.payload || {};
            state.engagementGeneralLoading = false;
        },
        setFunnelGeneral: (state, action) => {
            state.funnelGeneral = action.payload || {};
            state.funnelGeneralLoading = false;
        },
        setShoppableScopeGeneral: (state, action) => {
            state.shoppableScopeGeneral = action.payload || {};
            state.shoppableScopeGeneralLoading = false;
        },
        setShoppableScopeGeneralLoading: (state, action) => {
            state.shoppableScopeGeneralLoading = action.payload;
        },
        setShoppableEngagementGeneral: (state, action) => {
            state.shoppableEngagementGeneral = action.payload || {};
            state.shoppableEngagementGeneralLoading = false;
        },
        setShoppableEngagementGeneralLoading: (state, action) => {
            state.shoppableEngagementGeneralLoading = action.payload;
        },
        setShoppableTransactionalGeneral: (state, action) => {
            state.shoppableTransactionalGeneral = action.payload || {};
            state.shoppableTransactionalGeneralLoading = false;
        },
        setEventFinalizedAt: (state, action) => {
            state.eventFinalizedAt = action.payload;
        },
        setEventFinalizedAt30m: (state, action) => {
            state.eventFinalizedAt30m = action.payload;
        },
        setWords: (state, action) => {
            state.words = action.payload;
        },
        setAudienceGeneralLoading: (state, action) => {
            state.audienceGeneralLoading = action.payload;
        },
        setEngagementGeneralLoading: (state, action) => {
            state.engagementGeneralLoading = action.payload;
        },
        setFunnelGeneralLoading: (state, action) => {
            state.funnelGeneralLoading = action.payload;
        },
        setEventGeneralLoading: (state, action) => {
            state.eventGeneralLoading = action.payload;
        },
        setDateRange: (state, action) => {
            if (
                action.payload === DateRangeEnum.TODAY ||
                (Array.isArray(action.payload) &&
                    action.payload.length === 2 &&
                    moment.duration(moment(action.payload[1]).diff(moment(action.payload[0]))).asHours() < 48)
            ) {
                state.granularity = Granularity.HOUR;
            } else {
                state.granularity = Granularity.DAY;
            }
            state.dateRange = action.payload;
        },
        setGranularity: (state, action) => {
            state.granularity = action.payload;
        },
        resetEventDashboard: (state) => ({
            ...initialState,
            maximizeChart: state.maximizeChart,
            agents: state.agents,
            dateRange: state.dateRange,
            granularity: state.granularity,
        }),
        setMaximizeChart: (state, action) => {
            state.maximizeChart = action.payload;
        },
        setAgents: (state, action) => {
            state.agents = action.payload;
        },
    },
});

export const {
    setUniqueViewers,
    setAudienceGeneral,
    setEngagementGeneral,
    setFunnelGeneral,
    setEventFinalizedAt,
    setEventFinalizedAt30m,
    setEventGeneral,
    setWords,
    setShoppableScopeGeneral,
    setShoppableScopeGeneralLoading,
    setShoppableEngagementGeneral,
    setShoppableEngagementGeneralLoading,
    setShoppableTransactionalGeneral,
    setAudienceGeneralLoading,
    setEngagementGeneralLoading,
    setFunnelGeneralLoading,
    setEventGeneralLoading,
    resetEventDashboard,
    setDateRange,
    setGranularity,
    setMaximizeChart,
    setAgents,
} = dashboardSlice.actions;

export const getDateRange = (state) => state.dashboard.dateRange;
export const getGranularity = (state) => state.dashboard.granularity;
export const getUniqueViewers = (state) => state.dashboard.uniqueViewers;
export const getViewersLoading = (state) => state.dashboard.viewersLoading;
export const getAudienceGeneral = (state) => state.dashboard.audienceGeneral;
export const getEngagementGeneral = (state) => state.dashboard.engagementGeneral;
export const getFunnelGeneral = (state) => state.dashboard.funnelGeneral;
export const getEventGeneral = (state) => state.dashboard.eventGeneral;
export const getShoppableScopeGeneral = (state) => state.dashboard.shoppableScopeGeneral;
export const getShoppableScopeGeneralLoading = (state) => state.dashboard.shoppableScopeGeneralLoading;
export const getShoppableEngagementGeneral = (state) => state.dashboard.shoppableEngagementGeneral;
export const getShoppableEngagementGeneralLoading = (state) => state.dashboard.shoppableEngagementGeneralLoading;
export const getShoppableTransactionalGeneral = (state) => state.dashboard.shoppableTransactionalGeneral;
export const getShoppableTransactionalGeneralLoading = (state) => state.dashboard.shoppableTransactionalGeneralLoading;
export const getAudienceGeneralLoading = (state) => state.dashboard.audienceGeneralLoading;
export const getEngagementGeneralLoading = (state) => state.dashboard.engagementGeneralLoading;
export const getFunnelGeneralLoading = (state) => state.dashboard.funnelGeneralLoading;
export const getEventGeneralLoading = (state) => state.dashboard.eventGeneralLoading;
export const getEventFinalizedAt = (state) => state.dashboard.eventFinalizedAt;
export const getWords = (state) => state.dashboard.words;

const selectEventGeneralId = createSelector([getEventGeneral], (eg) => eg[EventFields.EVENT_ID]);
const selectEventGeneralTotalDisplayTime = createSelector(
    [getEventGeneral],
    (eg) => eg[EventFields.EVENT_TOTAL_DISPLAY_TIME] || 0,
);
const selectAudienceGeneralTotalViewers = createSelector(
    [getAudienceGeneral],
    (ag) => ag[AudienceFields.AUDIENCE_TOTAL_VIEWERS] || 1,
);

export const getAverageTime = createSelector(
    [selectEventGeneralId, selectEventGeneralTotalDisplayTime, selectAudienceGeneralTotalViewers],
    (eventId, totalDisplayTime, totalViewers) => {
        /* Condition for a Vodafone event where growth team need force average time value
          because was calculated specially and there is not way of get by algorithm */
        if (eventId === '82502ef6-b820-48ef-b3b7-0b78e13c342b') {
            return 22;
        }
        return Number(totalDisplayTime) / totalViewers / 60;
    },
);

export const getEventFinalizedAt30m = (state) => state.dashboard.eventFinalizedAt30m;
export const getMaximizeChart = (state) => state.dashboard.maximizeChart;
export const getAgents = (state) => state.dashboard.agents;

export default dashboardSlice.reducer;
