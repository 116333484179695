import { makeStyles } from 'tss-react/mui';

const useStylesFieldForm = makeStyles()((theme) => {
    return {
        scroll: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            height: 'calc(100% - 55px)',
            /* Hide scrollbar for IE, Edge and Firefox */
            scrollbarWidth: 'none',
            /* Hide scrollbar for Chrome, Safari and Opera */
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },

        optionsRow: {
            display: 'flex',
            alignItems: 'center',
        },

        formControl: {
            width: '100%',
            paddingTop: theme.spacing(1),
        },
        formGroup: {
            marginTop: theme.spacing(2.5),
            '& >label': {
                textTransform: 'uppercase',
                color: '#000',
                fontWeight: 500,
                fontSize: theme.spacing(1.5),
                marginBottom: theme.spacing(0.5),
                [theme.breakpoints.up('xl')]: {
                    fontSize: theme.spacing(2),
                    marginBottom: theme.spacing(0.75),
                },
            },
        },

        buttonStyle: {
            paddingRight: '4px',
            borderRadius: '8px',
            fontSize: '0.7rem',
            width: '100%',
            height: '38px',
            textTransform: 'none',
            fontWeight: '500',
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xl')]: {
                height: '42px',
                borderRadius: theme.custom.borderRadiusLarge,
                fontSize: '14px',
                fontWeight: '600',
            },
            padding: '4px 10px',
            boxShadow: 'none',
            backgroundColor: '#e0e0e0',
        },

        addButton: {
            fontWeight: '500',
            '&:hover': {
                backgroundColor: 'red',
                color: '#fff',
                fontWeight: '600',
            },
            color: '#3283D3',
            [theme.breakpoints.up('xl')]: {
                fontWeight: '600',
                '&:hover': {
                    fontWeight: '700',
                },
            },
        },

        addOption: {
            width: 'auto',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #00000021',
            borderRadius: '10px',
            opacity: '1',
            fontWeight: '500',
            '&:hover': {
                backgroundColor: 'red',
                color: '#fff',
                fontWeight: '600',
            },
            color: '#3283D3',
            [theme.breakpoints.up('xl')]: {
                fontWeight: '600',
                '&:hover': {
                    fontWeight: '700',
                },
            },
        },

        saveButton: {
            color: '#FFF',
            fontWeight: '600',
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.up('xl')]: {
                fontWeight: '600',
            },
            '&:hover': {
                backgroundColor: `${theme.palette.primary.main} !important`,
            },
        },

        positionEnd: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
        },

        variantOption: {
            width: 'fit-content',
            wordBreak: 'break-word',
        },

        variantRowSpan: {
            fontSize: '0.75rem',
            fontFamily: 'Montserrat',
            lineHeight: '1.5',
        },

        productForm: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            height: '100%',
        },

        variantContent: {
            background: ' #FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #00000021',
            borderRadius: '10px',
        },

        mtNeg10px: {
            marginTop: '-10px',
        },

        p10px: {
            padding: '10px',
        },

        pb10px: {
            paddingBottom: '10px',
        },

        mlr10px: {
            margin: '0px 10px 0px',
        },

        pt10px: {
            paddingTop: '10px',
        },

        mb20px: {
            marginBottom: '20px',
        },

        varOptText: {
            fontWeight: 'bold',
        },

        editVariant: {
            borderRadius: '10px 10px 0px 0px',
            background: 'red',
            color: '#FFFFFF',
        },

        divider: {
            marginLeft: '12px',
            marginRight: '4px',
            height: '20px',
            [theme.breakpoints.up('xl')]: {
                height: '24px',
                marginLeft: '15px',
                marginRight: '5px',
            },
        },

        dividerColor: {
            background: '#FFFFFF',
        },

        autocomplete: {
            width: 'auto !important',
            '& .MuiAutocomplete-inputRoot': {
                padding: '4px !important',
                height: '36px',
            },

            '& .MuiChip-root': {
                borderRadius: '5px',
                border: '1px solid #00000021',
                cursor: 'pointer',
                height: '26px',
                /* margin: 2px; */
                display: 'flex',
                fontSize: '12px',
                background: '#F5F5F5',
                '& svg': {
                    width: '14px',
                    color: 'gray',
                },
            },
        },

        descriptionError: {
            '& > div': {
                border: '1px solid red !important',
            },
        },

        langContainer: {
            marginTop: '5px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
        },

        check: {
            marginTop: '18px',
            display: 'flex',
            width: '100%',
            alignItems: 'start',
            marginLeft: '12px',
            '& .MuiSvgIcon-root': {
                color: `${theme.palette.primary.main} !important`,
                fontSize: '1.18rem !important',
            },
        },

        selectArrow: {
            '& .MuiSvgIcon-root': {
                width: '1em',
                height: '1em',
                display: 'inline-block',
                fontSize: '18px !important',
                transition: 'fill 200ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
                flexShrink: '0',
                userSelect: 'none',
                color: 'rgba(0, 0, 0, 0.54) !important',
            },
        },
    };
});

export default useStylesFieldForm;
